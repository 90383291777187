import { combineReducers } from 'redux';

import roommates from './roommates';
import changeRequest from './changeRequest';
import insurancePolicy from './insurancePolicy';
import cancellationRequest from './cancellationRequest';
import canChangePaymentMethod from './canChangePaymentMethod';

const rootReducer = combineReducers({
  roommates,
  changeRequest,
  insurancePolicy,
  cancellationRequest,
  canChangePaymentMethod,
  user: (state = {}, _action) => (state),
  csrf_token: (state = '', _action) => (state),
  mapEnabled: (state = false, _action) => (state)
});

export default rootReducer;
