import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { PaymentOption } from 'components/v2/quote_display/utils';
import { formatCents } from 'utils/money/formatter';
import { useLocation } from 'react-router';
import { MONTHLY_PROCESSING_FEE } from '../constants';
import Alert from '../assets/alert.svg';
import { serviceFeeLabel, processingFeeLabel } from 'utils/us_states';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

const DesktopWrapper = styled.div({
  background: PALETTE.white,
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 8,
  padding: 32,
  '& button': {
    display: 'inline-block'
  },
  maxWidth: 384,
  margin: 'auto'
});

const MobileWrapper = styled.div({
  background: '#f5f5f5',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 8,
  padding: 16,
  maxWidth: 384,
  margin: 'auto'
});

const Header = styled.h4(FONTS.h4, {
  size: 18,
  marginBottom: 14,
  textAlign: 'center'
});

const Divider = styled.hr({
  border: 'none',
  height: 1,
  margin: 0,
  marginTop: '.5rem',
  flexShrink: 0,
  marginBottom: '.5rem',
  backgroundColor: PALETTE.neutral12
});

const MiniDivider = styled.hr<{ marginTop?: number }>(
  {
    border: 'none',
    height: 1,
    margin: 0,
    marginTop: '6px',
    flexShrink: 0,
    marginBottom: '6px',
    backgroundColor: PALETTE.neutral12
  },
  (props) => ({ marginTop: props.marginTop })
);

const Label = styled.h6([
  FONTS.p2Medium,
  {
    color: PALETTE.pitch,
    flexGrow: 1,
    flexDirection: 'column',
    marginBottom: 0
  }
]);

const TotalLabel = styled.h6([
  FONTS.p1Medium,
  { color: PALETTE.pitch }
]);

const Sublabel = styled.h6([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral55,
    flexGrow: 1,
    marginBottom: 0,
    fontSize: 12,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
]);

const InfoIcon = styled.img({
  marginLeft: 5,
  marginTop: -2,
  cursor: 'pointer'
});

const Tooltip = styled.span([
  FONTS.p2Light, {
  position: 'absolute',
  width: 298,
  padding: 18,
  background: PALETTE.white,
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.12)',
  borderRadius: 4,
  left: 10,
  top: 31,
  zIndex: 9999
}]);

const TooltipHeader = styled.h5([
  FONTS.h5, {
    textAlign: 'center'
  }
]);

const SecondaryLabel = styled.h6<{ marginLeft?: number }>([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral88,
    flexGrow: 1,
    flexDirection: 'column',
    marginBottom: 0,
    fontSize: 12,
    position: 'relative'
  },
  (props) => ({ marginLeft: props.marginLeft })
])

const PriceLabel = styled.h6([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral88,
    marginBottom: 0
  }
]);

const CustomPriceLabel = styled(PriceLabel)<{ font?, color?, fontWeight? }>((props) => ({
  '&&': [
    props.font ? props.font : FONTS.p3Medium,
    {
      color: props.color ? props.color : PALETTE.neutral88,
      fontWeight: props.fontWeight ? props.fontWeight : undefined
    }
  ]
}));

const PriceValue = styled.h6([
  FONTS.p2Medium,
  {
    color: PALETTE.neutral88,
    marginBottom: 0
  }
]);

const BoldPriceLabel = styled.h6([
  FONTS.p1Medium,
  {
    color: PALETTE.neutralDark,
    fontSize: '16px',
    lineHeight: '28px'
  }
]);

const ItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
});

const SubItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const PolicyPeriod = styled.span([
  FONTS.p2Medium,
  {
    color: PALETTE.neutral88,
    marginBottom: 0
  }
]);

const CadenceLabel = styled.span([
  FONTS.p2Extended,
  {
    color: PALETTE.brand125,
    fontWeight: 700,
    marginBottom: 0
  }
]);

interface OrderSummaryProps {
  price: {
    upfront: string;
    monthly: string;
  };
  paymentOption: PaymentOption;
  sdiPrice: any;
  coverGeniusRentersInsurance?: any;
  qualifiesForMonthlyRentersInsurance: boolean | undefined;
  eligibleInstalmentRIState: boolean | undefined;
  taa_path: string;
  address_state: string | undefined;
}

export const OrderSummaryV2 = ({
  sdiPrice,
  coverGeniusRentersInsurance,
  qualifiesForMonthlyRentersInsurance,
  eligibleInstalmentRIState,
  taa_path,
  address_state
}: OrderSummaryProps) => {
  const { search, pathname } = useLocation();
  const rentersInsuranceSelected = new URLSearchParams(search).get('ri_selected') === 'true';
  const isMonthly = new URLSearchParams(search).get('payment_cadence') === '0';
  const isRiMonthly = new URLSearchParams(search).get('ri_cadence') === '0';
  const selectedSdiPrice = isMonthly ? sdiPrice.monthly_cents : sdiPrice.upfront_cents;
  const paymentStep = pathname === '/enroll/quote_display/payment';
  const period = isMonthly ? `/mo` : '';
  const riPeriod = isRiMonthly ? `/mo` : '';
  const asterisk = paymentStep && isRiMonthly ? '*' : '';
  const inFullRiPrice = coverGeniusRentersInsurance?.in_full_quote_cents;
  const monthlyRiPrice = coverGeniusRentersInsurance?.monthly_quote_cents;
  const instalmentsRiPrice = coverGeniusRentersInsurance?.instalments_quote_cents;

  const policyHasServiceFee = sdiPrice.service_fee_cents > 0;
  const policyHasProcessingFee = sdiPrice.upfront_processing_fee_cents > 0 || sdiPrice.monthly_processing_fee_cents > 0;
  const policyProcessingFee = isMonthly ? sdiPrice.monthly_processing_fee : sdiPrice.upfront_processing_fee;
  const serviceFee = sdiPrice.service_fee_cents;

  const [tooltipServiceFeeVisible, setTooltipServiceFeeVisible] = useState(false);
  const toggleTooltipServiceFee = () => setTooltipServiceFeeVisible(!tooltipServiceFeeVisible);
  const [tooltipProcessingFeeVisible, setTooltipProcessingFeeVisible] = useState(false);
  const toggleTooltipProcessingFee = () => setTooltipProcessingFeeVisible(!tooltipProcessingFeeVisible);
  
  useEffect(() =>  {
    const closeTooltipServiceFee = () => setTooltipServiceFeeVisible(false);
    document.body.addEventListener('click', closeTooltipServiceFee);
    const closeTooltipProcessingFee = () => setTooltipProcessingFeeVisible(false);
    document.body.addEventListener('click', closeTooltipProcessingFee);

    const cleanup = () => { 
      document.body.removeEventListener('click', closeTooltipServiceFee);
      document.body.removeEventListener('click', closeTooltipProcessingFee);
    };
    return cleanup;
  }, []);

  const rentersInsurancePrice = () => {
    if (isRiMonthly) {
      if (qualifiesForMonthlyRentersInsurance) {
        return monthlyRiPrice;
      } else if (eligibleInstalmentRIState) {
        return instalmentsRiPrice;
      }
    } 
    return inFullRiPrice;
  };

  const monthlySdiSubtotal = selectedSdiPrice + sdiPrice.monthly_processing_fee_cents;
  const riPrice = rentersInsuranceSelected ? rentersInsurancePrice() : 0;

  const sdiPriceSubtotal = isMonthly
    ? selectedSdiPrice + sdiPrice.monthly_processing_fee_cents
    : selectedSdiPrice + sdiPrice.upfront_processing_fee_cents; 

  const total = sdiPriceSubtotal + serviceFee + riPrice; 

  const Wrapper = useCheckMobile() ? MobileWrapper : DesktopWrapper;

  return (
    <Wrapper>
      <Header> Your {rentersInsuranceSelected ? 'policies' : 'policy'}</Header>
      <ItemWrapper data-cy="sdiPrice">
        <div style={{width: '100%'}}>
          <Label>
            <CadenceLabel>Paid {isMonthly ? 'Monthly' : 'in Full' }</CadenceLabel>
            : Security Deposit Insurance
          </Label>
          <Sublabel>
            <PriceLabel>Policy Price</PriceLabel>
            <PriceValue>
              {formatCents(selectedSdiPrice)}
              <PolicyPeriod id="sdi-price-period">{period}</PolicyPeriod>
            </PriceValue>
          </Sublabel>
          {policyHasProcessingFee && (
            <SubItemWrapper>
              <SecondaryLabel marginLeft={20}>{processingFeeLabel(address_state, { titleize: true })}
                <InfoIcon src={Alert} onClick={(e) => { e.stopPropagation(); toggleTooltipProcessingFee() }}/>
                { tooltipProcessingFeeVisible &&
                  <Tooltip>
                    <TooltipHeader>{processingFeeLabel(address_state, { titleize: true })}</TooltipHeader>
                    Rhino charges a non-refundable, per transaction <b>{processingFeeLabel(address_state)}</b> to cover
                    the costs associated with processing payments for credit and debit card transactions. 
                    Please review your <a href={taa_path}
                      target="_blank"
                      style={{textDecoration: 'underline'}}>
                      Tenant Acknowledgment Agreement
                    </a> and policy documents 
                    for additional information and important disclosures regarding this fee.
                  </Tooltip>
                }
              </SecondaryLabel>
              <PriceValue id="processing-fee">
                {policyProcessingFee}
                {isMonthly && (<PolicyPeriod>{period}</PolicyPeriod>)}
              </PriceValue>
            </SubItemWrapper>
          )}
          {(isMonthly) && (
            <>
              <MiniDivider marginTop={12} />
              <SubItemWrapper id="sdi-monthly-subtotal">
                <SecondaryLabel>Monthly Subtotal</SecondaryLabel>
                <PriceValue>{formatCents(monthlySdiSubtotal)}</PriceValue>
              </SubItemWrapper>
            </>
          )}
        </div>
      </ItemWrapper>
      {rentersInsuranceSelected && (
        <>
        <MiniDivider/>
        <ItemWrapper data-cy="riPrice">
          <div style={{width: '100%'}}>
            <Label><CadenceLabel>Paid {isRiMonthly ? 'Monthly' : 'in Full' }</CadenceLabel>: Renters Insurance</Label>
            <Sublabel>
              <PriceLabel>Policy Price</PriceLabel>
              <PriceValue>
                {formatCents(rentersInsurancePrice())}
                <PolicyPeriod id="ri-price-period">
                  {qualifiesForMonthlyRentersInsurance || eligibleInstalmentRIState ? `${riPeriod}${asterisk}` : ``}
                </PolicyPeriod>
              </PriceValue>
            </Sublabel>
            {(isRiMonthly) && (
              <>
                <Sublabel>
                  <CustomPriceLabel color={ PALETTE.neutral65 }>Instalment Fee</CustomPriceLabel>
                  <CustomPriceLabel color={ PALETTE.neutral65 }>
                    { `${formatCents(MONTHLY_PROCESSING_FEE)}${riPeriod}` }
                  </CustomPriceLabel>
                </Sublabel>
                <Sublabel>
                  <CustomPriceLabel font={FONTS.label} color={ PALETTE.neutral65 } fontWeight={ 300 }>
                    {`*This fee will not be charged today, but will be charged on each subsequent month.`}
                  </CustomPriceLabel>
                </Sublabel>
              </>
            )}
          </div>
        </ItemWrapper>
        </>
      )}
      <MiniDivider marginTop={6} />
      {(isRiMonthly && rentersInsuranceSelected) && (
        <>
          <SubItemWrapper id="ri-monthly-subtotal">
            <SecondaryLabel>Monthly Subtotal</SecondaryLabel>
            <PriceValue>{formatCents(riPrice + MONTHLY_PROCESSING_FEE)}</PriceValue>
          </SubItemWrapper>
          <MiniDivider />
        </>
      )}
      {policyHasServiceFee && (
        <>
          <SubItemWrapper data-cy="servicePrice">
            <SecondaryLabel>One-Time {serviceFeeLabel(address_state, { titleize: true })}
              <InfoIcon src={Alert} onClick={(e) => { e.stopPropagation(); toggleTooltipServiceFee() }}/>
              { tooltipServiceFeeVisible &&
              <Tooltip>
                <TooltipHeader>{serviceFeeLabel(address_state, { titleize: true })}</TooltipHeader>
                The {serviceFeeLabel(address_state)} is a one-time fee which covers security deposit
                insurance management services provided by Rhino, including but
                not limited to facilitating the digital application, purchase,
                and management your security deposit insurance and provision
                of automatic communications regarding your policy.
                See the <a href={taa_path}
                   target="_blank"
                   style={{textDecoration: 'underline'}}>
                  Tenant Acknowledgment Agreement
                </a> for more information regarding applicable taxes and fees.
              </Tooltip>
              }
            </SecondaryLabel>
            <PriceValue id="service-fee">{sdiPrice.service_fee}</PriceValue>
          </SubItemWrapper>
          <MiniDivider />
        </>
      )}
      <SubItemWrapper data-cy="totalPrice">
        <TotalLabel> Total due today </TotalLabel>
        <BoldPriceLabel id="total-price"> {formatCents(total)} </BoldPriceLabel>
      </SubItemWrapper>
    </Wrapper>
  );
};
