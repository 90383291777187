import React, { FormEvent, useState } from 'react';

import { IChildProps } from './step_wrapper';
import { renderErrors, useSubsetState } from '../step_helpers';

import { IUnit } from 'types/algolia/unit';
import { IProperty } from 'types/algolia/property';
import { PolicyApplication, IUnitName } from '../redux/state';
import { IResult } from '../redux/actions';

import V2AddressStep from 'components/v2/address';
import ErrorText from 'components/common/form/error-text/error-text';
import { useEffect } from 'react';

import { Button, ArrowIcon } from '@sayrhino/rhino-shared-js';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import { useCreatePolicyApplication } from '../hooks/useCreatePolicyApplication';
import Loader from 'components/v2/loader';
import styled from '@emotion/styled';
import { useAxiosDispatcher, validInvitations } from '../api';

const propertyError = 'Whoops! Make sure to select your address from the list';
const requiresInvitationError = 'Signing up for Rhino at this property requires an invitation';

const StyledLoader = styled(Loader)`
  padding: 10px 0px;
`;

function GetStarted(props: IChildProps) {
  const dispatchAPI = useAxiosDispatcher();
  const policyApplicationCreate = useCreatePolicyApplication(props.step);
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const [policyApplication, updatePolicyApplication] = useSubsetState(
    props.policyApplication.result,
    [
      'id',
      'address_line_one',
      'address_state',
      'property_id',
      'address_line_two',
      'full_address',
      'google_place_id',
      'unit_id',
      'signup_step',
      'property_id',
      'partial_quote_id',
      'policy_app_property_info',
      'policy_app_unit_id'
    ],
    { address_line_one: '' }
  );

  const [error, setError] = useState<string>('');
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false);
  // for use when user enters/changes the property
  const [ownerRequiresInvitation, setOwnerRequiresInvitation] = useState<boolean | undefined>();
  const { id } = policyApplication;

  const validPolicyApplication = (policyApp: IResult<PolicyApplication>, portalUnitName: IResult<IUnitName>) => {
    return (
      policyApp.result &&
      ((policyApp.result.id && policyApp.result.policy_app_property_info === undefined) ||
        (policyApp.result.policy_app_property_info &&
          (portalUnitName.result?.name || policyApp.result.policy_app_unit_id === undefined)))
    );
  };

  const newPolicyApplication = (policyApp: IResult<PolicyApplication>) => {
    return policyApp.result === undefined;
  };

  const onPropertyChange = (property: IProperty, unit: IUnit) => {
    const address_line_one = property.address_line_one;
    const address_state = property.address_state;
    const property_id = property.property_id || unit.property_id;
    const google_place_id = property.id;
    const address_line_two = unit.name;
    const unit_id = unit.id;
    const owner_requires_invitation = property.owner_requires_invitation;
    const update = {
      address_line_one,
      address_line_two,
      address_state,
      property_id,
      google_place_id,
      unit_id
    };

    setOwnerRequiresInvitation(owner_requires_invitation);
    setError('');
    updatePolicyApplication({ ...update, id, signup_step: 'get_started' });
  };

  const updatePolicyApplicationProperty = (
    policy_app_property_info: IProperty,
    policy_app_unit_id,
    policy_app_unit_name
  ) => {
    const address_line_one = policy_app_property_info.address_line_one;
    const address_state = policy_app_property_info.address_state;
    const property_id = policy_app_property_info.id;
    const google_place_id = policy_app_property_info.google_place_id;
    const address_line_two = policy_app_unit_name;
    const unit_id = policy_app_unit_id;
    const owner_requires_invitation = policy_app_property_info.owner_requires_invitation;
    const update = {
      address_line_one,
      address_line_two,
      address_state,
      property_id,
      google_place_id,
      unit_id
    };

    setOwnerRequiresInvitation(owner_requires_invitation);
    setError('');
    updatePolicyApplication({ ...update, id, signup_step: 'get_started' });
  };

  useEffect(() => {
    if (props.policyApplication.result?.policy_app_property_info) {
      updatePolicyApplicationProperty(
        props.policyApplication.result?.policy_app_property_info,
        props.policyApplication.result?.policy_app_unit_id,
        props.partialQuoteUnitName.result?.name
      );
    }
  }, [props.policyApplication.result?.policy_app_property_info]);

  const addressValues = () => {
    const { address_line_one, address_line_two, unit_id, google_place_id } = policyApplication;

    return { address_line_one, address_line_two, unit_id, google_place_id };
  };

  const unitName = props.policyApplication.result?.policy_app_unit_id ? props.partialQuoteUnitName.result?.name : '';

  const addressProps = {
    useForm: false,
    hideLabels: true,
    mapEnable: false,
    values: addressValues(),
    full_address: policyApplication.full_address,
    onPropertyChange,
    policy_app_property_info: props.policyApplication.result?.policy_app_property_info,
    policy_app_unit_info_name: unitName
  };

  async function withInFlight<T>(thunk: () => Promise<T>): Promise<T> {
    setRequestInFlight(true);
    const x = await thunk();
    setRequestInFlight(false);
    return x;
  }

  const onSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    if (requestInFlight) return;

    const propertyId = policyApplication.property_id;

    const propertyInfoPresent =
      !!policyApplication.address_line_one || !!policyApplication.google_place_id || !!propertyId;
    if (!propertyInfoPresent) {
      return setError(propertyError);
    }

    const userId = props.user.result?.id;
    const userEmail = props.user.result?.email;

    if (ownerRequiresInvitation) {
      if (userEmail && propertyId) {
        const userHasInvitation = await withInFlight(() => dispatchAPI(validInvitations)(userEmail, propertyId));
        if (!userHasInvitation) {
          return setError(requiresInvitationError);
        }
      } else {
        return setError(requiresInvitationError);
      }
    }

    if (id && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId: id,
        userId,
        policyApplication
      });
    } else if (policyApplication && userId) {
      policyApplicationCreate.mutate({
        userId,
        policyApplication
      });
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center align-items-sm-start" style={styles.row}>
        <div className="col" style={styles.col}>
          <h2 className="bold header" style={styles.header}>
            Let’s get you started
          </h2>
          <p className="p1">Tell us the address of your new Rhino-partnered home to get a personalized price.</p>
          {validPolicyApplication(props.policyApplication, props.partialQuoteUnitName) ||
          newPolicyApplication(props.policyApplication) ? (
            <V2AddressStep cyAddress="address" cyUnit="unit" {...addressProps} />
          ) : (
            <StyledLoader></StyledLoader>
          )}
          {error && <ErrorText text={error} />}
          {renderErrors(props.policyApplication)}
          <br />
          <Button
            data-cy="getStarted"
            variant="primary"
            value="Get Started"
            type="submit"
            style={styles.submit}
            onClick={onSubmit}
            disabled={requestInFlight}
          >
            Get started <ArrowIcon style={{ paddingLeft: 10 }} />
          </Button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  row: {
    minHeight: '700px'
  },
  col: {
    maxWidth: '480px',
    textAlign: 'center' as 'center'
  },
  header: {
    fontSize: '60px',
    lineHeight: '68px'
  },
  submit: {
    margin: '0 auto'
  }
};

export default GetStarted;
