import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

export const Greeting = styled.h3([FONTS.h3]);
export const SectionHeader = styled.h6([FONTS.h6Demi, PALETTE.neutralDark], {
  marginBottom: 0,
  marginTop: '32px',
  ':first-of-type': {
    marginTop: '0px'
  }
});

export const MobileNavbarWrapper = styled.div({
  '&&': {
    border: `1px solid ${PALETTE.neutral12}`,
    backgroundColor: PALETTE.neutral4,
    paddingTop: 22,
    paddingBottom: 22,
    height: '85px',
    marginBottom: 32,
    '@media (min-width: 992px)': {
      display: 'none'
    }
  }
});

export const GreetingContainer = styled.div({
  paddingTop: '33px',
  '@media (max-width: 992px)': {
    textAlign: 'center'
  }
});

export const DividerLine = styled.hr({
  marginBottom: '43px',
  marginTop: '0px',
  '@media (max-width: 992px)': {
    display: 'none'
  }
});

export const RhinoOfferCardContainer = styled.article({
  display: 'flex',
  flexFlow: 'row-reverse',
  gap: '16px',
  justifyContent: 'space-between',
  color: PALETTE.neutral88,
  background: 'white',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: PALETTE.neutral12,
  overflow: 'hidden',
  textAlign: 'left',
  '@media (max-width: 648px)': {
    flexFlow: 'column',
    gap: 0
  }
});
