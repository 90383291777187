import axios, { AxiosResponse } from 'axios';
import { useMutation, MutationFunction } from 'react-query';
import { IOptOutRentersInsuranceParams } from '../interfaces';

const optOutRentersInsurance: MutationFunction<AxiosResponse, IOptOutRentersInsuranceParams> = async (
  params: IOptOutRentersInsuranceParams
): Promise<AxiosResponse> => {
  const result = await axios.put(`/cover_genius_policy_details/${params.ins_number}/opt_out`, params);
  return result;
};

export default function useOptOutRentersInsurance() {
  return useMutation(optOutRentersInsurance);
}
