// @flow
import React from 'react';

const Submit = (
  props: {
    onClick?: () => mixed,
    id?: string,
    name?: string,
    style?: Object,
    className?: string,
    value: string,
    disabled?: boolean,
  }
): React$Node => (
  <input type="submit" disabled={props.disabled} {...props} data-disable-with={props.value}/>
);

Submit.defaultProps = {
  name: 'commit',
  className: 'btn-default',
};

export default Submit;
