import axios, { AxiosResponse } from 'axios';
import { ICancellationRequest } from 'components/v2/insurance_policies/policyProps';
import { QueryObserverResult, useQuery } from 'react-query';

interface ResponseDto {
  insurance_policies: {
    eligible_for_renewal: boolean;
    coverage_start_date: string;
    coverage_end_date: string;
    upfront: boolean;
    pending_change_request: boolean;
    status: string;
    policy_id: number;
    address_line_one: string;
    address_line_two: string;
    policy_number: string;
    lease_id: number;
    non_renewal_reason: string;
    policy_application_id: number;
    cancellation_request: ICancellationRequest;
  }[];
}

const getSDIs = async (filter: string) => {
  const { data }: AxiosResponse = await axios.get<any>(`/insurance_policies?version=2&filter=${filter}`, {
    headers: { Accept: 'application/json' }
  });

  return data;
};

export default function useGetSDIs(filter: string = 'All'): QueryObserverResult<ResponseDto> {
  return useQuery<ResponseDto>(['security_deposit_insurance'], () => getSDIs(filter));
}
