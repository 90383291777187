import React from 'react';
import { connect, Provider } from 'react-redux';
import { openPolicyApplication } from 'components/v2/signup/redux/actions';
import store from 'components/v2/signup/redux/store';
import Dashboard, { IProps } from './Dashboard';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();
const connector = connect((state, props) => ({ ...state, ...props }), { openPolicyApplication });
const WrappedDashboard = connector(Dashboard);

const DashboardWrapper = (props: IProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <WrappedDashboard {...props} />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};

export default DashboardWrapper;
