import React, { useState } from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';

import store from 'components/v2/signup/redux/store';
import SubrogationPlans, { Subrogation } from 'components/v2/subro_payment_plans/subroFormWrapper';
import { SubrogationPaymentConfirmationModal } from 'components/v2/subro_payment_plans/subrogationPaymentConfirmationModal';
import { formatDays } from 'utils/date/formatter';

const LoggedOutSubroForm = (props: Subrogation) => {
  const [subrogationData, setSubrogationData] = useState<Subrogation | undefined>();

  const child = subrogationData ? (
    <SubrogationPaymentConfirmationModal
      claim={subrogationData.claim}
      insurance_policy_number={subrogationData.insurance_policy_number}
      paymentPlanDuration={subrogationData.payment_plan.installments_number}
      autoChargeDate={formatDays(moment(subrogationData.payment_plan.start_date).date())}
      payment_plan={subrogationData.payment_plan}
    />
  ) : (
    <SubrogationPlans
      {...props}
      onPayButtonClick={setSubrogationData}
    />
  );

  return (
    <Provider store={store}>
      {child}
    </Provider>
  );
};

export default LoggedOutSubroForm;
