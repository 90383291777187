import React from 'react';

import { ActionCenterMobileNavbar } from './ActionCenterMobileNavbar';
import {
  DividerLine,
  Greeting,
  GreetingContainer,
  MobileNavbarWrapper
} from '../styles';

interface IProps {
  firstName?: string;
}

const ActionCenterHeader = (props: IProps) => (
  <>
    <div className="dashboard-header row">
      <GreetingContainer className="greeting container">
        <Greeting data-cy="policiesTitle">Welcome back{props.firstName ? `, ${props.firstName}` : '!'}</Greeting>
      </GreetingContainer>
    </div>
    <DividerLine />
    <MobileNavbarWrapper>
      <ActionCenterMobileNavbar />
    </MobileNavbarWrapper>
  </>
);

export default ActionCenterHeader;