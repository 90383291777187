import React, { SyntheticEvent } from 'react';
import { IChildProps } from './step_wrapper';
import { updateModalState } from '../redux/actions';
import { useDispatch } from 'react-redux';

function CheckYourInbox(props: IChildProps) {
  const dispatch = useDispatch();

  const closeModal = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(updateModalState({
      visible: false,
      redirect: '/'
    }));
  };

  return (
    <div id="check-your-inbox" className="flow-step--page" style={styles.container}>
      <h1 className="bold purple">
        Check Your Inbox!
      </h1>
      <p className="p2">
        We sent you a link to accept the invitation and begin enrolling with Rhino.
      </p>
      <input
        type="submit"
        className="transparent-button"
        style={styles.button}
        value="Back to home"
        onClick={closeModal}
      />
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center' as 'center',
    width: '576px',
    margin: 'auto',
    padding: '150px 0 150px 0'
  },
  button: {
    border: 'none'
  }
}

export default CheckYourInbox;
