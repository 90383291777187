import UserContext from '../../layout/account/user-context';
import React, { useContext, useState } from 'react';
import { UpdatePaymentModal } from './UpdatePaymentModal';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { FailedPaymentAlert } from './FailedPaymentAlert';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

const Card = styled.div({
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: `0.5rem`,
  padding: '1.5rem'
});

const CardTitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 4
});

const Wrapper = styled.div({
  padding: '80px 10px',
  '@media (min-width: 768px)': {
    padding: '0px 10px'
  }
});

const PaymentMethodCopy = styled.p(FONTS.p1Medium, { color: PALETTE.neutral88, margin: 0, fontWeight: 500 });
const Title = styled.h4(FONTS.h4, {
  color: PALETTE.pink,
  margin: 0,
  fontWeight: 500,
  marginBottom: '2.25rem',
  lineHeight: '72px'
});
const UpdatePaymentCopy = styled.div([
  FONTS.h6Demi,
  { color: PALETTE.interaction100, margin: 0, fontWeight: 500, cursor: 'pointer' }
]);
const CardNumber = styled.p([FONTS.p1Light, { marginBottom: 0, color: PALETTE.neutral88 }]);

export const UpdatePaymentModalWrapper = (props) => {
  const { isOpen, setIsOpen } = props;
  const { currentUser } = useContext(UserContext);
  const analyticsMetadataContext = useContext(AnalyticsMetadataContext);
  const analyticsMetadata = analyticsMetadataContext;
  const cardNumberInfo = props.last_4_card_digits
    ? `${props.card_brand}, **** **** **** ${props.last_4_card_digits}`
    : 'No card specified';

  return (
    <Wrapper>
      <Title>Payment Information</Title>
      <Card>
        <CardTitleWrapper>
          <PaymentMethodCopy>Payment Method</PaymentMethodCopy>
          <UpdatePaymentCopy
            onClick={() => {
              setIsOpen(true);
            }}
            data-cy="updatePayment"
          >
            Update payment
          </UpdatePaymentCopy>
        </CardTitleWrapper>
        <CardNumber>{cardNumberInfo}</CardNumber>
        {props.number_of_unpaid_invoices > 0 && <FailedPaymentAlert />}
      </Card>
      <UpdatePaymentModal closeModal={() => setIsOpen(false)} isOpen={isOpen} user={currentUser} {...props} />
    </Wrapper>
  );
};
