import { Select } from '@sayrhino/rhino-shared-js';
import React, { FC } from 'react';
import { formatCents } from 'utils/money/formatter';
import { addStripeFees } from 'utils/money/add_stripe_fees';

type SelectProp = {
  onChange: (value) => void;
  total_claim: number;
  value?: Plan;
  onMenuOpen?: () => void;
  disabled?: boolean;
};

export type Plan = {
  amount: number;
  duration: number;
};

const plural = (word: string, value: number) => {
  if (value > 1) return word + 's';
  return word;
};

const generateLabel = (duration: number, amount: number): string => {
  if (duration === 1) {
    return `${duration} Payment - ${formattedAmountWithFees(amount)} due today`;
  }
  return `${duration} Payments - ${formattedAmountWithFees(amount)}/mo`;
};

const formattedAmountWithFees = (amount: number): string => {
  const value = Math.trunc(amount);
  return formatCents(addStripeFees(value));
};

const allPlanAmount = (total_amount): Plan[] => {
  const durations = [1, 3, 6, 12];
  const plans: Plan[] = durations.map((duration) => {
    const amount = total_amount / duration;
    return {
      amount,
      duration,
      label: generateLabel(duration, amount)
    };
  });
  return plans;
};

export const PlanSelect: FC<SelectProp> = ({ onChange, total_claim, onMenuOpen, disabled }) => {
  /**
   * This does not take effect at the moment
   * I will follow up on why the react-select component does use this
   */
  const formatOptionLabel = (option, { context }) => {
    const suffix = option.duration === 1 ? ' due today' : '/mo';

    const label =
      context === 'menu' ? (
        <>
          {`${option.duration} ${plural('Payment', option.duration)} - `}
          <b>{formattedAmountWithFees(option.amount)}</b> {suffix}
        </>
      ) : (
        generateLabel(option.duration, option.amount)
      );

    return label;
  };
  return (
    <Select
      label="Select a payment plan option"
      id="plans"
      formatOptionLabel={formatOptionLabel}
      options={allPlanAmount(total_claim)}
      getOptionValue={(option) => (option as Plan).amount.toString()}
      onChange={onChange}
      isClearable={false}
      onMenuOpen={onMenuOpen}
      isDisabled={disabled}
    />
  );
};
