import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

interface ResponseDto {
  id: number;
  interested_party_email: undefined;
  coverage_start_date: string;
  coverage_end_date: string;
  upfront: boolean;
  status_for_ri_card: string;
  address_line_one: string;
  address_line_two: string;
  ins_number: string;
  pay_cadence: string;
  claim_url: string;
  policy_wording_url: string;
}

const getCoverGeniusRIs = async (id: number) => {
  const { data }: AxiosResponse = await axios.get<any>(
    `/cover_genius_policy_details?user_id=${id}&dashboard_view=true`,
    { headers: { Accept: 'application/json' } }
  );
  return data;
};

export default function useGetCoverGeniusRIs(id: number): QueryObserverResult<ResponseDto[]> {
  return useQuery<ResponseDto[]>(['renters_insurance'], () => getCoverGeniusRIs(id));
}
