import { omit, isEmpty } from 'lodash';
import { IPolicyApplication, IUser } from '../signup/redux/state';
import { MARKETING_ENUM_VALUES, OPTED_IN } from '../signup/constants/marketing';
import axios from 'axios';

export const sanitizedEmail = (userEmail) => {
  if (isProduction() || userEmail === undefined) return userEmail;

  const whitelist = (window as any).App?.stagingEmailWhitelist || [];
  if (whitelist.includes(userEmail)) {
    const stripped_email = userEmail.toLowerCase().replace(/[^a-z0-9\s]/g, '-');
    return `staging+${stripped_email}@sayrhino.com`;
  } else {
    return 'staging@sayrhino.com';
  }
};

const sanitizedPhone = (userPhone) => {
  if (isProduction()) return userPhone;

  const useRealPhone: string | null = (window as any).App.useUnsanitizedPhone;
  return useRealPhone?.toLowerCase() === 'true' ? userPhone : '5555555555';
};

export const smsMarketingBool = (marketingValue: string | number | undefined) =>
  MARKETING_ENUM_VALUES[OPTED_IN] === marketingValue;

export const stripUserForTracking = (user: Partial<IUser> | undefined): Partial<IUser> | undefined => {
  const cleanedObj = omit(user, ['password', 'social_security_number', 'sms_marketing_opt_in']);
  return Object.assign({}, cleanedObj, { sms_opt_in: smsMarketingBool(user?.sms_marketing_opt_in) });
};

// Cant use NODE_ENV here because we need to account for review app and staging environments
const isProduction = () => (window as any).App.production === true;

const sanitizedPersonalInfo = (personalInfoObject: Partial<IUser> | Partial<IPolicyApplication> | undefined) => {
  if (personalInfoObject === undefined || isEmpty(personalInfoObject)) {
    return {};
  }

  const cleanedObj = stripUserForTracking(personalInfoObject);

  return Object.assign({}, cleanedObj, {
    email: sanitizedEmail(cleanedObj?.email),
    phoneNumber: `1${sanitizedPhone(cleanedObj?.phone)}`
  });
};

export const sendEventToIterable = async (
  eventName: string,
  user: Partial<IUser> | undefined,
  policyApplication: Partial<IPolicyApplication> | undefined,
  url: string
) => {
  const sanitizedUser = sanitizedPersonalInfo(user);
  if ((sanitizedUser as any).email === 'staging@sayrhino.com') {
    return;
  }
  await axios.post(url, {
    event_name: eventName,
    user_id: user?.id,
    policy_app_id: policyApplication?.id,
    user: sanitizedUser,
    policy_app: sanitizedPersonalInfo(policyApplication)
  });
};
