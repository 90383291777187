import React from 'react';

interface IProps {
  value: string;
  label: string;
  newValue?: string;
  dataCy?: string;
}

const DataHolder: React.FC<IProps> = (props: IProps) => {
  const {value, label, newValue, dataCy} = props;

  const noChangeRequestDisplay = () => {
    return (
      <div className="col-12 data-holder">
        <div style={styles.value}>{value}</div>
        <div data-cy={dataCy} style={styles.label}>{label}</div>
      </div>
    );
  };

  const changeRequestDisplay = () => {
    return (
      <div className="col-12 data-holder">
        <div style={styles.oldValue} className="col-12">{value}</div>
        <div style={styles.newValue} className="col-6">{newValue}</div>
        <div data-cy={dataCy} style={styles.label} className="col-6">{label}</div>
      </div>
    );
  };

  return (
    newValue ? changeRequestDisplay() : noChangeRequestDisplay()
  );
};

const styles = {
  label: {
    fontFamily: 'MaisonNeueLight',
    textAlign: 'end' as 'end',
    float: 'right' as 'right',
    fontSize: '12px',
    lineHeight: '28px',
    color: '#777777',
    padding: '0'
  },
  value: {
    float: 'left' as 'left',
    fontFamily: 'MaisonNeueMedium',
    fontSize: '16px',
    lineHeight: '28px'
  },
  oldValue: {
    float: 'left' as 'left',
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    textDecorationLine: 'line-through',
    color: '#777777',
    padding: '0'
  },
  newValue: {
    float: 'left' as 'left',
    fontFamily: 'MaisonNeueMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#DF2D42',
    padding: '0'
  }
};

export default DataHolder;
