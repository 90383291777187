import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import EditAccount from '..';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

const EditAccountWrapper = (props) => {
  const isMobile = useCheckMobile();

  return (
    <Router>
      <Switch>
        <Route path="/account/edit">
          <AnalyticsMetadataContext.Provider value={{ userId: props.userId, sessionId: props.sessionId }}>
            <EditAccount isMobile={isMobile} {...props} />
          </AnalyticsMetadataContext.Provider>
        </Route>
      </Switch>
    </Router>
  );
};
export default EditAccountWrapper;
