import React from 'react';
import { Wrapper, StyledCardParagraph, StyledHeader } from './styles';

export const PaymentProcessingCard = () => {
  return (
    <Wrapper>
      <StyledHeader>Your payment is being processed</StyledHeader>
      <StyledCardParagraph>
        If you elected to pay with ACH, it will take 1-3 business days for the payment to process.
        If you paid with a credit or debit card, your payment will finish processing shortly.{' '}
      </StyledCardParagraph>
    </Wrapper>
  );
};
