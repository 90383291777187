import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

const useCreateStripeToken = () => {
  const elements = useElements();
  const stripe = useStripe();

  const createStripeToken = async (name) => {
    if (elements === null || stripe === null) {
      throw new Error('Payment form has not been initialized yet');
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement === null) {
      throw new Error('Payment form has not been initialized yet');
    }

    const { error, token: stripeToken } = await stripe.createToken(cardElement, { name });

    if (error) {
      throw new Error(error.message);
    }

    return { stripeToken };
  };

  return createStripeToken;
};

export default useCreateStripeToken;
