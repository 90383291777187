import React, { Component } from 'react';

import debounce from 'lodash.debounce';

import PropertiesTable from './PropertiesTable';
import type { IProperty } from './PropertiesTable';

import { fetchProperties } from './api';

type IProps = any;

type IState = {
  zip: string;
  properties: IProperty[];
  loading: boolean;
};

export default class PropertySearch extends Component<IProps, IState> {
  fetchProperties: any;

  initialState: IState = { zip: '', properties: [], loading: false };

  constructor(props: IProps) {
    super(props);

    this.fetchProperties = debounce(this._fetchProperties, 350);

    this.state = this.initialState;
  }

  _fetchProperties(zip: string) {
    this.setState({ zip, loading: true });
    fetchProperties(zip)
      .then((properties: IProperty[]) => {
        this.setState({ properties, loading: false });
      })
      .catch(() => {
        this.setState({ properties: [], loading: false });
      });
  }

  onChange = (event: { target: { value: string } }) => {
    const value = event.target.value;

    // cancel doesn't exist but I don't feel comfortable editing funtionality during this upgrade
    // @ts-ignore
    this.fetchProperties.cancel();

    if (value.length > 0) {
      this.fetchProperties(event.target.value);
    } else {
      this.setState({ properties: [], loading: false, zip: '' });
    }
  };

  renderProperties() {
    const { properties, zip, loading } = this.state;

    if (loading) {
      return 'Loading...';
    }

    if (properties.length > 0) {
      return <PropertiesTable properties={properties} />;
    } else if (zip !== '') {
      return 'No properties found';
    }

    return null;
  }

  render() {
    return (
      <div>
        <input className="form-control" name="zip" autoFocus={true} placeholder="Zip Code" onChange={this.onChange} />
        <div className="results">{this.renderProperties()}</div>
      </div>
    );
  }
}
