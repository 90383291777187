import styled from '@emotion/styled';

export const NavbarWrapper = styled.div({
  display: 'flex',
  '&&': {
    '@media (max-width: 992px)': {
      display: 'none'
    }
  }
});
