/* tslint:disable */
const values: { [key: string]: string; } = {
  NY: 'admitted',
  PA: 'admitted',
  SD: 'admitted',
  MD: 'admitted',
  CT: 'admitted',
  TN: 'admitted',
  OH: 'admitted',
  IN: 'admitted',
  DE: 'admitted',
  ME: 'admitted',
  KY: 'admitted',
  AK: 'admitted',
  WV: 'admitted',
  MT: 'admitted',
  WY: 'admitted',
  HI: 'admitted'
};

export default values;
