import React from 'react';

import DataHolder from '../data_holder';
import PolicyDocuments from './policyDocuments';

import PolicyStatusIndicator from '../../shared/policyStatusIndicator';
import {IChangeRequest, ICancellationRequest} from '../policyProps';

interface IProps {
  coverageInfo: ICoverageInfo;
  changeRequest: IChangeRequest;
}

interface ICoverageInfo {
  documents_url: string;
  document_effective_date: string;
  policy_id: string | number;
  payment_amount: string;
  unit_name?: string;
  coverage_start_date: string;
  coverage_end_date: string;
  monthly_rent: string;
  coverage_amount: string;
  status: string;
  upfront: boolean;
  pending_change_request: boolean;
  lease_start_date: string;
  lease_end_date: string;
  policy_number: string;
  cancellation_request: ICancellationRequest | null;
}

const PolicyCoverage: React.FC<IProps> = (props: IProps) => {
  const policy = props.coverageInfo;
  const changeRequest = props.changeRequest;
  const cancellationRequest = policy.cancellation_request;
  const pendingCancellation =
    cancellationRequest !== null && cancellationRequest.status === 'pending';

  const configureDateChange = () => {
    const {lease_start_date, lease_end_date} = changeRequest;

    if (lease_end_date == null && lease_start_date == null) {
      return undefined;
    }
    return `${lease_start_date || policy.lease_start_date} \u2014 ${lease_end_date || policy.lease_end_date}`;
  };

  const configureCoverageDateChange = () => {
    const { coverage_start_date, coverage_end_date } = changeRequest;

    if (!coverage_start_date && !coverage_end_date) return;

    return `${coverage_start_date || policy.coverage_start_date} \u2014 ${coverage_end_date || policy.coverage_end_date}`;
  }

  return (
    <div className="row no-gutters" style={styles.container}>
      <div className="row no-gutters" style={styles.headerContainer}>
        <div className="col-6">
          <h6 className="demi-18">Policy Coverage</h6>
        </div>
        <div className="col-6 status-indicator">
          <PolicyStatusIndicator
            status={policy.status}
            pendingCancellation={pendingCancellation}
            pendingChangeRequest={policy.pending_change_request}
            className="status-padding"
            dataCy="policyStatus"
          />
        </div>
      </div>
        {policy.unit_name ? <PolicyDocuments
          url={policy.documents_url}
          documentEffectiveDate={policy.document_effective_date}
          id={policy.policy_id}
        /> : ''}
      <div className="row no-gutters">
        <DataHolder
          value={policy.payment_amount}
          label={policy.upfront ? 'In full' : 'Per month'}
          newValue={changeRequest.payment_amount}
          dataCy="paymentType"
        />
        <DataHolder
          value={policy.unit_name || ''}
          label="Unit / Floor"
          newValue={changeRequest.unit}
        />
        <DataHolder
          value={`${policy.lease_start_date} \u2014 ${policy.lease_end_date}`}
          label="Lease dates"
          newValue={configureDateChange()}
        />
        <DataHolder
          value={`${policy.coverage_start_date} \u2014 ${policy.coverage_end_date}`}
          label="Coverage dates"
          newValue={configureCoverageDateChange()}
        />
        <DataHolder
          value={policy.monthly_rent}
          label="Rent amount"
          newValue={changeRequest.monthly_rent}
        />
        <DataHolder
          value={policy.coverage_amount}
          label="Coverage amount"
          newValue={changeRequest.coverage_amount}
        />
        <DataHolder
          value={policy.policy_number}
          label="Policy number"
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '60px'
  },
  headerContainer: {
    width: '100%',
    display: 'flex' as 'flex'
  }
};

export default PolicyCoverage;
