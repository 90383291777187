import { v4 as uuidv4 } from 'uuid';
import { IRoommate } from '../policyProps';
import { UPDATE_ROOMMATES, ADD_ROOMMATE, RENEW_ROOMMATE, REMOVE_ROOMMATE } from '../actions';

export default function roommates(state = [], action) {
  switch(action.type) {
    case UPDATE_ROOMMATES:
      return [newRoommate(), ...action.roommates];
    case ADD_ROOMMATE:
      return [newRoommate(), action.roommate, ...state.slice(1)];
    case RENEW_ROOMMATE:
      return state.map((roommate: IRoommate) => {
        if (roommate.id !== action.roommate.id) return roommate;

        return { ...roommate, ...action.roommate }
      });
      case REMOVE_ROOMMATE:
        return state.filter((roommate: IRoommate) => roommate.id !== action.id);
    default:
      return state;
  }
}

const newRoommate = () => {
  return {
    id: undefined,
    email: '',
    full_name: '',
    phone: null,
    temporaryId: uuidv4()
  };
}
