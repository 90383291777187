/** @jsx jsx */
import { jsx } from '@emotion/react';
import { PALETTE, FONTS, ButtonHabitat as ButtonBase } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import React from 'react';

const BannerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '16px -30px 16px -30px',
  backgroundColor: PALETTE.alert4,
  borderRadius: '8px',
  padding: '12px 24px',
  '@media (max-width: 425px)': {
    flexDirection: 'column',
    margin: '16px 0'
  }
});

const Button = styled(ButtonBase)({
  '&&': {
    backgroundColor: PALETTE.neutralLight,
    color: PALETTE.alert100,
    marginLeft: '10px',
    '@media (max-width: 425px)': {
      alignSelf: 'flex-end'
    }
  }
});

const TextContainer = styled.p({ margin: 0 });

const Text = styled.span([FONTS.p2Light], { color: PALETTE.alert100, margin: 0 });

const BoldText = styled.span([FONTS.p2Medium], { color: PALETTE.alert100 });

const Link = styled.a([FONTS.p2Light], {
  color: PALETTE.interaction100
});

const getDelinquencyCopy = (
  allowDelinquentManualRenewal: boolean,
  unreimbursedClaims: boolean,
  unpaidPremiums: boolean,
  firstUnreimbursedClaim: any
) => {
  const claimLink = firstUnreimbursedClaim && (
    <Link href={`/claims/${firstUnreimbursedClaim?.claim_id}/payment_plans/new`} target="_self">
      set up payment here.
    </Link>
  );

  switch (allowDelinquentManualRenewal) {
    case true:
      if (unreimbursedClaims) {
        return <Text>— This policy cannot auto-renew while there is an outstanding claim. Please {claimLink}</Text>;
      } else if (unpaidPremiums) {
        return <Text>— Avoid a lapse in coverage by updating the card on file.</Text>;
      }
    case false:
      if (unreimbursedClaims) {
        return <Text>{claimLink}</Text>;
      } else if (unpaidPremiums) {
        return <Text> update your credit card</Text>;
      }
  }
};

export const CannotRenewBanner = ({
  status,
  allow_delinquent_manual_renewal,
  policy_number,
  first_name,
  email,
  unreimbursed_claims_info
}) => {
  const filteredClaimsInfo = unreimbursed_claims_info.filter((claim) => claim.claim_status === 'subrogation');
  const firstUnreimbursedClaim = filteredClaimsInfo[0];
  const unpaidPremiums = status === 'unpaid_premiums';
  const unreimbursedClaims = status === 'unreimbursed_claims';
  const getHeaderCopy = React.useCallback(() => {
    if (unpaidPremiums) {
      return 'Policy cannot renew due to delinquent policy payments';
    }
    if (unreimbursedClaims) {
      return 'Policy cannot renew due to an outstanding claim balance';
    }
    return 'Policy cannot renew due to delinquent policy payments or an outstanding claim balance';
  }, [unreimbursedClaims, unpaidPremiums]);

  const claimLink = firstUnreimbursedClaim && (
    <Link href={`/claims/${firstUnreimbursedClaim?.claim_id}/payment_plans/new`} target="_self">
      set up payment here.
    </Link>
  );

  return (
    <BannerWrapper>
      {allow_delinquent_manual_renewal ? (
        <TextContainer>
          <BoldText>
            {unreimbursedClaims
              ? 'Outstanding claim needs your attention '
              : 'Update payment info to auto-renew policy '}
          </BoldText>
          {getDelinquencyCopy(
            allow_delinquent_manual_renewal,
            unreimbursedClaims,
            unpaidPremiums,
            firstUnreimbursedClaim
          )}
        </TextContainer>
      ) : (
        <TextContainer>
          <BoldText>{getHeaderCopy()}</BoldText>
          <Text>
            <span> </span>
            {'— To avoid losing coverage please '}
            {getDelinquencyCopy(
              allow_delinquent_manual_renewal,
              unreimbursedClaims,
              unpaidPremiums,
              firstUnreimbursedClaim
            )}
          </Text>
        </TextContainer>
      )}
      {unpaidPremiums && (
        <Button
          id="update-card"
          variant="destructive"
          size="small"
          usage="secondary"
          onClick={() => (window.location.href = '/account/edit')}
        >
          Update
        </Button>
      )}
    </BannerWrapper>
  );
};

export default CannotRenewBanner;
