import axios, { AxiosResponse } from 'axios';
import { ICancellationRequestParams } from './policyProps';
import { useMutation, MutationFunction } from 'react-query';

const createCancellationRequest: MutationFunction<AxiosResponse, ICancellationRequestParams> = async (
  params: ICancellationRequestParams
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.post(
    `/insurance_policies/${params.insurance_policy_id}/cancellation_requests`,
    params
  );
  return response;
};

export default function useCreateCancellationRequestMutation() {
  return useMutation(createCancellationRequest);
}
