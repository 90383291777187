import React from 'react';

// @ts-ignore
import timeIcon from './time.svg';

interface IRenewalProps {
  timeInterval: string;
}

const Renewal: React.FC<IRenewalProps> = (props: IRenewalProps) => {
  return (
    <div className="renewal-notice" style={styles.box}>
      <div className="icon" style={styles.icon}>
        <img src={timeIcon} style={styles.image} alt="Time" />
      </div>
      <div className="content" style={styles.content}>
        <div className="notice" style={styles.text.header}>
          { `${props.timeInterval} until your lease ends` }
        </div>
        <div style={styles.text.subHeader}>
          {
            `Your current lease ends in ${props.timeInterval}, so whether you're staying or going, you'll save thousands with Rhino.`
          }
        </div>
      </div>
    </div>
  );
};

const styles = {
  box: {
    backgroundColor: '#6318CE',
    borderRadius: '8px',
    display: 'flex',
    marginTop: '30px'
  },
  text: {
    header: {
      fontFamily: 'MaisonNeueExtendedMedium',
      fontSize: '18px',
      lineHeight: '32px',
      color: '#FFFFFF',
      marginBottom: '8px'
    },
    subHeader: {
      fontFamily: 'MaisonNeueLight',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#FFFFFF'
    }
  },
  content: {
    flex: '0 1 auto',
    margin: '24px 26px'
  },
  icon: {
    flex: '0 0 auto',
    marginTop: '28px',
    marginLeft: '28px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '21px'
  }
};

export default Renewal;
