// @ts-ignore
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
// @ts-ignore
import { Stripe, StripeCardNumberElement, StripeElements, StripeError, Token } from '@stripe/stripe-js';
import React, { Component, FormEvent, ReactNode } from 'react';

import { RhinoLogger } from 'components/v2/shared/rhino_logger';
import { rollbar } from 'components/v2/shared/rollbar';
import store from 'components/v2/signup/redux/store';
import { connect } from 'react-redux';

import RecaptchaWrapper from 'components/v2/common/recaptcha';
import { checkInvitation, checkInvitationSync, IPaymentProps, IResult } from 'components/v2/signup/redux/actions';
import { PolicyApplication, User } from 'components/v2/signup/redux/state';
import { collectErrors, PaymentOption } from './utils';
import { LabelLightGray } from './styles';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { formatCents } from 'utils/money/formatter';
import { RentersInsuranceLegalText } from './renters_insurance_legal_text';
import env from 'utils/env';
import getFeatureFlags from 'utils/getFeatureFlags';

const featureFlags = getFeatureFlags();
const postalCodeFlag = featureFlags?.postalCode;

export interface IPaths {
  subscriptions_path: string;
  terms_of_service_path: string;
  electronic_transactions_agreement_path: string;
  taa_path: string;
}

export interface IPaymentFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  paths: IPaths;
  paymentOption: PaymentOption;
  full_name: string;
  loaderImage?: string;
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  user: IResult<User>;
  submitEnabled: boolean;
  setSubmitEnabled: (bool) => void;
  displayLoader?: boolean;
  total?: string;
  isMobile?: boolean;
  confirmRentersInsurance?: any;
  rentersInsurancePolicyPriceInfo?: number;
  sdiPolicyPriceInfo?: any;
  rentersInsuranceSelected?: boolean;
  coverGeniusRentersInsurance?: any;
  optOut?: (ins_number: string) => void;
}

interface IPaymentFormState {
  displayLoading?: boolean;
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
  postalCode: string;
  errors: { postalCode?: string; stripeError?: string };
}

interface IRecaptchaResult {
  error?: string;
  token?: string;
}

const PostalCodeInput = styled.input({
  color: '#000000',
  fontSize: '16px',
  marginTop: 4,
  fontFamily: 'sans-serif',
  fontWeight: 300,
  '&&::placeholder': {
    color: '#595959'
  },
  display: 'block',
  width: '100%',
  height: '44px',
  padding: 8,
  border: '1px solid #bfbfbf',
  borderRadius: 4,
  '&&:focus-visible': {
    outline: 'none'
  }
});

class PaymentForm extends Component<IPaymentFormProps, IPaymentFormState> {
  private recaptchaRef: React.RefObject<any>;
  private recaptchaPromise?: Promise<IRecaptchaResult>;
  private recaptchaResolve?: (arg: IRecaptchaResult) => void;
  private loaderTimeout?: number;

  constructor(props: IPaymentFormProps) {
    super(props);

    (window as any).enableRollbar = true;

    this.recaptchaRef = React.createRef();

    this.state = {
      displayLoading: false,
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      postalCode: '',
      errors: {}
    };
  }

  public componentWillUnmount() {
    if (this.loaderTimeout !== undefined) {
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = undefined;
    }
    (window as any).enableRollbar = false;
  }

  public onBack = () => {
    history.back();
  };

  public render(): ReactNode {
    const {
      paths: { taa_path, subscriptions_path, terms_of_service_path, electronic_transactions_agreement_path },
      paymentOption,
      submitEnabled,
      setSubmitEnabled
    } = this.props;

    const { postalCode, errors } = this.state;
    const policyAppErrors = collectErrors(this.props.policyApplication);
    const userErrors = collectErrors(this.props.user);

    // Stripe elements are styled with Style object
    // https://stripe.com/docs/js/appendix/style
    const inputOptions = {
      style: {
        base: {
          color: '#000000',
          fontSize: '16px',
          fontWeight: '300',
          '::placeholder': {
            color: '#595959'
          }
        },
        invalid: {
          color: '#A72131',
          fontWeight: '300'
        }
      }
    };

    const PaymentUserAgreement = styled(LabelLightGray)({
      '&&': {
        position: 'static',
        width: '100%',
        height: '100%',
        paddingTop: 16,
        ...(this.props.isMobile && { paddingBottom: 96 })
      }
    });

    const Link = styled.a({
      '&&': {
        textDecoration: 'underline',
        textDecorationColor: PALETTE.neutral65,
        color: PALETTE.neutral65
      }
    });

    const CardError = styled.div([
      FONTS.p3,
      {
        backgroundColor: PALETTE.alert4,
        color: PALETTE.alert125,
        fontWeight: 500,
        marginLeft: '15px',
        padding: '12px 0',
        textAlign: 'center',
        width: '100%'
      }
    ]);

    const LegalDiv = styled.div([FONTS.p3], { color: PALETTE.neutral65, marginBottom: '8px' });

    const search = window.location.search;
    const isMonthly = new URLSearchParams(search).get('payment_cadence') === '0';
    const isRiMonthly = new URLSearchParams(search).get('ri_cadence') === '0';
    const addressState = this.props?.policyApplication?.result?.address_state;
    const qualifiesForMonthlyRentersInsurance = env('MONTHLY_RENTERS_INSURANCE_STATES').includes(addressState);
    const rentersInsuranceInstalmentsFlag = (window as any)?.App?.featureFlags?.rentersInsuranceInstalments;
    const rentersInsuranceInstalmentsEnrollmentFlag = (window as any)?.App?.featureFlags
      ?.rentersInsuranceInstalmentsEnrollment;
    const eligibleInstalmentRIState: boolean =
      env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(addressState) &&
      rentersInsuranceInstalmentsFlag &&
      rentersInsuranceInstalmentsEnrollmentFlag;

    const inFullRiPrice = this.props.coverGeniusRentersInsurance?.in_full_quote_cents;
    const monthlyRiPrice = this.props.coverGeniusRentersInsurance?.monthly_quote_cents;
    const instalmentsRiPrice = this.props.coverGeniusRentersInsurance?.instalments_quote_cents;
    const rentersInsurancePolicyAmount = () => {
      if (isRiMonthly) {
        if (qualifiesForMonthlyRentersInsurance) {
          return monthlyRiPrice;
        } else if (eligibleInstalmentRIState) {
          return instalmentsRiPrice;
        }
      }
      return inFullRiPrice;
    };
    const sdiPolicyAmount = isMonthly
      ? this.props.sdiPolicyPriceInfo.monthly_cents
      : this.props.sdiPolicyPriceInfo.upfront_cents;

    const serviceFee = this.props.sdiPolicyPriceInfo.service_fee_cents;

    const processingFee = isMonthly
      ? this.props.sdiPolicyPriceInfo.monthly_processing_fee_cents
      : this.props.sdiPolicyPriceInfo.upfront_processing_fee_cents;

    const totalSelectedPrice =
      (this.props.rentersInsuranceSelected ? rentersInsurancePolicyAmount() + sdiPolicyAmount : sdiPolicyAmount) +
      serviceFee +
      processingFee;

    const isUpfront = paymentOption === PaymentOption.Upfront ? 'true' : 'false';
    return (
      <div className="payment-form">
        <form action={subscriptions_path} method="post" onSubmit={this.handleSubmit.bind(this)}>
          <input type="hidden" name="upfront" value={isUpfront} />
          <div className="row">
            {(errors.stripeError || errors.postalCode) && (
              <CardError>{errors.stripeError || errors.postalCode}</CardError>
            )}
            <div className="col-12">
              <LabelLightGray className="payment-label">Credit card number</LabelLightGray>
              <div className="payment-input-wrapper" id="card-number" data-cy="ccNumber">
                <CardNumberElement
                  className="v2-input"
                  options={{ ...inputOptions, placeholder: '' }}
                  onChange={(event) =>
                    this.setState({ cardNumber: event.complete, errors: { ...errors, stripeError: undefined } })
                  }
                />
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <LabelLightGray className="payment-label">Expiration date</LabelLightGray>
              <div className="payment-input-wrapper" id="card-expiry" data-cy="ccExpiration">
                <CardExpiryElement
                  className="v2-input"
                  options={inputOptions}
                  onChange={(event) =>
                    this.setState({ cardExpiry: event.complete, errors: { ...errors, stripeError: undefined } })
                  }
                />
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <LabelLightGray className="payment-label">CVC / Security code</LabelLightGray>
              <div className="payment-input-wrapper" id="card-cvc" data-cy="ccCvc">
                <CardCvcElement
                  className="v2-input"
                  options={{ ...inputOptions, placeholder: '' }}
                  onChange={(event) =>
                    this.setState({ cardCvc: event.complete, errors: { ...errors, stripeError: undefined } })
                  }
                />
              </div>
            </div>
            {postalCodeFlag && (
              <div className="col-xl-4 col-sm-12">
                <LabelLightGray className="payment-label">Billing Zip Code</LabelLightGray>
                <div className="payment-input-wrapper" id="zip" data-cy="zip">
                  <PostalCodeInput
                    placeholder={''}
                    value={postalCode}
                    onChange={(e) => {
                      this.onPostalCodeFieldChange(e);
                    }}
                  />
                </div>
              </div>
            )}
            {policyAppErrors && (
              <CardError>
                {policyAppErrors.map((pError) => (
                  <p>{pError}</p>
                ))}
              </CardError>
            )}
            {userErrors && (
              <CardError>
                {userErrors.map((uError) => (
                  <p>{uError}</p>
                ))}
              </CardError>
            )}
          </div>
          <PaymentUserAgreement data-cy="paymentAgreement">
            {qualifiesForMonthlyRentersInsurance && isMonthly && this.props.rentersInsuranceSelected && (
              <LegalDiv style={{ fontWeight: 'bold' }}>
                *Your first charge will be $1 less than your recurring charge.
              </LegalDiv>
            )}
            <LegalDiv>
              I understand that by processing my payment (1) I am enrolling my rental unit pursuant to the terms of
              the&nbsp;
              <Link href={taa_path} id="tenant-agreement" target="_blank">
                Tenant Enrollment and Acknowledgement Agreement
              </Link>
              ; and (2) I am consenting to the electronic delivery of any information or other communications required
              by law to be provided in writing pursuant to the terms of the&nbsp;
              <Link href={electronic_transactions_agreement_path} id="transaction-agreement" target="_blank">
                Agreement to Conduct Electronic Transactions
              </Link>
              ; and (3) I have read and accepted the&nbsp;
              <Link href={terms_of_service_path} id="terms-of-service" target="_blank">
                Terms of Service
              </Link>
              .
            </LegalDiv>
            {this.props.rentersInsuranceSelected && (
              <RentersInsuranceLegalText
                ins_number={this.props.coverGeniusRentersInsurance.ins_number}
                underwriter={this.props.coverGeniusRentersInsurance.underwriter}
              />
            )}
          </PaymentUserAgreement>
          <RecaptchaWrapper
            onSuccess={this.onRecaptchaSuccess.bind(this)}
            onError={this.onRecaptchaError.bind(this)}
            recaptchaRef={this.recaptchaRef}
          />
          <QuoteDisplayFooter
            arrowOnButton={false}
            nextBtnLabel={`Pay ${formatCents(totalSelectedPrice)}`}
            nextBtnDisabled={!submitEnabled}
            onBack={this.onBack}
            onNext={this.handleSubmit.bind(this)}
            dataCy="payment"
          />
        </form>
        {this.state.displayLoading && (
          <div style={styles.loaderSection}>
            <img style={styles.loaderImage} src={this.props.loaderImage} />
          </div>
        )}
        {this.props.displayLoader && (
          <div style={styles.loaderSection}>
            <img style={styles.loaderImage} src={this.props.loaderImage} />
          </div>
        )}
      </div>
    );
  }

  private onPostalCodeFieldChange = (e) => {
    this.setState({ postalCode: e.target.value }, () => this.validatePostalCode());
  };

  private logInfo(message: string) {
    const prefixed = `policy_application_id=${this.props.policyApplication.result?.id}: ${message}`;
    RhinoLogger.info(prefixed);
  }

  private logError(message: string) {
    const prefixed = `policy_application_id=${this.props.policyApplication.result?.id}: ${message}`;
    RhinoLogger.error(prefixed);
  }

  private createSubscriptionWithPaymentIntent() {
    this.setState({ displayLoading: true });

    const search = window.location.search;
    const isAnnual = new URLSearchParams(search).get('payment_cadence') === '1';

    const payload = {
      upfront: isAnnual
    };
    this.props.payAndSubscribe(payload);
  }

  private optoutRentersInsurance() {
    this.props.optOut && this.props.coverGeniusRentersInsurance && !this.props.rentersInsuranceSelected
      ? this.props.optOut(this.props.coverGeniusRentersInsurance.ins_number)
      : null;
  }

  private async handleSubmit(e?: FormEvent<HTMLFormElement>) {
    this.props.setSubmitEnabled(false);
    e?.preventDefault();

    const userId = this.props.user.result?.id;
    const policyApplicationId = this.props.policyApplication.result?.id;

    this.logInfo('checking invitation');

    if (!userId) {
      rollbar.error('PaymentForm without user');
    } else if (!policyApplicationId) {
      rollbar.error('PaymentForm without policy application');
    } else {
      const invitation = await checkInvitation(userId, policyApplicationId);
      if (invitation.result) {
        this.logInfo('invitation changed: aborting');
        store.dispatch(checkInvitationSync(invitation));
      }
    }

    this.logInfo('payment submission started');

    this.recaptchaPromise = new Promise<IRecaptchaResult>((resolve) => {
      this.recaptchaResolve = resolve;
    });
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
    if (!this.state.errors.stripeError) {
      this.executeTransaction();
    }
  }

  private onRecaptchaSuccess(token: string) {
    if (this.recaptchaResolve) {
      this.recaptchaResolve({ token });
      this.recaptchaResolve = undefined;
    }
  }

  private onRecaptchaError(error: string) {
    if (this.recaptchaResolve) {
      this.recaptchaResolve({ error });
      this.recaptchaResolve = undefined;
    }
  }

  private validatePostalCode() {
    if (!postalCodeFlag) {
      return;
    }
    const { errors } = this.state;
    this.setState({ errors: { ...errors, postalCode: '' } });

    if (!this.state.postalCode) {
      this.setState({ errors: { ...errors, postalCode: 'Zip code is required.' } });
    } else if (this.state.postalCode.length !== 5) {
      this.setState({ errors: { ...errors, postalCode: 'Invalid zip code, must be 5 digits.' } });
    }
  }

  private async executeTransaction() {
    const { elements, stripe, user, policyApplication } = this.props;
    const { errors } = this.state;

    this.logInfo('payment submitted');

    if (stripe && elements) {
      const recaptchaResult = await this.recaptchaPromise;
      if (recaptchaResult?.error) {
        this.setState({ errors: { ...errors, stripeError: recaptchaResult.error } });
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      this.validatePostalCode();
      if (errors?.postalCode) {
        return;
      }

      if (!(policyApplication.result?.stripe_payment_intent_client_secret && cardElement)) {
        this.logError('stripe_payment_intent_client_secret or cardElement is blank');

        this.setState({ errors: { ...errors, stripeError: 'Please try again' } });
        return;
      }

      const stripePaymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { address: { postal_code: this.state.postalCode } }
      });

      if (stripePaymentMethodResult.error?.message) {
        this.logError(stripePaymentMethodResult.error.message);
        this.setState({ errors: { ...errors, stripeError: stripePaymentMethodResult.error.message } });
        this.props.setSubmitEnabled(true);
        return;
      }

      if (stripePaymentMethodResult?.paymentMethod?.card?.funding === 'prepaid') {
        const prepaid_payload = {
          user_id: user.result?.id,
          sdi_application_id: policyApplication.result?.id
        };

        this.logError('Used a prepaid card');

        this.setState({
          errors: { ...errors, stripeError: 'We do not accept prepaid cards. Enter a credit or debit card.' }
        });
        this.props.setSubmitEnabled(true);
        return;
      }

      const stripeResult = await stripe.confirmCardPayment(
        policyApplication.result?.stripe_payment_intent_client_secret,
        { payment_method: stripePaymentMethodResult?.paymentMethod?.id }
      );
      this.props.setSubmitEnabled(true);

      if (stripeResult.error?.message) {
        this.logError(stripeResult.error.message);
        this.setState({ errors: { ...errors, stripeError: stripeResult.error.message } });
        return;
      }

      this.logInfo('payment successful, creating subscription next');

      this.createSubscriptionWithPaymentIntent();
    } else {
      this.logInfo('Stripe uninitialized when submitting card form.');
    }
  }
}

const styles = {
  loaderSection: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  loaderImage: {
    width: '152px',
    height: '80px',
    margin: 'auto',
    marginTop: '20%',
    display: 'block'
  }
};

const mapStateToProps = (state) => ({
  displayLoader: state.modal.displayLoader
});

export default connect(mapStateToProps)(PaymentForm);
