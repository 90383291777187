import moment from 'moment';
import React, { FormEvent, useState } from 'react';

import { useLocation } from 'react-router';

import { IChildProps } from './step_wrapper';
import Input from 'components/v2/input';
import { renderError, useSubsetState, BackButton, mergeInvitationChange } from '../step_helpers';
import { PolicyApplication } from '../redux/state';
import { startDatePickerProps, endDatePickerProps, maxEndDate, maxStartDate } from './lease_dates';
import MapWithOverlay from '../map_with_overlay';
import { useAssetContext } from './asset_context';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';

export default function InvitationLeaseDates(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const { invitation, policyApplication } = props;
  const mergedApplication = mergeInvitationChange(policyApplication.result, invitation.result);

  const minEndDateM = moment(mergedApplication.lease_start_date);
  const minEndDate = minEndDateM.toDate();
  const location = useLocation();
  const shouldShowPrompt = location.search.match(/prompt=true/) !== null;

  const [showPrompt, setShowPrompt] = useState(shouldShowPrompt);
  const [policyApp, setPolicyApp] = useSubsetState(
    mergedApplication,
    [
      'lease_start_date',
      'lease_end_date',
      'monthly_rent',
      'property_id',
      'address_line_two',
      'unit_id',
      'google_place_id',
      'custom_coverage_amount_cents',
      'custom_coverage_multiplier',
      'partial_quote_id'
    ],
    { lease_end_date: mergedApplication.lease_end_date || minEndDateM.format('YYYY-MM-DD') }
  );

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    if (policyApplicationId && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication: policyApp
      });
    }
  }

  function updatePolicyApplication(update: PolicyApplication) {
    const u = Object.assign({}, policyApp, update);
    setPolicyApp(u);
  }

  function hideLeasePrompt(e: FormEvent) {
    e.preventDefault();
    setShowPrompt(false);
  }

  function mobileMap() {
    const assets = useAssetContext();
    const mapProps = invitation.result?.map_props || policyApplication.result?.map_props;

    if (mapProps) {
      const mapWithOverlayProps = {
        ...mapProps,
        wrapperId: 'mobile-card',
        assets: assets.map
      };

      return (
        <div className="d-md-none">
          <MapWithOverlay {...mapWithOverlayProps} />
        </div>
      );
    }
  }

  return (
    <div>
      {showPrompt && (
        <div className="updated-lease-prompt" style={styles.viewPrompt}>
          <div className="title" style={styles.title}>
            <h2 className="bold header">Your leasing information has been updated, let's get that sorted out</h2>
          </div>
          <div className="body" style={styles.body}>
            Please review and accept your updated policy details before continuing.
          </div>
          <div style={styles.buttonWrapper}>
            <button data-cy="reviewChanges" className="btn-default" onClick={hideLeasePrompt} style={styles.button}>
              Review Changes
            </button>
          </div>
        </div>
      )}
      <div>
        <h2 data-cy="leaseTermHeader" className="bold header">
          Review your updated lease information
        </h2>
        <form className="sign-up__form" id="lease-dates-form" onSubmit={onSubmit}>
          <div className="input-holder">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Input
                  type="date"
                  resource="policy_application"
                  field="lease_start_date"
                  maxDate={maxStartDate}
                  datePicker={startDatePickerProps}
                  value={policyApp.lease_start_date}
                  disabled={!!invitation.result?.start_date}
                  onChangeDate={(lease_start_date) => updatePolicyApplication({ lease_start_date })}
                />
                {renderError('start_date', props.policyApplication, 'Start date')}
              </div>
              <div className="col-md-6 col-sm-12">
                <Input
                  type="date"
                  resource="policy_application"
                  field="lease_end_date"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  datePicker={endDatePickerProps}
                  value={policyApp.lease_end_date}
                  selected={policyApp.lease_end_date}
                  disabled={!!invitation.result?.end_date}
                  onChangeDate={(lease_end_date) => updatePolicyApplication({ lease_end_date })}
                />
                {renderError('end_date', props.policyApplication, 'End date')}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  value={policyApp?.monthly_rent}
                  resource="policy_application"
                  field="monthly_rent"
                  type="currency"
                  placeholder="Rent amount"
                  dataCy="rent"
                  disabled={!!invitation.result?.monthly_rent}
                  onChangeNumber={(monthly_rent) => updatePolicyApplication({ monthly_rent })}
                />
                {renderError('monthly_rent', props.policyApplication, 'Monthly rent')}
              </div>
            </div>
          </div>

          {mobileMap()}

          <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <BackButton step={props.step} />
            <input type="submit" className="btn-default" value="Continue" />
          </div>
        </form>
      </div>
    </div>
  );
}

const styles = {
  viewPrompt: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  title: {
    textAlign: 'center' as 'center',
    width: '50%',
    margin: 'auto',
    marginTop: '20%'
  },
  body: {
    textAlign: 'center' as 'center',
    width: '50%',
    margin: 'auto',
    marginBottom: '38px'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    right: 'auto',
    bottom: 'auto',
    width: '228px'
  }
};
