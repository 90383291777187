import React, { SyntheticEvent, useEffect, useState } from 'react';

import AddressStep from 'components/v2/address';
import { useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import ErrorText from 'components/common/form/error-text/error-text';
import { useAxiosDispatcher, validInvitations } from '../api';

const propertyError = 'Whoops! Make sure to select your address from the list';
const requiresInvitationError = 'Signing up for Rhino at this property requires an invitation';

function NotThereYet(props: IChildProps) {
  const dispatchAPI = useAxiosDispatcher();
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'address_line_one',
    'address_state',
    'property_id',
    'address_line_two',
    'user_id',
    'full_address',
    'google_place_id',
    'unit_id',
    'policy_app_property_info'
  ]);

  const [error, setError] = useState<string>('');
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false);

  const updateProperty = (property, unit) => {
    const address_line_one = property.address_line_one;
    const address_state = property.address_state;
    const property_id = property.property_id;
    const google_place_id = property.id;
    const policy_app_property_info = property;
    const address_line_two = unit.name;
    const unit_id = unit.id;
    const update = {
      address_line_one,
      address_line_two,
      address_state,
      property_id,
      google_place_id,
      unit_id,
      policy_app_property_info
    };
    setError('');
    updatePolicyApplication(update);
  };

  async function withInFlight<T>(thunk: () => Promise<T>): Promise<T> {
    setRequestInFlight(true);
    const x = await thunk();
    setRequestInFlight(false);
    return x;
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (requestInFlight) return;

    const propertyId = policyApplication.property_id;
    const propertyInfoPresent =
      policyApplication.address_line_one || policyApplication.google_place_id || propertyId;
    if (!propertyInfoPresent) {
      return setError(propertyError);
    }

    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    const userEmail = props.user.result?.email;

    if (policyApplication.policy_app_property_info?.owner_requires_invitation) {
      if (userEmail && propertyId) {
        const userHasInvitation = await withInFlight(() => dispatchAPI(validInvitations)(userEmail, propertyId));
        if (!userHasInvitation) {
          return setError(requiresInvitationError);
        }
      } else {
        return setError(requiresInvitationError);
      }
    }

    if (policyApplication && policyApplicationId && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  return (
    <div>
      <div>
        <h2 className="bold header">We'll be there soon</h2>
        <p className="p2">
          This property doesn't offer Rhino just yet, but we're working to create a world where every building does.
        </p>
        <p className="p2">Check if another building you want to live in is partnered with us.</p>
        <form className="sign-up__form" id="not-there-yet-form" onSubmit={onSubmit}>
          <div className="form-holder">
            <div className="form" style={styles.formHolder}>
              <div className="input-holder" style={styles.inputContainer}>
                <AddressStep
                  user_id={policyApplication.user_id}
                  buttonText="Search another address"
                  useForm={false}
                  url=""
                  csrf_token=""
                  values={{
                    address_line_one: policyApplication.address_line_one,
                    address_line_two: policyApplication.address_line_two
                  }}
                  full_address={policyApplication.full_address ? policyApplication.full_address : ''}
                  hideLabels={true}
                  mapEnable={false}
                  onPropertyChange={(property, unit) => updateProperty(property, unit)}
                />
              </div>
              {error && <ErrorText text={error} />}
              <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
                <input
                  type="submit"
                  className="btn-default transparent-button transparent-button--wide"
                  value="Search another address"
                  data-cy="SearchAnother"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  }
};

export default NotThereYet;
