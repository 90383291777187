import { isEmpty } from 'lodash';
import React, { Component, FormEvent, KeyboardEvent, useState } from 'react';

import ErrorText from 'components/common/form/error-text/error-text';
import Input from 'components/common/form/input';
import Submit from 'components/common/form/submit';
import { IValues } from 'components/v2/address';
import V2AddressStep from 'components/v2/address';
import store from '../v2/signup/redux/store';
import {
  IResult,
  createUserAndPolicyApplicationSync,
  createPolicyApplicationSync,
  policyApplicationSelector,
  userSelector
} from 'components/v2/signup/redux/actions';
import { User } from 'components/v2/signup/redux/state';
import { coerceUndefined, renderError } from 'utils/redux_helpers';
import { IProperty } from 'types/algolia/property';
import { IUnit } from 'types/algolia/unit';
import { csrfToken } from 'utils/document';
import {
  createUserAndPolicyApplication,
  createPolicyApplication,
  useAxiosDispatcher,
  validInvitations
} from 'components/v2/signup/api';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { fieldWithPrefix } from 'utils/form';
import { queryClient } from 'components/v2/signup';
import RequiresInvitationModal from 'components/v2/signup/components/requires_invitation_modal';
import { RhinoModal } from '@sayrhino/rhino-shared-js';
import Loader from '../v2/loader'

export interface IState {
  email: string;
  role: string;
  error: string;
  success: string;
  unitCount: string;
  property: Partial<IProperty>;
  unit: Partial<IUnit>;
}

export interface IProps {
  submit_text?: string;
  displayOptions: boolean;
  newRenterSignup: boolean;
  whiteButton?: boolean;
  idPrefix?: string;
  dataCy?: string;
  dataCy2?: string;
}

class LandingPageForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      role: 'renter',
      error: '',
      success: '',
      unitCount: '',
      property: {},
      unit: {}
    };
  }

  public render(): JSX.Element {
    return (
      <Provider store={store}>
        <NewRenterSignup submitText={this.props.submit_text} idPrefix={this.props.idPrefix} />
      </Provider>
    );
  }
}

interface INewRenterSignupProps {
  submitText?: string;
  idPrefix?: string;
}

function NewRenterSignup(props: INewRenterSignupProps) {
  const dispatchAPI = useAxiosDispatcher();
  const dispatch = useDispatch();
  const policyApplication = useSelector(policyApplicationSelector);
  const user = useSelector(userSelector);
  const [state, setState] = useState({
    error: undefined as undefined | string,
    userResult: undefined as IResult<User> | undefined,
    email: '',
    requestInFlight: false,
    property: {} as IProperty,
    unit: {} as Partial<IUnit>,
    isLoading: false
  });
  const id = user.result?.id;
  const full_address = policyApplication.result?.full_address;
  const address_values: IValues | undefined = policyApplication.result
    ? {
        address_line_one: policyApplication.result.address_line_one,
        address_line_two: policyApplication.result.address_line_two,
        unit_id: policyApplication.result.unit_id,
        google_place_id: policyApplication.result.google_place_id
      }
    : {};
  const { email, property, unit } = state;

  const onPropertyChange = (p: IProperty, u: IUnit) => {
    setState({ ...state, property: p, unit: u });
  };

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const closeRequiresInvitationModal = () => {
    setModalOpen(false);
  };

  const addressProps = {
    user_id: id,
    csrf_token: csrfToken(),
    url: '/join/address',
    useForm: false,
    full_address,
    values: address_values,
    onPropertyChange,
    buttonText: `Let's get started`,
    namePrefix: props.idPrefix
  };

  const onEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const enterKeyCode = 13;
    if (event.which === enterKeyCode) {
      event.stopPropagation();
      onSubmit(event);
    }
  };

  const onEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      email: event.currentTarget.value
    });
  };

  const validate = () => {
    if (isEmpty(property)) {
      return 'Address cannot be empty';
    }

    if (property?.address_line_one === '') {
      return 'Whoops! Make sure to select your address from the list';
    }
  };

  const onSubmit = async (e: FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (state.requestInFlight) {
      return;
    }

    const error = validate();

    if (error) {
      setState({ ...state, error });
      return;
    }

    const userData = { email };
    const policyApp = coerceUndefined({
      address_line_one: property.address_line_one,
      address_state: property.address_state,
      property_id: property.property_id,
      google_place_id: property.id,
      unit_id: unit.id,
      address_city: unit.address_city,
      address_line_two: unit.address_line_two,
      zipcode: unit.address_zip,
      user_id: id
    });

    function withInFlight<T>(thunk: () => Promise<T>): Promise<T> {
      setState((oldState) => ({
        ...oldState,
        requestInFlight: true
      }));
      return thunk().then((x) => {
        setState((oldState) => ({
          ...oldState,
          requestInFlight: false
        }));
        return x;
      });
    }

    if (property.leasing_integration_renter_url) {
      window.location.href = property.leasing_integration_renter_url;
      return;
    }

    const property_id = property.property_id;
    if (property_id && property.owner_requires_invitation === true) {
      const userHasInvitation = await withInFlight(() => dispatchAPI(validInvitations)(userData.email, property_id));
      if (!userHasInvitation) {
        // open requires invitation modal
        setModalOpen(true);
        return;
      }
    }

    if (id !== undefined) {
      const result = await withInFlight(() => dispatchAPI(createPolicyApplication)(id, policyApp));
      if (result) {
        dispatch(createPolicyApplicationSync(result));
      }
      // @ts-ignore - type overloads mismatched in setstate. out of scope to fix in this pr
      setState((oldState) => ({
        ...oldState,
        error: '',
        email: '',
        property: {},
        unit: {},
        isLoading: true
      }));
    } else {
      const result = await withInFlight(() => dispatchAPI(createUserAndPolicyApplication)(userData, policyApp));
      if (result.user?.result !== undefined) {
        queryClient.setQueryData(['user'], result.user?.result);
      }
      dispatch(createUserAndPolicyApplicationSync(result));
      setState({
        ...state,
        userResult: result.user,
        error: '',
        email: '',
        property: {},
        unit: {},
        isLoading: true
      });
    }

    // navigate to the home page after submitting the form from a product page.
    if(window.location.pathname.includes('/products/')) {
      window.location.replace('/'); 
    }
  };

  return (
    <div className="flow-step--inner-container sign-up">
      {state.isLoading ? <Loader/> : (
        <>
        <V2AddressStep cyAddress="address" cyUnit="unit" {...addressProps} hideLabels={true} />
        {state.error && <ErrorText text={state.error} />}

        {!id && (
          <div>
            <Input
              data-cy="email"
              placeholder="Email"
              aria-label="Email"
              className="landing-page--input"
              id={`${fieldWithPrefix('default-signup-email-field', props.idPrefix, '-')}`}
              onKeyDown={onEnterPress}
              onChange={onEmailChange}
              value={state.email}
            />
          </div>
        )}
        {state.userResult && renderError('email', state.userResult)}
        <br />
        <Submit
          data-cy="getStarted"
          value={props.submitText || 'Get Started'}
          className="landing-page--button-white"
          onClick={onSubmit}
          disabled={state.requestInFlight}
          id={`${fieldWithPrefix('default-signup-get-started-button', props.idPrefix, '-')}`}
        />
        {isModalOpen && (
          <RhinoModal
            isOpen={isModalOpen}
            onRequestClose={() => closeRequiresInvitationModal()}
            style={{ position: 'fixed', top: 0, left: 0, 
              width: '100%', height: '100%', overflow: 'auto', zIndex: 1000 }}
            contentLabel="Renters Insurance Modal"
          >
            <RequiresInvitationModal
              onCloseModal={() => closeRequiresInvitationModal()}
              property={property}
              unit={unit}
            />
          </RhinoModal>
        )}
        </>
      )}
    </div>
  );
}

export default LandingPageForm;
