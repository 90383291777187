/** @jsx jsx */
import { AlertIcon, CheckIcon, CloseIcon, FONTS } from '@sayrhino/rhino-shared-js';
import React, { useCallback, useEffect, useState, createContext } from 'react';
import { jsx, css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { TOAST_STATUS } from './use-toast';
interface Props {
  position?: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left';
  type?: 'success' | 'warning' | 'error' | 'default' | 'info' | 'failed';
  duration?: number;
  children: JSX.Element | JSX.Element[];
}

interface IToast {
  id: number;
  message: string;
  type: string;
  status: TOAST_STATUS;
}

const initState = {
  addToast: (message: string, type = 'default', status = TOAST_STATUS.SUCCESS) => {
    const newToast: IToast = {
      id: Date.now(),
      message,
      type,
      status
    };
  }
};
const ToastContext = createContext(initState);
export const ToastProvider = ToastContext.Provider;
export default ToastContext;

const toastWrapper = css`
  position: fixed;
  z-index: 999;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const bottomLeft = css`
  bottom: 32px;
  left: 32px;
`;
const bottomRight = css`
  bottom: 32px;
  right: 32px;
`;

const topRight = css`
  top: 32px;
  right: 32px;
`;

const topLeft = css`
  top: 32px;
  left: 32px;
`;

const toast = css`
  max-width: 100vw;
  margin-bottom: 0.5rem;
  height: 56px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid transparent;

  i {
    border-radius: 50%;
    padding: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  p {
    margin: 0;
    padding-left: 8px;
    padding-right: 16px;
    font: ${FONTS.p2Light};
    font-size: 14px;
    color: black;
  }

  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: black;
    border: none;
    display: flex;
    align-items: center;
  }
`;

const toastPosition = (position) => {
  switch (position) {
    case 'bottom-left':
      return bottomLeft;
    case 'bottom-right':
      return bottomRight;
    case 'top-left':
      return topLeft;
    case 'top-right':
      return topRight;
    default:
      return bottomLeft;
  }
};

const types = (type) => {
  switch (type) {
    case 'success':
      // Habitat Greens
      return css`
        background: #E8FCEF;
        border-color: #12873D;
      `;
    case 'failed':
    case 'error':
      // Habitat Reds
      return css`
        background: #FFECE5;
        border-color: #DB3700;
      `;
    case 'neutral_warning':
    case 'warning':
      // Habitat Yellows
      return css`
        background: #FEFEE6;
        border-color: #F8D904;
      `;
    case 'info':
    default:
      // Habitat Blues
      return css`
        background: #F1F4FE;
        border-color: #315BF1;
      `;
  }
};

export const ToastContextProvider = ({ children, position = 'bottom-left', duration = 8000 }: Props) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setInterval(() => setToasts((messages) => messages.slice(1)), duration);
      return () => clearInterval(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    (message, type = 'default', status = TOAST_STATUS.SUCCESS) => {
      const id = Math.random();
      setToasts((messages) => [...messages, { id, message, type, status }]);
    },
    [setToasts]
  );

  const removeToast = (item: IToast) => {
    setToasts((messages) => messages.filter((elem: IToast) => elem !== item));
  };

  const variants = {
    leftClose: { y: '100%', opacity: 0 },
    rightClose: { y: '-100%', opacity: 0 }
  };

  return (
    <ToastProvider value={{ addToast }}>
      {children}
      <div
        css={css`
          ${toastWrapper};
          ${toastPosition(position)};
        `}
      >
        <AnimatePresence>
          {toasts.map((item: IToast, index: number) => (
            <motion.div
              css={css`
                ${toast};
                ${toastPosition(position)};
                ${types(item.type)};
              `}
              initial={position.includes('left') ? 'leftClose' : 'rightClose'}
              animate={{ y: 0, opacity: 1 }}
              transition={{ stiffness: 100 }}
              exit={position.includes('left') ? 'leftClose' : 'rightClose'}
              variants={variants}
              key={`toast-${index}`}
            >
              <i>
                {item.status === TOAST_STATUS.SUCCESS && <CheckIcon height={18} width={18} />}
                {item.status === TOAST_STATUS.ERROR && <AlertIcon height={18} width={18} />}
              </i>
              <p>{item.message}</p>
              <button onClick={() => removeToast(item)}>
                <CloseIcon height={16} width={16} />
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </ToastProvider>
  );
};
