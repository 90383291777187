import React, { useState, type CSSProperties } from 'react';
import { TextInput, HideIcon, ViewIcon } from '@sayrhino/rhino-shared-js';

interface IProps {
  style?: CSSProperties;
  resource: string;
  field?: string;
  toggleVisibility?: boolean;
  placeholder?: string;
  label?: string;
  onPasswordChange?: (value: string) => void;
  onChangeString?: (value: string) => void;
  value?: string;
  dataCy?: string;
}
const PasswordInput = (props: IProps) => {
  const [passwordString, setPasswordString] = useState('');
  const [inputType, setInputType] = useState('password');
  const { resource, toggleVisibility, placeholder, label, field, value, dataCy, style } = props;

  const togglePasswordVisibility = () => {
    const newInput = inputType === 'password' ? 'text' : 'password';
    setInputType(newInput);
  };

  const onChange = (event: { target: { value: string } }) => {
    const newVal = event.target.value;
    setPasswordString(newVal);
    if (props.onPasswordChange) {
      props.onPasswordChange(newVal);
    }
    if (props.onChangeString) {
      props.onChangeString(newVal);
    }
  };

  return (
    <div>
      <TextInput
        name={field ? `${resource}[${field}]` : 'user[password]'}
        id={field ? `${resource}_${field}` : 'user_password'}
        label={label ? label : 'Password'}
        style={style}
        onChange={onChange}
        type={inputType}
        value={typeof value === 'string' ? value : passwordString}
        resource={resource}
        placeholder={placeholder}
        data-cy={dataCy}
        rightIcon={
          toggleVisibility &&
          (inputType === 'password' ? (
            <HideIcon onClick={togglePasswordVisibility} />
          ) : (
            <ViewIcon onClick={togglePasswordVisibility} />
          ))
        }
      />
    </div>
  );
};

export default PasswordInput;
