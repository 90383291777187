import { CACHE_POLICY, UPDATE_POLICY } from '../actions';

export default function insurancePolicy(state = { cache: {}}, action) {
  switch(action.type) {
    case UPDATE_POLICY:
      return { ...state, ...action.policyParams };
    case CACHE_POLICY:
      return { ...state, cache: {...action.policyParams} };
    default:
      return state;
  }
}
