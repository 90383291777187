import React, { Fragment } from 'react';
import { SignupCopy, isICopyQuote } from '../../constants/copy';
// @ts-ignore
import dots from 'components/v2/shared/blob.svg';

function GuildingPanel(props: { step: string; copy: SignupCopy }) {
  const { step, copy } = props;

  let innerPanel;

  if (copy === undefined) {
    innerPanel = <Fragment />;
  } else {
    let textBody = copy;
    let headerText = 'Did you know';
    let quoteSpan = <Fragment />;
    let quoteAuthor = <Fragment />;

    if (isICopyQuote(copy)) {
      textBody = copy.quote_text;
      headerText = copy.headertext || 'Renters Love Rhino';
      quoteSpan = copy.quoteSpan || <span style={styles.quote}>“</span>;
      quoteAuthor = <div style={styles.from}>{copy.quote_author}</div>;
    }

    innerPanel = (
      <div id="did_you_know__inner" style={styles.inner}>
        <div style={styles.header}>{headerText}</div>
        {quoteSpan}
        <div style={styles.text}>{textBody}</div>
        {quoteAuthor}
      </div>
    );
  }

  return (
    <div style={styles.container} id="did_you_know">
      <div className="d-none d-lg-block" style={styles.dots}>
        <img src={dots} />
      </div>
      {innerPanel}
    </div>
  );
}

const styles = {
  container: {
    background: '#F5F4FB',
    borderRadius: '8px',
    display: 'flex' as 'flex'
  },
  inner: {
    alignSelf: 'flex-end',
    zIndex: 1
  },
  header: {
    fontFamily: 'MaisonNeueExtendedDemi',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#6318CE',
    paddingBottom: '16px'
  },
  text: {
    fontFamily: 'MaisonNeueExtendedLight',
    fontSize: '14px',
    lineHeight: '24px'
  },
  from: {
    marginTop: '16px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#6318CE'
  },
  quote: {
    fontFamily: 'MaisonNeueExtendedBold',
    fontSize: '40px',
    lineHeight: '52px',
    color: '#6318CE'
  },
  dots: {
    position: 'absolute' as 'absolute',
    top: '0',
    margin: '0 0 8px -30px'
  }
};

export default GuildingPanel;
