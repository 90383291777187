import moment from 'moment';
import React from 'react';
import ActionCard from './ActionCard';

interface Props {
  sdiEligibleForRenewalList: {
    eligible_for_renewal: boolean;
    coverage_end_date: string;
    upfront: boolean;
    pending_change_request: boolean;
    status: string;
    policy_id: number;
    lease_id: number;
  }[];
  cancelRenewal: (policyId: number, LeaseId: number) => void;
  openRenewalModal: (policyId: number) => void;
}

export const AutoRenewCard = (props: Props) => {
  return (
    <>
      {props.sdiEligibleForRenewalList.map((eligibleAddress) => {
        const autoRenewalDate = moment(eligibleAddress.coverage_end_date).subtract(15, 'days').format('MMMM D, YYYY');
        return (
          <ActionCard
            cancelRenewal={() => {
              props.cancelRenewal(eligibleAddress.policy_id, eligibleAddress.lease_id);
            }}
            handleClick={() => {
              props.openRenewalModal(eligibleAddress.policy_id);
            }}
            header={`Your policy will auto-renew on ${autoRenewalDate}`}
            text={'View your new policy terms, renew early, or cancel your upcoming policy'}
            ctaText={'Renew Now'}
            label={'Recommended'}
            destructive={false}
            autoRenew={true}
            callToActionId={'auto-renew-card'}
            key={eligibleAddress.policy_id}
          />
        );
      })}
    </>
  );
};
