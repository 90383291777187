import { UPDATE_CAN_CHANGE_PAYMENT_METHOD } from '../actions';

export default function canChangePaymentMethod(state = false, action) {
  switch(action.type) {
    case UPDATE_CAN_CHANGE_PAYMENT_METHOD:
      return action.value;
    default:
      return state;
  }
}
