import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, Button } from '@sayrhino/rhino-shared-js';
import { IChildProps } from './step_wrapper';
import protection from 'components/v2/shared/protection.svg';
import coins from 'components/v2/shared/coins.svg';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { BackButton, useSubsetState } from '../step_helpers';
import { formatCents } from 'utils/money/formatter';
import { useDispatch } from 'react-redux';
import { useAxiosDispatcher, checkInvitation, calculatePrice } from '../api';
import { checkInvitationSync, updatePolicyApplicationSync } from '../redux/actions';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import Declined from './declined';
import { CASH_DEPOSIT, SECURITY_DEPOSIT_INSURANCE } from '../redux/state';

const StyledH3 = styled.h3({
  fontFamily: 'GTSectraBold',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '48px',
  '@media (max-width: 480px)': {
    fontFamily: 'GTSectraBold',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '40px'
  }
});

const Header = styled.h3([
  FONTS.h3,
  {
    color: PALETTE.neutralDark,
    fontWeight: 600,
    marginTop: '8px'
  }
]);

const LoadingContainer = styled.div([
  {
    width: '100%',
    position: 'relative',
    top: '28vh',
    left: 0,
    background: 'white'
  }
]);

const MessageContainer = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center'
  }
]);

const progressBar = (bgcolor: string, completedProgressPercent: number) => {
  const StyledDiv = styled.div({
    display: 'flex'
  });

  const StyledFlex = styled.div({
    display: 'flex',
    justifyContent: 'center'
  });

  const StyledContainer = styled.div({
    height: '8px',
    width: '295px',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    marginTop: '24px'
  });

  const StyledFiller = styled.div({
    height: '100%',
    width: `${completedProgressPercent}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  });

  return (
    <StyledDiv>
      <StyledFlex>
        <StyledContainer>
          <StyledFiller></StyledFiller>
        </StyledContainer>
      </StyledFlex>
    </StyledDiv>
  );
};

const PersonalizedQuoteAmount = styled.span([FONTS.h3], {
  color: PALETTE.brand100,
  '@media screen and (max-width: 480px)': {
    fontSize: '16px'
  }
});
const PersonalizedQuoteAmountPerMonth = styled.span([FONTS.h5], {
  color: PALETTE.brand100
});
const Banner = styled.div([FONTS.p3Medium], {
  color: PALETTE.neutral65,
  textAlign: 'left',
  '@media screen and (max-width: 480px)': {
    fontSize: '12px'
  }
});
const TopBanner = styled.div([FONTS.p1], {
  color: PALETTE.neutral65,
  '@media screen and (max-width: 480px)': {
    fontSize: '14px'
  }
});

const ImageWrapper = styled.img({
  marginRight: 13,
  width: 57,
  height: 43,
  '@media screen and (max-width: 480px)': {
    width: '40px',
    height: '37px',
    marginRight: 0
  }
});

const Card = styled.div({
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral12}`,
  boxSizing: 'border-box',
  borderRadius: 8,
  padding: '24px',
  width: '480px',
  height: 'auto',
  maxHeight: '217px',
  margin: '0px auto',
  marginTop: '20px',
  textAlign: 'left',
  '@media screen and (max-width: 480px)': {
    maxWidth: '100%',
    marginTop: '20px',
    padding: '20px'
  }
});

const Container = styled.div({
  '&&': {
    '@media (min-width: 390px)': {
      display: 'flex',
      alignItems: 'baseline'
    },
    '@media screen and (min-width: 480px)': {
      fontSize: '24px',
      lineHeight: '0px'
    }
  }
});

const FlexDiv = styled.div({
  display: 'flex'
});

const PolicyPeriod = styled.span([FONTS.p3Light], {
  justifyContent: 'flex-start'
});

const PaidInFullText = styled.span([FONTS.p3Light], {
  paddingLeft: '10px'
});

const signUpNewPolicyBtn = {
  borderRadius: '4px',
  padding: '4px 6px 4px 6px',
  marginTop: '10px',
  height: '24px',
  width: '100px',
  color: '#7D53F7',
  borderColor: '#DACEFC',
  fontSize: '10px',
  backgroundColor: '#F9F7FE',
  letterSpacing: '0.02rem'
};

const RadioStyle = {
  width: '24px',
  height: '24px',
  accentColor: '#7D53F7'
};

const labelStyle = {
  marginLeft: '8px',
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 600,
  marginBottom: '0px'
};

const StyledColumn = styled.div({
  position: 'relative',
  textAlign: 'center' as 'center',
  width: '100%'
});

const StyledP4 = styled.p({});

const buttonStyles = {
  paddingTop: '56px',
  paddingLeft: '30px'
};

const radioFlex = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const cardBody = {
  marginLeft: '39px',
  '@media (max-width: 480px)': {
    marginLeft: '29px'
  }
};

const styles = {
  activeCard: {
    border: '1px solid #7D53F7'
  },
  checkbox: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px'
  },
  loaderSection: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  row: {
    minHeight: '700px'
  },
  header: {
    fontSize: '60px',
    lineHeight: '68px'
  },
  submit: {
    margin: '0 auto'
  },
  processingTime: {
    color: '#17B64B'
  },
  payTypeParagraph: {
    color: '#5A6468',
    fontSize: 14,
    marginBottom: 0
  },
  totalDue: {
    color: '#6A3BF5',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.4
  },
  paymentSelectCard: {
    textAlign: 'left'
  }
};

function ProductsOffered(props: IChildProps) {
  const isMobile = useCheckMobile();
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'cash_deposit_amount_cents',
    'cash_deposit_status',
    'selected_product'
  ]);
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const result = props.policyApplication.result;
  const property_cash_deposit_enabled = result?.property_cash_deposit_enabled;
  const property = result?.full_address;
  const lease_dates = result?.formatted_coverage_dates;
  const sdiCoverageFields = result?.price_info;
  const cashDepositOnly = result?.cash_deposit_only;
  const partialQuoteDepositAmount = result?.cash_deposit_amount_cents ? result?.cash_deposit_amount_cents : 0;
  const cashDepositAmount = formatCents(partialQuoteDepositAmount);
  const cashDepositSelected = result?.selected_product === CASH_DEPOSIT;
  const [depositInsurance, setDepositInsurance] = useState(!cashDepositSelected);
  const [securityDeposit, setSecurityDeposit] = useState(cashDepositSelected);
  const userId = props.user.result?.id;
  const policyApplicationId = props.policyApplication.result?.id;
  const currentCashDepositStatus = props.policyApplication.result?.cash_deposit_status;
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const dispatch = useDispatch();
  const dispatchAxios = useAxiosDispatcher();
  const buttonDivClassName = 'sign-up__actions d-flex justify-content-between align-items-center action_buttons';

  const [priceLoading, setPriceLoading] = useState(true);
  const [isDeclined, setIsDeclined] = useState(false);
  const [completedProgress, setCompletedProgress] = useState(0);
  const [isDeclinedFromMonthly, setIsDeclinedFromMonthly] = useState(false);

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (policyApplicationId && userId && policyApplication) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const onDepositMethod = (e) => {
    let product_clicked = 'cash';

    if (e.target.value === 'SecurityDeposit') {
      setSecurityDeposit(true);
      setDepositInsurance(false);
      updatePolicyApplication({
        ...(currentCashDepositStatus !== null && { cash_deposit_status: 1 }),
        cash_deposit_amount_cents: partialQuoteDepositAmount,
        selected_product: CASH_DEPOSIT
      });
    } else {
      setSecurityDeposit(false);
      setDepositInsurance(true);
      updatePolicyApplication({
        cash_deposit_status: null,
        cash_deposit_amount_cents: partialQuoteDepositAmount,
        selected_product: SECURITY_DEPOSIT_INSURANCE
      });
      product_clicked = 'sdi';
    }
  };

  useEffect(() => {
    if (userId && policyApplicationId) {
      setSubmitEnabled(false);
      prepareQuoteDisplay(userId, policyApplicationId);
    }
  }, [userId, policyApplicationId]);

  const getRandomProgressInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const setCashDepositSelected = () => {
    setSecurityDeposit(true);
    setDepositInsurance(false);
    updatePolicyApplication({
      ...(currentCashDepositStatus !== null && { cash_deposit_status: 1 }),
      cash_deposit_amount_cents: partialQuoteDepositAmount,
      selected_product: CASH_DEPOSIT
    });
  };

  async function prepareQuoteDisplay(userId, policyApplicationId) {
    setCompletedProgress(getRandomProgressInt(25, 80));
    if (props.policyApplication.result?.cash_deposit_only) {
      setCashDepositSelected();
      const invitation = await dispatchAxios(checkInvitation)(userId, policyApplicationId);
      if (invitation.result) {
        dispatch(checkInvitationSync(invitation));
      } else {
        setSubmitEnabled(true);
      }
      setPriceLoading(false);
      setCompletedProgress(100);
    } else {
      const policyApp = await dispatchAxios(calculatePrice)({
        userId,
        policyApplicationId,
        upfront: false,
        productType: cashDepositSelected ? CASH_DEPOSIT : SECURITY_DEPOSIT_INSURANCE
      });
      const declined = policyApp.result?.declined_from_subscription;
      const declinedFromMonthly = policyApp.result?.disqualified_from_monthly;
      dispatch(updatePolicyApplicationSync(policyApp));
      if (policyApp.result) {
        if (declined) setIsDeclined(true);
        if (declinedFromMonthly) setIsDeclinedFromMonthly(true);
        if (declined && cashDepositAmount) setCashDepositSelected();

        const invitation = await dispatchAxios(checkInvitation)(userId, policyApplicationId);
        if (invitation.result) {
          dispatch(checkInvitationSync(invitation));
        } else {
          setSubmitEnabled(true);
        }
      }
      setPriceLoading(false);
      setCompletedProgress(100);
    }
  }

  const wrapperClass = 'row justify-content-center align-items-center align-items-sm-start';

  return (
    <div>
      {priceLoading ? (
        <div className="container">
          <div className={wrapperClass}>
            <StyledColumn>
              <LoadingContainer>
                <MessageContainer>
                  <Header>Analyzing your information...</Header>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {progressBar('#6B3BF6', completedProgress)}
                  </div>
                </MessageContainer>
              </LoadingContainer>
            </StyledColumn>
          </div>
        </div>
      ) : (
        {
          true: (
            <div className="container">
              <div className={wrapperClass}>
                <StyledColumn>
                  <Declined />
                </StyledColumn>
              </div>
            </div>
          ),
          false: (
            <div className="container">
              <div className={wrapperClass} style={styles.row}>
                <StyledColumn>
                  <form id="products-offered-form" onSubmit={onSubmit}>
                    {partialQuoteDepositAmount && !isDeclined && !result?.cash_deposit_only ? (
                      <StyledH3>Your security deposit {isMobile ? <br /> : null}options</StyledH3>
                    ) : (
                      <StyledH3>Your security deposit {isMobile ? <br /> : null}option</StyledH3>
                    )}
                    <TopBanner>
                      {property}
                      <br />
                      {lease_dates}
                    </TopBanner>

                    {!isDeclined && sdiCoverageFields && !cashDepositOnly && (
                      <Card style={depositInsurance ? styles.activeCard : {}}>
                        <label htmlFor="DepositInsurance">
                          <div style={radioFlex}>
                            <div style={radioFlex}>
                              <input
                                type="radio"
                                checked={depositInsurance}
                                name={'DepositInsurance'}
                                id="DepositInsurance"
                                readOnly={true}
                                style={RadioStyle}
                                className="radioBorder"
                                value={'DepositInsurance'}
                                onChange={onDepositMethod}
                              />
                              <span style={labelStyle}>Rhino Deposit Insurance</span>
                            </div>
                            {!isMobile && <ImageWrapper src={protection} />}
                          </div>
                          <div style={cardBody}>
                            {isDeclinedFromMonthly && (
                              <div>
                                <Container>
                                  <PersonalizedQuoteAmount id="payment-option" data-cy="quotePrice">
                                    {sdiCoverageFields?.upfront}
                                    <PaidInFullText>(Paid in full)</PaidInFullText>
                                  </PersonalizedQuoteAmount>
                                </Container>
                                <FlexDiv></FlexDiv>
                              </div>
                            )}
                            {!isDeclinedFromMonthly && (
                              <div>
                                <Container>
                                  <PersonalizedQuoteAmount id="payment-option" data-cy="quotePrice">
                                    {sdiCoverageFields?.monthly}
                                  </PersonalizedQuoteAmount>
                                  <PersonalizedQuoteAmountPerMonth>/mo</PersonalizedQuoteAmountPerMonth>
                                </Container>
                                <FlexDiv>
                                  <PolicyPeriod id="price-period">
                                    (Or {sdiCoverageFields?.upfront} paid in full)
                                  </PolicyPeriod>
                                </FlexDiv>
                              </div>
                            )}
                            <Banner>
                              Protects your landlord from damage or lost rent, the same way a security deposit does
                            </Banner>
                            <Button
                              data-cy="newPolicySignUp"
                              className="sign-up-new-policy"
                              style={signUpNewPolicyBtn}
                              variant="tertiary"
                              tabIndex={-1}
                            >
                              RECOMMENDED
                            </Button>
                          </div>
                        </label>
                      </Card>
                    )}
                    {partialQuoteDepositAmount > 0 && property_cash_deposit_enabled && (
                      <Card style={securityDeposit ? styles.activeCard : {}}>
                        <label htmlFor="SecurityDeposit">
                          <div style={radioFlex}>
                            <div style={radioFlex}>
                              <input
                                type="radio"
                                checked={securityDeposit}
                                name={'SecurityDeposit'}
                                id="SecurityDeposit"
                                readOnly={true}
                                style={RadioStyle}
                                className="radioBorder"
                                value={'SecurityDeposit'}
                                onChange={onDepositMethod}
                              />
                              <span style={labelStyle}>Security Deposit</span>
                            </div>
                            {!isMobile && <ImageWrapper src={coins} />}
                          </div>
                          <div style={cardBody}>
                            <Container>
                              <PersonalizedQuoteAmount id="payment-option" data-cy="quotePrice">
                                {cashDepositAmount}
                              </PersonalizedQuoteAmount>
                              <PersonalizedQuoteAmountPerMonth></PersonalizedQuoteAmountPerMonth>
                            </Container>
                            <FlexDiv>
                              <PolicyPeriod id="price-period"> </PolicyPeriod>
                            </FlexDiv>
                            <Banner>
                              Pay the full security deposit amount required by your landlord today using bank transfer
                              or credit card
                            </Banner>
                          </div>
                        </label>
                      </Card>
                    )}

                    <br />
                    <div className={buttonDivClassName} style={buttonStyles}>
                      <div>
                        <BackButton step={props.step} />
                      </div>
                      <input
                        type="submit"
                        className="btn-default"
                        data-cy="continue"
                        value="Continue"
                      />
                    </div>
                  </form>
                </StyledColumn>
              </div>
            </div>
          )
        }[(isDeclined && !(partialQuoteDepositAmount > 0 && property_cash_deposit_enabled)).toString()]
      )}
    </div>
  );
}

export default ProductsOffered;
