import React, { Component } from 'react';
import { Provider } from 'react-redux';

import InsurancePolicyDashboard from './policy_details';
import configureStore from './store/configure_store';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

class InsurancePolicyProvider extends Component {
  public _store;

  store = () => {
    if (!this._store) {
      this._store = configureStore(this.props);
    }

    return this._store;
  };

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={this.store()}>
          <InsurancePolicyDashboard />
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default InsurancePolicyProvider;
