import React from 'react';
import styled from '@emotion/styled';
import Loader from '../../../loader';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { CloseIcon } from '@sayrhino/rhino-shared-js';
import { Button, ROUNDNESS } from '@sayrhino/rhino-shared-js';

interface ConfirmationCardProps {
  onCloseClick: () => void;
  headerText: string;
  reloading: boolean;
  subheaderText?: string;
  ctaText: string;
  onCTAClick: () => void;
}

const StyledCard = styled.div`
  position: relative;
  border: 1px solid ${PALETTE.neutral4};
  position: relative;
  background-color: white;
  border-radius: 6px;
  padding-top: 0px;
  border: none;
  margin-top: 30px;
  padding: 32px 64px 48px;
`;

const StyledCloseIconContainer = styled.div({
  position: 'absolute',
  right: '10%',
  top: '9%'
});

const StyledHeader = styled.h3(FONTS.h3, {
  marginBottom: 0,
  paddingTop: '60px',
  textAlign: 'center'
});

const StyledSubHeader = styled.p(FONTS.p1Light, {
  marginBottom: 0,
  textAlign: 'center',
  paddingTop: '15px'
});

const StyledButtonWrap = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '0px 40px',
  paddingTop: '30px',
  paddingBottom: '30px'
});

const StyledCTAButton = styled(Button)([
  ROUNDNESS.round,
  FONTS.p3Medium,
  {
    background: PALETTE.brand100,
    margin: '0 auto',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '14px',
    padding: '20px 21px',
    border: 'none',
    color: PALETTE.neutralLight
  }
]);

const StyledLoader = styled(Loader)`
  padding: 10px 0px;
`;

const ConfirmationCard = ({
  onCloseClick,
  headerText,
  reloading,
  subheaderText,
  ctaText,
  onCTAClick
}: ConfirmationCardProps) => {
  return (
    <StyledCard>
      <StyledCloseIconContainer onClick={onCloseClick}>
        <CloseIcon className={'cursor'} data-cy="modalCancel" />
      </StyledCloseIconContainer>
      <StyledHeader>{headerText}</StyledHeader>
      {reloading ? <StyledLoader /> : null}
      {subheaderText ? <StyledSubHeader> {subheaderText} </StyledSubHeader> : null}
      <StyledButtonWrap>
        <StyledCTAButton css={ROUNDNESS.round} onClick={onCTAClick}>
          {ctaText}
        </StyledCTAButton>
      </StyledButtonWrap>
    </StyledCard>
  );
};

export default ConfirmationCard;
