import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/v2/button';
import { IPolicy, IChangeRequest } from '../policyProps';
import { activeClasses } from 'components/v2/quote_display/utils';

interface IProps {
  upfront: boolean;
  insurancePolicy: IPolicy;
  changeRequest: IChangeRequest;
  onSave: () => void;
  onChange: (upfront: boolean) => void;
}

const buttonText = 'Save and update';

const PaymentOptions: React.FC<IProps> = (props: IProps) => {
  const { upfront, onSave, onChange, changeRequest, insurancePolicy } = props;
  const priceInfo = {
    ...(changeRequest.price_info || insurancePolicy.price_info),
    upfront_discount: insurancePolicy.price_info.upfront_discount
  };

  return (
    <div className="update-payment-method">
      <div className="header border-bottom">
        <h6 className="demi-18">Your Policy</h6>
      </div>

      <div className="payment-container">
        <div
          onClick={() => onChange(true)}
          data-cy="priceFull"
          className={activeClasses(upfront, 'payment-option')}
          id="payment-option-annual"
        >
          <div className="header">
            <p className="description">In full</p>
            <div className={activeClasses(upfront, 'checkbox')} />
          </div>
          <h3 className="bold">{priceInfo.upfront}</h3>
          <p className="p3">Pay for your policy in full and don’t worry about it for the rest of your lease.</p>
          {priceInfo.upfront_discount && (
            <span className="text-2 medium">Get a {priceInfo.upfront_discount} discount</span>
          )}
        </div>

        <div
          onClick={() => onChange(false)}
          data-cy="priceMonthly"
          className={activeClasses(!upfront, 'payment-option')}
          id="payment-option-monthly"
        >
          <div className="header">
            <p className="description">Per month</p>
            <div className={activeClasses(!upfront, 'checkbox')} />
          </div>
          <h3 className="bold">{priceInfo.monthly}</h3>
          <p className="p3">We’re flexible. Renters can pay for their Rhino policy on a monthly basis.</p>
        </div>
      </div>

      <Button dataCy={'saveAndUpdate'} text={buttonText} onClick={onSave} />
    </div>
  );
};

const mapStateToProps = ({ changeRequest, insurancePolicy }) => ({ changeRequest, insurancePolicy });

export default connect(mapStateToProps)(PaymentOptions);
