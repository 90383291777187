import React from 'react';
import { Col, InfoDesktop, InfoMobile, LabelContainer, LabelDesktop, LabelMobile, Row } from './styles';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { formatDate } from './utils';
import { RentersInsurancePolicyProps } from '../renters_insurance/interfaces';

interface IRenderPolicyDetailsProps {
  address: {
    street_address: string;
    city: string;
    state: string;
    zip: string;
    unit?: string;
  };
  addressForRI?: string;
  lease: {
    start_date?: string;
    end_date?: string;
  };
  inAccount: boolean;
  rentersInsurancePolicy?: RentersInsurancePolicyProps;
}

export function RenderPolicyDetails(props: IRenderPolicyDetailsProps) {
  const isMobile = useCheckMobile();
  const { address, lease, addressForRI } = props;

  const policyAddress = addressForRI
    ? addressForRI
    : `${address.street_address}, ${address.unit ? address.unit + ',' : ''} ${address.city}, ${address.state} ${
        address.zip
      }`;

  const leaseTerm =
    lease.start_date && lease.end_date && `${formatDate(lease.start_date)} \u2013 ${formatDate(lease.end_date)}`;

  if (isMobile || props.inAccount) {
    return (
      <>
        <Col>
          <LabelContainer>
            <LabelMobile>{props.inAccount ? 'Policy Address' : 'Address'}</LabelMobile>
            <InfoMobile>{policyAddress}</InfoMobile>
            <LabelMobile>Lease term</LabelMobile>
            <InfoMobile>{leaseTerm}</InfoMobile>
          </LabelContainer>
        </Col>
      </>
    );
  } else {
    return (
      <Row>
        <LabelContainer>
          <LabelDesktop>Address</LabelDesktop>
          <LabelDesktop>Lease term</LabelDesktop>
        </LabelContainer>
        <Col>
          <InfoDesktop>{policyAddress}</InfoDesktop>
          <InfoDesktop>{leaseTerm}</InfoDesktop>
        </Col>
      </Row>
    );
  }
}
