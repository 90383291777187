import React, { Component } from 'react';
import Events from 'minivents';

import Backdrop from './backdrop';
import Slider from './slider';

import Button from 'components/v2/button';

import { ButtonHabitat } from '@sayrhino/rhino-shared-js';

import styled from '@emotion/styled';
interface IProps {
  userId?: number;
  ctaText?: string;
  ctaId?: string;
  renderContent: () => JSX.Element;
  buttonClass?: string;
  openButtonClass?: string;
  buttonBackground?: string;
  confirmExit?: boolean;
  onClose?: () => void;
  dataCy?: string;
}

interface IDrawerState {
  isOpen: boolean;
  onConfirmExitStage: boolean;
}

const events = new Events();

const openDrawerFromEmail = window.location.href.split('&').includes('drawer=true');

const StyledButton = styled(ButtonHabitat)({});

class Drawer extends Component<IProps, IDrawerState> {
  static events = events;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: openDrawerFromEmail || false,
      onConfirmExitStage: false
    };

    this.close = this.close.bind(this);
  }

  componentDidMount = () => {
    events.on('drawer:close', () => {
      this.close();
    });
  };

  componentWillUnmount = () => {
    events.off('drawer:close');
  };

  public close() {
    const { confirmExit, onClose } = this.props;
    const { onConfirmExitStage } = this.state;
    if (confirmExit && !onConfirmExitStage) {
      this.setState({ isOpen: true, onConfirmExitStage: true });
    } else {
      document.body.style.overflow = 'auto';
      this.setState({ isOpen: false, onConfirmExitStage: false });
      if (onClose) {
        onClose();
      }
    }
  }

  public open = () => {
    document.body.style.overflow = 'hidden';
    this.setState({ isOpen: !this.state.isOpen });
  };

  public generateButtonClass = () => {
    const { buttonClass, openButtonClass } = this.props;

    if (this.state.isOpen && openButtonClass) {
      return [openButtonClass, 'drawer-button'].join(' ');
    } else if (!this.state.isOpen && buttonClass) {
      return [buttonClass, 'drawer-button'].join(' ');
    } else if (this.props.ctaText === 'Change Payment Frequency' || this.props.ctaText === 'View Payment History') {
      return;
    } else {
      return 'drawer-button';
    }
  };

  public back = () => {
    this.setState({ isOpen: true, onConfirmExitStage: false });
  };

  public renderConfirmExitContent = () => {
    return (
      <div className="drawer-confirm-exit">
        <div className="header">You have unsaved changes, are you sure you want to exit?</div>

        <div className="description">
          Updates are not made until you’ve hit save. Exiting now means you’ll lose your unsaved changes
        </div>

        <div className="actions">
          <a className="back-link" onClick={this.back}>
            Back
          </a>
          <Button className="exit-button" text="Yes, exit" onClick={this.close} />
        </div>
      </div>
    );
  };

  public render() {
    const { renderContent } = this.props;
    const { onConfirmExitStage } = this.state;
    const content = onConfirmExitStage ? this.renderConfirmExitContent : renderContent;

    return (
      <div className="drawer">
        <Slider
          show={this.state.isOpen}
          close={this.close}
          renderContent={content}
          onConfirmExitStage={onConfirmExitStage}
          buttonBackground={this.props.buttonBackground || ''}
        />

        {this.state.isOpen && <Backdrop close={this.close} />}

        {this.props.ctaText ? (
          <StyledButton
            variant="black"
            size="small"
            usage="secondary"
            onClick={this.open}
            className={this.generateButtonClass()}
            id={'id'}
            data-cy={this.props.dataCy}
          >
            {this.props.ctaText}
          </StyledButton>
        ) : (
          <div onClick={this.open}>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default Drawer;
