import React, { Component } from 'react';
import shortid from 'shortid';
import FlashMessage from './flash_message';
import Events from 'minivents';

const events = new Events();

interface IMessage {
  key: string;
  type: string;
  text: string;
}

interface IState {
  messages: IMessage[];
}

class FlashContainer extends Component<{}, IState> {
  static events = events;

  constructor(props) {
    super(props);

    this.state = { messages: [] }
  }

  componentDidMount() {
    events.on('flash:new', (data) => {
      this.setState({
        messages: [
          ...this.state.messages,
          this.buildMessage(data)
        ]
      })
    });
  }

  public buildMessage = (data) => {
    const key = shortid.generate();
    return { ...data, key, id: data.id || key };
  }

  onRemove = (key) => {
    const messages = this.state.messages.filter(m => m.key !== key);
    this.setState({ messages });
  }

  render() {
    return (
      <div>
        {
          this.state.messages.map(({ key, ...message }) => (
            <FlashMessage key={key} {...message} onRemove={this.onRemove} />
          ))
        }
      </div>
    );
  }
}

export default FlashContainer;
