import React from 'react';

const AttributeHeader = ({ children, ...props }) => (
  <div className="accounts-module-attribute--header">
    <p>
      {children}
    </p>
  </div>
);

export default AttributeHeader;
