// @ts-nocheck - allows svg imports for whole file
import PolicyDocIcon from './assets/PolicyDocIcon.svg';
import RenewIcon from './assets/RenewIcon.svg';
import RentersInsuranceIcon from './assets/RentersInsuranceIcon.svg';
import SdiIcon from './assets/SdiIcon.svg';
import SupportIcon from './assets/SupportIcon.svg';
import ClaimDollarIcon from './assets/ClaimDollarIcon.svg';
import CancelIcon from './assets/CancelIcon.svg';

export const FAQs = [
  {
    text: 'What does Rhino Cover?',
    svg: SdiIcon,
    link: 'https://support.sayrhino.com/hc/en-us/articles/360056603551-What-does-Rhino-cover-',
    eventName: 'rhino_cover_faq_click',
    hideIfCashOnly: true
  },
  {
    text: 'How do I get my policy documents?',
    svg: PolicyDocIcon,
    link: 'https://support.sayrhino.com/hc/en-us/articles/360054993092--Finding-your-policy-documents',
    eventName: 'policy_docs_faq_click',
    hideIfCashOnly: true
  },
  {
    text: 'How do I renew my policy?',
    svg: RenewIcon,
    link: 'https://support.sayrhino.com/hc/en-us/articles/360054995392-Renewing-a-Rhino-policy-',
    eventName: 'renew_faq_click',
    hideIfCashOnly: true
  },
  {
    text: 'What if a claim was filed against me?',
    svg: ClaimDollarIcon,
    // tslint:disable-next-line:max-line-length
    link: 'https://support.sayrhino.com/hc/en-us/articles/360054995852-What-am-I-responsible-for-when-a-claim-is-filed-',
    eventName: 'claim_faq_click',
    hideIfCashOnly: true
  },
  {
    text: 'What is Rhino Renter Insurance?',
    svg: RentersInsuranceIcon,
    link: 'https://support.sayrhino.com/hc/en-us/articles/4404682463124-What-is-Rhino-s-renters-insurance-',
    eventName: 'what_is_ri_faq_click',
    hideIfCashOnly: false
  },
  {
    text: 'I want to talk to someone',
    svg: SupportIcon,
    link: 'https://support.sayrhino.com/hc/en-us/categories/360003291332-Contact-us',
    eventName: 'support_faq_click',
    hideIfCashOnly: false
  },
  {
    text: 'How do I cancel my policy?',
    svg: CancelIcon,
    // tslint:disable-next-line:max-line-length
    link: 'https://support.sayrhino.com/hc/en-us/articles/360055411071-Canceling-your-Security-Deposit-Insurance-policy',
    eventName: 'support_faq_click',
    hideIfCashOnly: true
  }
];

export const missedPayment = {
  header: 'You have a missed payment',
  text: (missedPaymentCount, total) => {
    return `You have ${missedPaymentCount} missed payments totaling ${total}. Pay your balance now to
      ensure your lease requirements are met and your policy stays active.
      `;
  },
  label: 'Needs Attention',
  ctaText: 'Pay Now'
};

export const openClaim = {
  header: 'You have an outstanding claim',
  label: 'Needs Attention',
  ctaText: 'Pay Now',
  inProgressText: 'In-progress'
};

export const pendingChangeRequest = {
  header: 'Your property made an update to your policy',
  text: 'Please review and accept the changes.',
  label: 'Needs Attention',
  ctaText: 'Review Changes'
};
