import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './redux/store';
import { ICopyContent } from './constants/copy';

// Components
import Assets from './components/assets';
import CheckYourInbox from './components/check_your_inbox';
import Citizenship from './components/citizenship';
import ContactUs from './components/contact_us';
import EducationLevel from './components/education_level';
import EmploymentStatus from './components/employment_status';
import ExpiredInvitation from './components/expired_invitation';
import ExpiredToken from './components/expired_token';
import FailedEnrollment from './components/failed_enrollment';
import FailedPricingCalculation from './components/failed_pricing_calculation';
import GetStarted from './components/get_started';
import Income from './components/income';
import InvitationFromAgent from './components/invitation_from_agent';
import InvitationLeaseDates from './components/invitation_lease_dates';
import LeaseDates from './components/lease_dates';
import SignupModal from './components/modal';
import MostRecentAddress from './components/most_recent_address';
import NotThereYet from './components/not_there_yet';
import Password from './components/password';
import PaymentPage from './components/quote_display';
import PersonalInformation from './components/personal_information';
import CashDeposit from './components/cash_deposit';
import ProductsOffered from './components/products_offered';
import Rent from './components/rent';
import RentersInsuranceRequired from './components/renters_insurance_required';
import Social from './components/social';
import StepWrapper, { Panel } from './components/step_wrapper';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import Household from './components/household';
import Pets from './components/pets';
import GetStandardSecurityDeposit from './components/get_standard_security_deposit';

export const queryClient = new QueryClient();

interface ISignupAppProps {
  rootPage: string;
  loaderImage?: string;
  copyContent?: ICopyContent;
}

const BoldedText = styled.span(FONTS.H6Medium, {
  fontWeight: 600,
  fontSize: '14px'
});

const NotBolded = styled.span(FONTS.H6Light, {
  fontSize: '14px'
});

const TextContainer = styled.div({
  lineHeight: '24px'
});

const SignupApp = (props: ISignupAppProps) => {
  const { rootPage, copyContent } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <SignupModal rootPage={rootPage}>
            <Switch>
              <Route exact path="/enroll/not_there_yet">
                <StepWrapper component={NotThereYet} step="not_there_yet" panel={Panel.Map} />
              </Route>
              <Route exact path="/enroll/check_your_inbox">
                <StepWrapper component={CheckYourInbox} step="check_your_inbox" />
              </Route>
              <Route exact path="/enroll/invitation_from_agent">
                <StepWrapper component={InvitationFromAgent} step="invitation_from_agent" panel={Panel.Map} />
              </Route>
              <Route exact path="/enroll/lease_dates">
                <StepWrapper component={LeaseDates} step="lease_dates" panel={Panel.Map} />
              </Route>
              <Route exact path="/enroll/rent">
                <StepWrapper component={Rent} step="rent" panel={Panel.Map} />
              </Route>
              <Route exact path="/enroll/invitation/personal_information">
                <StepWrapper
                  component={PersonalInformation}
                  step="personal_information"
                  panel={Panel.Guilding}
                  copy={copyContent?.personal_info_step}
                />
              </Route>
              <Route exact path="/enroll/invitation/lease_dates">
                <StepWrapper component={InvitationLeaseDates} step="lease_dates" panel={Panel.Map} />
              </Route>
              <Route exact path="/enroll/personal_information">
                <StepWrapper
                  component={PersonalInformation}
                  step="personal_information"
                  panel={Panel.Guilding}
                  copy={copyContent?.personal_info_step}
                />
              </Route>
              <Route exact path="/enroll/products_offered">
                <StepWrapper
                  component={ProductsOffered}
                  step="products_offered"
                />
              </Route>
              <Route exact path="/enroll/cash_deposit">
                <StepWrapper
                  component={CashDeposit}
                  step="cash_deposit"
                />
              </Route>
              <Route exact path="/enroll/renters_insurance_required">
                <StepWrapper
                  component={RentersInsuranceRequired}
                  copy={{
                    headertext: 'Did you know',
                    quoteSpan: (
                      <TextContainer>
                        <div>
                          <BoldedText>{'Security deposit insurance'} </BoldedText>
                          <NotBolded>{'protects your landlord from excessive damage or lost rent.'} </NotBolded>
                        </div>
                        <br />
                        <div>
                          <BoldedText>{'Renters insurance'} </BoldedText>
                          <NotBolded>
                            {'helps protect your home and personal belongings from fires, theft and more.'}
                          </NotBolded>
                        </div>
                      </TextContainer>
                    ),
                    quote_text: '',
                    quote_author: ''
                  }}
                  step="renters_insurance_required"
                  panel={Panel.Guilding}
                />
              </Route>
              <Route exact path="/enroll/most_recent_address">
                <StepWrapper
                  component={MostRecentAddress}
                  step="most_recent_address"
                  panel={Panel.Guilding}
                  copy={copyContent?.where_do_you_currently_live}
                />
              </Route>
              <Route exact path="/enroll/education_level">
                <StepWrapper
                  component={EducationLevel}
                  step="education_level"
                  panel={Panel.Guilding}
                  copy={copyContent?.education_level_step}
                />
              </Route>
              <Route exact path="/enroll/income">
                <StepWrapper component={Income} step="income" panel={Panel.Guilding} copy={copyContent?.income_step} />
              </Route>
              <Route exact path="/enroll/employment_status">
                <StepWrapper
                  component={EmploymentStatus}
                  step="employment_status"
                  panel={Panel.Guilding}
                  copy={copyContent?.employment_status_step}
                />
              </Route>
              <Route exact path="/enroll/household">
                <StepWrapper component={Household} step="household" panel={Panel.Guilding} />
              </Route>
              <Route exact path="/enroll/pets">
                <StepWrapper component={Pets} step="pets" panel={Panel.Guilding} />
              </Route>
              <Route exact path="/enroll/social">
                <StepWrapper
                  component={Social}
                  step="social"
                  panel={Panel.Guilding}
                  copy={copyContent?.social_security_step}
                />
              </Route>
              <Route exact path="/enroll/password">
                <StepWrapper component={Password} step="password" panel={Panel.PasswordPanel} />
              </Route>
              <Route exact path="/enroll/citizenship">
                <StepWrapper
                  component={Citizenship}
                  step="citizenship"
                  panel={Panel.Guilding}
                  copy={copyContent?.citizenship_step}
                />
              </Route>
              <Route path="/enroll/quote_display">
                <StepWrapper
                  component={PaymentPage}
                  step="quote_display"
                  loaderImage={props.loaderImage}
                  copy={copyContent?.faq_sdi_bind}
                />
              </Route>
              <Route exact path="/enroll/failed_enrollment">
                <StepWrapper component={FailedEnrollment} step="failed_enrollment" />
              </Route>
              <Route exact path="/enroll/failed_pricing_calculation">
                <StepWrapper component={FailedPricingCalculation} step="failed_pricing_calculation" />
              </Route>
              <Route exact path="/enroll/contact_us">
                <StepWrapper component={ContactUs} step="contact_us" />
              </Route>
              <Route exact path="/enroll/get_started">
                <StepWrapper component={GetStarted} step="get_started" />
              </Route>
              <Route exact path="/enroll/expired_invitation">
                <StepWrapper component={ExpiredInvitation} step="expired_invitation" />
              </Route>
              <Route exact path="/enroll/assets">
                <StepWrapper component={Assets} step="assets" panel={Panel.Guilding} copy={copyContent?.assets_step} />
              </Route>
              <Route exact path="/enroll/expired_token">
                <StepWrapper component={ExpiredToken} step="expired_token" />
              </Route>
              <Route exact path="/enroll/get_standard_security_deposit">
                <StepWrapper component={GetStandardSecurityDeposit} step="get_standard_security_deposit" />
              </Route>
            </Switch>
          </SignupModal>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default SignupApp;
