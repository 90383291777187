import { post } from 'utils/request';
import env from 'utils/env';

const API_BASE = env('API_BASE');

const PROPERTIES_SEARCH_URL = API_BASE + '/property-search';

export function fetchProperties(zip: string): Promise<any> {
  const url = PROPERTIES_SEARCH_URL;
  const body = { zip };

  return post(url, body);
}
