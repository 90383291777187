import React from 'react';
import Modal from 'react-modal';
import { RhinoModal } from '@sayrhino/rhino-shared-js';
import { ChangeRequests } from './ChangeRequests';
import { Claims } from './Claims';
import { Delinquency } from './Delinquency';
import { Policies } from './Policies';
import RentersInsuranceModal from 'components/v2/renters_insurance/components/RentersInsuranceModal';
import { formatRentersInsuranceAddresses } from 'components/v2/renters_insurance/utils';
import RenewalModal from 'components/v2/insurance_policies/renewalModal';
import { csrfToken } from 'utils/document';
import { RenewalWarningModal } from '../../insurance_policies/nonRenewalWarningModal';
import CustomModal from 'components/v2/insurance_policies/modal';
import { EligibleForRIList } from './EligibleForRentersInsurance';
import { AutoRenewCard } from './AutoRenewCard';
import InterestedPartyActionCard from './InterestedPartyActionCard';
import moment from 'moment';
import SubrogationPlans from 'components/v2/subro_payment_plans/subroFormWrapper';
import { formatDays } from '../../../../utils/date/formatter';
import { SubrogationPaymentConfirmationModal } from '../../subro_payment_plans/subrogationPaymentConfirmationModal';

import { RenterAvailableWithdrawalFunds } from './RenterAvailableWithdrawalFunds';
import { RenterWithdrawalCard } from './RenterWithdrawalCard';
import CancelWithdrawalModal from './CancelWithdrawalModal';
import { PendingCheckCard } from './Deposits/PendingCheck';
import { CashDepositCard } from './Deposits/CashDepositCard';
import { ActionRequiredCard } from './Deposits/ActionRequiredCard';
import { PaymentProcessingCard } from './Deposits/PaymentProcessing';
import { PendingExternalCashDeposit } from './Deposits/PendingExternalCashDeposit';
import { SectionHeader } from '../styles';
import { MergedActionCenterProps, useActionCenter } from '../hooks/useActionCenter';

Modal.setAppElement('#payment-plan-start');

const ActionCenter = (props: MergedActionCenterProps) => {
  const {
    showActionHeader,
    showCancelWithdrawalModal,
    showAnyNextStepsCards,
    my_profile_path,
    user,
    handlePayButtonClick,
    stripe_public_key,
    needsAttentionData,
    policyApplicationPendingStatus,
    policyApplication,
    nextStepsData,
    isNonRenewalModalOpen,
    showAutoRenewCard,
    cancelRenewal,
    openRenewalModal,
    setShowIPActionCard,
    policyOverviewData,
    showIPActionCard,
    showAvailableWithdrawalFundsCard,
    showEligibleForCoverGeniusRICard,
    renterFunds,
    cashDeposits,
    withdrawalList,
    setSelectedWithdrawUid,
    setShowCancelWithdrawalModal,
    openRentersInsuranceModal,
    showSecurityDepositOverview,
    isRentersInsuranceModalOpen,
    closeRentersInsuranceModal,
    subrogation,
    displaySubrogationModal,
    hideSubrogationModal,
    hideSubrogationPaymentConfirmationModal,
    isRenewalModalOpen,
    closeRenewalModal,
    selectedPolicyId,
    selectedWithdrawUid,
    onNonRenewalClose,
    displayPaymentConfirmationModal,
    dispatch,
    displayQuote,
    paymentPlanDuration,
    paymentPlanStartDate,
    closeNonRenewalModal
  } = useActionCenter(props);

  const CashDepositPendingStatus = () => {
    if (policyApplication.result?.is_processing_external_cash_deposit) {
      const sdiEligible = policyApplication.result?.eligibility?.sdi &&
        !policyApplication.result?.declined_from_subscription
      return <PendingExternalCashDeposit
        sdiEligible={sdiEligible}
        partner={policyApplication.result?.integration_partner}
      />;
    }
    if (policyApplication.result?.is_cash_deposit_payment_processing) {
      return <PaymentProcessingCard />;
    }
    if (policyApplication.result?.is_cash_deposit_pending_check) {
      return <PendingCheckCard />;
    }

    return null;
  };

  return (
    <>
      {/* Needs Attention section */}
      {/* When adding cards to this section, make sure to adjust showActionHeader! */}
      {showActionHeader && <SectionHeader>Needs Attention</SectionHeader>}
      <Claims claims={user?.open_claims} userId={user?.id} onPayButtonClick={handlePayButtonClick} />
      <Delinquency
        myProfilePath={my_profile_path}
        userId={user.id}
        fullName={user.full_name}
        defaultCard={user?.default_card}
        stripePublicKey={stripe_public_key}
        isDelinquent={!!needsAttentionData?.isDelinquent}
        unpaidInvoicesBreakdown={user?.unpaid_invoices_breakdown || {}}
      />
      <ChangeRequests SDIs={needsAttentionData?.SDIsWithChangeRequest} />
      {policyApplication.result?.is_cash_deposit_verifying_bank && (
        <ActionRequiredCard policyApplication={policyApplication.result} cardType="bank_verification" />
      )}
      {policyApplication.result?.is_cash_deposit_payment_failed && (
        <ActionRequiredCard policyApplication={policyApplication.result} cardType="payment_failed" />
      )}

      {/* Next Steps section */}
      {/* When adding cards to this section, make sure to adjust showAnyNextStepsCards! */}
      {showAnyNextStepsCards && <SectionHeader>Next Steps</SectionHeader>}
      {policyApplicationPendingStatus && <CashDepositPendingStatus />}
      {showAvailableWithdrawalFundsCard && <RenterAvailableWithdrawalFunds renterFunds={renterFunds as number} />}
      {showEligibleForCoverGeniusRICard && (
        <EligibleForRIList
          userId={user?.id}
          riEligibleList={nextStepsData?.riEligibleList}
          handleClick={() => openRentersInsuranceModal()}
        />
      )}
      {showAutoRenewCard && (
        <AutoRenewCard
          cancelRenewal={cancelRenewal}
          openRenewalModal={openRenewalModal}
          sdiEligibleForRenewalList={nextStepsData?.sdiEligibleForRenewalList}
        />
      )}

      {!!policyOverviewData?.RIsEligibleForIPAction.length && showIPActionCard && (
        <InterestedPartyActionCard
          isEligibleForIPModal={policyOverviewData?.RIsEligibleForIPAction}
          userId={user?.id}
          partnerRequiresRenterInsurance={
            props.partnerRentersInsuranceRequirements?.result?.active_renters_insurances_requirements
              ?.requires_renters_insurance || false
          }
          setShowIPActionCard={setShowIPActionCard}
        />
      )}

      {/* Policy Overview and Applications sections */}
      {!!policyOverviewData && (
        <Policies
          SDIs={policyOverviewData?.SDIs}
          RIs={policyOverviewData?.RIs}
          policyApplication={policyApplication}
        />
      )}

      {/* Security Deposit Overview section */}
      {/* When adding cards to this section, make sure to adjust showSecurityDepositOverview! */}
      {showSecurityDepositOverview && <SectionHeader>Security Deposit Overview</SectionHeader>}
      {withdrawalList &&
        withdrawalList?.length > 0 &&
        withdrawalList.map((withdraw, index) => (
          <RenterWithdrawalCard
            key={index}
            withdraw={withdraw}
            setSelectedWithdrawUid={setSelectedWithdrawUid}
            setShowCancelWithdrawalModal={setShowCancelWithdrawalModal}
          />
        ))}
      {cashDeposits?.map((deposit) => (
        <CashDepositCard
          deposit={deposit}
          key={deposit.deposit_uid}
          policyApplication={policyApplication.result}
        />
      ))}

      <RhinoModal
        isOpen={isRentersInsuranceModalOpen}
        onRequestClose={() => closeRentersInsuranceModal()}
        style={{ inset: 0, padding: 0, position: 'fixed' }}
        contentLabel="Renters Insurance Modal"
      >
        <RentersInsuranceModal
          onCloseModal={closeRentersInsuranceModal}
          userId={user?.id}
          firstName={user?.firstName}
          addressesForRI={formatRentersInsuranceAddresses(user?.addresses_for_ri)}
          policyApp={policyApplication}
        />
      </RhinoModal>
      {subrogation && (
        <CustomModal isOpen={displaySubrogationModal} closeModal={hideSubrogationModal}>
          <SubrogationPlans
            {...subrogation}
            onCancelButtonClick={hideSubrogationModal}
            onPayButtonClick={handlePayButtonClick}
          />
        </CustomModal>
      )}
      {subrogation && (
        <CustomModal
          isOpen={displayPaymentConfirmationModal}
          closeModal={() => dispatch(hideSubrogationPaymentConfirmationModal({}))}
        >
          <SubrogationPaymentConfirmationModal
            claim={subrogation.claim}
            insurance_policy_number={subrogation.insurance_policy_number}
            paymentPlanDuration={paymentPlanDuration}
            autoChargeDate={formatDays(moment(paymentPlanStartDate).date())}
            payment_plan={subrogation.payment_plan}
            onCloseButtonClick={() => dispatch(hideSubrogationPaymentConfirmationModal({}))}
          />
        </CustomModal>
      )}
      {isRenewalModalOpen && (
        <RenewalModal
          csrfToken={csrfToken()}
          isOpen={isRenewalModalOpen}
          closeModal={() => closeRenewalModal()}
          id={selectedPolicyId}
          displayQuote={displayQuote}
        />
      )}
      <CustomModal isOpen={isNonRenewalModalOpen} variant="white" closeModal={() => closeNonRenewalModal()}>
        <RenewalWarningModal
          onNonRenewalCloseModal={() => closeNonRenewalModal()}
          onNonRenewalClose={onNonRenewalClose}
          onRenewalModal={() => openRenewalModal(selectedPolicyId)}
          userId={user?.id}
        />
      </CustomModal>
      {selectedWithdrawUid && (
        <CancelWithdrawalModal
          isOpen={showCancelWithdrawalModal}
          withdrawUid={selectedWithdrawUid}
          setShowCancelWithdrawalModal={setShowCancelWithdrawalModal}
        ></CancelWithdrawalModal>
      )}
    </>
  );
};

export default ActionCenter;
