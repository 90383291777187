import React, { Component } from 'react';

export type IProperty = {
  key: string;
  building_name: string;
  address_line_one: string;
};

type IProps = {
  properties: IProperty[];
};

export default class PropertiesTable extends Component<IProps> {
  propertyTitle(property: IProperty): string {
    if (property.building_name === property.address_line_one) {
      return property.building_name;
    }

    return [property.building_name, property.address_line_one].join(', ');
  }

  renderProperty(property: IProperty) {
    return (
      <tr key={property.address_line_one}>
        <td>{this.propertyTitle(property)}</td>
      </tr>
    );
  }

  render() {
    return (
      <table className="table table-striped">
        <tbody>{this.props.properties.map((property) => this.renderProperty(property))}</tbody>
      </table>
    );
  }
}
