import styled from '@emotion/styled';
import React from 'react';
import { ActionButton as ButtonBase, FONTS, PALETTE, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import DollarSVG from '../assets/dollar.svg';
import ActiveSVG from '../assets/active.svg';
import UpcomingSVG from '../assets/upcoming.svg';
import ProcessingSVG from '../assets/processing.svg';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import CancellationRequestStatusIndicator from 'components/v2/shared/cancellationRequestStatusIndicator';
import { ICancellationRequest } from 'components/v2/insurance_policies/policyProps';
import getFeatureFlags from 'utils/getFeatureFlags';

interface Props {
  title: string;
  address: string;
  status?: string;
  paymentType?: string;
  imageUrl: string;
  isActive: boolean;
  policyNumber?: string;
  label?: string;
  link?: string;
  policyDates?: string;
  finalizeUrl?: string;
  inProgressCard?: boolean;
  cancellationRequest?: ICancellationRequest;
}

const MobileWrapper = styled.a([
  ROUNDNESS.m,
  {
    background: PALETTE.white,
    border: `1px solid ${PALETTE.neutral12}`,
    borderRadius: ROUNDNESS.round,
    padding: `21px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    marginBottom: 16,
    marginTop: 8,
    textDecoration: 'none',
    '@media (min-width: 992px)': {
      maxWidth: '648px'
    }
  }
]);

const DesktopWrapper = styled.a([
  ROUNDNESS.m,
  {
    background: PALETTE.white,
    border: `1px solid ${PALETTE.neutral12}`,
    padding: `21px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '648px',
    margin: '16px 0',
    textDecoration: 'none',
    '@media (max-width: 992px)': {
      width: '100%'
    }
  }
]);

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center'
});

const DesktopIconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const SubTitleWrapper = styled.div({
  marginLeft: 36
});

const InnerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 36
});

const Title = styled.div([
  FONTS.h5,
  {
    color: PALETTE.pink,
    fontWeight: 500
  }
]);

const SubTitle = styled.div([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65,
    fontWeight: 500,
    marginBottom: -4
  }
]);

const Text = styled.h4([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral88,
    fontWeight: 400,
    marginBottom: 0
  }
]);

const ImageWrapper = styled.img({
  marginRight: 16,
  width: 19,
  height: 19
});

const StyledActionButton = styled(ButtonBase)({
  '&&': {
    textAlign: 'center',
    color: PALETTE.white,
    backgroundColor: PALETTE.raptor,
    borderRadius: '24px',
    padding: '0px 20px',
    fontSize: '14px',
    fontFamily: 'MaisonNeueExtendedMedium',
    float: 'right',
    margin: '16px 0px auto auto',
    '@media (max-width: 992px)': {
      margin: '16px auto auto auto'
    },
    '&:hover': {
      backgroundColor: '#502cb9',
      cursor: 'pointer'
    }
  }
});

const ProgressBar = styled.div({
  width: 'auto',
  height: '5px',
  backgroundColor: '#eee',
  borderRadius: '10px',
  margin: '-1.375rem -.95rem 1.5625rem -0.95rem'
});

const Progress = styled.div({
  height: '100%',
  borderRadius: '10px',
  backgroundColor: PALETTE.raptor,
  width: '50%'
});

const greenStatuses = ['Active', 'Submitted'];

const PolicyStatus = ({ status, label }) => {
  return (
    <InnerWrapper>
      <img
        src={greenStatuses.includes(status) ? ActiveSVG : status === 'Processing' ? ProcessingSVG : UpcomingSVG}
        height="14px"
        width="9px"
        style={{ marginRight: 12 }}
      />
      <Text>
        {status}
        &nbsp;-
      </Text>
      <Text>
        &nbsp;
        {label}
      </Text>
    </InnerWrapper>
  );
};

export const PolicyCard = ({
  address,
  finalizeUrl,
  imageUrl,
  inProgressCard,
  label,
  link,
  paymentType,
  policyDates,
  policyNumber,
  status,
  title,
  cancellationRequest
}: Props) => {
  const isMobile = useCheckMobile();
  const featureFlags = getFeatureFlags();
  const showCancellationRequestStatus = Boolean(featureFlags?.showCancellationRequestStatus);

  if (!isMobile) {
    return (
      <DesktopWrapper href={link}>
        <div style={{ marginBottom: 16 }}>
          {inProgressCard && (
            <ProgressBar>
              <Progress />
            </ProgressBar>
          )}
          <div className={`d-flex justify-content-${showCancellationRequestStatus ? 'between' : 'start'}`}>
            <DesktopIconWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImageWrapper src={imageUrl} />
                <Title>{title}</Title>
              </div>
              {!!policyNumber && (
                <div>
                  <SubTitle>#{policyNumber}</SubTitle>
                </div>
              )}
            </DesktopIconWrapper>
            {showCancellationRequestStatus && (
              <CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />
            )}
          </div>
          <SubTitleWrapper>
            <SubTitle>{address}</SubTitle>
            {!!policyDates && <SubTitle>{policyDates}</SubTitle>}
          </SubTitleWrapper>
        </div>
        {!!paymentType && (
          <InnerWrapper>
            <img src={DollarSVG} height="14px" width="9px" style={{ marginRight: 12 }} />{' '}
            <Text>Paid {paymentType}</Text>
          </InnerWrapper>
        )}
        {inProgressCard && (
          <StyledActionButton
            id={'policy-application-finalize-button'}
            disabled={false}
            variant={'noIcon'}
            onClick={() => (window.location.href = `${finalizeUrl}`)}
          >
            Finalize
          </StyledActionButton>
        )}
        {!!status && (
          <div style={{ marginTop: 8 }}>
            <PolicyStatus status={status} label={label} />
          </div>
        )}
      </DesktopWrapper>
    );
  }

  return (
    <MobileWrapper href={link}>
      <div style={{ marginBottom: 16 }}>
        {inProgressCard && (
          <ProgressBar>
            <Progress />
          </ProgressBar>
        )}
        <div className="d-flex justify-content-between">
          <IconWrapper>
            <ImageWrapper src={imageUrl} />
            <Title>{title}</Title>
          </IconWrapper>
          <CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />
        </div>
        <SubTitleWrapper>
          <SubTitle>{address}</SubTitle>
          <SubTitle>{policyDates}</SubTitle>
          {policyNumber ? <SubTitle>#{policyNumber}</SubTitle> : null}
        </SubTitleWrapper>
      </div>
      {paymentType ? (
        <InnerWrapper>
          <img src={DollarSVG} height="14px" width="9px" style={{ marginRight: 12 }} /> <Text>Paid {paymentType}</Text>
        </InnerWrapper>
      ) : null}
      {inProgressCard && (
        <StyledActionButton
          id={'policy-application-finalize-button'}
          disabled={false}
          variant={'noIcon'}
          onClick={() => (window.location.href = `${finalizeUrl}`)}
        >
          Finalize
        </StyledActionButton>
      )}
      {status ? (
        <div style={{ marginTop: 8 }}>
          <PolicyStatus status={status} label={label} />
        </div>
      ) : null}
    </MobileWrapper>
  );
};
