import React from 'react';

const Label = ({
  target,
  title,
  className,
  sublabel
}: {
  target?: string;
  title?: string;
  className?: string;
  sublabel?: boolean;
}): any => (
  <label htmlFor={target} className={sublabel ? 'form-input-sublabel' : className}>
    {title}&nbsp;
  </label>
);

export default Label;
