import React from 'react';
import { EditAccountLeftNav } from './EditAccountLeftNav';

const styles = {
  optionsMenu: {
    backgroundColor: '#F5F4FB',
    padding: '100px 0 100px 50px'
  },
  header: {
    paddingBottom: '15px'
  }
};

export const EditAccountLayout = ({ children, ...props }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <EditAccountLeftNav stripe_publishable_key={props.stripe_publishable_key} />
      </div>
      <div className="col-md-6 d-none d-md-block">{children}</div>
    </div>
  );
};

export const EditAccountMobileLayout = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">{children}</div>
    </div>
  );
};
