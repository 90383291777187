import React, { Component } from 'react';
import Form from 'components/common/form/form';
import { signUpFormProps, inputId, inputName } from 'utils/form';

import RadioOption from './RadioOption';
import SelectionError from './SelectionError';

type IOption = { label: string; value: string | number };

type IProps = {
  user_id?: number;
  url?: string;
  csrf_token?: string;
  field: string;
  options: IOption[];
  resource?: string;
  selected: any;
  onChange?: (value: any) => any;
  onSubmit?: (e: any) => any;
  back_button_path?: string;
  hideActions?: boolean;
  holderClass?: string;
};

type IState = {
  selected: string;
  error: boolean;
};

class RadioForm extends Component<IProps, IState> {
  form;
  constructor(props: IProps) {
    super(props);

    this.state = {
      selected: '',
      error: false
    };
  }

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  optionValue = (value: any): string => {
    return typeof value === 'string' ? value.toLowerCase() : value;
  };

  componentDidMount = () => {
    document.addEventListener('keypress', this.handleKeyPress);
    if (this.props.selected != null) {
      this.setState({
        selected: this.props.selected
      });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keypress', this.handleKeyPress);
  };

  onChange = (selected: string) => {
    this.setState({ selected, error: false });
    if (this.props.onChange) this.props.onChange(selected);
  };

  handleSubmit = (e?: any): null => {
    if (e) e.preventDefault();

    if (this.state.selected === '') {
      this.setState({ error: true });
    } else if (this.props.onSubmit) {
      this.props.onSubmit(e);
    } else {
      this.form.submit();
    }

    return null;
  };

  inputId = (): string => inputId(this.props.resource, this.props.field);
  inputName = (): string => inputName(this.props.resource, this.props.field);

  render() {
    const { selected, error } = this.state;
    const { user_id, url, csrf_token, options, holderClass, onSubmit } = this.props;
    const formProps = {
      // @ts-ignore
      ...signUpFormProps(user_id, url, csrf_token, ''),
      onSubmit
    };

    return (
      <div>
        <Form
          {...formProps}
          setRef={(form) => {
            if (form) this.form = form;
          }}
        >
          {this.state.error && <SelectionError />}

          {options.map(({ label, value }: IOption, index: number): any => (
            <RadioOption
              id={this.optionValue(value)}
              key={index}
              onChange={this.onChange}
              value={value}
              selected={selected}
              label={label}
            />
          ))}

          <input type="hidden" name={this.inputName()} id={this.inputId()} value={this.state.selected} />
        </Form>
        {!this.props.hideActions && (
          <div className="action_buttons">
            {this.props.back_button_path && (
              <a className="back-btn" href={this.props.back_button_path}>
                Back
              </a>
            )}
            <button className="btn-default sign-up__action" onClick={this.handleSubmit}>
              Continue
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default RadioForm;
