import React from 'react';
import { shallow } from 'enzyme';
import Enzyme from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
Enzyme.configure({ adapter: new Adapter() });

import CancellationRequestStatusIndicator from '../cancellationRequestStatusIndicator';

test('no cancellationRequest provided', () => {
  const wrapper = shallow(<CancellationRequestStatusIndicator />);
  expect(wrapper.text()).toHaveLength(0);
});

test('renders Cancellation Pending', () => {
  const cancellationRequest = {
    status: 'pending'
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Pending');
});

test('renders Cancellation Denied', () => {
  const cancellationRequest = {
    status: 'declined'
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Denied');
});

test('renders Cancellation Scheduled', () => {
  const today = new Date();
  const futureDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 5}`;

  const cancellationRequest = {
    status: 'accepted',
    effective_from: futureDate
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Scheduled');
});

test('renders Cancellation Scheduled', () => {
  const today = new Date();
  const futureDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 5}`;

  const cancellationRequest = {
    status: 'autocancelled',
    effective_from: futureDate
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Scheduled');
});

test('renders Cancellation Approved', () => {
  const today = new Date();
  const pastDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() - 2}`;

  const cancellationRequest = {
    status: 'accepted',
    effective_from: pastDate
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Approved');
});

test('renders Cancellation Approved', () => {
  const today = new Date();
  const pastDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() - 2}`;

  const cancellationRequest = {
    status: 'autocancelled',
    effective_from: pastDate
  };

  const wrapper = shallow(<CancellationRequestStatusIndicator cancellationRequest={cancellationRequest} />);
  expect(wrapper.text()).toContain('Cancellation Approved');
});
