/* eslint-disable indent */
import { formatDate } from 'components/v2/quote_display/utils';
import React from 'react';
import { formatCents } from 'utils/money/formatter';
import {
  StyledAddress,
  StyledAmount,
  StyledAmountContainer,
  StyledAmountHeading,
  StyledAmountSection,
  StyledHeader,
  StyledLeaseDate,
  StyledLeaseTerm,
  StyledStatus,
  Wrapper
} from './styles';
import { CashDepositCardProps } from './types';

export const CashDepositCard = ({ deposit, policyApplication }: CashDepositCardProps) => {
  const { lease_end_date, lease_start_date, full_address } = policyApplication || {};
  const activeDepositStatus = !['closed', 'cancelled'].includes(deposit.state);

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledHeader>{deposit.title}</StyledHeader>
        <StyledStatus status={activeDepositStatus ? 'active' : 'closed'}>
          {activeDepositStatus ? 'Active' : 'Closed'}
        </StyledStatus>
      </div>
      <StyledAddress>{full_address}</StyledAddress>
      <StyledAmountSection>
        <StyledAmountContainer>
          <StyledAmountHeading>Deposit Amount</StyledAmountHeading>
          <StyledAmount color="#000">{formatCents(deposit.amount_in_cents)}</StyledAmount>
        </StyledAmountContainer>
        <StyledAmountContainer>
          <StyledAmountHeading>Total Withheld</StyledAmountHeading>
          <StyledAmount color="#DB3700">{formatCents(deposit.withheld_funds_in_cents)}</StyledAmount>
        </StyledAmountContainer>
        <StyledAmountContainer>
          <StyledAmountHeading>Remaining Balance</StyledAmountHeading>
          <StyledAmount color="#12873D">{formatCents(deposit.amount_in_cents)}</StyledAmount>
        </StyledAmountContainer>
      </StyledAmountSection>

      <StyledLeaseTerm>
        <StyledAmountHeading>Lease Term</StyledAmountHeading>
        {lease_start_date && lease_end_date && (
          <StyledLeaseDate>
            {formatDate(lease_start_date)} - {formatDate(lease_end_date)}
          </StyledLeaseDate>
        )}
      </StyledLeaseTerm>
    </Wrapper>
  );
};
