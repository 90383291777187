import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Steps } from './RentersInsuranceModal';
import {
  FONTS,
  TextInput,
  PALETTE,
  Button,
  ArrowIcon as ArrowIconBase,
  DropdownMenu,
  CaretIcon as CaretIconBase
} from '@sayrhino/rhino-shared-js';
import { BackButton } from './SharedStyles';
import { IN_FULL, BOTH, RentersInsuranceOptions } from '../constants';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { IRentersInsuranceRequirements } from '../interfaces';
import RadioForm from 'components/common/radio_form';
import useCreateCoverGeniusPolicyDetailsMutation from '../hooks/createCoverGeniusPolicyDetails';
import { ICreateCoverGeniusPolicyDetailsParams } from '../interfaces';
import { AxiosError, AxiosResponse } from 'axios';

interface ISelectedAddress {
  value: number | null;
  label: string;
  renters_insurance_requirements?: IRentersInsuranceRequirements[];
}

const Header = styled.h2([FONTS.h2], {
  lineHeight: '150%',
  letterSpacing: '0.56px',
  marginBottom: '8px',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    fontSize: '24px'
  }
});

const RadioHeader = styled.p([FONTS.p1], {
  color: PALETTE.neutral65,
  marginTop: '41px',
  '@media (max-width:767px)': {
    marginTop: '30px'
  }
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginTop: '24px',
  '@media (max-width: 768px)': {
    marginBottom: 0
  }
});

const QuoteButton = styled(Button)({
  '&&': {
    backgroundColor: PALETTE.brand100
  }
});

const CaretIcon = styled(CaretIconBase)({
  position: 'absolute',
  right: '10px',
  top: '35%',
  height: '16px',
  width: '16px'
});

const MenuItem = styled(DropdownMenu.Item)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
  width: '500px',
  color: PALETTE.neutral88
});

const MenuItemMobile = styled(DropdownMenu.Item)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
  width: '89vmin',
  color: PALETTE.neutral88
});

const MenuTrigger = styled(DropdownMenu.Trigger)([FONTS.p2], {
  position: 'relative',
  height: '44px',
  width: '100%',
  color: PALETTE.neutral65
});

const Label = styled.label([FONTS.p2Light], { color: PALETTE.neutral65, marginBottom: '0px' });

const DropdownContainer = styled(DropdownMenu.Content)({
  width: '100%'
});

const LabelMobile = styled.label([FONTS.p2], {
  color: PALETTE.neutral65,
  paddingBottom: '4px',
  marginBottom: '0px'
});

const SelectedAddress = styled.span([FONTS.p1Light], {
  color: PALETTE.neutralDark,
  textAlign: 'left',
  position: 'relative',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  width: '90%'
});

const ArrowIcon = styled(ArrowIconBase)({
  paddingLeft: '10px'
});

const Input = styled(TextInput)({
  input: {
    textOverflow: 'ellipsis',
    color: 'black',
    fontFamily: 'MaisonNeueMedium',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.24px'
  },
  label: {
    marginBottom: 0
  },
  div: {
    background: 'transparent',
    border: `1px solid #ADB4B8`,
    borderRadius: '8px',
    padding: '16px 12px'
  }
});

const Wrapper = styled.div({
  margin: '0 auto',
  maxWidth: '538px',
  '& div[role="radiogroup"] label': {
    marginBottom: 0,
    marginLeft: 8
  }
});

const QuoteError = styled.p([
  FONTS.p2,
  {
    color: PALETTE.alert125,
    marginTop: '20px'
  }
]);

const Subhead = styled.p([
  FONTS.p1Medium,
  {
    color: 'black',
    letterSpacing: '0.32px',
    lineHeight: '150%',
    marginBottom: 24,
    textAlign: 'center',
    '@media (max-width: 480px)': {
      fontSize: '16px'
    }
  }
]);

interface IStartProps {
  addresses: ISelectedAddress[];
  onCloseModal: () => void;
  goToStep: (modalStep: string) => void;
  userId: number;
  setRentersInsurancePolicy: (policyResponse: any) => void;
  setSelectedPolicyApp: (policyApp: any) => void;
  selectedPolicyApp: any;
  firstName: string;
  setRentersInsuranceRequired: (rentersInsuranceRequirement: any) => void;
  setSelectedPartnerRequiresRentersInsurance: (selectedPartnerRequiresRentersInsurance: any) => void;
}

const Start = (props: IStartProps) => {
  const {
    addresses,
    onCloseModal,
    goToStep,
    userId,
    setRentersInsurancePolicy,
    setSelectedPolicyApp,
    selectedPolicyApp,
    firstName,
    setRentersInsuranceRequired,
    setSelectedPartnerRequiresRentersInsurance
  } = props;
  const [isRIRequired, setIsRIRequired] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const isMobile = useCheckMobile();
  const [loading, setLoading] = useState<boolean>(false);
  const { mutate: createCoverGeniusPolicyDetails } = useCreateCoverGeniusPolicyDetailsMutation();

  const getQuote = () => {
    setLoading(true);

    const requestData: ICreateCoverGeniusPolicyDetailsParams = {
      policy_application_id: selectedPolicyApp.value,
      user_id: userId,
      requested_quote: BOTH,
      ri_required: partnerRequiresRentersInsurance === true || isRIRequired === 'true'
    };

    setRentersInsuranceRequired(requestData.ri_required);

    createCoverGeniusPolicyDetails(
      { ...requestData },
      {
        onSuccess: (response: AxiosResponse) => {
          if (response.data.policy_detail) {
            setRentersInsurancePolicy(response.data.policy_detail);
            goToStep(Steps.QUOTE);
          }
        },
        onError: (responseError) => {
          setLoading(false);
          setError(true);
        }
      }
    );
  };

  const selectedEligibleAddress = addresses.find((address) => address.label === selectedPolicyApp.label);

  const partnerRequiresRentersInsurance = selectedEligibleAddress?.renters_insurance_requirements
    ? selectedEligibleAddress?.renters_insurance_requirements[0]?.active_renters_insurances_requirements
        ?.requires_renters_insurance
    : null;

  setSelectedPartnerRequiresRentersInsurance(partnerRequiresRentersInsurance);

  const getQuoteButtonEnabled = () => {
    if (typeof partnerRequiresRentersInsurance === 'boolean' || isRIRequired !== '') {
      return true;
    } else return false;
  };

  return (
    <Wrapper>
      <Header>Let's get started, {firstName}</Header>
      <Subhead>Confirm your property’s address, and we’ll provide you with a personalized quote.</Subhead>

      {addresses.length === 1 ? (
        <Input id="address" label="Policy Address" value={addresses[0] && addresses[0].label} disabled />
      ) : (
        <>
          {isMobile ? (
            <LabelMobile htmlFor="address-dropdown">Policy address</LabelMobile>
          ) : (
            <Label htmlFor="address-dropdown">Policy address</Label>
          )}
          {/* NOTE: This will eventually get replaced with a new Dropdown component that will be in SharedJS */}
          <DropdownMenu.Root>
            <MenuTrigger data-cy="policyAddress" id="address-dropdown">
              <SelectedAddress>{selectedPolicyApp.label}</SelectedAddress>
              <CaretIcon />
            </MenuTrigger>
            <DropdownContainer>
              {addresses.map((addressInfo) =>
                isMobile ? (
                  <MenuItemMobile
                    onSelect={() => setSelectedPolicyApp(addressInfo)}
                    key={addressInfo.label}
                    data-cy="policyAddressOption"
                  >
                    {addressInfo.label}
                  </MenuItemMobile>
                ) : (
                  <MenuItem
                    data-cy="policyAddressOption"
                    onSelect={() => setSelectedPolicyApp(addressInfo)}
                    key={addressInfo.label}
                  >
                    {addressInfo.label}
                  </MenuItem>
                )
              )}
            </DropdownContainer>
          </DropdownMenu.Root>
        </>
      )}
      {typeof partnerRequiresRentersInsurance === 'boolean' ? null : (
        <>
          <RadioHeader>Does your property require renters insurance?</RadioHeader>
          <RadioForm
            field="renters_insurance_required"
            options={RentersInsuranceOptions}
            selected={isRIRequired}
            hideActions={true}
            onChange={(renters_insurance_required) => setIsRIRequired(renters_insurance_required)}
            holderClass=""
          />
        </>
      )}

      {error && <QuoteError data-cy="error">Hmm, we couldn’t generate a quote — Please try again later</QuoteError>}
      <ButtonContainer>
        <BackButton variant="white" onClick={onCloseModal} data-cy="cancel">
          Cancel
        </BackButton>
        {getQuoteButtonEnabled() && !loading ? (
          <QuoteButton onClick={getQuote} data-cy="getMyQuote">
            Get my quote
            <ArrowIcon />
          </QuoteButton>
        ) : (
          <Button disabled={true}>
            Get my quote <ArrowIcon />
          </Button>
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

export default Start;
