import AccountDetails from 'components/v2/account_details';
import Drawer from 'components/v2/drawer';
import UpdateCard from 'components/v2/update_card';
import React, { Component } from 'react';
import './types';
import UpdatePassword from './updatePassword';
import { Route, Switch } from 'react-router-dom';
import { EditAccountLayout, EditAccountMobileLayout } from './components/EditAccountLayout';
import { UpdatePaymentModalWrapper } from './components/UpdatePaymentModalWrapper';
import { EditAccountLeftNavMobile } from './components/EditAccountLeftNav';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

interface IProps {
  user: IUser;
  user_registration_url: string;
  card_update_path: string;
  payment_method_update_path: string;
  stripe_publishable_key: string;
  users_account_details_path: string;
  dataCy?: string;
  last_4_card_digits: string;
  card_brand: string;
  isMobile: boolean;
  number_of_unpaid_invoices: number;
}

interface IState {
  highlightProfile: boolean;
  currentUser?: IUser;
  isUpdatePaymentModalOpen: boolean;
}

class EditAccount extends Component<IProps, IState> {
  static contextType = AnalyticsMetadataContext;

  constructor(props: IProps) {
    super(props);

    this.state = {
      highlightProfile: true,
      currentUser: this.props.user,
      isUpdatePaymentModalOpen: false
    };
  }

  public setCurrentUser = (user) => {
    this.setState({ currentUser: user });
  };

  public toggleProfileHighlight = () => {
    this.setState({ highlightProfile: !this.state.highlightProfile });
  };

  public render() {
    const {
      user,
      users_account_details_path,
      user_registration_url,
      stripe_publishable_key,
      card_update_path,
      payment_method_update_path,
      last_4_card_digits,
      card_brand
    } = this.props;
    const { highlightProfile, isUpdatePaymentModalOpen } = this.state;

    return (
      <div>
        {this.props.isMobile ? (
          <EditAccountMobileLayout>
            <Switch>
              <Route exact path="/account/edit">
                <EditAccountLeftNavMobile stripe_publishable_key={stripe_publishable_key} />
              </Route>
              <Route exact path="/account/edit/profile">
                <AccountDetails user={user} users_account_details_path={users_account_details_path} />
              </Route>
              <Route path="/account/edit/update_password">
                <UpdatePassword user_registration_url={user_registration_url} />
              </Route>
              {stripe_publishable_key && (
                <Route path="/account/edit/payment_information">
                  <div>
                    <UpdatePaymentModalWrapper
                      card_update_path={card_update_path}
                      payment_method_update_path={payment_method_update_path}
                      stripePublishableKey={stripe_publishable_key}
                      last_4_card_digits={last_4_card_digits}
                      card_brand={card_brand}
                      isOpen={isUpdatePaymentModalOpen}
                      setIsOpen={(value: boolean) => this.setState({ isUpdatePaymentModalOpen: value })}
                      number_of_unpaid_invoices={this.props.number_of_unpaid_invoices}
                    />
                  </div>
                </Route>
              )}
            </Switch>
          </EditAccountMobileLayout>
        ) : (
          <EditAccountLayout stripe_publishable_key={stripe_publishable_key}>
            <Switch>
              <Route exact path="/account/edit">
                <AccountDetails user={user} users_account_details_path={users_account_details_path} />
              </Route>
              <Route exact path="/account/edit/profile">
                <AccountDetails user={user} users_account_details_path={users_account_details_path} />
              </Route>
              <Route path="/account/edit/update_password">
                <UpdatePassword user_registration_url={user_registration_url} />
              </Route>
              {stripe_publishable_key && (
                <Route path="/account/edit/payment_information">
                  <div className="edit-profile-section">
                    <UpdatePaymentModalWrapper
                      card_update_path={card_update_path}
                      payment_method_update_path={payment_method_update_path}
                      stripePublishableKey={stripe_publishable_key}
                      last_4_card_digits={last_4_card_digits}
                      card_brand={card_brand}
                      isOpen={isUpdatePaymentModalOpen}
                      setIsOpen={(value: boolean) => this.setState({ isUpdatePaymentModalOpen: value })}
                      number_of_unpaid_invoices={this.props.number_of_unpaid_invoices}
                    />
                  </div>
                </Route>
              )}
            </Switch>
          </EditAccountLayout>
        )}
      </div>
    );
  }
}

const styles = {
  optionsMenu: {
    backgroundColor: '#F5F4FB',
    padding: '100px 0 100px 50px'
  },
  header: {
    paddingBottom: '15px'
  }
};

export default EditAccount;
