import React from 'react';
import moment from 'moment';

import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  estimatedRefundCents: number;
  responseDeadline: string;
  contactSupportUrl: string;
}

const PendingStatusBanner = (props: IProps) => {
  const {
    estimatedRefundCents,
    responseDeadline,
    contactSupportUrl
  } = props;

  const SupportButton = styled.a([
    FONTS.p2Extended,
    {
      '&&': {
        borderRadius: 24,
        fontWeight: 500,
        backgroundColor: '#6A3BF5',
        color: 'white',
        marginRight: 12,
        padding: '8px 20px'
      }
    }
  ]);

  const PendingBannerWrapper = styled.div({
    border: '1px solid #7c3aed',
    borderRadius: '8px',
    padding: '24px 15px',
    margin: '24px 15px 0px',
    backgroundColor: '#f5f3ff',
    fontSize: '1rem'
  });

  const BannerTitle = styled.h3([
    FONTS.h3,
    { fontSize: '1.1rem' }
  ]);

  const responseDeadlineFmt = moment(responseDeadline).format('MMMM D, YYYY');
  const estimatedRefundCentsFmt = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(estimatedRefundCents / 100);

  return (
    <PendingBannerWrapper>
      <BannerTitle>This policy has a pending cancellation</BannerTitle>
      <div>
        <ul>
          <li>Your cancellation request has been submitted to your Property Manager and will be
            resolved by <b>{responseDeadlineFmt}</b>.</li>
          <li>Don't worry, if accepted by your property manager your <b>{estimatedRefundCentsFmt}</b> refund
            will be based on the day you submitted the request, not the day it was processed.</li>
        </ul>
        <SupportButton
          href={contactSupportUrl}
          target="_blank"
          rel="noopener noreferrer"
          id="pendingContactSupportBtn"
        >
            Contact Support
        </SupportButton>
      </div>
    </PendingBannerWrapper>
  );
};

export default PendingStatusBanner;
