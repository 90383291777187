import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { useState } from 'react';
import { ActionCenterLink as BaseLink } from '../link/ActionCenterLink';
// @ts-ignore
import accountIcon from './accountIcon.svg';

interface IProps {
  userFirstName?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const DropdownWrapper = styled.div({
  zIndex: 10,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: 280,
  top: '50%',
  right: '0'
});

export const Dropdown = styled.div({
  background: 'white',
  border: 'solid',
  color: '#E0E0E0',
  marginTop: 27,
  paddingLeft: 50,
  paddingBottom: 30
});

export const HiUser = styled.a({
  color: PALETTE.neutralDark,
  fontSize: '16px',
  lineHeight: '28px',
  fontFamily: 'MaisonNeueExtendedLight',
  position: 'relative',
  overflow: 'visible'
});

export const DropdownHeader = styled.div([
  FONTS.p1Medium,
  {
    color: PALETTE.neutral88,
    fontSize: '18px',
    lineHeight: '32px',
    paddingTop: 20
  }
]);

export const DropdownLink = styled(BaseLink)({
  lineHeight: 3,
  cursor: 'pointer'
});
