import React from 'react';
import { ITestimonialCardFields } from '../../../../../@types/generated/contentful';
import Carousel from '../carousel';

interface TestimonialCardFields extends Omit<ITestimonialCardFields, 'testifierImage'> {
  testifierImage: { url: string; description: string };
}

export interface TestimonialsModuleProps {
  testimonialCard: TestimonialCardFields[];
}

export const TestimonialsModule: React.FC<TestimonialsModuleProps> = (props) => {
  const { testimonialCard: testimonialCards } = props;
  const carouselHeaders = testimonialCards.map(
    // We're inserting these @ here so that we can use it to split in TestimonialCard
    (testimonial) => `${testimonial.testifierName}@${testimonial.testifierCompany}`
  );
  const carouselImages = testimonialCards.map((testimonial) => testimonial.testifierImage.url);
  const carouselContent = testimonialCards.map((testimonial) => testimonial.testimonial);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col" id="testimonial-carousel">
          <Carousel
            type="residentialPageTestimonial"
            headers={carouselHeaders}
            itemOptions={{ footers: carouselContent, images: carouselImages }}
            carouselOptions={{
              mobileCardOverflow: true,
              desktopCardOverflow: false,
              nav: true,
              loop: false,
              mouseDrag: true,
              responsive: {
                0: {
                  items: 1
                },
                768: {
                  items: 2
                },
                1200: {
                  items: 3
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
