import React from 'react';

interface IProps {
  nextPath: string;
  className: string;
  content: string;
  fontFamily?: 'MaisonNeueExtendedLight' | 'MaisonNeueExtendedDemi';
  dataMethod?: string;
  color?: string;
  dataCy?: string;
}

const Link = (props: IProps & Partial<HTMLAnchorElement>) => {
  const { className, nextPath, content, fontFamily, dataMethod, color, dataCy, target = '_self' } = props;

  return (
    <div>
      <a
        href={nextPath}
        data-cy={dataCy}
        target={target}
        className={className}
        style={{ fontFamily, color }}
        data-method={dataMethod}
      >
        {content}
      </a>
    </div>
  );
};

export default Link;
