import React, { Component } from 'react';

import Drawer from 'components/v2/drawer';
import Payment from './payment';
import { IPayment } from './paymentProps';
import { IPolicy, ICallbacks } from '../policyProps';
import UpdatePaymentMethod from './updatePaymentMethod';

interface IProps {
  policyId: number;
  policy: IPolicy;
  acceptChangeRequest: (_hideDrawer: boolean, _callbacks: ICallbacks) => void;
}

interface IState {
  isLoading: boolean;
  payments: IPayment[];
  errors: string[];
}

class Payments extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      payments: [],
      errors: []
    };

    this.renderPayments = this.renderPayments.bind(this);
  }

  public componentDidMount = () => {
    this.getPaymentData();
  };

  public getPaymentData = (): void => {
    const policyId = this.props.policyId;
    const requestUrl = `/insurance_policies/${policyId}/payments`;

    if (policyId) {
      fetch(requestUrl, { credentials: 'same-origin', headers: { Accept: 'application/json' } })
        .then((response): any => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error('Unable to pull payment information.');
          }
        })
        .then((parsed_response) => {
          this.setState({
            isLoading: false,
            payments: parsed_response.payments
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            errors: [error.message]
          });
        });
    }
  };

  public renderPayments(): JSX.Element {
    return (
      <div className="inner">
        <div className="header border-bottom">
          <h6 className="demi-18">All Payments</h6>
        </div>
        <div className="content">
          {this.state.payments.map((payment: IPayment) => (
            <Payment key={payment.id} {...payment} />
          ))}
        </div>
      </div>
    );
  }

  public render() {
    const { payments } = this.state;
    const { acceptChangeRequest, policy } = this.props;

    return (
      <>
        <div className="row no-gutters" style={styles.header}>
          <div>
            <div>
              <Drawer
                ctaText="View all past payments ->"
                renderContent={this.renderPayments}
                buttonBackground={'#F5F4FB'}
              />
            </div>
            <div>
              {!policy.disqualified_from_monthly && <UpdatePaymentMethod acceptChangeRequest={acceptChangeRequest} />}
            </div>
          </div>
        </div>
        {payments.length > 0 && (
          <Payment {...payments[0]}>
            <Drawer
              ctaText="View all past payments ->"
              renderContent={this.renderPayments}
              buttonBackground={'#F5F4FB'}
            />
          </Payment>
        )}
      </>
    );
  }
}

const styles = {
  header: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  payment: {
    flex: '1 0 auto',
    marginBottom: '0px'
  }
};

export default Payments;
