import React, { Component } from 'react';

interface IProps {
  id?: string;
  type: string;
  text: string;
  autoClose: boolean;
  onClose: () => void;
  onRemove: (_id: any) => void;
}

interface IState {
  visible: boolean;
}

const ALERT_TYPES = ['success', 'info', 'warning', 'danger'];

class FlashMessage extends Component<IProps, IState> {
  static defaultProps = {
    text: '',
    type: 'success',
    autoClose: true,
    onClose: () => { null }
  }

  interval!: number;

  constructor(props) {
    super(props);

    this.state = { visible: true }
  }

  componentDidMount() {
    if (this.props.autoClose) {
      this.interval = window.setTimeout(this.close, 5000);
    }
  }

  close = () => {
    if (this.interval) clearInterval(this.interval);
    this.props.onClose();
    this.setState({ visible: false });

    if (this.props.onRemove) {
      setTimeout(() => {
        this.props.onRemove(this.props.id);
      }, 1000);
    }
  }

  render() {
    const { text, type } = this.props;

    if (!text || !ALERT_TYPES.includes(type)) return null;

    return (
      <div className={`alert fade in alert-${type}`}>
        <button type="button" className="close" data-dismiss="alert">×</button>
        {text}
      </div>
    );
  }
}

export default FlashMessage;
