import React, { SyntheticEvent } from 'react';

import RadioForm from 'components/common/radio_form';
import EmploymentOptions from '../constants/employment_options';
import { allPresent, BackButton, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { usePersistUserAndPolicyApplication } from '../hooks/usePersistUserAndPolicyApplication';

function EmploymentStatus(props: IChildProps) {
  const userAndPolicyApplicationPersist = usePersistUserAndPolicyApplication();
  const [user, setUser] = useSubsetState(props.user.result, ['employment_status']);
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'employment_status'
  ]);
  const updateEmployment = (newValue) => {
    const employment_status = newValue.employment_status;
    const update = {
      employment_status
    };
    updatePolicyApplication(update);
    setUser(update);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    userAndPolicyApplicationPersist(
      user,
      policyApplication,
      props.user.result?.id,
      props.policyApplication.result?.id,
      props.step
    );
  };

  const disabled = !allPresent([policyApplication.employment_status]);

  return (
    <div>
      <h2 className="bold header" data-cy="employmentStatusHeader">What’s your employment status?</h2>
      <form className="sign-up__form" id="employment-status-form" onSubmit={onSubmit}>
        <div>
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              <RadioForm
                field="employment_status_id"
                options={EmploymentOptions}
                selected={policyApplication.employment_status}
                hideActions={true}
                onSubmit={onSubmit}
                onChange={(employment_status) => updateEmployment({ employment_status })}
              />
            </div>

            <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
              <BackButton step={props.step} />
              <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  }
};

export default EmploymentStatus;
