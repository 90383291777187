import axios, { AxiosError } from 'axios';
import { useMutation, MutationFunction } from 'react-query';
import { useDispatch } from 'react-redux';
import { WrappedUser } from '../interfaces';
import { IResult, updateUserSync } from '../redux/actions';
import { csrfToken } from 'utils/document';
import { coerceUndefined } from 'utils/redux_helpers';
import { User } from '../redux/state';

export interface IUserUpdateVariables {
  user: User;
  userId: number;
  signupStep?: string;
}

type UserWithStep = User & {
  signup_step?: string;
};

export const updateUser: MutationFunction<User, IUserUpdateVariables> = async ({ user, userId, signupStep }) => {
  const userWithStep: UserWithStep = { ...user, signup_step: signupStep };
  const result = await axios.put<WrappedUser>(`/users/${userId}.json`, {
    user: coerceUndefined(userWithStep),
    authenticity_token: csrfToken()
  });
  return result.data.user;
};

export function useUpdateUser(currentStep?: string) {
  const dispatch = useDispatch();

  return useMutation(updateUser, {
    onSuccess: (user) => {
      const userResult = {
        result: user
      };
      dispatch(updateUserSync(userResult, currentStep));
    },
    onError: (r: AxiosError<IResult<User>>) => {
      const error = r.response?.data;
      if (error) {
        dispatch(updateUserSync(error, currentStep));
      }
    }
  });
}
