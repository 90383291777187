import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import env from 'utils/env';
import { post } from 'utils/request';

export interface IProps {
  onSuccess: (recaptchaToken: string) => void;
  onError: (recaptchaError: string) => void;
  recaptchaRef: React.Ref<any>;
}

export default class RecaptchaWrapper extends Component<IProps> {
  public render() {
    const style = { visibility: 'hidden' as 'hidden' };

    return (
      <div style={style}>
        <ReCAPTCHA
          ref={this.props.recaptchaRef}
          size="invisible"
          sitekey={env('RECAPTCHA_PUBLIC_KEY')}
          onChange={this.verifyRecaptcha.bind(this)}
        />
      </div>
    );
  }

  private verifyRecaptcha(recaptchaToken) {
    post(env('VERIFY_RECAPTCHA_PATH'), { recaptchaToken })
      .then((r) => this.props.onSuccess(recaptchaToken))
      .catch((e) => this.props.onError(e.error));
  }
}
