import React from 'react';

interface IProps {
  header: string;
  content: string;
}

const EmptyPolicy: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="border-top">
      <div className="row">
        <div className="col" style={styles.header}>
          {props.header}
        </div>
      </div>
      <div className="row">
        <div className="col" style={styles.content}>
          {props.content}
        </div>
      </div>
    </div>
  );
};

const styles = {
  header: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '24px',
    lineHeight: '36px',
    marginTop: '40px'
  },
  content: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: '40px'
  }
};

export default EmptyPolicy;
