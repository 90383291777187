export interface IAssetsOptions {
  label: string;
  value: number;
}

const AssetsOptions: IAssetsOptions[] = [
  { label: 'Less than $1,000', value: 1 },
  { label: '$1,000 - $5,000', value: 2 },
  { label: '$5,000 - $10,000', value: 3 },
  { label: '$10,000 - $50,000', value: 4 },
  { label: '$50,000 - $100,000', value: 5 },
  { label: 'More than $100,000', value: 6 },
  { label: "I don't have a checking or savings account", value: 0 }
];

export default AssetsOptions;
