import React, { Component } from 'react';
import Input from 'components/common/form/input';
import { inputName } from 'utils/form';
import Label from 'components/common/form/label';

type IProps = {};

type IState = {
  changed: boolean;
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
};

const sharedInputProps = {
  type: 'password',
  placeholder: '● ● ● ● ● ● ●',
  className: 'input_field',
  autoComplete: 'off'
};

class UpdatePassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      changed: false,
      password: '',
      passwordConfirmation: '',
      currentPassword: ''
    };
  }

  renderPasswordField = () => {
    const props = {
      name: inputName('user', 'password'),
      value: this.state.password || '',
      id: 'user_password',
      onChange: (e: any) => {
        this.setState({ password: e.target.value });
      },
      ...sharedInputProps
    };

    return (
      <div className="accounts-form-field col-md-6 col-sm-12 col-xs-12">
        <Label title="New Password" className="accounts-form--label small-form--label" />
        <Input {...props} />
      </div>
    );
  };

  renderPasswordConfirmationField = () => {
    const props = {
      name: inputName('user', 'password_confirmation'),
      value: this.state.passwordConfirmation || '',
      id: 'password_confirmation',
      onChange: (e: any) => {
        this.setState({ passwordConfirmation: e.target.value });
      },
      ...sharedInputProps
    };

    return (
      <div className="accounts-form-field col-md-6 col-sm-12 col-xs-12">
        <Label title="Confirm Password" className="accounts-form--label small-form--label" />
        <Input {...props} />
      </div>
    );
  };

  renderCurrentPasswordField = () => {
    const props = {
      name: inputName('user', 'current_password'),
      value: this.state.currentPassword || '',
      id: 'current_password',
      onChange: (e: any) => {
        this.setState({ currentPassword: e.currentTarget.value });
      },
      ...sharedInputProps
    };

    return (
      <div>
        <Label title="Current password" className="accounts-form--label small-form--label" />
        <Input {...props} />
      </div>
    );
  };

  renderPasswordFields = () => {
    return (
      <div className="row">
        {this.renderPasswordField()}
        {this.renderPasswordConfirmationField()}
      </div>
    );
  };

  renderResetLink = () => {
    const props = {
      href: '#',
      id: 'reset-password',
      className: 'accounts-form--link',
      onClick: this.handleClick
    };

    return (
      <div>
        <a {...props}>Reset Password</a>
      </div>
    );
  };

  handleClick = (e: any) => {
    e.preventDefault();
    this.setState({ changed: true });
  };

  render() {
    return this.state.changed ? this.renderPasswordFields() : this.renderResetLink();
  }
}
export default UpdatePassword;
