export interface IEducationLevel {
  label: string;
  value: string;
}

/* tslint:disable */
const EducationLevels: IEducationLevel[] = [
  { label: "High school diploma / GED", value: 'high_school' },
  { label: "Associate degree", value: 'associate_degree' },
  { label: "Bachelor's degree", value: 'bachelor_degree' },
  { label: "Master's degree", value: 'masters_degree' },
  { label: "Doctoral or professional degree", value: 'doctoral_degree' }
];

export default EducationLevels;
