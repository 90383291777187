import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import { FAQs } from '../constants';
import { FAQCard } from './FAQCard';

const FAQWrapper = styled.div([
  ROUNDNESS.m,
  {
    backgroundColor: PALETTE.neutral4,
    border: `1px solid ${PALETTE.neutral12}`,
    position: 'relative',
    padding: '24px 16px 16px 16px',
    '@media (max-width: 767px)': [
      ROUNDNESS.square,
      {
        margin: '0px -15px -20px',
        maxWidth: '767px'
      }
    ]
  }
]);

const FAQHeader = styled.h6([
  FONTS.h6,
  {
    color: PALETTE.neutralDark,
    textAlign: 'center',
    paddingBottom: '8px'
  }
]);

export const FAQ = (props) => {
  return (
    <FAQWrapper>
      <FAQHeader>How can we help?</FAQHeader>
      {FAQs.map((faq) => {
        faq = {...faq, hideIfCashOnly: faq.hideIfCashOnly === props?.hideFAQ }
        return <FAQCard key={faq.text} {...faq} />;
      })}
    </FAQWrapper>
  );
};

export default FAQ;