/**
 * Checks if `value` is an empty object, collection, map, number, or set.
 *
 * Extends isEmpty from lodash by checking if the value is a number or has no enumerable string keyed properties
 *
 * Array-like values such as `arguments` objects, arrays, buffers, strings, or
 * jQuery-like collections are considered empty if they have a `length` of `0`.
 * Similarly, maps and sets are considered empty if they have a `size` of `0`.
 *
 * @category Logic
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 * @example
 *
 * isEmptyExtended(new Set())
 * // => true
 *
 * isEmptyExtended({})
 * // => true
 *
 * isEmptyExtended([])
 * // => true
 *
 * isEmptyExtended(null)
 * // => true
 *
 * isEmptyExtended(1)
 * // => false
 *
 * isEmptyExtended(true)
 * // => false
 *
 * isEmptyExtended(false)
 * // => false
 *
 * isEmptyExtended([1, 2, 3])
 * // => false
 *
 * isEmptyExtended('abc')
 * // => false
 *
 * isEmptyExtended({ 'a': 1 })
 * // => false
 */
import { isEmpty } from 'lodash';

const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;
const isNumberType = (value: any): boolean => typeof value === 'number';
const isNumberConstructor = (value: any): boolean => value && value.constructor === Number;

const isEmptyExtended = (value: any): boolean => {
  if (isNullOrUndefined(value)) {
    return true;
  }
  if (isNumberType(value) || isNumberConstructor(value)) {
    return false;
  }
  return isEmpty(value);
};

export default isEmptyExtended;
