import React, { useState } from 'react';
import ReactCurrencyInput from 'react-currency-input-field';
import { styles as inputStyles } from 'components/v2/input';
import { Alert2Icon, PALETTE } from '@sayrhino/rhino-shared-js';
import priceAverages from './price_averages';
import stateMarkets from './state_markets';
import zipToState from './zip_to_state';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

const styles = {
  desktop: {
    savingsSection: {
      maxWidth: '491px',
      height: 'fit-content',
      background: '#10AA42',
      borderRadius: '200px',
      textAlign: 'center' as 'center',
      padding: '30px 44px',
      color: 'white',
      margin: 'auto'
    },
    pricingSection: {
      textAlign: 'left' as 'left',
      padding: '40px 0 14px 42px'
    }
  },
  mobile: {
    pricingSection: {
      textAlign: 'left' as 'left',
      padding: '40px 40px 14px 42px'
    },
    savingsSection: {
      height: 'fit-content',
      background: '#17B64B',
      borderRadius: '200px',
      textAlign: 'center' as 'center',
      padding: '48px 24px',
      color: 'white',
      margin: 'auto'
    },
    headerText: {
      marginRight: '5px'
    }
  },
  image: {
    height: '68px'
  },
  spendingAmounts: {
    marginBottom: 0
  },
  button: {
    paddingLeft: '27px'
  },
  letsGoButton: {
    position: 'absolute' as 'absolute',
    bottom: '32px',
    paddingLeft: '10%'
  }
};

interface IProps {
  image: string;
}

type Tuple = [number, number];

const PRICE_MODEL_LOWER_LIMIT = 100;
const PRICE_MODEL_UPPER_LIMIT = 9000;
const GROCERIES_FOR_FOUR = 200;
const CUP_OF_COFFEE = 5;
const NETFLIX_SUB = 18;
const ROLL_OF_TOILET_PAPER = 0.7;
const DEFAULT_ZIP = '30068';

const getCoverageInfo = (zipCode: string): Tuple | undefined => {
  const usState = zipToState[zipCode];
  if (usState) {
    const market = stateMarkets[usState] || 'excess_lines';
    const averages = priceAverages.by_state[usState];
    if (averages) {
      return averages;
    } else {
      return priceAverages.by_market[market];
    }
  }
};

const calculatePriceEstimate = (coverageInfo?: Tuple, securityDeposit?: number): number | undefined => {
  if (coverageInfo && securityDeposit) {
    const [coverageAmount, basePrice] = coverageInfo;
    return (securityDeposit / coverageAmount) * basePrice;
  }
};

const calculateMonthlyPrice = (estimate?: number): number => {
  if (estimate) {
    estimate = estimate / 12;
    const price = Math.round(estimate);
    return price;
  } else {
    return 0;
  }
};

const scrollToForm = () => {
  document.getElementById('leave-upfront-costs')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

const PricingModule = (props: IProps) => {
  const [securityDeposit, setSecurityDeposit] = useState<number>(900);
  const [zipCode, setZipCode] = useState<string>(DEFAULT_ZIP);
  const invalidZipCode = !zipToState[zipCode];
  const lowSecurityDeposit = securityDeposit < PRICE_MODEL_LOWER_LIMIT;
  const highSecurityDeposit = securityDeposit > PRICE_MODEL_UPPER_LIMIT;
  const coverageInfo = getCoverageInfo(zipCode);
  const priceEstimate = calculatePriceEstimate(coverageInfo, securityDeposit);
  const monthlyPrice = calculateMonthlyPrice(priceEstimate);
  const { image } = props;

  const updateSecurityDeposit = (value?: string) => {
    const numValue = value ? +value : 0;
    setSecurityDeposit(numValue);
  };

  const pricingVariesText = () => {
    return '*Pricing will vary by individual renter. Actual rates are determined based on the specific information provided to Rhino. Monthly payment plans may not be available to all renters.';
  };

  const renderDesktopVersion = () => {
    return (
      <div className="pricing-module-desktop" style={{ marginBottom: '-17px' }}>
        <div>
          <div className="row">
            <div className="tablet-section col-6" style={{ textAlign: 'center', padding: '0' }}>
              <img src={image} style={{ width: '100%' }} />
            </div>
            <div className="col-6" id="pricing-section" style={styles.desktop.pricingSection}>
              <p className="p1 demi" style={{ marginBottom: 52 }}>
                See what you can save.
              </p>
              <div style={{ paddingBottom: 0, display: 'flex', flexWrap: 'wrap' }}>
                <h1 className="medium">Replace your </h1>
                <div style={{ position: 'relative' }}>
                  <ReactCurrencyInput
                    decimalScale={0}
                    autoFocus={false}
                    id="pricing_security_deposit"
                    className="landing-page--input landing-page-currency input"
                    style={{
                      borderBottom: lowSecurityDeposit
                        ? `1px solid ${PALETTE.alert100}`
                        : `1px solid ${PALETTE.neutral12}`
                    }}
                    prefix="$"
                    onValueChange={(value) => updateSecurityDeposit(value)}
                    value={securityDeposit}
                    type="tel"
                  />
                  {lowSecurityDeposit && (
                    <Alert2Icon
                      color={PALETTE.alert100}
                      style={{ position: 'absolute', bottom: '45px', right: '20px' }}
                      height="20px"
                      width="20px"
                    />
                  )}
                  <label
                    htmlFor="pricing_security_deposit"
                    style={
                      !lowSecurityDeposit ? inputStyles.label : { ...inputStyles.label, color: `${PALETTE.alert100}` }
                    }
                  >
                    Security Deposit
                  </label>
                </div>
                <h1 className="medium"> deposit in </h1>
                <div style={{ position: 'relative' }}>
                  <input
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="input landing-page-zipcode"
                    style={{
                      borderBottom: invalidZipCode ? `1px solid ${PALETTE.alert100}` : `1px solid ${PALETTE.neutral12}`,
                      maxWidth: !zipCode.length || (invalidZipCode && zipCode.length === 5) ? '260px' : '220px'
                    }}
                    id="pricing_zip_code"
                    placeholder={DEFAULT_ZIP}
                    type="text"
                    maxLength={5}
                  />
                  {invalidZipCode && (
                    <Alert2Icon
                      color={PALETTE.alert100}
                      style={{ position: 'absolute', bottom: '45px', right: '20px' }}
                      height="20px"
                      width="20px"
                    />
                  )}
                  <label
                    htmlFor="pricing_zip_code"
                    style={!invalidZipCode ? inputStyles.label : { ...inputStyles.label, color: `${PALETTE.alert100}` }}
                  >
                    Zip code
                  </label>
                </div>
                <h1 className="medium">with a </h1>
                <p style={{ margin: '0 15px 25px' }}>
                  <span
                    className="display2"
                    style={{ color: '#118838', borderBottom: '1px solid #118838', fontWeight: 'bold' }}
                  >
                    *${monthlyPrice ? monthlyPrice : '-'}/month
                  </span>
                </p>
                <p>
                  <span
                    className="display2"
                    style={{ color: '#118838', borderBottom: '1px solid #118838', fontWeight: 'bold' }}
                  >
                    policy
                  </span>
                </p>
                <button
                  className="landing-page--button-black"
                  style={{ ...styles.button, paddingRight: 65, margin: 'auto 32px' }}
                  onClick={scrollToForm}
                >
                  Get your quote
                </button>
              </div>
              {highSecurityDeposit && [
                <p className="p2">
                  Hold on, that’s a dizzying deposit. Let’s learn more about you so you can keep more cash.
                </p>,
                <button className="landing-page--button-black" style={styles.letsGoButton} onClick={scrollToForm}>
                  Let's go
                </button>
              ]}
              {lowSecurityDeposit && (
                <p className="p2" style={{ color: `${PALETTE.alert100}`, marginTop: '15px' }}>
                  Security deposit must be at least $100.
                </p>
              )}
              <p className="text-2" style={{ marginTop: 60, marginBottom: 0, color: '#595959' }}>
                {pricingVariesText()}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileVersion = () => {
    return (
      <div className="pricing-module-mobile">
        <div style={styles.mobile.pricingSection}>
          <p className="p1 demi" style={{ marginBottom: 40 }}>
            See what you can save
          </p>
          <div style={{ paddingBottom: 0, display: 'flex', flexWrap: 'wrap', maxWidth: 683 }}>
            <h1 className="medium">Replace your </h1>
            <div style={{ position: 'relative' }}>
              <ReactCurrencyInput
                decimalScale={0}
                autoFocus={false}
                id="pricing_security_deposit"
                className="landing-page--input landing-page-currency input"
                style={{
                  borderBottom: lowSecurityDeposit ? `1px solid ${PALETTE.alert100}` : `1px solid ${PALETTE.neutral12}`
                }}
                prefix="$"
                onValueChange={(value) => updateSecurityDeposit(value)}
                value={securityDeposit}
                type="tel"
              />
              {lowSecurityDeposit && (
                <Alert2Icon
                  color={PALETTE.alert100}
                  style={{ position: 'absolute', bottom: '45px', right: '20px' }}
                  height="20px"
                  width="20px"
                />
              )}
              <label
                htmlFor="pricing_security_deposit"
                style={!lowSecurityDeposit ? inputStyles.label : { ...inputStyles.label, color: `${PALETTE.alert100}` }}
              >
                Security Deposit
              </label>
            </div>
            <h1 className="medium"> deposit in </h1>
            <div style={{ position: 'relative' }}>
              <input
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="input landing-page-zipcode"
                style={{
                  borderBottom: invalidZipCode ? `1px solid ${PALETTE.alert100}` : `1px solid ${PALETTE.neutral12}`,
                  maxWidth: !zipCode.length || (invalidZipCode && zipCode.length === 5) ? '260px' : '220px'
                }}
                id="pricing_zip_code"
                placeholder={DEFAULT_ZIP}
                type="text"
                maxLength={5}
              />
              {invalidZipCode && (
                <Alert2Icon
                  color={PALETTE.alert100}
                  style={{ position: 'absolute', bottom: '45px', right: '20px' }}
                  height="20px"
                  width="20px"
                />
              )}
              <label
                htmlFor="pricing_zip_code"
                style={!invalidZipCode ? inputStyles.label : { ...inputStyles.label, color: `${PALETTE.alert100}` }}
              >
                Zip code
              </label>
            </div>
            <h1 className="medium">with a </h1>
            <p style={{ margin: '0 15px 25px' }}>
              <span
                className="display2"
                style={{ color: '#118838', borderBottom: '1px solid #118838', fontWeight: 'bold' }}
              >
                *${monthlyPrice ? monthlyPrice : '-'}/month
              </span>
            </p>
            <p>
              <span
                className="display2"
                style={{ color: '#118838', borderBottom: '1px solid #118838', fontWeight: 'bold' }}
              >
                policy
              </span>
            </p>
            <div className="br"></div>
            <button
              className="landing-page--button-black"
              style={{ ...styles.button, paddingRight: 65, margin: 'auto 32px' }}
              onClick={scrollToForm}
            >
              Get your quote
            </button>
          </div>
          <p className="text-2" style={{ marginTop: 82, marginBottom: 36, color: '#595959' }}>
            {pricingVariesText()}
          </p>
          <img src={image} />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderDesktopVersion()}
      {renderMobileVersion()}
    </div>
  );
};

export default PricingModule;
