import React from 'react';
import { IPayment } from './paymentProps';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { IPolicy, ICallbacks } from '../policyProps';
import { formatToLocaleDate } from '../../../common/form/date-picker/utils';
import styled from '@emotion/styled';

interface IProps {
  policyId: number;
  policy: IPolicy;
  last_four: string;
  brand: string;
  due_date: string;
  amount: string;
  acceptChangeRequest: (_hideDrawer: boolean, _callbacks: ICallbacks) => void;
}

const BillingInfo = styled.h5([FONTS.h5]);

const Label = styled.div([FONTS.p2Medium]);

const Info = styled.div([
  FONTS.p1,
  {
    flex: '70%',
    textAlign: 'left'  as 'left',
    '@media (max-width: 768px)': {
      flex: '100%'
    }
  }
]);

const Container = styled.div({
  marginTop: '34px'
});

const Row = styled.div({
  justifyContent: 'space-between',
  display: 'flex',
  marginBottom: '16px'
});

const Col = styled.div({
  display: 'flex',
  marginBottom: '16px',
  '@media (max-width: 768px)': {
    display: 'block'
  }
});

const PaymentLinks = styled.div([
  FONTS.p1Medium,
  {
    color: PALETTE.interaction100,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
]);

const BillingInformation: React.FC<IPayment> = (props: IPayment) => {
  return (
    <Container>
      <BillingInfo>Billing information</BillingInfo>
      <Row>
        <Label>Upcoming charge</Label>
        <Col>
          <Info>{props.amount} due on {formatToLocaleDate(props.due_date)}</Info>
        </Col>
        <Col>
          <PaymentLinks>View history</PaymentLinks>
        </Col>
      </Row>
      <Row>
        <Label>Payment method</Label>
        <Col>
          <Info>{props.brand}, •••• •••• •••• {props.last_four}</Info>
        </Col>
        <PaymentLinks>Update</PaymentLinks>
      </Row>
    </Container>
  );
};

export default BillingInformation;
