import React from 'react';
import styled from '@emotion/styled';
import {
  Wrapper,
  StyledCardParagraph,
  StyledHeader,
  StyledActionButton,
  StyledActionSection
} from './styles';
import COLORS from '../../../../../../../config/tailwind/colors';

export const PendingExternalCashDeposit = ({ sdiEligible, partner }) => {
  const title = sdiEligible ?
      "Sign up for Rhino's security deposit insurance" :
      'Pay your security deposit through your resident portal';

  const body = sdiEligible ?
    `If you haven't paid your security deposit through ${partner.name}'s resident portal,
    you still have the option to sign up for Rhino Deposit Insurance. This fulfills the
    same requirement as a regular deposit, but at a fraction fo the cost!` :
    `This is a reminder to pay your security deposit through ${partner.name}'s resident portal,
    if you haven't done so already. Paying your security deposit is required to ensure a
    smooth move-in and fulfills your landlord's requirements.`;

  const badge = (() => {
    const color = sdiEligible ? 'river-blue' : 'barry-red';
    const Badge = styled.span`
      border: 1px solid ${COLORS[color][100]};
      background: ${COLORS[color][5]};
      color: ${COLORS[color][100]};
      border-radius: 20px;
      padding: 0 10px;
      line-height: 15px;
      font-size: 10px;
      justify-content: center;
    `;

    return (
      <Badge>
        { sdiEligible ? 'Recommended' : 'Required' }
      </Badge>
    );
  })();

  const actionButtons = (() => {
    const RightSection = styled(StyledActionSection)`
      margin: 16px 0 auto auto;
      flex-direction: row;
    `;

    const LeftSection = styled(StyledActionSection)`
      margin: auto auto 8px 0;
      flex-direction: row;
    `;

    const ActionButton = styled(StyledActionButton)({
      '&&': {
        textAlign: 'center',
        border: '1px solid',
        borderColor: COLORS['raptor-purple'][100],
        borderRadius: '20px',
        color: COLORS['raptor-purple'][100],
        backgroundColor: COLORS['pitch-neutral'][0],
        margin: '16px 0px auto auto',
        '@media (max-width: 992px)': {
          margin: '16px auto auto auto'
        },
        '&:hover': {
          backgroundColor: '#502cb9',
          color: 'white',
          cursor: 'pointer'
        }
      }
    });

    const continueEnrollment = (
      <ActionButton
        id={`pending-external-ach.action.continue`}
        variant={'noIcon'}
        onClick={() => (
          window.location.href = sdiEligible ? partner.enrollment_url : partner.renter_cash_deposit_portal_url
        )} >
        { sdiEligible ? 'Continue' : 'Go to Partner Portal' }
      </ActionButton>
    );

    return (
      <>
        <LeftSection>
          { badge }
        </LeftSection>
        <RightSection>
          { continueEnrollment }
        </RightSection>
      </>
    );
  })();

  return (
    <Wrapper>
      <StyledHeader>
        { title }
      </StyledHeader>
      <StyledCardParagraph>
        { body }
      </StyledCardParagraph>
      <StyledActionSection>
        { actionButtons }
      </StyledActionSection>
    </Wrapper>
  );
};
