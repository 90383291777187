import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import FormGroup from '../form-group';
import Label from '../label';
import Input from '../input';
import { consoleTestResultHandler } from 'tslint/lib/test';

type IProps = {
  onChange?: (event: any, pureValue: string) => any;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  mask: string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  resource?: string;
  separator?: string;
  autoFocus?: boolean;
  labelClass?: string;
  disabled: boolean;
  style?: {};
  type?: string;
  dataCy?: string;
};

type IState = { value: string };

class MaskedInput extends Component<IProps, IState> {
  inputWrapper;

  constructor(props: IProps) {
    super(props);
    const value = this.props.value || '';

    this.state = { value };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus === true) {
      const input = this.inputWrapper.querySelector('input');
      input && input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) [this.setState({ value: this.props.value || '' })];
  }

  pureValue = (): string => {
    const { value } = this.state;
    if (!value.length) return '';

    const { separator = '-' } = this.props;

    return value.split(separator).join('');
  };

  onChange = (event: { target: { value: string } }) => {
    this.setState({ value: event.target.value }, () => {
      this.props.onChange &&
        // $FlowIgnore: onChange is always present at this point
        this.props.onChange(event, this.pureValue());
    });
  };

  maskedInputProps = (): any => {
    const { mask, maskChar, alwaysShowMask, placeholder, className, disabled = false, style, type } = this.props;
    const { value } = this.state;
    const id = this.props.id + '_masked';
    return { id, mask, maskChar, alwaysShowMask, placeholder, className, value, disabled, style, type };
  };

  pureValueinputProps = (): any => {
    const { id, name } = this.props;
    const value = this.pureValue();
    const type = 'hidden';

    return { id, name, value, type };
  };

  render() {
    const { label, id, labelClass } = this.props;

    return (
      <FormGroup>
        {label ? <Label target={id} title={label} className={labelClass} /> : null}
        <div
          ref={(r) => {
            if (r) this.inputWrapper = r;
          }}
        >
          <InputMask {...this.maskedInputProps()} onChange={this.onChange} />
          <Input data-cy={this.props.dataCy} {...this.pureValueinputProps()} />
        </div>
      </FormGroup>
    );
  }
}

export default MaskedInput;
