import React from 'react';

const Error = ({ text, css="inline-error-text" }) => (
  <span data-cy="errorText" className={css} style={styles}>{text}</span>
);

const styles = {
  color: '#ff3a3a',
  fontWeight: '400',
  letterSpacing: '0.5px',
  lineHeight: '14px',
  fontSize: '14px',
  display: 'block',
  margin: '10px 0'
}

export default Error;
