import { useContext } from 'react';
import ToastContext from './toast-context';

export enum TOAST_STATUS {
  SUCCESS = 'success', // shows CheckIcon
  ERROR = 'error' // shows exclamation mark
}

export default function useToast() {
  return useContext(ToastContext);
}
