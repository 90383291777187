import React, { useContext } from 'react';
import { Link } from './Link';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

export const PaymentInformationLink = () => {
  const analyticsMetadataContext = useContext(AnalyticsMetadataContext);

  return (
    <Link
      label="Payment Information"
      path="/account/edit/payment_information"
      dataCy="paymentInformation"
      analyticsEvent={{
        name: 'Payment Information Clicked',
        metadata: {
          userId: analyticsMetadataContext.userId,
          sessionId: analyticsMetadataContext.sessionId
        }
      }}
    />
  );
};
