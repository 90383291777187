import React from 'react';

type IProps = {
  selected: string | null;
  option: string;
  name?: string;
  label: any;
  value?: any;
  onClick?: (option: any) => any;
  readOnly?: boolean;
};

const Checkbox = (props: IProps) => {
  const { option, selected, onClick, label, name, value, readOnly } = props;

  return (
    <div className="field custom-checkbox">
      <input
        type="checkbox"
        id={option}
        name={name}
        value={value}
        onClick={onClick ? () => onClick(option) : () => null}
        disabled={readOnly}
        checked={selected === option}
      />

      <label htmlFor={option}>{label}</label>
    </div>
  );
};

export default Checkbox;
