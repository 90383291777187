import styled from '@emotion/styled';
import { Button, ButtonHabitat, PALETTE } from '@sayrhino/rhino-shared-js';
import { IRentersInsuranceAddress } from 'components/v2/renters_insurance/interfaces';
import React from 'react';
import ActionCard from './ActionCard';
import warningSVG from '../assets/warning.svg';

interface Props {
  riEligibleList: IRentersInsuranceAddress[];
  handleClick: () => void;
  userId: number;
}

const StyledUL = styled.ul`
  padding: 0;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const EligibleForRIList = (props: Props) => {
  const { handleClick, riEligibleList, userId } = props;
  return (
    <StyledUL>
      {riEligibleList.map((eligibleAddress, index) => {
        return (
          <EligibleForRICard key={index} eligibleAddress={eligibleAddress} handleClick={handleClick} containerAs="li" />
        );
      })}
    </StyledUL>
  );
};

const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-self: strech;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #adb4b8;
  background: #f5f6f7;
`;

const TopSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopSectionHeader = styled.h3`
  color: #000;
  font-family: 'MaisonNeueExtendedMedium';
  font-size: 20px;
  line-height: 125%;
  letter-spacing: 0.4px;
  margin: 0;
`;

const Badge = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 24px;
  border: 1px solid #315bf1;
  background: #f1f4fe;
  width: max-content;
  height: auto;
  gap: 4px;
`;

const BadgeWarning = styled(Badge)`
  && {
    border: 1px solid #db3700;
    background: #ffece5;
  }
`;

const BadgeText = styled.p`
  color: #315bf1;
  text-align: center;
  font-family: 'MaisonNeueExtendedDemi';
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
  white-space: nowrap;
`;

const BadgeTextWarning = styled(BadgeText)`
  color: #db3700;
`;

const DescriptionText = styled.p`
  color: #5A6468;
  font-family 'MaisonNeueMedium';
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.21px;
  margin: 0;
`;

const PercentStyle = styled.span`
  font-family 'MaisonNeueExtendedBold';
  color: #12873d;
`;

const CTAButton = styled(ButtonHabitat)({
  '&&': {
    display: 'block',
    width: 'max-content',
    margin: 0
  }
});

export const EligibleForRICard = ({
  eligibleAddress,
  handleClick,
  containerAs = 'div'
}: {
  eligibleAddress: IRentersInsuranceAddress;
  handleClick: () => void;
  containerAs: 'li' | 'div';
}) => {
  const riRequired =
    eligibleAddress?.renters_insurance_requirements?.[0].active_renters_insurances_requirements
      ?.requires_renters_insurance;

  return (
    <Container as={containerAs}>
      <TopSection>
        <TextContainer>
          <TopSectionHeader>Purchase Renters Insurance</TopSectionHeader>
          {riRequired ? (
            <DescriptionText>
              {eligibleAddress.address_line_one} requires renters insurance.
              <PercentStyle> Save 35% </PercentStyle> when you purchase a Rhino policy.
            </DescriptionText>
          ) : (
            <DescriptionText>
              Add renters insurance to maximize your protection. Be ready for anything and everything. Starting at
              $12/month.
            </DescriptionText>
          )}
        </TextContainer>
        {riRequired ? (
          <BadgeWarning>
            <img src={warningSVG} style={{ width: '14px', height: '14px' }} />
            <BadgeTextWarning>Lease Required</BadgeTextWarning>
          </BadgeWarning>
        ) : (
          <Badge>
            <BadgeText>Recommended</BadgeText>
          </Badge>
        )}
      </TopSection>

      <CTAButton
        id={`ri-cta-button-${eligibleAddress.policy_application_id.toString()}`}
        onClick={handleClick}
        variant="primary"
        size="small"
      >
        Get Covered Now
      </CTAButton>
    </Container>
  );
};
