import { Button as ButtonBase, CheckIcon as CheckIconBase, FONTS, PALETTE, Button, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import styled from '@emotion/styled';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';
import { formatCents } from 'utils/money/formatter';
import { IPartnerRentersInsuranceRequirements } from '../signup/redux/state';
// @ts-ignore
import rentersInsuranceImage from './rentersInsuranceImage.svg';
import { MONTHLY_PROCESSING_FEE } from '../renters_insurance/constants';
import { riPageType } from './quote_display_variant';
import { ToggleButtonWrapper, ToggleButtonStyle, activeButtonStyles, nonActiveButtonStyles } from './styles';
import env from 'utils/env';

const Wrapper = styled.div({
  width: '100%',
  maxWidth: 734,
  marginLeft: 'auto',
  textAlign: 'center',
  '@media (min-width: 992px)': {
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    marginRight: -17,
    paddingRight: 110
  },
  '&&': {
    '@media (max-width: 768px)': {
      marginRight: 0
    }
  }
});

const riPaymentFlexiblityEnabled = (window as any).App?.featureFlags?.riPaymentFlexiblity;

const Title = styled.h2([FONTS.h2, { '&&': { marginBottom: '8px' } }]);
const MobileTitle = styled.h3([FONTS.h3, { color: PALETTE.neutralDark, paddingTop: '32px' }]);
const SubHeader = styled.p([FONTS.p1Light, { color: PALETTE.neutralDark, marginBottom: 0 }]);
const PercentageStyle = styled.span([FONTS.p1Medium], {
  lineHeight: '28px',
  color: `${PALETTE.neutralDark}`,
  paddingBottom: '25px'
});

const Section = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '@media (min-width: 768px)': {
    margin: 'auto',
    width: '85%'
  }
});

const SubLabel = styled.div([
  FONTS.p1Light,
  {
    color: PALETTE.neutral88,
    fontSize: 10,
    marginBottom: '0px',
    lineHeight: '16px',
    paddingTop: '4px'
  }
]);

const LeaseRequirementStyle = styled.div(FONTS.p2Medium, {
  backgroundColor: PALETTE.neutralDark,
  width: '430px',
  color: PALETTE.neutralLight,
  padding: '5px 0px',
  margin: '-32px auto 24px -49px',
  borderRadius: '8px 8px 0px 0px',
  '@media (max-width: 520px)': {
    margin: '-24px -24px 24px -24px',
    width: 'auto'
  }
});

const WhatsIncludedContainer = styled.div({
  background: PALETTE.white,
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 8,
  padding: '32px 48px',
  '& button': {
    display: 'inline-block'
  },
  width: 430,
  margin: 'auto',
  '@media (max-width: 520px)': {
    padding: 24,
    maxWidth: 430,
    margin: 0,
    width: 'auto'
  }
});

const WhatsIncludedHeader = styled.p(FONTS.p1Medium, {
  '&&': { color: PALETTE.neutral88, marginTop: 24, marginBottom: 8 }
});
const WhatsIncluded = styled.p(FONTS.p2Light, {
  '&&': { color: PALETTE.neutral88, textAlign: 'left', marginBottom: 0 }
});
const CheckIcon = styled(CheckIconBase)({ color: PALETTE.success, fontWeight: 'bold' });
const AddedCheckIcon = styled(CheckIconBase)({ color: PALETTE.neutralLight, fontWeight: 'bold', paddingBottom: '5px' });
const ItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const Label = styled.h4([
  FONTS.h4,
  {
    color: PALETTE.brand125,
    flexGrow: 1,
    flexDirection: 'column',
    marginBottom: 0,
    textAlign: 'left'
  }
]);

const PriceLabel = styled.h4([
  FONTS.h4,
  {
    color: PALETTE.brand125,
    marginBottom: 0
  }
]);

const Explainer = styled.div([FONTS.p3Light], {
  color: PALETTE.neutral65,
  maxWidth: 430,
  textAlign: 'left',
  paddingTop: 20
});

interface RentersInsuranceProps {
  setActiveStep: (step: string | null) => void;
  rentersInsuranceSelected: boolean;
  setRentersInsuranceSelected: (e) => void;
  setRentersInsuranceCadence: (e) => void;
  userId: number;
  policyAppId: number;
  coverGeniusRentersInsurance: any;
  paymentOption: PAYMENT_OPTIONS;
  qualifiesForMonthlyRentersInsurance: boolean;
  eligibleInstalmentRIState: boolean;
  partnerRentersInsuranceRequirements?: IPartnerRentersInsuranceRequirements;
  coverGeniusRentersInsuranceNumber?: number;
  rentersInsuranceCadence: PAYMENT_OPTIONS;
  policyApplicationAddressState: string;
}

export const RentersInsurance = (props: RentersInsuranceProps) => {
  const {
    setActiveStep,
    rentersInsuranceSelected,
    setRentersInsuranceSelected,
    setRentersInsuranceCadence,
    userId,
    policyAppId,
    coverGeniusRentersInsurance,
    paymentOption,
    qualifiesForMonthlyRentersInsurance,
    eligibleInstalmentRIState,
    partnerRentersInsuranceRequirements,
    coverGeniusRentersInsuranceNumber,
    rentersInsuranceCadence,
    policyApplicationAddressState
  } = props;
  const isMobile = useCheckMobile();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const hasLeaseRequirement =
    partnerRentersInsuranceRequirements?.active_renters_insurances_requirements?.requires_renters_insurance &&
    !partnerRentersInsuranceRequirements?.active_renters_insurances_requirements?.has_exclusive_agreement;

  const PolicyDetailsButton = styled(ButtonBase)(
    rentersInsuranceSelected
      ? {
          '&&': {
            width: '100%',
            textAlign: 'center',
            marginTop: '25px',
            color: PALETTE.neutralLight,
            backgroundColor: 'black'
          }
        }
      : {
          '&&': { width: '100%', textAlign: 'center', marginTop: '25px' }
        }
  );

  useEffect(() => {
    setActiveStep('renters_insurance');
    return () => setActiveStep(null);
  }, []);

  useEffect(() => {
    const riCadence = riPaymentFlexiblityEnabled ? rentersInsuranceCadence : paymentOption
    history.push(pathname + `?payment_cadence=${paymentOption}` + `&ri_cadence=${riCadence}` + `&ri_selected=${rentersInsuranceSelected}`);
  }, [rentersInsuranceSelected, rentersInsuranceCadence]);

  const addRentersInsurance = () => {
    setRentersInsuranceSelected(!rentersInsuranceSelected);
  };

  const rentersInsuranceButtonText = rentersInsuranceSelected ? (
    <React.Fragment>
      Added <AddedCheckIcon />
    </React.Fragment>
  ) : (
    'Add Renters Insurance'
  );

  const inFull = coverGeniusRentersInsurance.in_full_quote_cents;
  const monthly = coverGeniusRentersInsurance.monthly_quote_cents + MONTHLY_PROCESSING_FEE;
  const instalments = coverGeniusRentersInsurance.instalments_quote_cents + MONTHLY_PROCESSING_FEE;
  const inInstalmentState = env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(policyApplicationAddressState);
  const monthlyPaymentAllowed = qualifiesForMonthlyRentersInsurance || eligibleInstalmentRIState;
  const isMonthly = () => {
    if (riPaymentFlexiblityEnabled) {
      return rentersInsuranceCadence === PAYMENT_OPTIONS.Monthly;
    } else {
      return paymentOption === PAYMENT_OPTIONS.Monthly;
    }
  }
  const period = isMonthly() && monthlyPaymentAllowed ? ' monthly' : '/year';
  const selectedPrice = () => {
    if (isMonthly()) {
      if (qualifiesForMonthlyRentersInsurance) {
        return formatCents(monthly);
      } else if (eligibleInstalmentRIState) {
        return formatCents(instalments);
      } else {
        return formatCents(inFull);
      }
    } else {
      return formatCents(inFull);
    }
  };

  const ToggleButton = ({ onClick, options, selectedOptionValue }) => {
    return (
      <ToggleButtonWrapper>
        {options.map((option) => {
          const styles = option.value === selectedOptionValue ? activeButtonStyles : nonActiveButtonStyles;
          return (
            <ToggleButtonStyle
              style={styles}
              key={option.label}
              onClick={() => onClick(option.value)}
            >
              {option.label}
            </ToggleButtonStyle>
          );
        })}
      </ToggleButtonWrapper>
    );
  };

  const PAYMENT_OPTIONS_WITH_LABEL: any = [
    { label: 'Pay monthly', value: PAYMENT_OPTIONS.Monthly },
    { label: 'Pay in full', value: PAYMENT_OPTIONS.Upfront }
  ];
  return (
    <Wrapper>
      <Section data-cy="rentersInsuranceHeader">
        {isMobile ? <MobileTitle>Add renters insurance</MobileTitle> : <Title>Add renters insurance</Title>}
        <SubHeader>
          {hasLeaseRequirement ? (
            <>
              {partnerRentersInsuranceRequirements?.building_name} requires renters insurance.
              <PercentageStyle> Save 35%* </PercentageStyle>
              when you sign up with Rhino.
              <SubLabel>*Compared to the base price if your property did not require renters insurance.</SubLabel>
            </>
          ) : (
            'Renters Insurance protects your home and personal belongings from fires, theft and more'
          )}
          {(riPaymentFlexiblityEnabled && !inInstalmentState) && (
          <ToggleButton
            options={PAYMENT_OPTIONS_WITH_LABEL}
            selectedOptionValue={rentersInsuranceCadence}
            onClick={(option) => setRentersInsuranceCadence(option)}
          />
        )}
        </SubHeader>
      </Section>
      <Section>
        <WhatsIncludedContainer>
          <LeaseRequirementStyle>{hasLeaseRequirement ? 'LEASE REQUIREMENT' : 'RECOMMENDED'}</LeaseRequirementStyle>
          <img src={rentersInsuranceImage} />
          <WhatsIncludedHeader> What's included:</WhatsIncludedHeader>
          <WhatsIncluded>
            <CheckIcon />
            &nbsp;$15,000 in personal property damage
          </WhatsIncluded>
          <WhatsIncluded>
            <CheckIcon />
            &nbsp;$100,000 in liability coverage
          </WhatsIncluded>
          <WhatsIncluded>
            <CheckIcon />
            &nbsp;Satisfies most lease requirements
          </WhatsIncluded>
          <hr />
          <ItemWrapper>
            <Label>Total</Label>
            <PriceLabel data-cy="riQuotePrice">
              {selectedPrice()}
              {period}
            </PriceLabel>
          </ItemWrapper>
          <PolicyDetailsButton onClick={addRentersInsurance} variant="tertiary" data-cy="addRentersInsurance">
            {rentersInsuranceButtonText}
          </PolicyDetailsButton>
        </WhatsIncludedContainer>
        {monthlyPaymentAllowed && (
          <Explainer>
            If you prefer a different payment plan, go back and change your selection on the previous page.
          </Explainer>
        )}
      </Section>
      <QuoteDisplayFooter
        arrowOnButton={true}
        onBack={() => history.push(`/enroll/quote_display`)}
        onNext={() => history.push(`policy_details` + search)}
        nextBtnLabel="Review Details"
        dataCy="reviewDetails"
      />
    </Wrapper>
  );
};
