import React, { Component } from 'react';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from 'components/common/form/input';

interface IState {
  passwordString: string;
  errors: string;
  inputType: string;
  toggleButtonColor: string;
}

interface IProps {
  styles?: object;
  resource: string;
  field?: string;
  className?: string;
  toggleVisibility?: boolean;
  placeholder?: string;
  onChangeString?: (value: string) => void;
  value?: string;
  dataCy?: string;
}

class PasswordInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      passwordString: '',
      errors: '',
      inputType: 'password',
      toggleButtonColor: 'gray'
    };
  }

  public render(): JSX.Element {
    const { resource, toggleVisibility, placeholder, field, value, dataCy } = this.props;
    return (
      <div>
        <div className="password-input-container">
          <Input
            name={ field ? `${resource}[${field}]` : 'user[password]'}
            id={field ? `${resource}_${field}` : 'user_password'}
            field={field ? field : 'password'}
            placeholder={placeholder ? placeholder : 'Password'}
            className={this.props.className ? this.props.className : 'sign-up__input'}
            style={this.props.styles}
            onChange={this.onChange}
            type={this.state.inputType}
            value={typeof(value) === 'string' ? value : this.state.passwordString}
            resource={resource}
            dataCy={this.props.dataCy}
          />
          { toggleVisibility &&
            <FontAwesomeIcon
              icon={this.state.inputType === 'password' ? faEyeSlash : faEye}
              className="visibility-icon"
              onClick={this.togglePasswordVisibility}
              color={this.state.toggleButtonColor}
            />
          }
        </div>
      </div>
    );
  }

  private onChange = (event: { target: { value: string } }) => {
    const newVal = event.target.value;
    this.setState({passwordString: newVal});
    if (this.props.onChangeString) {
      this.props.onChangeString(newVal);
    }
  }

  private togglePasswordVisibility = () => {
    const newInput = this.state.inputType === 'password' ? 'text' : 'password';
    const newButtonColor = newInput === 'password' ? 'grey' : 'black';
    this.setState({ inputType: newInput, toggleButtonColor: newButtonColor });
  }
}

export default PasswordInput;
