import React, { ReactNode } from 'react';
import Map, { IProps as IMapProps } from './map';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface ICoverageAmount {
  amount: string;
}
interface ICoverageMultiplier {
  multiplier: number;
}
type Coverage = ICoverageAmount | ICoverageMultiplier;

const PropertyDetailsHeader = styled.div(FONTS.h4, {
  color: PALETTE.black,
  fontWeight: 500,
  marginBottom: 6
});

const AddressDescription = styled.div([
  FONTS.p2Medium,
  {
    color: PALETTE.black,
    fontWeight: 500
  }
]);

const AddressDetails = styled.div([
  FONTS.p1Light,
  {
    color: PALETTE.black,
    fontWeight: 300,
    marginBottom: 10
  }
]);

export interface IProps {
  wrapperId?: string;
  map: IMapProps;
  overlay?: {
    address?: {
      name?: string;
      streetAddress: string;
      city: string;
      state: string;
      unit: string;
      zipCode: string;
    };
    coverage?: Coverage;
  };
}

export interface IAssets {
  assets: {
    tooltip: string;
    whiteTooltip: string;
  };
}

interface IState {
  displayCoverageInfo: boolean;
}

type Props = IProps & IAssets;

class MapWithOverlay extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayCoverageInfo: false
    };
  }

  public render(): ReactNode {
    const { wrapperId, map, overlay } = this.props;
    let overlayDiv;

    if (overlay) {
      overlayDiv = <div className="card-img-overlay-container">{this.renderAddressOverlay()}</div>;
    }

    const mapAttrs = {
      className: 'card-img rounded d-lg-block card-img-map'
    };

    if (wrapperId === 'mobile-card') {
      return (
        <div className="card align-items-center border-0" id={wrapperId}>
          <div className="mobile-property-address">
            {this.renderMobileAddressOverlay()}
            <Map.Component attributes={mapAttrs} {...map} wrapperId={wrapperId} />
          </div>
        </div>
      );
    }

    return (
      <div className="card align-items-center border-0" id={wrapperId}>
        <Map.Component attributes={mapAttrs} {...map} wrapperId={wrapperId} />
        {overlayDiv}
      </div>
    );
  }

  private renderAddressOverlay(): ReactNode {
    const { overlay } = this.props;
    if (overlay) {
      if (overlay.address) {
        const address = overlay.address;
        return (
          <div className="card-img-overlay bg-white img-fluid">
            <PropertyDetailsHeader>Property Details</PropertyDetailsHeader>
            <AddressDescription>Property Address</AddressDescription>
            <AddressDetails>
              {address.streetAddress}, {address.city}, {address.state} {address.zipCode}
            </AddressDetails>
            <AddressDescription>Unit/Floor</AddressDescription>
            <AddressDetails>{address.unit}</AddressDetails>
          </div>
        );
      }
    }
  }

  private renderMobileAddressOverlay(): ReactNode {
    const { overlay } = this.props;
    if (overlay) {
      if (overlay.address) {
        const address = overlay.address;
        return (
          <div>
            <PropertyDetailsHeader>Property Details</PropertyDetailsHeader>
            <AddressDescription>Property Address</AddressDescription>
            <AddressDetails>
              {address.streetAddress} {address.city}, {address.state} {address.zipCode}
            </AddressDetails>
            <AddressDescription>Unit/Floor</AddressDescription>
            <AddressDetails>{address.unit}</AddressDetails>
          </div>
        );
      }
    }
  }
}

const styles = {
  divider: {
    borderBottom: '1px solid white',
    width: '100%',
    paddingTop: '20px'
  },
  coverageText: {
    color: 'white',
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '20px',
    marginBottom: '0'
  }
};

export default MapWithOverlay;
export { Props as MapWithOverlayProps };
