import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Popover from 'components/common/popover';
import moment from 'moment';
import { inputId, inputName } from 'utils/form';
import EventEmitter from 'utils/event_emitter';
import MediaQuery from 'react-responsive';
import Label from '../label';

import Calendar from './calendar';
import { formatDate, formatFromMoment, placeholder, DATE_FORMAT, ISO_FORMAT, formatToIso } from './utils';

import type { IBaseProps, IBaseState } from './types';

type IProps = IBaseProps;
type IState = IBaseState & {
  displayValue: string;
};

const MINIMUM_DESKTOP_RESOLUTION_WIDTH = 1024;

class DesktopDatePicker extends Component<IProps, IState> {
  static defaultProps = {
    pickerProps: {
      skipBaseStyle: false,
      className: ''
    },
    popoverStyles: {}
  };

  _element;
  wrapper;
  ee: EventEmitter;

  constructor(props: IProps) {
    super(props);

    const { resource, field } = this.props;
    const value = formatDate(this.props.value);

    this.state = {
      value,
      open: false,
      inputId: inputId(resource, field),
      inputName: inputName(resource, field),
      displayValue: this.displayValue(value),
      dirty: false
    };

    this.ee = new EventEmitter();
    this.ee.on('calendar:opened', (id) => {
      if (id !== this.state.inputId) {
        this.setState({ open: false });
      }
    });
  }

  displayValue = (value: string): string => {
    const momented = moment(value, [DATE_FORMAT, ISO_FORMAT], true);

    return momented.isValid() ? momented.format(DATE_FORMAT) : value;
  };

  componentDidMount() {
    if (this.props.pickerProps && this.props.pickerProps.autoFocus) {
      // flow ignored it so ignoring it again. This should be converted to a ref
      // @ts-ignore
      ReactDOM.findDOMNode(this._element).focus();
    }
  }

  onChange = (value: moment.Moment) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  handleCalendarChange = (calendar: moment.Moment) => {
    const value = formatFromMoment(calendar);
    this.setState({ value, displayValue: this.displayValue(value), open: false });

    this.onChange(calendar);
  };

  handleInputChange = (e: { target: { value: string; id: string } }) => {
    const { value } = e.target;
    this.setState({ value: formatDate(value), displayValue: this.displayValue(value) });

    this.onChange(moment(value, DATE_FORMAT));
  };

  onInputBlur = (e: any) => {
    if (!this.wrapper.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  onClickOutside = (e: MouseEvent) => {
    if (!this.wrapper.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  onInputFocus = (e: any) => {
    if (this.wrapper.contains(e.target)) {
      this.setState({ open: true });
      this.ee.emit('calendar:opened', this.state.inputId);
    }
  };

  inputClassName = (): string => {
    const { dirty } = this.state;
    const { skipBaseStyle, className } = this.props.pickerProps || {};

    return (className || '') + (dirty ? ' ' : ' default-color-font') + (skipBaseStyle ? ' ' : ' form-control');
  };

  dateInput = () => {
    const { inputId: id, inputName: name, displayValue } = this.state;
    const { placeholderText } = this.props.pickerProps || {};

    return (
      <div>
        <input
          type="text"
          id={`display_${id}`}
          name={`display_${name}`}
          placeholder={placeholderText || placeholder()}
          className={this.inputClassName()}
          onChange={this.handleInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          value={displayValue}
          disabled={this.props.disabled}
          style={this.props.style}
          data-cy={this.props.dataCy}
          ref={(el) => {
            if (el) this._element = el;
          }}
        />
        <input type="hidden" id={id} name={name} value={formatToIso(this.state.value)}
          readOnly={true} />
      </div>
    );
  };

  popoverProps = (): any => {
    const styles = Object.assign(
      {},
      {
        width: '243px',
        position: 'absolute',
        top: '0px'
      },
      this.props.popoverStyles
    );

    return {
      target: this.dateInput(),
      style: styles,
      arrowPosition: 'topRight',
      open: this.state.open,
      toggable: false,
      hasCloseButton: false,
      closeOnOutsideClick: false
    };
  };

  calendar = () => {
    const { minDate, maxDate, style, selected } = this.props;

    const props = {
      value: formatToIso(this.state.value || new Date().toISOString()),
      minDate,
      maxDate,
      onChange: this.handleCalendarChange,
      onClickOutside: this.onClickOutside,
      style,
      selected
    };

    return <Calendar {...props} />;
  };

  render() {
    const { label, labelClass } = this.props;

    return (
      <div>
        {label && <Label title={label} className={labelClass || ''} />}
        <div
          ref={(el) => {
            if (el) this.wrapper = el;
          }}
        >
          <MediaQuery minDeviceWidth={MINIMUM_DESKTOP_RESOLUTION_WIDTH}>
            {(matches) => {
              if (matches) {
                return <Popover {...this.popoverProps()}>{this.calendar()}</Popover>;
              } else {
                return this.dateInput();
              }
            }}
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default DesktopDatePicker;
