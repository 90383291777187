/** @jsx jsx */
import React, { Fragment, useContext, useState } from 'react';
import { Button, FONTS } from '@sayrhino/rhino-shared-js';
import { jsx, css } from '@emotion/react';
import UiModal from './modal';
import UpdatePersonalDetails from './updatePersonalDetails';
import UserContext from '../layout/account/user-context';
import moment from 'moment';

interface IProps {
  users_account_details_path: string;
}

const personalInformation = (props: IProps) => {
  const { currentUser, updateUser } = useContext(UserContext);
  const { users_account_details_path } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const updateIsComplete = () => {
    setIsComplete(true);
  };
  const closeModal = () => {
    setTimeout(() => {
      setIsComplete(false);
    }, 500);
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const birthdate = currentUser.birthdate ? moment(currentUser.birthdate).format('DD/MM/YYYY') : '-';
  const phone = currentUser.phone ? currentUser.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '-';

  return (
    <Fragment>
      <div className="row">
        <h5 className="col" css={[FONTS.h5]}>
          Personal information
        </h5>
        <div className="col-auto" css={[FONTS.p2Light]}>
          <span onClick={() => openModal()} className="edit-info">
            Edit
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6 col-12 mb-md-3" css={[FONTS.p2Medium]}>
              First name
            </div>
            <div className="col-md-6 col-12 mb-3" css={[FONTS.p1Light]}>
              {currentUser.first_name}
            </div>
            <div className="col-md-6 col-12 mb-md-3" css={[FONTS.p2Medium]}>
              Last name
            </div>
            <div className="col-md-6 col-12 mb-3" css={[FONTS.p1Light]}>
              {currentUser.last_name}
            </div>
            <div className="col-md-6 col-12 mb-md-3" css={[FONTS.p2Medium]}>
              Date Of birth
            </div>
            <div className="col-md-6 col-12 mb-3" css={[FONTS.p1Light]}>
              {birthdate}
            </div>
            <div className="col-md-6 col-12 mb-md-3" css={[FONTS.p2Medium]}>
              Phone number
            </div>
            <div className="col-md-6 col-12 mb-3" css={[FONTS.p1Light]}>
              {phone}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <UiModal closeModal={closeModal} isOpen={isOpen}>
        {!isComplete ? (
          <Fragment>
            <h3 css={[FONTS.h3]}>Edit personal information</h3>
            <UpdatePersonalDetails
              user={currentUser}
              updateUser={updateUser}
              users_account_details_path={users_account_details_path}
              updateIsComplete={updateIsComplete}
            />
          </Fragment>
        ) : (
          <div
            css={css`
              @media (max-width: 768px) {
                height: 75vh;
                display: flex;
                align-items: center;
                jusify-content: center;
                grid-template-columns: 1fr;
              }
            `}
          >
            <div>
              <h3 css={[FONTS.h3]} className="text-center">
                Your info’s been updated!
              </h3>
              <Button children="Ok, got it" className="mt-4 mx-auto" onClick={closeModal} />
            </div>
          </div>
        )}
      </UiModal>
    </Fragment>
  );
};

export default personalInformation;
