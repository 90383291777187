import axios from 'axios';
import useGetUser from 'components/v2/dashboard/hooks/useGetUser';
import { IDisplayQuote } from 'components/v2/insurance_policies/types';
import { IPolicy } from 'components/v2/insurance_policies/policyProps';
import { IN_FULL } from 'components/v2/renters_insurance/constants';
import { IOpenPolicyApplication, IResult } from 'components/v2/signup/redux/actions';
import {
  Invitation,
  IPartnerRentersInsuranceRequirements,
  IState,
  PolicyApplication
} from 'components/v2/signup/redux/state';
import {
  hideSubrogationPaymentConfirmationModal,
  setPaymentPlanDuration,
  setStartDate,
  showSubrogationPaymentConfirmationModal
} from 'components/v2/subro_payment_plans/redux/reducers';
import { Subrogation } from 'components/v2/subro_payment_plans/subroFormWrapper';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getFeatureFlags from 'utils/getFeatureFlags';
import isPresent from 'utils/isPresent';
import { ICashDeposit } from '../components/Deposits/types';
import { IWithdrawal, NonCancelledWithdrawal } from '../components/RenterWithdrawalFunds/types';
import { IUser } from '../interfaces';
import useGetCoverGeniusRIs from './useGetCoverGeniusRIs';
import useGetSDIs from './useGetSDIs';
import { useNextStepsData } from './useNextStepsData';
import Flash from 'components/v2/flash';
import { csrfToken } from 'utils/document';

export interface IActionCenterProps {
  user: IUser;
  policyApplication: IResult<PolicyApplication>;
  stripe_public_key: string;
  my_profile_path: string;
  invitations: Invitation[];
  insurancePolicy: IPolicy;
  partnerRentersInsuranceRequirements: IResult<IPartnerRentersInsuranceRequirements>;
}

interface IActionCenterConnectedProps {
  openPolicyApplication: (data: IOpenPolicyApplication) => void;
}

interface IRenterAccountBalance {
  locked_balance_in_cents: number;
  free_balance_in_cents: number;
  total_balance_in_cents: number;
}

export type MergedActionCenterProps = IActionCenterProps & IActionCenterConnectedProps;

export const useActionCenter = (props: MergedActionCenterProps) => {
  const { data: security_deposit_insurances, isLoading: isLoadingSDIs } = useGetSDIs();
  const dispatch = useDispatch();
  const { user, policyApplication } = props;
  const { data: user_renters_insurances } = useGetCoverGeniusRIs(user?.id);
  const history = useHistory();
  const { data: userInfo } = useGetUser(user?.id);
  const [isRentersInsuranceModalOpen, setRentersInsuranceModalOpen] = useState(false);
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);
  const [displayQuote, setDisplayQuote] = useState<IDisplayQuote>();
  const [selectedPolicyId, setSelectedPolicyId] = useState(undefined);
  const [selectedLeaseId, setSelectedLeaseId] = useState(undefined);
  const [isNonRenewalModalOpen, setIsNonRenewalModalOpen] = useState(false);
  // this is set but looks like never used?
  const [errors, setErrors] = useState<string[]>([]);
  const [showIPActionCard, setShowIPActionCard] = useState<boolean>(true);
  const [displaySubrogationModal, setDisplaySubrogationModal] = useState(false);
  const [subrogation, setSubrogation] = useState<Subrogation>();
  const [renterFunds, setRenterFunds] = useState<number>();
  const [withdrawalList, setWithdrawalList] = useState<NonCancelledWithdrawal[] | undefined>([]);
  const [cashDeposits, setCashDeposits] = useState<ICashDeposit[]>([]);
  const [showCancelWithdrawalModal, setShowCancelWithdrawalModal] = useState(false);
  const [selectedWithdrawUid, setSelectedWithdrawUid] = useState<string | undefined>(undefined);

  const featureFlags = getFeatureFlags();
  const displayPaymentConfirmationModal = useSelector(
    (state: IState) => state.subrogationPaymentConfirmationModal.isOpen
  );
  const paymentPlanDuration = useSelector(
    (state: IState) => state.subrogationPaymentConfirmationModal.paymentPlanDuration
  );
  const paymentPlanStartDate = useSelector((state: IState) => state.subrogationPaymentConfirmationModal.startDate);

  const getRenterFunds = async () => {
    const url = '/users/renter_withdrawal_funds/renter_withdrawal_funds.json';
    const response: { data: IRenterAccountBalance } = await axios.get(url, {
      headers: { Accept: 'application/json' }
    });

    setRenterFunds(response.data.free_balance_in_cents);
  };

  const getWithdrawalList = async () => {
    const url = '/users/renter_withdrawal_funds/withdrawal_list.json';
    const response: { data: { withdrawal_list: IWithdrawal[] } } = await axios.get(url, {
      headers: { Accept: 'application/json' }
    });

    const withdrawals = response.data.withdrawal_list;
    const nonCancelledWithdrawals = withdrawals.filter((withdrawal) => withdrawal.state !== 'cancelled');
    setWithdrawalList(nonCancelledWithdrawals as NonCancelledWithdrawal[]);
  };

  const getDepositList = async () => {
    const url = '/users/cash_deposits/cash_deposit_list.json';
    const response: { data: { deposit_list: ICashDeposit[] } } = await axios.get(url, {
      params: {
        policy_app_id: policyApplication.result?.id
      },
      headers: { Accept: 'application/json' }
    });

    const deposits = response.data.deposit_list;
    setCashDeposits(deposits);
  };

  const needsAttentionData = React.useMemo(() => {
    if (!security_deposit_insurances?.insurance_policies || !user) {
      return null;
    }
    const SDIs = security_deposit_insurances?.insurance_policies;
    return {
      isDelinquent: user?.is_delinquent,
      SDIsWithChangeRequest: SDIs.filter((SDI) => SDI.pending_change_request)
    };
  }, [security_deposit_insurances]);

  const policyOverviewData = useMemo(() => {
    if (!security_deposit_insurances?.insurance_policies || !user_renters_insurances) {
      return null;
    }
    const SDIs = security_deposit_insurances?.insurance_policies;
    return {
      SDIs: SDIs.map((SDI) => {
        const unitName = SDI.address_line_two ? `, ${SDI.address_line_two}` : '';

        return {
          status: SDI.status,
          isUpfront: SDI.upfront,
          coverageStartDate: SDI.coverage_start_date,
          coverageEndDate: SDI.coverage_end_date,
          policyNumber: SDI.policy_number,
          address: SDI.address_line_one + unitName,
          policyId: SDI.policy_id,
          policyApplicationId: SDI.policy_application_id,
          cancellationRequest: SDI.cancellation_request
        };
      }),
      RIs: user_renters_insurances
        .filter(
          (renters_insurance) => !['canceled', 'expired'].includes(renters_insurance.status_for_ri_card.toLowerCase())
        )
        .map((renters_insurance) => {
          const unitName = renters_insurance.address_line_two ? `, ${renters_insurance.address_line_two}` : '';
          return {
            status: renters_insurance.status_for_ri_card,
            isUpfront: renters_insurance.pay_cadence === IN_FULL,
            coverageStartDate: renters_insurance.coverage_start_date,
            coverageEndDate: renters_insurance.coverage_end_date,
            insNumber: renters_insurance.ins_number,
            address: renters_insurance.address_line_one + (unitName.toLowerCase() !== 'n/a' ? unitName : '')
          };
        }),
      RIsEligibleForIPAction: user_renters_insurances
        .filter(
          (renters_insurance) =>
            !['canceled', 'expired'].includes(renters_insurance.status_for_ri_card.toLowerCase()) &&
            (renters_insurance.interested_party_email === undefined || renters_insurance.interested_party_email === '')
        )
        .map((renters_insurance) => {
          const unitName = renters_insurance.address_line_two ? `, ${renters_insurance.address_line_two}` : '';
          return {
            id: renters_insurance.id,
            cliam_url: renters_insurance.claim_url,
            ins_number: renters_insurance.ins_number,
            policy_wording_url: renters_insurance.policy_wording_url,
            address: renters_insurance.address_line_one + (unitName.toLowerCase() !== 'n/a' ? unitName : '')
          };
        })
    };
  }, [security_deposit_insurances, user_renters_insurances]);

  const nextStepsData = useMemo(
    () => useNextStepsData(security_deposit_insurances, userInfo),
    [security_deposit_insurances, userInfo]
  );

  const allClaimsHavePaymentPlans = user?.open_claims.every(
    (claim) => claim.subrogation_payment_plan_installments !== null
  );

  const showActionHeader =
    (user?.open_claims.length > 0 && !allClaimsHavePaymentPlans) ||
    needsAttentionData?.isDelinquent ||
    (!isLoadingSDIs && needsAttentionData?.SDIsWithChangeRequest?.length !== 0) ||
    policyApplication.result?.is_cash_deposit_verifying_bank ||
    policyApplication.result?.is_cash_deposit_payment_failed;

  const showLemonadeRhinoOfferCard = useMemo(
    () => !!policyApplication?.result?.eligible_for_lemonade_offer &&
      !policyApplication?.result?.has_effective_lemonade_policy,
    [
      policyApplication.result?.eligible_for_lemonade_offer,
      policyApplication?.result?.has_effective_lemonade_policy]
  );

  const showEligibleForCoverGeniusRICard = useMemo(() => {
    if (policyApplication?.result?.has_effective_lemonade_policy) return false;
    if (showLemonadeRhinoOfferCard) return false;
    if (!nextStepsData?.riEligibleList?.length) return false;
    if (!security_deposit_insurances?.insurance_policies?.length)
      return !!featureFlags?.upsellRentersInsuranceForCash && !!user.stripe_customer_id;
    return !!policyApplication.result?.stripe_customer_id_exists;
  }, [
    nextStepsData,
    security_deposit_insurances,
    showLemonadeRhinoOfferCard,
    user.stripe_customer_id,
    policyApplication?.result?.has_effective_lemonade_policy
  ]);

  const showAutoRenewCard = !!nextStepsData?.sdiEligibleForRenewalList?.length;

  const showAvailableWithdrawalFundsCard = !!renterFunds;

  const policyApplicationPendingStatus =
    policyApplication.result?.is_cash_deposit_pending_check ||
    policyApplication.result?.is_cash_deposit_payment_processing ||
    policyApplication.result?.is_processing_external_cash_deposit

  const showAnyNextStepsCards =
    showEligibleForCoverGeniusRICard ||
    showAutoRenewCard ||
    showAvailableWithdrawalFundsCard ||
    policyApplicationPendingStatus ||
    (!!policyOverviewData?.RIsEligibleForIPAction?.length && showIPActionCard);

  const showSecurityDepositOverview = !!withdrawalList?.length || !!cashDeposits?.length;

  const openRentersInsuranceModal = () => {
    history.push('/users/edit/renters_insurance');
    setRentersInsuranceModalOpen(true);
    onToggleFullscreenModal(true);
  };
  const closeRentersInsuranceModal = () => {
    history.push('/users/edit');
    onToggleFullscreenModal(false);
    window.location.reload();
    window.scrollTo({ top: 0 });
    setRentersInsuranceModalOpen(false);
  };

  const closeRenewalModal = () => {
    setIsRenewalModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  const openRenewalModal = async (id) => {
    setIsNonRenewalModalOpen(false);
    setSelectedPolicyId(id);
    try {
      const quote = await axios.post(`/insurance_policies/${id}/display_quote.json`);
      setDisplayQuote(quote.data);
      setIsRenewalModalOpen(true);
    } catch (error) {
      Flash.events.emit('flash:new', { text: 'Internal server error.', type: 'danger' });
    }
  };

  const cancelRenewal = (policyId, leaseId) => {
    setSelectedPolicyId(policyId);
    setSelectedLeaseId(leaseId);
    onOpenNonRenewalModal();
  };

  const onOpenNonRenewalModal = async () => {
    setIsNonRenewalModalOpen(true);
  };

  const closeNonRenewalModal = async () => {
    setIsNonRenewalModalOpen(false);
  };

  const onNonRenewalClose = async (reason: string) => {
    if (selectedLeaseId) {
      const payload = {
        lease: { moving_out_after_end: reason === '0' },
        authenticity_token: csrfToken()
      };

      const url = `/leases/${selectedLeaseId}`;
      try {
        await axios.put(url, payload);
        await axios.put(
          `/insurance_policies/${selectedPolicyId}/update_non_renewal_reasons.json`,
          { renter_policy: { non_renewal_reason: Number(reason) } },
          { headers: { Accept: 'application/json', authenticity_token: csrfToken() } }
        );
      } catch (error) {
        if (error instanceof Error) {
          setErrors([error.message]);
        }
      }
    }
    setIsNonRenewalModalOpen(false);
  };
  const { stripe_public_key } = user;
  const { my_profile_path } = user;

  const handlePayButtonClick = (subrogationData: Subrogation) => {
    setSubrogation(subrogationData);
    const paymentPlanExists = isPresent(subrogationData.payment_plan);
    if (paymentPlanExists) {
      dispatch(showSubrogationPaymentConfirmationModal({}));
    } else {
      setDisplaySubrogationModal(true);
    }
  };

  const hideSubrogationModal = () => setDisplaySubrogationModal(false);

  useEffect(() => {
    const paymentPlan = subrogation?.payment_plan;
    if (paymentPlan) {
      dispatch(setStartDate(paymentPlan.start_date));
      dispatch(setPaymentPlanDuration(paymentPlan.installments_number));
    }
  }, [subrogation]);

  useEffect(() => {
    if (user?.deposify_account_uid) {
      getRenterFunds();
      getWithdrawalList();
      getDepositList();
    }
  }, [user?.deposify_account_uid]);

  return {
    stripe_public_key,
    my_profile_path,
    hideSubrogationModal,
    handlePayButtonClick,
    policyApplicationPendingStatus,
    onNonRenewalClose,
    closeNonRenewalModal,
    cancelRenewal,
    openRenewalModal,
    closeRenewalModal,
    closeRentersInsuranceModal,
    openRentersInsuranceModal,
    showSecurityDepositOverview,
    showAnyNextStepsCards,
    showActionHeader,
    paymentPlanDuration,
    paymentPlanStartDate,
    displayPaymentConfirmationModal,
    setSelectedWithdrawUid,
    selectedWithdrawUid,
    showCancelWithdrawalModal,
    setShowCancelWithdrawalModal,
    setShowIPActionCard,
    displaySubrogationModal,
    errors,
    isNonRenewalModalOpen,
    displayQuote,
    isRenewalModalOpen,
    isRentersInsuranceModalOpen,
    user,
    needsAttentionData,
    policyApplication,
    nextStepsData,
    subrogation,
    showSubrogationPaymentConfirmationModal,
    renterFunds,
    withdrawalList,
    cashDeposits,
    showEligibleForCoverGeniusRICard,
    policyOverviewData,
    showIPActionCard,
    showAutoRenewCard,
    hideSubrogationPaymentConfirmationModal,
    security_deposit_insurances,
    showAvailableWithdrawalFundsCard,
    dispatch,
    selectedLeaseId,
    selectedPolicyId
  };
};
