import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';

import { ISO_FORMAT } from './utils';

type IProps = {
  value: string | Date;
  className?: string;
  selected?: string | Date;
  minDate?: string | Date;
  maxDate?: string | Date;
  onChange: (value: any) => any;
  inline: boolean;
  onClickOutside: (evt: Event) => any;
};

type IState = {
  selected: moment.Moment;
  maxDate: moment.Moment | null;
  minDate: moment.Moment | null;
};

class Calendar extends Component<IProps, IState> {
  static defaultProps = {
    onChange: () => null,
    className: 'form-control',
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: 'select',
    popperPlacement: 'bottom-end',
    inline: true
  };

  constructor(props: IProps) {
    super(props);

    this.state = this.calculateState(this.props.value);
  }

  handleClickOutside = (evt) => {
    this.props.onClickOutside(evt);
  };

  calculateState = (value: string | Date): IState => {
    const { maxDate, minDate, selected } = this.props;

    return {
      selected: selected ? moment(selected) : moment(value, ISO_FORMAT),
      maxDate: maxDate ? moment(maxDate) : null,
      minDate: minDate ? moment(minDate) : null
    };
  };

  componentWillReceiveProps(nextProps: IProps) {
    const newVal = moment(nextProps.value, ISO_FORMAT, true);

    if (newVal.isValid()) {
      this.setState(this.calculateState(nextProps.value));
    }
  }

  render() {
    // ignoring due to implied overload types
    // @ts-ignore
    return <DatePicker {...this.props} {...this.state} key={Date.now()} />;
  }
}

export default onClickOutside(Calendar);
