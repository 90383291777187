import React from 'react';
import { FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { IResidentialHeroSectionFields } from '../../../../../@types/generated/contentful';
import { HeroSectionSignupForm } from './forms/HeroSectionSignupForm';

export interface HeroSectionProps extends Omit<IResidentialHeroSectionFields, 'heroImage'> {
  heroImage: { url: string; description: string };
}

const HeaderMessage = styled.div([FONTS.brandH1Bold, { fontSize: '60px', lineHeight: '72px', marginBottom: '40px' }]);
const ParagraphMessage = styled.div([FONTS.text2Light]);

const HeroSectionSpacer = styled.div({ marginTop: '56px' });

const HeroSectionContainer = styled.div`
  background: linear-gradient(180deg, #ece5ff 0%, rgba(248, 248, 254, 0) 100%);
`;

const HeroSectionRow = styled.div`
  background: linear-gradient(70deg, white 57%, rgba(255, 255, 255, 0%) 43%);
  justify-content: flex-end;
  /* Override -15px margin on .row from landing_page.scss */
  margin-left: 0px;
  margin-right: 0px;

  @media (max-width: 991.98px) {
    justify-content: center;
  }
`;

const HeroSectionLeftCol = styled.div`
  padding-left: 15px;
  padding-right: 48px;
  margin-top: 12%;
  margin-bottom: 9%;

  @media (max-width: 991.98px) {
    padding-right: 15px;
  }

  /* 
    This col spans the whole left half of the screen since this is a container-fluid, 
    so these max-widths make sure the content aligns with the rest of the bootstrap
    content on the page
  */
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 480px;
  }
  @media (min-width: 1200px) {
    max-width: 570px;
  }
`;

const HeroSectionRightCol = styled.div`
  padding-left: 5%;
  padding-right: 5%;
`;

const ImageWrapper = styled.div`
  width: 100%;

  @media (max-width: 992px) {
    margin-bottom: 9%;
  }

  @media (max-width: 768px) {
    margin-top: 12%;
    margin-bottom: 0%;
  }
`;

const HeroImage = styled.img`
  width: 100%;
`;

export const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const { content, headerText, heroImage, over500UnitsButtonText, under500UnitsButtonText } = props;
  return (
    <HeroSectionContainer className="container-fluid pl-0 pr-0">
      <HeroSectionRow className="row align-items-center">
        <HeroSectionLeftCol className="col col-lg-6">
          <HeaderMessage>{headerText}</HeaderMessage>
          <ParagraphMessage>{content}</ParagraphMessage>
          <HeroSectionSpacer />
          <div className="row">
            <div className="col">
              <HeroSectionSignupForm
                over500UnitsButtonText={over500UnitsButtonText}
                under500UnitsButtonText={under500UnitsButtonText}
              />
            </div>
          </div>
        </HeroSectionLeftCol>
        <HeroSectionRightCol className="col-lg-6 d-none d-lg-inline-block">
          <ImageWrapper>
            <HeroImage src={heroImage.url} alt={heroImage.description} />
          </ImageWrapper>
        </HeroSectionRightCol>
      </HeroSectionRow>
    </HeroSectionContainer>
  );
};
