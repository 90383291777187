import React, { useState } from 'react';

function ExpiredToken() {
  const [hovered, setHovered] = useState(false);
  const expiredText = hovered ? styles.hoveredExpiredText : styles.expiredText;
  const modalScreen = document.getElementsByClassName('fullscreen-modal')[0];
  if (modalScreen) {
    modalScreen.addEventListener('click', () => {
      window.location.reload();
    })
  }

  return (
    <div>
      <div className="sign-up__form" id="ExpiredToken-form" style={styles.container}>
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="expired-box" style={styles.expiredBox}>
              <p style={styles.text}>Session expired</p>
              <button
                style={expiredText}
                onClick={() => window.location.reload() }
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const expiredTextCss = {
  color: 'white',
  fontSize: '16px',
  margin: '12px 18px',
  border: 'none',
  borderRadius: '15px',
  background: '#6318CE',
  padding: '0px 15px 0px 15px'
}
const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    padding: '220px',
    alignItems: 'center'
  },
  container: {
    textAlign: 'center' as 'center'
  },
  expiredBox: {
    borderRadius: '24px',
    width: 'fit-content'
  },
  expiredText: { ...expiredTextCss, cursor: 'default' },
  hoveredExpiredText: { ...expiredTextCss, cursor: 'pointer' },
  text: {
    fontWeight: 600,
    margin: '0px'
  }
};

export default ExpiredToken;
