import styled from '@emotion/styled';
import { ArrowIcon, Button, CloseButton, FONTS, PALETTE, RhinoLogo } from '@sayrhino/rhino-shared-js';
import axios from 'axios';
import Modal from 'react-modal';
import { IDisplayQuote } from './types';
import { IPolicy } from './policyProps';
import Flash from 'components/v2/flash';
import React, { useState } from 'react';
import useGetInsurancePolicy from '../action_center/hooks/useGetInsurancePolicy';

interface IProps {
  isOpen: boolean;
  csrfToken: string;
  closeModal: () => void;
  insurancePolicy?: IPolicy;
  displayQuote?: IDisplayQuote;
  id?: number | undefined;
}

const Container = styled.div({
  width: '576px',
  margin: 'auto',
  '@media (max-width: 600px)': {
    width: '100%'
  }
});

const Header = styled.h3([FONTS.brandH1Bold], {
  fontSize: '44px',
  lineHeight: '56px',
  marginTop: '40px',
  marginBottom: '40px'
});

const SubHeader = styled.p([
  FONTS.p1Light,
  {
    color: `${PALETTE.neutral88}`
  }
]);

const Card = styled.div({
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '16px',
  padding: '24px',
  margin: '40px 0'
});

const CardHeader = styled.h4([FONTS.h4]);

const ButtonWrap = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '40px'
});

const ButtonText = styled.span({
  marginRight: '12px',
  color: PALETTE.neutralLight
});

const LoadingButtonText = styled.span({
  marginRight: '12px',
  color: PALETTE.brand125
});

const ModalBody = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

const Content = styled.p([
  FONTS.p1Medium,
  {
    marginBottom: 0
  }
]);

const Table = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 0',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  '& b': {
    textAlign: 'end'
  }
});

const SmallText = styled.div([
  FONTS.p2Light,
  {
    color: `${PALETTE.neutral65}`,
    marginBottom: '16px'
  }
]);

const TableHeader = styled.div([
  FONTS.p3Light,
  {
    color: `${PALETTE.neutral55}`
  }
]);

const RenewalModal = ({ isOpen, closeModal, insurancePolicy, displayQuote, id }: IProps) => {
  const [renewing, setRenewing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const new_lease_start_date = new Date(displayQuote?.renewal.lease_start_date!);
  const new_lease_end_date = new Date(displayQuote?.renewal.lease_end_date!);

  insurancePolicy === undefined ? (insurancePolicy = useGetInsurancePolicy(id).data) : null;

  const policyId = id ? id : insurancePolicy?.id;

  const styles = {
    content: {
      border: 0,
      borderRadius: 0,
      inset: 0,
      minHeight: '100dvh',
      position: 'fixed'
    },
    overlay: { zIndex: 999 }
  };

  const renewal = async () => {
    try {
      setRenewing(true);

      if (insurancePolicy?.market_type === 'admitted' && !displayQuote?.renewal.use_taktile_for_admitted) {
        await axios.post(`/admitted_renewals/${displayQuote?.renewal.id}/confirm.json`);
      } else {
        await axios.post('/renewals.json', {
          lease: {
            start_date: displayQuote?.renewal.lease_start_date,
            end_date: displayQuote?.renewal.lease_end_date,
            policy_id: policyId
          }
        });
      }
      window.location.pathname = `/users/edit`;
    } catch (error) {
      setRenewing(false);
      Flash.events.emit('flash:new', { text: 'Internal server error.', type: 'danger' });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={styles}>
      <ModalBody>
        <RhinoLogo />
        <CloseButton
          onClick={closeModal}
          style={{ color: PALETTE.neutral25, border: `1px solid ${PALETTE.neutral25}`, background: 'transparent' }}
        />
      </ModalBody>

      <Container>
        <Header>Renew your Rhino policy</Header>
        <SubHeader>
          See the details of your policy for <b>{insurancePolicy?.address_line_one}</b> below.
        </SubHeader>
        <Card>
          <CardHeader>Policy details</CardHeader>
          {displayQuote && (
            <Table>
              <TableHeader>New lease dates</TableHeader>
              <Content>
                {new_lease_start_date.toLocaleString('en-US', {
                  timeZone: 'UTC',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
                &nbsp;-&nbsp;
                {new_lease_end_date.toLocaleString('en-US', {
                  timeZone: 'UTC',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </Content>
            </Table>
          )}
          <Table>
            <TableHeader>Monthly rent</TableHeader>
            <Content>{insurancePolicy?.monthly_rent}</Content>
          </Table>
          <Table>
            <TableHeader>{displayQuote?.renewal.upfront ? 'Payment In Full' : 'Monthly payments'}</TableHeader>
            <Content>
              {displayQuote?.renewal.upfront ? displayQuote?.renewal.upfront_price : displayQuote?.renewal.next_price}
            </Content>
          </Table>
        </Card>
        <SmallText>
          <b>Terms look off?</b> No worries, just continue with renewal and your property manager can edit the policy
          details up to 14 days after the policy renews.
        </SmallText>
        <SmallText>
          {displayQuote?.renewal.upfront
            ? `By clicking "Submit" you'll be charged the full amount due to the card on file.`
            : `By clicking "Submit" you'll be charged your first month's payment to the card on file.`}
        </SmallText>
        <ButtonWrap>
          <Button onClick={() => renewal()} disabled={renewing}>
            {renewing ? (
              <LoadingButtonText>Processing...</LoadingButtonText>
            ) : (
              <>
                <ButtonText>Submit</ButtonText> <ArrowIcon />
              </>
            )}
          </Button>
        </ButtonWrap>
      </Container>
    </Modal>
  );
};

export default RenewalModal;
