import { PALETTE, Button } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

export const Link = styled.a({
  '&&': {
    textDecoration: 'underline',
    textDecorationColor: PALETTE.neutral65,
    color: PALETTE.neutral65
  }
});

export const BackButton = styled(Button)({
  '&&': {
    color: PALETTE.brand88,
    padding: '0'
  }
});
