export const UPDATE_POLICY = 'UPDATE_POLICY';
export const CACHE_POLICY = 'CACHE_POLICY';
export const ACCEPT_CHANGE_REQUEST = 'ACCEPT_CHANGE_REQUEST';
export const UPDATE_CHANGE_REQUEST = 'UPDATE_CHANGE_REQUEST';
export const UPDATE_CAN_CHANGE_PAYMENT_METHOD = 'UPDATE_CAN_CHANGE_PAYMENT_METHOD';
export const UPDATE_ROOMMATES = 'UPDATE_ROOMMATES';
export const ADD_ROOMMATE = 'ADD_ROOMMATE';
export const RENEW_ROOMMATE = 'RENEW_ROOMMATE';
export const REMOVE_ROOMMATE = 'REMOVE_ROOMMATE';
export const UPDATE_CANCELLATION_REQUEST = 'UPDATE_CANCELLATION_REQUEST';

export const updatePolicy = (policyParams) => {
  return {
    type: UPDATE_POLICY,
    policyParams
  };
};

export const cachePolicy = (policyParams) => {
  return {
    type: CACHE_POLICY,
    policyParams
  };
};

export const acceptChangeRequest = () => {
  return { type: ACCEPT_CHANGE_REQUEST };
};

export const updateChangeRequest = (changeRequestParams) => {
  return {
    type: UPDATE_CHANGE_REQUEST,
    changeRequestParams
  };
};

export const updateCanChangePaymentMethod = (value) => {
  return { type: UPDATE_CAN_CHANGE_PAYMENT_METHOD, value };
};

export const updateRoommates = (roommates) => {
  return {
    type: UPDATE_ROOMMATES,
    roommates
  };
};

export const addRoommate = (roommate) => {
  return {
    type: ADD_ROOMMATE,
    roommate
  };
};

export const renewRoommate = (roommate) => {
  return {
    type: RENEW_ROOMMATE,
    roommate
  };
};

export const removeRoommate = (id) => {
  return {
    type: REMOVE_ROOMMATE,
    id
  };
};

export const updateCancellationRequest = (cancellationRequest) => {
  return {
    type: UPDATE_CANCELLATION_REQUEST,
    cancellationRequest
  };
};
