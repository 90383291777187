import React, { Fragment } from 'react';
import { CloseIcon, Button } from '@sayrhino/rhino-shared-js';
import {
  ClaimItem,
  ClaimLabel,
  ClaimValue,
  ConfirmationClaimDiv,
  StyledH3,
  StyledP2,
  GotItButtonContainer,
  TitleContainer,
  StyledModal,
  StyledDiv,
  StyledMainContainer
} from './styles';

import { IClaims, IPaymentPlan } from '../action_center/interfaces';
import { formatCents } from 'utils/money/formatter';
import { addStripeFees } from 'utils/money/add_stripe_fees';
import { useCheckMobile } from '../../../utils/hooks/useCheckMobile';

interface Props {
  claim: IClaims & {
    amount_cents: number;
    insurance_policy_id: number;
    unreimbursed_claim_amount_cents: number;
    total_claim_payouts_cents: number;
    payment_plan_amount_cents: number;
    remaining_debt_cents: number;
  };
  insurance_policy_number: string;
  paymentPlanDuration: number;
  autoChargeDate: string;
  payment_plan: IPaymentPlan;
  onCloseButtonClick?: () => any;
}

const SubrogationPaymentConfirmationModal = ({
  claim,
  insurance_policy_number,
  paymentPlanDuration,
  autoChargeDate,
  payment_plan,
  onCloseButtonClick
}: Props) => {
  const isOneTimePayment = paymentPlanDuration === 1;
  const claimAmount = formatCents(addStripeFees(claim.unreimbursed_claim_amount_cents));
  const paymentPlanAmount = formatCents(addStripeFees(claim.payment_plan_amount_cents));
  const totalPlan = formatCents(claim.total_claim_payouts_cents);
  const remaining = formatCents(claim.remaining_debt_cents);
  const installmentStripeFee = formatCents(payment_plan.installment_stripe_fee_cents);
  const perInstallmentAmountCents = formatCents(payment_plan.per_installment_amount_cents);
  const isMobile = useCheckMobile();
  const goHome = () => window.location.href = '/';

  return (
    <StyledMainContainer>
      {onCloseButtonClick && (
        <div style={{ padding: '1rem 1rem 0 0' }}>
          <CloseIcon onClick={onCloseButtonClick} className="cursor" data-cy="modalClose" />
        </div>
      )}
      <StyledDiv data-cy="confirmModal">
        <StyledModal>
          {isOneTimePayment ? (
            <Fragment>
              <TitleContainer>
                <StyledH3> Thanks! Your payment is confirmed</StyledH3>
                <StyledP2>
                  We processed your payment of {claimAmount}. Save your confirmation details below for your records.
                </StyledP2>
              </TitleContainer>
              <ConfirmationClaimDiv>
                <ClaimItem>
                  <ClaimLabel>Policy Number</ClaimLabel>
                  <ClaimValue>{insurance_policy_number}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Claim Number</ClaimLabel>
                  <ClaimValue>#{claim.claim_number}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Outstanding Claim Balance</ClaimLabel>
                  <ClaimValue>{remaining}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Amount Paid Today</ClaimLabel>
                  <ClaimValue>{claimAmount}</ClaimValue>
                </ClaimItem>
              </ConfirmationClaimDiv>
            </Fragment>
          ) : (
            <Fragment>
              <TitleContainer>
                <StyledH3> Thanks! Your payment is confirmed</StyledH3>
                {isMobile ? (
                  <StyledP2>
                    We processed your payment of {paymentPlanAmount}. Funds will automatically be withdrawn monthly on
                    the {autoChargeDate} for the length of your payment plan. Save the confirmation details below for
                    your records.
                  </StyledP2>
                ) : (
                  <StyledP2>
                    We processed your monthly payment of {paymentPlanAmount} ({perInstallmentAmountCents} +
                    {installmentStripeFee} processing fee), towards your total claim amount of{' '}
                    {totalPlan}. This means your remaining balance is now {remaining}. Funds will automatically be
                    withdrawn monthly for the length of your payment plan. Save the confirmation details below for
                    your records.
                  </StyledP2>
                )}
              </TitleContainer>
              <ConfirmationClaimDiv>
                <ClaimItem>
                  <ClaimLabel>Policy Number</ClaimLabel>
                  <ClaimValue>{insurance_policy_number}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Claim Number</ClaimLabel>
                  <ClaimValue>#{claim.claim_number}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Outstanding Claim Balance</ClaimLabel>
                  <ClaimValue>{remaining}</ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Pay-Over-Time</ClaimLabel>
                  <ClaimValue>
                    {perInstallmentAmountCents} + {installmentStripeFee} processing fee, auto-pay monthly
                  </ClaimValue>
                </ClaimItem>
                <ClaimItem>
                  <ClaimLabel>Auto-Charge Date</ClaimLabel>
                  <ClaimValue>{autoChargeDate} of each month</ClaimValue>
                </ClaimItem>
              </ConfirmationClaimDiv>
            </Fragment>
          )}
          <GotItButtonContainer>
            <Button id="got-it-button" onClick={onCloseButtonClick || goHome}>
              Got it, Thanks!
            </Button>
          </GotItButtonContainer>
        </StyledModal>
      </StyledDiv>
    </StyledMainContainer>
  );
};
export { SubrogationPaymentConfirmationModal };
