import React from 'react';
import styled from '@emotion/styled';
import { FONT_FAMILY, Button, PALETTE, CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { CGOptions } from './RentersInsurancePolicyCard';

// mobile
const continueButtonStyle = {
  width: '100%',
  height: '48px',
  fontSize: '16px',
  justifyContent: 'center'
};

const cancelButtonStyle = {
  width: '100%',
  height: '48px',
  fontSize: '16px',
  justifyContent: 'center'
};

const OuterDiv = styled.div({
  position: 'fixed',
  height: '100dvh',
  width: '100%',
  bottom: 0,
  left: 0,
  background: PALETTE.white,
  zIndex: 999999
});

const MainDiv = styled.div([
  FONT_FAMILY.extendedDemi,
  {
    fontSize: 24,
    lineHeight: '36px',
    textAlign: 'center',
    padding: '0 7%',
    marginBottom: '12px'
  }
]);

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90%',
  flexDirection: 'column'
});

const ContainerDiv = styled.div({
  margin: '30px 30px 0 30px'
});

const ButtonPosition = styled.div({
  width: '90%',
  left: '20px',
  display: 'flex',
  flexDirection: 'column'
});

const ContentPosition = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '70%'
});

// desktop
const ModalWrapper = styled.div({
  position: 'fixed',
  zIndex: 1300,
  inset: 0
});

const ModalContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
});

const desktopContinueButtonStyle = {
  minWidth: '187px',
  height: '48px',
  fontSize: '16px',
  justifyContent: 'center'
};

const desktopCancelButtonStyle = {
  minWidth: '187px',
  height: '48px',
  fontSize: '16px',
  justifyContent: 'center'
};

const BackdropDiv = styled.div({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  zIndex: -1,
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 1,
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
});

const DesktopButtonPosition = styled.div({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center'
});

const DesktopContainerDiv = styled.div({
  backgroundColor: PALETTE.white,
  padding: '30px 45px 60px',
  width: '580px',
  borderRadius: '5px'
});

const DesktopMainDiv = styled.div([
  FONT_FAMILY.extendedDemi,
  {
    fontSize: 24,
    lineHeight: '36px',
    textAlign: 'center',
    padding: '10px'
  }
]);

// both
const TopDiv = styled.div({
  margin: '8px 0px'
});

const ButtonPadding = styled.div({
  padding: '10px'
});

const CloseIconContainer = styled.div({
  paddingBottom: '30px',
  justifyContent: 'right',
  textAlign: 'right'
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer',
  textAlign: 'right'
});

interface ICoverGeniusRedirectProps {
  onClose: () => void;
  isOpen: boolean;
  redirectAction: string | null;
  policyWordingUrl: string;
  claimUrl: string;
}

const CoverGeniusRedirect: React.FC<ICoverGeniusRedirectProps> = ({
  onClose,
  isOpen,
  redirectAction,
  policyWordingUrl,
  claimUrl
}: ICoverGeniusRedirectProps) => {
  const isMobile = useCheckMobile();

  React.useEffect(() => {
    const bodyRef = document.getElementsByTagName('body')[0];
    if (isOpen) {
      bodyRef.style.overflow = 'hidden';
    } else {
      bodyRef.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const redirectText = redirectAction === CGOptions.FILE_CLAIM ? 'To file a claim' : 'To see your policy';
  const redirectUrl = redirectAction === CGOptions.FILE_CLAIM ? claimUrl : policyWordingUrl;
  const dataCy = redirectAction === CGOptions.FILE_CLAIM ? 'fileAClaimModal' : 'policyDetailsModal';

  if (isMobile) {
    return (
      <OuterDiv>
        <ContainerDiv>
          <TopDiv>
            <CloseIconContainer>
              <CloseIcon onClick={onClose} data-cy="modalCancel" />
            </CloseIconContainer>
          </TopDiv>
        </ContainerDiv>
        <ContentPosition>
          <ContentWrapper>
            <MainDiv>{redirectText}, you’ll need to visit XCover.com</MainDiv>
            <ButtonPosition>
              <ButtonPadding>
                <Button
                  variant="primary"
                  style={continueButtonStyle}
                  onClick={() => window.open(redirectUrl)}
                  data-cy="continueToXcover"
                >
                  Continue to XCover
                </Button>
              </ButtonPadding>
              <ButtonPadding>
                <Button variant="secondary" style={cancelButtonStyle} onClick={onClose} data-cy="cancel">
                  Cancel
                </Button>
              </ButtonPadding>
            </ButtonPosition>
          </ContentWrapper>
        </ContentPosition>
      </OuterDiv>
    );
  } else {
    return (
      <ModalWrapper onClick={onClose} data-cy={dataCy}>
        <BackdropDiv />
        <ModalContentWrapper>
          <DesktopContainerDiv onClick={(e) => e.stopPropagation()}>
            <TopDiv>
              <CloseIconContainer>
                <CloseIcon onClick={onClose} />
              </CloseIconContainer>
              <DesktopMainDiv>{redirectText}, you’ll need to visit XCover.com</DesktopMainDiv>
            </TopDiv>
            <DesktopButtonPosition>
              <ButtonPadding>
                <Button variant="secondary" style={desktopCancelButtonStyle} onClick={onClose} data-cy="cancel">
                  Cancel
                </Button>
              </ButtonPadding>
              <ButtonPadding>
                <Button
                  variant="primary"
                  style={desktopContinueButtonStyle}
                  onClick={() => window.open(redirectUrl)}
                  data-cy="continueToXcover"
                >
                  Continue to XCover
                </Button>
              </ButtonPadding>
            </DesktopButtonPosition>
          </DesktopContainerDiv>
        </ModalContentWrapper>
      </ModalWrapper>
    );
  }
};

export default CoverGeniusRedirect;
