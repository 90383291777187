import DateInput from 'components/common/form/date-picker';
import moment from 'moment';

import PasswordInput from 'components/v2/input/passwordInput';
import React, { ChangeEvent, Component, KeyboardEvent, SyntheticEvent } from 'react';

import PhoneInput from 'components/common/form/masked-input/masked-input';
import ReactCurrencyInput from 'react-currency-input-field';

type InputValue = number | string | Date | undefined;

type DatePickerProps = { placeholderText?: string; autofocus?: boolean };

interface IProps {
  placeholder?: string;
  className?: string;
  id?: string;
  label?: string;
  type?: string;
  value?: InputValue;
  onChangeString?: (val: string) => void;
  onChangeNumber?: (val: number | undefined) => void;
  onChangeMasked?: (val: string) => void;
  onChangeDate?: (val: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  resource?: string;
  field?: string;
  datePicker?: DatePickerProps;
  minDate?: string | Date;
  maxDate?: string | Date;
  selected?: string | Date;
  toggleVisibility?: boolean;
  borderColor?: string;
  disabled?: boolean;
  precision?: number;
  dataCy?: string;
  currency?: {
    maxLength?: number;
  };
}

function inputValueMaybeString(x: InputValue | undefined): x is string | undefined {
  return typeof x === 'string' || typeof x === 'undefined';
}

interface IState {
  value?: number | string | Date;
}

class Input extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { value } = this.props;
    this.state = { value };
  }

  public currencyInputProps = () => {
    const { field, resource, precision, className } = this.props;

    return {
      decimalScale: precision || 0,
      id: this.fieldId(),
      name: resource && field ? `${resource}[${field}]` : '',
      className: `landing-page--input landing-page-currency input ${className}`,
      prefix: '$',
      onValueChange: this.onChangeCurrency,
      value: this.props.value as string,
      disabled: this.props.disabled,
      'data-cy': this.props.dataCy,
      ...this.props.currency
    };
  };

  fieldId = () => {
    const { resource, field, type } = this.props;
    let { id } = this.props;

    if (id) {
      return id;
    }

    id = resource + '_' + field;
    if (type === 'currency') {
      id += '_currency';
    }
    return id;
  };

  public renderInput = () => {
    const {
      type,
      className,
      datePicker,
      placeholder,
      maxLength,
      resource,
      field,
      value,
      minDate,
      maxDate,
      toggleVisibility,
      borderColor,
      onChangeString,
      onKeyDown,
      selected,
      disabled = false,
      dataCy
    } = this.props;

    const id = this.fieldId();

    if (type === 'currency') {
      return <ReactCurrencyInput autoFocus={false} {...this.currencyInputProps()} />;
    } else if (type === 'password' && inputValueMaybeString(value)) {
      return (
        <PasswordInput
          styles={borderColor ? { ...styles.inputStyle, borderBottom: `1px solid ${borderColor}` } : styles.inputStyle}
          resource={resource ? resource : 'user'}
          toggleVisibility={toggleVisibility}
          className={className}
          placeholder={placeholder}
          onChangeString={onChangeString}
          field={field ? field : 'password'}
          value={value}
          dataCy={dataCy}
        />
      );
    } else if (type === 'phone' && inputValueMaybeString(value)) {
      return (
        <PhoneInput
          name={resource + '[' + field + ']'}
          id={id}
          resource={resource}
          placeholder="Phone number"
          mask="999-999-9999"
          value={value}
          className={className}
          style={className ? {} : styles.inputStyle}
          onChange={this.onChangeMasked}
          disabled={disabled}
          type="tel"
          dataCy={dataCy}
        />
      );
    } else if (type === 'date') {
      return (
        <DateInput
          field={field}
          resource={resource}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          className={className}
          selected={selected}
          pickerProps={{ placeholderText: datePicker?.placeholderText, className }}
          style={className ? {} : styles.inputStyle}
          onChange={this.onChangeDate}
          disabled={disabled}
          dataCy={dataCy}
        />
      );
    } else {
      return (
        <input
          style={className ? {} : styles.inputStyle}
          className={className}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          value={value?.toString()}
          type={type ? type : 'text'}
          onChange={this.onChangeString}
          resource={resource}
          name={resource + '[' + field + ']'}
          id={id}
          data-cy={dataCy}
        />
      );
    }
  };

  public render() {
    const { label } = this.props;
    const id = this.fieldId();

    return (
      <div>
        {this.renderInput()}
        {label && (
          <label htmlFor={id} style={styles.label}>
            {label}
          </label>
        )}
      </div>
    );
  }

  private onChangeCurrency = (value?: string) => {
    const numValue = value ? +value : 0;
    this.setState({ value: numValue });
    if (this.props.onChangeNumber) {
      this.props.onChangeNumber(numValue);
    }
  };

  private onChangeString = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
    if (this.props.onChangeString) {
      this.props.onChangeString(event.target.value);
    }
  };

  private onChangeMasked = (event: SyntheticEvent, pureValue: string) => {
    this.setState({ value: pureValue });
    if (this.props.onChangeMasked) {
      this.props.onChangeMasked(pureValue);
    }
  };

  private onChangeDate = (date: moment.Moment) => {
    const momentValue = moment(date, 'YYYY-MM-DD');
    const formattedDate = momentValue.isValid() ? momentValue.format('YYYY-MM-DD') : '';
    this.setState({ value: formattedDate });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(formattedDate);
    }
  };
}

export const styles = {
  label: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'black',
    textAlign: 'center' as 'center',
    marginTop: '5px',
    display: 'block'
  },
  inputStyle: {
    width: '100%',
    height: '40px',
    color: 'black',
    fontSize: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'MaisonNeueLight',
    lineHeight: '28px',
    padding: '0px',
    margin: '0px',
    backgroundColor: 'transparent',
    borderRadius: '0',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #E3E3E3'
  }
};

export default Input;
