import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import Carousel from '../carousel';
import { Spacer48 } from './Spacers';

export interface LogosModuleProps {
  sectionTitle: string;
  logos: [{ url: string; description: string }];
}

const SectionHeader = styled.div([FONTS.h5, { marginBottom: 48 }]);

const LogoContainer = styled.div({
  maxWidth: '192px',
  '@media (max-width: 1200px)': {
    maxWidth: '168px'
  }
});

const Logo = styled.img({
  width: '100%'
});

export const LogosModule: React.FC<LogosModuleProps> = (props) => {
  const { sectionTitle, logos } = props;
  const imageUrlsArray = logos.map((logo) => logo.url);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col">
          <SectionHeader className="d-flex justify-content-center">{sectionTitle}</SectionHeader>
          {/* Hide this on all screen sizes below large... */}
          <div className="d-none d-lg-flex flex-wrap justify-content-around align-items-center">
            {logos.map((logo, index) => {
              return (
                <div key={index}>
                  <LogoContainer className="d-flex justify-content-center">
                    <Logo src={logo.url} alt={logo.description} />
                  </LogoContainer>
                  {/* If we have more than 5 logos, we wrap to 2 rows. Add spacing below the first row. */}
                  {logos.length > 5 && index < Math.floor(logos.length / 2) && <Spacer48 />}
                </div>
              );
            })}
          </div>
          {/* ...and replace it with a carousel: */}
          <div className="d-lg-none" id="mark-carousel">
            <Carousel
              type="media"
              carouselOptions={{
                loop: false,
                mouseDrag: true,
                nav: false,
                mobileCardOverflow: true,
                desktopCardOverflow: false,
                responsive: {
                  0: {
                    items: 2
                  },
                  500: {
                    items: 3
                  },
                  1000: {
                    items: 4
                  }
                }
              }}
              itemOptions={{}}
              headers={imageUrlsArray}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
