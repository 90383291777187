import React, { FC, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IClaims, IPaymentPlan } from '../action_center/interfaces';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import SetupSubroPaymentPlans from './subroForm';
import env from 'utils/env';

const queryClient = new QueryClient();

export type Subrogation = {
  claim: IClaims & {
    amount_cents: number;
    insurance_policy_id: number;
    id: number;
    unreimbursed_claim_amount_cents: number;
  };
  user: User;
  property: Property;
  insurance_policy_number: string;
  stripe_payment_method_configuration: string;
  stripe_publishable_key: string;
  payment_plan: IPaymentPlan;
};

type Props = Subrogation & { onCancelButtonClick?: () => void; onPayButtonClick: (subrogation: Subrogation) => void };

type User = {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
};

type Property = {
  address_line_one: string;
};

const SetupSubroPaymentPlansWrapper: FC<Props> = ({
  claim,
  user,
  property,
  insurance_policy_number,
  stripe_payment_method_configuration,
  stripe_publishable_key,
  onCancelButtonClick,
  onPayButtonClick
}) => {
  const stripePromise = useMemo(() => loadStripe(stripe_publishable_key), [stripe_publishable_key]);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      spacingGridRow: '16px',
      spacingGridColumn: '16px'
    }
  };

  const options: any = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false
    },
    appearance,
    mode: 'setup',
    currency: 'usd',
    payment_method_configuration: stripe_payment_method_configuration
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupSubroPaymentPlans
        claim={claim}
        user={user}
        property={property}
        insurance_policy_number={insurance_policy_number}
        stripe_publishable_key={stripe_publishable_key}
        onCancelButtonClick={onCancelButtonClick}
        onPayButtonClick={onPayButtonClick}
      ></SetupSubroPaymentPlans>
    </Elements>
  );
};

const SubrogationPlans: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SetupSubroPaymentPlansWrapper {...props} />
    </QueryClientProvider>
  );
};

export default SubrogationPlans;
