import React from 'react';
import { PolicyCard } from './PolicyCard';
// @ts-ignore
import HomeSVG from '../assets/home.svg';
// @ts-ignore
import KeySVG from '../assets/key.svg';
import moment from 'moment';
import styled from '@emotion/styled';
import { PALETTE, ButtonHabitat as ButtonBase } from '@sayrhino/rhino-shared-js';
import { PolicyApplication } from 'components/v2/signup/redux/state';
import { IResult } from 'components/v2/signup/redux/actions';
import { NoActivePolicies } from './NoActivePolicies';
import { SectionHeader } from '../styles';
import { ICancellationRequest } from 'components/v2/insurance_policies/policyProps';

interface Props {
  SDIs:
    | {
        status: string;
        coverageStartDate: string;
        coverageEndDate: string;
        isUpfront: boolean;
        address: string;
        policyNumber: string;
        policyId: number;
        policyApplicationId: number;
        cancellationRequest: ICancellationRequest;
      }[]
    | undefined;
  RIs:
    | {
        status: string;
        coverageStartDate: string;
        coverageEndDate: string;
        isUpfront: boolean;
        address: string;
        insNumber: string;
      }[]
    | undefined;
  policyApplication?: IResult<PolicyApplication>;
}

const ViewPoliciesButtonWrapper = styled.div({
  marginLeft: 'auto',
  marginRight: 0,
  width: '100%',
  '@media (max-width: 992px)': {
    marginBottom: 24
  }
});
const ViewPoliciesButton = styled(ButtonBase)({
  '&&': {
    textAlign: 'center',
    color: PALETTE.neutralDark,
    backgroundColor: PALETTE.neutralLight,
    margin: '16px 0px auto auto',
    '@media (max-width: 992px)': {
      margin: '16px auto auto auto'
    }
  }
});

const getSDILabel = (status: string, coverageStartDate: string, coverageEndDate: string) => {
  if (status === 'Active') {
    return `Coverage ends ${coverageEndDate}`;
  } else {
    return `Coverage starts ${coverageStartDate}`;
  }
};

const getRILabel = (status: string, isUpfront: boolean, coverageStartDate: string, coverageEndDate: string) => {
  if (status === 'Active') {
    const formattedCoverageEndDate = moment.utc(coverageEndDate).format('MMM DD, YYYY');
    return isUpfront ? `Coverage ends ${formattedCoverageEndDate}` : `Coverage is continuous`;
  } else {
    const formattedCoverageStartDate = moment.utc(coverageStartDate).format('MMM DD, YYYY');
    return `Coverage starts ${formattedCoverageStartDate}`;
  }
};

export const Policies = (props: Props) => {
  const SDIs = props.SDIs || [];
  const RIs = props.RIs || [];
  const policyApplication = props.policyApplication?.result;

  const policyApplicationAddress = policyApplication?.address_line_two
    ? `${policyApplication.address_line_one}, ${policyApplication.address_line_two}`
    : policyApplication?.address_line_one;

  const getEnrollmentURL = () => {
    if (policyApplication?.from_native_enrollment)
      return `/partners/${policyApplication.partner_id}/${policyApplication.source}/users`;

    if (policyApplication?.partial_quote)
      return `/enroll/invitation/${policyApplication?.signup_step}`;

    return `/enroll/${policyApplication?.signup_step}`;
  }

  const finalizeUrl = getEnrollmentURL();

  const policyStartDate = moment(policyApplication?.lease_start_date).format('MMM D, YYYY');
  const policyEndDate = moment(policyApplication?.lease_end_date).format('MMM D, YYYY');
  const policyDates = `${policyStartDate} - ${policyEndDate}`;

  // should line up with whether the application is either in one of the cash deposit processes
  // or a cash deposit has been paid
  const currentApplicationIsCashDepositFunded =
    policyApplication?.cash_deposit_status && policyApplication.cash_deposit_status !== 'selected';

  const activeSDIs = SDIs.filter((sdi) => !['Expired', 'Canceled'].includes(sdi.status));

  // The stored policy application object won't update upon SDI finalizing, so we use SDI policy application
  // ids to determine if the current application has acquired a finalized policy since page load
  // Cash deposit status is set to paid synchronously, so we don't need to check whether that has changed
  const currentApplicationHasSDI =
    policyApplication?.id && SDIs.map((sdi) => sdi.policyApplicationId).includes(policyApplication.id);

  // this is kind of gross, but it will help us make sure the section header only shows when something is in the section
  const showCurrentApplicationCard =
    // general requirements for both/all types
    policyApplication && !policyApplication.is_finalized && !currentApplicationHasSDI &&
    // check requirements for specific cards
    (policyApplication.is_in_progress || (policyApplication.submitted && policyApplication.sdi_selected));

  const showNoActivePoliciesCard =
    !activeSDIs.length && !showCurrentApplicationCard && !currentApplicationIsCashDepositFunded;

  const showPolicyOverview = !!SDIs.length || !!RIs.length;

  const getCurrentApplicationCard = () => {
    if (!policyApplication || policyApplication.is_finalized) return null;

    if (policyApplication.is_in_progress) {
      return (
        <PolicyCard
          title="Application in progress"
          address={policyApplicationAddress ? policyApplicationAddress : ''}
          policyDates={policyDates}
          isActive
          imageUrl={HomeSVG}
          inProgressCard={true}
          finalizeUrl={finalizeUrl}
        />
      );
    }

    if (policyApplication.submitted && policyApplication.sdi_selected) {
      return (
        <PolicyCard
          title="Security Deposit Insurance"
          address={policyApplicationAddress ? policyApplicationAddress : ''}
          isActive
          imageUrl={HomeSVG}
          status={'Submitted'}
          label={'Creating policy'}
        />
      );
    }
  };

  return (
    <>
      {showNoActivePoliciesCard && <NoActivePolicies imageUrl={HomeSVG} />}

      {showPolicyOverview && <SectionHeader>Policy Overview</SectionHeader>}

      {SDIs.map((policy, index) => {
        const {
          status,
          isUpfront,
          policyNumber,
          address,
          coverageStartDate,
          coverageEndDate,
          policyId,
          cancellationRequest
        } = policy;

        const label = getSDILabel(status, coverageStartDate, coverageEndDate);
        return (
          <PolicyCard
            key={index}
            title="Security Deposit Insurance"
            address={address}
            label={label}
            status={status}
            policyNumber={policyNumber}
            isActive
            paymentType={isUpfront ? 'annually' : 'monthly'}
            imageUrl={HomeSVG}
            link={`/insurance_policies/${policyId}`}
            cancellationRequest={cancellationRequest}
          />
        );
      })}
      {RIs.map((policy, index) => {
        const { status, isUpfront, insNumber, address, coverageStartDate, coverageEndDate } = policy;
        const label = getRILabel(status, isUpfront, coverageStartDate, coverageEndDate);

        return (
          <PolicyCard
            key={index}
            title="Renters Insurance"
            address={address}
            label={label}
            status={status}
            policyNumber={insNumber}
            isActive={status === 'Active'}
            paymentType={isUpfront ? 'annually' : 'monthly'}
            imageUrl={KeySVG}
            link={'/policies'}
          />
        );
      })}

      {showCurrentApplicationCard && (
        <>
          <SectionHeader>Applications</SectionHeader>
          {getCurrentApplicationCard()}
        </>
      )}

      {(showPolicyOverview || showCurrentApplicationCard) && (
        <ViewPoliciesButtonWrapper>
          <ViewPoliciesButton
            id="view-policies-button"
            variant="black"
            usage="secondary"
            size="small"
            onClick={() => (window.location.href = `/policies`)}
          >
            View all policies
          </ViewPoliciesButton>
        </ViewPoliciesButtonWrapper>
      )}
    </>
  );
};
