import React from 'react';
import moment from 'moment';

import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

import { ICancellationRequest } from '../../policyProps'

interface IProps {
  insurancePolicyId: string | number;
  currentRequest: ICancellationRequest | null;
  coverageEndDate: string;
}

const CancellationRequestButton = (props: IProps) => {
  const {
    currentRequest,
    insurancePolicyId,
    coverageEndDate
  } = props;

  const Button = styled.button([
    FONTS.p2Extended,
    {
      '&&': {
        border: `1px solid ${PALETTE.neutralDark}`,
        borderRadius: 24,
        fontWeight: 500,
        backgroundColor: PALETTE.white,
        marginRight: 12,
        padding: '8px 20px'
      }
    }
  ]);

  const DisabledButton = styled(Button)(
    {
      '&&': {
        backgroundColor: '#d4d4d4',
        color: 'white',
        borderColor: '#a3a3a3',
        opacity: 0.7
      }
    }
  )

  const handleCancellationButtonClicked = () => {
    window.location.href = `/v2/insurance_policies/${insurancePolicyId}/cancellation_requests/new`;
  }

  let btnTooltip;
  let allowNewRequests = true;

  if (currentRequest) {
    if (currentRequest.status === 'declined') {
      const { denial_reason, denial_locking_period } = currentRequest;

      if (['other', 'no_indicated_plans'].includes(denial_reason) || !denial_locking_period) {
        btnTooltip = 'Additional cancellation requests are not allowed';
        allowNewRequests = false;
      } else if (moment.utc().isBefore(denial_locking_period)) {
        btnTooltip = `You can request a new cancellation on ${moment(denial_locking_period).format('MMMM D, YYYY')}`;
        allowNewRequests = false;
      }
    } else if (['pending', 'accepted'].includes(currentRequest.status)) {
      btnTooltip = 'You already have an active cancellation request';
      allowNewRequests = false;
    } 
  }

  const isTodayCoverageEndDate = React.useMemo(
    () => moment(coverageEndDate).isSame(moment(), 'day'), [coverageEndDate]
  )

  if (isTodayCoverageEndDate) {
    btnTooltip = 'Your coverage is already scheduled to end today, no cancellation request is required';
    allowNewRequests = false;
  }

  return (
    <div>
      {allowNewRequests ? (
        <Button
          onClick={handleCancellationButtonClicked}
          id="cancellationButton"
        >
          Request Policy Cancellation
        </Button>
      ) : (
        <div title={btnTooltip}><DisabledButton disabled={true}>Request Policy Cancellation</DisabledButton></div>
      )}
    </div>
  );
};

export default CancellationRequestButton;
