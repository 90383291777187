/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react';
import { jsx } from '@emotion/react';
import { Button, CloseButton, PALETTE, FONTS, Radio } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import axios from 'axios';
import useGetInsurancePolicy from '../action_center/hooks/useGetInsurancePolicy';
interface IProps {
  onNonRenewalCloseModal: () => void;
  onNonRenewalClose: (reason: string) => void;
  onRenewalModal: (event: React.MouseEvent) => void;
  userId: number;
  insuranceApplicationId?: number;
  policyAppId?: number;
}

interface IReasons {
  reasons: IReason[];
}

interface IReason {
  reason: string;
  value: number;
}

export const RenewalWarningModal = (props: IProps) => {
  const { userId } = props;
  const [cancelRenewal, setCancelRenewal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [reasons, setReasons] = useState<IReason[]>([]);

  const insurancePolicy =
    !props.policyAppId || !props.insuranceApplicationId ? null : useGetInsurancePolicy(userId).data;

  const policyAppId = props.policyAppId ? props.policyAppId : insurancePolicy?.policy_app_id;
  const insuranceApplicationId = props.insuranceApplicationId
    ? props.insuranceApplicationId
    : Number(insurancePolicy?.policy_id);

  const ModalContainer = styled.div({
    display: 'flex',
    maxWidth: '574px',
    backgroundColor: PALETTE.white,
    border: '1px solid #BFBFBF',
    borderRadius: 8,
    margin: 'auto',
    flexDirection: 'column',
    padding: '20px',
    '@media (max-width: 768px)': {
      border: 0
    }
  });

  const ContentContainer = styled.div({
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '38px 0px 50px 0px',
    '@media (max-width: 768px)': {
      paddingTop: '150px',
      alignItems: 'center'
    }
  });

  const TextContainer = styled.div({
    padding: '32px 0px 0px 0px',
    flexDirection: 'row',
    '@media (max-width: 400px)': {
      padding: '5px 0px 0px 0px'
    }
  });

  const Header = styled.h3([FONTS.h3], {
    textAlign: 'left',
    '@media (max-width: 980px)': {
      textAlign: 'center'
    }
  });

  const Paragraph = styled.p([FONTS.p1], {
    '@media (max-width: 980px)': {
      textAlign: 'center'
    }
  });

  const HeaderContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end'
  });

  const ButtonContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2rem',
    alignItems: 'baseline',
    '@media (max-width: 980px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  const CancelContainer = styled.div({
    '@media (max-width: 980px)': {
      padding: '0px 0px 10px 0px',
      width: '100%'
    }
  });

  const CancelButton = styled(Button)({
    '&&': {
      backgroundColor: PALETTE.brand100,
      width: '100%',
      justifyContent: 'center'
    }
  });

  const NonRenewalButton = styled(Button)({
    '&&': {
      color: PALETTE.brand88,
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  });

  const getReasons = async () => {
    const url = '/policy_non_renewal_reasons.json';
    const { data } = await axios.get(url, {
      headers: { Accept: 'application/json' }
    });
    const response: IReasons = data;
    setReasons(response.reasons);
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <ModalContainer>
      <HeaderContainer>
        <CloseButton
          onClick={props.onNonRenewalCloseModal}
          style={{ color: PALETTE.gray3, border: `1px solid ${PALETTE.gray3}`, background: 'transparent' }}
        />
      </HeaderContainer>
      {!cancelRenewal ? (
        <Fragment>
          <ContentContainer>
            <Header>Are you sure you do not want to renew your coverage?</Header>
            <TextContainer>
              <Paragraph>
                Your property has a security deposit requirement. If you don’t renew your policy but aren’t moving out,
                <strong> you may be required to pay a cash security deposit in full.</strong>
              </Paragraph>
            </TextContainer>
          </ContentContainer>
          <ButtonContainer>
            <NonRenewalButton onClick={props.onRenewalModal} variant="secondary">
              No, I still want coverage
            </NonRenewalButton>
            <CancelContainer>
              <CancelButton onClick={() => setCancelRenewal(true)}>Yes, cancel my renewal</CancelButton>
            </CancelContainer>
          </ButtonContainer>
        </Fragment>
      ) : (
        <Fragment>
          <ContentContainer>
            <Header>We’re sorry to see you go</Header>
            <TextContainer>
              <Paragraph>Please tell us why you aren’t planning on renewing your policy.</Paragraph>
            </TextContainer>
            <Radio.Root
              name="teams"
              value={selected}
              onValueChange={(value) => {
                setSelected(value);
              }}
            >
              {reasons.map((list: IReason, index) => (
                <Radio.Option key={index} value={list.value.toString()} label={list.reason} />
              ))}
            </Radio.Root>
          </ContentContainer>
          <ButtonContainer>
            <NonRenewalButton
              onClick={props.onNonRenewalCloseModal}
              variant="secondary"
            >
              Never mind
            </NonRenewalButton>
            <CancelContainer>
              <CancelButton disabled={!selected} onClick={() => props.onNonRenewalClose(selected)}>
                Cancel Renewal
              </CancelButton>
            </CancelContainer>
          </ButtonContainer>
        </Fragment>
      )}
    </ModalContainer>
  );
};

export default RenewalWarningModal;
