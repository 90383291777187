import React, { Component, ReactNode } from 'react';
import { activeClasses, PaymentOption } from './utils';

export interface IProps {
  paymentOption: PaymentOption;
  onChange: (PaymentOption) => void;
  price: {
    upfront_discount?: string;
    upfront: string;
    monthly: string;
  };
}

class QuoteOptionToggle extends Component<IProps> {
  public render(): ReactNode {
    const { paymentOption, onChange, price } = this.props;
    const { Upfront, Monthly } = PaymentOption;
    const upfront = paymentOption === Upfront;
    const monthly = paymentOption === Monthly;
    return (
      <div className="row no-gutters">
        <div className="col-lg-6 col-sm-12 payment-interval-container">
          <div
            onClick={() => onChange(Upfront)}
            className={activeClasses(upfront, 'payment-interval-option')}
            id="payment-option-annual"
            data-cy="priceFull">
            <div className="header">
              <p className="description">In full</p>
              <div className={activeClasses(upfront, 'checkbox')}>
              </div>
            </div>
            <h3 className="bold">{price.upfront}</h3>
            <p className="p3">
              Pay for your policy in full and don’t worry about it for the
              rest of your lease.
            </p>
            {price.upfront_discount && (
              <span className="text-2 medium">
                Get a {price.upfront_discount} discount
              </span>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 payment-interval-container">
          <div
            onClick={() => onChange(Monthly)}
            className={activeClasses(monthly, 'payment-interval-option')}
            id="payment-option-monthly"
            data-cy="priceMonthly">
            <div className="header">
              <p className="description">Per month</p>
              <div className={activeClasses(monthly, 'checkbox')}>
              </div>
            </div>
            <h3 className="bold">{price.monthly}</h3>
            <p className="p3">
              We’re flexible. Renters can pay for their Rhino policy on a
              monthly basis.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteOptionToggle;
