import React from 'react';
import MapWithOverlay from '../map_with_overlay';
import { useAssetContext } from './asset_context';
import styled from '@emotion/styled';
import { RhinoLogo, CloseIcon } from '@sayrhino/rhino-shared-js';
import { IProperty } from 'types/algolia/property';
import env from 'utils/env';
import { IUnit } from 'types/algolia/unit';

interface RequiresInvitationModalProps {
  onCloseModal: () => void;
  property: IProperty;
  unit?: Partial<IUnit>;
}
const RequiresInvitationModal = ({ onCloseModal, property, unit }: RequiresInvitationModalProps) => {
  const assets = useAssetContext();
  const full_address_string = property.full_address + ', ' + 'United States';
  const GOOGLE_API_KEY = env('GOOGLE_API_KEY');
  const mapProps = {
    map: {
      apiKey: GOOGLE_API_KEY,
      location: full_address_string,
      size: {
        width: 385,
        height: 640
      }
    },
    overlay: {
      address: {
        name: property.building_name,
        streetAddress: property.address_line_one,
        city: property.address_city,
        unit: unit?.name ? unit?.name : 'N/A',
        state: property.address_state,
        zipCode: property.address_zip
      }
    }
  };

  const mapWithOverlayProps = {
    ...mapProps,
    wrapperId: 'desktop-card',
    assets: assets.map
  };

  const HeaderContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 60px 0px'
  });

  return (
    <div className="flow-step--page">
      <div className="container">
        <HeaderContainer>
          <RhinoLogo />
          <CloseIcon onClick={onCloseModal} data-cy="modalCancel" />
        </HeaderContainer>
        <div className="row">
          <div className="col-lg-5 col-sm-12 col-xs-12 d-lg-block order-last order-lg-first flow-step--left-panel">
            <MapWithOverlay {...mapWithOverlayProps} />
          </div>
          <div
            className="col-lg-7 col-sm-12 col-xs-12 d-lg-block order-first order-lg-last flow-step--right-panel"
            style={{ marginBottom: '56px' }}
          >
            <div>
              <h2 className="bold header">Signing up for Rhino at this property requires an invitation</h2>
              <p className="p2">
                Don’t worry! Many applicants receive an email invitation to sign-up for Rhino from their property within
                a day or two of submitting their lease application.
              </p>
              <p className="p2">
                If you have other concerns or issues, please reach out to your property's leasing team or contact Rhino
                at&nbsp;
                <a className="bold underline" href={`mailto:support@sayrhino.com`}>
                  {'support@sayrhino.com'}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '50px'
  }
};

export default RequiresInvitationModal;
