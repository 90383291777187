import React, { useEffect } from 'react';
import {
  FONTS,
  PALETTE
} from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PaymentOption as PAYMENT_OPTIONS } from './utils';
import { DidYouKnow } from './did_you_know';
import styled from '@emotion/styled';
import { SignupCopy } from '../signup/constants/copy';
import { BOTH, IN_FULL } from '../../v2/renters_insurance/constants';
import useCreateCoverGeniusPolicyDetailsMutation from '../../v2/renters_insurance/hooks/createCoverGeniusPolicyDetails';
import { ICreateCoverGeniusPolicyDetailsParams } from '../../v2/renters_insurance/interfaces';
import { AxiosResponse } from 'axios';
import env from 'utils/env';
import { IPartnerRentersInsuranceRequirements } from '../signup/redux/state';
import { isLeaseMoreThan90DaysAway } from '../action_center/utils';
import { ToggleButtonWrapper, ToggleButtonStyle, activeButtonStyles, nonActiveButtonStyles } from './styles';

const CardTitle = styled.div([FONTS.h4], { margin: 8 });
const QuoteHeader = styled.div([FONTS.brandH1Bold]);
const PersonalizedQuote = styled.span([FONTS.h6], { color: PALETTE.brand100 });
const PersonalizedQuoteAmount = styled.span([FONTS.h2], { color: PALETTE.brand100 });
const Banner = styled.div([FONTS.p1], { color: PALETTE.neutral65 });
const Discount = styled.div([FONTS.p1Medium], { justifyContent: 'flex-end', color: PALETTE.success125 });

const Wrapper = styled.div({
  width: '100%',
  maxWidth: 624,
  marginLeft: 'auto',
  marginRight: 96,
  '&&': {
    '@media (max-width: 768px)': {
      marginRight: 0
    }
  }
});

const Gradient = styled.div({
  position: 'fixed',
  zIndex: -1,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background:
    'linear-gradient(176.92deg,rgba(107, 59, 246, 0) 2.79%,rgba(51, 128, 217, 0.314499) 35.35%, #6B3BF6 106.34%)',
  opacity: 0.1,
  width: '59%',
  '&&': {
    '@media (max-width: 768px)': {
      position: 'fixed',
      zIndex: -1,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      background:
        'linear-gradient(176.92deg,rgba(107, 59, 246, 0) 2.79%,rgba(51, 128, 217, 0.314499) 35.35%, #6B3BF6 106.34%)',
      opacity: 0.1,
      width: '100%'
    }
  }
});

const Card = styled.div({
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral12}`,
  boxSizing: 'border-box',
  borderRadius: 8,
  padding: 24
});

const Title = styled.div({
  marginBottom: 28,
  display: 'flex',
  flexDirection: 'column',
  '&&': {
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 32
    }
  }
});

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginTop: 24
});

const Container = styled.div({
  '&&': {
    '@media (min-width: 390px)': {
      display: 'flex',
      alignItems: 'baseline'
    }
  }
});

const DidYouKnowWrapperMobile = styled.div({
  paddingTop: '24px'
});

interface PaymentOptionProps {
  isMonthly: boolean;
  price: {
    upfront_discount?: string;
    upfront: string;
    monthly: string;
  };
}

const PaymentOption = ({ isMonthly, price }: PaymentOptionProps) => {
  const selectedPrice = isMonthly ? price.monthly : price.upfront;

  return (
    <Container>
      <PersonalizedQuoteAmount id="payment-option" data-cy="quotePrice">
        {selectedPrice}
      </PersonalizedQuoteAmount>
      {isMonthly ? <PersonalizedQuote>/month</PersonalizedQuote> : <PersonalizedQuote>&nbsp;total</PersonalizedQuote>}
    </Container>
  );
};

export interface PolicySelectionProps {
  price: {
    upfront_discount?: string;
    upfront: string;
    monthly: string;
  };
  paymentOption: PAYMENT_OPTIONS;
  setPaymentOption: (option: number) => void;
  backPath: string;
  policyApplicationId?: number;
  userId?: number;
  setActiveStep: (step: string | null) => void;
  copy?: SignupCopy;
  policyApplication: any;
  setRentersInsuranceQuote: (policyDetail) => void;
  partnerRentersInsuranceRequirements?: IPartnerRentersInsuranceRequirements;
  qualifiesForMonthlyRentersInsurance: boolean;
  eligibleInstalmentRIState: boolean;
  disqualifiedFromMonthlySdi?: boolean;
  rentersInsuranceSelected: boolean;
  rentersInsuranceCadence: PAYMENT_OPTIONS;
}

const PAYMENT_OPTIONS_WITH_LABEL: any = [
  { label: 'Pay monthly', value: PAYMENT_OPTIONS.Monthly, dataCy: 'priceMonthly' },
  { label: 'Pay in full', value: PAYMENT_OPTIONS.Upfront, dataCy: 'priceFull' }
];

const riPaymentFlexiblityEnabled = (window as any).App?.featureFlags?.riPaymentFlexiblity;

export const PolicySelection = ({
  price,
  setPaymentOption,
  paymentOption,
  backPath,
  policyApplicationId,
  userId,
  setActiveStep,
  copy,
  policyApplication,
  setRentersInsuranceQuote,
  partnerRentersInsuranceRequirements,
  qualifiesForMonthlyRentersInsurance,
  eligibleInstalmentRIState,
  disqualifiedFromMonthlySdi,
  rentersInsuranceSelected,
  rentersInsuranceCadence
}: PolicySelectionProps) => {
  const isMobile = useCheckMobile();
  const isMonthly = paymentOption === PAYMENT_OPTIONS.Monthly;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname, search } = useLocation();
  const discount = price?.upfront_discount;

  useEffect(() => {
    setActiveStep('quote_display');
    return () => setActiveStep(null);
  }, []);

  useEffect(() => {
    history.push(pathname + `?payment_cadence=${paymentOption}`);
  }, [paymentOption]);

  useEffect(() => {
    const riCadence = riPaymentFlexiblityEnabled ? rentersInsuranceCadence : paymentOption
    history.push(pathname + `?payment_cadence=${paymentOption}` + `&ri_cadence=${riCadence}` + `&ri_selected=${rentersInsuranceSelected}`);
  }, [rentersInsuranceSelected, rentersInsuranceCadence]);

  const { mutate: createCoverGeniusPolicyDetails } = useCreateCoverGeniusPolicyDetailsMutation();

  function goToNextPage() {
    qualifiesForCoverGenuisRentersInsurance() ? getRIQuoteAndGoToRIBindPage() : goToPolicyDetails();
  }

  const renterInsuranceRequirements = partnerRentersInsuranceRequirements?.active_renters_insurances_requirements;
  const monthlyPaymentAllowed = qualifiesForMonthlyRentersInsurance || eligibleInstalmentRIState;

  function qualifiesForCoverGenuisRentersInsurance() {
    const ownerEMA = renterInsuranceRequirements?.has_exclusive_agreement;
    const ownerDidNotIndicateRentersInsuranceRequirements = renterInsuranceRequirements === undefined;
    const rentersInsuranceStepEnabled = (window as any).App?.featureFlags?.signUp?.rentersInsuranceAnnualAtSignUp;
    const inRentersInsuranceState = env('RENTERS_INSURANCE_STATES').includes(policyApplication.address_state);
    const atEligiblePartner = policyApplication.partner_eligible_for_renters_insurance;
    const lease90DaysInFuture = isLeaseMoreThan90DaysAway(policyApplication.lease_start_date);
    return (
      !policyApplication.eligible_for_lemonade_offer &&
      rentersInsuranceStepEnabled &&
      inRentersInsuranceState &&
      atEligiblePartner &&
      !lease90DaysInFuture &&
      (!ownerEMA || ownerDidNotIndicateRentersInsuranceRequirements)
    );
  }

  function checkForRIDiscount() {
    const ownerSetRIRequirement = renterInsuranceRequirements?.requires_renters_insurance;
    if (policyApplication.renters_insurance_required !== undefined) {
      return policyApplication.renters_insurance_required;
    } else if (ownerSetRIRequirement !== (undefined || null)) {
      return ownerSetRIRequirement;
    } else {
      return false;
    }
  }

  function getRIQuoteAndGoToRIBindPage() {
    const requestData: ICreateCoverGeniusPolicyDetailsParams = {
      policy_application_id: policyApplicationId,
      user_id: userId,
      requested_quote: monthlyPaymentAllowed ? BOTH : IN_FULL,
      ri_required: checkForRIDiscount()
    };

    createCoverGeniusPolicyDetails(
      { ...requestData },
      {
        onSuccess: (response: AxiosResponse) => {
          setRentersInsuranceQuote(response.data.policy_detail);
          history.push(`${path}/renters_insurance`);
        },
        onError: (error) => {
          goToPolicyDetails();
        }
      }
    );
  }

  function goToPolicyDetails() {
    history.push(`${path}/policy_details?payment_cadence=${paymentOption}`);
  }

  return (
    <Wrapper>
      <Title>
        <QuoteHeader data-cy="quoteHeader">
          Your personalized <br /> quote
        </QuoteHeader>
        {!disqualifiedFromMonthlySdi && (
          <ToggleButton
            options={PAYMENT_OPTIONS_WITH_LABEL}
            selectedOptionValue={paymentOption}
            onClick={(option) => setPaymentOption(option)}
          />
        )}
      </Title>
      <div>
        <Card>
          <CardTitle>Security Deposit Insurance</CardTitle>
          <Banner>
            This policy replaces your cash security deposit, and protects your landlord from damage or lost rent during
            your lease term
          </Banner>
          <Footer>
            <PaymentOption isMonthly={isMonthly} price={price} />
            {!isMonthly && discount && <Discount>{`${discount} OFF`}</Discount>}
          </Footer>
        </Card>
        {isMobile && (
          <DidYouKnowWrapperMobile>{<DidYouKnow copyContent={copy} />}</DidYouKnowWrapperMobile>
        )}
      </div>
      <QuoteDisplayFooter
        arrowOnButton={true}
        onBack={() => history.push(backPath)}
        onNext={() => goToNextPage()}
        nextBtnLabel="Continue"
        dataCy="continue"
      />
      <Gradient />
    </Wrapper>
  );
};

interface ToggleButtonProps {
  onClick: (value: number) => void;
  options: { label: string; value: number; dataCy: string }[];
  selectedOptionValue: number;
}

const ToggleButton = ({ onClick, options, selectedOptionValue }: ToggleButtonProps) => {
  return (
    <ToggleButtonWrapper>
      {options.map((option) => {
        const styles = option.value === selectedOptionValue ? activeButtonStyles : nonActiveButtonStyles;
        return (
          <ToggleButtonStyle
            style={styles}
            key={option.label}
            onClick={() => onClick(option.value)}
            data-cy={option.dataCy}
          >
            {option.label}
          </ToggleButtonStyle>
        );
      })}
    </ToggleButtonWrapper>
  );
};
