import styled from '@emotion/styled';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';

interface IProps {
  nextPath?: string;
  className?: string;
  content: string;
  dataCy?: string;
  dataMethod?: string;
  handleClick?: () => void;
}

export const StyledLink = styled.a({
  color: PALETTE.neutralDark,
  fontSize: '16px',
  lineHeight: '28px',
  fontFamily: 'MaisonNeueExtendedLight'
});

export const ActionCenterLink = (props: IProps & Partial<HTMLAnchorElement>) => {
  const { className, nextPath, content, dataCy, dataMethod, handleClick } = props;

  return (
    <div>
      <StyledLink href={nextPath} data-cy={dataCy} className={className} data-method={dataMethod} onClick={handleClick}>
        {content}
      </StyledLink>
    </div>
  );
};
