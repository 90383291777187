import React, {Component, ReactNode} from 'react';

interface IProps {
  index: number;
  item: string;
  content?: string;
  link?: string;
  author?: string;
}

class TextWithAuthorItem extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, link, content, author } = this.props;

    return (
      <div className="item info carousel-card" key={index}>
        <p className="carousel-card--header-text">{item}</p>
        <hr/>
        <div className="link">
          <div className="author">
            {author}
          </div>
          <div className="arrow">
            <a href={link} target="_blank" title={item} />
          </div>
        </div>
      </div>
    );
  }
}

export default TextWithAuthorItem;