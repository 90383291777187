import Drawer from 'components/v2/drawer';
import UpdatePersonalDetails from 'components/v2/edit_account/updatePersonalDetails';
import moment from 'moment';
import React, { Component } from 'react';
import '../edit_account/types';

interface IProps {
  user: IUser;
  users_account_details_path: string;
}

class AccountDetails extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { user, users_account_details_path } = this.props;
    let { phone } = user;
    const birthdate = user.birthdate ? moment.utc(user.birthdate).format('ll') : '-';
    phone = phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '-';
    return (
      <div className="edit-profile-section">
        <div className="row no-gutters justify-content-between">
          <p className="p1 demi">Personal</p>
          <Drawer
            ctaText="Edit"
            dataCy="edit"
            renderContent={() => (
              <UpdatePersonalDetails user={user} users_account_details_path={users_account_details_path} />
            )}
            buttonClass="edit-details-btn"
            buttonBackground="white"
          />
        </div>
        <hr style={styles.divider} />

        <div className="row no-gutters justify-content-between">
          <p style={styles.userInfo}>{user.first_name ? user.first_name : '-'}</p>
          <p style={styles.label}>First name</p>
        </div>
        <hr style={styles.divider} />

        <div className="row no-gutters justify-content-between">
          <p style={styles.userInfo}>{user.last_name ? user.last_name : '-'}</p>
          <p style={styles.label}>Last name</p>
        </div>
        <hr style={styles.divider} />

        <div className="row no-gutters justify-content-between">
          <p style={styles.userInfo}>{birthdate}</p>
          <p style={styles.label}>Date of birth</p>
        </div>
        <hr style={styles.divider} />

        <div className="row no-gutters justify-content-between">
          {<p style={styles.userInfo}>{phone}</p>}
          <p style={styles.label}>Phone number</p>
        </div>
        <hr style={styles.divider} />

        <div className="row no-gutters justify-content-between">
          {<p style={styles.userInfo}>{user.email ? user.email : '-'}</p>}
          <p style={styles.label}>Email</p>
        </div>
        <hr style={styles.divider} />
      </div>
    );
  }
}

const styles = {
  label: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '12px',
    lineHeight: '28px',
    color: '#777777',
    height: '15px',
    marginTop: '12px'
  },
  userInfo: {
    height: '15px',
    marginTop: '12px'
  },
  divider: {
    color: '#E3E3E3',
    width: '100%',
    margin: '5px 0'
  }
};

export default AccountDetails;
