import React from 'react';
import styled from '@emotion/styled';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

const NewPaymentMethodContainer = styled.div`
  padding: 20px;
`;

const PaymentInputContainer = styled.div`
  padding-bottom: 20px;
`;

const PaymentLabel = styled.label([
  FONTS.p3Medium,
  {
    fontSize: '12px',
    paddingBottom: '4px',
    lineHeight: '20px',
    margin: '0px;'
  }
]);

const cardInputOptions = {
  style: {
    base: {
      fontSize: '16px'
    }
  }
};

export const StyledCardNumber = styled(CardNumberElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

export const StyledCardCvc = styled(CardCvcElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

export const StyledCardExp = styled(CardExpiryElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  display: 'block',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

const ZipCodeInput = styled.input`
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  border: 1px solid ${PALETTE.neutral25};
  padding: 19px 0px 19px 5px;
  border-radius: 4px;
  width: 100%;
  height: 19.2px;
  font-size: 1em;
  height: 1.2em;
  line-height: 1.2em;
  &:focus {
    outline: none;
  }
`;

const StyledUpdateNotice = styled.div([
  FONTS.p1Medium,
  {
    color: PALETTE.neutral55,
    backgroundColor: PALETTE.neutral4,
    borderRadius: 3,
    padding: `8px 0px 8px 0px`,
    fontSize: `14px`,
    lineHeight: `24px`,
    '& button': {
      display: 'inline-block'
    },
    textAlign: `center`
  }
]);

interface NewPaymentMethodFormProps {
  handleZipCodeUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
}

const NewPaymentMethodForm = ({ handleZipCodeUpdate }: NewPaymentMethodFormProps) => {
  return (
    <NewPaymentMethodContainer>
      <div className="row no-gutters">
        <PaymentInputContainer className="col-sm-12 card-element">
          <PaymentLabel>Credit or Debit Card Number</PaymentLabel>
          <StyledCardNumber className="credit-card-input" />
        </PaymentInputContainer>
      </div>
      <div className="row">
        <PaymentInputContainer className="col-sm-12 col-md-4">
          <PaymentLabel>Expiration Date</PaymentLabel>
          <StyledCardExp className="credit-card-input" />
        </PaymentInputContainer>
        <PaymentInputContainer className="col-sm-12 col-md-4">
          <PaymentLabel>CVC/Security Code</PaymentLabel>
          <StyledCardCvc className="credit-card-input" />
        </PaymentInputContainer>
        <PaymentInputContainer className="col-sm-12 col-md-4">
          <PaymentLabel>Billing Zip Code</PaymentLabel>
          <ZipCodeInput type="text" onChange={handleZipCodeUpdate} placeholder="Zip Code" />
        </PaymentInputContainer>
        <div className="col-sm-12">
          <StyledUpdateNotice>The payment method will save as the default card on file</StyledUpdateNotice>
        </div>
      </div>
    </NewPaymentMethodContainer>
  );
};

export default NewPaymentMethodForm;
