import getFeatureFlags from 'utils/getFeatureFlags';
import env from 'utils/env';
import { IResult } from '../signup/redux/actions';
import { PolicyApplication } from '../signup/redux/state';

interface ICalculateTotalAmount {
  sdiPolicyPriceInfo?: any;
  rentersInsuranceSelected?: boolean;
  policyApplication: IResult<PolicyApplication>;
  coverGeniusRentersInsurance?: any;
}

const calculateTotalAmount = (props: ICalculateTotalAmount): number => {
  const featureFlags = getFeatureFlags();

  const search = window.location.search;
  const addressState = props?.policyApplication?.result?.address_state;

  const securityDepositInsurancePolicyAmount = () => {
    const isMonthly = new URLSearchParams(search).get('payment_cadence') === '0';
    const sdiPolicyAmount = isMonthly ? props.sdiPolicyPriceInfo.monthly_cents : props.sdiPolicyPriceInfo.upfront_cents;

    const serviceFee = props.sdiPolicyPriceInfo.service_fee_cents;

    const processingFee = isMonthly
      ? props.sdiPolicyPriceInfo.monthly_processing_fee_cents
      : props.sdiPolicyPriceInfo.upfront_processing_fee_cents;

    return sdiPolicyAmount + serviceFee + processingFee;
  };

  const qualifiesForMonthlyRentersInsurance = env('MONTHLY_RENTERS_INSURANCE_STATES').includes(addressState);

  const rentersInsurancePolicyAmount = () => {
    if (!props.rentersInsuranceSelected) return 0;

    const rentersInsuranceInstalmentsFlag = featureFlags?.rentersInsuranceInstalments;
    const rentersInsuranceInstalmentsEnrollmentFlag = featureFlags;
    const isRiMonthly = new URLSearchParams(search).get('ri_cadence') === '0';

    const eligibleInstalmentRIState: boolean =
      env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(addressState) &&
      rentersInsuranceInstalmentsFlag &&
      rentersInsuranceInstalmentsEnrollmentFlag;

    const inFullRiPrice = props.coverGeniusRentersInsurance?.in_full_quote_cents;
    const monthlyRiPrice = props.coverGeniusRentersInsurance?.monthly_quote_cents;
    const instalmentsRiPrice = props.coverGeniusRentersInsurance?.instalments_quote_cents;

    if (isRiMonthly) {
      if (qualifiesForMonthlyRentersInsurance) {
        return monthlyRiPrice;
      } else if (eligibleInstalmentRIState) {
        return instalmentsRiPrice;
      }
    }
    return inFullRiPrice;
  };

  return rentersInsurancePolicyAmount() + securityDepositInsurancePolicyAmount();
};

export default calculateTotalAmount;
