import React, { Component } from 'react';
import Select from 'react-select';
import { inputId, inputName } from 'utils/form';
import Input from '../input';

import './styles.scss';

export type IOption = {
  value: any;
  label: string;
};

type IProps = {
  resource: string;
  field: string;
  className?: string;
  placeholder?: string;
  options: IOption[];
  value: any;
  clearable?: boolean;
  searchable?: boolean;
  disabled?: boolean;
  dataCy?: string;
  onChange: (option: IOption) => any;
};

type IState = { value: any };

class CustomSelect extends Component<IProps & any, IState> {
  static defaultProps = {
    value: '',
    clearable: false,
    searchable: false,
    onChange: (option: IOption) => null
  };

  constructor(props: IProps) {
    super(props);

    const { value } = this.props;
    this.state = { value };
  }

  updateValue = (option: IOption) => {
    const { value } = option;
    this.setState({ value });
    this.props.onChange(option);
  };

  pureValueInputProps = (): any => {
    const { resource, field, dataCy } = this.props;
    const { value } = this.state;

    return {
      type: 'hidden',
      id: inputId(resource, field),
      name: inputName(resource, field),
      readOnly: true,
      'data-cy': dataCy,
      value
    };
  };

  selectInputProps = (): any => {
    const { placeholder, className, options, clearable, searchable, disabled } = this.props;
    const { value } = this.state;

    return {
      disabled,
      className,
      placeholder,
      options,
      clearable,
      searchable,
      value,
      onChange: this.updateValue
    };
  };

  render() {
    const { resource, field } = this.props;

    return (
      <div id={`react_select_${resource}_${field}`}>
        <Select {...this.selectInputProps()} />
        <Input {...this.pureValueInputProps()} />
      </div>
    );
  }
}

export default CustomSelect;
