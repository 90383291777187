import React from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

type ButtonProps = {
  selected: boolean;
};

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: PALETTE.neutralLight,
  borderRadius: '20px',
  width: '300px',
  border: `1px solid ${PALETTE.neutralDark}`,
  margin: '0px !important'
});

const Button = styled.button<ButtonProps>([
  FONTS.p2,
  {
    whiteSpace: 'nowrap',
    height: '32px',
    borderRadius: '16px',
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    margin: '0px !important',
    flex: 1,
    textAlign: 'center',
    '&:disabled': {
      cursor: 'not-allowed',
      color: PALETTE.neutral12,
      backgroundColor: PALETTE.neutralLight
    }
  },
  ({ selected }) => ({
    backgroundColor: selected ? PALETTE.neutralDark : 'transparent',
    color: selected ? PALETTE.neutralLight : PALETTE.neutralDark,
    border: '1px solid',
    borderColor: selected ? PALETTE.neutralDark : 'transparent',
    fontFamily: selected ? 'MaisonNeueMedium' : 'MaisonNeueLight',
    '&:hover': {
      background: selected ? PALETTE.neutral65 : PALETTE.neutral12,
      borderColor: selected ? PALETTE.neutral65 : 'transparent',
      color: selected ? PALETTE.neutralLight : PALETTE.neutralDark
    }
  })
]);

export type SwitchSelectorProps = {
  active: string;
  list: { label: string; value: string }[];
  onClick: (active: string) => void;
  id?: string;
  disabled?: boolean;
};

const SwitchSelector: React.FC<SwitchSelectorProps> = ({ active, disabled, list, onClick, id }) => {
  return (
    <ButtonGroup id={id}>
      {list.map(({ value, label }) => {
        const selected = active === value;
        return (
          <Button
            key={value}
            data-cy={`singlePropertyCoverageSwitch${value}`}
            aria-selected={selected}
            disabled={disabled}
            onClick={() => onClick(value)}
            selected={selected}
            name={`${id}-${value}`}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default SwitchSelector;
