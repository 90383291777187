import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IBankAccountInfo } from './types';

type ICreateBankAccountParams = {
  bank_account: IBankAccountInfo;
  amount_in_cents: number;
}

const createBankAccount = async (withdrawalInfo: ICreateBankAccountParams) => {
  const token = csrfToken();

  const response = await axios.post<ICreateBankAccountParams>(
    '/users/renter_withdrawal_funds',
    withdrawalInfo,
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createBankAccount;
