interface QueryParams {
  [key: string]: any;
}

const getQueryParams = (): QueryParams => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export default getQueryParams;
