import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, Button, CheckIcon as CheckIconBase } from '@sayrhino/rhino-shared-js';
import { Steps } from './RentersInsuranceModal';
import { MONTHLY, IN_FULL, INSTALMENTS, MONTHLY_PROCESSING_FEE } from '../constants';
import { BackButton } from './SharedStyles';
import { IConfirmRentersInsuranceParams, RentersInsurancePolicyProps } from '../interfaces';
// @ts-ignore
import loaderImage from '../assets/rhino_money.png';
import useConfirmRentersInsurance from '../hooks/useConfirmRentersInsurance';
import { AxiosResponse } from 'axios';
import { RentersInsuranceLegalText } from 'components/v2/quote_display/renters_insurance_legal_text';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';
import { formatCents } from 'utils/money/formatter';
import moment from 'moment';
import env from 'utils/env';
import useGetLastFourCcDigits from '../hooks/useLastFourCc';

const Wrapper = styled.div([
  {
    margin: '0 auto',
    maxWidth: '480px',
    '@media (min-width: 768px)': {
      margin: '0 auto',
      width: '480px'
    }
  }
]);

const Header = styled.h2([FONTS.h2], {
  lineHeight: '150%',
  letterSpacing: '0.56px',
  marginBottom: '8px',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    fontSize: '24px'
  }
});

const ItemizedListContainer = styled.div({
  backgroundColor: PALETTE.neutral4,
  padding: '16px 18px',
  borderRadius: '8px',
  marginBottom: '35px'
});

const Item = styled.p([FONTS.p1], { margin: 0 });

const ItemPrice = styled.p([FONTS.p1], { margin: 0 });

const Row = styled.div([FONTS.p1Medium], {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const TotalDueText = styled.p([FONTS.p1Medium], { margin: 0 });

const TotalDueAmount = styled.p([FONTS.p1Medium], { margin: 0 });

const BillingDate = styled.p([FONTS.p3], { color: PALETTE.neutral65, marginBottom: 0 });

const PaymentMethodContainer = styled.div({ marginBottom: '22px' });

const PaymentMethodText = styled.p([FONTS.p2], { color: PALETTE.neutral88, marginBottom: '5px' });

const PaymentMethod = styled.p([FONTS.p1], {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '13px 18px',
  borderRadius: '6px',
  marginBottom: 0
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginTop: '40px',
  '@media (max-width: 375px': {
    marginTop: '50px'
  },
  '@media (max-width: 768px)': {
    marginTop: '92px',
    marginBottom: 0
  }
});

const PayButton = styled(Button)({
  '&&': {
    backgroundColor: PALETTE.brand100
  }
});

const CheckIcon = styled(CheckIconBase)({
  borderRadius: '50%',
  padding: '4px',
  width: '20px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: PALETTE.success100,
  color: PALETTE.neutralLight
});

const loaderStyles = {
  container: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    background: 'white'
  },
  image: {
    width: '152px',
    height: '80px',
    margin: 'auto',
    marginTop: '20%',
    display: 'block'
  }
};

const LegalDiv = styled.div([FONTS.p3], { color: PALETTE.neutral65, marginBottom: '8px', fontWeight: 'bold' });

interface ICheckoutProps {
  onCloseModal: () => void;
  goToStep: (modalStep: string) => void;
  rentersInsurancePolicy: RentersInsurancePolicyProps;
  setConfirmPolicyErrors: any;
  paymentOption: PAYMENT_OPTIONS;
  qualifiesForMonthlyRentersInsurance: boolean;
  eligibleInstalmentRIState: boolean;
}

const Checkout = ({
  goToStep,
  rentersInsurancePolicy,
  setConfirmPolicyErrors,
  paymentOption,
  qualifiesForMonthlyRentersInsurance,
  eligibleInstalmentRIState
}: ICheckoutProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { mutate: confirmRentersInsurance } = useConfirmRentersInsurance();
  const { data } = useGetLastFourCcDigits(rentersInsurancePolicy.ins_number);
  const ccDigits = data ? data?.last_4 : '••••';
  /**
   *
   * @returns monthly or instalment quote amount in cents plus the monthly processing fee
   */
  const getAdjustedMonthlyAmount = () => {
    if (rentersInsurancePolicy.monthly_quote_cents) {
      return rentersInsurancePolicy.monthly_quote_cents + MONTHLY_PROCESSING_FEE;
    } else if (rentersInsurancePolicy.instalments_quote_cents && eligibleInstalmentRIState) {
      return rentersInsurancePolicy.instalments_quote_cents + MONTHLY_PROCESSING_FEE;
    }
  };

  const amountDueToday =
    paymentOption === PAYMENT_OPTIONS.Upfront ? rentersInsurancePolicy.in_full_quote_cents : getAdjustedMonthlyAmount();

  const formattedAmountDueToday = amountDueToday && formatCents(amountDueToday);

  const totalAmount = rentersInsurancePolicy.in_full_quote_cents;
  const formattedTotalAmount = totalAmount && formatCents(totalAmount);

  /**
   *
   * @returns the correct pay cadence to send to the backend when confirming the RI policy
   */
  const getSelectedCadence = () => {
    if (paymentOption === PAYMENT_OPTIONS.Upfront) {
      return IN_FULL;
    } else if (paymentOption === PAYMENT_OPTIONS.Monthly && eligibleInstalmentRIState) {
      return INSTALMENTS;
    } else {
      return MONTHLY;
    }
  };
  const renewalDate = () => {
    const date = new Date(rentersInsurancePolicy.coverage_start_date);
    return getSelectedCadence() === IN_FULL
      ? moment(date).add(1, 'years').format('M/DD/YYYY')
      : monthlyBillingDay(date);
  };

  const monthlyBillingDay = (date) => {
    return moment
      .utc(date)
      .add(Number(env('RENTERS_INSURANCE_TRIAL_LENGTH')), 'days')
      .format('Do');
  };

  const submitPayment = () => {
    setLoading(true);

    const confirmData: IConfirmRentersInsuranceParams = {
      ins_number: rentersInsurancePolicy.ins_number,
      selected_quote_type: getSelectedCadence(),
      payment_received: false
    };
    confirmRentersInsurance(
      { ...confirmData },
      {
        onSuccess: (response: AxiosResponse) => {
          if (response.data.policy_detail) {
            goToStep(Steps.INSURED);
          }
        },
        onError: (error: any) => {
          setConfirmPolicyErrors(error.response?.data.errors);
          goToStep(Steps.POLICY_CONFIRMATION_FAILED);
        }
      }
    );
  };

  return (
    <Wrapper>
      <Header data-cy="addPaymentHeader">Review payment details</Header>
      <ItemizedListContainer>
        <Row>
          <Item>Renters Insurance</Item>
          <ItemPrice>
            <>
              {formattedAmountDueToday}
              {getSelectedCadence() === IN_FULL ? '/yr' : '/mo*'}
            </>
          </ItemPrice>
        </Row>
        <Row>
          <BillingDate>
            {getSelectedCadence() === IN_FULL ? `Renews ${renewalDate()}` : `Billed the ${renewalDate()} of each month`}
          </BillingDate>
        </Row>

        <hr />
        <Row>
          <TotalDueText>Total due today</TotalDueText>
          <TotalDueAmount>{formattedAmountDueToday}</TotalDueAmount>
        </Row>
      </ItemizedListContainer>
      <PaymentMethodContainer>
        <PaymentMethodText>Payment method</PaymentMethodText>
        <PaymentMethod>
          Visa •••• •••• •••• {ccDigits}
          <CheckIcon />
        </PaymentMethod>
      </PaymentMethodContainer>
      {qualifiesForMonthlyRentersInsurance && getSelectedCadence() === 'monthly' && (
        <LegalDiv style={{ fontWeight: 'bold' }}>
          *Your first charge will be $1 less than your recurring charge.
        </LegalDiv>
      )}
      <RentersInsuranceLegalText
        ins_number={rentersInsurancePolicy.ins_number}
        underwriter={rentersInsurancePolicy.underwriter}
      />
      <ButtonContainer>
        <BackButton variant="white" onClick={() => goToStep(Steps.REVIEW_POLICY)} data-cy="back">
          Back
        </BackButton>
        <PayButton onClick={submitPayment} data-cy="payment">
          Pay {formattedAmountDueToday}
        </PayButton>
      </ButtonContainer>
      <div style={loading ? loaderStyles.container : { display: 'none' }}>
        <img style={loaderStyles.image} src={loaderImage} />
      </div>
    </Wrapper>
  );
};

export default Checkout;
