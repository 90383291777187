import React from 'react';

const Skip = ({
  skip_path,
  link_id,
  classes,
  optional_text
}: {
  skip_path: string;
  link_id: string;
  classes: string;
  optional_text: string;
}): any => (
  <a href={`${skip_path}`} className={`${classes}`} id={`${link_id}`}>
    {optional_text || 'Skip'}
  </a>
);

export default Skip;
