import axios, { AxiosError } from 'axios';
import { useMutation, MutationFunction } from 'react-query';
import { useDispatch } from 'react-redux';
import { WrappedPolicyApplication } from '../interfaces';
import { addCsrf, createPolicyApplicationSync, IResult, makeUpdate } from '../redux/actions';
import { PolicyApplication } from '../redux/state';
import { coerceUndefined, redirectToPartnerEnrollment, verifyResponseForConflict } from '../step_helpers';

export interface ICreatePolicyApplicationVars {
  userId: number;
  policyApplication: PolicyApplication;
}

export const createPolicyApplication: MutationFunction<PolicyApplication, ICreatePolicyApplicationVars> = async ({
  userId,
  policyApplication
}) => {
  const body = addCsrf({ policy_application: coerceUndefined(policyApplication) });
  const url = `/users/${userId}/policy_applications.json`;
  const result = await axios.post<WrappedPolicyApplication>(url, body);
  return result.data.policy_application;
};

export function useCreatePolicyApplication(currentStep?: string) {
  const dispatch = useDispatch();

  return useMutation(createPolicyApplication, {
    onSuccess: (policyApplication) => {
      const policyApplicationResult: IResult<PolicyApplication> = {
        result: policyApplication
      };
      dispatch(createPolicyApplicationSync(policyApplicationResult, currentStep));
    },
    onError: (r: AxiosError<IResult<PolicyApplication>>) => {
      const errors = r.response === undefined ? { errors: { request: [r.message] } } : r.response.data;
      verifyResponseForConflict(r).then(
        () => dispatch(createPolicyApplicationSync(errors, currentStep))
      ).catch(redirectToPartnerEnrollment);
    }
  });
}
