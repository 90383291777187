import styled from '@emotion/styled';
import React from 'react';
import { FONTS, PALETTE, ROUNDNESS, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

interface Props {
  imageUrl: string;
}

const MobileWrapper = styled.a([
  ROUNDNESS.m,
  {
    background: PALETTE.white,
    border: `1px solid ${PALETTE.neutral12}`,
    borderRadius: ROUNDNESS.round,
    padding: `21px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    marginTop: 8,
    textDecoration: 'none',
    '@media (min-width: 992px)': {
      maxWidth: '600px'
    }
  }
]);

const DesktopWrapper = styled.a([
  ROUNDNESS.m,
  {
    background: PALETTE.white,
    border: `1px solid ${PALETTE.neutral12}`,
    padding: `21px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    marginBottom: 16,
    marginTop: 8,
    textDecoration: 'none',
    '@media (max-width: 992px)': {
      width: '100%'
    }
  }
]);

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column'
});

const Title = styled.div([
  FONTS.h5,
  {
    color: PALETTE.neutralDark,
    fontWeight: 600
  }
]);

const Text = styled.div([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65,
    fontWeight: 500,
    marginBottom: 2,
    paddingTop: 15
  }
]);

const MobileText = styled.div([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65,
    fontWeight: 500,
    marginBottom: 2,
    paddingTop: 15,
    textAlign: 'center'
  }
]);

const TitleWrapper = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  paddingBottom: 15
});

const ImageWrapper = styled.img({
  marginRight: 16,
  width: 19,
  height: 19
});

export const NoActivePolicies = ({ imageUrl }: Props) => {
  const isMobile = useCheckMobile();

  if (!isMobile) {
    return (
      <DesktopWrapper>
        <div style={{ marginBottom: 16 }}>
          <IconWrapper>
            <TitleWrapper>
              <ImageWrapper src={imageUrl} />
              <Title>You have no active policies</Title>
            </TitleWrapper>
            <Text>Make sure your property's lease requirements are met with security deposit insurance</Text>
          </IconWrapper>
        </div>
      </DesktopWrapper>
    );
  }

  return (
    <MobileWrapper>
      <div style={{ marginBottom: 16 }}>
        <IconWrapper>
          <TitleWrapper>
            <ImageWrapper src={imageUrl} />
            <Title>You have no active policies</Title>
          </TitleWrapper>
          <MobileText>Make sure your property's lease requirements are met with security deposit insurance</MobileText>
        </IconWrapper>
      </div>
    </MobileWrapper>
  );
};
