import axios from 'axios';
import { useMutation } from 'react-query';

export const cancelRentersInsurance = async (params: { ins_number: string; reason: string }) => {
  return await axios.put(`/cover_genius_policy_details/${params.ins_number}/cancel`, {
    cancellation_reason: params.reason,
    ins_number: params.ins_number
  });
};

export default function useCancelRentersInsurancePolicy() {
  return useMutation<any, any, { ins_number: string; reason: string }>(cancelRentersInsurance);
}
