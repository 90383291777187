import React, {Component} from 'react';
import { post } from 'utils/request';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import Loader from 'components/v2/loader';

interface IProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  planToken: string;
  email: string;
  name: string;
  setSuccess(value: boolean): void;
}

interface IState {
  error?: string,
  loading: boolean
}

const errorMessage =
  'Sorry, we were unable to start your plan. Please double-check your payment information and try again.';

class CardElement extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: '',
      loading: false
    };
  };

  public onSubmit = async (event) => {
    event.preventDefault();

    const { elements, stripe } = this.props;

    if (elements && stripe) {
      const card = elements.getElement(CardNumberElement);

      if (!card) return;

      const { error, token } = await stripe.createToken(card, { name: this.props.name });

      if (error) {
        return this.setState({ error: errorMessage });
      } else {
        this.setState({ error: undefined });
      }

      this.createSubscription(token);
    }
  };

  public createSubscription(token) {
    const { email, planToken, setSuccess } = this.props;

    this.setState({ loading: true });

    post(`/payment_plans/${planToken}/start`, { stripeToken: token.id, user_email: email })
      .then(() => setSuccess(true))
      .catch(() => this.setState({ loading: false, error: errorMessage }));
  }

  render() {
    const { error } = this.state;

    return (
      <div className="row">
        <div className="payment-card payment-form">
          <div className="row no-gutters">
            <div className="col-12 card-element">
              <div className="payment-input-wrapper" id="card-number">
                <CardNumberElement className="v2-input" options={inputOptions} />
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 card-element card-date">
              <div className="payment-input-wrapper" id="card-expiry">
                <CardExpiryElement className="v2-input" options={inputOptions} />
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 card-element card-cvc">
              <div className="payment-input-wrapper" id="card-cvc">
                <CardCvcElement className="v2-input" options={inputOptions} />
              </div>
            </div>
          </div>

          {error && <div className="error">{error}</div>}

          <p className="payment-user-agreement">
            A 2.9% + $0.30 processing fee will be applied for each installment of credit card payments.
            I understand that by submitting this information, a payment plan will be set up for my claim.
            I authorize Rhino to electronically debit my account and, if necessary,
            electronically credit my account to correct erroneous debits.
          </p>

          <div className="row no-gutters button-container">
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }

  private renderButton = () => {
    if (this.state.loading) return <Loader />;

    return(
      <input type="submit"
        value="Start Plan"
        className="start-plan-button"
        disabled={!this.props.stripe}
        onClick={this.onSubmit} />
    );
  }
}

const inputOptions = {
  style: {
    base: {
      fontSize: '16px'
    }
  }
};

export default CardElement;