import React, { useEffect } from 'react';
import { FONTS, Button, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

const StyledButton = styled(Button)([
  FONTS.p4Medium,
  {
    alignItems: 'center',
    height: '48px',
    padding: '0 35px',
    minWidth: 169,
    justifyContent: 'center',
    marginTop: 24,
    background: PALETTE.raptor
  }
]);
const WrapperStyle = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  minHeight: '100%',
  textAlign: 'center'
});
const HeaderMessage = styled.div([FONTS.brandH2Bold, { marginBottom: 8 }]);
const ParagraphMessage = styled.div([FONTS.p1Light]);
const LineBreak = styled.div({
  padding: '4px'
});

interface IErrorObject {
  internal: string[];
  stripe: string[];
  cover_genius: string[];
}

interface IFinalStepMessageProps {
  errors?: IErrorObject;
  onCloseModal: () => void;
}

const CARD_DECLINE_ERROR = 'Your card was declined.';

const FinalStepMessage = ({ onCloseModal, errors }: IFinalStepMessageProps) => {
  const renderHeaderMessage = () => {
    if (errors !== undefined) {
      return <HeaderMessage>Hmm, that didn't work</HeaderMessage>;
    } else {
      return <HeaderMessage>Congratulations, you're covered!</HeaderMessage>;
    }
  };

  const renderParagraphMessage = () => {
    if (errors !== undefined) {
      if (errors.stripe) {
        if (errors.stripe.includes(CARD_DECLINE_ERROR)) {
          return (
            <ParagraphMessage>
              Oops, we could't process your payment. Please double-check your payment info and try again.
            </ParagraphMessage>
          );
        } else {
          return <ParagraphMessage>Uh-oh, looks like something went wrong. Please try again.</ParagraphMessage>;
        }
      } else {
        return <ParagraphMessage>Uh-oh, looks like something went wrong. Please try again.</ParagraphMessage>;
      }
    } else {
      return (
        <>
          <ParagraphMessage>
            Be sure to check for an email from <b>XCover</b>, our insurance partner. It’ll provide more information
            about getting proof of coverage, managing your account, and more.
          </ParagraphMessage>
          <LineBreak />
          <ParagraphMessage>
            Remember to download your proof of insurance and forward it to your property manager if necessary.
          </ParagraphMessage>
        </>
      );
    }
  };

  const dataCy = errors !== undefined ? 'error' : 'success';

  return (
    <WrapperStyle data-cy={dataCy}>
      {renderHeaderMessage()}
      {renderParagraphMessage()}
      <StyledButton onClick={onCloseModal} data-cy="confirmFinalStep">
        Got it, thanks!
      </StyledButton>
    </WrapperStyle>
  );
};

export default FinalStepMessage;
