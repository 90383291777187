export const useNextStepsData = (security_deposit_insurances, userInfo) => {
  if (!userInfo || !security_deposit_insurances) {
    return null;
  }
  const SDIs = security_deposit_insurances?.insurance_policies;
  const sdiEligibleForRenewalList = SDIs?.filter((SDI) => SDI.eligible_for_renewal && !SDI.non_renewal_reason);
  const riEligibleList = userInfo?.addresses_for_ri.filter(
    (address) =>
      address.renters_insurance_requirements[0].active_renters_insurances_requirements?.has_exclusive_agreement !== true
  );
  return { sdiEligibleForRenewalList, riEligibleList };
};
