import React, { Component } from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import CardElement from './CardElement';
import RadioOption from 'components/common/radio_form/RadioOption';

interface IProps {
  name: string;
  admitted_market: boolean;
  plan_token: string;
  email: string;
  claim_amount: string;
  installments_number: number;
  amount_per_installment: string;
  start_date: string;
  stripe_publishable_key: string;
}

interface IState {
  token?: IStripeToken | null;
  success: boolean;
  linkToken: string;
  paymentMethod: string;
}

const PaymentMethodOptions = [
  { label: 'Credit Card', value: 'card' },
  { label: 'Bank Account', value: 'bank' }
];

class CardForm extends Component<IProps, IState> {
  private stripePromise: Promise<Stripe | null>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      success: false,
      linkToken: 'not-set',
      paymentMethod: 'card'
    };

    this.stripePromise = loadStripe(this.props.stripe_publishable_key);
  }

  public setSuccess = (value) => {
    this.setState({ success: value });
  };

  render() {
    if (this.state.success) return this.renderSuccess();

    const { email, claim_amount, installments_number, amount_per_installment, start_date } = this.props;
    const multipleEnding = installments_number > 1 && 's';

    return (
      <div id="payment-plan">
        <div className="row">
          <p className="heading">Start your payment plan</p>
        </div>

        <div className="row ub-heading">
          <p>Choose a payment method below to start your payment plan.</p>
        </div>

        <div className="row payment-plan-email">
          <p>{email}</p>
        </div>

        <div className="row payment-plan-amount">
          <p>Claim amount {claim_amount}</p>
        </div>

        <div className="row payment-plan-installments">
          <p>
            {installments_number} Installment{multipleEnding} /{amount_per_installment} per installment
          </p>
        </div>

        <div className="row payment-plan-start">
          <p>Payment plan starts {start_date}</p>
        </div>

        {this.renderRadioOptions()}
        {this.renderCardElement()}
      </div>
    );
  }

  private onPaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  private renderSuccess = () => {
    return (
      <div id="payment-plan-set">
        <div className="plan-payed-image" />
        <p>Your plan has been set up</p>
      </div>
    );
  };

  private renderRadioOptions = () => {
    return (
      <div className="row payment-plan-payment-method">
        {PaymentMethodOptions.map(({ label, value }, index) => (
          <RadioOption
            id={value}
            key={index}
            onChange={this.onPaymentMethodChange}
            value={value}
            selected={this.state.paymentMethod}
            label={label}
          />
        ))}
      </div>
    );
  };

  private renderCardElement = () => {
    if (this.state.paymentMethod !== 'card') return null;

    const { name, email, plan_token } = this.props;

    return (
      <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <CardElement
              name={name}
              email={email}
              stripe={stripe}
              elements={elements}
              planToken={plan_token}
              setSuccess={this.setSuccess}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  };
}

export default CardForm;
