import { ACCEPT_CHANGE_REQUEST, UPDATE_CHANGE_REQUEST } from '../actions';

export default function changeRequest(state = {}, action) {
  switch(action.type) {
    case ACCEPT_CHANGE_REQUEST:
      return {};
    case UPDATE_CHANGE_REQUEST:
      return { ...state, ...action.changeRequestParams };
    default:
      return state;
  }
}
