import React from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import { FONTS, PALETTE, ButtonHabitat, CloseIcon, Card } from '@sayrhino/rhino-shared-js';
import axios from 'axios';

const StyledCardDetails = styled(Card)({
  padding: '1rem',
  height: 'auto !important',
  overflow: 'hidden',
  cursor: 'auto',
  marginBottom: 24,
  '@media(max-width:768px)': {
    width: '345px !important'
  }
});

const StyledCloseIconContainer = styled.div({
  position: 'absolute',
  right: '10%',
  top: '9%',
  '@media(max-width:768px)': {
    right: '0%',
    top: '12%'
  }
});

const CardHeader = styled.h3(FONTS.h3, {
  marginBottom: 0,
  paddingTop: '25px',
  textAlign: 'center'
});

const CardSubText = styled.p(FONTS.h6Light, {
  textAlign: 'center'
});

const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '15px',
  marginBottom: '20px'
});

const StyledModal = styled(Modal)({
  width: '480px',
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  background: PALETTE.neutralLight,
  borderRadius: '8px',
  outline: 0,
  '@media(max-width:768px)': {
    padding: '88px 20px 64px 20px',
    width: '345px',
    height: '100%',
    top: '0',
    left: '0',
    borderRadius: '0',
    transform: 'none',
    background: 'none'
  }
});

interface IProps {
  isOpen: boolean;
  withdrawUid: string;
  setShowCancelWithdrawalModal: (showCancelWithdrawalModal: boolean) => void;
}

const CancelWithdrawalModal = ({ isOpen, withdrawUid, setShowCancelWithdrawalModal }: IProps) => {
  const cancelWithdrawalRequest = async (uid: string) => {
    const url = `/users/renter_withdrawal_funds/${uid}/cancel_withdrawal.json`;
    await axios.patch(url);

    window.location.reload();
  }

  const closeModal = () => setShowCancelWithdrawalModal(false);

  return (
    <StyledModal isOpen={isOpen}>
      <StyledCardDetails>
        <StyledCloseIconContainer onClick={closeModal}>
          <CloseIcon className={'cursor'} data-cy="modalCancel" />
        </StyledCloseIconContainer>
        <CardHeader>Cancel Transfer?</CardHeader>
        <CardSubText>Are you sure you want to cancel this transfer request.</CardSubText>

        <ButtonRow>
          <ButtonHabitat
            id="cancel"
            variant="neutral"
            size="small"
            usage="secondary"
            onClick={closeModal}
          >
            Nevermind
          </ButtonHabitat>

          <ButtonHabitat
            id="save"
            variant="destructive"
            size="small"
            usage="primary"
            onClick={() => cancelWithdrawalRequest(withdrawUid)}
          >
            Yes, Cancel
          </ButtonHabitat>
        </ButtonRow>
      </StyledCardDetails>
    </StyledModal>
  );
};

export default CancelWithdrawalModal;
