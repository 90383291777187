import { INSTALMENTS, IN_FULL, MONTHLY } from './constants';
import env from 'utils/env';

export const formatRentersInsuranceAddresses = (rentersInsuranceAddresses) => {
  return rentersInsuranceAddresses.map((address) => {
    const label = `${address.address_line_one}, ${address.address_line_two ? `${address.address_line_two},` : ``} ${
      address.address_city
    }, ${address.address_state} ${address.zipcode}`;
    return {
      label: `${label}`,
      value: address.policy_application_id,
      lease_start_date: address.lease_start_date,
      lease_end_date: address.lease_end_date,
      map_props: address.map_props,
      renters_insurance_requirements: address.renters_insurance_requirements
    };
  });
};

/**
 * Helper method to return string representation of payment cadence based on URL param and address state
 * @param location location from useLocation hook
 * @param state state of policy application
 * @returns 'monthly', 'in_full', or 'instalments'
 */

export const getPayCadence = (location: string, state: string | undefined) => {
  switch (new URLSearchParams(location).get('payment_cadence')) {
    case '0':
      if (env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(state)) {
        return INSTALMENTS;
      }
      return MONTHLY;
    case '1':
      return IN_FULL;
  }
};

/**
 * Helper method to return string representation of payment cadence based on URL param and address state
 * @param location location from useLocation hook
 * @param state state of policy application
 * @returns 'monthly', 'in_full', or 'instalments'
 */

export const getRiPayCadence = (location: string, state: string | undefined) => {
  switch (new URLSearchParams(location).get('ri_cadence')) {
    case '0':
      if (env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(state)) {
        return INSTALMENTS;
      }
      return MONTHLY;
    case '1':
      return IN_FULL;
  }
};