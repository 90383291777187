import React from 'react';
import styled from '@emotion/styled';
import { IChangeRequest, ICancellationRequest } from '../policyProps';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { formatToLocaleDate } from '../../../common/form/date-picker/utils';

interface IProps {
  coverageInfo: ICoverageInfo;
  changeRequest: IChangeRequest;
}

interface ICoverageInfo {
  documents_url: string;
  document_effective_date: string;
  policy_id: string | number;
  payment_amount: string;
  unit_name?: string;
  coverage_start_date: string;
  coverage_end_date: string;
  monthly_rent: string;
  coverage_amount: string;
  status: string;
  upfront: boolean;
  pending_change_request: boolean;
  lease_start_date: string;
  lease_end_date: string;
  policy_number: string;
  address_line_one: string;
  city: string;
  state: string;
  zipcode: string;
  cancellation_request: ICancellationRequest | null;
}

const PolicyNumber = styled.p([FONTS.p1Medium]);

const Container = styled.div({
  marginTop: '34px'
});

const Row = styled.div({
  display: 'flex',
  marginBottom: '16px',
  '@media (max-width: 768px)': {
    display: 'block'
  }
});

const Label = styled.div([
  FONTS.p2Medium,
  {
    flex: '30%',
    '@media (max-width: 768px)': {
      flex: '100%'
    }
  }
]);

const Info = styled.div([
  FONTS.p1,
  {
    flex: '70%',
    '@media (max-width: 768px)': {
      flex: '100%'
    }
  }
]);

const PolicyCoverageDetails: React.FC<IProps> = (props: IProps) => {
  const { coverageInfo: policy } = props;
  const {
    status,
    policy_number,
    address_line_one,
    zipcode,
    unit_name,
    city,
    state,
    monthly_rent,
    coverage_amount,
    coverage_start_date,
    coverage_end_date,
    lease_start_date,
    lease_end_date
  } = policy;

  return (
    <Container>
      <PolicyNumber>Policy Number: {policy_number}</PolicyNumber>
      <Row>
        <Label>Status</Label>
        <Info>{status}</Info>
      </Row>
      <Row>
        <Label>Leasing address</Label>
        <Info>
          {address_line_one}, {unit_name ? `Unit ${unit_name},` : ``} {city}, {state}, {zipcode}
        </Info>
      </Row>
      <Row>
        <Label>Lease dates</Label>
        <Info>{`${formatToLocaleDate(lease_start_date)}\u2014${formatToLocaleDate(lease_end_date)}`}</Info>
      </Row>
      <Row>
        <Label>Coverage dates</Label>
        <Info>{`${formatToLocaleDate(coverage_start_date)}\u2014${formatToLocaleDate(coverage_end_date)}`}</Info>
      </Row>
      <Row>
        <Label>Rent amount</Label>
        <Info>{monthly_rent}</Info>
      </Row>
      <Row>
        <Label>Coverage amount</Label>
        <Info>{coverage_amount}</Info>
      </Row>
    </Container>
  );
};

export default PolicyCoverageDetails;
