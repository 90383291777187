import React, { SyntheticEvent } from 'react';

import AddressInput from 'components/common/form/address-input';
import { useSubsetState } from '../step_helpers';
import { BackButton, renderError } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import MapWithOverlay from '../map_with_overlay';
import { useAssetContext } from './asset_context';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import Error from 'components/common/form/input/Error';

function MostRecentAddress(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const { invitation } = props;
  const [policyApplication, setPolicyApplication] = useSubsetState(props.policyApplication.result, [
    'recent_address_line_one',
    'recent_address_line_two',
    'recent_google_place_id',
    'first_time_renter'
  ]);

  const updateApplication = (update?: object) => {
    setPolicyApplication(Object.assign({}, policyApplication, update));
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    if (policyApplicationId && userId && policyApplication) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const onAddressChange = (p, u) => {
    updateApplication({
      recent_google_place_id: p.id,
      recent_address_line_one: p.full_address,
      recent_address_line_two: u.name
    });
  };

  const toggleFirstTimeRenter = () => {
    updateApplication({
      first_time_renter: !policyApplication?.first_time_renter,
      recent_google_place_id: '',
      recent_address_line_one: '',
      recent_address_line_two: ''
    });
  };

  const mobileMap = () => {
    const assets = useAssetContext();
    const mapProps = invitation.result?.map_props;

    if (mapProps) {
      const mapWithOverlayProps = {
        ...mapProps,
        wrapperId: 'mobile-card',
        assets: assets.map
      };

      return (
        <div className="d-md-none">
          <MapWithOverlay {...mapWithOverlayProps} />
        </div>
      );
    }
  };

  return (
    <div>
      <h2 className="bold header" data-cy="currentAddressHeader">
        Where do you currently live?
      </h2>
      <div className="form-container">
        <form className="sign-up__form" id="most-recent-address-form" onSubmit={onSubmit}>
          <AddressInput
            cyAddress="currentAddress"
            cyUnit="currentUnit"
            autocompleteProps={{ fieldId: 'currentAddress' }}
            property={{
              address_line_one: policyApplication?.recent_address_line_one,
              id: policyApplication?.recent_google_place_id,
              full_address: policyApplication?.recent_address_line_one
            }}
            unit={{ name: policyApplication?.recent_address_line_two }}
            mapEnable={true}
            onChange={onAddressChange}
            disabled={policyApplication?.first_time_renter}
            empty={policyApplication?.first_time_renter}
            algoliaEnabled={false}
          />
          {renderError('recent_address', props.policyApplication)}
          <div className="v2-checkbox position-relative">
            <input
              data-cy="firstTimeRenter"
              type="checkbox"
              id="first_time_renter"
              onClick={toggleFirstTimeRenter}
              value={policyApplication?.first_time_renter?.toString()}
              checked={policyApplication?.first_time_renter}
              readOnly={true}
            />
            <label htmlFor="first_time_renter" className="v2-checkbox-label">
              I’m a first-time renter
            </label>
          </div>

          {/* Visiblity enabled upon Google Maps API failure in app/javascript/utils/google.js */}
          <div id="google-maps-api-error-message-container" style={{ display: 'none' }}>
            <Error text="Sorry, we're having trouble with our address searching service at the moment." />
          </div>

          <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <BackButton step={props.step} />
            <input
              type="submit"
              className="btn-default"
              data-cy="continue"
              value="Continue"
              disabled={!policyApplication.recent_address_line_one && !policyApplication.first_time_renter}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MostRecentAddress;
