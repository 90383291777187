import React, { useState } from 'react';

// @ts-ignore
import downloadIcon from '../../policy_coverage/download-icon.svg';
// @ts-ignore
import policyDocImage from '../../policy_coverage/policy-document.png';

import { csrfToken } from 'utils/document';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

interface IProps {
  url: string;
  documentEffectiveDate: string;
  id: string | number;
}

function initializeDownload(url: string): Promise<Response> {
  url = `${url}?authenticity_token=${csrfToken()}`;
  return fetch(url, { credentials: 'same-origin' });
}

function handleReceived(data) {
  const openUrl = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  openUrl(data.documents_url);
  openUrl(data.rental_bond_url);
  openUrl(data.landlord_rental_reimbursement_url);
  openUrl(data.rental_bond_dec_page_url);
  openUrl(data.provision_endorsement_url);
  openUrl(data.snc_endorsement_url);
  openUrl(data.snc_policyholder_notice_url);
  openUrl(data.signature_page_renter_url);
  openUrl(data.standard_privacy_statement_url);
  openUrl(data.ofac_notice_url);
  openUrl(data.taa_url);
  openUrl(data.trade_or_economic_sanctions_url);
  openUrl(data.service_fee_endorsement_url);
  openUrl(data.complaint_notice_url);
}

const PolicyDocuments: React.FC<IProps> = (props: IProps) => {
  const { url, documentEffectiveDate } = props;
  const [buttonText, updateButtonText] = useState('Download');
  const subscriptions = (window as any).App.cable.subscriptions;
  const isMobile = useCheckMobile();

  const initializeDownloader = () => {
    subscriptions.create('DownloadChannel', {
      connected: () => {
        // connected
      },
      disconnected: () => {
        // disconnected
      },
      received: (data) => {
        if (subscriptions.subscriptions.length >= 1) {
          subscriptions.subscriptions = [];
        }
        updateButtonText('Download');
        handleReceived(data);
      }
    });
  };

  const onDownloadClick = () => {
    updateButtonText('Downloading...');

    initializeDownloader();
    initializeDownload(url);
  };

  const Header = styled.div([FONTS.h4], { lineHeight: '32px', marginBottom: 10 });

  return (
    <div style={styles.container} className="payment-box row">
      <div className="col-12" style={styles.textContainer}>
        <div className="row no-gutters" style={styles.textContainerInner}>
          <div className="col-12" style={styles.text}>
            <Header>Policy Documents</Header>
          </div>
          <div className="col-6 col-sm-3">
            <img src={policyDocImage} style={styles.image} />
          </div>
          <div className="col-6 col-sm-9" style={{ paddingLeft: isMobile ? '0px' : '20px' }}>
            <div style={styles.subheading}>Created {documentEffectiveDate}</div>
            <a href="#" onClick={onDownloadClick} className="view-documents-button">
              <div style={styles.button}>
                <div style={styles.buttonText} className="view-documents-text">
                  {buttonText}
                </div>
                <img src={downloadIcon} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    border: '1px solid #E3E3E3',
    borderRadius: '8px'
  },
  image: {
    borderRadius: '8px'
  },
  textContainer: {
    width: '100%',
    margin: '0',
    padding: '0px 30px'
  },
  textContainerInner: {
    display: 'flex' as 'flex',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16
  },
  subheading: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#777777',
    marginBottom: '10px'
  },
  text: {
    alignSelf: 'center' as 'center'
  },
  button: {
    border: '1px solid #000000',
    borderRadius: '24px',
    display: 'inline-flex',
    padding: '4px 20px'
  },
  buttonText: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '14px',
    lineHeight: '24px',
    paddingRight: '10px'
  }
};

export default PolicyDocuments;
