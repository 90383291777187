import { useState } from 'react';
import { useCreatePropertyManagerMutation } from './useCreatePropertyManagerMutation';
import { validateEmail, validateRequiredField } from '../../utils/formValidations';

export const useResidentialForm = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | undefined>();
  const [unitCountOption, setUnitCount] = useState<any>();
  const unitCount = unitCountOption?.value ?? '';
  const [unitCountError, setUnitCountError] = useState<string | undefined>();
  const { mutate: createPropertyManager, isLoading } = useCreatePropertyManagerMutation();
  const resetErrors = () => {
    setEmailError(undefined);
    setUnitCountError(undefined);
  };

  const handleEmailChange = (value) => {
    setEmailError(undefined);
    setEmail(value);
  };

  const handleUnitCountChange = (option) => {
    setUnitCountError(undefined);
    setUnitCount(option);
  };

  const validateForm = () => {
    let isValid = true;

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      setEmailError(emailValidation);
      isValid = false;
    }

    const unitValidation = validateRequiredField(unitCount, 'Units Under Management');
    if (unitValidation) {
      setUnitCountError(unitValidation);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    resetErrors();

    if (validateForm()) {
      createPropertyManager(
        { email, unitCount },
        {
          onError: (error: any) => {
            if (error.message === 'Request failed with status code 422') setEmailError('Email has already been taken');
          }
        }
      );
    }
  };

  const unitCountOptions = [
    { value: 'under_2000', label: '1 – 1,999' },
    { value: 'between_2000_7999', label: '2,000 – 7,999' },
    { value: 'between_8000_14999', label: '8,000 – 14,999' },
    { value: 'over_15000', label: '15,000+' }
  ];

  return {
    email,
    handleEmailChange,
    unitCountOption,
    handleUnitCountChange,
    emailError,
    unitCountError,
    handleSubmit,
    isLoading,
    unitCountOptions
  };
};
