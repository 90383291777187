import axios from 'axios';
import { MutationFunction } from 'react-query';
import { WrappedUser } from '../interfaces';
import { addCsrf } from '../redux/actions';
import { User } from '../redux/state';

export const createUser : MutationFunction<User, User> = async (user) => {
  const body = addCsrf({user});
  const url = `/users.json`;
  const result = await axios.post<WrappedUser>(url, body);
  return result.data.user;
}
