// @flow
import React from 'react';

const SelectionError = (): React$Node => (
  <div className="flow-error--container">
    <span className="error--default">Selection can‘t be blank.</span>
  </div>
)

export default SelectionError;
