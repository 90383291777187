/* tslint:disable */
interface IPriceAverages {
  by_state: { [key: string]: [number, number]; };
  by_market: { [key: string]: [number, number]; };
}
// [coverage amount, base price]
const values: IPriceAverages = {
  by_state: {
    AL: [5000, 323],
    CA: [1500, 109], 
    CO: [1000, 68],
    FL: [1500, 79],
    IL: [2500, 119],
    NC: [2500, 170],
    NE: [725, 59],
    NJ: [3600, 248],
    NY: [5000, 421],
    OH: [1000, 102],
    OK: [1000, 66],
    SD: [795, 76],
    TN: [1000, 82],
    TX: [1500, 91],
    UT: [1000, 63],
    VA: [1000, 65]
  },
  by_market: {
    admitted: [795, 76],
    excess_lines: [1000, 68]
  }
};

export default values;
