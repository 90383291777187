import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from '../reducers';

const defaultPolicy = {
  building_name: '-',
  address_line_one: '-',
  state: '-',
  city: '-',
  zipcode: '-',
  coverage_amount: '-',
  coverage_end_date: '-',
  coverage_start_date: '-',
  lease_end_date: '-',
  lease_start_date: '-',
  document_effective_date: '-',
  monthly_rent: '-',
  payment_amount: '-',
  policy_number: '-',
  status: '-',
  documents_url: '#',
  policy_id: null,
  upfront: false,
  pending_change_request: false,
  eligible_for_renewal: false,
  change_request_id: null,
  lease_id: null,
  price_info: { upfront: '', monthly: '' },
  update_payment_method: false,
  cancellation_request: null
};

export default function configureStore(preloadedState) {
  const middlewares = [];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = compose(middlewareEnhancer);

  const insurancePolicy = { ...defaultPolicy, ...preloadedState.insurancePolicy };
  const initialState = { ...preloadedState, insurancePolicy };

  const store = createStore(rootReducer, initialState, composedEnhancers);

  return store;
}
