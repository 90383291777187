import styled from '@emotion/styled';
import { ButtonHabitat, PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';

export default function AddRentersInsuranceButton({ rentersInsuranceAdded, children, ...rest }) {
  const Button = styled(ButtonHabitat)({
    '&&': {
      width: '237px',
      textAlign: 'center',
      justifyContent: 'center',
      padding: '10px 24px',
      color: rentersInsuranceAdded ? PALETTE.neutralLight : PALETTE.neutralDark,
      backgroundColor: rentersInsuranceAdded ? PALETTE.neutralDark : PALETTE.neutralLight,
      '@media (max-width: 768px)': {
        width: '100%'
      }
    }
  });
  return (
    <Button {...rest} variant="black" usage="secondary" id="add-renters-insurance">
      {children}
    </Button>
  );
}
