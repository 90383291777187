import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RhinoLogo, CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';
import Start from './Start';
import FinalStepMessage from './FinalStepMessage';
import Checkout from './Checkout';
import GetQuote from './GetQuote';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import PolicyDetails from 'components/v2/quote_display/policy_details';
import { IResult } from 'components/v2/signup/redux/actions';
import { PolicyApplication } from 'components/v2/signup/redux/state';
import { IRentersInsuranceAddress } from '../interfaces';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';
import env from 'utils/env';

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

const ModalContainer = styled.div({
  padding: '39px 107px',
  '@media (max-width: 768px)': {
    padding: '34px 20px 34px 21px'
  },
  minHeight: '100dvh',
  display: 'flex',
  flexDirection: 'column'
});

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  padding: '88px 0',
  maxWidth: '538px',
  margin: '0 auto',
  '@media (max-width:  768px)': {
    padding: '42px 0 30px 0'
  },
  flexGrow: 1
});

const MobileContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  padding: '88px 0',
  '@media (max-width:  768px)': {
    padding: '42px 0 30px 0'
  },
  flexGrow: 1
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer'
});

export type ValidSteps = 'START' | 'QUOTE' | 'REVIEW_POLICY' | 'CHECKOUT' | 'INSURED' | 'POLICY_CONFIRMATION_FAILED';

export const Steps: { [key in ValidSteps]: ValidSteps } = {
  START: 'START',
  QUOTE: 'QUOTE',
  CHECKOUT: 'CHECKOUT',
  REVIEW_POLICY: 'REVIEW_POLICY',
  INSURED: 'INSURED',
  POLICY_CONFIRMATION_FAILED: 'POLICY_CONFIRMATION_FAILED'
};

interface RentersInsuranceModalProps {
  firstName?: string;
  addressesForRI?: IRentersInsuranceAddress[];
  userId?: number;
  onCloseModal: () => void;
  policyApp?: IResult<PolicyApplication>;
}

const RentersInsuranceModal = ({
  onCloseModal,
  userId,
  addressesForRI,
  firstName,
  policyApp
}: RentersInsuranceModalProps) => {
  const [step, setStep] = useState<string>(Steps.START);
  const [rentersInsurancePolicy, setRentersInsurancePolicy] = useState<any>(null);
  const [confirmPolicyErrors, setConfirmPolicyErrors] = useState<any>({});
  const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTIONS.Upfront);
  const [selectedPolicyApp, setSelectedPolicyApp] = useState({
    label: '',
    value: 0,
    map_props: { map: { location: '', size: { width: 0, height: 0 }, apiKey: '' } }
  });
  const [rentersInsuranceRequired, setRentersInsuranceRequired] = useState<boolean>(false);
  const [selectedPartnerRequiresRentersInsurance, setSelectedPartnerRequiresRentersInsurance] = useState<
    boolean | null
  >(null);
  const RIModalWrapper = document.getElementById('ri-modal-wrapper');
  const policyAddress = {
    street_address: policyApp?.result?.address_line_one || '',
    city: policyApp?.result?.address_city || '',
    state: policyApp?.result?.address_state || '',
    zip: policyApp?.result?.zipcode || '',
    unit: policyApp?.result?.address_line_two || ''
  };
  const coverage = {
    start_date: policyApp?.result?.coverage_start_date || '',
    end_date: policyApp?.result?.coverage_end_date || '',
    amount: policyApp?.result?.coverage_amount_cents || '',
    policy_term: policyApp?.result?.policy_term || 0
  };
  const lease = {
    start_date: policyApp?.result?.lease_start_date || '',
    end_date: policyApp?.result?.lease_end_date || ''
  };

  const isMobile = useCheckMobile();

  const rentersInsuranceInstalmentsFlag = (window as any)?.App?.featureFlags?.rentersInsuranceInstalments;

  const disqualifiedFromMonthlySdi = policyApp?.result?.disqualified_from_monthly;
  const qualifiesForMonthlyRentersInsurance =
    env('MONTHLY_RENTERS_INSURANCE_STATES').includes(policyAddress.state) && !disqualifiedFromMonthlySdi;

  const eligibleInstalmentRIState =
    env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(policyAddress.state) && rentersInsuranceInstalmentsFlag;

  const goToStep = (modalStep) => {
    setStep(modalStep);
    if (RIModalWrapper) {
      RIModalWrapper.scrollIntoView();
    }
  };

  useEffect(() => {
    if (addressesForRI!.length === 1) {
      setSelectedPolicyApp(addressesForRI![0]);
    }
  });

  const renderStep = () => {
    switch (step) {
      case Steps.START:
        return (
          <Start
            addresses={addressesForRI!}
            onCloseModal={onCloseModal}
            userId={userId!}
            firstName={firstName!}
            setRentersInsurancePolicy={setRentersInsurancePolicy}
            goToStep={goToStep}
            setSelectedPolicyApp={setSelectedPolicyApp}
            selectedPolicyApp={selectedPolicyApp}
            setRentersInsuranceRequired={setRentersInsuranceRequired}
            setSelectedPartnerRequiresRentersInsurance={setSelectedPartnerRequiresRentersInsurance}
          />
        );
      case Steps.QUOTE:
        return (
          <GetQuote
            rentersInsurancePolicy={rentersInsurancePolicy}
            goToStep={goToStep}
            setPaymentOption={setPaymentOption}
            paymentOption={paymentOption}
            policyApplication={policyApp}
            rentersInsuranceRequired={rentersInsuranceRequired}
            partnerRequiresRentersInsurance={selectedPartnerRequiresRentersInsurance}
            eligibleInstalmentRIState={eligibleInstalmentRIState}
          />
        );
      case Steps.REVIEW_POLICY:
        return (
          <PolicyDetails
            rentersInsurancePolicy={rentersInsurancePolicy}
            goToStep={goToStep}
            selectedPolicyApp={selectedPolicyApp}
            inAccount={true}
            map={selectedPolicyApp.map_props.map}
            address={policyAddress}
            coverage={coverage}
            addressForRI={selectedPolicyApp.label}
            lease={lease}
            paymentOption={paymentOption}
            qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
          />
        );
      case Steps.CHECKOUT:
        return (
          <Checkout
            onCloseModal={onCloseModal}
            rentersInsurancePolicy={rentersInsurancePolicy}
            setConfirmPolicyErrors={setConfirmPolicyErrors}
            goToStep={goToStep}
            paymentOption={paymentOption}
            qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
            eligibleInstalmentRIState={eligibleInstalmentRIState}
          />
        );
      case Steps.INSURED:
        return <FinalStepMessage onCloseModal={onCloseModal} />;
      case Steps.POLICY_CONFIRMATION_FAILED:
        return (
          <FinalStepMessage onCloseModal={onCloseModal} errors={confirmPolicyErrors} />
        );
      default:
        return null;
    }
  };

  const contentContainer = isMobile ? (
    <MobileContentContainer>{renderStep()}</MobileContentContainer>
  ) : (
    <ContentContainer>{renderStep()}</ContentContainer>
  );

  return (
    <ModalContainer id="ri-modal-wrapper">
      <HeaderContainer>
        <RhinoLogo />
        <CloseIcon onClick={onCloseModal} data-cy="modalCancel" />
      </HeaderContainer>
      {contentContainer}
    </ModalContainer>
  );
};

export default RentersInsuranceModal;
