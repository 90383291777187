import React, { useState } from 'react';
import { connect } from 'react-redux';

import Slider from 'components/v2/drawer/slider';
import Backdrop from 'components/v2/drawer/backdrop';

import RoommateCard from './RoommateCard';
import { IRoommate } from '../policyProps';

interface IProps {
  roommates: IRoommate[];
}

interface IRoommateProps {
  onClick: () => void;
  roommate: IRoommate;
}

const Roommates: React.FC<IProps> = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => setIsOpen(true);

  const renderContent = () => {
    return (
      <div className="inner">
        <div className="header border-bottom" >
          <h6 className="demi-18">Your Roommates</h6>
        </div>

        <div className="content">
          {
            props.roommates.map((roommate, index) => (
              <RoommateCard
                key={roommate?.id || roommate?.temporaryId}
                index={index}
                numeration={props.roommates.length - index}
                roommate={roommate} />
            ))
          }
        </div>
      </div>
    );
  };

  return (
    <div className="roommates">
      <div className="drawer">
        <Slider
          show={isOpen}
          renderContent={renderContent}
          close={() => setIsOpen(false)}
          buttonBackground={'#F5F4FB'} />

        {isOpen && <Backdrop close={() => setIsOpen(false)} />}
      </div>

      <div className="add-roommate" onClick={onClick}>
        <p>Add a roommate</p>
        <div className="plus-sign"/>
      </div>

      {
        props.roommates.map((roommate) => (
          <Roommate
            key={roommate.id || roommate?.temporaryId}
            roommate={roommate}
            onClick={onClick} />
        ))
      }
    </div>
  );
};

const Roommate: React.FC<IRoommateProps> = (props: IRoommateProps) => {
  const { roommate } = props;
  const [useInitials, setUseInitials] = useState(true);
  const name = useInitials ? roommate.initials : roommate.full_name;

  if (!roommate?.id) return null;

  return (
    <div
      className="roommate"
      onClick={props.onClick}
      onMouseEnter={() => setUseInitials(false)}
      onMouseLeave={() => setUseInitials(true) }
    >
      {name}
    </div>
  )
}

const mapStateToProps = ({ roommates }) => ({ roommates });

export default connect(mapStateToProps)(Roommates);
