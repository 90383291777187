import React, { ChangeEvent, useEffect, useState } from 'react';
import { Radio, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import {
  ButtonGroup,
  HeaderWrapper,
  SelectorWrapper,
  StyledTextInput,
  HeaderText,
  StyledContent,
  StyledBackButton,
  WithdrawalWrapper,
  DepositAmount,
  Heading,
  BankSectionText,
  StyledRadioRoot,
  DepositSection,
  FormLabel,
  PhoneInputWrapper
} from './Styled';
import { useBankAccountForm } from './useBankAccountForm';
import SwitchSelector from './CoverageSelector';
import _ from 'lodash';
import { ToastContextProvider } from './toast/toast-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { formatCents } from 'utils/money/formatter';
import { AccountTypes, IBankAccountInfo } from './types';
import ErrorText from 'components/common/form/error-text/error-text';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const queryClient = new QueryClient();

type IProps = {
  withdrawalAmountInCents: number;
  bankAccount?: { formFields: IBankAccountInfo; uid: string };
};

const BankAccountFormWrapped = ({ withdrawalAmountInCents, bankAccount }: IProps) => {
  const { account, setAccount, errors, handleSubmit, isLoading, isRedirecting } = useBankAccountForm();
  const formattedWithdrawalAmount = formatCents(withdrawalAmountInCents);
  const [phoneNumberCountry, setPhoneNumberCountry] = useState('us');

  useEffect(() => {
    if (bankAccount) {
      setAccount((acc) => ({
        ...acc,
        ...bankAccount.formFields
      }));
    }
  }, []);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccount((acc) => ({
      ...acc,
      [e.target.name]: e.target.value
    }));
  };
  const requiredBankAccountFields = [
    'bank_name',
    'account_number',
    'contact_phone_number',
    'first_name_on_account',
    'last_name_on_account',
    'account_type'
  ];
  const requiredUsDomesticFields = ['ach'];
  const requiredInternationalAccountFields = [
    'recipient_country',
    'recipient_address_state',
    'recipient_address_city',
    'recipient_postal_code',
    'bic_swift_code',
    'recipient_address_street'
  ];
  const isFormValid = React.useCallback(() => {
    // if the phone number is a us phone number, check that it has 17 characters
    // since it will be in format: '+1 (xxx) xxx-xxxx' for the PhoneInput component)
    // otherwise, we won't currently validate whether the phone number has the correct form
    const phoneValid = phoneNumberCountry !== 'us' || account.contact_phone_number.length === 17;
    const requiredFields =
      account.internal_account_type === 'us_domestic'
        ? Object.values(_.pick(account, [...requiredBankAccountFields, ...requiredUsDomesticFields]))
        : Object.values(_.pick(account, [...requiredBankAccountFields, ...requiredInternationalAccountFields]));
    return phoneValid && requiredFields.every((value) => value !== null && value !== '');
  }, [account, phoneNumberCountry]);

  const isSubmitDisabled = !isFormValid() || isLoading || isRedirecting;

  return (
    <ToastContextProvider position="bottom-right">
      <WithdrawalWrapper>
        <HeaderWrapper>
          <Heading>Transfer your deposit</Heading>
        </HeaderWrapper>
        {withdrawalAmountInCents === 0 && <ErrorText text="You have no available funds to withdraw" />}
        <DepositSection>
          <HeaderText>1. Deposit Amount</HeaderText>
          <DepositAmount>{formattedWithdrawalAmount}</DepositAmount>
        </DepositSection>
        <StyledContent>
          <HeaderText>2. Bank Details</HeaderText>
          <BankSectionText>
            Add the bank information of the account you'd like to transfer your deposit funds
          </BankSectionText>
          <SelectorWrapper className={!!bankAccount ? 'disabled' : ''}>
            <SwitchSelector
              active={account.internal_account_type}
              list={[
                { label: 'Domestic Account', value: 'us_domestic' },
                { label: 'International Account', value: 'international' }
              ]}
              disabled={!!bankAccount}
              id="internal_account_type"
              onClick={(value) => {
                if (value === 'us_domestic' || value === 'international') {
                  setAccount((prev) => ({
                    ...prev,
                    internal_account_type: value
                  }));
                }
              }}
            />
          </SelectorWrapper>
          <StyledTextInput
            id="bank_name"
            onChange={handleFormChange}
            label="Bank Name"
            subtext={errors.bank_name}
            error={!!errors.bank_name}
            name="bank_name"
            value={account.bank_name}
            required
          />
          <StyledTextInput
            id="nickname"
            label="Account Nickname"
            subtext={errors.nickname}
            error={!!errors.nickname}
            name="nickname"
            onChange={handleFormChange}
            value={account.nickname}
          />
          <StyledTextInput
            id="account_number"
            label="Account Number"
            type="number"
            subtext={errors.account_number}
            error={!!errors.account_number}
            name="account_number"
            onWheel={(event) => event.currentTarget.blur()}
            onChange={handleFormChange}
            value={account.account_number}
          />
          {account.internal_account_type === 'us_domestic' && (
            <StyledTextInput
              id="ach"
              label="ACH Routing Number"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              onChange={handleFormChange}
              name="ach"
              subtext={errors.ach}
              error={!!errors.ach}
              required
              value={account.ach}
            />
          )}
          {account.internal_account_type === 'international' && (
            <StyledTextInput
              id="bic_swift_code"
              label="BIC/Swift/Transfer No./Bank ID"
              subtext={errors.bic_swift_code}
              error={!!errors.bic_swift_code}
              onChange={handleFormChange}
              name="bic_swift_code"
              required
              value={account.bic_swift_code}
            />
          )}
          <FormLabel>Account Type</FormLabel>
          <StyledRadioRoot
            name="account_type"
            value={account.account_type}
            onValueChange={(value: AccountTypes) => {
              setAccount({
                ...account,
                account_type: value
              });
            }}
          >
            <Radio.Option id="checking" value={AccountTypes.CHECKING} label="Checking" />
            <Radio.Option id="savings" value={AccountTypes.SAVINGS} label="Savings" />
          </StyledRadioRoot>
          <StyledTextInput
            id="first_name"
            name="first_name_on_account"
            label="Recipient First Name"
            subtext={errors.first_name_on_account}
            error={!!errors.first_name_on_account}
            onChange={handleFormChange}
            value={account.first_name_on_account}
            required
          />
          <StyledTextInput
            id="last_name"
            label="Recipient Last Name"
            subtext={errors.last_name_on_account}
            error={!!errors.last_name_on_account}
            onChange={handleFormChange}
            value={account.last_name_on_account}
            name="last_name_on_account"
            required
          />
          {account.internal_account_type === 'international' && (
            <React.Fragment>
              <StyledTextInput
                id="country"
                label="Recipient Country"
                subtext={errors.recipient_country}
                error={!!errors.recipient_country}
                onChange={handleFormChange}
                name="recipient_country"
                required
                value={account.recipient_country}
              />
              <StyledTextInput
                id="state"
                label="Recipient State"
                subtext={errors.recipient_address_state}
                error={!!errors.recipient_address_state}
                onChange={handleFormChange}
                name="recipient_address_state"
                required
                value={account.recipient_address_state}
              />
              <StyledTextInput
                id="city"
                label="Recipient City"
                subtext={errors.recipient_address_city}
                error={!!errors.recipient_address_city}
                onChange={handleFormChange}
                name="recipient_address_city"
                required
                value={account.recipient_address_city}
              />
              <StyledTextInput
                id="street"
                label="Recipient Street"
                subtext={errors.recipient_address_street}
                error={!!errors.recipient_address_street}
                onChange={handleFormChange}
                name="recipient_address_street"
                required
                value={account.recipient_address_street}
              />
              <StyledTextInput
                id="recipient_postal_code"
                label="Recipient Post Code"
                subtext={errors.recipient_postal_code}
                error={!!errors.recipient_postal_code}
                onChange={handleFormChange}
                name="recipient_postal_code"
                required
                value={account.recipient_postal_code}
              />
            </React.Fragment>
          )}
          <PhoneInputWrapper>
            <FormLabel>Recipient Phone Number</FormLabel>
            <PhoneInput
              defaultCountry="us"
              className="phone-input-container"
              inputClassName="phone-number phone-input"
              countrySelectorStyleProps={{ buttonClassName: 'phone-input' }}
              value={account.contact_phone_number}
              onChange={(phone, country) => {
                setAccount({
                  ...account,
                  contact_phone_number: phone
                });
                setPhoneNumberCountry(country);
              }}
            />
          </PhoneInputWrapper>
        </StyledContent>
        <ButtonGroup>
          <StyledBackButton variant="secondary" onClick={() => (window.location.href = '/users/edit')}>
            Go Back
          </StyledBackButton>
          <ButtonHabitat
            id="submit-button"
            variant="primary"
            type="submit"
            children="Transfer Money"
            onClick={(event) => handleSubmit(event, withdrawalAmountInCents, bankAccount?.uid)}
            disabled={isSubmitDisabled}
          />
        </ButtonGroup>
      </WithdrawalWrapper>
    </ToastContextProvider>
  );
};

const BankAccountForm = (props: IProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BankAccountFormWrapped {...props} />
    </QueryClientProvider>
  );
};
export default BankAccountForm;
