import React, { Component, ReactNode } from 'react';

interface IProps {
  index: number;
  item: string;
  subheader?: string;
  content?: string;
  link?: string;
}

class MediaItem extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, subheader, link, content } = this.props;
    return (
      <div className="item carousel-card" key={index}>
        <span onClick={() => link && window.open(link, '_blank')}>
          <img style={styles.image} src={item} alt={item}/>
          {subheader && <img style={styles.quotes} src={subheader.toString()} />}
        </span>
        {content && <p style={styles.text}>{content}</p>}
      </div>
    );
  }
}

const styles = {
  image: {
    width: '144px'
  },
  quotes: {
    width: '20px'
  },
  text: {
    fontFamily: 'MaisonNeueExtendedLight',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black',
    marginTop: '25px'
  }
};

export default MediaItem;
