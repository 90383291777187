import React, { useMemo } from 'react';
import { CardElementComponent, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { missedPayment } from '../constants';
import ActionCard from './ActionCard';
import useDeferredOrAsyncEffect from '../../../../utils/hooks/useDeferredOrAsyncEffect';
import PayNowModalContainer from './PayNowModal/PayNowModalContainer';
import usePayNowModals from '../components/PayNowModal/usePayNowModals';
import { getFeatureFlags } from '../../../../utils';
import env from 'utils/env';

import {
  getInvoicesCount,
  getHumanizedInvoicesTotal,
  showPayNowModalParamPresent
} from '../components/PayNowModal/utils';
interface Props {
  isDelinquent: boolean;
  fullName: string;
  userId: string | number;
  myProfilePath: string;
  defaultCard?: {
    brand: string;
    country: string;
    exp_month: string | number;
    exp_year: string | number;
    funding: string;
    last4: string | number;
    id: string;
  };
  unpaidInvoicesBreakdown: any;
  stripePublicKey: string;
}

export const Delinquency = ({
  isDelinquent,
  fullName,
  stripePublicKey,
  defaultCard,
  userId,
  myProfilePath,
  unpaidInvoicesBreakdown
}: Props) => {
  const featureFlags = getFeatureFlags();
  const isPayNowModalEnabled = Boolean(featureFlags?.pay_now_modal);

  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [stripePublicKey]);

  const {
    showPayNowModal,
    showPaymentConfirmationModal,
    showPartialPaymentConfirmationModal,
    handlePayNowClickWithTracking,
    handleShowPayNowOnPageLoadWithTracking,
    handleClosePayNowModalWithTracking,
    reloading,
    handleShowPaymentConfirmationModal,
    handleShowPartialPaymentConfirmationModal,
    handleClosePaymentConfirmationModal,
    handleClosePartialPaymentConfirmationModal,
    handlePayRemainingBalanceClick
  } = usePayNowModals({ myProfilePath });

  const invoiceTotal = getHumanizedInvoicesTotal(unpaidInvoicesBreakdown);
  const invoiceCount = getInvoicesCount(unpaidInvoicesBreakdown);
  const showPayNowOnPageLoad = handleShowPayNowOnPageLoadWithTracking(userId);

  useDeferredOrAsyncEffect(() => {
    if (showPayNowModalParamPresent() && isDelinquent) {
      showPayNowOnPageLoad();
    }
  }, [isDelinquent]);

  if (!isPayNowModalEnabled) {
    return null;
  }

  if (!isDelinquent || invoiceCount === 0) {
    return null;
  }

  const options: any = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false
    },
    mode: 'setup',
    currency: 'usd',
    payment_method_configuration: env('STRIPE_SUPER_PARENT_CC_ONLY_PAYMENT_METHOD_CONFIG')
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <PayNowModalContainer
          reloading={reloading}
          defaultCard={defaultCard}
          stripePublicKey={stripePublicKey}
          fullName={fullName}
          unpaidInvoicesBreakdown={unpaidInvoicesBreakdown}
          showPaymentConfirmationModal={showPaymentConfirmationModal}
          showPayNowModal={showPayNowModal}
          showPartialPaymentConfirmationModal={showPartialPaymentConfirmationModal}
          handleClosePayNowModal={handleClosePayNowModalWithTracking(userId)}
          handleShowPaymentConfirmationModal={handleShowPaymentConfirmationModal}
          handleShowPartialPaymentConfirmationModal={handleShowPartialPaymentConfirmationModal}
          handleClosePaymentConfirmationModal={handleClosePaymentConfirmationModal}
          handleClosePartialPaymentConfirmationModal={handleClosePartialPaymentConfirmationModal}
          handlePayRemainingBalanceClick={handlePayRemainingBalanceClick}
        />
      </Elements>

      <ActionCard
        header={missedPayment.header}
        text={missedPayment.text(invoiceCount, invoiceTotal)}
        label={missedPayment.label}
        ctaText={missedPayment.ctaText}
        handleClick={handlePayNowClickWithTracking(userId)}
        destructive={true}
        callToActionId={'missed-payment-pay-now'}
      />
    </>
  );
};
