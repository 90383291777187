import createBankAccount from './createBankAccount';
import { IBankAccountForm, IBankAccountFormErrors, IBankAccountInfo } from './types';
import useUpdateBankAccountMutation from './updateBankAccount';
import { AxiosError } from 'axios';
import { SyntheticEvent, useState } from 'react';
import { useMutation } from 'react-query';
import useToast from './toast/use-toast';

const defaultBankAccount: IBankAccountForm = {
  bank_name: '',
  first_name_on_account: '',
  last_name_on_account: '',
  nickname: '',
  account_type: '',
  internal_account_type: 'us_domestic',
  contact_phone_number: '',
  account_number: '',
  ach: '',
  bic_swift_code: '',
  recipient_address_city: '',
  recipient_address_state: '',
  recipient_address_street: '',
  recipient_postal_code: '',
  recipient_country: ''
};

const bankAccountErrors: IBankAccountFormErrors = {
  ...defaultBankAccount,
  internal_account_type: ''
};

const useBankAccountForm = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { addToast } = useToast();

  const [account, setAccount] = useState<IBankAccountForm>(defaultBankAccount);
  const [errors, setErrors] = useState<IBankAccountFormErrors>(bankAccountErrors);
  const { mutate: createBankAccountMutation, isLoading: isLoadingCreate } = useMutation(createBankAccount, {});
  const { mutate: updateBankAccountMutation, isLoading: isLoadingUpdate } = useUpdateBankAccountMutation();

  const filterParams = (params: IBankAccountForm) => {
    return {
      bank_name: params.bank_name,
      account_number: params.account_number,
      first_name_on_account: params.first_name_on_account,
      last_name_on_account: params.last_name_on_account,
      account_type: params.account_type,
      internal_account_type: params.internal_account_type ?? 'us_domestic',
      contact_phone_number: params.contact_phone_number,
      nickname: params.nickname,
      ...(params.internal_account_type === 'us_domestic' && {
        ach: params.ach
      }),
      ...(params.internal_account_type === 'international' && {
        bic_swift_code: params.bic_swift_code,
        recipient_country: params.recipient_country,
        recipient_address_street: params.recipient_address_street,
        recipient_address_city: params.recipient_address_city,
        recipient_address_state: params.recipient_address_state,
        recipient_postal_code: params.recipient_postal_code
      })
    };
  };

  const create = (bankAccountParams: IBankAccountForm, amountInCents: number) => {
    createBankAccountMutation(
      {
        bank_account: filterParams(bankAccountParams) as IBankAccountInfo,
        amount_in_cents: amountInCents
      },
      {
        onSuccess: () => {
          addToast('Withdrawal requested');
          setIsRedirecting(true);
          window.location.href = '/users/edit';
        },
        // @ts-ignore
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const update = (bankAccountParams: IBankAccountForm, bankAccountUid: string) => {
    updateBankAccountMutation(
      {
        bank_account: filterParams(bankAccountParams) as IBankAccountInfo,
        bank_account_uid: bankAccountUid
      },
      {
        onSuccess: () => {
          addToast('Withdrawal updated');
          setIsRedirecting(true);
          window.location.href = '/users/edit';
        },
        // @ts-ignore
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const handleSubmit = (event: SyntheticEvent, withdrawalAmountInCents: number, bankAccountUid?: string) => {
    event.preventDefault();

    if (bankAccountUid) update(account, bankAccountUid);
    else create(account, withdrawalAmountInCents);
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 422) {
      const data = error.response?.data;
      const responseErrors = data.errors;
      setErrors(JSON.parse(responseErrors));
      addToast(responseErrors, 'error');
    } else {
      addToast('Something went wrong', 'error');
    }
  };

  return {
    account,
    setAccount,
    create,
    handleSubmit,
    handleError,
    errors,
    isLoading: isLoadingCreate || isLoadingUpdate,
    isRedirecting
  };
};

export { useBankAccountForm };
