import Carousel, { ICarouselProps } from 'components/v2/carousel';
import React, { Component } from 'react';

interface IProps {
  subheaders: string[];
  images: string[];
  text_sections: string[];
  alt_tags: string[];
}

class OfferingRhinoIsSimpleModule extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public renderDesktopVersion() {
    const { images, subheaders, text_sections, alt_tags } = this.props;
    const cardImages = images.filter((i) => !i.includes('sign-up-mobile'));

    return (
      <div className="container d-none d-md-block">
        <div className="row" style={styles.desktop.cardSection}>
          {subheaders.map(function (subheader: string, index: number) {
            return (
              <div className="col-md-4 card-container" key={index}>
                <p style={styles.purpleCircle}>{index + 1}</p>
                <br />
                <div style={styles.desktop.textHeader}>{subheader}</div>
                <div style={styles.desktop.text}>{text_sections[index]}</div>
                <div>
                  <img className="card-images" src={cardImages[index]} alt={alt_tags[index]} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  public renderMobileVersion() {
    return (
      <div className="d-block d-md-none">
        <Carousel {...this.carouselProps()} />
      </div>
    );
  }

  public carouselProps = (): ICarouselProps => {
    const { images, subheaders, text_sections, alt_tags } = this.props;
    images[1] = '';
    const headers = images.filter((i) => i !== '');

    return {
      headers,
      type: 'numberedImageWithText',
      itemOptions: {
        alt_tags,
        subheaders,
        content: text_sections
      },
      carouselOptions: {
        responsive: {
          0: { items: 1 }
        },
        loop: false,
        nav: false,
        rewind: true
      }
    };
  };

  public render() {
    return (
      <div>
        {this.renderDesktopVersion()}
        {this.renderMobileVersion()}
      </div>
    );
  }
}

const styles = {
  desktop: {
    cardSection: {
      textAlign: 'center' as 'center',
      marginTop: '75px'
    },
    textHeader: {
      color: 'white',
      fontFamily: 'MaisonNeueExtendedMedium',
      fontSize: '24px',
      marginTop: '27px',
      textAlign: 'left' as 'left'
    },
    text: {
      fontSize: '16px',
      lineHeight: '28px',
      marginTop: '16px',
      textAlign: 'left' as 'left'
    }
  },
  purpleCircle: {
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'center' as 'center',
    float: 'left' as 'left',
    display: 'block',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    marginRight: '10px',
    fontSize: '14px',
    fontWeight: 'bold' as 'bold'
  }
};

export default OfferingRhinoIsSimpleModule;
