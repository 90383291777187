import { csrfToken } from 'utils/document';
import axios from 'axios';

const API_NAMESPACE = `/`;

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};
const DEFAULT_OPTIONS = {
  authenticity_token: csrfToken(),
  credentials: 'same-origin',
  headers: DEFAULT_HEADERS
};

const createPaymentSetup = async (claimId) => {
  const url = `/claims/${claimId}/subrogation/create_payment_setup`;
  const response = await axios.post(url, DEFAULT_OPTIONS);

  return response.data.intent;
};

export { createPaymentSetup };
