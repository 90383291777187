import axios, { AxiosResponse } from 'axios';
import { IPolicy } from 'components/v2/insurance_policies/policyProps';
import { QueryObserverResult, useQuery } from 'react-query';

const getInsurancePolicy = async (id: number | undefined) => {
  const { data }: AxiosResponse = await axios.get<any>(`/insurance_policies/${id}`, {
    headers: { Accept: 'application/json' }
  });
  return data.insurance_policy;
};

export default function useGetInsurancePolicy(id: number | undefined): QueryObserverResult<IPolicy> {
  return useQuery<IPolicy>(['security_deposit_insurance', 'id'], () => getInsurancePolicy(id));
}
