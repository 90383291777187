import { IResult } from 'components/v2/signup/redux/actions';
import { IUser } from '../signup/redux/state';
import { flatMap, omit } from 'lodash';
import moment from 'moment';

export enum PaymentOption {
  Monthly,
  Upfront
}

export function activeClasses(predicate: boolean, classes: string, active: string = 'active'): string {
  return predicate ? `${classes} ${active}` : classes;
}

export function collectErrors(result: IResult<any>): string[] | undefined {
  if (result.errors) {
    return flatMap(result.errors, (v) => v);
  }
}

export const formatDate = (date: string) => {
  return moment(date).format('MM/DD/YYYY');
};
