import { updatePolicyApplication, IUpdatePolicyApplication } from './useUpdatePolicyApplication';
import { updateUser, IUserUpdateVariables } from './useUpdateUser';
import { createPolicyApplication, ICreatePolicyApplicationVars } from './useCreatePolicyApplication';
import { useMutation, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { IResult, updateUserAndPolicyApplicationSync, IUCP } from '../redux/actions';
import { AxiosError } from 'axios';
import { createUser } from './useCreateUser';
import { PolicyApplication, User } from '../redux/state';
import { redirectToPartnerEnrollment, verifyResponseForConflict } from '../step_helpers';

interface IPersistOptions<TData, TError> {
  onSuccess: (data: TData) => void;
  onError: (error: TError) => void;
}

export const usePersistUserAndPolicyApplication = () => {
  const dispatch = useDispatch();
  const policyApplicationUpdate: UseMutationResult<
    PolicyApplication,
    AxiosError<IResult<PolicyApplication>>,
    IUpdatePolicyApplication
  > = useMutation(updatePolicyApplication);
  const policyApplicationCreate: UseMutationResult<
    PolicyApplication,
    AxiosError<IResult<PolicyApplication>>,
    ICreatePolicyApplicationVars
  > = useMutation(createPolicyApplication);
  const userUpdate: UseMutationResult<User, AxiosError<IResult<User>>, IUserUpdateVariables> = useMutation(updateUser);
  const userCreate: UseMutationResult<User, AxiosError<IResult<User>>, User> = useMutation(createUser);

  return (
    user: User,
    policyApplication: PolicyApplication,
    userId?: number,
    policyApplicationId?: number,
    currentStep?: string
  ) => {
    const persistUser = (config: IPersistOptions<User, AxiosError<IResult<User>>>) => {
      if (userId !== undefined) {
        userUpdate.mutate({ userId, user, signupStep: currentStep }, config);
      } else {
        userCreate.mutate(user, config);
      }
    };
    const persistPolicyApplication = (
      id: number,
      config: IPersistOptions<PolicyApplication, AxiosError<IResult<PolicyApplication>>>
    ) => {
      if (policyApplicationId !== undefined) {
        const policyAppVars = { policyApplicationId, userId: id, policyApplication };
        policyApplicationUpdate.mutate(policyAppVars, config);
      } else {
        const policyAppVars = { userId: id, policyApplication };
        policyApplicationCreate.mutate(policyAppVars, config);
      }
    };
    persistUser({
      onSuccess: (userResponse: User) => {
        const id = userId || userResponse.id;
        if (id !== undefined) {
          persistPolicyApplication(id, {
            onSuccess: (policyAppResponse) => {
              const iucp: IUCP = {
                user: { result: userResponse },
                policyApplication: { result: policyAppResponse }
              };
              dispatch(updateUserAndPolicyApplicationSync(iucp, currentStep));
            },
            onError: (e: AxiosError<IResult<PolicyApplication>>) => {
              const error = e.response === undefined ? { errors: { request: [e.message] } } : e.response.data;
              const iucp: IUCP = {
                user: { result: userResponse },
                policyApplication: error
              };
              verifyResponseForConflict(e).then(
                () => dispatch(updateUserAndPolicyApplicationSync(iucp, currentStep))
              ).catch(redirectToPartnerEnrollment);
            }
          });
        } else {
          throw new Error('User ID was undefined');
        }
      },
      onError: (e: AxiosError<IResult<User>>) => {
        const error = e.response === undefined ? { errors: { request: [e.message] } } : e.response.data;
        const iucp: IUCP = {
          user: error
        };
        dispatch(updateUserAndPolicyApplicationSync(iucp, currentStep));
      }
    });
  };
};
