import React from 'react';
import { useContentful } from 'utils/useContentful';
import moment from 'moment';
import { Collection } from './digest.interface';
import Loader from '../loader';

interface IProps {
  category: string;
}

const Digest = ({ category }: IProps) => {
  const [digests, loadingDigests, queryContentful, showMore] = useContentful({
    contentType: 'blogPost',
    category
  });

  const renderThumbnail = (post) => {
    let image;

    if (post.fields.thumbnail) {
      image = post.fields.thumbnail.fields;
    } else {
      image = post.fields.heroImage.fields;
    }

    return (
      <img
        alt={image.description}
        className="card-img"
        src={image.file.url}
        width="100%"
      />
    )
  }

  return (
    <>
      <div className="container digest-subhead">
        <h2 className="subhead4 demi digest-title">Digest</h2>
      </div>
      <div className="container-fluid digest-posts">
        <div className="row">
          {digests.map((post: Collection, index: number) => (
            <div className="col-md-6 col-12 mb-5 mb-md-5" key={`digest-${index}`}>
              <div className="card border-0">
                <div className="row no-gutters">
                  <div className="col-md-4 col-4">
                    <a href={`/blog/posts/${post.fields.slug}`}>
                      {renderThumbnail(post)}
                    </a>
                  </div>
                  <div className="col-md-8 pl-0 pl-md-4 col-8">
                    <div className="card-body py-0">
                      <a href={`/blog/posts/${post.fields.slug}`}>
                        <h6 className="demi">{post.fields.title}</h6>
                      </a>
                      <p className="card-text">
                        <small>{moment(post.sys.createdAt).format('MMM DD, YYYY')}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="py-4 text-center read-more">
        {loadingDigests ? (
          <Loader />
        ) : (
          showMore && (
              <button className="bg-transparent rounded-pill" title="read-more" onClick={() => queryContentful()}>
                Read more
            </button>
          )
        )}
      </div>
    </>
  );
};

export default Digest;
