import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import IncomeOptions from '../constants/income_options';
import { allPresent, BackButton, renderError, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { usePersistUserAndPolicyApplication } from '../hooks/usePersistUserAndPolicyApplication';
import CustomSelect from 'components/common/form/custom-select';

function Income(props: IChildProps) {
  const userAndPolicyApplicationPersist = usePersistUserAndPolicyApplication();
  const [user, setUser] = useSubsetState(props.user.result, ['yearly_income']);
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'yearly_income',
    'address_state'
  ]);
  const monthlyRent = props.policyApplication.result?.monthly_rent || 1000;

  const cappedMonthlyRent = (rent: number) => {
    if (rent < 1000) return 1000;

    if (rent > 10000) return 10000;

    return rent;
  };

  const updateIncome = (newValue) => {
    const yearly_income = newValue.yearly_income.value;
    const update = {
      yearly_income
    };

    setUser(update);
    updatePolicyApplication(update);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    userAndPolicyApplicationPersist(
      user,
      policyApplication,
      props.user.result?.id,
      props.policyApplication.result?.id,
      props.step
    );
  };

  const disabled = !allPresent([policyApplication.yearly_income]);

  return (
    <div>
      <h2 className="bold header" data-cy="annualIncomeHeader">
        What's the total annual income of your household?
      </h2>
      <p className="p2" data-cy="annualIncomeDescription">
        Include the income of everyone that lives with you.
      </p>
      <form className="sign-up__form" id="income-form" onSubmit={onSubmit}>
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              <CustomSelect
                options={IncomeOptions(cappedMonthlyRent(monthlyRent))}
                resource="user"
                field="yearly_income"
                dataCy="income"
                onChange={(yearly_income) => updateIncome({ yearly_income })}
              />
            </div>
            {renderError('yearly_income', props.policyApplication)}

            <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
              <BackButton step={props.step} />
              <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  }
};

export default Income;
