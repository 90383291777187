import React from 'react';
import { Col, InfoDesktop, InfoMobile, LabelContainer, LabelDesktop, LabelMobile, Row } from './styles';
import { formatToLocaleDate, formatLongDate } from '../../common/form/date-picker/utils';
import { IRenderPolicyDetailsProps } from '../renters_insurance/interfaces';
import { RenderNeedToKnow, NeedToKnowText } from './render_need_to_know';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { isAfter, endOfTomorrow, addDays } from 'date-fns';
import { useLocation } from 'react-router';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';

export function RenderRentersInsuranceDetails(props: IRenderPolicyDetailsProps) {
  const { search } = useLocation();
  const isMonthly = props.qualifiesForMonthlyRentersInsurance
    ? new URLSearchParams(search).get('payment_cadence') === '0' || props.paymentOption === PAYMENT_OPTIONS.Monthly
    : false;
  const isMobile = useCheckMobile();
  const { rentersInsurancePolicy, coverGeniusRentersInsurance } = props;

  const coverageStartDate = rentersInsurancePolicy
    ? rentersInsurancePolicy.coverage_start_date
    : coverGeniusRentersInsurance.coverage_start_date;

  const tomorrow = endOfTomorrow();
  const after = isAfter(new Date(coverageStartDate), tomorrow);
  const revisedCoverageDate = after ? addDays(Date.parse(coverageStartDate), 1) : coverageStartDate;

  const coverageEndDate = rentersInsurancePolicy
    ? rentersInsurancePolicy.coverage_end_date
    : coverGeniusRentersInsurance.coverage_end_date;

  const renderMonthlySelectedCopy = () => {
    if (isMonthly) {
      return (
        <NeedToKnowText>
          Your policy will renew monthly until you cancel or your payment is not received. Note that partial month
          refunds are not available.{' '}
        </NeedToKnowText>
      );
    }
  };

  if (isMobile || props.inAccount) {
    return (
      <>
        <LabelMobile>Coverage amounts</LabelMobile>
        <InfoMobile>Personal property — $15,000</InfoMobile>
        <InfoMobile>Liability — $100,000</InfoMobile>
        <>
          <LabelMobile>Coverage start date</LabelMobile>
          <InfoMobile>{formatLongDate(revisedCoverageDate)}</InfoMobile>{' '}
        </>
        <RenderNeedToKnow
          address={props.address}
          inAccount={props.inAccount}
          hasRentersInsurance={props.hasRentersInsurance}
        />
        {renderMonthlySelectedCopy()}
      </>
    );
  } else {
    return (
      <Row>
        <LabelContainer>
          <LabelDesktop>Coverage amounts</LabelDesktop>
          <br />
          <LabelDesktop>Coverage start date</LabelDesktop>
        </LabelContainer>
        <Col>
          <InfoDesktop>Personal property — $15,000</InfoDesktop>
          <InfoDesktop>Liability — $100,000</InfoDesktop>
          <InfoDesktop>{formatLongDate(revisedCoverageDate)}</InfoDesktop>
          <RenderNeedToKnow
            inAccount={props.inAccount}
            address={props.address}
            hasRentersInsurance={props.hasRentersInsurance}
          />
          {renderMonthlySelectedCopy()}
        </Col>
      </Row>
    );
  }
}
