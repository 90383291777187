import axios from 'axios';
import { csrfToken } from 'utils/document';
import Rollbar from 'rollbar';
import { get, isEmpty, isObject } from 'lodash';
import env from 'utils/env';
import { isPresent } from '../../../../../utils';

const API_NAMESPACE = `/api/v1`;

const getInvoiceFromError = (error) => get(error, ['response', 'data', 'invoice']);

const getInvoiceError = (error) => get(error, ['response', 'data', 'error']);

const DEFAULT_TIMEOUT = 5000;

export interface IAPIResponse {
  message?: string;
  error?: string;
  status?: number | string;
  errors?: { [key: string]: string[] };
}

const DEFAULT_HEADERS = {
  accept: 'application/json',
  'Content-Type': 'application/json'
};

const DEFAULT_OPTIONS = {
  authenticity_token: csrfToken(),
  credentials: 'same-origin',
  headers: DEFAULT_HEADERS
};

const sorryMessage = 'We were unable to process your request. Please try again in a few minutes.';

const isInternalServerError = (err) => err.includes('server error');

const isRequestFailedError = (err) => err.includes('request failed');

const isTimeoutError = (err) => err.includes('timeout') || err.includes('exceedded');

const isVagueAPIError = (err) => {
  if (!isPresent(err)) {
    return false;
  }
  const loweredErr = err.toLowerCase();
  return isInternalServerError(loweredErr) || isRequestFailedError(loweredErr) || isTimeoutError(loweredErr);
};

const converToSorryMessage = (err) => {
  if (isVagueAPIError(err)) {
    return sorryMessage;
  }
  return err;
};

const formatPayNowError = (err: any) => {
  if (!isPresent(err)) {
    return sorryMessage;
  }

  if (typeof err === 'string') {
    return converToSorryMessage(err);
  }

  const invoiceError = getInvoiceError(err);

  if (typeof invoiceError === 'string') {
    return converToSorryMessage(invoiceError);
  }

  if (isObject(err as any)) {
    return converToSorryMessage(err.message || err.error);
  }

  return sorryMessage;
};

const rollbar = new Rollbar({
  accessToken: env('ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN'),
  environment: (window as any).App.production ? 'production' : 'staging'
});

const payUnpaidPolicyApplicationInvoices = async (): Promise<IAPIResponse> => {
  const url = `${API_NAMESPACE}/users/unpaid_policy_application_invoices/pay`;
  return axios.put(url, DEFAULT_OPTIONS);
};

const listInvoices = async (): Promise<any> => {
  return axios.get('/invoices?unpaid=1', { ...DEFAULT_OPTIONS, timeout: DEFAULT_TIMEOUT });
};

const payInvoice = async (invoice: any) => {
  const req = await axios.post(`/invoices/${invoice.id}/pay`, { ...DEFAULT_OPTIONS }, { timeout: DEFAULT_TIMEOUT });
  return req.data || invoice;
};

const getAxiosError = (axiosError) => {
  if (typeof axiosError === 'string') {
    return axiosError;
  }
  if (isObject(axiosError as any)) {
    if (axiosError.response) {
      const responseError = axiosError.response.data?.error;
      const statusText = axiosError.response.statusText;
      return responseError || statusText;
    }
    if (axiosError.request) {
      return axiosError.request.responseText;
    }
    if (axiosError.message) {
      return axiosError.message;
    }
  }
  return axiosError.toString();
};

const payInvoices = async (invoices: any) => {
  const failedInvoices: any = [];
  const successfulInvoices: any = [];
  let standardInvoiceError = '';
  let invoiceId = null;
  for (const invoice of invoices) {
    try {
      invoiceId = invoice.id;
      const currentInvoice = await payInvoice(invoice);
      successfulInvoices.push(currentInvoice);
    } catch (err: any) {
      const failedInvoice = getInvoiceFromError(err);
      const formattedError = formatPayNowError(err);
      const requestError = getAxiosError(err);
      if (isEmpty(standardInvoiceError)) {
        standardInvoiceError = formattedError;
      }
      failedInvoices.push({ ...failedInvoice, error: requestError });
    }
  }
  return { failedInvoices, successfulInvoices, error: standardInvoiceError };
};

const payOpenInvoices = async (invoices: any) => {
  const successfulInvoices: any = [];

  try {
    for (const invoice of invoices) {
      const currentInvoice = await payInvoice(invoice);
      successfulInvoices.push(currentInvoice);
    }
  } catch (err: any) {
    const formattedError = formatPayNowError(err);

    // this keeps backwards compatibility
    // it gets the remaining invoices and
    // return them in a compatible format. i.e. { invoice, error }
    const remainingInvoices = invoices
      .filter((invoice: any) => !successfulInvoices.includes(invoice))
      .map((failedInvoice: any) => ({ ...failedInvoice, error: formattedError }))

    return { successfulInvoices, failedInvoices: remainingInvoices, error: formattedError };
  }

  return { successfulInvoices, failedInvoices: [], error: null };
};

const updateDefaultCard = async ({ zipCode, stripeTokenId }): Promise<IAPIResponse> => {
  const url = `${API_NAMESPACE}/users/payment_methods/update_default_card`;
  return axios.put(url, {
    ...DEFAULT_OPTIONS,
    zip_code: zipCode,
    stripe_token_id: stripeTokenId
  });
};

// Create the SetupIntent and obtain clientSecret
const createSetupIntent = async () => {
  const url = `${API_NAMESPACE}/users/payment_methods/create_setup_intent`;
  const response = await axios.post(url, DEFAULT_OPTIONS);
  return response.data.intent
};

const makeSetupIntentDefaultPaymet = async (setupId) => {
  const url = `${API_NAMESPACE}/users/payment_methods/update_setup_intent_to_default_payment_method`;
  const response = await axios.put(url, {
    ...DEFAULT_OPTIONS,
    setup_id: setupId
  });
 };

export {
  payUnpaidPolicyApplicationInvoices,
  payInvoices,
  payOpenInvoices,
  isVagueAPIError,
  formatPayNowError,
  listInvoices,
  updateDefaultCard,
  createSetupIntent,
  makeSetupIntentDefaultPaymet,
  getAxiosError
};
