import styled from '@emotion/styled';
import { Alert2Icon, PALETTE, FONTS, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import * as React from 'react';

const Header = styled.h3([
  FONTS.h3,
  {
    color: PALETTE.neutralDark,
    fontWeight: 600,
    marginTop: '8px'
  }
]);

const DeclineMessage = styled.p([
  FONTS.p2,
  {
    color: PALETTE.neutralDark,
    fontWeight: 500,
    marginTop: '8px'
  }
]);

const DeclinedContainer = styled.div([
  {
    width: '100%',
    position: 'relative',
    top: '28vh',
    left: 0,
    background: 'white'
  }
]);

const MessageContainer = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center'
  }
]);

const DeclinedButton = styled(ButtonHabitat)([
  {
    '&&': {
      marginTop: '-4px',
      fontSize: '14px',
      padding: '4px, 20px, 4px, 20px',
      height: '32px'
    }
  }
]);

const Declined = () => {
  const faqLink =
    'https://support.sayrhino.com/hc/en-us/articles/360054994392-Getting-a-quote-for-your-Rhino-Security-Deposit-Insurance-policy';
  const handleClick = () => {
    window.location.href = faqLink;
  };

  return (
    <DeclinedContainer>
      <MessageContainer>
        <Alert2Icon color={PALETTE.alert100} height="50px" width="50px" />
        <Header>Not Eligible</Header>
        <DeclineMessage>Unfortunately, you don't meet the requirements for a Rhino policy at this time</DeclineMessage>
        <DeclinedButton id="decline-details" variant="black" usage="secondary" onClick={handleClick}>
          Learn More
        </DeclinedButton>
      </MessageContainer>
    </DeclinedContainer>
  );
};

export default Declined;
