import { flatMap, isArray, isUndefined, defaults as getObjectDefaults } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Error from 'components/common/form/input/Error';

interface IResult<T> {
  result?: T;
  errors?: { [key: string]: string[] };
}

export function pluck<T extends object, K extends keyof T, U extends Pick<T, K>>(obj: T, keys: K[]): U {
  return keys.reduce((m, k) => {
    return Object.assign(m, {[k]: obj[k]});
  }, {} as U);
}

type SubsetState<T> = [T, Dispatch<SetStateAction<T>>];
export function useSubsetState<
  T extends object,
  K extends keyof T
>(
  obj: T | undefined,
  keys: K[],
  defaults?: Partial<Pick<T, K>>,
  overrides?: Partial<Pick<T, K>>
): SubsetState<Pick<T, K>> {
  let initialState = pluck(obj || {} as T, keys);
  if (defaults) {
    initialState = getObjectDefaults(initialState, defaults);
  }
  if (overrides) {
    initialState = {
      ...initialState,
      ...overrides
    };
  }
  return useState(initialState);
}

export function renderErrors(result: IResult<any>): React.ReactNode {
  if (result.errors) {
    const errorTexts = flatMap(result.errors, (v) => v);

    return (
      <div>
        {errorTexts.map((error, i) => (<Error text={error} key={`error-${i}`} />))}
      </div>
    );
  }
}

export function renderError(key: string, result: IResult<any>, fieldName?: string): React.ReactNode {
  if (result.errors) {
    const errorTexts = result.errors[key];
    if (isArray(errorTexts)) {

      return(
        <div>
          {fieldName && <Error text={fieldName} key={0} />}
          { errorTexts.map((error, i) => (<Error text={error} key={i + 1} />)) }
        </div>
      );
    }
  }
}

// coerces undefined into null
export function coerceUndefined(obj: any): any {
  const result = {};

  Object.keys(obj).forEach((k) => {
    const value = obj[k];
    result[k] = isUndefined(value) ? null : value;
  });

  return result;
}
