import React from 'react';
import { Card, PALETTE } from '@sayrhino/rhino-shared-js';
import ChangeRequest from './notification/changeRequest';
import Renewal from './notification/renewal';
import { IPolicyProps } from './policyProps';
import PolicyStatusIndicator from '../../shared/policyStatusIndicator';

const Policy: React.FC<IPolicyProps> = (props: IPolicyProps) => {
  const {
    address_line_one,
    city,
    state,
    zipcode,
    status,
    url,
    pending_change_request,
    eligible_for_renewal,
    time_interval_until_lapse,
    cancellation_request,
    policy_number
  } = props;

  const addressLineTwo = props.address_line_two !== 'N/A' || undefined ? props.address_line_two : null;
  const pendingCancellation = cancellation_request !== null && cancellation_request.status === 'pending';
  const renderRenewalNotice = () => {
    if (eligible_for_renewal) {
      return (
        <div className="row">
          <Renewal timeInterval={time_interval_until_lapse} />
        </div>
      );
    }
    return <></>;
  };

  const renderCoverageRequestNotice = () => {
    if (pending_change_request) {
      return (
        <div className="row no-gutters">
          <ChangeRequest />
        </div>
      );
    }
    return <></>;
  };
  return (
    <div id="dashboard-policy" data-cy="policyCard">
      <Card variant="border" style={cardDetailsStyle}>
        <a href={url}>
          <div style={styles.inner}>
            <div className="row">
              <div className="col-md-12" style={styles.header}>
                Security Deposit Insurance
              </div>
            </div>
            <div className="row" style={styles.row}>
              <div className="col-9 d-md-block" style={styles.addressText}>
                {address_line_one} {city}, {state} {zipcode}
                {addressLineTwo ? `, Unit ${addressLineTwo}` : null}
              </div>
              <div className="col-9 d-none d-md-block" style={styles.policyNumberText}>
                Policy number: {policy_number}
              </div>
              <div className="col-9 d-block d-md-none">
                <div style={styles.policyNumberText}>Policy number: {policy_number}</div>
              </div>
            </div>
            <div style={styles.bottom}>
              <div className="col-lg-5 status-indicator" style={styles.statusIndicator}>
                <PolicyStatusIndicator
                  status={status}
                  pendingCancellation={pendingCancellation}
                  pendingChangeRequest={pending_change_request}
                  className="status-padding"
                  dataCy="policyStatus"
                />
              </div>
              <div style={styles.policyDetails}>Policy Details &gt;</div>
            </div>
            {renderRenewalNotice()}
          </div>
          {renderCoverageRequestNotice()}
        </a>
      </Card>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '576px',
    borderRadius: '8px',
    marginBottom: '32px'
  },
  inner: {
    padding: '32px'
  },
  header: {
    fontFamily: 'MaisonNeueExtendedDemi',
    fontSize: '18px',
    lineHeight: '32px'
  },
  addressText: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    width: 'auto',
    paddingRight: '0px'
  },
  policyNumberText: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '8px'
  },
  unit: {
    textAlign: 'end' as 'end',
    padding: '0 15px 0 0'
  },
  coverageText: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '10px',
    lineHeight: '16px',
    color: `${PALETTE.neutralDark}`,
    marginRight: '10px'
  },
  coverageDatesText: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px'
  },
  coverageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginLeft: '0px',
    paddingLeft: '0px'
  },
  policyDetails: {
    color: PALETTE.interaction100,
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    alignItems: 'right',
    textAlign: 'end' as 'end',
    width: '100%',
    paddingTop: '5px'
  },
  statusIndicator: {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginTop: '0px'
  },
  row: {
    marginRight: '-40px'
  }
};

const cardDetailsStyle = {
  width: 'auto',
  height: 'auto',
  overflow: 'hidden',
  cursor: 'pointer',
  marginBottom: 24,
  border: `1px solid ${PALETTE.neutral12}`,
  '&:hover': {
    boxShadow: `0px 4px 16px 0px ${PALETTE.neutral12}`
  }
};

export default Policy;
