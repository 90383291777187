import React, { useState } from 'react';
import { AlertIcon, RhinoModal } from '@sayrhino/rhino-shared-js';
import RentersInsuranceModal from 'components/v2/renters_insurance/components/RentersInsuranceModal';
import { IRentersInsuranceAddress } from '../interfaces';
import { formatRentersInsuranceAddresses } from '../utils';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';

interface IRentersInsAutoCancelledAlertProps {
  addresses?: IRentersInsuranceAddress[];
  userId?: number;
}

const RentersInsAutoCancelledAlert: React.FC<IRentersInsAutoCancelledAlertProps> = ({
  addresses,
  userId
}: IRentersInsAutoCancelledAlertProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => {
    setModalOpen(true);
    onToggleFullscreenModal(true);
  };

  const closeModal = () => {
    onToggleFullscreenModal(false);
    window.location.reload();
    window.scrollTo({ top: 0 });
    setModalOpen(false);
  };

  return (
    <div style={styles.actionNeeded}>
      <div className="col-1" style={styles.actionNeededIcon}>
        <AlertIcon style={{ color: 'white' }} />
      </div>
      <div data-cy="propertyUpdatedPolicy" className="col-11" style={styles.actionNeededText}>
        Your policy was automatically canceled and refunded because your address was updated.&nbsp;
        <p onClick={onOpenModal} style={styles.newPolicyLink} data-cy="getYourPolicyHere">
          Get your policy here.
        </p>
        <RhinoModal
          isOpen={isModalOpen}
          onRequestClose={() => closeModal()}
          style={{ inset: 0, padding: 0, position: 'fixed' }}
          contentLabel="Renters Insurance Modal"
        >
          <RentersInsuranceModal
            onCloseModal={closeModal}
            addressesForRI={formatRentersInsuranceAddresses(addresses)}
            userId={userId}
          />
        </RhinoModal>
      </div>
    </div>
  );
};

const styles = {
  actionNeeded: {
    background: '#DF2D42',
    borderRadius: '8px',
    margin: '0 20px 20px 20px',
    padding: '24px 0',
    display: 'inline-flex'
  },
  actionNeededText: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    paddingLeft: '25px'
  },
  actionNeededIcon: {
    alignSelf: 'center' as 'center'
  },
  newPolicyLink: {
    display: 'inline',
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    textDecoration: 'underline'
  }
};

export default RentersInsAutoCancelledAlert;
