import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

const Disclaimer = styled.div([FONTS.p3], { color: PALETTE.neutral65, marginBottom: 8 });

const Link = styled.a({
  '&&': {
    textDecoration: 'underline',
    textDecorationColor: PALETTE.neutral65,
    color: PALETTE.neutral65
  }
});

interface IRentersInsuranceLegalProps {
  ins_number: string;
  underwriter: string;
}

export const RentersInsuranceLegalText = (props: IRentersInsuranceLegalProps) => {
  const { ins_number, underwriter } = props;
  return (
    <>
      <Disclaimer>
        The renters insurance (
        <Link href={`https://www.xcover.com/en-us/pds/${ins_number}`} target="_blank">
          view terms
        </Link>
        ) is underwritten by {underwriter}, 4521 Highwoods Parkway, Glen Allen, VA 23060, and is provided by Cover
        Genius Insurance Services, LLC, a licensed broker.&nbsp;
        <Link href="https://www.covergenius.com/insurance-licenses-united-states/" target="_blank">
          License information available here.
        </Link>
        &nbsp;Terms and conditions for rates and coverages vary. Insurance and coverage are subject to availability and
        qualifications and may not be available in all states.
      </Disclaimer>
      <Disclaimer>
        I consent to the electronic delivery to my email address of all current and future insurance-related documents.
        In order to access the electronic transmissions, I understand that I must have a personal computer or electronic
        device with internet connectivity. I understand that I may withdraw my consent to electronic delivery and
        request a paper copy of communications, free of charge, by contacting support@xcover.com with the following
        subject line: "WITHDRAW ELECTRONIC CONSENT."
      </Disclaimer>
      <Disclaimer>
        By purchasing this policy, you agree that the owner of the residence premises may submit third party claims
        against the policy.
      </Disclaimer>
    </>
  );
};
