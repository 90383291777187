import React from 'react';

import styled from '@emotion/styled';
import env from 'utils/env';
import { FONTS, PALETTE, Checkbox } from '@sayrhino/rhino-shared-js';
import { IResult } from '../signup/redux/actions';
import { PolicyApplication } from '../signup/redux/state';
import { RentersInsuranceLegalText } from './renters_insurance_legal_text';
import { US_STATE_ABBREVIATION_TO_NAME } from 'utils/us_states';

export interface IPaths {
  subscriptions_path: string;
  terms_of_service_path: string;
  electronic_transactions_agreement_path: string;
  taa_path: string;
}

export interface PaymentUserAgreementProps {
  paths: IPaths;
  policyApplication: IResult<PolicyApplication>;
  rentersInsuranceSelected?: boolean;
  coverGeniusRentersInsurance?: any;
  setIsUserAgreementAccepted: (value: boolean) => void;
  isUserAgreementAccepted: boolean;
  isUserAgreementError: boolean;
}

const ACCEPT_TERMS_ID = 'accept-payment-terms-checkbox';

const PaymentUserAgreement = (props: PaymentUserAgreementProps) => {
  const addressState = props?.policyApplication?.result?.address_state;
  const qualifiesForMonthlyRentersInsurance = env('MONTHLY_RENTERS_INSURANCE_STATES').includes(addressState);
  const addressStateFull = addressState ? US_STATE_ABBREVIATION_TO_NAME[addressState] : addressState;
  const search = window.location.search;
  const isMonthly = new URLSearchParams(search).get('payment_cadence') === '0';

  const ErrorMeesage = styled.div([
    FONTS.p3,
    {
      color: PALETTE.alert125,
      fontWeight: 500,
      padding: '0',
      margin: '0'
    }
  ]);

  const LegalText = styled.p([FONTS.p3], { color: PALETTE.neutral65, marginBottom: '0' });
  const Link = styled.a({
    '&&': {
      textDecoration: 'underline',
      color: PALETTE.neutral65,
      textDecorationColor: PALETTE.neutral65,
      transitionProperty: 'color, text-decoration-color',
      transitionDuration: '300ms',
      transitionTimingFunction: 'ease',
      '&:visited': {
        color: PALETTE.neutral88,
        textDecorationColor: PALETTE.neutral88
      },
      '&:hover, &:focus, &:active': {
        color: PALETTE.brand88,
        textDecorationColor: PALETTE.brand88
      }
    }
  });

  return (
    <section data-cy="paymentAgreement" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {props.isUserAgreementError ? <ErrorMeesage>Please accept the terms and conditions</ErrorMeesage> : null}
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
        <Checkbox
          label="Accept Terms and Conditions"
          aria-label="Accept Terms and Conditions"
          id={ACCEPT_TERMS_ID}
          checked={props.isUserAgreementAccepted}
          onCheckedChange={() => props.setIsUserAgreementAccepted(!props.isUserAgreementAccepted)}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem !important' }}>
          <LegalText>
            By checking this box, I agree (1) I am enrolling my rental property pursuant to the terms of the{' '}
            <Link href={props.paths.taa_path} id="tenant-agreement" target="_blank">
              Tenant Acknowledgment Agreement
            </Link>{' '}
            (the “Agreement”), including reading and accepting the cancellation and termination provisions contained
            therein; (2) this enrollment will be executed by affixing my electronic signature to the Agreement and this
            signature is the same as handwritten signature for purposes of validity and enforceability; (3) I am
            consenting to the electronic delivery of any information or other communications required by law to be
            provided in writing pursuant to the terms of the{' '}
            <Link href={props.paths.electronic_transactions_agreement_path} id="transaction-agreement" target="_blank">
              Agreement to Conduct Electronic Transactions
            </Link>
            ; and (4) I have read and accepted the{' '}
            <Link href={props.paths.terms_of_service_path} id="terms-of-service" target="_blank">
              Terms of Service and Privacy
            </Link>
            .
          </LegalText>
          <LegalText>
            By clicking the Pay button below, I am authorizing Rhino New York LLC (Rhino Insurance Agency in{' '}
            {addressStateFull}) to charge my card or any other acceptable payment method that I provided for payment(s)
            in accordance with the terms and conditions provided above.
          </LegalText>
          {qualifiesForMonthlyRentersInsurance && isMonthly && props.rentersInsuranceSelected && (
            <LegalText style={{ fontWeight: 'bold' }}>
              *Your first charge will be $1 less than your recurring charge.
            </LegalText>
          )}
          {props.rentersInsuranceSelected && (
            <RentersInsuranceLegalText
              ins_number={props.coverGeniusRentersInsurance.ins_number}
              underwriter={props.coverGeniusRentersInsurance.underwriter}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentUserAgreement;
