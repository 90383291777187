export interface IRentersInsuranceOption {
  label: string;
  value: string;
}

const RentersInsuranceOptions: IRentersInsuranceOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: "I'm not sure", value: 'also false' }
];

export default RentersInsuranceOptions;
