/** @jsx jsx */
import React from 'react';
import { ClassNames, jsx } from '@emotion/react';
import Modal from 'react-modal';
import { CloseIcon, PALETTE } from '@sayrhino/rhino-shared-js';

Modal.setAppElement('#wrapper');
interface Props {
  isOpen: boolean;
  id?: string;
  variant?: 'black' | 'white';
  closeModal: () => void;
  children: JSX.Element | JSX.Element[] | boolean;
}

const CustomModal = ({ isOpen, closeModal, children, id, variant = 'black' }: Props) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Modal
          id={id}
          isOpen={isOpen}
          onRequestClose={() => closeModal()}
          overlayClassName={{
            base: 'overlay-base',
            afterOpen: 'overlay-after',
            beforeClose: 'overlay-before'
          }}
          className={{
            base: 'content-base',
            afterOpen: 'content-after',
            beforeClose: 'content-before'
          }}
          closeTimeoutMS={500}
          portalClassName={css`
            .overlay-base {
              padding: 1rem;
              position: fixed;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              opacity: 0;
              transition-property: background-color, opacity;
              transition-duration: 500ms;
              transition-timing-function: ease-in-out;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99999;
              overflow: auto;
              @media (max-width: 768px) {
                display: block;
                padding: 0px;
              }
            }
            .overlay-after {
              background-color: ${variant === 'black' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
              opacity: 1;
              @media (max-width: 768px) {
                background-color: ${PALETTE.white};
              }
            }
            .overlay-before {
              opacity: 0;
            }
            .content-base {
              position: relative;
              top: auto;
              left: auto;
              right: auto;
              bottom: auto;
              margin: 0 auto;
              border: 0;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100%;
              width: 100%;
              background-color: transparent;
              transition-property: background-color, width, height;
              transition-duration: 500ms;
              transition-timing-function: ease-in-out;
              @media (max-width: 768px) {
                display: block;
              }
            }
            .content-before {
              width: 100%;
              height: 0%;
              background-color: transparent;
            }
          `}
        >
          {children}
        </Modal>
      )}
    </ClassNames>
  );
};

export default CustomModal;
