import { get, isNil } from 'lodash';
import { getQueryParams } from '../../../../../utils/index';

const safeGet = (data, key, defaultValue) => {
  if (isNil(data)) {
    return defaultValue;
  }
  return get(data, key, defaultValue);
};

export const getInvoicesCount = (unpaidInvoicesBreakdown: any): any => {
  return safeGet(unpaidInvoicesBreakdown, 'invoices_count', 0);
};

export const getInvoicesTotal = (unpaidInvoicesBreakdown: any): any => {
  return safeGet(unpaidInvoicesBreakdown, 'total', 0);
};

export const getHumanizedInvoicesTotal = (unpaidInvoicesBreakdown: any): any => {
  return safeGet(unpaidInvoicesBreakdown, 'humanized_invoices_total', 0);
};

export const getInvoices = (unpaidInvoicesBreakdown: any): any => {
  return safeGet(unpaidInvoicesBreakdown, 'invoices', []);
};

const mimicCreditCardMasking = (last4) => {
  const asteriks = `**** **** ****`;
  return `${asteriks} ${last4}`;
};

export const buildCreditCardLabel = (brand, last4) => {
  return `${brand} ${mimicCreditCardMasking(last4)}`;
};

export const slugifyLabel = (str) => {
  const coercedStr = typeof str === 'string' ? str : str.toString();
  const lowerCaseStr = coercedStr.toLowerCase();
  const matchSpaceRegex = /\s/g;
  const matchDashRegex = /-/g;
  return lowerCaseStr.replace(matchSpaceRegex, '_').replace(matchDashRegex, '_');
};

export const showPayNowModalParamPresent = () => {
  const params = getQueryParams();
  return Boolean(params?.show_pay_now_modal);
};

const invoicesEmpty = (initialInvoices, failedInvoices) => {
  return initialInvoices.length === 0 || failedInvoices.length === 0;
};

export const allInvoicesFailed = (initialInvoices, failedInvoices) => {
  if (invoicesEmpty(initialInvoices, failedInvoices)) {
    return false;
  }

  return failedInvoices.length === initialInvoices.length;
};

export const someInvoicesFailed = (initialInvoices, failedInvoices) => {
  if (invoicesEmpty(initialInvoices, failedInvoices)) {
    return false;
  }

  return failedInvoices.length < initialInvoices.length;
};

export const prettyPrintErrors = (errors) => {
  if (typeof errors === 'string') {
    return errors;
  }
  if (Array.isArray(errors)) {
    return errors.join(' ');
  }
  if (errors.constructor() === 'Error') {
    return errors.message;
  }
  const joinedErrorValues = Object.values(errors).join(' ');
  return joinedErrorValues;
};

export const filterUnpaidInvoices = (invoices) => invoices.filter((invoice) => invoice.paid === false);
