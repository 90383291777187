/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { Component } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import env from 'utils/env';
import { post } from 'utils/request';
import styled from '@emotion/styled';

export type IProps = {
  onSuccess: (recaptchaToken: string) => any,
  onError: (recaptchaError: string) => any,
  recaptchaRef: any
};

type IState = {};

const Link = styled.a`
  text-decoration: underline;
  color: ${PALETTE.neutral65};
`;

export default class RecaptchaWrapper extends Component<IProps, IState> {
  verifyRecaptcha = (recaptchaToken) => {
    post(env('VERIFY_RECAPTCHA_PATH'), { recaptchaToken })
      .then((r) => this.props.onSuccess(recaptchaToken))
      .catch((e) => this.props.onError(e.error));
  };

  render(): React$Node {
    return (
      <div className={'mt-4'}>
        <div css={[FONTS.p2Light, { color: PALETTE.neutral65 }]}>
          This site is protected by reCAPTCHA and the Google&nbsp;
          <Link href="https://policies.google.com/privacy" target="_blank">
            Privacy Policy
          </Link>
          &nbsp;and&nbsp;
          <Link href="https://policies.google.com/terms" target="_blank">
            Terms of Service
          </Link>
          &nbsp;apply.
        </div>
        <ReCAPTCHA
          style={{ visibility: 'hidden' }}
          ref={this.props.recaptchaRef}
          size="invisible"
          sitekey={env('RECAPTCHA_PUBLIC_KEY')}
          onChange={this.verifyRecaptcha}
        />
      </div>
    );
  }
}
