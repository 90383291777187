import React, {Component, ReactNode} from 'react';

interface IProps {
  index: number;
  item: string;
  content?: string;
}

class TextItem extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item } = this.props;
    return (
      <div className="item carousel-card" key={index}>
        <p className="carousel-card--header-text">{item}</p>
        <p className="carousel-card--text">{this.props.content}</p>
      </div>
    );
  }
}

export default TextItem;
