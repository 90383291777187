import React, { useState, useEffect, useRef } from 'react';
import { connect, Provider } from 'react-redux';
import { startInvitation } from 'components/v2/signup/redux/actions';
import { IInvitation, IState } from 'components/v2/signup/redux/state';
import store from '../signup/redux/store';

interface IProps {
  startInvitation: (_) => void;
  incompleteApplicationId: number | null;
  invitation: IInvitation | undefined;
  buttonText: string;
  showOnScroll: boolean;
}

const connector = connect((state: IState, props) => Object.assign({ ...props, invitation: state.invitation?.result }), {
  startInvitation
});

const visibleScrollOffset = 480;

const InvitationButton = (props: IProps) => {
  const { incompleteApplicationId, invitation, showOnScroll, buttonText } = props;

  const [visible, setVisible] = useState(!showOnScroll);
  const scrollHandlerSet = useRef(false);
  useEffect(() => {
    if (showOnScroll && scrollHandlerSet.current === false) {
      const handleScroll = () => setVisible(window.pageYOffset > visibleScrollOffset);
      window.addEventListener('scroll', handleScroll);
      scrollHandlerSet.current = true;
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleClick = () => {
    const errors = incompleteApplicationId
      ? {
          redirect: [`/policy_applications/${incompleteApplicationId}/in_progress/`]
        }
      : undefined;
    props.startInvitation({ errors });
  };

  return (
    <a
      id="start-invitation-btn"
      className="btn btn-primary signup-button"
      style={{ display: visible ? 'block' : 'none' }}
      onClick={handleClick}
      data-cy="inviteSignUp"
    >
      {buttonText}
    </a>
  );
};

const WrappedStartInvitationButton = connector(InvitationButton);

const wrappedInvitationButton = (props) => {
  return (
    <Provider store={store}>
      <WrappedStartInvitationButton {...props} />
    </Provider>
  );
};

export default wrappedInvitationButton;
