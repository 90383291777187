import React from 'react';

interface IProps {
  renewal: {
    next_price_cents: number;
    next_price: string;
    upfront_price: string;
    need_card: boolean;
  };
  stripeProps: {
    name: string;
    api_key: string;
  };
}

function ExcessLinesToAdmittedPaymentForm(props: IProps) {
  return (
    <div></div>
  );
}

export default ExcessLinesToAdmittedPaymentForm;
