/** @jsx jsx */
import React, { ChangeEvent, Fragment, useContext, useState } from 'react';
import { FONTS, TextInput, Button as UiButton } from '@sayrhino/rhino-shared-js';
import { jsx, css } from '@emotion/react';
import UiModal from './modal';
import UserContext from '../layout/account/user-context';
import ResetPassword from './reset-password';
import Button from 'components/v2/button';
import { csrfToken } from 'utils/document';
import { put, putRaw } from 'utils/request';
interface IProps {
  user: IUser;
  user_registration_url: string;
  users_account_details_path: string;
}
interface IErrors {
  current_password?: [string];
  email?: string;
}

interface IResetPassword {
  email: string;
  passwordString: string;
  oldPassword: string;
  errors: IErrors;
  success: boolean;
  activeButton: boolean;
}

const AccountInformation = (props: IProps) => {
  const { currentUser, updateUser } = useContext(UserContext);
  const resetPasswordInitState = {
    email: currentUser.email,
    passwordString: '',
    oldPassword: '',
    errors: {},
    success: false,
    activeButton: false
  };
  const [resetPassword, setResetPassword] = useState<IResetPassword>(resetPasswordInitState);
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [btnName, setBtnName] = useState<string>('Save');
  const { errors, email, oldPassword, passwordString } = resetPassword;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState(false);

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    setIsOpen(false);
    setResetPassword(resetPasswordInitState);
    setShowPasswordUpdate(false);
    setIsComplete(false);
  };

  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setResetPassword({ ...resetPassword, [key]: event.target.value });
  };

  const resetPasswordChange = (value: string, key: string) => {
    setResetPassword({ ...resetPassword, [key]: value });
  };

  const handleConfirmationUpdate = () => {
    setResetPassword({ ...resetPassword, errors: {} });
  };

  const handleSubmit = async () => {
    const authenticity_token = csrfToken();
    handleConfirmationUpdate();
    if (oldPassword && passwordString) {
      passwordUpdate(authenticity_token);
    } else {
      emailUpdate(authenticity_token);
    }
  };

  const emailUpdate = (authenticity_token) => {
    const user = {
      ...currentUser,
      email
    };
    put(props.users_account_details_path, {
      authenticity_token,
      user
    })
      .then((response) => {
        completedUpdate();
      })
      .catch((response: any) => {
        setIsLoading(false);
        const updateError = response.errors;
        setResetPassword({ ...resetPassword, errors: updateError });
      });
  };

  const passwordUpdate = (authenticity_token) => {
    const user = {
      current_password: oldPassword,
      password: passwordString,
      password_confirmation: passwordString
    };
    setIsLoading(true);
    putRaw(props.user_registration_url, {
      user,
      authenticity_token
    })
      .then((response) => {
        completedUpdate();
      })
      .catch((response: any) => {
        setIsLoading(false);
        response
          .json()
          .then((resp) => {
            const err: IErrors = {
              current_password: resp.errors || [resp.error]
            };
            setResetPassword({ ...resetPassword, errors: err });
          })
          .catch(() => {
            const catchErr: IErrors = {
              current_password: ['Something went wrong']
            };
            setResetPassword({ ...resetPassword, errors: catchErr });
          });
      });
  };

  const completedUpdate = () => {
    setIsLoading(false);
    updateUser({ ...currentUser, email: resetPassword.email });

    setBtnName('Saved');
    setIsComplete(true);
    setTimeout(() => {
      setResetPassword(resetPasswordInitState);
      setBtnName('Save');
    }, 3000);
  };

  const resetEmailCheck = () => {
    return email && !showPasswordUpdate && !isLoading;
  };

  const resetEmailAndPasswordCheck = () => {
    return email && showPasswordUpdate && oldPassword && passwordString && checkPassword && !isLoading;
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col" css={FONTS.h5}>
          Account information
        </div>
        <div className="col-auto" css={FONTS.p2Light}>
          <span onClick={() => openModal()} className="edit-info">
            Edit
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6 col-12 mb-md-3" css={FONTS.p2Medium}>
              Email
            </div>
            <div className="col-md-6 col-12 mb-3" css={FONTS.p1Light}>
              {currentUser.email}
            </div>
            <div className="col-md-6 col-12 mb-md-3" css={FONTS.p2Medium}>
              Password
            </div>
            <div className="col-md-6 col-12 mb-3" css={FONTS.p1Light}>
              ********
            </div>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <UiModal closeModal={closeModal} isOpen={isOpen}>
        {!isComplete ? (
          <Fragment>
            <h3 css={[FONTS.h3]}>Edit account information</h3>
            <TextInput
              id="email"
              label="Email"
              value={email}
              error={errors.email?.length! > 0}
              onChange={(event) => handleChange(event, 'email')}
              subtext={errors.email}
            />
            {!showPasswordUpdate ? (
              <div className="form-group">
                <label>Password</label>
                <div className="row">
                  <div className="col">********</div>
                  <div className="col-auto">
                    <span
                      className="edit-info cursor mt-3"
                      css={FONTS.p2Light}
                      onClick={() => setShowPasswordUpdate(true)}
                    >
                      Update Password
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <h5 css={FONTS.h5}>Update password</h5>
                <TextInput
                  id="oldPassword"
                  label="Old password"
                  type="password"
                  error={errors.current_password?.length! > 0}
                  onChange={(event) => handleChange(event, 'oldPassword')}
                  subtext={errors.current_password}
                />
                <ResetPassword
                  resource="user"
                  onChange={(e) => resetPasswordChange(e, 'passwordString')}
                  onCheckPassword={setCheckPassword}
                  onConfirmationChange={handleConfirmationUpdate}
                  field_name={'new_password'}
                  dataCy="newPassword"
                  dataCy2="confirmPassword"
                />
              </div>
            )}
            <Button
              text={btnName}
              className="mt-4"
              disabled={isLoading}
              styles={resetEmailCheck() || resetEmailAndPasswordCheck() ? styles.activeButton : styles.button}
              onClick={handleSubmit}
              id="update-password-btn"
              dataCy="update"
            />
          </Fragment>
        ) : (
          <div
            css={css`
              @media (max-width: 768px) {
                height: 75vh;
                display: grid;
                align-items: center;
              }
            `}
          >
            <div>
              <h3 css={[FONTS.h3]} className="text-center">
                Your info’s been updated!
              </h3>
              <UiButton children="Ok, got it" className="mt-4 mx-auto" onClick={closeModal} />
            </div>
          </div>
        )}
      </UiModal>
    </Fragment>
  );
};

export default AccountInformation;

const styles = {
  button: {
    border: '1px solid #E3E3E3',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black',
    padding: '0px 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'not-allowed',
    width: '100%'
  },
  activeButton: {
    border: 'none',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'white',
    padding: '0 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    backgroundColor: '#6318CE',
    cursor: 'pointer',
    width: '100%'
  },
  error: {
    color: 'red'
  }
};
