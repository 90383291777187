import { useMutation } from 'react-query';
import axios from 'axios';
import { csrfToken } from 'utils/document';

export interface IDeposit {
  userId?: number | undefined;
  policyApplicationId?: number;
  signup_step?: boolean;
}

const authenticity_token = csrfToken();
const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': authenticity_token
};

const FetchDepositFees = async ({ userId, policyApplicationId }: IDeposit) => {
  const { data } = await axios.get(
    `/users/${userId}/policy_applications/${policyApplicationId}/cash_deposit_fees.json`,
    {
      headers
    }
  );

  return data;
};

export const useCashDepositFees = () => {
  const { mutate: getCashDepositFees, ...rest } = useMutation(FetchDepositFees);
  return {
    getCashDepositFees,
    ...rest
  };
};
