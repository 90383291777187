import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { FONTS, Button, PALETTE, ArrowIcon } from '@sayrhino/rhino-shared-js';
import { formatToLocaleDate } from '../../../common/form/date-picker/utils';
import Map, { IProps as IMapProps } from 'components/v2/signup/map_with_overlay/map';
import { formatMoney } from 'utils/money';
import { Steps } from './RentersInsuranceModal';
import { BackButton } from './SharedStyles';

export interface IProps {
  map: IMapProps;
  address: {
    street_address: string;
    city: string;
    state: string;
    zip: string;
    unit: string;
  };
}

const Header = styled.h2([FONTS.h2], {
  lineHeight: '150%',
  letterSpacing: '0.56px',
  marginBottom: '8px',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    fontSize: '24px'
  }
});
const Label = styled.p([FONTS.p2Light, { color: PALETTE.neutral65, marginBottom: 0 }]);
const Info = styled.p([FONTS.p1]);
const FillerText = styled.div([FONTS.labelLight, { color: PALETTE.neutral65 }]);
const RIMonthly = styled.div([FONTS.labelLight, { color: PALETTE.neutral65 }]);
const PolicyDetails = styled.div([{ marginTop: '23px' }]);
const AddPayment = styled(Button)([{ '&&': { background: PALETTE.brand100 } }]);

const Wrapper = styled.div([
  {
    '@media (min-width: 768px)': {
      margin: '0 auto',
      width: '480px'
    }
  }
]);

const MapSection = styled.div([
  {
    height: '86px',
    border: '1px solid',
    width: '100%',
    borderColor: PALETTE.neutral12,
    borderRadius: '8px',
    backgroundColor: 'lightgrey',
    overflow: 'hidden'
  }
]);

const MapContainer = styled.div([
  {
    height: 'auto',
    width: 'auto',
    margin: '-325px 0 0 0px',
    '@media (max-width: 520px)': {
      margin: '-275px 0 0 0px'
    }
  }
]);

const ActionButtons = styled.div([
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: '35px'
  }
]);

interface IReviewPolicyProps {
  rentersInsurancePolicy: any;
  goToStep: (modalStep: string) => void;
  selectedPolicyApp: any;
  inAccount: boolean;
  map: {};
  address: {};
  coverage: {};
}

const ReviewPolicy = (props: IReviewPolicyProps) => {
  const { rentersInsurancePolicy, goToStep, selectedPolicyApp } = props;

  const mapAttrs = {
    className: 'card-img rounded d-lg-block card-img-map',
    alt: `Map of ${selectedPolicyApp.map_props.map.location}`
  };

  const leaseStartDate = formatToLocaleDate(selectedPolicyApp.lease_start_date);
  const leaseEndDate = formatToLocaleDate(selectedPolicyApp.lease_end_date);

  return (
    <Wrapper>
      <Header data-cy="reviewDetailsHeader">Review policy details</Header>

      <MapSection>
        <MapContainer role="img" aria-label={`map of ${selectedPolicyApp.label}`}>
          <Map.Component {...selectedPolicyApp.map_props.map} attributes={mapAttrs} />
        </MapContainer>
      </MapSection>

      <PolicyDetails>
        <Label>Policy address</Label>
        <Info>{selectedPolicyApp.label}</Info>

        <Label>Lease term</Label>
        <Info>
          {leaseStartDate} – {leaseEndDate}
        </Info>

        <Label>Coverage amounts</Label>
        <Info style={{ marginBottom: 0 }}>Personal property — $15,000</Info>
        <Info>Liability — $100,000</Info>

        <Label>Policy term</Label>
        <Info>
          12 months ({formatToLocaleDate(rentersInsurancePolicy.coverage_start_date)} –{' '}
          {formatToLocaleDate(rentersInsurancePolicy.coverage_end_date)})
        </Info>
      </PolicyDetails>

      <FillerText>
        Renters insurance provides coverage for specific perils (e.g. theft or fires) and liability coverage for
        lawsuits caused by accidents in your home. It also covers theft and damage for your belongings, which are
        covered even when you’re outside your home. Please review your policy documents for any applicable coverage
        limits, sub-limits, or exclusions.
      </FillerText>

      <RIMonthly>Your policy will renew monthly until you cancel or your payment is not received.</RIMonthly>

      <ActionButtons>
        <BackButton variant="white" onClick={() => goToStep(Steps.QUOTE)} data-cy="back">
          Back
        </BackButton>
        <AddPayment onClick={() => goToStep(Steps.CHECKOUT)} data-cy="paymentInfo">
          Add payment info
          <ArrowIcon style={{ paddingLeft: 10 }} />
        </AddPayment>
      </ActionButtons>
    </Wrapper>
  );
};

export default ReviewPolicy;
