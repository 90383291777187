import React from 'react';
import { connect, Provider as ReduxProvider } from 'react-redux';
import { openPolicyApplication } from 'components/v2/signup/redux/actions';
import store from 'components/v2/signup/redux/store';
import ActionCenter from './components/ActionCenter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { IActionCenterProps } from './hooks/useActionCenter';

const queryClient = new QueryClient();
const connector = connect((state, props) => ({ ...state, ...props }), { openPolicyApplication });
const WrappedActionCenter = connector(ActionCenter);

const ActionCenterWrapper = (props: IActionCenterProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <WrappedActionCenter {...props} />
        </BrowserRouter>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

export default ActionCenterWrapper;
