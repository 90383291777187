import React, { SyntheticEvent } from 'react';
import { allPresent, BackButton, useSubsetState, doesExist } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { FONTS, Select } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { NumberOptions, PetOptions, IRadioOption } from '../constants/household_options';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import RadioForm from 'components/common/radio_form';

const QuestionContent = styled.div([FONTS.p1Light]);
const QuestionsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column'
});
const InputContainer = styled.div({
  paddingTop: '15px'
});
const QuestionGroup = styled.div({
  marginTop: '40px',
  '@media(max-width: 991px)': {
    marginBottom: '200px'
  }
});

function Pets(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const policyApplicationId = props.policyApplication.result?.id;
  const userId = props.user.result?.id;

  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, ['total_pets']);
  const [pets, setPets] = React.useState<any>(doesExist(policyApplication.total_pets));

  const updatePetOption = (newValue) => {
    const value = newValue.pet_option === 'true';
    if (!value) updatePolicyApplication({ total_pets: 0 });
    setPets(value);
  };

  const updateTotalPets = (newValue) => {
    updatePolicyApplication({
      total_pets: newValue?.value
    });
  };

  const totalOption = (value) => {
    return NumberOptions.find((option) => option.value === value);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (policyApplicationId && userId && policyApplication) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const verifyInputs = () => {
    if ((pets && !doesExist(policyApplication.total_pets)) || (!pets && !allPresent([policyApplication.total_pets]))) {
      return false;
    }
    return true;
  };

  const disabled = () => {
    return !verifyInputs();
  };

  return (
    <div>
      <h2 className="bold header" data-cy="householdHeader">
        Will any pets be living in your home?
      </h2>
      <form className="sign-up__form" id="pet-form" onSubmit={onSubmit}>
        <QuestionsContainer>
          <div>
            <InputContainer className="input-holder">
              <RadioForm
                field="total_pets"
                options={PetOptions}
                selected={`${pets}`}
                hideActions={true}
                onChange={(pet_option: IRadioOption) => updatePetOption({ pet_option })}
                holderClass=""
              />
            </InputContainer>
          </div>
          <QuestionGroup id="pets-clarifying-question">
            {pets && (
              <div>
                <QuestionContent>How many?</QuestionContent>
                <div>
                  <Select
                    options={NumberOptions}
                    id="total-pets-select"
                    value={totalOption(policyApplication.total_pets)}
                    onChange={(newValue) => updateTotalPets(newValue)}
                    placeholder="Select number"
                  />
                </div>
              </div>
            )}
          </QuestionGroup>
          <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <BackButton step={props.step} />
            <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled()} />
          </div>
        </QuestionsContainer>
      </form>
    </div>
  );
}

export default Pets;
