import axios, { AxiosResponse } from 'axios';
import { IConfirmRentersInsuranceParams } from '../interfaces';
import { useMutation, MutationFunction } from 'react-query';

const confirmCoverGeniusPolicyDetails: MutationFunction<AxiosResponse, IConfirmRentersInsuranceParams> = async (
  params: IConfirmRentersInsuranceParams
): Promise<AxiosResponse> => {
  return axios.put(`/cover_genius_policy_details/${params.ins_number}/confirm`, params);
};

export default function useConfirmCoverGeniusPolicyDetailsMutation() {
  return useMutation(confirmCoverGeniusPolicyDetails);
}
