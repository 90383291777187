import React, { Component, ReactNode } from 'react';
import styled from '@emotion/styled';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 432px;
  height: 340px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 0px;
  text-align: left;
  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1;

  background-image: asset-url('v3_landing_page/five-stars.svg');
  background-repeat: no-repeat;
  background-position: 282px 43px;
  padding: 40px;

  @media (max-width: 750px) {
    width: 350px;
    background-position: 202px 43px !important;
  }
`;

const Padding = styled.div({
  paddingRight: '50px'
});

const UserIcon = styled.img`
  height: 48px;
  width: 48px !important;
  border-radius: 24px;
`;

interface IProps {
  index: number;
  item: string;
  footers: string[];
  images: string[];
}

const QuoteItem: React.FC<IProps> = ({ index, item, footers, images }) => {
  return (
    <Padding>
      <Card key={index} className="rating-bg">
        <p style={styles.title}>{item}</p>
        <p style={styles.text}>{footers[index]}</p>
        <UserIcon src={images[index]} alt="google-user-icon" />
      </Card>
    </Padding>
  );
};

const styles = {
  text: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black',
    marginTop: '16px'
  },
  title: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '18px',
    lineHeight: '32px',
    color: 'black',
    marginTop: '-10px'
  },
  whiteCard: {
    backgroundColor: 'white',
    backgroundImage: 'none'
  },
  link: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    marginTop: '35px',
    position: 'absolute',
    paddingRight: '30px'
  }
};

export default QuoteItem;
