/** @jsx jsx */
import React from 'react';
import { ClassNames, jsx } from '@emotion/react';
import Modal from 'react-modal';
import { CloseIcon } from '@sayrhino/rhino-shared-js';

Modal.setAppElement('#wrapper');
interface Props {
  isOpen: boolean;
  id?: string;
  closeModal: () => void;
  children: JSX.Element | JSX.Element[] | boolean;
  styleOptions?: {};
}

const UiModal = ({ isOpen, closeModal, children, id, styleOptions }: Props) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Modal
          id={id}
          isOpen={isOpen}
          onRequestClose={() => closeModal()}
          overlayClassName={{
            base: 'overlay-base',
            afterOpen: 'overlay-after',
            beforeClose: 'overlay-before'
          }}
          className={{
            base: 'content-base',
            afterOpen: 'content-after',
            beforeClose: 'content-before'
          }}
          closeTimeoutMS={500}
          portalClassName={css`
            .overlay-base {
              padding: 1rem;
              position: fixed;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0);
              opacity: 0;
              transition-property: background-color, opacity;
              transition-duration: 500ms;
              transition-timing-function: ease-in-out;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99999;
              overflow: auto;
              @media (max-width: 768px) {
                display: block;
                padding: 0px;
              }
            }

            .overlay-after {
              background-color: rgba(0, 0, 0, 0.8);
              opacity: 1;
              @media (max-width: 768px) {
                background-color: #fff;
              }
            }

            .overlay-before {
              background-color: rgba(0, 0, 0, 0);
              opacity: 0;
            }

            .content-base {
              position: relative;
              top: auto;
              left: auto;
              right: auto;
              bottom: auto;
              margin: 0 auto;
              border: 0;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100%;
              width: 100%;
              background-color: transparent;
              transition-property: background-color, width, height;
              transition-duration: 500ms;
              transition-timing-function: ease-in-out;
              @media (max-width: 768px) {
                display: block;
              }
            }

            .content-before {
              width: 100%;
              height: 0%;
              background-color: transparent;
            }
          `}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-9 col-12">
                <div className="card border-0">
                  <div className="card-body m-md-4" style={styleOptions}>
                    <div className="pull-right">
                      <CloseIcon onClick={() => closeModal()} className="cursor" data-cy="modalCancel" />
                    </div>
                    <div className="mt-md-4 mt-5"></div>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </ClassNames>
  );
};

export default UiModal;
