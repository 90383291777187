import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IUser } from '../interfaces';

const getUser = async (id: number) => {
  const { data }: AxiosResponse = await axios.get<IUser>(`/users/${id}`);
  return data.user;
};

export default function useGetUser(id: number): QueryObserverResult<IUser> {
  return useQuery<IUser>(['user', id], () => getUser(id));
}
