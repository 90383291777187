import React from 'react';
import { isPresent } from '../../utils';

interface DisplayWhenProps {
  predicate: boolean | (() => boolean);
  children?: React.ReactNode;
  render?: (() => React.ReactNode) | React.ReactNode;
  component?: React.ReactNode;
}

type ReactNodeOrReactFun = React.ReactNode | (() => React.ReactNode);

const isFunction = (data) => typeof data === 'function';

const invokeWhenFunc = (func) => (isFunction(func) ? func() : func);

const renderComponentOrCallFunc = (componentOrFunc: ReactNodeOrReactFun) => {
  if (!isPresent(componentOrFunc)) {
    return null;
  }
  const res = invokeWhenFunc(componentOrFunc);
  return res;
};

const DisplayWhen: React.FC<DisplayWhenProps> = ({ children, component, render, predicate }) => {
  const dataToRender = component || render || children;
  const shouldRender = invokeWhenFunc(predicate);

  if (shouldRender) {
    return renderComponentOrCallFunc(dataToRender);
  }

  return null;
};

export default DisplayWhen;
