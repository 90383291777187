import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { put } from 'utils/request';
import Drawer from 'components/v2/drawer';
import Flash from 'components/v2/flash';

import PaymentOptions from './paymentOptions';
import PendingChangeRequest from './pendingChangeRequest';
import { IPolicy, IChangeRequest, ICallbacks } from '../policyProps';
import { updatePolicy, updateChangeRequest, cachePolicy, updateCanChangePaymentMethod } from '../actions';
import getFeatureFlags from 'utils/getFeatureFlags';
interface IProps {
  insurancePolicy: IPolicy;
  changeRequest: IChangeRequest;
  canChangePaymentMethod: boolean;
  updatePolicy: (_policyParams) => void;
  cachePolicy: (_policyParams) => void;
  updateCanChangePaymentMethod: (_value) => void;
  updateChangeRequest: (_changeRequestParams) => void;
  acceptChangeRequest: (_hideDrawer: boolean, _callbacks: ICallbacks) => void;
}

interface IState {
  isDirty: boolean;
}

class UpdatePaymentMethod extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { isDirty: false };
    this.initializeCable();
    this.changesPresent = this.changesPresent.bind(this)
  }

  initializeCable = () => {
    (window as any).App.cable.subscriptions.create(
      {
        channel: 'PaymentMethodChannel',
        id: this.props.insurancePolicy.id
      },
      {
        received: ({ success, error }) => {
          if (success) {
            this.onCableSuccess();
          } else {
            this.onCableError();
          }
        }
      }
    );
  };

  showPaymentDrawer() {
    const { isDirty } = this.state;
    const { canChangePaymentMethod } = this.props;
    const upfront = this.getUpfront();
    const featureFlags = getFeatureFlags();
    const preventUpfrontToMonthlyChange = featureFlags?.preventUpfrontToMonthlyCadenceChange || false;
    const changeToMonthlyDisabled = upfront && preventUpfrontToMonthlyChange;
    const changesPresent = this.changesPresent();

    if (changesPresent && isDirty) {
      return true;
    }
    
    if (changeToMonthlyDisabled) {
      return false;
    }

    if (!canChangePaymentMethod) {
      return false;
    }

    return true;
  }

  public render() {
    const showPaymentDrawer = this.showPaymentDrawer();

    if (showPaymentDrawer) {
      return (
        <Drawer
          dataCy="changePayment"
          ctaText="Change Payment Frequency"
          ctaId="change-payment-method-btn"
          buttonBackground={PALETTE.brand4}
          confirmExit={this.state.isDirty && this.changesPresent()}
          onClose={this.onClose}
          renderContent={this.renderContent}
        ></Drawer>
      );
    }
    return null;
  }

  private getUpfront = () => this.props.insurancePolicy?.upfront_policy_application;

  private getCachedUpfront = () => this.props.insurancePolicy?.cache?.upfront_policy_application || false;

  private onCableSuccess = () => {
    const { insurancePolicy } = this.props;
    this.update(this.getUpfront());
    this.props.updateCanChangePaymentMethod(true);
    this.emitCableSuccessMessage();
    this.props.cachePolicy(insurancePolicy);
  };

  private onCableError = () => {
    this.rollbackChanges();
    this.emitCableFailureMessage();
  };

  private renderPendingChangeRequest = (): JSX.Element => {
    const { insurancePolicy, changeRequest } = this.props;
    return (
      <PendingChangeRequest
        policy={insurancePolicy}
        changeRequest={changeRequest}
        onConfirm={this.onConfirmChangeRequest}
      />
    );
  };

  private renderContent = (): JSX.Element => {
    const { insurancePolicy } = this.props;
    if (insurancePolicy.pending_change_request) {
      return this.renderPendingChangeRequest();
    }
    return this.renderPaymentOptions();
  };

  private renderPaymentOptions = (): JSX.Element => {
    return <PaymentOptions upfront={this.getUpfront()} onChange={this.onChange} onSave={this.onSave} />;
  };

  private onConfirmChangeRequest = (callbacks: ICallbacks) => {
    this.props.acceptChangeRequest(false, callbacks);
  };

  private onChange = (value: boolean) => {
    this.updatePolicy(value);
    this.setState({...this.state, isDirty: true});
  };

  private onClose = () => {
    const { isDirty } = this.state;
    if (this.changesPresent() && isDirty) {
      this.rollbackChanges();
    }
    this.setState({ isDirty: false })
  };

  private updatePaymentMethod = async () => {
    const {
      insurancePolicy: { id }
    } = this.props;
    const url = `/insurance_policies/${id}/update_payment_method`;
    return put(url, { upfront: this.getUpfront() });
  };

  private changesPresent = () => {
    const upfront = this.getUpfront();
    const cachedUpfront = this.getCachedUpfront();
    
    return upfront !== cachedUpfront
  }

  private onSave = async () => {
    const changesPresent = this.changesPresent();
  
    if (!changesPresent) {
      return this.closeDrawer();
    }

    const upfront = this.getUpfront();

    try {
      await this.setState({ ...this.state, isDirty: false })
      await this.update(upfront);
      await this.updatePaymentMethod();
      this.emitRequestSuccessMessage();
    } catch (err) {
      await this.rollbackChanges()
      this.emitRequestErrorMessage();
    }
  };

  private closeDrawer = () => {
    Drawer.events.emit('drawer:close');
  };

  private emitRequestSuccessMessage = () => {
    const successMessage = 'Your request is being processed';
    Flash.events.emit('flash:new', { text: successMessage });
  };

  private emitRequestErrorMessage = () => {
    const errorMessage = "You can't change your payment method";
    Flash.events.emit('flash:new', { text: errorMessage, type: 'danger' });
  };

  private emitCableSuccessMessage = () => {
    const successMessage = 'Your payment method was succesfully changed';
    Flash.events.emit('flash:new', { text: successMessage });
  };

  private emitCableFailureMessage = () => {
    const errorMessage = 'Something went wrong, your payment method can not be changed';
    Flash.events.emit('flash:new', { text: errorMessage, type: 'danger' });
  };

  private rollbackChanges = () => {
    const cachedUpfront = this.getCachedUpfront();
    this.updatePolicy(cachedUpfront);
    this.updateChangeRequest(cachedUpfront);
  };

  private updatePolicy = (upfront: boolean) => {
    const { insurancePolicy } = this.props;
    const { price_info } = insurancePolicy;
    const payment_key = upfront ? 'upfront' : 'monthly';
    const payment_amount = price_info[payment_key];
    const updatedPolicy = { upfront, payment_amount, upfront_policy_application: upfront };
    this.props.updatePolicy(updatedPolicy);
  };

  private updateChangeRequest = (upfront: boolean) => {
    const {
      changeRequest: { price_info: request_price_info = {} }
    } = this.props;
    const payment_key = upfront ? 'upfront' : 'monthly';
    const request_payment_amount = request_price_info[payment_key];
    this.props.updateChangeRequest({ payment_amount: request_payment_amount });
  };

  private update = (upfront: boolean) => {
    this.closeDrawer();
    this.updatePolicy(upfront);
    this.updateChangeRequest(upfront);
  };
}

const mapStateToProps = ({ changeRequest, insurancePolicy, canChangePaymentMethod }) => ({
  changeRequest,
  insurancePolicy,
  canChangePaymentMethod
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatePolicy: (policyParams) => dispatch(updatePolicy(policyParams)),
    cachePolicy: (policyParams) => dispatch(cachePolicy(policyParams)),
    updateCanChangePaymentMethod: (value) => dispatch(updateCanChangePaymentMethod(value)),
    updateChangeRequest: (changeRequestParams) => dispatch(updateChangeRequest(changeRequestParams))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethod);
