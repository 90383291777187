import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { IFinalCtaModuleFields } from '../../../../../@types/generated/contentful';
import { FinalCtaSignupForm } from './forms/FinalCtaSignupForm';
import { Spacer120px100px52px, Spacer36, Spacer60pxDesktop12pxMobile } from './Spacers';

const HeaderMessage = styled.div([
  FONTS.h2,
  {
    textAlign: 'center'
  }
]);

const FormContainer = styled.div`
  width: 50%;
  max-width: 768px;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

export const FinalCtaModule: React.FC<IFinalCtaModuleFields> = (props) => {
  const { ctaButtonText, sectionTitle } = props;
  return (
    <div>
      <Spacer120px100px52px />
      <div className="container">
        <div className="row align-items-center" id="become-rhino-partner">
          <div className="col d-flex flex-column align-items-center">
            <HeaderMessage>{sectionTitle}</HeaderMessage>
            <Spacer36 />
            <FormContainer>
              <FinalCtaSignupForm ctaButtonText={ctaButtonText} />
            </FormContainer>
          </div>
        </div>
      </div>
      <Spacer60pxDesktop12pxMobile />
    </div>
  );
};
