import React, { useState, useEffect } from 'react';
import { RhinoModal } from '@sayrhino/rhino-shared-js';
import { IRentersInsuranceAddress } from '../interfaces';
import RentersInsuranceModal from './RentersInsuranceModal';
import { IResult } from '../../signup/redux/actions';
import { PolicyApplication } from '../../signup/redux/state';
import { formatRentersInsuranceAddresses } from '../utils';
import { useHistory, useLocation } from 'react-router-dom';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';
import { EligibleForRICard } from 'components/v2/action_center/components/EligibleForRentersInsurance';

interface IRentersInsuranceBannerProps {
  userId: number;
  firstName: string;
  insuranceId?: number;
  policyApp: IResult<PolicyApplication>;
  addressesForRI: IRentersInsuranceAddress[];
}

const RentersInsuranceBanner: React.FC<IRentersInsuranceBannerProps> = (props: IRentersInsuranceBannerProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const rentersInsurancePath = pathname.includes('/renters_insurance');

  const onOpenModal = () => {
    rentersInsurancePath ? null : history.push('/users/edit/renters_insurance');
    setModalOpen(true);
    onToggleFullscreenModal(true);
  };

  const closeModal = () => {
    history.push('/users/edit');
    onToggleFullscreenModal(false);
    window.location.reload();
    window.scrollTo({ top: 0 });
    setModalOpen(false);
  };

  useEffect(() => {
    if (rentersInsurancePath) {
      onOpenModal();
    }
  }, []);

  return (
    <>
      <EligibleForRICard eligibleAddress={props.addressesForRI[0]} handleClick={onOpenModal} containerAs="div" />
      <RhinoModal
        isOpen={isModalOpen}
        onRequestClose={() => closeModal()}
        style={{ inset: 0, padding: 0, position: 'fixed' }}
        contentLabel="Renters Insurance Modal"
      >
        <RentersInsuranceModal
          onCloseModal={closeModal}
          userId={props.userId}
          firstName={props.firstName}
          addressesForRI={formatRentersInsuranceAddresses(props.addressesForRI)}
          policyApp={props.policyApp}
        />
      </RhinoModal>
    </>
  );
};

export default RentersInsuranceBanner;
