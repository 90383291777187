import React from 'react';

const AttributeBody = ({ children, ...props }) => (
  <div className="accounts-module-attribute--body" id={props.id}>
    <p>
      {children}
    </p>
  </div>
);

export default AttributeBody;
