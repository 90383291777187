import React, { PureComponent } from 'react';
import FormGroup from '../form-group';
import Label from '../label';
import ErrorText from '../error-text';
import CustomSelect from '../custom-select';

export type IOption = { value: any; label: string };

type IProps = {
  options: IOption[];
  resource: string;
  field: string;
  className?: string;
  placeholder?: string;
  label?: string;
  value: string | number;
  defaultValue?: any;
  error: string;
  wrapperClassName?: string;
  clearable?: boolean;
  searchable?: boolean;
  onChange?: (e: any) => any;
  disabled?: boolean;
};

// TODO Remove any. too many overloads of previously incorrect types to correct in this pr
class Select extends PureComponent<IProps & any> {
  static defaultProps = {
    className: '',
    error: '',
    clearable: false,
    searchable: false
  };

  placeholder = (): React.ReactElement | void => {
    const { placeholder } = this.props;

    if (placeholder) {
      return (
        <option value="1" disabled>
          {placeholder}
        </option>
      );
    }
  };

  select = (): React.ReactElement => {
    const { error, wrapperClassName, ...selectProps } = this.props;

    return <CustomSelect {...selectProps} />;
  };

  render() {
    const { label, resource, field, error, wrapperClassName } = this.props;

    return (
      <FormGroup className={wrapperClassName}>
        {error ? <ErrorText text={error} /> : null}
        {label && <Label target={`react_select_${resource}_${field}`} title={label} />}
        {this.select()}
      </FormGroup>
    );
  }
}

export default Select;
