import axios, { AxiosError } from 'axios';
import useConfirmRentersInsurance from 'components/v2/renters_insurance/hooks/useConfirmRentersInsurance';
import { useMutation, MutationFunction, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { WrappedPolicyApplication } from '../interfaces';
import { addCsrf, IPaymentProps, IResult, payAndSubscribeSync } from '../redux/actions';
import { PolicyApplication } from '../redux/state';
import { getPayCadence, getRiPayCadence } from 'components/v2/renters_insurance/utils';
import { RhinoLogger } from 'components/v2/shared/rhino_logger';
export interface IPaymentInfo {
  userId: number;
  policyApplicationId: number;
  paymentProps: IPaymentProps;
}

export const payAndSubscribe: MutationFunction<PolicyApplication, IPaymentInfo> = async (payload: IPaymentInfo) => {
  const url = `/users/${payload.userId}/policy_applications/${payload.policyApplicationId}/complete.json`;
  RhinoLogger.info(`policy_application_id=${payload.policyApplicationId}: completing payment`);
  const result = await axios.post<WrappedPolicyApplication>(url, addCsrf({ payment_info: payload.paymentProps }));

  return result.data.policy_application;
};

export function usePayAndSubscribe(
  currentStep: string
): UseMutationResult<PolicyApplication, AxiosError<IResult<PolicyApplication>>, IPaymentInfo> {
  const { search } = useLocation();
  const { mutate: confirmRentersInsurance } = useConfirmRentersInsurance();

  const riPaymentFlexiblityEnabled = (window as any).App?.featureFlags?.riPaymentFlexiblity;
  const rentersInsuranceSelected = new URLSearchParams(search).get('ri_selected') === 'true';

  const confirmError = (r) => {
    const error = r.response?.data;
    if (error) {
      dispatch(payAndSubscribeSync(error, currentStep));
    }
  };

  const dispatch = useDispatch();
  return useMutation(payAndSubscribe, {
    onSuccess: (policyApplication) => {
      const sdiPaymentCadence = getPayCadence(search, policyApplication.address_state);
      const riPaymentCadence = getRiPayCadence(search, policyApplication.address_state);

      const payResult = {
        result: policyApplication
      };
      if (rentersInsuranceSelected) {
        confirmRentersInsurance(
          {
            ins_number: policyApplication.cover_genius_renters_insurance.ins_number,
            selected_quote_type: riPaymentFlexiblityEnabled ? riPaymentCadence : sdiPaymentCadence,
            payment_received: false
          },
          {
            onSuccess: () => {
              dispatch(payAndSubscribeSync(payResult, currentStep));
            },
            onError: (r: AxiosError<IResult<PolicyApplication>> | any) => {
              confirmError(r);
            }
          }
        );
      } else {
        dispatch(payAndSubscribeSync(payResult, currentStep));
      }
    },
    onError: (r: AxiosError<IResult<PolicyApplication>> | any) => {
      confirmError(r);
    }
  });
}
