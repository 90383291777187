import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Card as CardBase, CheckIcon as CheckIconBase, FONTS, PALETTE, DropdownMenu } from '@sayrhino/rhino-shared-js';
import { RentersInsurancePolicyProps } from '../interfaces';
import PolicyStatusIndicator from '../../shared/policyStatusIndicator';
import env from 'utils/env';
import CoverGeniusRedirect from './CoverGeniusRedirect';
import CancelationModal from './CancelationModal';
import CanceledConfirmationModal from './CanceledConfirmation';
import RentersInsAutoCancelledAlert from './RentersAutoCancelledAlert';
import InterestedPartyModal from './InterestedPartyModal';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import InterestedPartyConfirmation from './InterestedPartyConfirmation';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';

const Card = styled(CardBase)({
  // These double ampersands are being used to increase the specificity of rules on the component
  '&&': {
    border: `1px solid ${PALETTE.neutral12}`,
    width: 'auto',
    height: 'auto',
    overflow: 'hidden',
    marginBottom: '32px',
    cursor: 'auto'
  },
  '&&:hover': {
    boxShadow: `none`
  }
});

const InnerCardWrapper = styled.div({
  padding: '32px'
});

const Header = styled.h4([
  FONTS.h4,
  {
    lineHeight: '32px',
    color: `${PALETTE.neutralDark}`,
    marginBottom: 0
  }
]);

const AddressText = styled.p([FONTS.p1Light, { marginBottom: '12px' }]);

const CoverageText = styled.p([
  FONTS.p2,
  {
    color: `${PALETTE.neutral88}`,
    fontsize: '14px',
    height: '24px',
    marginBottom: '0px'
  }
]);

const BottomContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px'
});

const RIMenu = styled(DropdownMenu.Root)({
  width: '308px'
});

const MenuTrigger = styled(DropdownMenu.Trigger)([
  FONTS.p2Medium,
  {
    alignItems: 'center',
    color: `${PALETTE.neutral88}`,
    display: 'flex',
    fontStyle: 'Maison Neue',
    height: '32px',
    position: 'relative',
    width: '63px'
  }
]);

const MenuItem = styled(DropdownMenu.Item)({
  cursor: 'pointer',
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'space-between',
  marginBottom: '8px',
  marginTop: '8px',
  paddingLeft: '24px',
  width: '308px'
});

const InactiveMenuItem = styled.div({
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'space-between',
  marginBottom: '8px',
  marginTop: '8px',
  paddingLeft: '24px',
  width: '308px',
  color: `${PALETTE.neutral55}`,
  fontFamily: 'MaisonNeueLight'
});

const RedMenuItem = styled(MenuItem)({
  color: `${PALETTE.alert100}`
});

const LinkArrow = styled.div({
  color: '#353535',
  fontSize: '20px',
  marginRight: '14px'
});

const CheckIcon = styled(CheckIconBase)({ color: PALETTE.success, fontWeight: 'bold', marginLeft: 25 });

export const CGOptions = {
  FILE_CLAIM: 'FILE_CLAIM',
  SHOW_POLICY_DETAILS: 'SHOW_POLICY_DETAILS'
};

const RentersInsurancePolicyCard: React.FC<RentersInsurancePolicyProps> = (props: RentersInsurancePolicyProps) => {
  const {
    ins_number,
    download_policy_document_url,
    claim_url,
    policy_wording_url,
    auto_cancelled,
    addresses,
    userId,
    status_for_ri_card,
    address_line_one,
    address_line_two,
    address_state,
    address_city,
    zipcode
  } = props;

  const [showCGInterstitial, setCGInterstitial] = useState(false);
  const [redirectAction, setRedirectAction] = useState(null);
  const [showCanceledConfirmationModal, setCanceledConfirmationModal] = useState(false);
  const [showCancelationModal, setCancelationModal] = useState(false);
  const [showInterestedPartyModal, setShowInterestedPartyModal] = useState(false);
  const [showInterestedPartyConfirmation, setShowInterestedPartyConfirmation] = useState(false);
  const [interestedPartyError, setInterestedPartyError] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();
  const interestedPartyPath = pathname.includes(`/users/edit/add_interested_party/${ins_number}`);
  useEffect(() => {
    pathname.includes(`/users/edit/add_interested_party/${ins_number}`) ? setShowInterestedPartyModal(true) : null;
  }, []);

  const onOpenInterestedPartyModal = () => {
    interestedPartyPath ? null : history.push(`/users/edit/add_interested_party/${ins_number}`);
    setShowInterestedPartyModal(true);
    onToggleFullscreenModal(true);
  };
  const closeInterestedPartyModal = () => {
    setShowInterestedPartyModal(false);
    history.push('/users/edit');
  };

  const completeInterestedPartyAdded = () => {
    closeInterestedPartyModal();
    setShowInterestedPartyConfirmation(true);
  };

  const closeInterestedPartyConfirmation = () => {
    setShowInterestedPartyConfirmation(false);
    setInterestedPartyError(false);
  };

  const onClose = () => {
    setCGInterstitial(false);
    setRedirectAction(null);
    setShowInterestedPartyModal(false);
  };

  const redirectToCoverGenius = (coverGeniusAction) => {
    setCGInterstitial(true);
    setRedirectAction(coverGeniusAction);
  };

  const onCancel = () => {
    setCancelationModal(false);
    setCanceledConfirmationModal(true);
  };
  const displayCancelledBanner = addresses?.find((address) => {
    const rentersInsuranceCardAddress = `${address_line_one} ${
      address_line_two ? address_line_two : null
    } ${address_city} ${address_state} ${zipcode}`;
    const eligibleForRentersInsuranceAddress = `${address.address_line_one} ${
      address.address_line_two ? address.address_line_two : null
    } ${address.address_city} ${address.address_state} ${address.zipcode}`;
    return rentersInsuranceCardAddress === eligibleForRentersInsuranceAddress;
  });
  return (
    <>
      <Route exact path={`/users/edit/add_interested_party/${ins_number}`}>
        <InterestedPartyModal
          isOpen={showInterestedPartyModal}
          onClose={() => closeInterestedPartyModal()}
          onComplete={() => completeInterestedPartyAdded()}
          insNumber={props.ins_number}
          setError={() => setInterestedPartyError(true)}
          interestedPartyAddress={props.interested_party_address}
          interestedPartyEmail={props.interested_party_email}
          interestedPartyName={props.interested_party_name}
        />
      </Route>
      <InterestedPartyConfirmation
        isOpen={showInterestedPartyConfirmation}
        interestedPartyError={interestedPartyError}
        closeInterestedPartyConfirmation={() => closeInterestedPartyConfirmation()}
        updated={props.interested_party_email !== undefined}
      />
      <CoverGeniusRedirect
        isOpen={showCGInterstitial}
        onClose={onClose}
        redirectAction={redirectAction}
        claimUrl={claim_url}
        policyWordingUrl={policy_wording_url}
      />
      <CanceledConfirmationModal
        isOpen={showCanceledConfirmationModal}
        onClose={() => setCanceledConfirmationModal(false)}
      />
      <CancelationModal
        isOpen={showCancelationModal}
        onClose={() => setCancelationModal(false)}
        onCancel={onCancel}
        ins_number={ins_number}
      />
      <div id="dashboard-policy" data-cy="riPolicyCard">
        <Card variant="border">
          <InnerCardWrapper>
            <Header>Renters Insurance</Header>
            <AddressText>
              {address_line_one} {address_city}, {address_state} {zipcode}
              {address_line_two ? `, Unit ${address_line_two}` : null}
            </AddressText>
            <CoverageText data-cy="riPolicyNumber">Policy number: {ins_number}</CoverageText>
            <CoverageText>Personal property coverage — $15,000</CoverageText>
            <CoverageText>Liability coverage — $100,000</CoverageText>
            <BottomContainer>
              <PolicyStatusIndicator dataCy="riPolicyStatus" status={status_for_ri_card} className="status-padding" />
              <RIMenu>
                <MenuTrigger data-cy="menu">Menu</MenuTrigger>
                <DropdownMenu.Content>
                  <MenuItem
                    onSelect={() => window.open(download_policy_document_url, '_blank')}
                    data-cy="downloadReceipt"
                  >
                    <div>Download Coverage Summary</div>
                    <LinkArrow>&#10515;</LinkArrow>
                  </MenuItem>
                  <MenuItem onSelect={() => onOpenInterestedPartyModal()}>
                    <div>{props.interested_party_email ? 'Edit' : 'Add'} interested party/landlord</div>
                    <LinkArrow>&#8594;</LinkArrow>
                  </MenuItem>
                  <MenuItem
                    onSelect={() => {
                      redirectToCoverGenius(CGOptions.FILE_CLAIM);
                    }}
                    data-cy="fileAClaim"
                  >
                    <div>File a Claim</div>
                    <LinkArrow>&#8599;</LinkArrow>
                  </MenuItem>
                  <MenuItem
                    onSelect={() => {
                      redirectToCoverGenius(CGOptions.SHOW_POLICY_DETAILS);
                    }}
                    data-cy="policyDetails"
                  >
                    <div>Policy Details</div>
                    <LinkArrow>&#8599;</LinkArrow>
                  </MenuItem>
                  <MenuItem onSelect={() => window.open(env('RENTERS_INSURANCE_FAQ_URL'), '_blank')} data-cy="FAQ">
                    <div>Renters Insurance FAQs</div>
                    <LinkArrow>&#8599;</LinkArrow>
                  </MenuItem>
                  {status_for_ri_card !== 'Canceled' && (
                    <RedMenuItem onSelect={() => setCancelationModal(true)} data-cy="cancelPolicy">
                      Cancel Policy
                      <LinkArrow>&#8594;</LinkArrow>
                    </RedMenuItem>
                  )}
                </DropdownMenu.Content>
              </RIMenu>
            </BottomContainer>
          </InnerCardWrapper>
          {auto_cancelled && displayCancelledBanner && (
            <RentersInsAutoCancelledAlert addresses={addresses} userId={userId} />
          )}
        </Card>
      </div>
    </>
  );
};

export default RentersInsurancePolicyCard;
