interface ICopyQuote {
  quote_author: string;
  quote_text: string;
  headertext?: string;
  quoteSpan?: any;
}

export interface ICopyContent {
  renters_insurance_step: string;
  personal_info_step: string;
  education_level_step: string;
  income_step: string;
  employment_status_step: string;
  citizenship_step: string;
  social_security_step: string;
  assets_step: string;
  faq_sdi_bind: string;
  where_do_you_currently_live: string;
  cash_deposit_step: string;
  products_offered_step: string;
}

export type SignupCopy = string | ICopyQuote | undefined;

export function isICopyQuote(copy: SignupCopy): copy is ICopyQuote {
  return typeof copy !== 'string';
}
