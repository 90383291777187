import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

interface ICancellationReasons {
  label: string;
  value: number;
}
export const getCancellationReasons = async () => {
  const { data }: AxiosResponse = await axios.get<ICancellationReasons[]>('/insurance_policies/cancellation_reasons');

  return data;
};

export const cancellationReasonsKey = 'cancellationReasons';

export default function useCancellationReasons(): QueryObserverResult<ICancellationReasons[]> {
  return useQuery<ICancellationReasons[]>([cancellationReasonsKey], () => getCancellationReasons());
}
