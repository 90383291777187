import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { csrfToken } from 'utils/document';
import { IChildProps } from './step_wrapper';

import { BackButton, useSubsetState } from '../step_helpers';

import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import env from 'utils/env';
import { useHistory } from 'react-router';
import axios from 'axios';
import { formatCents } from 'utils/money/formatter';
import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { PaymentOptionsSelector } from './cash_deposit/payment_options_selector';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './cash_deposit/checkout_form';
import Loader from 'components/v2/loader';
import { calculatePriceAndCreateOrUpdatePaymentIntent, useAxiosDispatcher } from '../api';
import { useDispatch } from 'react-redux';
import { updatePolicyApplicationSync } from '../redux/actions';
import useToast, { TOAST_STATUS } from 'components/v2/action_center/components/RenterWithdrawalFunds/toast/use-toast';
import { rollbar } from 'components/v2/shared/rollbar';
import { CASH_DEPOSIT } from '../redux/state';
import { ExtraStepsNeededAlert } from './get_standard_security_deposit';

const buttonDivClassName = 'sign-up__actions d-flex justify-content-between align-items-center action_buttons';

const StyledColumn = styled.div({
  maxWidth: '538px',
  textAlign: 'center' as 'center',
  position: 'relative',
  width: '100%'
});

const buttonStyles = {
  paddingTop: '56px',
  paddingLeft: '30px'
};

const DEFAULT_HEADERS = {
  accept: 'application/json',
  'Content-Type': 'application/json'
};

const DEFAULT_OPTIONS = {
  authenticity_token: csrfToken(),
  credentials: 'same-origin',
  headers: DEFAULT_HEADERS
};

const CheckDepositProcessingTime = styled.span`
  color: #db3700;
  font-family: 'MaisonNeueExtendedBold';
`;

const CheckDepositLink = styled.p({
  color: '#6a3bf5',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '125%',
  letterSpacing: 0.24,
  marginTop: '16px',
  marginBottom: '0px !important',
  textDecorationLine: 'underline',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
    color: '#B69EFA'
  }
});

const CheckDepositLinkContainer = styled.div`
  @media (max-width: 481px) {
    margin-right: auto;
    margin-left: 25;
  }
  @media (max-width: 768px) {
    margin-left: 25;
  }
`;

const CashDepositDepositDetailsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  textAlign: 'left',
  width: '538px',
  maxWidth: '100%',
  '@media (max-width: 768px)': {
    padding: 0
  }
});

const CheckDepositStepsSection = styled.section``;

const CheckDepositStepsParagraph = styled.p`
  color: #5a6468;
  font-family: 'MaisonNeueMedium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.21px;
  margin-bottom: 0 !important;
`;

const BoldSpan = styled.span({
  color: '#5a6468',
  fontFamily: 'MaisonNeueExtendedBold'
});

export const SectionHeading = styled.h2({
  color: '#000',
  fontSize: 28,
  fontFamily: 'MaisonNeueExtendedBold',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: 0.56,
  margin: '0 auto',
  textAlign: 'center',
  marginBottom: '0px !important',
  '@media (max-width: 768px)': {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.48,
    lineHeight: '125%'
  }
});

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  gap: '40px',
  margin: '0 auto',
  maxWidth: '100%',
  width: '750px',
  height: '100%',
  '@media (max-width: 480px)': {
    justifyContent: 'center'
  }
});

export const ProcessingTime = styled.span({
  color: PALETTE.alert100,
  fontWeight: 'bold'
});

export const CardParagraph = styled.p({
  color: '#5A6468 !important',
  fontFamily: 'MaisonNeueMedium',
  fontSize: '10px',
  fontWeight: 500,
  letterSpacing: 0.15,
  lineHeight: '150%',
  marginBottom: '0 !important'
});

const StyledCardParagraph = styled(CardParagraph)({
  color: '#5A6468',
  fontFamily: 'MaisonNeueMedium',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%' /* 21px */,
  letterSpacing: 0.21,
  fontSize: 14
});

const StyledPaymentOptions = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  '@media (max-width: 960px)': {
    minHeight: 300
  }
});

const ContinueButton = styled.button({
  backgroundColor: 'rgb(106, 59, 245)',
  color: '#fff',
  padding: 10,
  width: 'auto !important',
  backgroundImage: 'none !important',
  '&:disabled': {
    backgroundColor: '#CBCFD2'
  }
});

const CashDepositSection = styled.section({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: '100%',
  width: '750px',
  '@media (max-width: 480px)': {
    gap: '32px'
  }
});

interface IDepositFee {
  amount: number;
  total: number;
  fees_total: number;
  fee_percentage: number;
  fee_base: number;
  has_lowest_fees: boolean;
  save_message: string;
}
interface IDepositFees {
  ach: IDepositFee;
  credit_card: IDepositFee;
}

type PaymentMethodFees = {
  ach: {
    amount: number;
    total: number;
    fees_total: number;
    fee_percentage: number;
    fee_base: number;
    has_lowest_fees: boolean;
    save_message: string;
  };
  credit_card: {
    amount: number;
    total: number;
    fees_total: number;
    fee_percentage: number;
    fee_base: number;
    has_lowest_fees: boolean;
    save_message: string;
  };
};

type CashDepositDataPrepServiceResult = {
  deposit_fees: IDepositFees;
  deposit_fees_for_tenant: IDepositFees;
} | {
  deposify_iframe_url: string;
};

export enum ONLINE_PAYMENT_OPTIONS {
  'Pay Online - Bank Transfer (ACH)' = 'ach',
  'Pay Online - Credit/Debit Card' = 'card'
}

function CashDeposit(props: IChildProps) {
  const dispatch = useDispatch();
  const dispatchAxios = useAxiosDispatcher();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [iframe, setIframe] = useState('');
  const [cashDepositFees, setCashDepositFees] = useState<IDepositFees>({} as IDepositFees);
  const [showCheckDepositDetails, setShowCashDepositDetails] = useState<boolean>(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<'check' | 'online' | ''>('');
  const [selectedOnlineType, setSelectedOnlineType] = useState<'ach' | 'card' | ''>('card');
  const [policyApplication] = useSubsetState(props.policyApplication.result, [
    'uses_partner_enrollment',
    'partner_enrollment_url',
    'cash_deposit_status'
  ], {
    cash_deposit_status: 2
  });
  const [showCashStripeElements, setShowCashStripeElements] = useState<boolean>(false);
  const { addToast } = useToast();

  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const result = props.policyApplication.result;

  const userId = props.user.result?.id;
  const policyApplicationId = result?.id;
  const navigate = useHistory();

  const stripePromise = useMemo(
    () => loadStripe(result?.stripe_publishable_key || ''),
    [result?.stripe_publishable_key || '']
  );

  const submitCashDeposit = async () => {
    const url = `/policy_applications/${policyApplicationId}`;

    const response = await axios.put(url, { submitted: true },
      DEFAULT_OPTIONS
    );
    return response
  };

  useEffect(() => {
    checkShowCashStripeElements()
  }, []);

  /*
    TO-DO:
    - We can refactor this method to only use requestCashDepositDetails
      and then calculatePriceAndCreateOrUpdatePaymentIntent
    - requestCashDepositDetails calls show_cash_stripe_elements? on the backend
  */
  const checkShowCashStripeElements = async () => {
    if (policyApplication.uses_partner_enrollment) return;
    setIsFetching(true);
    const res = await axios.get(
      `/users/${userId}/policy_applications/${policyApplicationId}/show_cash_stripe_elements.json`,
      DEFAULT_OPTIONS
    );
    const showStripeElements = res?.data?.show_cash_stripe_elements;
    let updatedPolicyApp;
    // we should always have a userId and policyApplicationId on this page
    if (showStripeElements && userId && policyApplicationId) {
      updatedPolicyApp = await dispatchAxios(calculatePriceAndCreateOrUpdatePaymentIntent)({
        userId,
        policyApplicationId,
        upfront: false,
        productType: CASH_DEPOSIT
    });
      dispatch(updatePolicyApplicationSync(updatedPolicyApp));
    }

    setShowCashStripeElements(
      !!showStripeElements && !!updatedPolicyApp?.result?.stripe_payment_intent_client_secret
    );

    await requestCashDepositDetails()
    setIsFetching(false);
  };

  const requestCashDepositDetails = async (): Promise<CashDepositDataPrepServiceResult | undefined> => {
    if (!userId || !policyApplicationId) return

    const res = await axios.get(
      `/users/${userId}/policy_applications/${policyApplicationId}/cash_deposit_details.json`,
      DEFAULT_OPTIONS
    );

    if (!res.data) return

    if (res.data.deposify_iframe_url) {
      setIframe(res.data.deposify_iframe_url);
    } else if (res.data.deposit_fees) {
      setCashDepositFees(res.data.deposit_fees);
    }

    return res.data
  }

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        const deposify_url = env('DEPOSIFY_EMBED_URL');
        if (deposify_url.includes(event.origin)) {
          const data = JSON.parse(event.data);

          if (data.event === 'deposit_paid') {
            if (policyApplicationId && userId && policyApplication) {
              policyApplicationUpdate.mutate({
                policyApplicationId,
                userId,
                policyApplication
              });
            }

            navigate.push('/enroll/password');
          } else if (data.event === 'userCancelledPayment') {
            navigate.push('/enroll/products_offered');
          }
        }
      },
      false
    );
  }, []);

  const handlePaperCheckClick = async () => {
    if (!policyApplicationId || !userId || !policyApplication) {
      rollbar.error('handlePaperCheckClick without policyApplicationId, userId or policyApplication');
      return;
    }

    return axios.post(
      `/users/${userId}/policy_applications/${policyApplicationId}/pay_by_check.json`,
      {
        ...DEFAULT_OPTIONS
      }
    );
  };

  const handleDownloadContinueClick = async () => {
    try {
      const response = await handlePaperCheckClick();

      const pdfURL =
        'https://deposify-public-cdn.s3.us-east-2.amazonaws.com/site/forms/Cash+Deposit+-+Check+Mail-in+Form+%26+Instructions.pdf';
      window.open(pdfURL, '_blank');

      if (response && response.status === 200) {
        navigate.push('/enroll/password');
      } else {
        addToast('An unexpected error occurred.', 'error', TOAST_STATUS.ERROR);
      }
    } catch (error) {
      addToast('An unexpected error occurred.', 'error', TOAST_STATUS.ERROR);
    }
  };

  const paymentOptionsConfig = [
    {
      title: 'Pay Online - Credit/Debit Card',
      serviceFee: cashDepositFees?.credit_card?.fees_total,
      totalDue: formatCents(cashDepositFees?.credit_card?.total),
      processingTime: 'Instant',
      showCardOptions: true
    },
    {
      title: 'Pay Online - Bank Transfer (ACH)',
      processingTime: '1 - 3 Business days',
      serviceFee: cashDepositFees?.ach?.fees_total,
      totalDue: formatCents(cashDepositFees?.ach?.total)
    }
  ];

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      spacingGridRow: '16px',
      spacingGridColumn: '16px'
    }
  };

  const stripePaymentOptions: StripeElementsOptions = {
    clientSecret: result?.stripe_payment_intent_client_secret,
    appearance
  };

  const handleOptionSelect = () => {
    setShowCheckoutForm(true);
  };

  if (policyApplication.uses_partner_enrollment) {
    return (
      <StyledContainer>
        {/* @ts-ignore */}
        <ExtraStepsNeededAlert enrollmentUrl={policyApplication.partner_enrollment_url} />
      </StyledContainer>
    )
  }

  if (isFetching) {
    return (
      <StyledContainer>
        <Loader />
      </StyledContainer>
    );
  }

  if (showCashStripeElements) {
    return (
      <StyledContainer>
        <CashDepositSection>
          {showCheckoutForm ? (
            <Elements options={stripePaymentOptions} stripe={stripePromise}>
              <CheckoutForm
                paymentIntentSecret={result?.stripe_payment_intent_client_secret}
                step={props.step}
                totalAmount={formatCents(
                  selectedOnlineType === 'ach' ? cashDepositFees.ach.total : cashDepositFees.credit_card.total
                )}
                defaultTab={selectedOnlineType === 'ach' ? 'us_bank_account' : 'card'}
                submitCashDeposit={submitCashDeposit}
              />
            </Elements>
          ) : (
            <React.Fragment>
              <SectionHeading>How would you like to pay your security deposit?</SectionHeading>
              <section>
                <StyledPaymentOptions>
                  <PaymentOptionsSelector
                    paymentOptionsConfig={paymentOptionsConfig}
                    setSelectedOnlineType={setSelectedOnlineType}
                  />
                </StyledPaymentOptions>
                <CheckDepositLinkContainer>
                  <CheckDepositLink
                    onClick={() => {
                      setSelectedPaymentOption((state) => {
                        if (state === 'check') return 'online';

                        return 'check';
                      });
                      setShowCashDepositDetails((state) => !state);
                    }}
                  >
                    Pay by Paper check
                  </CheckDepositLink>
                </CheckDepositLinkContainer>
              </section>
              {showCheckDepositDetails && (
                <CashDepositDepositDetailsSection>
                  <CheckDepositStepsSection>
                    <StyledCardParagraph>Service fee: Free</StyledCardParagraph>
                    <StyledCardParagraph>
                      Processing Time: <CheckDepositProcessingTime>10 - 12 business days</CheckDepositProcessingTime>
                    </StyledCardParagraph>
                  </CheckDepositStepsSection>

                  <CheckDepositStepsSection>
                    <CheckDepositStepsParagraph>
                      Step 1: Download the form by clicking the button below.
                    </CheckDepositStepsParagraph>
                    <CheckDepositStepsParagraph>
                      Step 2: Complete the form and send a check to <BoldSpan>Rhino Deposit Management, LLC.</BoldSpan>
                    </CheckDepositStepsParagraph>
                    <CheckDepositStepsParagraph>
                      Step 3: Drop the form and payment in the mail and send it to the address provided.
                    </CheckDepositStepsParagraph>
                  </CheckDepositStepsSection>

                  <CheckDepositStepsParagraph>
                    Please note that this option requires the longest processing time. If you’d like to expedite the
                    process, please consider submitting payment via ACH or card.
                  </CheckDepositStepsParagraph>
                </CashDepositDepositDetailsSection>
              )}
            </React.Fragment>
          )}
        </CashDepositSection>
        {!showCheckoutForm ? (
          <StyledColumn
            style={{
              margin: '0 auto'
            }}
          >
            <div className={buttonDivClassName} style={buttonStyles}>
              <BackButton step={props.step} />
              {(selectedPaymentOption === 'online' || selectedPaymentOption === '') && (
                <ContinueButton
                  type="submit"
                  className="btn-default"
                  data-cy="continue"
                  disabled={!selectedOnlineType}
                  onClick={handleOptionSelect}
                >
                  Continue
                </ContinueButton>
              )}
              {selectedPaymentOption === 'check' && (
                <ContinueButton
                  type="submit"
                  className="btn-default"
                  data-cy="continue"
                  onClick={handleDownloadContinueClick}
                >
                  Download and Continue
                </ContinueButton>
              )}
            </div>
          </StyledColumn>
        ) : null}
      </StyledContainer>
    );
  } else if (!iframe) {
    return (
      <StyledContainer>
        <Loader />
      </StyledContainer>
    );
  } else {
    return (
      <StyledContainer>
        <iframe
          width="100%"
          height="1100px"
          src={iframe}
          style={{ border: 'none' }}
        />
      </StyledContainer>
    );
  }
}

export default CashDeposit;
