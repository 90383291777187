import React from 'react';

// @ts-ignore
import dots from 'components/v2/shared/blob.svg';
import { useEnrollmentStep } from '../../step_helpers';

const PasswordPanel: React.FC = () => {
  return (
    <div>
      <h2 className="bold header" data-cy="passwordHeader">
        Your policy is ready to go! Create a password for your new account.
      </h2>
      <p className="p2" data-cy="passwordDescription">
        We’ll reach out to your landlord to confirm your enrollment with Rhino.
        If they request any changes, we’ll let you know right away.
      </p>
    </div>
  );
};

export default PasswordPanel;
