import axios, { AxiosResponse } from 'axios';
import { ICreateCoverGeniusPolicyDetailsParams } from '../interfaces';
import { useMutation, MutationFunction } from 'react-query';

const createCoverGeniusPolicyDetails: MutationFunction<AxiosResponse, ICreateCoverGeniusPolicyDetailsParams> = async (
  params: ICreateCoverGeniusPolicyDetailsParams
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.post(`/cover_genius_policy_details`, params);
  return response;
};

export default function useCreateCoverGeniusPolicyDetailsMutation() {
  return useMutation(createCoverGeniusPolicyDetails);
}
