import React, { useState } from 'react';
import MaskedInput from 'components/common/form/masked-input';
import { User } from '../redux/state';
import { BackButton, renderError, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { usePersistUserAndPolicyApplication } from '../hooks/usePersistUserAndPolicyApplication';
import { FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import useCreateCoverGeniusPolicyDetailsMutation from '../../renters_insurance/hooks/createCoverGeniusPolicyDetails';
import { ICreateCoverGeniusPolicyDetailsParams } from '../../renters_insurance/interfaces';
import { AxiosResponse } from 'axios';
import { BOTH } from '../../renters_insurance/constants';
import env from 'utils/env';

const OptInText = styled.div(FONTS.p3Light);

function Social(props: IChildProps) {
  const userAndPolicyApplicationPersist = usePersistUserAndPolicyApplication();
  const [user, updateUserState] = useSubsetState(
    props.user.result,
    ['social_security_number', 'has_ssn', 'authorized_credit_check'],
    { has_ssn: true },
    { authorized_credit_check: true }
  );
  const [policyApplication, updatePolicyApplication] = useSubsetState(
    props.policyApplication.result,
    ['has_ssn', 'authorized_credit_check'],
    { has_ssn: true },
    { authorized_credit_check: true }
  );
  const { mutate: createCoverGeniusPolicyDetails } = useCreateCoverGeniusPolicyDetailsMutation();
  const [rentersInsuranceQuote, setRentersInsuranceQuote] = useState(null);

  const updateUserAndPolicyApp = (update: User) => {
    updateUserState(Object.assign({}, user, update));
    updatePolicyApplication(Object.assign({}, policyApplication, update));
  };

  const renterInsuranceRequirements =
    props.partnerRentersInsuranceRequirements?.result?.active_renters_insurances_requirements;
  const ownerSetRIRequirement = renterInsuranceRequirements?.requires_renters_insurance;

  const checkForRIDiscount = () => {
    if (props.policyApplication.result?.renters_insurance_required !== undefined) {
      return props.policyApplication.result?.renters_insurance_required;
    } else if (ownerSetRIRequirement !== (undefined || null)) {
      return ownerSetRIRequirement;
    } else {
      return false;
    }
  };

  const qualifiesForRentersInsurance = () => {
    const ownerEMA = renterInsuranceRequirements?.has_exclusive_agreement;
    const ownerDidNotIndicateRentersInsuranceRequirements = renterInsuranceRequirements === undefined;
    const rentersInsuranceStepEnabled = (window as any).App?.featureFlags?.signUp?.rentersInsuranceAnnualAtSignUp;
    const inRentersInsuranceState = env('RENTERS_INSURANCE_STATES').includes(
      props.policyApplication.result?.address_state
    );
    return (
      rentersInsuranceStepEnabled &&
      inRentersInsuranceState &&
      (!ownerEMA || ownerDidNotIndicateRentersInsuranceRequirements)
    );
  };

  const getRIQuote = () => {
    const requestData: ICreateCoverGeniusPolicyDetailsParams = {
      policy_application_id: props.policyApplication.result?.id,
      user_id: props.user.result?.id,
      requested_quote: BOTH,
      ri_required: checkForRIDiscount()
    };

    createCoverGeniusPolicyDetails(
      { ...requestData },
      {
        onSuccess: (response: AxiosResponse) => {
          setRentersInsuranceQuote(response.data.policy_detail);
        }
      }
    );
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (qualifiesForRentersInsurance()) {
      getRIQuote();
    }
    userAndPolicyApplicationPersist(
      user,
      policyApplication,
      props.user.result?.id,
      props.policyApplication.result?.id,
      props.step
    );
  };

  const headerText = () => {
    return 'To determine your eligibility and give you the most accurate quote, we need to run a soft credit check.';
  };

  const socialSecurityText = () => {
    return 'We need your social security number to see if you are eligible for a Rhino policy and to give you the most accurate quote. Don’t worry, this won’t affect your credit score.';
  };

  return (
    <div>
      <div>
        <h2 className="bold header" data-cy="ssnHeader">
          {headerText()}
        </h2>
        <p className="p2" data-cy="ssnDescription">
          {socialSecurityText()}
        </p>
        <form className="sign-up__form" id="social-form" onSubmit={onSubmit}>
          <div className="form-holder">
            <div className="form" style={styles.formHolder}>
              <div className="input-holder" style={styles.inputContainer}>
                <MaskedInput
                  placeholder="Social security number"
                  mask="999-99-9999"
                  dataCy="ssn"
                  autoFocus={true}
                  id="user_social_security_number"
                  name="user[social_security_number]"
                  value={user.social_security_number}
                  className="landing-page--input"
                  onChange={(_e, social_security_number) => {
                    updateUserAndPolicyApp({ social_security_number, has_ssn: true });
                  }}
                />
                {renderError('social_security_number', props.user)}
              </div>
              <div style={styles.optInContainer}>
                <OptInText>
                  By providing your social security number, you authorize us to obtain a copy of your credit report for
                  underwriting/rating purposes.
                </OptInText>
              </div>
              <div className="input-holder v2-checkbox" style={styles.checkbox}>
                <input
                  type="checkbox"
                  id="has_ssn"
                  data-cy="noSsn"
                  className="no-ssn-checkbox v2-checkbox"
                  name="policyApplication[has_ssn]"
                  onChange={(e) => updateUserAndPolicyApp({ social_security_number: '', has_ssn: !e.target.checked })}
                  value={policyApplication?.has_ssn?.toString() || ''}
                  checked={!policyApplication.has_ssn}
                  readOnly={true}
                />
                <label htmlFor="has_ssn" className="v2-checkbox-label">
                  I don't have a social security number
                </label>
              </div>

              <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
                <BackButton step={props.step} />
                <input type="submit" className="btn-default" data-cy="continue" value="Continue" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  },
  checkbox: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: '100px'
  },
  optInContainer: {
    margin: '16px 0px'
  }
};

export default Social;
