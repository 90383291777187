import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CardUpdateStep } from '../edit_account/paymentInformation';

import UpdateCardForm from './UpdateCardForm';

interface IProps {
  name: string;
  wrapperClassName: string;
  updateStep?: (value: CardUpdateStep) => void; // Used only for renter-dashboard-v2
  closeInitialModal?: (value: boolean) => void; // Used only for renter-dashboard-v2
  update_payment_method_url: string;
  stripePublishableKey: string;
  isMobile?: any;
}

const UpdateCard: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="edit-profile-section">
      <StripeProvider apiKey={props.stripePublishableKey}>
        <Elements>
          <UpdateCardForm {...props} />
        </Elements>
      </StripeProvider>
    </div>
  );
};

export default UpdateCard;
