import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { SignupCopy } from '../signup/constants/copy';

interface IDidYouKnowProps {
  copyContent?: SignupCopy;
}

const DidYouKnowContainer = styled.div({
  background: PALETTE.brand4,
  padding: '24px',
  borderRadius: '10px'
});

const DidYouKnowHeader = styled.h5([FONTS.h5, { '&&': { color: PALETTE.brand125, marginBottom: '4px' } }]);
const DidYouKnowText = styled.p([FONTS.p2, { '&&': { color: PALETTE.Neutral88, marginBottom: '0px' } }]);

export const DidYouKnow = (props: IDidYouKnowProps) => {
  const { copyContent } = props;

  return (
    <DidYouKnowContainer>
      <DidYouKnowHeader>Did you know?</DidYouKnowHeader>
      <DidYouKnowText>{copyContent}</DidYouKnowText>
    </DidYouKnowContainer>
  );
};
