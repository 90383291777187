import { AxiosError } from 'axios';
import { flatMap } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorRentAmount from 'components/common/form/input/ErrorRentAmount';
import { IChildProps } from './components/step_wrapper';
import { pluck as Pluck } from 'utils/redux_helpers';
export { renderError, useSubsetState, renderErrors, coerceUndefined } from 'utils/redux_helpers';
import { clearRedirect, IResult, redirectSelector, signupStepSelector } from './redux/actions';
import { PolicyApplication, User, Invitation } from './redux/state';
import { previousStep, stepUrl } from './steps';

export const usePolicyApplication = (props: IChildProps): [PolicyApplication, (u: PolicyApplication) => void] => {
  const [policyApplication, setPolicyApplication] = useState(props.policyApplication.result || {});
  const updatePolicyApplication = (update: PolicyApplication) => {
    setPolicyApplication(Object.assign({}, policyApplication, update));
  };
  return [policyApplication, updatePolicyApplication];
};

(window as any).pluck = Pluck;

export const pluck = Pluck;

export function previousStepUrl(
  step: string,
  user: IResult<User>,
  policyApplication: IResult<PolicyApplication>
): string {
  const prev = previousStep(policyApplication, user, step);

  return prev ? stepUrl(prev, { policyApplication: policyApplication.result }) : '#';
}

export function BackButton({ step }) {
  const signupStepData = useSelector(signupStepSelector);
  const { user, policyApplication } = signupStepData;
  const prev = previousStep(policyApplication, user, step);
  let url = '#';

  if (prev) {
    url = stepUrl(prev, { policyApplication: policyApplication.result });
    url = `${url}?back=true`;
  }

  return (
    <Link to={url} data-cy="back" className="back-btn">
      Back
    </Link>
  );
}

export function useRedirect() {
  const redirect = useSelector(redirectSelector);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const redirectTo = pathname !== redirect && redirect;

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      dispatch(clearRedirect());
    }
  }, [redirectTo]);
}

export function useEnrollmentStep() {
  const location = useLocation();
  const match = location.pathname.match(/\/enroll\/(.+)$/);

  if (match === null) {
    return;
  }

  return match[1];
}

export function loadedViaBackButton() {
  const location = useLocation();

  return location.search.match(/back=true/) !== null;
}

export function useLoader(duration: number | null = 2500): boolean {
  const [displayLoader, setDisplayLoader] = useState(false);
  const timeout = useRef<number>();
  const loaderEnabled = (window as any).App?.featureFlags?.signUp?.loaderEnabled;
  const loadedViaBack = loadedViaBackButton();

  useEffect(() => {
    if (loaderEnabled && !loadedViaBack) {
      if (timeout.current !== undefined) {
        return;
      }
      setDisplayLoader(true);
      if (duration !== null) {
        timeout.current = window.setTimeout(() => {
          timeout.current = undefined;
          setDisplayLoader(false);
        }, duration);
      }
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    };
  }, []);

  return displayLoader;
}

export function allPresent(fields: any[]): boolean {
  return fields.reduce((m, x) => m && x !== undefined && x !== '', true);
}

export const doesExist = (value: any) => {
  if (value === undefined) return;
  return Boolean(value);
};

export function renderRentAmoutError(result: IResult<any>): React.ReactNode {
  if (result.errors) {
    const errorTexts = flatMap(result.errors, (v) => v);

    return (
      <div>
        {errorTexts.map((error, i) => (
          <ErrorRentAmount text={error} key={i} id={i} />
        ))}
      </div>
    );
  }
}

export function mergeInvitationChange(policyApp?: PolicyApplication, invitation?: Invitation): PolicyApplication {
  const coverageAmount = invitation?.custom_coverage_amount;
  const coverageMultiplier = invitation?.coverage_months;
  // If the invitation has a coverage amount defined, use that. Otherwise, if the invitation
  // has a coverage multiplier defined, clear out custom_coverage_amount_cents so that it won't
  // override the multiplier. If neither exists, default to whatever value the policyApp has.
  const customCoverageAmountCents = coverageAmount ? Math.round(coverageAmount * 100) :
    coverageMultiplier ? undefined : policyApp?.custom_coverage_amount_cents

  return {
    ...policyApp,
    lease_start_date: invitation?.start_date ? invitation?.start_date : policyApp?.lease_start_date,
    lease_end_date: invitation?.end_date ? invitation?.end_date : policyApp?.lease_end_date,
    monthly_rent:
      invitation?.monthly_rent && invitation.monthly_rent > 0 ? invitation.monthly_rent : policyApp?.monthly_rent,
    property_id: invitation?.property_id || policyApp?.property_id,
    unit_id: invitation?.unit_id || policyApp?.unit_id,
    google_place_id: invitation?.google_place_id || policyApp?.google_place_id,
    address_line_two: invitation?.address_line_two || policyApp?.address_line_two,
    custom_coverage_amount_cents: customCoverageAmountCents,
    custom_coverage_multiplier: coverageMultiplier ? parseFloat(coverageMultiplier) : undefined,
    partial_quote_id: invitation?.id || policyApp?.partial_quote_id
  };
}

export const verifyResponseForConflict = (error: AxiosError<IResult<PolicyApplication>>) => {
  return new Promise<string>((resolve, reject) => {
    if (hasPartnerEnrollmentConflict(error)) {
      return reject(error.response?.data?.errors?.enrollment_link);
    } else return resolve('');
  });
}

export const hasPartnerEnrollmentConflict = (error: AxiosError<IResult<PolicyApplication>>): boolean => {
  return error.response?.status === 409 && !!error.response.data?.errors?.enrollment_link;
}

export const redirectToPartnerEnrollment = (enrollment_link: string) => {
  window.location.replace(enrollment_link);
  throw new Error('Redirected to partner enrollment');
}
