import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MapWithOverlay from '../map_with_overlay';
import { IResult } from '../redux/actions';
import * as actions from '../redux/actions';
import {
  Invitation,
  PolicyApplication,
  User,
  IPartnerRentersInsuranceRequirements,
  IUnitName
} from '../redux/state';
import { useAssetContext } from './asset_context';
import { SignupCopy } from '../constants/copy';
import GuildingPanel from './panels/guilding';
import PasswordPanel from './panels/password_panel';
import ZendeskSupportWidget from '../components/zendesk_support_widget';
export interface IChildProps {
  user: IResult<User>;
  invitation: IResult<Invitation>;
  policyApplication: IResult<PolicyApplication>;
  partnerRentersInsuranceRequirements: IResult<IPartnerRentersInsuranceRequirements>;
  partialQuoteUnitName: IResult<IUnitName>;
  step: string;
  sendInvitationFromAgent: () => void;
  loaderImage?: string;
  copy?: SignupCopy;
}

export enum Panel {
  Guilding,
  Map,
  PasswordPanel
}

export interface IProps {
  component: React.FunctionComponent<IChildProps>;
  step: string;
  panel?: Panel;
  containerId?: string;
  loaderImage?: string;
  copy?: SignupCopy;
}

function panelFor(props: IProps, pa?: PolicyApplication, invitation?: Invitation) {
  const assets = useAssetContext();
  const mapProps = invitation?.map_props || pa?.map_props;

  if (props.panel === Panel.Guilding) {
    return <GuildingPanel step={props.step} copy={props.copy} />;
  } else if (props.panel === Panel.PasswordPanel) {
    return <PasswordPanel />;
  } else if (mapProps) {
    const mapWithOverlayProps = {
      // TODO(bigs): mobile???
      ...mapProps,
      wrapperId: 'desktop-card',
      assets: assets.map
    };

    return <MapWithOverlay {...mapWithOverlayProps} />;
  }
}

function StepWrapper(props: IProps) {
  // Hooks
  const dispatch = useDispatch();
  const childData = useSelector(actions.signupStepSelector);
  const user = childData.user.result;

  const { component, containerId, loaderImage, step, copy } = props;
  const policyApplication = childData.policyApplication.result;
  const invitation = childData.invitation.result;

  const sendInvitationFromAgent = () => {
    dispatch(actions.sendInvitationFromAgent());
  };

  useEffect(() => {
    if (user?.id && policyApplication?.id) {
      const update = actions.makeUpdate({ userId: user.id, policyAppId: policyApplication.id }, step);

      dispatch(actions.setPolicyAppSignupStep(update));
    }
  }, [step]);

  useEffect(() => {
    if (policyApplication?.id) {
      dispatch(actions.getPartnerRentersInsuranceRequirements(policyApplication.property_id));
    } else if (invitation?.id) {
      dispatch(actions.getPartnerRentersInsuranceRequirements(invitation.property_id));
    }
    dispatch(actions.getPartialQuoteUnitName(policyApplication?.policy_app_unit_id));
  }, []);

  const childProps = {
    copy,
    sendInvitationFromAgent,
    loaderImage,
    step,
    ...childData
  };
  const child = React.createElement(component, childProps);
  const panel = panelFor(props, policyApplication, invitation);
  const chatBotWidget = step !== 'quote_display' ? <ZendeskSupportWidget /> : null;

  if (props.panel === undefined) {
    return (
      <>
        {chatBotWidget}
        {child}
      </>
    );
  } else {
    return (
      <div id={containerId} className="flow-step--page">
        {chatBotWidget}
          <div className="row">
            <div className="col-lg-5 col-sm-12 col-xs-12 d-lg-block order-last order-lg-first flow-step--left-panel">
              {panel}
            </div>

            <div
              className="col-lg-7 col-sm-12 col-xs-12 d-lg-block order-first order-lg-last flow-step--right-panel"
              style={{ marginBottom: '56px' }}
            >
              {child}
            </div>
          </div>
      </div>
    );
  }
}

export default StepWrapper;
