import React, { useEffect } from 'react';
import RentersInsuranceBanner from '../renters_insurance/components/RentersInsuranceBanner';
import { useQueryClient } from 'react-query';
import { IResult } from '../signup/redux/actions';
import { PolicyApplication } from '../signup/redux/state';
import { IPolicyProps } from './policy/policyProps';
import RentersInsurancePartnerBanner from '../renters_insurance/components/RentersInsurancePartnerBanner';
import { IRentersInsuranceAddress } from '../renters_insurance/interfaces';
import getFeatureFlags from 'utils/getFeatureFlags';

const featureFlags = getFeatureFlags();

interface IRentersInsuranceBannerWrapperProps {
  userId: number;
  firstName: string;
  insuranceId?: number;
  policyApp: IResult<PolicyApplication>;
  addressesForRI: IRentersInsuranceAddress[];
  renter_insurance_faq_variation?: boolean;
  sdiPolicies: IPolicyProps[];
}

export const RentersInsuranceBannerWrapper = (props: IRentersInsuranceBannerWrapperProps) => {
  const { addressesForRI, firstName, policyApp, sdiPolicies, userId } = props;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['user', userId]);
  }, [sdiPolicies.length]);

  const riAddressesWithNoExclusiveMarketingAgreement = addressesForRI.filter((address) => {
    return (
      address.renters_insurance_requirements?.[0].active_renters_insurances_requirements?.has_exclusive_agreement !==
      true
    );
  });

  const userHasEligibleRIAddress = riAddressesWithNoExclusiveMarketingAgreement.length > 0;

  const riRequiredPropertiesList = riAddressesWithNoExclusiveMarketingAgreement.filter((property) => {
    return property.renters_insurance_requirements?.[0].active_renters_insurances_requirements
      ?.requires_renters_insurance;
  });

  if (featureFlags.rentersInsurance && riRequiredPropertiesList.length === 0 && userHasEligibleRIAddress) {
    return (
      <RentersInsuranceBanner
        userId={userId}
        firstName={firstName}
        policyApp={policyApp}
        addressesForRI={riAddressesWithNoExclusiveMarketingAgreement}
      />
    );
  }
  if (featureFlags.rentersInsurance && userHasEligibleRIAddress) {
    return (
      <RentersInsurancePartnerBanner
        userId={userId}
        firstName={firstName}
        policyApp={policyApp}
        addressesForRI={riAddressesWithNoExclusiveMarketingAgreement}
      />
    );
  }
  return null;
};
