import React, { Fragment, useState } from 'react';
import UiModal from '../modal';
import { CardUpdateStep } from '../paymentInformation';
import { FONTS, Button as UiButton, PALETTE } from '@sayrhino/rhino-shared-js';
import { css } from '@emotion/react';
import UpdateCard from 'components/v2/update_card';
import styled from '@emotion/styled';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

export const UpdatePaymentModal = ({
  closeModal,
  isOpen,
  user,
  card_update_path,
  payment_method_update_path,
  stripePublishableKey
}) => {
  const [step, setStep] = useState<CardUpdateStep>(CardUpdateStep.Initial);
  const isMobile = useCheckMobile();

  const Header = styled.h3([FONTS.h3, { color: PALETTE.neutralDark }]);
  const Subheader = styled.p([FONTS.p1, { color: PALETTE.neutral65 }]);

  return (
    <UiModal
      id="initialModal"
      closeModal={closeModal}
      isOpen={isOpen}
      styleOptions={{ paddingRight: '0px', paddingLeft: '0px' }}
    >
      <Fragment>
        {step === CardUpdateStep.Initial && (
          <div>
            <Header>Update payment information</Header>
            <Subheader>Updating this payment method will update payment on all of your policies.</Subheader>
            <UpdateCard
              name={`${user.first_name} ${user.last_name}`}
              update_payment_method_url={payment_method_update_path}
              updateStep={setStep}
              closeInitialModal={closeModal}
              wrapperClassName="payment-details edit-payment-details"
              stripePublishableKey={stripePublishableKey}
              isMobile={isMobile}
            />
          </div>
        )}
        {step === CardUpdateStep.Complete && (
          <div
            css={css`
              @media (max-width: 768px) {
                height: 75vh;
                display: grid;
                align-items: center;
              }
            `}
          >
            <div>
              <h3 css={[FONTS.h3]} className="text-center">
                Payment Updated
              </h3>
              <p css={FONTS.p1Light} className="text-center mt-2">
                If you missed any payments due to an expired card, those payments will be charged to this card.
              </p>
              <UiButton children="Ok, got it" className="mt-4 mx-auto" onClick={closeModal} />
            </div>
          </div>
        )}
      </Fragment>
    </UiModal>
  );
};
