import React, { Component } from 'react';

type IProps = {};

type IState = {
  partial: string;
};

class HowItWorksSteps extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      partial: 'newRenters'
    };
  }

  showGradeOptions = () => {
    const STEPS = {
      newRenters: [
        {
          title: 'No need to collect a deposit.',
          content: 'Rhino replaces the security deposit entirely. Once a renter signs up with us, you’re covered.'
        },
        {
          title: 'Get the same quality applicants.',
          content:
            'You’ll approve all applications as you do now. We run checks to ensure renters can reimburse us, too.'
        },
        {
          title: 'Reimbursement is simple and fast.',
          content: 'Just submit a claim online, we’ll review in 48 hours guaranteed and pay you via ACH or check.'
        }
      ],
      existingRenters: [
        {
          title: 'Incentivize your best tenants to renew.',
          content: 'Offer Rhino at renewal. Renters will love the extra cash and you’ll save on turnover costs.'
        },
        {
          title: 'Make renters happy with early deposit returns.',
          content: 'Once a renter signs up, you’re covered. Just sign the new lease and return the deposit.'
        },
        {
          title: 'Get 2x the coverage and easy reimbursements.',
          content: 'Just submit a claim online, we’ll review in 48 hours guaranteed and pay you via ACH or check.'
        }
      ]
    };

    return (
      <div className="how-it-works--steps">
        {STEPS[this.state.partial].map((step, index) => (
          <div className={`steps--step${index + 1} col-md-7 col-sm-12`} key={index}>
            <div className={`steps--background-number steps--background-number-${index + 1}`}>
              <h1>{index + 1}</h1>
            </div>
            <h1 className="steps--title medium-h1-size">{step.title}</h1>
            <p>{step.content}</p>
          </div>
        ))}
      </div>
    );
  };

  toggleRenterTypeButton = () => {
    const { partial } = this.state;
    this.setState({ partial: partial === 'newRenters' ? 'existingRenters' : 'newRenters' });
  };

  render() {
    const toggleNewRentersClass =
      this.state.partial === 'newRenters' ? 'toggle-button--active' : 'toggle-button--inactive';
    const toggleExistingRentersClass =
      this.state.partial === 'existingRenters' ? 'toggle-button--active' : 'toggle-button--inactive';

    return (
      <div>
        <div className="steps--toggle-container">
          <span className="toggle-container--rule"></span>
          <div className="toggle-button--container">
            <button
              id="toggleNewRenters"
              className={'toggle-container--toggle-button ' + toggleNewRentersClass}
              onClick={this.toggleRenterTypeButton}
            >
              For new renters
            </button>
            <button
              id="toggleExistingRenters"
              className={'toggle-container--toggle-button ' + toggleExistingRentersClass}
              onClick={this.toggleRenterTypeButton}
            >
              For existing renters
            </button>
          </div>
          <span className="toggle-container--rule"></span>
        </div>
        <div>{this.showGradeOptions()}</div>
      </div>
    );
  }
}

export default HowItWorksSteps;
