import React from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button as RhinoButton } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import Modal from './Modal';
import { CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';

// mobile
const Title = styled.h3([
  FONTS.h3,
  {
    textAlign: 'center',
    paddingBottom: '4px'
  }
]);

const SubTitle = styled.p([
  FONTS.p1Light,
  {
    color: PALETTE.neutral65,
    textAlign: 'center'
  }
]);

const Button = styled(RhinoButton)([
  FONTS.h5,
  { background: PALETTE.raptor, width: '169px', justifyContent: 'center' }
]);

const Container = styled.div({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  flexGrow: 1
});

const DivContainer = styled.div({
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

const CloseIconContainer = styled.div({
  justifyContent: 'right',
  textAlign: 'right',
  paddingBottom: '30px'
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer',
  textAlign: 'right'
});

const TopDiv = styled.div({
  margin: '0px'
});

interface CancelationModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const CanceledConfirmation = (props: CancelationModalProps) => {
  const isMobile = useCheckMobile(768);
  const { onClose, isOpen } = props;

  if (isMobile) {
    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <TopDiv>
          <CloseIconContainer>
            <CloseIcon onClick={onClose} data-cy="modalCancel" />
          </CloseIconContainer>
        </TopDiv>
        <Container>
          <Title data-cy="success"> Your renters insurance policy was canceled</Title>
          <SubTitle>You'll receive an email soon with details about your cancellation</SubTitle>
          <Button onClick={onClose} data-cy="okGotIt">
            {' '}
            OK, got it
          </Button>
        </Container>
      </Modal>
    );
  } else {
    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <TopDiv>
          <CloseIconContainer>
            <CloseIcon onClick={onClose} data-cy="modalCancel" />
          </CloseIconContainer>
        </TopDiv>
        <DivContainer>
          <Title data-cy="success"> Your renters insurance policy was canceled</Title>
          <SubTitle>You'll receive an email soon with details about your cancellation</SubTitle>
          <Button onClick={onClose} data-cy="okGotIt">
            {' '}
            OK, got it
          </Button>
        </DivContainer>
      </Modal>
    );
  }
};

export default CanceledConfirmation;
