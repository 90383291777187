import React, { useState, useEffect } from 'react';
import ActionCard from './ActionCard';
import InterestedPartyModal from '../../renters_insurance/components/InterestedPartyModal';
import InterestedPartyConfirmation from '../../renters_insurance/components/InterestedPartyConfirmation';
import CoverGeniusRedirect from '../../renters_insurance/components/CoverGeniusRedirect';
import CanceledConfirmationModal from '../../renters_insurance/components/CanceledConfirmation';
import CancelationModal from '../../renters_insurance/components/CancelationModal';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { InterestedRentersInsurancePolicyProps } from 'components/v2/renters_insurance/interfaces';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';

interface Props {
  isEligibleForIPModal: any;
  userId: number;
  partnerRequiresRenterInsurance: boolean;
  setShowIPActionCard: (showIPActionCard: boolean) => void;
}

const InterestedPartyCardProps = (street_address: string, partnerRequiresRenterInsurance: boolean) => {
  return {
    header: 'Add your property to your renters insurance policy',
    text: `${street_address} ${
      partnerRequiresRenterInsurance ? 'requires' : 'may require'
    } that you add them as an interested party to your renters insurance policy`,
    label: partnerRequiresRenterInsurance ? 'Lease Requirement' : 'Recommended',
    ctaText: 'Add now',
    destructive: false
  };
};

const InterestedPartyActionCard = (props: Props) => {
  const [showInterestedPartyModal, setShowInterestedPartyModal] = useState(false);
  const [showInterestedPartyConfirmation, setShowInterestedPartyConfirmation] = useState(false);
  const [interestedPartyError, setInterestedPartyError] = useState(false);
  const [selectedRI, setSelectedRI] = useState<InterestedRentersInsurancePolicyProps>();
  const [showCGInterstitial, setCGInterstitial] = useState(false);
  const [redirectAction, setRedirectAction] = useState(null);
  const [showCancelationModal, setCancelationModal] = useState(false);
  const [showCanceledConfirmationModal, setCanceledConfirmationModal] = useState(false);
  const [eligibleForIPModal, setEligibleForIPModal] = useState<any[]>(props.isEligibleForIPModal || []);

  const { pathname } = useLocation();
  const history = useHistory();
  const interestedPartyPath = pathname.includes(`/users/edit/add_interested_party${selectedRI?.ins_number}`);

  useEffect(() => {
    pathname.includes(`/users/edit/add_interested_party/${selectedRI?.ins_number}`)
      ? setShowInterestedPartyModal(true)
      : null;
  }, []);

  const onOpenInterestedPartyModal = (renter_ins) => {
    setSelectedRI(renter_ins);
    interestedPartyPath ? null : history.push(`/users/edit/add_interested_party/${renter_ins.ins_number}`);
    setShowInterestedPartyModal(true);
    onToggleFullscreenModal(true);
  };

  const closeInterestedPartyModal = () => {
    setShowInterestedPartyModal(false);
    history.push('/users/edit');
  };

  const completeInterestedPartyAdded = () => {
    closeInterestedPartyModal();
    setShowInterestedPartyConfirmation(true);
  };

  const closeInterestedPartyConfirmation = () => {
    setShowInterestedPartyConfirmation(false);
    setInterestedPartyError(false);
    if (!interestedPartyError) {
      const remainingEligibleRIsForIPModal = props.isEligibleForIPModal.filter(
        (ip) => ip.ins_number !== selectedRI?.ins_number
      );
      setEligibleForIPModal(remainingEligibleRIsForIPModal);
      if (remainingEligibleRIsForIPModal?.length === 0) props.setShowIPActionCard(false);
    }
  };

  const onClose = () => {
    setCGInterstitial(false);
    setRedirectAction(null);
    setShowInterestedPartyModal(false);
  };

  const onCancel = () => {
    setCancelationModal(false);
    setCanceledConfirmationModal(true);
  };

  return (
    <>
      {eligibleForIPModal.map((eligibleIns) => {
        const cardProps = InterestedPartyCardProps(eligibleIns.address, props.partnerRequiresRenterInsurance);
        return (
          <ActionCard
            key={eligibleIns.id}
            callToActionId="add-now"
            handleClick={() => onOpenInterestedPartyModal(eligibleIns)}
            {...cardProps}
          />
        );
      })}
      <Route exact path={`/users/edit/add_interested_party/${selectedRI?.ins_number}`}>
        <InterestedPartyModal
          isOpen={showInterestedPartyModal}
          onClose={() => closeInterestedPartyModal()}
          onComplete={() => completeInterestedPartyAdded()}
          insNumber={selectedRI?.ins_number || ''}
          setError={() => setInterestedPartyError(true)}
          interestedPartyAddress={''}
          interestedPartyEmail={''}
          interestedPartyName={''}
        />
      </Route>
      <InterestedPartyConfirmation
        isOpen={showInterestedPartyConfirmation}
        interestedPartyError={interestedPartyError}
        closeInterestedPartyConfirmation={() => closeInterestedPartyConfirmation()}
        updated={false}
      />
      <CoverGeniusRedirect
        isOpen={showCGInterstitial}
        onClose={onClose}
        redirectAction={redirectAction}
        claimUrl={selectedRI?.claim_url || ''}
        policyWordingUrl={selectedRI?.policy_wording_url || ''}
      />
      <CanceledConfirmationModal
        isOpen={showCanceledConfirmationModal}
        onClose={() => setCanceledConfirmationModal(false)}
      />
      <CancelationModal
        isOpen={showCancelationModal}
        onClose={() => setCancelationModal(false)}
        onCancel={onCancel}
        ins_number={selectedRI?.ins_number || ''}
      />
    </>
  );
};

export default InterestedPartyActionCard;
