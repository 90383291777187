import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { Component, ReactNode } from 'react';
import OwlCarousel, { Options } from 'react-owl-carousel';
import ImageWithSubheader from './imageWithSubheader';
import ImageWithTextItem from './imageWithTextItem';
import MediaItem from './mediaItem';
import NumberedImageWithTextItem from './numberedImageWithTextItem';
import QuoteItem from './quoteItem';
import TextItem from './textItem';
import ImageWithLink from './imageWithLink';
import TextWithAuthorItem from './textWithAuthorItem';
import { TestimonialCard } from './TestimonialCard';

export interface ICarouselProps {
  headers: string[];
  type:
    | 'text'
    | 'imageWithText'
    | 'numberedImageWithText'
    | 'imageWithSubheader'
    | 'media'
    | 'quote'
    | 'textWithAuthor'
    | 'imageWithLink'
    | 'residentialPageTestimonial';
  itemOptions: IItemOptions;
  carouselOptions: ICustomOptions;
}

interface IItemOptions {
  subheaders?: string[] | number[];
  content?: string[];
  links?: string[];
  images?: string[];
  footers?: string[];
  authors?: string[];
  alt_tags?: string[];
}

interface ICustomOptions {
  mouseDrag?: boolean;
  mobileCardOverflow?: boolean;
  loop?: boolean;
  nav?: boolean;
  rewind?: boolean;
  desktopCardOverflow?: boolean;
  responsive?: {};
}

interface IOptions {
  margin: number;
  touchDrag: boolean;
  mouseDrag: boolean;
  dotClass: string;
  navText: string[];
  navClass: string[];
}

class Carousel extends Component<ICarouselProps> {
  protected static defaultProps = {
    loop: true,
    nav: true,
    rewind: false
  };

  carouselRef: React.RefObject<OwlCarousel>;

  constructor(props: ICarouselProps) {
    super(props);

    this.carouselRef = React.createRef();
  }

  public options(): IOptions {
    return this.carouselOptions(this.props.carouselOptions);
  }

  public items(): ReactNode {
    const { itemOptions } = this.props;
    return this.props.headers.map((item, index: number) => {
      const defaultProps = {
        index,
        item,
        key: index,
        content: itemOptions.content && itemOptions.content[index],
        subheader: itemOptions.subheaders && itemOptions.subheaders[index],
        alt_tag: itemOptions.alt_tags && itemOptions.alt_tags[index]
      };

      if (this.props.type === 'text') {
        return <TextItem {...defaultProps} />;
      } else if (this.props.type === 'imageWithText') {
        return <ImageWithTextItem {...defaultProps} />;
      } else if (this.props.type === 'numberedImageWithText') {
        return <NumberedImageWithTextItem {...defaultProps} />;
      } else if (this.props.type === 'imageWithSubheader') {
        return <ImageWithSubheader {...defaultProps} />;
      } else if (this.props.type === 'media') {
        return (
          <MediaItem
            {...defaultProps}
            subheader={itemOptions.subheaders && itemOptions.subheaders[0].toString()}
            link={itemOptions.links ? itemOptions.links[index] : undefined}
          />
        );
      } else if (this.props.type === 'quote') {
        return (
          <QuoteItem
            {...defaultProps}
            footers={itemOptions.footers ? itemOptions.footers : []}
            images={itemOptions.images ? itemOptions.images : []}
          />
        );
      } else if (this.props.type === 'textWithAuthor') {
        return (
          <TextWithAuthorItem
            {...defaultProps}
            author={itemOptions.authors ? itemOptions.authors[index] : undefined}
            link={itemOptions.links ? itemOptions.links[index] : undefined}
          />
        );
      } else if (this.props.type === 'imageWithLink') {
        return (
          <ImageWithLink
            {...defaultProps}
            title={itemOptions.content ? itemOptions.content[index] : undefined}
            link={itemOptions.links ? itemOptions.links[index] : undefined}
          />
        );
      } else if (this.props.type === 'residentialPageTestimonial') {
        return (
          <TestimonialCard
            {...defaultProps}
            footers={itemOptions.footers ? itemOptions.footers : []}
            images={itemOptions.images ? itemOptions.images : []}
          />
        );
      }
    });
  }

  public componentDidMount() {
    this.makeOwlCarouselA11yCompliant();
  }

  public makeOwlCarouselA11yCompliant() {
    // Owl carousel is using <button> element for dots navigation
    // https://dequeuniversity.com/rules/axe/4.1/button-name?application=axeAPI
    setTimeout(() =>
      this.carouselRef.current?.$ele
        .find('.rhino-carousel-dot')
        .each((i: number, el: HTMLElement) => el.setAttribute('aria-label', `Page ${i + 1}`))
    );
  }

  public carouselOptions(customOptions: ICustomOptions): IOptions {
    const defaults = {
      margin: 0,
      nav: customOptions.nav,
      loop: customOptions.loop,
      responsive: customOptions.responsive,
      touchDrag: true,
      autoWidth: true,
      rewind: customOptions.rewind,
      dotClass: 'rhino-carousel-dot',
      navText: ['&#8592;', '&#8594;'],
      navClass: ['rhino-carousel-nav--left', 'rhino-carousel-nav--right']
    };

    let mouseDrag = true;

    if (typeof customOptions.mouseDrag !== 'undefined') {
      mouseDrag = customOptions.mouseDrag;
    }

    return {
      mouseDrag,
      ...defaults
    };
  }

  public render() {
    return (
      <div>
        <OwlCarousel ref={this.carouselRef} className="owl-theme" {...this.options()}>
          {this.items()}
        </OwlCarousel>
      </div>
    );
  }
}

export default Carousel;
