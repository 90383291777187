import styled from '@emotion/styled';
import { FONTS, PALETTE, ROUNDNESS, TextInput as TextInputBase } from '@sayrhino/rhino-shared-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

export const inputOptions = {
  style: {
    base: {
      color: '#000000',
      fontSize: '16px',
      fontWeight: '300',
      '::placeholder': {
        color: '#595959'
      }
    },
    invalid: {
      color: '#A72131',
      fontWeight: '300'
    }
  }
};

export const PaymentInputContainer = styled.div({
  position: 'relative',
  width: '100%',
  paddingRight: '15px',
  paddingLeft: '15px',
  paddingBottom: '20px'
});

export const PaymentLabel = styled.label([
  FONTS.p3Medium,
  {
    fontSize: '12px',
    paddingBottom: '4px',
    lineHeight: '20px',
    margin: '0px;'
  }
]);

export const StyledCardNumber = styled(CardNumberElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

export const StyledCardCvc = styled(CardCvcElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

export const StyledCardExp = styled(CardExpiryElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '100%',
  display: 'block',
  '@media (min-width: 768px)': {
    width: 'initial'
  }
});

export const ZipCodeInput = styled.input`
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  border: 1px solid ${PALETTE.neutral25};
  padding: 19.3px 0px 19.3px 5px;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  height: 1.2em;
  line-height: 1.2em;
  &:focus {
    outline: none;
  }
`;

export const View = styled.div(() => {
  const isMobile = useCheckMobile();
  return [
    ROUNDNESS.m,
    {
      width: !isMobile ? '608px' : '100vw',
      height: '100%',
      padding: !isMobile ? '0 64px' : '16px',
      margin: isMobile ? '0' : '50px auto'
    }
  ];
});

export const Header = styled.h3([FONTS.h3, {}]);

export const ClaimInfoDiv = styled.div({
  backgroundColor: PALETTE.neutral4,
  padding: '0 16px',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '8px',
  margin: '24px 0px 0px 0px'
});

export const ListItem = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  padding: '5px 0',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '32px',
  gap: '10px',
  '&:last-of-type': {
    border: 'none'
  }
});

export const ListLabel = styled.p(() => {
  const isMobile = useCheckMobile();
  return [
    isMobile ? FONTS.p3Light : FONTS.p3Medium,
    { color: PALETTE.neutral88, width: !isMobile ? '23%' : '30%', margin: 0 }
  ];
});

export const ListValue = styled.p(() => {
  const isMobile = useCheckMobile();
  return [isMobile ? FONTS.p2Medium : FONTS.p2Light, { color: PALETTE.neutral88, fontSize: '14px', margin: 0 }];
});

export const SubTitle = styled.h5([FONTS.h5, { color: PALETTE.neutral88, fontSize: '16px', margin: '0' }]);

export const PaymentFieldView = styled.div(() => {
  return { margin: '16px 0' };
});

export const SpaceBetween = styled.div(() => {
  const isMobile = useCheckMobile();
  return {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: isMobile ? '16px' : '0'
  };
});

export const OptionComponent = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  padding: '5px 0',
  margin: '0px 10px',
  '&:last-of-type': {
    border: 'none'
  }
});

export const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '16px',
  margin: '48px 0'
});

export const Margin = styled.div({
  margin: '10px 0'
});

export const CardNumber = styled(CardNumberElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px'
});

export const CardCvc = styled(CardCvcElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '143.5px'
});

export const CardExp = styled(CardExpiryElement)({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '10px 5px',
  fontSize: '16px',
  width: '161px'
});

export const Label = styled.label([
  FONTS.labelMedium,
  {
    fontSize: '13px',
    color: PALETTE.neutral65
  }
]);

export const ErrorContainer = styled.div([
  ROUNDNESS.s,
  {
    padding: '12px 8px',
    backgroundColor: PALETTE.alert4,
    marginBottom: '24px'
  }
]);

export const ErrorList = styled.ul({
  margin: 0
});

export const ErrorItem = styled.li([
  FONTS.p3Medium,
  {
    color: PALETTE.alert,
    margin: 0
  }
]);

export const ConfirmationClaimDiv = styled.div({
  backgroundColor: PALETTE.neutral4,
  padding: '8px 14px',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '8px',
  margin: '26px 0px 40px'
});

export const ClaimItem = styled.div(() => {
  const isMobile = useCheckMobile();
  return [
    {
      borderBottom: `1px solid ${PALETTE.neutral12}`,
      display: 'flex',
      flexDirection: 'row',
      maxHeight: !isMobile ? '32px' : '52px',
      padding: '4px 0px 0px 0px',
      width: '100%',
      '&:last-of-type': {
        border: 'none'
      }
    }
  ];
});

export const ClaimLabel = styled.p(() => {
  const isMobile = useCheckMobile();
  return [FONTS.p2Light, { color: PALETTE.neutral88, width: !isMobile ? '38%' : '40%', margin: 0 }];
});

export const ClaimValue = styled.p([FONTS.p3Medium, { color: PALETTE.neutral88, fontSize: '14px', margin: 0 }]);

export const StyledH3 = styled.h3([FONTS.h3, PALETTE.neutralDark], { marginBottom: 0, marginTop: '100px' });

export const StyledP2 = styled.p([FONTS.p2Medium, PALETTE.neutral65], { marginBottom: 0, marginTop: '8px' });

export const StyledLightP2 = styled.p([FONTS.label, PALETTE.neutral65], { marginBottom: '40px', marginTop: '8px' });
export const StyledP3 = styled.p([FONTS.p2Light, PALETTE.neutral65], {
  marginBottom: 0,
  fontSize: '12px',
  lineHeight: '20px'
});

export const GotItButtonContainer = styled.div({
  justifyContent: 'center',
  display: 'flex'
});

export const TitleContainer = styled.div({
  textAlign: 'center'
});

export const StyledMainContainer = styled.div`
  background: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledModal = styled.div({
  width: '580px',
  height: '100%'
});

export const StyledDiv = styled.div({
  minHeight: '100%',
  minWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem 0 2rem 4rem'
});

export const ButtonDiv = styled.div({
  float: 'right'
});
export const ProcessingFee = styled.p(() => {
  const isMobile = useCheckMobile();
  return [
    FONTS.labelLight,
    {
      lineHeight: '16px'
    },
    isMobile && { marginTop: '30px' }
  ];
});

export const TextInput = styled(TextInputBase)(({ error }) => {
  const isMobile = useCheckMobile();
  return (
    isMobile && {
      width: '100%',
      height: '82px'
    }
  );
});

export const ModalControl = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: '30px 30px 0px 0px'
});
