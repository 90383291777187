import React from 'react';

interface IBackdropProps {
  close: () => void;
}

const Backdrop: React.FC<IBackdropProps> = (props: IBackdropProps) => {
  return (
    <div className="backdrop" onClick={props.close}/>
  );
};

export default Backdrop;
