/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import {
  ArrowIcon,
  Button,
  CloseButton,
  RadioOption,
  RhinoLogo,
  FONTS,
  FONT_FAMILY,
  Loading,
  Datepicker,
  CalendarIcon,
  PALETTE,
  TextInput
} from '@sayrhino/rhino-shared-js';
import useCancellationReasons from './useCancallationReasons';
import { ICancellationRequestParams } from './policyProps';
import useCreateCancellationRequestMutation from './createCancellationRequest';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { differenceInDays, startOfToday } from 'date-fns';
import styled from '@emotion/styled';

enum Reasons {
  DID_NOT_SIGN_LEASE = 0,
  DID_NOT_RENEW = 1,
  ROOMMATE_SIGNED_UP = 2,
  DUPLICATE_POLICY = 3,
  PAID_CASH_DEPOSIT = 4,
  OTHER = 5,
  MOVED_OUT = 6
}

interface IProps {
  onCloseModal: () => void;
  policyId: string | number;
  userId: number;
  insuranceApplicationId: number;
  leaseStartDate: string;
  leaseEndDate: string;
  policyAppId: number;
  cancellationLookbackDays: number;
  coverageStartDate: string;
}

const fieldStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: '1px solid black',
  input: [
    FONTS.p1,
    {
      border: 'none',
      width: '75%',
      background: 'transparent'
    }
  ]
});
const FormField = (props) => <div css={fieldStyles} {...props} />;
const errorCSS = css([
  {
    color: 'red',
    marginLeft: '40px'
  },
  FONTS.p3
]);

export const CancellationRequestModal = (props: IProps) => {
  const {
    userId,
    policyId,
    leaseStartDate,
    leaseEndDate,
    insuranceApplicationId,
    policyAppId,
    cancellationLookbackDays,
    coverageStartDate
  } = props;
  const today = new Date();
  const { isLoading, data: cancellationReasons } = useCancellationReasons();
  const { mutate: createCancellationRequest, isSuccess } = useCreateCancellationRequestMutation();
  const [errors, setErrors] = useState<string[] | undefined>();
  const [reasonSelected, updateReasonSelected] = useState<number>();
  const [roommateEmail, setRoommateEmail] = useState<string | undefined>();
  const [other, setOther] = useState<string | undefined>();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const options = Array();

  useEffect(() => {
    setErrors([]);
    checkIfFormValid();
  }, [reasonSelected, roommateEmail, other]);

  useEffect(() => {
    setRoommateEmail(undefined);
    setOther(undefined);
  }, [reasonSelected]);

  const stringifyDate = (date: Date): string => format(date, 'LLL d, u');

  const checkIfFormValid = () => {
    if (reasonSelected === Reasons.ROOMMATE_SIGNED_UP) {
      roommateEmail ? setIsFormValid(true) : setIsFormValid(false);
    } else if (reasonSelected === Reasons.OTHER) {
      other ? setIsFormValid(true) : setIsFormValid(false);
    } else if (reasonSelected !== undefined) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleSubmit = async () => {
    const requestData: ICancellationRequestParams = {
      insurance_policy_id: policyId,
      reason: reasonSelected,
      requester_id: userId
    };
    if (reasonSelected === Reasons.ROOMMATE_SIGNED_UP) {
      requestData.roommate_email = roommateEmail;
    }
    if (reasonSelected === Reasons.OTHER) {
      requestData.other_reason = other;
    }
    if (reasonSelected === Reasons.MOVED_OUT) {
      requestData.terminated_at = today.toISOString();
    }

    createCancellationRequest(
      { ...requestData },
      {
        // @ts-ignore
        onError: (error: AxiosError) => setErrors(error.response?.data.errors)
      }
    );
  };

  const updateReason = (selectedVal, label) => {
    updateReasonSelected(parseInt(selectedVal.target.value, 10));
  };

  const renderOptions = (value: number): JSX.Element | undefined => {
    switch (value) {
      case Reasons.ROOMMATE_SIGNED_UP:
        return generateOption('roomate email', `Roommate's email address`, 'roommateEmail', setRoommateEmail);
      case Reasons.OTHER:
        return generateOption('other', 'Reason for cancellation', 'other', setOther);
    }
  };

  const Label = styled.label([FONTS.p3Medium, { color: PALETTE.neutral65 }]);

  const generateOption = (name: string, label: string, id: string, setField): JSX.Element => {
    return (
      <div>
        <TextInput
          style={{ marginLeft: '40px', marginTop: '-25px' }}
          label={label}
          name={name}
          id={id}
          onChange={(event) => setField(event.target.value)}
        />
      </div>
    );
  };

  const convertedStartDate = Date.parse(coverageStartDate);
  const dateToday = startOfToday().getTime();
  const daysSinceCoverageStart = differenceInDays(dateToday, convertedStartDate);

  const filteredCancelledReasons =
    daysSinceCoverageStart >= 30
      ? cancellationReasons?.filter((reason) => reason.value === Reasons.OTHER || reason.value === Reasons.MOVED_OUT)
      : cancellationReasons?.filter((reason) => reason.value !== Reasons.OTHER);

  return isSuccess ? (
    <div
      css={{
        padding: '24px 38px 0 107px',
        '@media (max-width: 425px)': {
          padding: '24px 38px'
        }
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <RhinoLogo />
        <CloseButton
          onClick={props.onCloseModal}
          style={{ color: PALETTE.neutral25, border: `1px solid ${PALETTE.neutral25}`, background: 'transparent' }}
        />
      </div>
      <div css={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div css={{ maxWidth: 480, marginTop: 50 }}>
          <p css={[{ fontSize: 44, lineHeight: '56px', textAlign: 'center' }, FONT_FAMILY.sectraBold]}>
            Your cancellation request is being processed
          </p>
          <p css={[{ fontSize: 18, lineHeight: '32px', textAlign: 'center' }]}>
            Your property manager will have {cancellationLookbackDays || 7} business days to respond to this request. If
            we don't hear from them within that timeframe, your policy will be canceled automatically.
          </p>
        </div>
        <Button onClick={props.onCloseModal} variant="secondary">
          Back to policy details <ArrowIcon style={{ paddingLeft: 10 }} />
        </Button>
      </div>
    </div>
  ) : (
    <div
      css={{
        padding: '24px 38px 0 107px',
        '@media (max-width: 425px)': {
          padding: '24px 38px'
        }
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <RhinoLogo />
        <CloseButton
          onClick={props.onCloseModal}
          style={{ color: PALETTE.neutral25, border: `1px solid ${PALETTE.neutral25}`, background: 'transparent' }}
        />
      </div>
      <div css={{ maxWidth: 480, marginTop: 50 }}>
        <p css={[{ fontSize: 44, lineHeight: '56px' }, FONT_FAMILY.sectraBold]}>We're sorry to see you go</p>
        <p css={[FONTS.p1, { marginTop: 28 }]}>Please tell us why you're canceling your Rhino policy</p>
        {!isLoading ? (
          <div
            css={css`
              [type='radio'] + label::before {
                background: white !important;
              }
            `}
          >
            {filteredCancelledReasons?.length &&
              filteredCancelledReasons.forEach((reason) => {
                const { value, label } = reason;
                options.push(
                  <RadioOption
                    key={label}
                    id={`radio-radio-group-option-${value}`}
                    value={value}
                    label={label}
                    onChange={(selectedVal) => updateReason(selectedVal, label)}
                    selected={reasonSelected}
                    name="radio-group"
                  />
                );
                if (value === reasonSelected) {
                  options.push(renderOptions(value));
                  if (errors) options.push(<p css={errorCSS}>{errors}</p>);
                }
              })}
            {options}
          </div>
        ) : (
          <Loading />
        )}
        <Button
          onClick={handleSubmit}
          children="Submit cancellation request"
          variant="secondary"
          disabled={!isFormValid}
        />
      </div>
    </div>
  );
};

export default CancellationRequestModal;
