import { useState, useEffect } from 'react';
const { throttle } = require('lodash');
const DEFAULT_LG_BREAK = 992;

/**
 * A react hook for detecting desktop/mobile devices
 * @returns boolean, true if device is in mobile size
 */
export function useCheckMobile(breakpoint: number = DEFAULT_LG_BREAK) {
  const windowRef = typeof window !== 'undefined' ? window : undefined;
  const [isMobile, setIsMobile] = useState(checkIsMobile());

  function checkIsMobile() {
    if (!windowRef) {
      return;
    }
    return windowRef.innerWidth < breakpoint;
  }

  const onResizeListener = () => {
    setIsMobile(checkIsMobile());
  };

  const throttledOnResizeListener = throttle(onResizeListener);

  useEffect(function addResizeListener() {
    windowRef?.addEventListener('resize', throttledOnResizeListener);

    return function removeResizeListener() {
      windowRef?.removeEventListener('resize', throttledOnResizeListener);
    };
  }, []);

  return isMobile;
}
