import React from 'react';

import './styles/datepicker.scss';
import { isMobileDevice } from 'utils/mobile';

import type { IBaseProps } from './types';

import MobileDatePicker from './mobile';
import DesktopDatePicker from './desktop';

const DatePickerInput = (props: IBaseProps & any) => (
  <div style={{ display: props.hidden ? 'none' : 'block' }}>
    {isMobileDevice() ? <MobileDatePicker {...props} /> : <DesktopDatePicker {...props} />}
  </div>
);

export default DatePickerInput;
