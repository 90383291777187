import Link from 'components/v2/link/index';
import React, { Component } from 'react';
import { ActionCenterLink } from '../link/ActionCenterLink';
import { NavbarWrapper } from './styles';
import { Dropdown, DropdownHeader, DropdownLink, DropdownWrapper, HiUser } from './actionCenterAccountDropdown';
// @ts-ignore
import accountIcon from './accountIcon.svg';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

interface IProps {
  contact_us_path: string;
  destroy_user_session_path: string;
  display_user_first_name: boolean;
  edit_account_path: string;
  edit_payment_information_path: string;
  sessionId: string;
  show_your_account: boolean;
  userId: number;
}

interface IState {
  displayDropdown: boolean;
}

class ActionCenterNavBar extends Component<IProps, IState> {
  static contextType = AnalyticsMetadataContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      displayDropdown: false
    };
  }

  public render(): JSX.Element {
    const { edit_payment_information_path } = this.props;
    const analyticsMetadata = { userId: this.props.userId, sessionId: this.props.sessionId };

    return (
      <div className="nav-dropdown--items-signed-in">
        <div className="header--right-links">
          <NavbarWrapper>
            <ActionCenterLink
              // update to overview_path when route is created
              nextPath={'/users/edit'}
              content="Overview"
            />
            <ActionCenterLink
              nextPath={'/policies'}
              content="My Policies"
            />
            <ActionCenterLink
              nextPath={edit_payment_information_path}
              content="Billing & Payments"
            />
          </NavbarWrapper>
          {this.renderActionLink()}
        </div>
      </div>
    );
  }

  private renderActionLink = (): JSX.Element => {
    const {
      display_user_first_name,
      destroy_user_session_path,
      show_your_account,
      edit_account_path,
      contact_us_path
    } = this.props;

    if (show_your_account) {
      return (
        <NavbarWrapper>
          <HiUser
            onMouseEnter={() => this.setState({ displayDropdown: true })}
            onMouseLeave={() => this.setState({ displayDropdown: false })}
          >
            Hi, {display_user_first_name || 'anonymous'} &nbsp;
            <img src={accountIcon} />
            {this.state.displayDropdown && (
              <DropdownWrapper
                onMouseEnter={() => this.setState({ displayDropdown: true })}
                onMouseLeave={() => this.setState({ displayDropdown: false })}
              >
                <Dropdown>
                  <DropdownHeader>Your account</DropdownHeader>
                  <DropdownLink
                    nextPath={edit_account_path}
                    content="Settings"
                    dataCy="account"
                  />
                  <DropdownLink
                    content="Help Center"
                    dataCy="helpCenter"
                    handleClick={() => {
                      window.open(`https://support.sayrhino.com/hc/en-us`);
                    }}
                    target="_blank"
                  />
                  <DropdownLink
                    nextPath={contact_us_path}
                    content="Contact Us"
                    dataCy="contactUs"
                  />
                  <DropdownLink
                    nextPath={destroy_user_session_path}
                    content="Sign Out"
                    dataCy="signOut"
                    dataMethod={'delete'}
                  />{' '}
                </Dropdown>
              </DropdownWrapper>
            )}
          </HiUser>
        </NavbarWrapper>
      );
    } else {
      return (
        <Link
          nextPath={destroy_user_session_path}
          className="nav--text-link"
          content="Log Out"
          dataMethod={'delete'}
          dataCy="logOut"
        />
      );
    }
  };
}

export default ActionCenterNavBar;
