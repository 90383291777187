export const getColorProperties = (status?: string) => {
  switch (status) {
    case 'active':
      return {
        color: '#315BF1',
        backgroundColor: '#F1F4FE',
        borderColor: '#315BF1'
      };
    case 'pending_check':
      return {
        color: '#DB3700',
        backgroundColor: '#FFECE5',
        borderColor: '#FFECE5'
      };
    default:
      return {
        color: '#5A6468',
        backgroundColor: '#F5F6F7',
        borderColor: '#F5F6F7'
      };
  }
};
