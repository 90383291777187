import React, { useContext } from 'react';
import { ProductModule } from './types/ProductModule';
import { ButtonHabitat, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { Spacer72pxDesktop52pxMobile } from './Spacers';
import UserContext from '../layout/account/user-context';

const FeaturesModuleHeader = styled.h2(FONTS.brandH2Bold, {
  fontSize: '44px',
  lineHeight: '56px',
  textAlign: 'center'
});

const FeaturesModuleContainer = styled.section`
  background: linear-gradient(0deg, #ece5ff 0%, rgba(248, 248, 254, 0) 100%);
`;

const FeaturesModuleGradientOverlay = styled.div`
  background: linear-gradient(108deg, rgba(255, 255, 255, 0%) 40%, white 0%);
`;

const ChecklistItemHeader = styled.h3(FONTS.h3, {
  margin: '0px',

  '@media (max-width: 991px)': {
    fontSize: '14px',
    lineHeight: '20px'
  }
});

const ChecklistSectionItems = styled.div({
  '> *:not(:last-child)': {
    borderBottom: '1px solid #e3e3e3'
  }
});

const ChecklistSectionItem = styled.div({
  paddingTop: '1rem',
  paddingBottom: '1rem',
  gap: '24px'
});

const FeaturesModuleImage = styled.img({
  width: '100%',
  maxWidth: '220px',

  '@media (max-width: 991px)': {
    marginBottom: '50px'
  }
});

const CtaButton = styled(ButtonHabitat)`
  background: ${PALETTE.brand100} url(${require('../../../../assets/images/v2_landing_page/arrow.svg')}) no-repeat
    bottom 50% right 20px;
  width: auto;
`;

export const FeaturesModule: React.FC<ProductModule> = (props) => {
  const { cta, ctaLink, groupedContent, header, images } = props;
  const { currentUser } = useContext(UserContext);

  return (
    <FeaturesModuleContainer>
      <FeaturesModuleGradientOverlay>
        <div className="container d-flex flex-column align-items-center">
          <Spacer72pxDesktop52pxMobile />
          <FeaturesModuleHeader>{header}</FeaturesModuleHeader>
          <Spacer72pxDesktop52pxMobile />

          <div className="row flex-lg-row d-flex flex-column">
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
              {images && images[0] && <FeaturesModuleImage src={images[0].url} alt={images[0].description} />}
            </div>
            <ChecklistSectionItems className="col-lg-6">
              {groupedContent &&
                groupedContent.map((item) => {
                  return (
                    <ChecklistSectionItem className="d-flex flex-row align-items-center">
                      <img src={item.asset?.url} alt={item.asset?.description} />
                      <div>
                        <ChecklistItemHeader>{item.header}</ChecklistItemHeader>
                      </div>
                    </ChecklistSectionItem>
                  );
                })}
            </ChecklistSectionItems>
          </div>
          <Spacer72pxDesktop52pxMobile />
          <div className="d-flex justify-content-start justify-content-md-center w-100">
            <CtaButton
              variant="primary"
              type="button"
              id="residential-features-cta-button"
              style={{ paddingRight: '68px' }}
              onClick={() => {
                const ctaForm = document.getElementById('become-rhino-partner');

                ctaForm &&
                  ctaForm.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
              }}
            >
              {cta}
            </CtaButton>
          </div>
          <Spacer72pxDesktop52pxMobile />
        </div>
      </FeaturesModuleGradientOverlay>
    </FeaturesModuleContainer>
  );
};
