import styled from '@emotion/styled';
import { AlertIcon, FONTS, PALETTE, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import React from 'react';

const Wrapper = styled.div([
  FONTS.p2Medium,
  ROUNDNESS.s,
  {
    background: PALETTE.alert125,
    padding: 8,
    color: PALETTE.neutralLight,
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8
  }
]);

const StyledAlertIcon = styled(AlertIcon)({
  color: PALETTE.neutralLight,
  marginRight: 8,
  fontWeight: 500
});

const TextWrapper = styled.div([
  FONTS.p2Medium,
  {
    flexGrow: 1,
    color: PALETTE.neutralLight,
    fontWeight: 500
  }
]);

export const FailedPaymentAlert = () => {
  return (
    <Wrapper>
      <StyledAlertIcon />
      <TextWrapper>Payment method failed. Update your payment method.</TextWrapper>
    </Wrapper>
  );
};
