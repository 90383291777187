export type Nullable<T> = T | null | undefined;

export const isEmptyString = (elem: unknown): boolean => {
  if (typeof elem === 'string') {
    return elem === '';
  }
  return elem === null;
};

const capitalize = (s: string): string | null => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const validateRequiredField = (
  value: Nullable<string | number | boolean | object>,
  fieldCaption: string
): string | null => {
  if (isEmptyString(value) || !value?.toString().length || value === false) {
    return `${capitalize(fieldCaption)} required.`;
  }

  return null;
};

export const isValidEmail = (email: Nullable<string>): boolean => {
  if (!email) {
    return false;
  }
  if (!/^['A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return false;
  }

  return true;
};

export const validateEmail = (email: Nullable<string>): string | null => {
  if (!email || !email.length) {
    return 'Email required.';
  }
  if (!isValidEmail(email)) {
    return 'Enter a valid email address.';
  }

  return null;
};
