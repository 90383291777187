import React, { SyntheticEvent } from 'react';

import Submit from 'components/common/form/submit';
import { BackButton, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import RadioForm from 'components/common/radio_form';
import AssetsOptions from '../constants/assets_options';

function Assets(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, ['assets_range']);

  const updateAssets = (update: { assets_range?: number }) => {
    updatePolicyApplication(Object.assign({}, policyApplication, update));
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    if (policyApplicationId && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  return (
    <div>
      <h2 className="bold header" data-cy="savingsHeader">
        How much do you have in your checking and savings account?
      </h2>
      <p className="p2" data-cy="savingsDescription">
        Try to be as accurate as you can. This helps us make sure your Rhino coverage is as personalized as possible.
      </p>
      <form className="sign-up__form" id="asset-form">
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              <RadioForm
                field="assets_range"
                options={AssetsOptions}
                selected={policyApplication?.assets_range}
                hideActions={true}
                onChange={(assets_range) => updateAssets({ assets_range })}
                holderClass=""
              />
            </div>
            <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
              <BackButton step={props.step} />
              <Submit value="Continue" data-cy="continue" className="btn-default continue_button" onClick={onSubmit} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '0px',
    marginBottom: '60px'
  }
};

export default Assets;
