// @flow
import React, { Component } from 'react';

import { formatMoney } from 'utils/money';

interface IProps {
  label: string;
  value?: number;
  options: number[];
  onChange(price: number): void;
}

interface IState {
  value: number;
  valueIndex: number;
}

class PriceToggle extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const value = props.value || props.options[0];
    const valueIndex = props.options.indexOf(value);

    this.state = { value, valueIndex };
  }

  public componentWillReceiveProps = (nextProps: IProps) => {
    const value = nextProps.value || nextProps.options[0];
    const valueIndex = nextProps.options.indexOf(value);
    this.setState({ value, valueIndex });
  };

  public up = () => {
    if (!this.hasNext()) {
      return;
    }

    this.step(1);
  };

  public down = () => {
    if (!this.hasPrev()) {
      return;
    }

    this.step(-1);
  };

  public step = (where: 1 | -1) => {
    const { options, onChange } = this.props;
    const valueIndex = this.state.valueIndex + where;
    const value = options[valueIndex];

    this.setState({ value, valueIndex });
    onChange(value);
  };

  public hasNext = (): boolean => {
    const { options } = this.props;
    const { valueIndex } = this.state;

    return valueIndex < options.length - 1;
  };

  public hasPrev = (): boolean => {
    return this.state.valueIndex > 0;
  };

  public render(): JSX.Element {
    const priceFormatted = formatMoney(this.state.value, { precision: 0 }).maskedValue;

    return (
      <div className="price-toggle">
        <div style={styles.label}>{this.props.label}</div>
        <div className="row">
          <div className="col-6">
            <h3 className="medium">{priceFormatted}</h3>
          </div>
          <div className="col-6" style={styles.buttonsContainer}>
            <div style={styles.buttonCircle}>
              <a className={'down ' + (!this.hasPrev() ? 'disabled' : '')} style={styles.buttons} onClick={this.down}>
                &#8722;
              </a>
            </div>
            <div style={styles.buttonCircle}>
              <a className={'up ' + (!this.hasNext() ? 'disabled' : '')} style={styles.buttons} onClick={this.up}>
                &#43;
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  label: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 0'
  },
  buttonsContainer: {
    textAlign: 'center' as 'center',
    display: 'inline-flex',
    justifyContent: 'flex-end'
  },
  buttonCircle: {
    border: '1px solid #6318CE',
    borderRadius: '14px',
    margin: '0 12px',
    width: '28px',
    height: '28px',
    alignSelf: 'center',
    cursor: 'pointer'
  },
  buttons: {
    color: '#6318CE',
    fontSize: '30px'
  }
};

export default PriceToggle;
