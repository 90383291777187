import React from 'react';
import { Helmet } from 'react-helmet';
import env from 'utils/env';

class ZendeskSupportWidget extends React.PureComponent {
  render () {
    const isMobile = (window as any).innerWidth < 800;
    const isEnabled = (window as any).App?.featureFlags?.signUp?.zendeskChatEnabled;

    if (isEnabled && !isMobile && (window as any).zE === undefined) {
      return <Helmet>
          <script id="ze-snippet" src={env('ZENDESK_SCRIPT_URL')} />
        </Helmet>
    } else { return null }
  }
};

export default ZendeskSupportWidget;
