import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, ButtonHabitat as ButtonBase } from '@sayrhino/rhino-shared-js';
export interface IProps {
  header: string;
  text: string | React.ReactNode;
  handleClick: () => void;
  ctaText: string;
  label: string | React.ReactNode;
  destructive: boolean;
  autoRenew?: boolean;
  callToActionId: string;
  cancelRenewal?: () => void;
  disableButton?: boolean;
}

const ActionCard = (props: IProps) => {
  const {
    autoRenew,
    callToActionId,
    cancelRenewal,
    ctaText,
    destructive,
    disableButton,
    handleClick,
    header,
    label,
    text
  } = props;

  const Card = styled.div({
    backgroundColor: '#FFFFFF',
    border: `1px solid ${PALETTE.neutral12}`,
    borderRadius: '8px',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    marginTop: '8px',
    marginBottom: '16px',
    // desktop
    '@media (max-width: 992px)': {
      maxWidth: 'none',
      width: '100%'
    }
  });
  const Divider = styled.hr({
    border: 'none',
    height: 1,
    margin: 0,
    marginTop: '.5rem',
    flexShrink: 0,
    marginBottom: '.5rem',
    backgroundColor: PALETTE.neutral12,
    width: '100%'
  });
  const CTAContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 16px 4px, 16px',
    width: '100%',
    justifyContent: 'space-between'
  });
  const CTAButton = styled(ButtonBase)({
    '&&': {
      margin: '6px 16px 14px 16px',
      display: 'inline-block'
    }
  });

  const Header = styled.h6([FONTS.h6Demi, { margin: '20px 16px 0px 16px' }]);
  const Text = styled.p([FONTS.p2Medium, { margin: '0px 16px 4px 16px', color: PALETTE.neutral55 }]);
  const Label = styled.label([
    FONTS.labelMedium,
    {
      padding: '4px',
      borderRadius: '4px',
      margin: '10px 16px 14px 16px'
    }
  ]);
  const CancelStyle = styled.div([
    FONTS.h6Demi,
    { cursor: 'pointer', color: PALETTE.alert125, display: 'inline-block' }
  ]);

  const needsAttentionCTA = (styles: object) => {
    return (
      <CTAContainer>
        <div>
          <Label style={styles}>{label}</Label>
        </div>
        <CTAButton
          disabled={disableButton}
          id={callToActionId}
          onClick={handleClick}
          size="small"
          usage="primary"
          variant="destructive"
        >
          {ctaText}
        </CTAButton>
      </CTAContainer>
    );
  };

  const nextStepsCTA = (styles: object) => {
    return (
      <CTAContainer>
        <div>
          <Label style={styles}>{label}</Label>
        </div>
        <div>
          {autoRenew && <CancelStyle onClick={cancelRenewal}>Cancel</CancelStyle>}
          <CTAButton
            disabled={false}
            id={`next-step-${callToActionId}`}
            onClick={handleClick}
            size="small"
            usage="primary"
            variant="primary"
          >
            {ctaText}
          </CTAButton>
        </div>
      </CTAContainer>
    );
  };

  return (
    <Card>
      <Header>{header}</Header>
      <Text>{text}</Text>
      <Divider />
      {destructive
        ? needsAttentionCTA({
            color: PALETTE.alert125,
            backgroundColor: PALETTE.alert4
          })
        : nextStepsCTA({
            color: PALETTE.brand125,
            backgroundColor: PALETTE.brand4,
            cursor: null
          })}
    </Card>
  );
};

export default ActionCard;
