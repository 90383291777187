import React, { useState } from 'react';
import { connect } from 'react-redux';

import Input from 'components/v2/input';
import Button from 'components/v2/button';
import { renderError, useSubsetState } from 'utils/redux_helpers';

import { put, post, destroy } from 'utils/request';
import { csrfToken } from 'utils/document';

import { addRoommate, renewRoommate, removeRoommate } from '../actions';
import { IPolicy, IRoommate } from '../policyProps';

interface IErrors {
  email?: string[];
  phone?: string[];
  full_name?: string[];
}

interface IProps {
  index: number;
  numeration: number;
  roommate?: IRoommate;
  insurancePolicy: IPolicy;
  onSubmit: () => void;
  removeRoommate: (id: number) => void;
  addRoommate: (roomate: IRoommate) => void;
  renewRoommate: (roommate: IRoommate) => void;
}

const RoommateForm: React.FC<IProps> = (props: IProps) => {
  const authenticity_token = csrfToken();
  const [submitted, setSubmitted] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [errors, setErrors] = useState({});
  const [roommate, setRoommate] = useSubsetState(props.roommate,
    ['id', 'full_name', 'email', 'phone']
  );

  const editForm = roommate?.id;
  const resource = ['roommate', editForm].filter(Boolean).join('_');
  const buttonText = editForm ? 'Update' : 'Add';
  const header = editForm ? `Roommate ${props.numeration}` : 'Add a roommate';

  const updateRoommate = (update: Partial<IRoommate>) => {
    setRoommate(Object.assign({}, roommate, update));
  };

  const onDestroy = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    const url = `/insurance_policies/${props.insurancePolicy.id}/roommates/${roommate.id}`;

    destroy(url, { authenticity_token }).then(() => {
      roommate.id && props.removeRoommate(roommate.id);
    }).catch((() => {
      // Handle destroy errors
    }));
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setSubmitted(true);

    if (roommate.id) {
      const url = `/insurance_policies/${props.insurancePolicy.id}/roommates/${roommate.id}`;

      put(url, { authenticity_token, roommate }).then((response: { roommate: IRoommate }) => {
        props.renewRoommate(response.roommate);
        setSubmitted(false);
        props.onSubmit();
      }).catch(((response: { errors: IErrors }) => {
        setErrors({ errors: response.errors });
        setSubmitted(false);
      }));
    } else {
      const url = `/insurance_policies/${props.insurancePolicy.id}/roommates/`;

      post(url, { authenticity_token, roommate }).then((response: { roommate: IRoommate }) => {
        props.addRoommate(response.roommate);
      }).catch(((response: { errors: IErrors }) => {
        setErrors({ errors: response.errors });
        setSubmitted(false);
      }));
    }
  }

  return (
    <div className="roommate-form ">
      <div className="header-container">
        <div className="header">{header}</div>
        { editForm && <div className="remove" onClick={() => setRemoved(true)}>Remove</div> }
      </div>

      <div className="line">
        <Input
          placeholder="Name"
          resource={resource}
          field="full_name"
          value={roommate.full_name}
          onChangeString={(full_name) => updateRoommate({full_name})}
        />
        {renderError('full_name', errors)}
      </div>

      <div className="line">
        <Input
          placeholder="Email"
          resource={resource}
          field="email"
          value={roommate.email}
          onChangeString={(email) => updateRoommate({email})}
        />
        {renderError('email', errors)}
      </div>

      <div className="line">
        <Input
          placeholder="Phone number"
          resource={resource}
          field="phone"
          type="phone"
          value={roommate.phone || ''}
          onChangeMasked={(phone) => updateRoommate({phone})}
        />
        {renderError('phone', errors)}
      </div>

      <Button className="submit-button" text={buttonText} onClick={onSubmit} disabled={submitted}/>

      {
        removed &&
          <div className="remove-container">
            <div className="header">Are you sure?</div>
            <Button className="remove-roommate" text="Yes, remove roommate" onClick={onDestroy}/>
            <a className="cancel-removing" onClick={() => setRemoved(false)}>Cancel</a>
          </div>
      }
    </div>
  )
}

const mapStateToProps = ({ insurancePolicy }) => ({ insurancePolicy });
const mapDispatchToProps = (dispatch) => {
  return {
    addRoommate: (roommate: IRoommate) => dispatch(addRoommate(roommate)),
    removeRoommate: (id: number) => dispatch(removeRoommate(id)),
    renewRoommate: (roommate: IRoommate) => dispatch(renewRoommate(roommate))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoommateForm);
