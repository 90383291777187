import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';

import { useMutation, MutationFunction } from 'react-query';

export interface createPropertyManagerParams {
  email: string;
  unitCount: string;
}
const createPropertyManager: MutationFunction<AxiosResponse, createPropertyManagerParams> = async (
  params: createPropertyManagerParams
): Promise<AxiosResponse> => {
  const { email, unitCount } = params;
  const csrf: string = csrfToken();

  const response: AxiosResponse = await axios.post('/landlord_leads', {
    authenticity_token: csrf,
    email,
    portfolio_size: unitCount
  });
  return response;
};

export function useCreatePropertyManagerMutation() {
  return useMutation(createPropertyManager);
}
