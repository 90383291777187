import React from 'react';
import SupportLink from './support_link';
import { IChildProps } from './step_wrapper';

function FailedEnrollment(props: IChildProps) {
  return (
    <div>
      <div className="sign-up__form" id="FailedEnrollment-form">
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              We're sorry. We need to confirm a few details with you before we
              can finish enrollment. Please <SupportLink name="contact us" />.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    padding: '50px'
  }
};

export default FailedEnrollment;
