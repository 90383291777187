import React, { Component } from 'react';
import { IResult, IOpenPolicyApplication } from 'components/v2/signup/redux/actions';
import { PolicyApplication } from 'components/v2/signup/redux/state';
import FilterButtons from './filterButtons';
import EmptyPolicy from './policy/emptyPolicy';
import Policy from './policy/index';
import Loader from 'components/v2/loader';
import Invitations from './Invitations';
import { IPolicyProps } from './policy/policyProps';
import { RentersInsurancePolicyProps } from '../renters_insurance/interfaces';
import { Invitation } from 'components/v2/signup/redux/state';
import RentersInsurancePolicyCard from '../renters_insurance/components/RentersInsurancePolicyCard';
import { RentersInsuranceBannerWrapper } from './RentersInsuranceBannerWrapper';
import { IUser } from './interfaces';
import { FAQ } from '../action_center/components/faq';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { isLeaseMoreThan90DaysAway } from '../action_center/utils';
import getFeatureFlags from 'utils/getFeatureFlags';

const featureFlags = getFeatureFlags();
export interface IProps {
  user: IUser;
  policyApplication: IResult<PolicyApplication>;
  invitations: Invitation[];
}

interface IState {
  isLoading: boolean;
  filter: string;
  sdiPolicies: IPolicyProps[];
  inProgressApplications?: PolicyApplication[];
  errors: string[];
  factNumber: number;
  requestInFlight: boolean;
  rentersInsurancePolicies: RentersInsurancePolicyProps[];
}

interface IConnectedProps {
  openPolicyApplication: (data: IOpenPolicyApplication) => void;
}

export type Props = IProps & IConnectedProps;

const DEFAULT_FILTERS = ['Active', 'Canceled', 'Expired'];
const rentersInsurancePolicyOrder = ['Active', 'Upcoming', 'Canceled'];
const SDIPolicyOrder = ['Active', 'Pending', 'Canceled', 'Expired'];
const sortByProperty = {
  STATUS: 'status',
  STATUS_FOR_RI_CARD: 'status_for_ri_card'
};
const RENTERS_INSURANCE_FILTER_MAP = {
  Canceled: 'Canceled',
  Active: 'Active',
  Upcoming: 'Active',
  Expired: 'Expired'
};

const FilterWrapper = styled.div({
  '&&': {
    display: 'inline-block',
    width: '40%',
    textAlign: 'left',
    // mobile
    '@media (max-width: 992px)': {
      textAlign: 'center',
      width: '100%',
      paddingLeft: '24px'
    }
  }
});

const PoliciesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '@media (max-width: 480px)': {
    gap: '16px'
  }
});

const Greeting = styled.h3([FONTS.h3]);
const GreetingContainer = styled.div({
  paddingTop: '33px',
  '@media (max-width: 992px)': {
    textAlign: 'center'
  }
});

const DividerLine = styled.hr({
  marginBottom: '43px',
  marginTop: '0px',
  '@media (max-width: 992px)': {
    display: 'none'
  }
});

class Dashboard extends Component<Props, IState> {
  refreshInterval: number | undefined;

  constructor(props: Props) {
    super(props);

    this.refreshInterval = window.setInterval(this.poll, 5000);

    this.state = {
      isLoading: true,
      filter: 'Active',
      sdiPolicies: [],
      factNumber: 0,
      errors: [],
      requestInFlight: false,
      rentersInsurancePolicies: []
    };
  }

  public getPolicyData = (filter: string): void => {
    const userId = this.props.user.id;
    const requestUrl = `/insurance_policies?version=2&filter=${filter}`;

    if (userId) {
      fetch(requestUrl, { credentials: 'same-origin', headers: { Accept: 'application/json' } })
        .then((response): any => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error('Unable to pull policies.');
          }
        })
        .then((parsed_response) => {
          this.setState({
            isLoading: false,
            sdiPolicies: this.sortPolicies(parsed_response.insurance_policies, SDIPolicyOrder, sortByProperty.STATUS)
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            errors: [error.message]
          });
        });
    }
  };

  public redirectToPolicyApplicationEnrollment = (user, application): void => {
    if (application.from_native_enrollment) {
      const finalizeUrl = `/partners/${application.partner_id}/${application.source}/users`
      window.location.href = finalizeUrl;
    } else {
      const openPolicyApplicationParams = { userId: user.id, policyApplicationId: application.id };
      this.props.openPolicyApplication(openPolicyApplicationParams)
    }
  }

  public getPolicyApplicationData = (): void => {
    const userId = this.props.user.id;
    const requestUrl = `/users/${userId}/policy_applications/in_progress.json`;

    if (userId) {
      fetch(requestUrl, { credentials: 'same-origin', headers: { Accept: 'application/json' } })
        .then((response): any => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error('Unable to pull policies.');
          }
        })
        .then((parsed_response) => {
          this.setState({
            isLoading: false,
            inProgressApplications: parsed_response
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            errors: [error.message]
          });
        });
    }
  };

  public getRenterInsurancePolicies = (): void => {
    const userId = this.props.user.id;
    const requestUrl = `/cover_genius_policy_details?user_id=${userId}&dashboard_view=true`;

    if (userId) {
      fetch(requestUrl, { credentials: 'same-origin', headers: { Accept: 'application/json' } })
        .then((response): any => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error('Unable to pull renters insurance policies.');
          }
        })
        .then((parsed_response) => {
          this.setState({
            isLoading: false,
            rentersInsurancePolicies: this.sortPolicies(
              parsed_response,
              rentersInsurancePolicyOrder,
              sortByProperty.STATUS_FOR_RI_CARD
            )
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            errors: [error.message]
          });
        });
    }
  };

  public componentDidMount = () => {
    this.getRandomInt(5);
    this.getPolicyData(this.state.filter);
    this.getPolicyApplicationData();
    this.getRenterInsurancePolicies();
  };

  public componentWillUnmount = () => {
    clearInterval(this.refreshInterval);
  };

  public sortPolicies = (array, sortOrder, sortProperty) => {
    return array.sort(function (a, b) {
      return sortOrder.indexOf(a[sortProperty]) - sortOrder.indexOf(b[sortProperty]);
    });
  };

  public renderSdiPolicies = () => {
    return this.state.sdiPolicies.map((policy: IPolicyProps) => {
      return <Policy key={policy.policy_id} {...policy} />;
    });
  };

  public renderRentersInsurancePolicies = () => {
    if (featureFlags.rentersInsurance) {
      return this.state.rentersInsurancePolicies.map((policy: RentersInsurancePolicyProps) => {
        if (RENTERS_INSURANCE_FILTER_MAP[policy.status_for_ri_card] === this.state.filter) {
          return (
            <RentersInsurancePolicyCard
              userId={this.props.user.id}
              key={policy.ins_number}
              addresses={this.props.user.addresses_for_ri}
              {...policy}
            />
          );
        }
      });
    }
  };

  public renderApplications = () => {
    const applications = this.state.inProgressApplications;

    if (applications) {
      return applications.map((application) => {
        if (application.id) {
          if (application.submitted) {
            return (
              <div style={styles.container} className="dashboard-policy" key={application.id}>
                <div className="row">
                  <h6 className="col demi-18">{application.address_line_one}</h6>
                </div>
                <div className="row">
                  <p className="col-10 p2">{application.full_address}</p>
                  <p className="col p2">{application.address_line_two}</p>
                </div>
                <div className="row">
                  <div className="col-12 finalizing-policy">
                    &#x21bb; &nbsp;&nbsp;&nbsp; We’re finalizing your policy now!
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={styles.container}
                id={`app-${application.id}`}
                key={application.id}
                className="dashboard-policy"
                onClick={() => this.redirectToPolicyApplicationEnrollment(this.props.user, application)}
              >
                <div className="row">
                  <h6 className="col demi-18">{application.address_line_one}</h6>
                </div>
                <div className="row">
                  <p className="col-10 p2">{application.full_address}</p>
                  <p className="col p2">{application.address_line_two}</p>
                </div>
                <div className="row">
                  <div className="col-8">{/*delete button*/}</div>
                  <div className="col" style={styles.sticker}>
                    In-Progress
                  </div>
                </div>
              </div>
            );
          }
        }
      });
    }

    return <></>;
  };

  public renderApplicationsAndPolicies = () => {
    const { errors, sdiPolicies, inProgressApplications, filter, isLoading, rentersInsurancePolicies } = this.state;

    if (isLoading) {
      return (
        <div className="border-top">
          <Loader />
        </div>
      );
    }

    if (errors.length !== 0) {
      return <EmptyPolicy header={'Uh oh!'} content={'Something went wrong, try again later'} />;
    }

    if (sdiPolicies.length === 0 && inProgressApplications?.length === 0 && rentersInsurancePolicies.length === 0) {
      let header = `No policies`;
      let content = `Looks like you don't have any policies.`;

      if (filter !== 'All') {
        header = `No ${filter.toLowerCase()} policies`;
        content = `Looks like you don't have any ${filter.toLowerCase()} policies.`;
      }

      return <EmptyPolicy header={header} content={content} />;
    }
    return (
      <div className="dashboard-policies">
        <Invitations />
        {this.renderApplications()}
        {this.renderSdiPolicies()}
        {this.renderRentersInsurancePolicies()}
      </div>
    );
  };

  public getRandomInt = (max: number) => {
    const randomNumber = Math.floor(Math.random() * Math.floor(max));
    this.setState({ factNumber: randomNumber });
  };

  public updateFilter(filter: string): void {
    if (filter === this.state.filter) {
      return;
    }

    this.setState({
      isLoading: true,
      filter
    });
    this.getPolicyData(filter);
  }

  public render() {
    const leaseStartDate = this.props.policyApplication.result?.lease_start_date
      ? new Date(this.props.policyApplication.result.lease_start_date)
      : new Date();

    return (
      <>
        <div className="dashboard-header row">
          <GreetingContainer className="greeting container">
            <Greeting data-cy="policiesTitle">My Policies</Greeting>
          </GreetingContainer>
        </div>
        <DividerLine />
        <div className="container-lg">
          <div className="dashboard-main row">
            <PoliciesWrapper className="col-lg-7">
              <div>
                <FilterWrapper>
                  <FilterButtons
                    filters={DEFAULT_FILTERS}
                    filter={this.state.filter}
                    setFilter={(filter) => this.updateFilter(filter)}
                  />
                </FilterWrapper>
              </div>
              {!isLeaseMoreThan90DaysAway(leaseStartDate) && featureFlags.upsellRentersInsuranceForCash ? (
                <RentersInsuranceBannerWrapper
                  userId={this.props.user.id}
                  firstName={this.props.user.firstName}
                  sdiPolicies={this.state.sdiPolicies}
                  policyApp={this.props.policyApplication}
                  addressesForRI={this.props.user.addresses_for_ri}
                />
              ) : null}
              {this.renderApplicationsAndPolicies()}
            </PoliciesWrapper>

            <div className="col-lg-5">
              <FAQ />
            </div>
          </div>
        </div>
      </>
    );
  }

  private poll = () => {
    this.getPolicyData(this.state.filter);
    this.getPolicyApplicationData();
    this.getRenterInsurancePolicies();
  };
}

const styles = {
  container: {
    borderRadius: '8px',
    marginBottom: '32px',
    padding: '30px',
    cursor: 'pointer'
  },
  sticker: {
    borderRadius: '14px',
    border: '1px solid #777777',
    maxWidth: '106px',
    height: '28px',
    color: '#777777',
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    lineHeight: '24px'
  },
  buttonContainer: {
    marginTop: '14px'
  },
  button: {
    border: '1px solid #FFFFFF',
    borderRadius: '24px',
    display: 'inline-flex',
    padding: '4px 20px',
    backgroundColor: 'transparent'
  },
  buttonText: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#FFFFFF'
  }
};

export default Dashboard;
