import styled from '@emotion/styled';
import ConfirmationCard from './ConfirmationCard';
import StyledModal from './StyledModal';
import React from 'react';
import { PaymentConfirmationModalProps } from './interfaces';

const Container = styled.div({
  width: '576px',
  margin: 'auto',
  '@media (max-width: 600px)': {
    width: '100%'
  }
});

const confirmationCardHeader = `Thanks! Your payment was successfully processed`;
const confirmationCardCTAText = `Ok, got it`;

const PaymentConfirmationModal = ({
  showModal,
  reloading,
  id,
  handleClosePaymentConfirmationModal
}: PaymentConfirmationModalProps) => {
  return (
    <StyledModal id={id} isOpen={showModal} onRequestClose={handleClosePaymentConfirmationModal}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="mt-md-4 mt-5"></div>
          <Container>
            <ConfirmationCard
              reloading={reloading}
              headerText={confirmationCardHeader}
              ctaText={confirmationCardCTAText}
              onCloseClick={handleClosePaymentConfirmationModal}
              onCTAClick={handleClosePaymentConfirmationModal}
            />
          </Container>
        </div>
      </div>
    </StyledModal>
  );
};

export default PaymentConfirmationModal;
