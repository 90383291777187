import React from 'react';
import { ActionButton as ButtonBase, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
// @ts-ignore
import Caret from '../assets/Caret.svg';
import styled from '@emotion/styled';

const MobileActionCenterNavButton = styled(ButtonBase)<{ isLastChild?: boolean }>((props) => ({
  '&&': [
    FONTS.p2Medium,
    {
      display: 'flex',
      justifyContent: 'center',
      marginRight: props.isLastChild ? 0 : '16px',
      backgroundColor: PALETTE.neutralLight,
      border: `1px solid ${PALETTE.neutral12}`,
      color: PALETTE.neutralDark,
      borderRadius: '8px',
      boxShadow: `0px 1px 4px ${PALETTE.neutral12}`,
      paddingRight: '12px',
      height: '42px'
    }
  ]
}));

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center'
});

const CaretIcon = styled.img({
  height: '14px',
  paddingLeft: '12px'
});

export const ActionCenterMobileNavbar = () => {
  return (
    <ButtonWrapper>
      <a href="/policies">
        <MobileActionCenterNavButton
          id="mobile-policies-button"
          variant="noIcon"
          disabled={false}
        >
          Policies
          <CaretIcon src={Caret} />
        </MobileActionCenterNavButton>
      </a>

      <a href="/account/edit/payment_information">
        <MobileActionCenterNavButton
          id={'mobile-payment-info-button'}
          variant="noIcon"
          disabled={false}
          isLastChild
        >
          Billing & Payments <CaretIcon src={Caret} />
        </MobileActionCenterNavButton>
      </a>
    </ButtonWrapper>
  );
};
