import { Button as ButtonBase, CheckIcon as CheckIconBase, FONTS, PALETTE, Radio } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import styled from '@emotion/styled';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';
import { formatCents } from 'utils/money/formatter';
import { IPartnerRentersInsuranceRequirements } from '../signup/redux/state';
import { MONTHLY_PROCESSING_FEE } from '../renters_insurance/constants';

const Wrapper = styled.div({
  width: '100%',
  maxWidth: 734,
  marginLeft: 'auto',
  textAlign: 'center',
  '@media (min-width: 992px)': {
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    marginRight: -17,
    paddingRight: 110
  },
  '&&': {
    '@media (max-width: 768px)': {
      marginRight: 0
    }
  }
});

const Title = styled.h2([FONTS.h2, { '&&': { marginBottom: '8px' } }]);
const MobileTitle = styled.h3([FONTS.h3, { color: PALETTE.neutralDark, paddingTop: '32px' }]);
const SubHeader = styled.p([FONTS.p1Light, { color: PALETTE.neutralDark, marginBottom: 0 }]);

const Section = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '@media (min-width: 768px)': {
    margin: 'auto',
    width: '85%'
  }
});

const SubLabel = styled.div([
  FONTS.p1Light,
  {
    color: PALETTE.neutral88,
    fontSize: 10,
    marginBottom: '0px',
    lineHeight: '16px',
    paddingTop: '4px'
  }
]);

const WhatsIncludedContainer = styled.div({
  background: PALETTE.white,
  '& button': {
    display: 'inline-flex'
  },
  width: 430,
  margin: 'auto',
  '@media (max-width: 520px)': {
    maxWidth: 430,
    margin: 0,
    width: 'auto',
    padding: 0
  }
});

const RadioOptionBox = styled.div<{ selected: boolean; error?: boolean; primaryOption?: boolean }>(
  {
    border: `1px solid ${PALETTE.neutral12}`,
    borderRadius: 8,
    padding: '16px',
    marginBottom: '8px',
    textAlign: 'start'
  },
  (props) =>
    props.primaryOption && {
      backgroundColor: props.error ? `${PALETTE.interaction4}` : `${PALETTE.brand4}`,
      textAlign: 'center'
    },
  (props) =>
    props.selected && {
      border: `1px solid ${PALETTE.interaction100}`
    },
  (props) =>
    props.error && {
      border: `1px solid ${PALETTE.alert100}`
    }
);

const StyledBenefitList = styled.div({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `4px 0px 0px 32px`,
  gap: `4px`
});

const StyledRadioRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px',
  gap: '8px'
});

const StyledRadioLabel = styled(Radio.Label)([
  FONTS.h5,
  {
    '@media (max-width: 520px)': { marginBottom: 0 }
  }
]);
// needed to override shared-js styles
const StyledRadioItem = styled(Radio.Item)({
  '&[data-state="checked"] + label': [FONTS.h5]
});
const StyledRecommendedBadge = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const StyledBadgeLabel = styled.label([
  FONTS.labelMedium,
  {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    padding: `2px 8px`,
    background: `${PALETTE.success100}`,
    borderRadius: '4px',
    color: 'white',
    height: 20
  }
]);

const StyledErrorHeader = styled.div({
  backgroundColor: `${PALETTE.alert4}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px 0px 8px',
  '& label': {
    color: `${PALETTE.alert125}`,
    fontFamily: 'inter',
    fontSize: '14px',
    margin: 0
  }
});

const WhatsIncluded = styled.p(FONTS.p2Medium, {
  '&&': { color: PALETTE.neutral88, textAlign: 'left', marginBottom: 0 }
});
const CheckIcon = styled(CheckIconBase)({ color: PALETTE.success, fontWeight: 'bold' });

interface RentersInsuranceProps {
  setActiveStep: (step: string | null) => void;
  rentersInsuranceSelected: boolean;
  setRentersInsuranceSelected: (e) => void;
  userId: number;
  policyAppId: number;
  coverGeniusRentersInsurance: any;
  paymentOption: PAYMENT_OPTIONS;
  qualifiesForMonthlyRentersInsurance: boolean;
  eligibleInstalmentRIState: boolean;
  partnerRentersInsuranceRequirements?: IPartnerRentersInsuranceRequirements;
  coverGeniusRentersInsuranceNumber?: number;
}

type RentersInsuranceSelectionType = 'ri' | 'no_ri' | 'none';

export const RentersInsuranceMandatoryVariant = (props: RentersInsuranceProps) => {
  const {
    setActiveStep,
    rentersInsuranceSelected,
    setRentersInsuranceSelected,
    userId,
    policyAppId,
    coverGeniusRentersInsurance,
    paymentOption,
    qualifiesForMonthlyRentersInsurance,
    eligibleInstalmentRIState,
    partnerRentersInsuranceRequirements,
    coverGeniusRentersInsuranceNumber
  } = props;
  const isMobile = useCheckMobile();
  const history = useHistory();
  const { pathname, search } = useLocation();

  //  Do we need to make this decision based on the partner requirements or policy application requirements?
  //  Right now we're basing it off of partner, but do we want to check their application response too?
  const hasLeaseRequirement =
    partnerRentersInsuranceRequirements?.active_renters_insurances_requirements?.requires_renters_insurance &&
    !partnerRentersInsuranceRequirements?.active_renters_insurances_requirements?.has_exclusive_agreement;

  useEffect(() => {
    setActiveStep('renters_insurance');
    return () => setActiveStep(null);
  }, []);

  useEffect(() => {
    history.push(pathname + `?payment_cadence=${paymentOption}` + `&ri_selected=${rentersInsuranceSelected}`);
  }, [rentersInsuranceSelected]);

  const addRentersInsurance = () => {
    setRentersInsuranceSelected(true);
  };
  const removeRentersInsurance = () => {
    setRentersInsuranceSelected(false);
  };

  const reviewDetails = () => {
    if (radioSelection === 'none') setError(true);
    else history.push(`policy_details` + search);
  };
  const clearError = () => setError(false);

  const inFull = coverGeniusRentersInsurance.in_full_quote_cents;
  const monthly = coverGeniusRentersInsurance.monthly_quote_cents + MONTHLY_PROCESSING_FEE;
  const instalments = coverGeniusRentersInsurance.instalments_quote_cents + MONTHLY_PROCESSING_FEE;
  const monthlyPaymentAllowed = qualifiesForMonthlyRentersInsurance || eligibleInstalmentRIState;
  const isMonthly = paymentOption === PAYMENT_OPTIONS.Monthly;
  const [radioSelection, setRadioSelection] = useState<RentersInsuranceSelectionType>('none');
  const [error, setError] = useState<boolean>(false);
  const period = isMonthly && monthlyPaymentAllowed ? ' monthly' : '/year';
  const selectedPrice = () => {
    if (isMonthly) {
      if (qualifiesForMonthlyRentersInsurance) {
        return formatCents(monthly);
      } else if (eligibleInstalmentRIState) {
        return formatCents(instalments);
      } else {
        return formatCents(inFull);
      }
    } else {
      return formatCents(inFull);
    }
  };

  const selectedPriceAndCadence = () => {
    return (
      <>
        &nbsp;
        <b>{selectedPrice()}</b>
        {period}
      </>
    );
  };

  const handleRadioSelection = (val: RentersInsuranceSelectionType) => {
    setRadioSelection(val);
    if (val === 'ri') addRentersInsurance();
    else if (val === 'no_ri') removeRentersInsurance();
    clearError();
  };

  const SuccessBadge = () => (
    <StyledRecommendedBadge>
      <StyledBadgeLabel data-mobile={`isMobile: ${isMobile}`}>
        {hasLeaseRequirement ? 'LEASE REQUIREMENT' : 'RECOMMENDED'}
      </StyledBadgeLabel>
    </StyledRecommendedBadge>
  );

  return (
    <Wrapper>
      <Section data-cy="rentersInsuranceHeader">
        {isMobile ? <MobileTitle>Add renters insurance</MobileTitle> : <Title>Add renters insurance</Title>}
        <SubHeader>
          {hasLeaseRequirement ? (
            <>
              {partnerRentersInsuranceRequirements?.building_name} requires renters insurance. Save 35%* when you sign
              up with Rhino for {selectedPriceAndCadence()}.
              <SubLabel>*Compared to the base price if your property did not require renters insurance.</SubLabel>
            </>
          ) : (
            <>
              Renters Insurance protects your home and personal belongings from fires, theft and more for
              {selectedPriceAndCadence()}.
            </>
          )}
        </SubHeader>
      </Section>
      <Section>
        <WhatsIncludedContainer>
          {error && (
            <StyledErrorHeader>
              <label>Please make a renters insurance selection</label>
            </StyledErrorHeader>
          )}
          <Radio.Root
            onValueChange={(value: RentersInsuranceSelectionType) => {
              handleRadioSelection(value);
            }}
            value={radioSelection}
          >
            <RadioOptionBox
              primaryOption={true}
              selected={radioSelection === 'ri'}
              onClick={() => handleRadioSelection('ri')}
              error={error}
            >
              <StyledRadioRow>
                <span>
                  <StyledRadioItem id={'radio-ri'} value="ri">
                    <Radio.Indicator />
                  </StyledRadioItem>
                  <StyledRadioLabel htmlFor={'radio-ri'}>Add renters insurance</StyledRadioLabel>
                </span>
                {!isMobile && SuccessBadge()}
              </StyledRadioRow>
              <StyledBenefitList>
                {isMobile && SuccessBadge()}
                <span>
                  <WhatsIncluded>
                    <CheckIcon />
                    &nbsp;$15,000 in personal property {isMobile ? '' : 'coverage'}
                  </WhatsIncluded>
                  <WhatsIncluded>
                    <CheckIcon />
                    &nbsp;$100,000 in liability coverage
                  </WhatsIncluded>
                  <WhatsIncluded>
                    <CheckIcon />
                    &nbsp;Satisfies most lease requirements
                  </WhatsIncluded>
                </span>
              </StyledBenefitList>
            </RadioOptionBox>
            <RadioOptionBox
              error={error}
              selected={radioSelection === 'no_ri'}
              onClick={() => handleRadioSelection('no_ri')}
            >
              <span>
                <StyledRadioItem id={'radio-no-ri'} value="no_ri">
                  <Radio.Indicator />
                </StyledRadioItem>
                <StyledRadioLabel htmlFor={'radio-no-ri'}>No thanks, I'll risk it</StyledRadioLabel>
              </span>
            </RadioOptionBox>
          </Radio.Root>
        </WhatsIncludedContainer>
      </Section>
      <QuoteDisplayFooter
        arrowOnButton={true}
        onBack={() => history.push(`/enroll/quote_display`)}
        onNext={() => reviewDetails()}
        nextBtnLabel="Review Details"
        dataCy="reviewDetails"
      />
    </Wrapper>
  );
};
