export interface ICitizenshipOption {
  label: string;
  value: number;
}

const CitizenshipOptions: ICitizenshipOption[] = [
  {label: 'Yes', value: 0},
  {label: 'No', value: 1}
];

export default CitizenshipOptions;
