import React, { ImgHTMLAttributes, ReactNode } from 'react';
import env from 'utils/env';

const stubGoogleMaps = env('STUB_CLIENT_GOOGLE_MAPS') === 'true';

export interface IProps {
  location: string;
  // Default red
  markerColor?: string;
  size: {
    width: number;
    height: number;
  };
  apiKey: string;
  attributes?: ImgHTMLAttributes<HTMLImageElement>;
  wrapperId?: string;
}

class Component extends React.Component<IProps> {
  public render(): ReactNode {
    return <img {...this.props.attributes} src={this.imageSource()} />;
  }

  private imageSource(): string {
    const { location, size, apiKey } = this.props;
    const markerColor = this.props.markerColor || 'red';
    const center = encodeURIComponent(location);
    let sizeStr = encodeURIComponent(`${size.width}x${size.height}`);

    if (this.props.wrapperId === 'mobile-card') {
      sizeStr = encodeURIComponent(`${100}x${100}`);
    }

    const base = 'https://maps.googleapis.com/maps/api/staticmap';
    if (stubGoogleMaps) {
      return '';
    } else {
      return `${base}?key=${apiKey}&center=${center}&size=${sizeStr}&markers=color:${markerColor}|${center}`;
    }
  }
}

export default {
  Component
};
