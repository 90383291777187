export enum AccountTypes {
  CHECKING = 'checking',
  SAVINGS = 'savings'
}

interface ISharedBankAccountInfo {
  bank_name: string;
  account_number: string;
  first_name_on_account: string;
  last_name_on_account: string;
  account_type: AccountTypes;
  contact_phone_number: string;
  nickname?: string;
  internal_account_type: string;
}

interface IDomesticBankAccountInfo extends ISharedBankAccountInfo {
  internal_account_type: 'us_domestic';
  ach: string;
}

interface IInternationalBankAccountInfo extends ISharedBankAccountInfo {
  internal_account_type: 'international';
  bic_swift_code: string;
  recipient_country: string;
  recipient_address_state: string;
  recipient_address_city: string;
  recipient_address_street: string;
  recipient_postal_code: string;
}

export type IBankAccountInfo = IDomesticBankAccountInfo | IInternationalBankAccountInfo

export interface IBankAccountForm {
  bank_name: string,
  first_name_on_account: string,
  last_name_on_account: string,
  nickname: string,
  account_type: '' | AccountTypes,
  internal_account_type: 'us_domestic' | 'international',
  contact_phone_number: string,
  account_number: string,
  ach: string,
  bic_swift_code: string,
  recipient_address_city: string,
  recipient_address_state: string,
  recipient_address_street: string,
  recipient_postal_code: string,
  recipient_country: string
}

export type IBankAccountFormErrors = { [K in keyof IBankAccountForm]: string }

export enum WithdrawalState {
  pending = 'pending',
  user_info_needed = 'user_info_needed',
  payment_pending = 'payment_pending',
  payment_complete = 'payment_complete',
  cancelled = 'cancelled'
}

export interface IWithdrawal {
  withdraw_uid: string;
  state: WithdrawalState;
  amount: number;
  created_timestamp: string;
}

export type NonCancelledWithdrawal =
  Omit<IWithdrawal, 'state'> & { state: Exclude<WithdrawalState, WithdrawalState.cancelled> };
