import { useHistory, useLocation } from 'react-router-dom';
import _Button from 'components/v2/button';
import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { isEmpty } from 'lodash';

const Button: any = styled(_Button)([
  FONTS.h6Light,
  {
    border: 'none',
    background: 'inherit',
    display: 'block',
    '&:focus': {
      outline: 'none'
    }
  }
]);

export const Link = ({ path, label, dataCy, analyticsEvent }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isActivePage = pathname === path;

  return (
    <Button
      onClick={() => {
        history.push(path);
      }}
      text={label}
      styles={{ fontWeight: isActivePage ? 600 : 300 }}
      dataCy={dataCy}
    />
  );
};
