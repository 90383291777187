import React from 'react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg`
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

interface SpinnerProps {
  width?: string | number;
}

const appendPixels = (str: string | number) => {
  let coercedStr = str || '';
  if (typeof coercedStr !== 'string') {
    coercedStr = coercedStr.toString();
  }
  return coercedStr.endsWith('px') ? str : `${str}px`;
};

const SpinnerContainer = styled.div<SpinnerProps>`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  width: ${(props) => (props.width ? appendPixels(props.width) : '50px')};
`;
const SpinnerIcon = ({ width }: SpinnerProps) => {
  return (
    <SpinnerContainer width={width}>
      <StyledSvg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/StyledSvg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2955 1.62129L21.3626 1.12583L20.3717 0.991463L20.3045 1.48693L19.6948 5.98383C17.8356 3.1619 14.62 1.3541 11 1.3541C5.22386 1.3541 0.5 6.07796 0.5 11.8541C0.5 17.6302 5.22386 22.3541 11 22.3541C16.7761 22.3541 21.5 17.6302 21.5 11.8541V11.3541H20.5V11.8541C20.5 17.078 16.2239 21.3541 11 21.3541C5.77614 21.3541 1.5 17.078 1.5 11.8541C1.5 6.63024 5.77614 2.3541 11 2.3541C14.4035 2.3541 17.3998 4.11497 19.0418 6.82192L14.0661 6.1585L13.5705 6.09241L13.4383 7.08364L13.9339 7.14972L19.6123 7.90685L19.6682 8.02214L19.8427 7.93756L19.9339 7.94972L20.4284 8.01566L20.481 7.62808L20.568 7.58587L20.5045 7.45478L21.2955 1.62129Z"
          fill="#502CB9"
        />
      </StyledSvg>
    </SpinnerContainer>
  );
};

export default SpinnerIcon;
