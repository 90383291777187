import React, { FormEvent, useMemo, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { rollbar } from 'components/v2/shared/rollbar';

import env from 'utils/env';

import { IPaymentProps, IResult, checkInvitation, checkInvitationSync } from '../signup/redux/actions';
import { PolicyApplication, User } from '../signup/redux/state';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import { RhinoLogger } from '../shared/rhino_logger';
import store from '../signup/redux/store';
import { formatCents } from 'utils/money/formatter';
import styled from '@emotion/styled';
import { FONTS, Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import getFeatureFlags from 'utils/getFeatureFlags';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

interface IPaymentElementProps {
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  user: IResult<User>;
  notes: React.ReactNode;
  totalAmount: number;
  nextButtonDisabled: boolean;
  setNextButtonDisabled: (value: boolean) => void;
  isUserAgreementAccepted: boolean;
  setIsUserAgreementError: (value: boolean) => void;
}

const CardError = styled.div([
  FONTS.p3,
  {
    backgroundColor: PALETTE.alert4,
    color: PALETTE.alert125,
    fontWeight: 500,
    margin: '16px auto',
    padding: '16px 0',
    textAlign: 'center',
    width: '100%'
  }
]);

const PaymentElementForm = ({
  payAndSubscribe,
  notes,
  policyApplication,
  user,
  totalAmount,
  nextButtonDisabled,
  setNextButtonDisabled,
  isUserAgreementAccepted,
  setIsUserAgreementError
}: IPaymentElementProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useHistory();

  const search = window.location.search;
  const isAnnual = new URLSearchParams(search).get('payment_cadence') === '1';

  const [message, setMessage] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const featureFlags = getFeatureFlags();
  const requireAcceptTermsOnCheckout = Boolean(featureFlags?.requireAcceptTermsOnCheckout);

  const isNextButtonDisabled = useMemo(
    () =>
      requireAcceptTermsOnCheckout
        ? nextButtonDisabled || isLoading || !stripe || !elements
        : isLoading || !stripe || !elements,
    [isLoading, stripe, elements, nextButtonDisabled]
  );

  const logInfo = (logMessage: string) => {
    const prefixed = `policy_application_id=${policyApplication.result?.id}: ${logMessage}`;
    RhinoLogger.info(prefixed);
  };

  const createSubscriptionWithPaymentIntent = () => {
    setIsLoading(true);

    const payload = {
      upfront: isAnnual
    };
    payAndSubscribe(payload);
  };

  const checkInvitations = async () => {
    logInfo('checking invitation');

    const userId = user.result?.id;
    const policyApplicationId = policyApplication.result?.id;

    if (!userId) {
      rollbar.error('PaymentForm without user');
    } else if (!policyApplicationId) {
      rollbar.error('PaymentForm without policy application');
    } else {
      const invitation = await checkInvitation(userId, policyApplicationId);
      if (invitation.result) {
        logInfo('invitation changed: aborting');
        store.dispatch(checkInvitationSync(invitation));
      }
    }
  };

  const onPaymentElementChange = (event: StripePaymentElementChangeEvent): any | undefined => {
    // enabled the submit button if any payment element has some value
    // allow error messaging to tell the user what is missing
    setNextButtonDisabled(Boolean(isLoading || !stripe || !elements || event?.empty));
  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (requireAcceptTermsOnCheckout && !isUserAgreementAccepted) {
      setIsUserAgreementError(true);
      return;
    }

    setIsLoading(true);

    await checkInvitations();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: env('RHINO_URL') + `/enroll/password?step=password`
      },
      redirect: 'if_required'
    });

    if (error) {
      setIsLoading(false);
      if (error.payment_method?.card?.funding === 'prepaid') {
        setMessage('We do not accept prepaid cards. Enter a credit or debit card.');
      } else {
        setMessage(String(error.message));
      }
      return;
    } else {
      navigate.push('/enroll/password');
    }

    createSubscriptionWithPaymentIntent();
    setIsLoading(false);
  };

  const onBack = () => {
    history.back();
  };

  const onNext = () => {
    setMessage('');
    handleSubmit();
  };

  if (!stripe || !elements) {
    return <Loading />;
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div className="row payment-element-container">
        <div className="col-12">
          {message && <CardError id="payment-message">{message}</CardError>}
          <PaymentElement id="payment-element" onChange={onPaymentElementChange} />
        </div>
      </div>
      <>{notes}</>
      <QuoteDisplayFooter
        arrowOnButton={false}
        nextBtnLabel={`Pay ${formatCents(totalAmount)}`}
        nextBtnDisabled={isNextButtonDisabled}
        onBack={onBack}
        onNext={onNext}
        dataCy="payment"
      />
    </form>
  );
};

export default PaymentElementForm;
