import axios from 'axios';
import { csrfToken } from 'utils/document';

/* 
  These constants are taken from api.ts which lives here:
  app/javascript/components/v2/action_center/components/PayNowModal/api.ts
*/

const DEFAULT_HEADERS = {
  accept: 'application/json',
  'Content-Type': 'application/json'
};

const DEFAULT_OPTIONS = {
  authenticity_token: csrfToken(),
  credentials: 'same-origin',
  headers: DEFAULT_HEADERS
};

class RhinoLogger {
  public static log(level: string, message: string, payload?: object) {
    let messageAndPayload = message;
    if (payload) {
      messageAndPayload += ` ${JSON.stringify(payload)}`;
    }

    axios.post('/rhino_loggers/', {
      ...DEFAULT_OPTIONS,
      level,
      message: messageAndPayload
    });
  }

  public static info(message: string, payload?: object) {
    this.log('info', message, payload);
  }

  public static error(message: string, payload?: object) {
    this.log('error', message, payload);
  }
}

export { RhinoLogger }
