import { isAfter, addDays, parseISO, format } from 'date-fns';

/**
 * Checks if lease start date is more than 90 days in the future
 * @param leaseStartDate
 * @returns true if lease is > 90 days away
 */
export const isLeaseMoreThan90DaysAway = (leaseStartDate: Date): boolean => {
  const todayplus90 = addDays(new Date(), 90);
  // checks if the lease begins after today + 90 days
  return isAfter(leaseStartDate, todayplus90);
};

/**
 * To be used with dates returned from our endpoint
 * and will create a default ISO date or the current day
 * instead of an InvalidDate object
 * @param dateISO - date as ISO 8601
 */
export const initializeDate = (dateISO?: string): Date => {
  return dateISO === undefined ? new Date() : parseISO(dateISO);
};

/**
 * Formats Date to a string with short month, short day, and year
 * @param date - Date object
 *
 * @returns formatted date string, e.g. "Jan 1, 2020"
 */
export const stringifyDate = (date: Date): string => format(date, 'LLL d, u');
