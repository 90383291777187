import moment from 'moment';
import React, { SyntheticEvent } from 'react';

import Input from 'components/v2/input';
import { BackButton, renderError, useSubsetState, loadedViaBackButton, useLoader } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';

export const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD');
export const defaultEndDate = moment().startOf('month').add(12, 'months').subtract(1, 'day').format('YYYY-MM-DD');
export const maxStartDate = moment().add(6, 'months').endOf('day').toDate();
export const maxEndDate = moment().add(3, 'years').add(6, 'months').endOf('day').toDate();
export const startDatePickerProps = {
  autofocus: false,
  placeholderText: 'Start Date'
};
export const endDatePickerProps = {
  autofocus: false,
  placeholderText: 'End Date'
};

function LeaseDates(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const loadedViaBack = loadedViaBackButton();
  const [policyApplication, updatePolicyApplication] = useSubsetState(
    props.policyApplication.result,
    ['lease_start_date', 'lease_end_date', 'map_props'],
    {
      lease_start_date: props.policyApplication.result?.lease_start_date || defaultStartDate,
      lease_end_date: props.policyApplication.result?.lease_end_date || defaultEndDate,
      map_props: props.policyApplication.result?.map_props
    }
  );

  const updateStartDate = (lease_start_date) =>
    updatePolicyApplication(
      Object.assign(policyApplication, {
        lease_start_date
      })
    );
  const updateEndDate = (lease_end_date) =>
    updatePolicyApplication(
      Object.assign(policyApplication, {
        lease_end_date
      })
    );

  const minEndDate = moment(policyApplication?.lease_start_date).toDate();
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    if (policyApplicationId && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const displayLoading = useLoader();

  return (
    <div>
      {displayLoading && (
        <div style={styles.loaderSection}>
          <h1 className="black purple" style={styles.loaderTitle}>
            Thanks {props.user.result?.first_name}, lets keep going!
          </h1>
        </div>
      )}
      <h2 className="bold" data-cy="leaseTermHeader">
        Enter your lease term
      </h2>
      <form id="lease-dates-form" onSubmit={onSubmit}>
        <div className="input-holder">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Input
                dataCy="leaseStart"
                type="date"
                resource="policy_application"
                field="lease_start_date"
                maxDate={maxStartDate}
                datePicker={startDatePickerProps}
                value={policyApplication.lease_start_date}
                onChangeDate={updateStartDate}
              />
              {renderError('start_date', props.policyApplication)}
            </div>
            <div className="col-md-6 col-sm-12">
              <Input
                dataCy="leaseEnd"
                type="date"
                resource="policy_application"
                field="lease_end_date"
                minDate={minEndDate}
                maxDate={maxEndDate}
                datePicker={endDatePickerProps}
                value={policyApplication.lease_end_date}
                onChangeDate={updateEndDate}
              />
              {renderError('end_date', props.policyApplication)}
            </div>
          </div>
        </div>
        <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
          <BackButton step={props.step} />
          <input type="submit" className="btn-default" data-cy="continue" value="Continue" />
        </div>
      </form>
    </div>
  );
}

const styles = {
  loaderSection: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  loaderTitle: {
    textAlign: 'center' as 'center',
    width: '50%',
    margin: 'auto',
    marginTop: '20%'
  }
};

export default LeaseDates;
