import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button as RhinoButton } from '@sayrhino/rhino-shared-js';
import Modal from './Modal';
import { CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';

const Title = styled.h3([
  FONTS.h3,
  {
    textAlign: 'center',
    paddingBottom: '4px'
  }
]);

const ErrorTitle = styled.h3([
  FONTS.brandH2Bold,
  {
    textAlign: 'center',
    paddingBottom: '4px'
  }
]);

const SubTitle = styled.p([
  FONTS.p1Light,
  {
    color: PALETTE.neutral65,
    textAlign: 'center'
  }
]);

const Button = styled(RhinoButton)([
  FONTS.h5,
  { background: PALETTE.raptor, width: '169px', justifyContent: 'center' }
]);

const Container = styled.div({
  '&&': {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&&': {
      '@media (max-width: 768px)': {
        height: '100%',
        flexGrow: 1
      }
    }
  }
});

const CloseIconContainer = styled.div({
  justifyContent: 'right',
  textAlign: 'right',
  paddingBottom: '30px'
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer',
  textAlign: 'right'
});

const TopDiv = styled.div({
  margin: '0px'
});

interface InterestedPartyModalProps {
  isOpen: boolean;
  interestedPartyError: boolean;
  closeInterestedPartyConfirmation: () => void;
  updated: boolean;
}

const InterestedPartyConfirmation = (props: InterestedPartyModalProps) => {
  const { isOpen, interestedPartyError, closeInterestedPartyConfirmation, updated } = props;
  const [wasUpdated, setWasUpdated] = useState(updated);

  return (
    <Modal onClose={closeInterestedPartyConfirmation} isOpen={isOpen}>
      <TopDiv>
        <CloseIconContainer>
          <CloseIcon onClick={closeInterestedPartyConfirmation} data-cy="modalCancel" />
        </CloseIconContainer>
      </TopDiv>
      {interestedPartyError ? (
        <Container>
          <ErrorTitle>Hmm, that didn't work</ErrorTitle>
          <SubTitle>Uh-oh, looks like something went wrong. Please try again.</SubTitle>
          <Button onClick={closeInterestedPartyConfirmation} data-cy="okGotIt">
            Got it, thanks!
          </Button>
        </Container>
      ) : (
        <Container>
          <Title data-cy="success">Your interested party has been {wasUpdated ? 'updated' : 'added'}!</Title>
          <SubTitle>
            They will be notified of this change, and you will receive a new copy of your updated coverage summary.
          </SubTitle>
          <Button onClick={closeInterestedPartyConfirmation} data-cy="okGotIt">
            OK, got it
          </Button>
        </Container>
      )}
    </Modal>
  );
};

export default InterestedPartyConfirmation;
