import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import { ButtonHabitat, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { useContext } from 'react';
import { ICaseStudyModuleFields } from '../../../../../@types/generated/contentful';
import UserContext from '../layout/account/user-context';
import { Spacer72pxDesktop52pxMobile } from './Spacers';

export interface CaseStudyModuleProps extends Omit<ICaseStudyModuleFields, 'caseStudiesImage'> {
  caseStudiesImage: { url: string; description: string };
}

const LightListItem = styled.li({ color: PALETTE.neutralLight });
const LightParagraph = styled.p({ color: PALETTE.neutralLight });
const DarkWrapper = styled.div({ backgroundColor: PALETTE.neutralDark });

const options = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_node, children) => <LightListItem>{children}</LightListItem>,
    [BLOCKS.PARAGRAPH]: (_node, children) => <LightParagraph>{children}</LightParagraph>
  }
};

const HeaderMessage = styled.div([
  FONTS.brandH2Bold,
  { marginBottom: 32, color: PALETTE.neutralLight, fontSize: '44px', lineHeight: '56px' }
]);

export const CaseStudyModule: React.FC<CaseStudyModuleProps> = (props) => {
  const { ctaButtonText, ctaButtonUrl, sectionTitle, caseStudiesImage, caseStudiesDescription } = props;
  const { currentUser } = useContext(UserContext);

  return (
    <DarkWrapper>
      <div className="container">
        <div className="row">
          <div className="col">
            <Spacer72pxDesktop52pxMobile />
            <div className="row align-items-center">
              <div className="col-lg-6">
                <HeaderMessage>{sectionTitle}</HeaderMessage>
                <div>{documentToReactComponents(caseStudiesDescription, options)}</div>
                <ButtonHabitat
                  variant="primary"
                  id="case-studies-button"
                  onClick={() => {
                    window.open(ctaButtonUrl, '_blank');
                  }}
                >
                  {ctaButtonText}
                </ButtonHabitat>
              </div>
              <div className="col"></div>
              <div className="col-lg-5">
                <img src={caseStudiesImage.url} alt={caseStudiesImage.description} width="100%" />
              </div>
            </div>
            <Spacer72pxDesktop52pxMobile />
          </div>
        </div>
      </div>
    </DarkWrapper>
  );
};
