import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation } from 'react-query';
import { IBankAccountInfo } from './types';

interface IUpdateBankAccountResponse {
  errors?: string[];
}

type IUpdateBankAccountParams = {
  bank_account: IBankAccountInfo;
  bank_account_uid: string;
}

const updateBankAccount = async (
  params: IUpdateBankAccountParams
): Promise<AxiosResponse<IUpdateBankAccountResponse>> => {
  const authenticity_token = csrfToken();

  const response: AxiosResponse<IUpdateBankAccountResponse> = await axios.patch(
    `/users/renter_withdrawal_funds/${params.bank_account_uid}`, { bank_account: params.bank_account },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdateBankAccountMutation() {
  return useMutation(updateBankAccount);
}
