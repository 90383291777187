import React from 'react';
import axios from 'axios';

import ActionCard from './ActionCard';
import { openClaim } from '../constants';
import { IClaims } from '../interfaces';
import { formatMoney } from 'utils/money';
import { Subrogation } from '../../subro_payment_plans/subroFormWrapper';

interface Props {
  claims: IClaims[];
  userId: number;
  onPayButtonClick: (subrogation: Subrogation) => void;
}

export const Claims = ({ claims, onPayButtonClick }: Props) => {
  if (claims.length < 1) {
    return null;
  }

  const showInProgressButton = (claim: IClaims) => {
    return ['payment_plan_in_progress', 'payment_plan'].includes(claim.claim_status);
  };

  return (
    <>
      {claims.map((claim) => (
        <div key={claim.claim_id}>
          <div id="subrogation-card">
            {claim.subrogation_payment_plan_installments !== 1 && claim.unreimbursed_claim_amount_cents > 0 && (
              <ActionCard
                header={openClaim.header}
                text={`You have an outstanding claim balance due of ${
                  formatMoney(claim.unreimbursed_claim_amount_cents / 100, { precision: 2 }).maskedValue
                }.
                  Pay now to avoid any coverage lapse at renewal.`}
                label={openClaim.label}
                ctaText={showInProgressButton(claim) ? openClaim.inProgressText : openClaim.ctaText}
                handleClick={async () => {
                  const { data } = await axios({
                    method: 'get',
                    url: `/claims/${claim.claim_id}/payment_plans/new`,
                    headers: { Accept: 'application/json' }
                  });
                  onPayButtonClick(data as Subrogation);
                }}
                disableButton={showInProgressButton(claim) && claim.subrogation_payment_plan_installments < 1}
                destructive={true}
                key={claim.claim_number}
                callToActionId={showInProgressButton(claim) ? 'subrogation-in-pogress' : 'subrogation-pay-now'}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};
