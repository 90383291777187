import PayNowModal from './PayNowModal';
import React, { useState } from 'react';
import { get } from 'lodash';
import PaymentConfirmationModal from './PaymentConfirmationModal';
import PartialPaymentConfirmationModal from './PartialPaymentConfirmationModal';
import useCreateStripeToken from './useCreateStripeToken';
import {
  updateDefaultCard,
  payInvoices,
  payOpenInvoices,
  formatPayNowError,
  listInvoices,
  getAxiosError,
  isVagueAPIError
} from './api';
import { isPresent, getFeatureFlags } from '../../../../../utils';
import { PayNowModalContainerProps } from './interfaces';
import { formatCents } from 'utils/money/formatter';
import { allInvoicesFailed, someInvoicesFailed, filterUnpaidInvoices } from './utils';

const googlePayPayNowEnabled = (window as any).App?.featureFlags?.googlePayPayNow;

const defaultCardOption = { value: 'default_credit_card', label: 'Credit or Debit Card Number' };

const updatePaymentMethodOption = { value: 'update_payment_method', label: 'Update Payment Method' };

const getDefaultPaymentOption = (defaultCard) => {
  if (isPresent(defaultCard)) {
    return defaultCardOption.value;
  }
  return updatePaymentMethodOption.value;
};

const aggregateInvoiceTotal = (invoices) => {
  const remainingAmounts = invoices.map((invoice) => invoice.total || 0);
  return remainingAmounts.reduce((curr, remainingAmount) => {
    return (curr += remainingAmount);
  }, 0);
};

const PayNowModalConntainer = ({
  showPayNowModal,
  showPaymentConfirmationModal,
  showPartialPaymentConfirmationModal,
  stripePublicKey,
  fullName,
  reloading,
  unpaidInvoicesBreakdown,
  defaultCard,
  handleClosePayNowModal,
  handleShowPartialPaymentConfirmationModal,
  handleClosePaymentConfirmationModal,
  handleClosePartialPaymentConfirmationModal,
  handlePayRemainingBalanceClick,
  handleShowPaymentConfirmationModal
}: PayNowModalContainerProps): JSX.Element | null => {
  const [remainingPartialPaymentAmount, setRemainingPartialPaymentAmount] = useState<number | string>(0);
  const [selectedOption, setSelectedOption] = useState(getDefaultPaymentOption(defaultCard));
  const [submitting, setSubmitting] = useState<any>(false);
  const [remainingInvoices, setRemainingInvoices] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [zipCode, setZipCode] = useState<string>('');

  const createStripeToken = useCreateStripeToken();

  const handleSelectOption = (value: string): void => {
    setErrors({});
    setSelectedOption(value);
  };

  const handleZipCodeUpdate = (e): void => {
    setZipCode(e.target.value);
  };

  const validate = (): string[] => {
    const validationErrors: string[] = [];
    if (selectedOption === defaultCardOption.value) {
      return validationErrors;
    }
    if (!isPresent(zipCode)) {
      validationErrors.push('Missing zip code');
    }
    return validationErrors;
  };

  const getInitialOrRemaingInvoices = () => {
    if (isPresent(remainingInvoices)) {
      return remainingInvoices;
    }
    const { invoices: initialInvoices } = unpaidInvoicesBreakdown;
    return initialInvoices;
  };

  const getFreshFailedInvoices = async () => {
    const fetchedInvoices = await listInvoices();
    const invoices = get(fetchedInvoices, ['data', 'invoices'], []);
    return filterUnpaidInvoices(invoices);
  };

  const getFreshOrPreviousFailedInvoices = async (previousFailedInvoice) => {
    const notAnInvoice = (failedInvoice) => {
      return !isPresent(failedInvoice.id) && !isPresent(failedInvoice.paid);
    };

    const miscErrorsPresent = previousFailedInvoice.filter((failedInvoice) => {
      return isVagueAPIError(failedInvoice.error || '') || notAnInvoice(failedInvoice);
    });
    // If the request times out or another error occurs then
    // the failed invoice will contain the error and not the invoice.
    // So if we encounter something that's not an invoice, then that means
    // we need to make a new request to get the "actual" failed invoices to
    // avoid false positives
    if (isPresent(miscErrorsPresent)) {
      return await getFreshFailedInvoices();
    }
    return previousFailedInvoice;
  };

  const processPayment = async () => {
    const { invoices: initialInvoices } = unpaidInvoicesBreakdown;
    const invoices = getInitialOrRemaingInvoices();

    // This is a short live code
    // Once the feature is live, we will remove lines 119 and 120
    // and replace 'payInvoicesFn' with 'payOpenInvoices'
    const featureFlags = getFeatureFlags();
    const payInvoicesFn = Boolean(featureFlags?.shortCircuitInvoicePayFailures) ? payOpenInvoices : payInvoices
    const { failedInvoices, error: invoiceError } = await payInvoicesFn(invoices)

    const actualFailedInvoices = await getFreshOrPreviousFailedInvoices(failedInvoices);

    setRemainingInvoices(actualFailedInvoices);
    // Here we store the failed invoices to be processed again
    // if the user tries to pay with a new card and not process
    // the same invoice again

    const everyInvoiceFailed = allInvoicesFailed(initialInvoices, actualFailedInvoices);

    if (everyInvoiceFailed) {
      // Here we  display the default error from the failed invoice that failed
      return setErrors(invoiceError);
    }

    const severalInvoicesFailed = someInvoicesFailed(initialInvoices, actualFailedInvoices);

    if (severalInvoicesFailed) {
      const aggregatedInvoiceTotal = aggregateInvoiceTotal(actualFailedInvoices);
      const formattedTotal = formatCents(aggregatedInvoiceTotal);
      setRemainingPartialPaymentAmount(formattedTotal);
      return handleShowPartialPaymentConfirmationModal();
    }

    handleShowPaymentConfirmationModal();
  };

  const handleSubmit = async () => {
    try {
      setErrors({});
      setSubmitting(true);

      if (selectedOption === updatePaymentMethodOption.value && !googlePayPayNowEnabled) {
        const validationErrors = validate();
        if (isPresent(validationErrors)) {
          setSubmitting(false);
          return null;
        }
        const { stripeToken } = await createStripeToken(fullName);
        await updateDefaultCard({ zipCode, stripeTokenId: stripeToken?.id });
      }

      await processPayment();
    } catch (err) {
      const axiosError = getAxiosError(err);
      const formattedError = formatPayNowError(axiosError);
      setErrors(formattedError);
    } finally {
      setSubmitting(false);
    }
  };

  let modalToDisplay: JSX.Element | null = null;
  if (showPayNowModal) {
    modalToDisplay = (
      <PayNowModal
        submitting={submitting}
        handleSelectOption={handleSelectOption}
        handleSubmit={handleSubmit}
        errors={errors}
        handleZipCodeUpdate={handleZipCodeUpdate}
        selectedOption={selectedOption}
        handleShowPartialPaymentConfirmationModal={handleShowPartialPaymentConfirmationModal}
        handleShowPaymentConfirmationModal={handleShowPaymentConfirmationModal}
        unpaidInvoicesBreakdown={unpaidInvoicesBreakdown}
        stripePublicKey={stripePublicKey}
        showModal={showPayNowModal}
        fullName={fullName}
        defaultCard={defaultCard}
        handleClosePayNowModal={handleClosePayNowModal}
        setSubmitting={setSubmitting}
      />
    );
  } else if (showPaymentConfirmationModal) {
    modalToDisplay = (
      <PaymentConfirmationModal
        reloading={reloading}
        showModal={showPaymentConfirmationModal}
        handleClosePaymentConfirmationModal={handleClosePaymentConfirmationModal}
      />
    );
  } else if (showPartialPaymentConfirmationModal) {
    modalToDisplay = (
      <PartialPaymentConfirmationModal
        reloading={reloading}
        remainingPartialPaymentAmount={remainingPartialPaymentAmount}
        handlePayRemainingBalanceClick={handlePayRemainingBalanceClick}
        showModal={showPartialPaymentConfirmationModal}
        handleClosePartialPaymentConfirmationModal={handleClosePartialPaymentConfirmationModal}
      />
    );
  } else {
    modalToDisplay = null;
  }
  return modalToDisplay === null ? null : modalToDisplay;
};

export default PayNowModalConntainer;
