import React from 'react';
import { IFinalCtaModuleFields } from '../../../../../../@types/generated/contentful';
import { ButtonHabitat, TextInput, Select } from '@sayrhino/rhino-shared-js';
import { useResidentialForm } from '../hooks/useResidentialForm';
import { StyledSubtext } from './HeroSectionSignupForm';

export const FinalCtaSignupForm: React.FC<Partial<IFinalCtaModuleFields>> = (props) => {
  // leaving this since it comes from contentful (in case someone wants to reimplement it)
  const { ctaButtonText } = props;
  const {
    email,
    handleEmailChange,
    unitCountOption,
    handleUnitCountChange,
    emailError,
    unitCountError,
    handleSubmit,
    isLoading,
    unitCountOptions
  } = useResidentialForm();

  return (
    <form
      onSubmit={(e) => {
        e?.preventDefault();
        handleSubmit();
      }}
      id="residential-final-cta-form"
    >
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            id="email_final_Cta"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            subtext={emailError}
            error={!!emailError}
            placeholder="Email address"
          />
        </div>
        <div className="col-lg-6">
          <Select
            options={unitCountOptions}
            id="unit-count_final_Cta"
            value={unitCountOption}
            onChange={handleUnitCountChange}
            subtext={unitCountError}
            error={!!unitCountError}
            placeholder="Units Under Management"
          />
          {unitCountError && <StyledSubtext error>{unitCountError}</StyledSubtext>}
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col d-flex flex-column align-items-center">
          <ButtonHabitat variant="primary" type="submit" id="final-cta-submit-button" disabled={isLoading}>
            Get in touch
          </ButtonHabitat>
        </div>
      </div>
    </form>
  );
};
