// @ts-nocheck
// ignoring file bc of two assignments? that I'm not sure if have ever worked
// lines 70 & 74
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { inputId, inputName } from 'utils/form';

import { formatDate, DATE_FORMAT, ISO_FORMAT } from './utils';
import type { IBaseProps, IBaseState } from './types';

type IProps = IBaseProps;
type IState = IBaseState & {
  displayValue: string;
  type: string | any;
};

class MobileDatePicker extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { resource, field } = this.props;
    const value = this.props.value;

    this.state = {
      value,
      open: false,
      inputId: inputId(resource, field),
      inputName: inputName(resource, field),
      dirty: false,
      displayValue: value,
      type: value ? 'date' : 'text'
    };
  }

  displayValue = (value: string): string => {
    const momented = moment(value, [DATE_FORMAT, ISO_FORMAT], true);

    return momented.isValid() ? momented.format(DATE_FORMAT) : value;
  };

  onChange = (value: moment.Moment) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  handleInputChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    this.setState({ value: formatDate(value), displayValue: value, type: 'date' });

    this.onChange(moment(value, 'YYYY-MM-DD'));
  };

  inputClassName = (): string => {
    const { dirty } = this.state;
    const { skipBaseStyle, className } = this.props.pickerProps || {};

    return (className || '') + (dirty ? ' ' : ' default-color-font') + (skipBaseStyle ? ' ' : ' form-control');
  };

  dateInput = () => {
    const { displayValue } = this.state;
    const { placeholderText } = this.props.pickerProps || {};

    return (
      <div className="mobile-date-picker-wrapper">
        <input
          type={this.state.type}
          id={`display_${this.state.inputId}`}
          name={`display_${this.state.inputName}`}
          placeholder={displayValue ? (this.state.type = 'date') : placeholderText}
          className={this.inputClassName()}
          onChange={this.handleInputChange}
          onFocus={(e) => (e.target.type = 'date')}
          onMouseOver={(e) => (e.target.type = 'date')}
          onBlur={(e) => (displayValue ? (e.target.type = 'date') : (e.target.type = 'text'))}
          value={displayValue}
          disabled={this.props.disabled}
          style={this.props.style}
          data-cy={this.props.dataCy}
        />
        <input
          type="hidden"
          id={this.state.inputId}
          name={this.state.inputName}
          value={this.state.value}
          readOnly={true}
        />
      </div>
    );
  };

  render() {
    return <Fragment>{this.dateInput()}</Fragment>;
  }
}

export default MobileDatePicker;
