import axios, { AxiosResponse } from 'axios';
import { ILast4CcDigits } from '../interfaces';
import { useQuery, QueryObserverResult } from 'react-query';
import { addCsrf } from 'components/v2/signup/redux/actions';

const LAST_4_CC_KEY = 'last_4_cc';

const getLastFourCcDigits = async (ins_number) => {
  const params = addCsrf({ ins_number });
  const config = {
    withCredentials: true,
    headers: { Accept: 'application/json' }
  };
  const { data }: AxiosResponse = await axios.get(`/cover_genius_policy_details/${ins_number}/credit_card_last_4`, {
    ...config,
    params
  });
  return data;
};

/**
 * Retrieves last 4 cc digits used on a policy
 * @param ins_number Policy INS number
 * @returns 4 cc
 */
export default function useGetLastFourCcDigits(ins_number): QueryObserverResult<ILast4CcDigits> {
  return useQuery<ILast4CcDigits>([LAST_4_CC_KEY, ins_number], () => getLastFourCcDigits(ins_number));
}
