import { formatDate } from 'components/v2/quote_display/utils';
import React from 'react';
import {
  Progress,
  ProgressBar,
  StyledAddress,
  Wrapper
} from './styles';
import { PALETTE, Button } from '@sayrhino/rhino-shared-js';
import AlertSVG from '../../assets/alert.svg';
import styled from '@emotion/styled';
import { ActionRequiredCardProps } from './types';
import getFeatureFlags from 'utils/getFeatureFlags';

const StyledWrapper = styled(Wrapper)({
  gap: '24px',
  padding: '24px'
});

const StyledHeader = styled.h3({
  fontFamily: 'MaisonNeueExtended',
  fontSize: '20px',
  lineHeight: '150%' /* 30px */,
  fontWeight: 600,
  marginBottom: '0px'
});

const StyledSubtext = styled(StyledAddress)({
  marginBottom: '0px'
});

const StyledInfo = styled.div({
  padding: '16px',
  background: PALETTE.neutral4,
  borderRadius: '8px',
  fontFamily: 'MaisonNeue',
  fontSize: '12px',
  lineHeight: '150%' /* 18px */,
  fontWeight: 500,
  letterSpacing: '0.18px'
});

export const ActionRequiredCard = ({ policyApplication, cardType }: ActionRequiredCardProps) => {
  const {
    lease_end_date,
    lease_start_date,
    full_address,
    stripe_microdeposits_hosted_verification_url
  } = policyApplication;

  const featureFlags = getFeatureFlags();

  const { actionName, extraInfo, buttonText, buttonLink, secondaryButtonText, secondaryButtonLink } = {
    'bank_verification': {
      actionName: 'Bank Verification',
      extraInfo:
        'Expect a $0.01 deposit to your account in 1-2 business days and an email \
        with additional instructions to verify your bank account.',
      buttonText: 'Verify in Stripe',
      buttonLink: stripe_microdeposits_hosted_verification_url,
      ...(featureFlags?.changePaymentMethod ? {
        secondaryButtonText: 'Update payment method',
        secondaryButtonLink: `/policy_applications/${policyApplication.id}/change_payment_method`
      } : {})
    },
    'payment_failed': {
      actionName: 'Deposit Payment Failed',
      extraInfo: null,
      buttonText: 'Update my payment',
      buttonLink: `/policy_applications/${policyApplication.id}/payment_retry`
    }
  }[cardType];

  return (
    <StyledWrapper>
      <ProgressBar>
        <Progress style={{ width: '66%', backgroundColor: PALETTE.raptor}}/>
      </ProgressBar>
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px'}}>
        <img src={AlertSVG}/>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <StyledHeader>ACTION REQUIRED: {actionName}</StyledHeader>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledSubtext>{full_address}</StyledSubtext>
            {lease_start_date && lease_end_date && (
              <StyledSubtext>{formatDate(lease_start_date)} - {formatDate(lease_end_date)}</StyledSubtext>
            )}
          </div>
        </div>
      </div>
      {extraInfo && <StyledInfo>{extraInfo}</StyledInfo>}
      {(buttonLink || secondaryButtonLink) &&
        (
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap:'6px' }}>
            {buttonLink &&
              (
                <Button
                  id="go-to-stripe"
                  style={{ padding: '8px 16px', fontSize: '12px', fontFamily: 'MaisonNeueExtended', height: '30px' }}
                  onClick={() => window.location.href = buttonLink}
                >
                  {buttonText}
                </Button>
              )
            }
            {secondaryButtonLink &&
              (
                <Button
                  id="pay-with-card"
                  style={{ padding: '8px 16px', fontSize: '12px', fontFamily: 'MaisonNeueExtended', height: '30px' }}
                  onClick={() => window.location.href = secondaryButtonLink}
                  variant="secondary"
                >
                  {secondaryButtonText}
                </Button>
              )
            }
          </div>
        )
      }
    </StyledWrapper>
  );
};
