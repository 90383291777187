import { Button, CloseIcon } from '@sayrhino/rhino-shared-js';
import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { formatCents } from 'utils/money/formatter';
import { addStripeFees } from 'utils/money/add_stripe_fees';
import { IClaims } from '../action_center/interfaces';
import { Plan, PlanSelect } from './selectComponents';
import useCreateStripeTokenWithZip from './useCreateStripeTokenWithZip';
import { getAxiosError } from '../action_center/components/PayNowModal/api';
import { RhinoLogger } from 'components/v2/shared/rhino_logger';
import {
  ClaimInfoDiv,
  Header,
  ListItem,
  ListLabel,
  ListValue,
  SubTitle,
  View,
  PaymentFieldView,
  ButtonContainer,
  ErrorContainer,
  ErrorItem,
  ErrorList,
  ProcessingFee,
  StyledP2,
  StyledP3,
  ModalControl,
  StyledLightP2,
  PaymentInputContainer,
  StyledCardNumber,
  StyledCardCvc,
  StyledCardExp,
  ZipCodeInput,
  PaymentLabel,
  inputOptions
} from './styles';
import axios from 'axios';
import { Link } from '../renters_insurance/components/SharedStyles';
import { formatDays } from '../../../utils/date/formatter';
import isPresent from 'utils/isPresent';
import { setPaymentPlanDuration, setStartDate, showSubrogationPaymentConfirmationModal } from './redux/reducers';
import { Subrogation } from './subroFormWrapper';
import getFeatureFlags from 'utils/getFeatureFlags';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createPaymentSetup } from './api';
import env from 'utils/env';

type IForm = {
  payment_plan: Plan;
  card_number: string;
  card_exp_date: string;
  zip_code: string;
  card_cvv: string;
};

type IProp = {
  claim: IClaims & {
    amount_cents: number;
    insurance_policy_id: number;
    id: number;
    unreimbursed_claim_amount_cents: number;
  };
  user: User;
  property: Property;
  insurance_policy_number: string;
  stripe_publishable_key: string;
  onCancelButtonClick?: () => void;
  onPayButtonClick: (subrogation: Subrogation) => void;
};

type User = {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
};

type Property = {
  address_line_one: string;
};

type PaymentPlan = {
  installments_number: number;
  start_date: string;
};

type Pay = {
  claim_id: number;
  payment_plan: {
    installments_number: number;
    stripe_token: string;
    payment_method_id: string;
  };
};

const saveToStripe = async (payload: Pay) => {
  const { claim_id, ...rest } = payload;
  return axios.post(`/claims/${claim_id}/payment_plans/`, rest);
};

const SetupSubroPaymentPlans: FC<IProp> = ({
  claim,
  user,
  property,
  insurance_policy_number,
  onCancelButtonClick,
  onPayButtonClick
}) => {
  const contactEmail = 'recovery@sayrhino.com';
  const [form, setForm] = useState<IForm>({} as IForm);
  const { mutate: createStripeSub, isLoading: creatingSub } = useMutation(saveToStripe);
  const [errors, setErrors] = useState<any>({});
  const createStripeToken = useCreateStripeTokenWithZip();
  const [zipCode, setZipCode] = useState<string>('');
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [paymentElementErrorMessage, setPaymentElementErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const featureFlags = getFeatureFlags();
  const subroStripePaymentElement = featureFlags?.subroStripePaymentElement;

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (isSuccessfullySubmitted && onCancelButtonClick) onCancelButtonClick();
  }, [isSuccessfullySubmitted]);

  const validateForm = (form_values: IForm, zip_code: string) => {
    if (subroStripePaymentElement) {
      return Boolean(form_values.payment_plan?.duration);
    } else {
      return isPresent(zip_code) && Boolean(form_values.payment_plan?.duration);
    }
  };

  const handleError = (error) => {
    setPaymentElementErrorMessage(error.message);
  };

  const handleChange = (key: keyof IForm, value: string | Plan) => {
    setForm((prev) => ({ ...prev, [key]: value }));
    if (typeof value !== 'string') {
      dispatch(setPaymentPlanDuration(value.duration));
    }
  };

  const canSubmit = () => {
    return !validateForm(form, zipCode) || creatingSub;
  };

  const paymentAvailable = () => {
    return claim.unreimbursed_claim_amount_cents > 0;
  };

  const handleZipCodeUpdate = (e): void => {
    setZipCode(e.target.value);
  };

  const onSubmitPaymentElement = async () => {
    setPaymentElementErrorMessage(undefined);

    if (!stripe || !elements) {
      logError('Missing required Stripe API references');
      return;
    }

    try {
      // @ts-ignore:next-line
      const { error: submitError } = await elements.submit();
      if (submitError) {
        logError(`Failed to submit Stripe elements: ${submitError.message}`);
        setErrors(submitError);
        return;
      }

      const { client_secret: clientSecret, id: setupId } = await createPaymentSetup(claim.id);

      // @ts-ignore:next-line
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: env('RHINO_URL') + '/users/edit'
        },
        redirect: 'if_required'
      });

      if (error) {
        logError(`Failed to confirm setup: ${error.message}`);
        handleError(error);
        return;
      }
      if (!setupIntent) {
        logError('Missing setup intent');
        return;
      }

      // @ts-ignore:next-line
      await createStripeSubscription('', setupIntent.payment_method);
    } catch (err: any) {
      const error = getAxiosError(err);
      handleError(error);
      setIsLoading(false);
    }
  };

  const onSubmitCardElement = async () => {
    setErrors({});
    try {
      const full_name = user.first_name + ' ' + user.last_name;
      const { stripeToken } = await createStripeToken(full_name, zipCode);
      await createStripeSubscription(stripeToken?.id);
    } catch (err: any) {
      const error = getAxiosError(err);
      logError(error);
      setErrors({ error });
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (subroStripePaymentElement) {
      await onSubmitPaymentElement();
    } else {
      await onSubmitCardElement();
    }
    setIsLoading(false);
  };

  const createStripeSubscription = async (stripeToken, paymentMethodId = '') => {
    const payload: Pay = {
      claim_id: claim.id,
      payment_plan: {
        installments_number: form.payment_plan.duration,
        stripe_token: stripeToken,
        payment_method_id: paymentMethodId
      }
    };

    createStripeSub(payload, {
      onSuccess(data) {
        onPayButtonClick(data.data);
        dispatch(setStartDate(data.data.start_date));
        setIsSuccessfullySubmitted(true);
        return;
      },
      onError(error: any) {
        setErrors(error?.response?.data?.errors);
      }
    });
  };

  const dateToday = new Date();
  const paymentPlanAmountCents = claim.unreimbursed_claim_amount_cents / form?.payment_plan?.duration;

  const logError = (error) => {
    const message = typeof error === 'string' ? error : error.message;
    const prefixed = `[SetupSubroPaymentPlans] [claim: ${claim.id}] [user: ${user.id}]`;
    RhinoLogger.error(`${prefixed} ${message}`);
  };

  return (
    <div style={{ background: 'white', position: 'relative' }}>
      {onCancelButtonClick && (
        <ModalControl>
          <CloseIcon onClick={onCancelButtonClick} className="cursor" data-cy="modalClose" />
        </ModalControl>
      )}
      <View>
        <Header>Pay off your claim</Header>
        <StyledP2>
          Your property at {property.address_line_one} filed a claim against you for {claim.claim_type}. Select a
          payment schedule to start paying back your claim of {formatCents(claim.unreimbursed_claim_amount_cents)}.
        </StyledP2>
        <ClaimInfoDiv id="subrogation-claim-info">
          <ListItem>
            <ListLabel>Policy Number</ListLabel>
            <ListValue># {insurance_policy_number}</ListValue>
          </ListItem>
          <ListItem>
            <ListLabel>Claim Number</ListLabel>
            <ListValue># {claim.claim_number}</ListValue>
          </ListItem>
          <ListItem>
            <ListLabel>Claim Type</ListLabel>
            <ListValue>{claim.claim_type}</ListValue>
          </ListItem>
          <ListItem>
            <ListLabel>Claim Amount</ListLabel>
            <ListValue>{formatCents(claim.unreimbursed_claim_amount_cents)}</ListValue>
          </ListItem>
          <ListItem>
            <ListLabel>Processing Fees</ListLabel>
            <ListValue>
              {form?.payment_plan?.duration
                ? formatCents(addStripeFees(paymentPlanAmountCents) - paymentPlanAmountCents)
                : '$ ——'}
            </ListValue>
          </ListItem>
        </ClaimInfoDiv>
        <StyledLightP2>Processing fees determined by payment plan selection</StyledLightP2>
        {errors?.length > 0 && (
          <ErrorContainer>
            <ErrorList>
              {errors.map((error) => (
                <ErrorItem>{error}</ErrorItem>
              ))}
            </ErrorList>
          </ErrorContainer>
        )}
        <SubTitle>Choose how you’d like to pay over time</SubTitle>
        <PaymentFieldView>
          <PlanSelect
            value={form.payment_plan}
            total_claim={claim.unreimbursed_claim_amount_cents}
            onChange={(value: Plan) => handleChange('payment_plan', value)}
            disabled={!paymentAvailable()}
          />
          {form.payment_plan && form.payment_plan.duration > 1 && (
            <StyledP3>
              Funds will automatically withdraw from your account on the {formatDays(dateToday.getDate())} of each month
            </StyledP3>
          )}
        </PaymentFieldView>
        <SubTitle>Enter your payment information</SubTitle>
        {paymentElementErrorMessage && (
          <ErrorContainer>
            <ErrorList>
              <ErrorItem>{paymentElementErrorMessage}</ErrorItem>
            </ErrorList>
          </ErrorContainer>
        )}
        {subroStripePaymentElement && (
          <div className="row">
            <div className="col-12">
              <PaymentElement />
            </div>
          </div>
        )}
        {!subroStripePaymentElement && (
          <div>
            <div className="row no-gutters">
              <PaymentInputContainer className="col-sm-12 card-element">
                <PaymentLabel>Credit or Debit Card Number</PaymentLabel>
                <StyledCardNumber
                  className="credit-card-input"
                  options={{ ...inputOptions, placeholder: '', disabled: !paymentAvailable() }}
                />
              </PaymentInputContainer>
            </div>
            <div className="row">
              <PaymentInputContainer className="col-sm-12 col-md-4">
                <PaymentLabel>Expiration Date</PaymentLabel>
                <StyledCardExp
                  className="credit-card-input"
                  options={{ ...inputOptions, disabled: !paymentAvailable() }}
                />
              </PaymentInputContainer>
              <PaymentInputContainer className="col-sm-12 col-md-4">
                <PaymentLabel>CVC/Security Code</PaymentLabel>
                <StyledCardCvc
                  className="credit-card-input"
                  options={{ ...inputOptions, placeholder: '', disabled: !paymentAvailable() }}
                />
              </PaymentInputContainer>
              <PaymentInputContainer className="col-sm-12 col-md-4">
                <PaymentLabel>Billing Zip Code</PaymentLabel>
                <ZipCodeInput
                  id="zip_code"
                  type="text"
                  onChange={(e) => handleZipCodeUpdate(e)}
                  disabled={!paymentAvailable()}
                />
              </PaymentInputContainer>
            </div>
          </div>
        )}
        <PaymentFieldView>
          <ProcessingFee>
            A 2.9% + $0.30 processing fee is applied to each installment payment made by card. I understand that by
            submitting the above information and clicking "Pay Now" below, I am agreeing to enroll in autopay for the
            payment plan I selected above. I authorize Rhino to electronically debit my account in accordance with this
            payment plan. To change my payment plan or unenroll from autopay, I can contact{' '}
            <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>.
          </ProcessingFee>
        </PaymentFieldView>
        <ButtonContainer>
          {onCancelButtonClick && (
            <Button
              variant="secondary"
              children="Cancel"
              onClick={onCancelButtonClick}
              id="subrogation_cancel_button"
            />
          )}
          <Button
            variant="primary"
            children={creatingSub ? 'Processing...' : 'Pay now'}
            onClick={onSubmit}
            disabled={canSubmit() && !isLoading}
            id="subrogation_pay_button"
          />
        </ButtonContainer>
      </View>
    </div>
  );
};

export default SetupSubroPaymentPlans;
