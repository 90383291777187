import React from 'react';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import env from 'utils/env';
import { RentersInsuranceFAQs } from '../renters_insurance/constants';

const FAQContainer = styled.div({
  background: PALETTE.neutral4,
  padding: '30px',
  borderRadius: '10px',
  marginBottom: '100px'
});

const FAQHeader = styled.h5([FONTS.h5, { '&&': { color: PALETTE.brand125, marginBottom: '0' } }]);
const SubHeader = styled.p([FONTS.p2Medium, { '&&': { color: PALETTE.neutral65, margin: '10px 0 0 0' } }]);
const Detail = styled.p([FONTS.p2Light, { '&&': { color: PALETTE.neutral65, margin: '0' } }]);
const Legal = styled.p([FONTS.labelLight, { '&&': { color: PALETTE.neutral65, margin: '0' } }]);
const LearnMoreDiv = styled.div({ marginTop: '10px' });
const LearnMore = styled.a([FONTS.h5, { color: PALETTE.interaction100 }]);

export const FAQ = () => {
  return (
    <>
      <FAQContainer data-cy="FAQ">
        <FAQHeader>Frequently Asked Questions</FAQHeader>
        {RentersInsuranceFAQs.map((question) => {
          return (
            <>
              <SubHeader>{question.text}</SubHeader>
              <Detail>{question.answer}</Detail>
              {question.legalCopy && <Legal>{question.legalCopy}</Legal>}
            </>
          );
        })}
        <LearnMoreDiv>
          <LearnMore href={env('RENTERS_INSURANCE_FAQ_URL')} target="_blank" data-cy="learnMore">
            Learn more
          </LearnMore>
        </LearnMoreDiv>
      </FAQContainer>
    </>
  );
};
