import React, { useEffect, useState } from 'react';

import { IPaymentProps, IResult } from '../signup/redux/actions';
import { PolicyApplication, User } from '../signup/redux/state';
import PaymentElementForm from './PaymentElementForm';
import PaymentUserAgreement from './PaymentUserAgreement';
import calculateTotalAmount from './calculateTotalAmount';
export interface IPaths {
  subscriptions_path: string;
  terms_of_service_path: string;
  electronic_transactions_agreement_path: string;
  taa_path: string;
}

export interface IPaymentContainerProps {
  coverGeniusRentersInsurance?: any;
  sdiPolicyPriceInfo?: any;
  paths: IPaths;
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  rentersInsuranceSelected?: boolean;
  user: IResult<User>;
}

const PaymentElementContainer = (props: IPaymentContainerProps) => {
  const [isUserAgreementAccepted, setIsUserAgreementAccepted] = useState(false);
  const [isUserAgreementError, setIsUserAgreementError] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (props.sdiPolicyPriceInfo) {
      setTotalAmount(calculateTotalAmount(props));
    }
  }, [props.sdiPolicyPriceInfo]);

  useEffect(() => {
    if (isUserAgreementAccepted) {
      setNextButtonDisabled(false);
      setIsUserAgreementError(false);
    }
  }, [isUserAgreementAccepted]);

  return (
    <PaymentElementForm
      user={props.user}
      payAndSubscribe={props.payAndSubscribe}
      policyApplication={props.policyApplication}
      nextButtonDisabled={nextButtonDisabled}
      setNextButtonDisabled={setNextButtonDisabled}
      isUserAgreementAccepted={isUserAgreementAccepted}
      setIsUserAgreementError={setIsUserAgreementError}
      totalAmount={totalAmount}
      notes={
        <PaymentUserAgreement
          setIsUserAgreementAccepted={setIsUserAgreementAccepted}
          isUserAgreementAccepted={isUserAgreementAccepted}
          isUserAgreementError={isUserAgreementError}
          {...props}
        />
      }
    />
  );
};

export default PaymentElementContainer;
