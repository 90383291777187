import styled from '@emotion/styled';
import { FONTS, PALETTE, ROUNDNESS, Button } from '@sayrhino/rhino-shared-js';

const Col = styled.div({ display: 'flex', flexDirection: 'column' });
const Info = styled.p([FONTS.p1Light, { color: PALETTE.neutralDark }]);
const Label = styled.p([FONTS.p2Light, { color: PALETTE.neutral65 }]);
const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 158,
  marginRight: '20%'
});
const LabelLightGray = styled.div(FONTS.p3Light, { color: PALETTE.neutral65 });
const Row = styled.div({ display: 'flex', flexDirection: 'row' });
const Section = styled.div({
  display: 'flex',
  flexDirection: 'column'
});
const SubHeader = styled.p([
  FONTS.h5,
  {
    '&&': {
      marginBottom: 8,
      color: PALETTE.neutral88
    }
  }
]);
const Title = styled.h2([FONTS.h2, { fontFamily: 'MaisonNeueExtendedMedium' }]);
const MobileTitle = styled.h2([FONTS.h2, { fontFamily: 'MaisonNeueExtendedMedium', paddingTop: '32px' }]);

const LabelMobile = styled(Label)({ '&&': { marginBottom: 4 } });
const InfoMobile = styled(Info)({ '&&': { marginBottom: 8 } });
const LabelDesktop = styled(Label)({ '&&': { marginBottom: 8 } });
const InfoDesktop = styled(Info)({ '&&': { marginBottom: 6 } });

export {
  Col,
  Info,
  Label,
  LabelContainer,
  LabelLightGray,
  Row,
  Section,
  SubHeader,
  Title,
  MobileTitle,
  LabelMobile,
  InfoMobile,
  LabelDesktop,
  InfoDesktop
};

export const ToggleButtonWrapper = styled.div([
  ROUNDNESS.round,
  {
    '&&': {
      background: PALETTE.neutral4,
      border: `1px solid ${PALETTE.neutral25}`,
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'space-between',
      padding: 3,
      marginTop: 24,
      marginLeft: 0,
      '&&': {
        '@media (max-width: 768px)': {
          background: PALETTE.neutral4,
          border: `1px solid ${PALETTE.neutral25}`,
          alignItem: 'left',
          justifyContent: 'left',
          marginLeft: 'auto',
          width: '100%'
        }
      }
    }
  }
]);

export const ToggleButtonStyle = styled(Button)([
  {
    '&&': {
      border: 'none',
      background: 'transparent',
      fontSize: 16,
      padding: '6px 22px',
      flexGrow: 1,
      outline: 'none',
      width: 133,
      font: FONTS.p1Medium,
      '&:focus': {
        outline: 'none'
      }
    }
  }
]);

export const activeButtonStyles = { color: 'white', background: PALETTE.neutralDark, justifyContent: 'center' };

export const nonActiveButtonStyles = {
  font: FONTS.p1Medium,
  background: PALETTE.neutral4,
  color: PALETTE.neutralDark,
  justifyContent: 'center'
};
