import React, { Component } from 'react';

interface IProps {
  checked: number;
  onLabel: string;
  offLabel: string;
  handleToggle: (event: any) => void;
  name: string;
  resource?: string;
}

class Toggle extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public onChange = (event: any) => {
    this.props.handleToggle(event.target.checked);
  }

  public render(): JSX.Element {
    const { checked, onLabel, offLabel, resource, name } = this.props;

    return (
      <div>
        <input
          checked={!!checked}
          onChange={this.onChange}
          className="react-switch-checkbox"
          id={`react-switch-new-` + name}
          type="checkbox"
          name={`${resource}[${name}]`}
          resource={resource}
          value={checked}
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new-` + name}
        >
          <span className={`react-switch-button`}>{checked ? onLabel : offLabel}</span>
          { !!checked &&
            <span className="active-state-text">{offLabel}</span>
          }

          { !checked &&
            <span className="inactive-state-text">{onLabel}</span>
          }
        </label>
      </div>
    );
  }
}

export default Toggle;
