import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { CloseIcon } from '@sayrhino/rhino-shared-js';
import { getInvoices, getHumanizedInvoicesTotal } from './utils';
import MissedPaymentSummary from './MissedPaymentSummary';
import PaymentMethods from './PaymentMethods';
import { PayNowCardProps } from './interfaces';

const Card = styled.div(`
  border: 1px solid ${PALETTE.neutral4};
  position: relative;
  background-color: white;
  border-radius: 16px;
  padding-top: 0px;
  padding: 40px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`);

const CloseIconContainer = styled.div({
  position: 'absolute',
  right: '8%',
  top: '3%'
});

const StyledHeader = styled.h3(FONTS.h3, {
  paddingBottom: '24px',
  marginBottom: 0
});

const PayNowCard = ({
  handleClosePayNowModal,
  handleSelectOption,
  handleSubmit,
  selectedOption,
  unpaidInvoicesBreakdown,
  handleZipCodeUpdate,
  defaultCard,
  submitting,
  errors,
  fullName,
  setSubmitting
}: PayNowCardProps) => {
  const invoices = getInvoices(unpaidInvoicesBreakdown);
  const humanizedInvoicesTotal = getHumanizedInvoicesTotal(unpaidInvoicesBreakdown);
  
  return (
    <Card>
      <CloseIconContainer onClick={handleClosePayNowModal}>
        <CloseIcon className={'cursor'} data-cy="modalCancel" />
      </CloseIconContainer>
      <StyledHeader>Pay Your Balance</StyledHeader>
      <MissedPaymentSummary totalDueToday={humanizedInvoicesTotal} invoices={invoices} />
      <PaymentMethods
        handleZipCodeUpdate={handleZipCodeUpdate}
        defaultCard={defaultCard}
        handleSubmit={handleSubmit}
        handleSelectOption={handleSelectOption}
        selectedOption={selectedOption}
        errors={errors}
        submitting={submitting}
        fullName={fullName}
        handleClosePayNowModal={handleClosePayNowModal}
        setSubmitting={setSubmitting}
      />
    </Card>
  );
};

export default PayNowCard;
