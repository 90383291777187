import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';

interface TestimonialCardProps {
  index: number;
  item: string;
  footers: string[];
  images: string[];
}

const CardAuthor = styled.span([FONTS.h5]);
const CardAuthorBusiness = styled.span([FONTS.p1Light]);
const CardContent = styled.div([FONTS.p1Light]);
const Padding = styled.div({
  paddingRight: '30px'
});

export const Spacer = styled.div({ marginBottom: '16px' });

export const TestimonialCard: React.FC<TestimonialCardProps> = (props) => {
  const { index, item, footers, images } = props;

  // In order to bold the testifier and not the business, we split the header and use <span> tags
  const author = item.split('@')[0];
  const business = item.split('@')[1];

  return (
    <Padding>
      <div className="carousel-card" key={index}>
        <div>
          <CardAuthor>{author}</CardAuthor> <CardAuthorBusiness>{business}</CardAuthorBusiness>
        </div>
        <Spacer />
        <CardContent>{footers[index]}</CardContent>
        <Spacer />
        <img src={images[index]} style={{ height: '48px', width: '48px', borderRadius: '24px' }} />
      </div>
    </Padding>
  );
};
