import React, { SyntheticEvent, useState } from 'react';
import { IChildProps } from './step_wrapper';

function InvitationFromAgent(props: IChildProps) {
  const [disabled, disableButton] = useState(false)
  const handleSubmit = (e: SyntheticEvent) => {
    disableButton(true)
    props.sendInvitationFromAgent()
  }

  return (
    <div id="inv-from-agent" className="flow-step--page">
      <h2 className="bold">
        You’ve been invited to enroll with Rhino
      </h2>
      <p className="p2">
        A leasing agent invited you to sign up for Rhino.
        If you’ve already started your Rhino application, accepting this
        invitation will replace your in-progress application with a new one.
      </p>
      <div className="sign-up__actions d-flex justify-content-between align-items-center action_divs">
        <input
          type="submit"
          className="btn-default transparent-button transparent-button--wide"
          value="Start enrolling"
          onClick={handleSubmit}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default InvitationFromAgent;
