import React, { useEffect } from 'react';
import { useAxiosDispatcher, listInvitations } from '../signup/api';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../signup/redux/actions';
import Invitation from './Invitation';

export default function Invitations() {
  const dispatch = useDispatch();
  const invitations = useSelector(actions.invitationsSelector);

  useEffect(() => { dispatch(actions.listInvitations()) }, []);

  return (
    <div>
      { invitations.map((invite) => <Invitation invite={invite} key={invite.id} />) }
    </div>
  )
}
