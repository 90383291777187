import { CancelTokenSource, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import axios, { Axios } from './axios';
import { useEffect, useRef, MutableRefObject, useState } from 'react';

import { csrfToken } from 'utils/document';
import { Invitation, PolicyApplication, User, RawInvitation, SECURITY_DEPOSIT_INSURANCE, CASH_DEPOSIT } from './redux/state';
import { addCsrf, IResult } from './redux/actions';
import { hasPartnerEnrollmentConflict, redirectToPartnerEnrollment } from './step_helpers';

type Wrapped<T, K extends keyof any> = {
  [P in K]: T;
};

type AsyncFunction<A extends any[], R> = (...args: A) => Promise<R>;
type APICall<A extends any[], R> = (config: AxiosRequestConfig) => AsyncFunction<A, R>;
type Dispatcher = <A extends any[], R>(apiCall: APICall<A, R>) => AsyncFunction<A, R>;

export function useAxiosDispatcher(): Dispatcher {
  const cancelTokens: MutableRefObject<Set<CancelTokenSource>> = useRef(new Set());

  useEffect(() => {
    return () => {
      cancelTokens.current.forEach((token) => {
        token.cancel('XHR canceled on component unmount');
      });
    };
  }, []);

  return <A extends any[], R>(f: APICall<A, R>) =>
    async (...args: A) => {
      const source = Axios.CancelToken.source();
      const config = { cancelToken: source.token };
      cancelTokens.current.add(source);
      const result = await f(config)(...args);
      cancelTokens.current.delete(source);
      return result;
    };
}

export const createUserAndPolicyApplication = (config: AxiosRequestConfig) =>
  async function (
    user: User,
    policyApplication: PolicyApplication
  ): Promise<{ user?: IResult<User>; policyApplication?: IResult<PolicyApplication> }> {
    const csrf: string = csrfToken();
    const userData = {
      user,
      property_id: policyApplication.property_id,
      authenticity_token: csrf
    };

    let userResult: IResult<User> = {};
    try {
      const u: AxiosResponse<{ user: User }> = await axios.post('/users.json', userData, config);
      userResult = { result: u.data.user };
    } catch (_e) {
      const e = _e as AxiosError<IResult<User>>;
      return { user: e.response?.data };
    }

    const url = `/users/${userResult.result?.id}/policy_applications.json`;
    const policyAppData = {
      policy_application: policyApplication,
      authenticity_token: csrf
    };
    let policyAppResult: IResult<PolicyApplication> = {};
    try {
      const p: AxiosResponse<{ policy_application: PolicyApplication }> = await axios.post(url, policyAppData, config);
      policyAppResult = { result: p.data.policy_application };
    } catch (_e) {
      const e = _e as AxiosError<IResult<PolicyApplication>>;
      if (hasPartnerEnrollmentConflict(e)) {
        // @ts-ignore
        return redirectToPartnerEnrollment(e.response?.data?.errors?.enrollment_link);
      } else {
        return { user: userResult, policyApplication: e.response?.data };
      }
    }
    return { user: userResult, policyApplication: policyAppResult };
  };

export const createPolicyApplication = (config: AxiosRequestConfig) =>
  async function (
    userId: number,
    policyApplication: PolicyApplication
  ): Promise<IResult<PolicyApplication> | undefined> {
    const csrf: string = csrfToken();
    const url = `/users/${userId}/policy_applications.json`;
    const policyAppData = {
      policy_application: policyApplication,
      authenticity_token: csrf
    };
    let policyAppResult: IResult<PolicyApplication> = {};
    try {
      const p: AxiosResponse<{ policy_application: PolicyApplication }> = await axios.post(url, policyAppData, config);
      policyAppResult = { result: p.data.policy_application };
    } catch (_e) {
      const e = _e as AxiosError<IResult<PolicyApplication>>;
      if (hasPartnerEnrollmentConflict(e)) {
        // @ts-ignore
        return redirectToPartnerEnrollment(e.response?.data?.errors?.enrollment_link);
      } else {
        return e.response?.data;
      }
    }
    return policyAppResult;
  };

export const checkInvitation =
  (config: AxiosRequestConfig) =>
  async (userId: number, policyApplicationId: number): Promise<IResult<Invitation>> => {
    const params = addCsrf({});
    const route = `/users/${userId}/policy_applications/${policyApplicationId}/check_invitation.json`;
    try {
      const result = await axios.get(route, { ...config, params });
      if (result.status === 204) {
        return {} as IResult<Invitation>;
      }
      const data: Invitation = result.data;
      return { result: data };
    } catch (_e) {
      return { errors: {} };
    }
  };

export const fetchInvitation =
  (config: AxiosRequestConfig) =>
  async (invitationToken: string): Promise<IResult<Invitation> | undefined> => {
    const params = addCsrf({});
    config.params = params;
    const route = `/invitations/${invitationToken}/fetch.json`;
    try {
      const result = await axios.get(route, config);
      const data: Wrapped<Invitation, 'invitation'> = result.data;
      return { result: data.invitation };
    } catch (_e) {
      return;
    }
  };

export const calculatePrice =
  (config: AxiosRequestConfig) => async ({
    userId,
    policyApplicationId,
    upfront,
    productType
  } : {
    userId: number,
    policyApplicationId: number,
    upfront: boolean,
    productType: typeof CASH_DEPOSIT | typeof SECURITY_DEPOSIT_INSURANCE
  }) => {
    const calculatePriceUrl = `/users/${userId}/policy_applications/${policyApplicationId}/calculate_price`;
    const params = addCsrf({ payment_info: { upfront }, product_type: productType });
    let result: AxiosResponse<{ policy_application: PolicyApplication }>;
    try {
      result = await axios.put(calculatePriceUrl, params, config);
    } catch (_e) {
      const e = _e as AxiosError<IResult<PolicyApplication>>;
      return e.response?.data || { errors: { request: [e.message] } };
    }
    const policyApplication: PolicyApplication = result.data.policy_application;
    return { result: policyApplication };
  };

export const calculatePriceAndCreateOrUpdatePaymentIntent = (config: AxiosRequestConfig) => async ({
    userId,
    policyApplicationId,
    upfront,
    productType
  } : {
    userId: number,
    policyApplicationId: number,
    upfront: boolean,
    productType: typeof CASH_DEPOSIT | typeof SECURITY_DEPOSIT_INSURANCE
  }) => {
    const calculatePriceAndCreateOrUpdatePaymentIntentUrl =
      `/users/${userId}/policy_applications/${policyApplicationId}/calculate_price_create_update_payment_intent`;
    const params = addCsrf({ payment_info: { upfront }, product_type: productType });
    let result: AxiosResponse<{ policy_application: PolicyApplication }>;
    try {
      result = await axios.put(calculatePriceAndCreateOrUpdatePaymentIntentUrl, params, config);
    } catch (_e) {
      const e = _e as AxiosError<IResult<PolicyApplication>>;
      return e.response?.data || { errors: { request: [e.message] } };
    }
    const policyApplication: PolicyApplication = result.data.policy_application;
    return { result: policyApplication };
  };

export const validInvitations = (config: AxiosRequestConfig) => async (userEmail: string, propertyId: number) => {
  const validInvitationsUrl = `/invitations/valid_invitation_at_property?email=${userEmail}&property_id=${propertyId}`;
  config.params = addCsrf({});
  let result: AxiosResponse<boolean>;
  try {
    result = await axios.get(validInvitationsUrl, config);
  } catch (_e) {
    return false;
  }

  return result.data;
};

export const listInvitationsHelper = (config: AxiosRequestConfig) => async () => {
  const url = '/invitations/sent';
  config.params = addCsrf({});
  let result: AxiosResponse<Invitation[]>;
  try {
    result = await axios.get(url, config);
  } catch (_e) {
    return [];
  }

  return result.data;
};

export function listInvitations(dispatchAPI: Dispatcher) {
  const [invitations, setInvitations] = useState<RawInvitation[]>([]);

  useEffect(() => {
    dispatchAPI(listInvitationsHelper)().then((result) => setInvitations(result));
  }, []);

  return invitations;
}