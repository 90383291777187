import Error from 'components/common/form/input/Error';
import Input from 'components/v2/input';
import Validation from 'components/v2/validation';
import { debounce } from 'lodash';
import React, {Component} from 'react';
import { csrfToken } from 'utils/document';
import { post } from 'utils/request';

interface IErrors {
  password: string[];
  password_confirmation: string[];
}

interface IProps {
  resource: string;
  token?: string;
  onChange?: (value: string) => void;
  onConfirmationChange?: (value: string) => void;
  setActiveButton?: (value: boolean) => void;
  errors?: IErrors;
  field_name?: string;
  submitButton?: boolean;
  dataCy?: string;
  dataCy2?: string;
}

interface IState {
  validStrength: boolean;
  isButtonDisabled: boolean;
  errors: string;
  passwordString: string;
  passwordConfirmation: string;
}

class CreatePassword extends Component<IProps, IState> {
  public validateStrength: (value: string) => void;

  constructor(props: IProps) {
    super(props);

    this.validateStrength = debounce(this._validateStrength.bind(this), 500);

    this.state = {
      validStrength: false,
      isButtonDisabled: true,
      errors: '',
      passwordString: '',
      passwordConfirmation: ''
    };
  }

  public handleChange = (value: string): void => {
    this.setState({passwordString: value, validStrength: false});
    if (this.props.setActiveButton) {
      this.props.setActiveButton(false);
    }
    this.validateStrength(value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  public handleConfirmationChange = (value: string): void => {
    this.setState({passwordConfirmation: value});
    this.setActiveButton(
      this.state.passwordString,
      value,
      this.state.validStrength
    );

    if (this.props.onConfirmationChange) {
      this.props.onConfirmationChange(value);
    }
  }

  public _validateStrength = (value: string) => {
    const token = csrfToken();
    post('/password/validate', { password: value, authenticity_token: token })
      .then((response: { valid: boolean }) => {
        if (response.valid === true) {
          this.setState({ validStrength: true});
        } else {
          this.setState({ validStrength: false});
        }
        this.setActiveButton(
          this.state.passwordString,
          this.state.passwordConfirmation,
          response.valid
        );
      }).catch((response: {errors: string}) => {
        const errors = response.errors || '';
        this.setState({ errors });
        if (this.props.setActiveButton) {
          this.props.setActiveButton(false);
        }
      });
  }

  public validatePasswords = (
    password: string,
    passwordConfirmation: string,
    validStrength: boolean
  ): {borderColor: string; buttonActive: boolean} => {
    const passwordsMatch = passwordConfirmation === password;
    if (passwordsMatch) {
      if (validStrength) {
        return {
          borderColor: '#E3E3E3',
          buttonActive: true
        };
      } else {
        return {
          borderColor: '#E3E3E3',
          buttonActive: false
        };
      }
    } else {
      return {
        borderColor: 'red',
        buttonActive: false
      };
    }
  }

  public setActiveButton = (password: string, passwordConfirmation: string, validStrength: boolean) => {
    if (this.props.setActiveButton) {
      const { buttonActive } = this.validatePasswords(password, passwordConfirmation, validStrength);
      this.props.setActiveButton(buttonActive);
    }
  }

  public renderError = (field: string, errors: string[]) => {
    if (!errors) { return; }
    return (
      errors.map((error, i) => {
        return <Error key={i} text={`${field} ${error}`} css="custom-error-text" />;
      })
    );
  }

  public renderSubmit = (buttonActive: boolean) => {
    if (this.props.submitButton) {
      return (
        <button
          type="submit"
          className="create-pw-btn"
          disabled={ !buttonActive }
          data-cy="submitPassword"
          >Create Password
        </button>
      );
    }
  }

  public renderHeader = () => {
    if (this.props.submitButton) {
      return (
        <div className="password-header">Create a password</div>
      );
    }
  }

  public render(): JSX.Element {
    const { resource, token, errors } = this.props;
    const { passwordString, passwordConfirmation, validStrength } = this.state;
    const { borderColor, buttonActive } =
      this.validatePasswords(passwordString, passwordConfirmation, validStrength);

    return (
      <div className="create-pw-section">
        <div className="password-container">
          {this.renderHeader()}
            <div>
            {errors && this.renderError('Password', errors.password)}
            <Input
              placeholder="New password"
              type="password"
              toggleVisibility={false}
              onChangeString={this.handleChange}
              resource={resource}
              field={this.props.field_name || 'password'}
              borderColor={borderColor}
              dataCy={this.props.dataCy || 'password'}
            />
            { token &&
              <input
                resource={resource}
                name={resource + '[reset_password_token]'}
                id={resource + '_reset_password_token'}
                style={styles.hidden}
                value={token}
              />
            }

            {errors && this.renderError('Password confirmation', errors.password_confirmation)}
            <Input
              placeholder="Re-enter password"
              type="password"
              toggleVisibility={false}
              resource={resource}
              onChangeString={this.handleConfirmationChange}
              field={'password_confirmation'}
              borderColor={borderColor}
              dataCy={this.props.dataCy2 || 'confirmPassword'}
            />

            <div data-cy="validationSection" style={styles.validationSection} id="validation-section">
              <Validation
                label={'At least 8 characters'}
                active={validStrength}
                dataCy={'passwordValidationLength'}
              />
              <Validation
                label={'At least 1 number'}
                active={validStrength}
                dataCy={'passwordValidationNumber'}
              />
              <Validation
                label={'At least 1 symbol'}
                active={validStrength}
                dataCy={'passwordValidationSymbol'}
              />
            </div>
            {this.renderSubmit(buttonActive)}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  validationSection: {
    paddingTop: '20px'
  },
  hidden: {
    display: 'none'
  }
};

export default CreatePassword;
