import React, { useState } from 'react';
import { connect } from 'react-redux';

import Slider from 'components/v2/drawer/slider';
import Backdrop from 'components/v2/drawer/backdrop';

import RoommateCard from './RoommateCard';
import { IRoommate } from '../policyProps';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  roommates: IRoommate[];
}

interface IRoommateProps {
  onClick: () => void;
  roommate: IRoommate;
}

const Button = styled.button([
  FONTS.p2Extended,
  {
    '&&': {
      border: `1px solid ${PALETTE.neutralDark}`,
      borderRadius: 24,
      fontWeight: 500,
      backgroundColor: PALETTE.white,
      marginRight: 12,
      marginBottom: 16,
      padding: '8px 20px'
    }
  }
]);

const Roommates: React.FC<IProps> = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => setIsOpen(true);

  const renderContent = () => {
    return (
      <div className="inner">
        <div className="header border-bottom">
          <h6 className="demi-18">Your Roommates</h6>
        </div>

        <div className="content">
          {props.roommates.map((roommate, index) => (
            <RoommateCard
              key={roommate?.id || roommate?.temporaryId}
              index={index}
              numeration={props.roommates.length - index}
              roommate={roommate}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="roommates" style={{ display: 'inline' }}>
      <div className="drawer" style={{ display: 'inline' }}>
        <Slider
          show={isOpen}
          renderContent={renderContent}
          close={() => setIsOpen(false)}
          buttonBackground={'#F5F4FB'}
        />

        {isOpen && <Backdrop close={() => setIsOpen(false)} />}
      </div>

      <Button onClick={onClick}>Add a roommate</Button>
    </div>
  );
};

const mapStateToProps = ({ roommates }) => ({ roommates });

export default connect(mapStateToProps)(Roommates);
