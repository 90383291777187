import React from 'react';
import { LabelLightGray } from './styles';
import styled from '@emotion/styled';
import env from 'utils/env';
import { PALETTE } from '@sayrhino/rhino-shared-js';

export const NeedToKnowText = styled(LabelLightGray)({ '&&': { marginBottom: 10 } });
const Link = styled.a({
  '&&': {
    textDecoration: 'underline',
    textDecorationColor: PALETTE.neutral65,
    color: PALETTE.neutral65
  }
});

interface INeedToKnowProps {
  address: { street_address: string; city: string; state: string; zip: string; unit?: string };
  inAccount: boolean;
  hasRentersInsurance?: boolean;
}

export function RenderNeedToKnow(props: INeedToKnowProps) {
  const { address } = props;
  const renderRhodeIslandNotice = () => {
    if (address.state === 'RI') {
      return (
        <NeedToKnowText className="notice" data-cy="quoteInfoBulletRI">
          NOTICE - THIS INSURANCE CONTRACT WILL BE PLACED WITH AN INSURER NOT LICENSED TO DO BUSINESS IN THE STATE OF
          RHODE ISLAND BUT APPROVED AS A SURPLUS LINES INSURER. THE INSURER IS NOT A MEMBER OF THE RHODE ISLAND INSURERS
          INSOLVENCY FUND. SHOULD THE INSURER BECOME INSOLVENT, THE PROTECTION AND BENEFITS OF THE RHODE ISLAND INSURERS
          INSOLVENCY FUND ARE NOT AVAILABLE.
        </NeedToKnowText>
      );
    }
  };
  return props.inAccount || props.hasRentersInsurance ? (
    <NeedToKnowText>
      Renters insurance provides coverage for specific perils (e.g. theft or fires) and liability coverage for lawsuits
      caused by accidents in your home. It also covers theft and damage for your belongings, which are covered even when
      you’re outside your home. Please review your policy documents for any applicable coverage limits, sub-limits, or
      exclusions.
    </NeedToKnowText>
  ) : (
    <>
      <NeedToKnowText className="need-to-know">
        Your Rhino quote is based on the personal information you provide and the amount of coverage required by your
        landlord to protect your unit and, for those who don’t qualify for the lease on their own,&nbsp;
        <Link href={env('GUARANTOR_URL')} target="blank">
          guarantee coverage
        </Link>
        . Landlords may select a coverage limit that is greater than the amount of a cash security deposit. Check with
        your landlord if you have any questions about your coverage requirements.
      </NeedToKnowText>
      <NeedToKnowText className="need-to-know">
        As long as you pay your rent on time and keep your unit in good condition, you’ll only pay your Rhino fee.
      </NeedToKnowText>
      <NeedToKnowText className="need-to-know">
        Of course, you’re still responsible if you don’t pay your rent or cause damage to the apartment, and if a valid
        claim is submitted by your landlord, you’ll have to pay Rhino back.
      </NeedToKnowText>
      {renderRhodeIslandNotice()}
    </>
  );
}
