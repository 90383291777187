import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import env from './env';
import { Collection } from 'components/v2/blog/digest.interface';

interface contenfulData {
  contentType: string;
  category: string;
}

const client = createClient({
  space: env('CONTENTFUL_SPACE_ID'),
  accessToken: env('CONTENTFUL_ACCESS_TOKEN'),
  environment: env('CONTENTFUL_ENVIRONMENT'),
  host: env('CONTENTFUL_API_URL')
});

const limit = 8;

const useContentful = ({ contentType, category }: contenfulData): [Collection[], boolean, () => void, boolean] => {
  const [data, setData] = useState<Collection[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [showMore, setShowMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const queryContentful = () => {
    setLoading(true);
    client
      .getEntries({
        content_type: contentType,
        'fields.category[match]': category,
        order: '-sys.createdAt',
        limit,
        skip
      })
      .then((entry: any) => {
        setData([...data, ...entry.items]);
        setSkip(skip + limit);
        setShowMore(skip + limit < entry.total);
        setLoading(false);
      });
  };

  useEffect(() => {
    queryContentful();
  }, []);

  return [data, loading, queryContentful, showMore];
};

export { useContentful };
