import styled from '@emotion/styled';
import { ActionButton as ButtonBase, PALETTE, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import { getColorProperties } from './helpers';

export const Wrapper = styled.section([
  ROUNDNESS.m,
  {
    fontFamily: 'MaisonNeueLight',
    background: PALETTE.white,
    border: `1px solid ${PALETTE.neutral12}`,
    padding: `21px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    margin: '16px 0',
    textDecoration: 'none',
    '@media (max-width: 992px)': {
      width: '100%'
    }
  }
]);

export const StyledHeader = styled.h2({
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%' /* 36px */,
  letterSpacing: '0.48px',
  color: '#000',
  marginBottom: '8px'
});

export const StyledStatus = styled.span<{ status: string }>`
  color: ${(props) => getColorProperties(props.status).color};
  background: ${(props) => getColorProperties(props.status).backgroundColor};
  border-radius: 24px;
  border: ${(props) => `1px solid ${getColorProperties(props.status).borderColor}`};
  font-family: MaisonNeueLight;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 2px 8px;
`;

export const StyledAddress = styled.h4({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%' /* 21px */,
  letterSpacing: '0.21px'
});

export const StyledAmountSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '24px 0'
});

export const StyledAmountHeading = styled.p`
  font-family: 'MaisonNeueLight';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 0.18px;
  margin-bottom: 0;
`;

export const StyledLeaseDate = styled.p`
  font-family: 'MaisonNeueMedium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
`;

export const StyledAmount = styled.p<{ color }>`
  color: ${(props) => props.color};
  font-family: 'MaisonNeueMedium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
`;

export const ProgressBar = styled.div({
  width: '100%',
  height: '8px',
  backgroundColor: '#eee',
  borderRadius: '10px',
  textAlign: 'center',
  position: 'relative'
});

export const Progress = styled.div({
  height: '100%',
  borderRadius: '8px 0px 0px 8px'
});

export const StyledActionButton = styled(ButtonBase)({
  '&&': {
    textAlign: 'center',
    color: PALETTE.white,
    backgroundColor: PALETTE.raptor,
    borderRadius: '24px',
    padding: '0px 20px',
    fontSize: '14px',
    fontFamily: 'MaisonNeueExtendedMedium',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#502cb9',
      cursor: 'pointer'
    },
    '@media (max-width: 600px)': {
      fontSize: '12px',
      padding: '0px 16px'
    }
  }
});

export const StyledActionSection = styled.section({
  display: 'flex',
  alignItems: 'center'
});

export const StyledCardParagraph = styled.p({
  fontFamily: 'MaisonNeueLight',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%' /* 21px */,
  letterSpacing: '0.21px',
  marginBottom: '24px'
});

export const StyledLeaseTerm = styled.section({});
export const StyledAmountContainer = styled.div({});
