export interface IEmploymentOption {
  label: string;
  value: number;
}

const EmploymentOptions: IEmploymentOption[] = [
  {label: 'Employed', value: 1},
  {label: 'Self-Employed', value: 2},
  {label: 'Unemployed', value: 3},
  {label: 'Retired', value: 4},
  {label: 'Student', value: 0}
];

export default EmploymentOptions;
