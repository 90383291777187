import axios, { AxiosError } from 'axios';
import { useMutation, MutationFunction } from 'react-query';
import { useDispatch } from 'react-redux';
import { WrappedPolicyApplication } from '../interfaces';
import { addCsrf, IResult, updatePolicyApplicationAndAdvanceSync, usePartnerEnrollment } from '../redux/actions';
import { CASH_DEPOSIT, PolicyApplication } from '../redux/state';
import { coerceUndefined, redirectToPartnerEnrollment, verifyResponseForConflict } from '../step_helpers';

export interface IUpdatePolicyApplication {
  policyApplication: PolicyApplication;
  userId: number;
  policyApplicationId: number;
}

export const updatePolicyApplication: MutationFunction<PolicyApplication, IUpdatePolicyApplication> = async ({
  policyApplication,
  userId,
  policyApplicationId
}) => {
  const body = addCsrf({
    policy_application: coerceUndefined(policyApplication)
  });
  const url = `/users/${userId}/policy_applications/${policyApplicationId}.json`;
  const result = await axios.put<WrappedPolicyApplication>(url, body);
  return result.data.policy_application;
};

export function useUpdatePolicyApplication(currentStep?: string) {
  const dispatch = useDispatch();
  return useMutation(updatePolicyApplication, {
    onSuccess: (policyApplication) => {
      dispatch(updatePolicyApplicationAndAdvanceSync({ result: policyApplication }, currentStep));
    },
    onError: (e: AxiosError<IResult<PolicyApplication>>) => {
      const errors = e.response === undefined ? { errors: { request: [e.message] } } : e.response.data;
      verifyResponseForConflict(e).then(
        () => dispatch(updatePolicyApplicationAndAdvanceSync(errors, currentStep))
      ).catch((enrollmentRedirectUrl: string) => {
        // @ts-ignore
        if (currentStep === 'products_offered' && e.response?.data?.errors?.selected_product === CASH_DEPOSIT) {
          dispatch(usePartnerEnrollment());
        } else {
          redirectToPartnerEnrollment(enrollmentRedirectUrl);
        }
      });
    }
  });
}
