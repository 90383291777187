import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { memo } from 'react';

const StyledPaymentLineItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  lineHeight: `24p`,
  justifyContent: 'space-between'
});

const StyledMissedPaymentLabel = styled.div([
  FONTS.p2Medium,
  {
    color: `${PALETTE.neutral88}`,
    marginBottom: 0
  }
]);

const StyledContainer = styled.div({
  background: PALETTE.white,
  border: `1px solid ${PALETTE.neutral12}`,
  maxHeight: '300px',
  backgroundColor: '#F5F5F5',
  overflowY: 'auto',
  borderRadius: 8,
  padding: '15px',
  '& button': {
    display: 'inline-block'
  },
  margin: '10px auto'
});

const StyledLabel = styled.h3([
  FONTS.p2Light,
  {
    color: `${PALETTE.neutral88}`,
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '36px',
    flexDirection: 'column',
    marginBottom: 0
  }
]);

const StyledTotalDueContainer = styled.div([
  {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    lineHeight: `24px`,
    paddingTop: `12px`,
    borderTop: `1px solid ${PALETTE.neutral12}`,
    justifyContent: 'space-between'
  }
]);

const StyledTotalDueText = styled.p([
  FONTS.p2Medium,
  {
    flexGrow: 1,
    paddingBottom: '0px',
    marginBottom: '0px',
    fontSize: '14px',
    fontWeight: 500
  }
]);

const StyledTotalDueAmount = styled.p([
  FONTS.p2Medium,
  {
    color: `${PALETTE.neutral88}`,
    fontWeight: 900,
    fontSize: '14px',
    paddingBottom: '0px',
    marginBottom: '0px'
  }
]);

const buildMissedPaymentText = (paymentDate) => `Missed Payment, ${paymentDate}`;

const MissedPaymentSummary = ({ invoices, totalDueToday }) => {
  const renderLineItem = ({ index, invoice }) => (
    <StyledPaymentLineItem data-cy="missedPaymentSummary" key={`${index}-${invoice.humanized_total}`}>
      <StyledLabel> {buildMissedPaymentText(invoice.humanized_created)} </StyledLabel>
      <StyledMissedPaymentLabel>{invoice.humanized_total}</StyledMissedPaymentLabel>
    </StyledPaymentLineItem>
  );

  const renderPaymentLineItems = () => invoices.map((invoice, index) => renderLineItem({ index, invoice }));

  return (
    <StyledContainer>
      {renderPaymentLineItems()}
      <StyledTotalDueContainer>
        <StyledTotalDueText>Total Due Today</StyledTotalDueText>
        <StyledTotalDueAmount>{totalDueToday}</StyledTotalDueAmount>
      </StyledTotalDueContainer>
    </StyledContainer>
  );
};

const missedPaymentPropsAreEqual = (prevProps, nextProps) => {
  const sameInvoices = prevProps.invoices.length === nextProps.invoices.length;
  const sameTotalDue = prevProps.totalDueToday === nextProps.totalDueToday;
  return sameInvoices && sameTotalDue;
};

export default memo(MissedPaymentSummary, missedPaymentPropsAreEqual);
