import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Map, { IProps as IMapProps } from 'components/v2/signup/map_with_overlay/map';
import { IPaymentProps, IResult } from 'components/v2/signup/redux/actions';
import { IPartnerRentersInsuranceRequirements, PolicyApplication, User } from 'components/v2/signup/redux/state';
import PaymentPanel from './payment';
import { IPaths } from './payment_form';
import QuoteOptionToggle from './quote_option_toggle';
import { PaymentOption } from './utils';
import env from 'utils/env';
import { SignupCopy } from '../signup/constants/copy';

export interface IProps {
  price: {
    monthly: string;
    upfront: string;
    upfront_discount?: string;
  };
  map: IMapProps;
  address: {
    street_address: string;
    city: string;
    state: string;
    zip: string;
    unit: string;
  };
  user: IResult<User>;
  coverage: {
    start_date: string;
    end_date: string;
    amount: string;
    policy_term?: number;
  };
  lease: {
    start_date: string;
    end_date: string;
  };
  payment: {
    paths: IPaths;
    full_name: string;
    loaderImage?: string;
    submitEnabled: boolean;
    setSubmitEnabled: (bool) => void;
    dataCy?: string;
  };
  edit_path: string;
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  total?: string;
  copy?: SignupCopy;
  partnerRentersInsuranceRequirements: IResult<IPartnerRentersInsuranceRequirements>;
}

export interface IState {
  paymentOption: PaymentOption;
}

class QuoteDisplay extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      paymentOption: PaymentOption.Upfront
    };
  }

  public render(): ReactNode {
    const { edit_path } = this.props;

    return (
      <div className="container" id="quote-display">
        <div className="row">
          <div className="col-12">
            <Link to={edit_path} id="back" data-cy="back">
              Back
            </Link>
          </div>
        </div>
        <div className="row align-items-start">
          {this.renderQuotePanel()}
          {this.renderPaymentPanel()}
        </div>
      </div>
    );
  }

  private updatePaymentOption(paymentOption: PaymentOption) {
    this.setState({ paymentOption });
  }

  private renderQuotePanel(): ReactNode {
    const { price } = this.props;
    const { paymentOption } = this.state;

    return (
      <div className="col-lg-7 col-sm-12 quote-panel">
        <h2 className="bold" data-cy="quoteHeader">
          Your personalized policy
        </h2>
        <QuoteOptionToggle price={price} paymentOption={paymentOption} onChange={this.updatePaymentOption.bind(this)} />
        {this.renderNeedToKnow()}
        <hr />
        {this.renderInformationPanels()}
      </div>
    );
  }

  private renderNeedToKnow(): ReactNode {
    return (
      <div className="need-to-know" data-cy="quoteInfo">
        <p className="p1 demi" data-cy="quoteInfoTitle">
          What you need to know
        </p>
        <ul data-cy="quoteInfoBullets">
          <li>
            Your Rhino quote is based on the personal information you provide and the amount of coverage required by
            your landlord to protect your unit and, for those who don’t qualify for the lease on their own,&nbsp;
            <a href={env('GUARANTOR_URL')} style={{ textDecoration: 'underline' }} target="blank">
              guarantor coverage
            </a>
            . Landlords may select a coverage limit that is greater than the amount of a cash security deposit. Check
            with your landlord if you have any questions about your coverage requirements.
          </li>
          <li>
            As long as you pay your rent on time and keep your unit in good condition, you’ll only pay your Rhino fee.
          </li>
          <li>
            Of course, you’re still responsible if you don’t pay your rent or cause damage to the apartment and if a
            valid claim is submitted by your landlord, you’ll have to pay Rhino back.
          </li>
          {this.renderRhodeIslandNotice()}
        </ul>
      </div>
    );
  }

  private renderInformationPanels(): ReactNode {
    const { address, coverage, map, user, edit_path, policyApplication } = this.props;

    return (
      <div className="your-information">
        <div className="header">
          <p className="p1 demi">Your leasing information</p>
        </div>
        <div className="map">
          <Map.Component {...map} />
        </div>
        <div className="row no-gutters information-panels">
          <div className="col-lg-4 col-12-sm">
            <p className="subheading">Leasing</p>
            <div className="content">
              {address.street_address} <br />
              {address.city}, {address.state} {address.zip} <br />
              {address.unit}
            </div>
          </div>
          <div className="col-lg-4 col-12-sm">
            <p className="subheading">Personal</p>
            <div className="content">
              {policyApplication?.result?.full_name} <br />
              {user?.result?.email} <br />
              {user?.result?.phone}
            </div>
          </div>
          <div className="col-lg-4 col-12-sm">
            <p className="subheading">Coverage</p>
            <div className="content">
              {coverage.start_date} &mdash; <br />
              {coverage.end_date} <br />
              {coverage.amount}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderPaymentPanel(): ReactNode {
    const { paymentOption } = this.state;
    const { payAndSubscribe, policyApplication, user, total } = this.props;
    const paymentPanelProps = Object.assign({ paymentOption }, this.props.payment, {
      payAndSubscribe,
      policyApplication,
      user,
      total
    });

    return (
      <div className="col-lg-5 col-sm-12 payment-card">
        <h6>Finish your enrollment</h6>
        <PaymentPanel dataCy="hello" {...paymentPanelProps} />
      </div>
    );
  }

  private renderRhodeIslandNotice = () => {
    if (this.props.address.state === 'RI') {
      return (
        <li data-cy="quoteInfoBulletRI">
          NOTICE - THIS INSURANCE CONTRACT WILL BE PLACED WITH AN INSURER NOT LICENSED TO DO BUSINESS IN THE STATE OF
          RHODE ISLAND BUT APPROVED AS A SURPLUS LINES INSURER. THE INSURER IS NOT A MEMBER OF THE RHODE ISLAND INSURERS
          INSOLVENCY FUND. SHOULD THE INSURER BECOME INSOLVENT, THE PROTECTION AND BENEFITS OF THE RHODE ISLAND INSURERS
          INSOLVENCY FUND ARE NOT AVAILABLE.
        </li>
      );
    } else return null;
  };
}

export default QuoteDisplay;
