import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button as RhinoButton, TextInput as RhinoTextInput } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import Modal from './Modal';
import { CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';
import useUpdateInterestedParty from '../hooks/useUpdateInterestedPolicy';
import { IUpdateInterestedPartyParams } from '../interfaces';
import { AxiosResponse } from 'axios';

// desktop
const ButtonWrapper = styled.div({
  marginTop: 47,
  display: 'flex',
  justifyContent: 'flex-end'
});

const CancelButton = styled.button([
  FONTS.h5,
  {
    background: PALETTE.white,
    borderRadius: '100px',
    color: PALETTE.raptor,
    borderColor: PALETTE.raptor,
    border: '1px solid',
    marginRight: '16px',
    padding: '10px 16px'
  }
]);

const CancelButtonMobile = styled.button([
  FONTS.h5,
  {
    background: PALETTE.white,
    borderRadius: '100px',
    color: PALETTE.raptor,
    borderColor: PALETTE.raptor,
    border: '1px solid',
    marginTop: '16px',
    padding: '9px 16px',
    width: '100%'
  }
]);

const Title = styled.h3([FONTS.h3, { marginBottom: 4 }]);

const SubTitle = styled.p([
  FONTS.p1Light,
  {
    color: PALETTE.neutral65
  }
]);

const ButtonWrapperMobile = styled.div({
  marginTop: 42
});

const Button = styled(RhinoButton)([
  FONTS.h5,
  {
    background: PALETTE.raptor,
    width: '327px',
    justifyContent: 'center',
    'button&:disabled': { background: PALETTE.neutral12, color: PALETTE.neutral55 }
  }
]);

const MobileButton = styled(RhinoButton)([
  FONTS.h5,
  {
    background: PALETTE.raptor,
    width: '100%',
    justifyContent: 'center',
    'button&:disabled': { background: PALETTE.neutral12, color: PALETTE.neutral55 }
  }
]);

const TextInput = styled(RhinoTextInput)([
  {
    '&>label': FONTS.p2Light
  }
]);

const CloseIconContainer = styled.div({
  paddingBottom: 16,
  justifyContent: 'right',
  textAlign: 'right'
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer',
  textAlign: 'right'
});

const TopDiv = styled.div({
  margin: '0px'
});

interface InterestedPartyModalProps {
  onClose: () => void;
  isOpen: boolean;
  onComplete: () => void;
  insNumber: string;
  setError: () => void;
  interestedPartyEmail?: string;
  interestedPartyName?: string;
  interestedPartyAddress?: string;
}
const InterestedPartyModal = (props: InterestedPartyModalProps) => {
  const isMobile = useCheckMobile(768);
  const { isOpen, onClose, onComplete, setError } = props;
  const [propertyEmail, setPropertyEmail] = useState(props.interestedPartyEmail ? props.interestedPartyEmail : '');
  const [propertyName, setPropertyName] = useState(props.interestedPartyName ? props.interestedPartyName : '');
  const [propertyAddress, setPropertyAddress] = useState(
    props.interestedPartyAddress ? props.interestedPartyAddress : ''
  );
  const [buttonDisabled, setButtonDisabled] = useState(props.interestedPartyEmail ? false : true);
  const { mutate: updateInterestedParty } = useUpdateInterestedParty();

  const validateEmail = (email) => /^['A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

  const setEmail = (email) => {
    setPropertyEmail(email);
    setButtonDisabled(!validateEmail(email));
  };

  const addInterestedParty = () => {
    const interestedPartyData: IUpdateInterestedPartyParams = {
      ins_number: props.insNumber,
      email: propertyEmail,
      name: propertyName,
      address: propertyAddress
    };

    updateInterestedParty(
      { ...interestedPartyData },
      {
        onSuccess: (response: AxiosResponse) => {
          onComplete();
        },
        onError: (error: any) => {
          setError();
          onComplete();
        }
      }
    );
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <TopDiv>
          <CloseIconContainer>
            <CloseIcon onClick={onClose} />
          </CloseIconContainer>
        </TopDiv>
        <div>
          <Title data-cy={'addInterestedParty'}>
            {props.interestedPartyEmail ? 'Edit' : 'Add an'} interested party/landlord
          </Title>
          <SubTitle>
            Enter the details your landlord provided you with below, and they’ll be notified of any changes to your
            policy
          </SubTitle>
          <TextInput
            id="textInput"
            data-cy={'propertyEmail'}
            label="Property email (they will be notified)*"
            onChange={(e) => setEmail(e.target.value)}
            value={propertyEmail}
          />
          <TextInput
            id="textInput"
            data-cy={'propertyName'}
            label="Property name"
            onChange={(e) => setPropertyName(e.target.value)}
            value={propertyName}
          />
          <TextInput
            id="textInput"
            data-cy={'propertyAddress'}
            label="Property address"
            onChange={(e) => setPropertyAddress(e.target.value)}
            value={propertyAddress}
          />
        </div>
        {isMobile ? (
          <ButtonWrapperMobile>
            <MobileButton data-cy={'addInterestedParty'} disabled={buttonDisabled} onClick={addInterestedParty}>
              {props.interestedPartyEmail ? 'Update' : 'Add'} Interested Party
            </MobileButton>
            <CancelButtonMobile onClick={onClose} data-cy="cancel">
              Cancel
            </CancelButtonMobile>
          </ButtonWrapperMobile>
        ) : (
          <ButtonWrapper>
            <CancelButton onClick={onClose} data-cy="cancel">
              Cancel
            </CancelButton>
            <Button data-cy={'addInterestedParty'} onClick={addInterestedParty} disabled={buttonDisabled}>
              {props.interestedPartyEmail ? 'Update' : 'Add'} Interested Party
            </Button>
          </ButtonWrapper>
        )}
      </div>
    </Modal>
  );
};

export default InterestedPartyModal;
