import React from 'react';

export const CancellationRequestStatusIndicator = (props) => {
  const { cancellationRequest } = props;
  const effectiveFrom = new Date(cancellationRequest?.effective_from);
  const today = new Date();

  const cssStyles = () => {
    return { ...styles.base, ...styles[cancellationStatus()] };
  };

  const showStatus = () => {
    return statusMapping[cancellationStatus()];
  };

  const cancellationStatus = () => {
    switch (cancellationRequest.status) {
      case 'accepted':
      case 'autocancelled':
        if (effectiveFrom > today) {
          return 'scheduled';
        } else {
          return 'approved';
        }
        break;
      default:
        return cancellationRequest.status;
        break;
    }
  };

  return (cancellationRequest && <div style={cssStyles()}>{showStatus()}</div>) || <></>;
};

const statusMapping: Record<string, string> = {
  pending: 'Cancellation Pending',
  declined: 'Cancellation Denied',
  scheduled: 'Cancellation Scheduled',
  approved: 'Cancellation Approved'
};

const styles = {
  base: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '14px',
    textAlign: 'center' as 'center',
    display: 'inline-block',
    padding: '0px 10px'
  },
  pending: {
    color: '#5A6468',
    background: '#F5F6F7'
  },
  approved: {
    color: '#12873D',
    background: '#E8FCEF'
  },
  declined: {
    color: '#DB3700',
    background: '#FFECE5'
  },
  scheduled: {
    color: '#5A6468',
    background: '#F5F6F7'
  }
};

export default CancellationRequestStatusIndicator;
