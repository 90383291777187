import React from 'react';

type Props = { text: string; className?: string };

const ErrorText = ({ text, className = 'error-message__text' }: Props) => {
  return (
    <div className="error-message">
      <span data-cy="errorText" className={className}>{text}</span>
    </div>
  );
};

export default ErrorText;
