import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import CheckSVG from '../assets/complete-check.svg';
import { formatCents } from 'utils/money/formatter';
import { NonCancelledWithdrawal, WithdrawalState } from './RenterWithdrawalFunds/types';
import { initializeDate, stringifyDate } from '../utils';

const WithdrawalCard = styled.div({
  backgroundColor: '#FFFFFF',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '8px',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  margin: '16px 0',
  // desktop
  '@media (max-width: 992px)': {
    maxWidth: 'none',
    width: '100%'
  }
});

const StyledStatus = styled.label([
  FONTS.p2Medium,
  {
    background: '#eee',
    borderRadius: '15px',
    padding: '3px 10px 3px 10px'
  }
]);

const DepositText = styled.div(FONTS.h6);

const Header = styled.h6([FONTS.h6, { margin: '20px 16px 0px 16px' }]);
const Text = styled.p([FONTS.p3Medium, { margin: '0px 16px 4px 16px', color: PALETTE.neutral55 }]);

const HeaderTop = styled.div({
  display: 'flex',
  width: '95%',
  justifyContent: 'space-between',
  margin: 'auto',
  padding: '24px 5px'
});

const BottomSection = styled.div({
  display: 'flex',
  width: '95%',
  justifyContent: 'center',
  margin: 'auto',
  padding: '18px 15px',
  flexDirection: 'column',
  alignItems: 'center'
});

const ActionButton = styled.button([
  FONTS.h6,
  {
    background: PALETTE.white,
    borderRadius: '100px',
    color: PALETTE.alert125,
    border: 'none',
    '&:focus': {
      outline: 'none'
    }
  }
]);

const ProgressBar = styled.div({
  width: '65%',
  height: '16px',
  backgroundColor: '#eee',
  borderRadius: '10px',
  margin: 'auto',
  textAlign: 'center',
  position: 'relative'
})

const Progress = styled.div({
  height: '100%',
  borderRadius: '10px 0px 0px 10px'
})

interface IProps {
  withdraw: NonCancelledWithdrawal;
  setSelectedWithdrawUid: (withdrawUid: string) => void;
  setShowCancelWithdrawalModal: (showCancelWithdrawalModal: boolean) => void;
}

type CardsContext = {
  [key in Exclude<WithdrawalState, WithdrawalState.cancelled>]: {
    withdrawalStatus: string;
    bottomRichText: string;
    bottomRichTextSmall: string;
    progress: string;
    progressBgColor: string;
    cancelTransferButton: boolean;
    updateWithdrawalButton: boolean;
  }
}

export const RenterWithdrawalCard = ({ withdraw, setSelectedWithdrawUid, setShowCancelWithdrawalModal }: IProps) => {
  const { state, amount, withdraw_uid, created_timestamp } = withdraw;
  const formattedAmount = formatCents(amount);
  const goToWithdrawalForm = () => {
    const url = `/users/renter_withdrawal_funds/${withdraw_uid}/edit`;
    window.location.href = url;
  }

  const cardsContext: CardsContext = {
    pending: {
      withdrawalStatus: 'Transfer Requested',
      bottomRichText: `We received your withdrawal request on ${stringifyDate(initializeDate(created_timestamp))}`,
      bottomRichTextSmall: 'Please allow 5-10 business days for the transfer to be processed',
      progress: '33%',
      progressBgColor: PALETTE.raptor,
      cancelTransferButton: true,
      updateWithdrawalButton: false
    },
    user_info_needed: {
      withdrawalStatus: 'Transfer Requested',
      bottomRichText: 'Your transfer requires input',
      bottomRichTextSmall: 'We encountered an issue sending your deposit funds.',
      progress: '33%',
      progressBgColor: PALETTE.alert125,
      cancelTransferButton: false,
      updateWithdrawalButton: false
    },
    payment_pending: {
      withdrawalStatus: 'Transfer Initiated',
      bottomRichText: `We received your withdrawal request on ${stringifyDate(initializeDate(created_timestamp))}`,
      bottomRichTextSmall: 'Please allow 5-10 business days for the transfer to be processed',
      progress: '66%',
      progressBgColor: PALETTE.raptor,
      cancelTransferButton: false,
      updateWithdrawalButton: false
    },
    payment_complete: {
      withdrawalStatus: 'Transfer Completed',
      bottomRichText: 'Your transfer is complete',
      bottomRichTextSmall: `Your withdrawal of ${formattedAmount} was successfully transferred.`,
      progress: '100%',
      progressBgColor: PALETTE.raptor,
      cancelTransferButton: false,
      updateWithdrawalButton: false
    }
  }

  const {
    withdrawalStatus,
    bottomRichText,
    bottomRichTextSmall,
    progress,
    progressBgColor,
    cancelTransferButton,
    updateWithdrawalButton
  } = cardsContext[state];

  return (
    <>
      <WithdrawalCard>
        <HeaderTop>
          <DepositText>Withdrawal: {formattedAmount}</DepositText>
          <StyledStatus>{withdrawalStatus}</StyledStatus>
        </HeaderTop>

        <ProgressBar>
          <Progress style={{ width: progress, backgroundColor: progressBgColor }} />
          {progress === '100%' &&
            <img src={CheckSVG} style={{ position: 'absolute', top: '-6px', right: '-10px' }} />
          }
        </ProgressBar>
        <BottomSection>
          <Header>{bottomRichText}</Header>
          <Text>{bottomRichTextSmall}</Text>
          {cancelTransferButton &&
            <ActionButton onClick={() => {
              setSelectedWithdrawUid(withdraw_uid);
              setShowCancelWithdrawalModal(true)
            }}>
              Cancel Transfer
            </ActionButton>
          }
          {updateWithdrawalButton &&
            <ActionButton id="update-withdrawal" onClick={goToWithdrawalForm}>Update Withdrawal</ActionButton>
          }
        </BottomSection>
      </WithdrawalCard>
    </>
  );
};
