import React from 'react';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import algoliasearch from 'algoliasearch/lite';
import { keyBy, merge } from 'lodash';
import env from 'utils/env';
import { constructIndexName } from 'utils/algolia';
import '@reach/combobox/styles.css';

const ALGOLIA_APP_ID = env('ALGOLIA_APP_ID');
const ALGOLIA_SEARCH_KEY = env('ALGOLIA_SEARCH_KEY');

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

interface Hit {
  id: number;
  name: string;
  user_input: boolean;
  rhino_modifier: number;
  md5: string;
  objectID: string;
  reference_prifixes: string[];
}

interface AlgoliaOptions {
  attributesToRetrieve?: string[];
  hitsPerPage?: number;
}

interface ResultsHash {
  [key: string]: Hit;
}

const inputStyles = {
  height: '40px',
  width: '100%',
  color: '#777777',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '0.5px',
  fontFamily: 'MaisonNeueLight',
  lineHeight: '28px',
  padding: '0px',
  margin: 0,
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  borderBottom: '1px solid #E3E3E3'
};

const menuStyles = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  fontSize: '90%',
  zIndex: 100000
};

function useAlgoliaSearch(query: string, indexName: string, options?: AlgoliaOptions) {
  const [hits, setHits] = React.useState<Hit[]>([]);
  const queryResultsHash = React.useRef<ResultsHash>({});
  const index = client.initIndex(indexName);

  React.useEffect(() => {
    index.search(query, options).then((res) => {
      queryResultsHash.current = merge(queryResultsHash.current, keyBy(res.hits, 'name'));
      setHits(res.hits);
    });
  }, [query]);

  const getResultInfo = (resultName: string): Hit => queryResultsHash.current[resultName];

  return {
    getResultInfo,
    results: queryResultsHash.current,
    hits
  };
}

// TODO: Rething placeholder and name into a more natural interface. Possible expose via composition
interface AlgoliaAutocompleteProps {
  indexName: string;
  indexSuffix: string;
  onSelect: (employer: Hit) => Hit;
  placeholder?: string;
  name: string;
  value: string;
  onChange: (a?: any) => any;
  dataCy?: string;
}

const AlgoliaAutocomplete = (props: AlgoliaAutocompleteProps) => {
  const { hits, getResultInfo } = useAlgoliaSearch(
    props.value,
    constructIndexName(props.indexName, props.indexSuffix),
    {
      hitsPerPage: 6
    }
  );

  return (
    <Combobox
      aria-label="What's the name of your current employer?"
      onSelect={(resultsString) => props.onSelect(getResultInfo(resultsString))}
    >
      <ComboboxInput
        onChange={props.onChange}
        style={{ ...inputStyles }}
        placeholder={props.placeholder}
        name={`user[${props.name}_name]`}
        id={`user_${props.name}_name`}
        autoComplete="off"
        data-cy={props.dataCy}
      />
      {hits && (
        <ComboboxPopover style={{ ...menuStyles }}>
          {hits.length > 0 ? (
            <ComboboxList>
              {hits.map((hit, index) => {
                return <ComboboxOption key={index} value={hit.name} className="autocomplete-item" />;
              })}
            </ComboboxList>
          ) : (
            <span style={{ display: 'block', margin: 8 }}>No results found</span>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  );
};

export default AlgoliaAutocomplete;
