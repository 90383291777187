import React, { Component } from 'react';
import { inputId, inputName } from 'utils/form';

import './styles.scss';

export type IProps = {
  checked: boolean;
  resource: string;
  field: string;
  onChange: (val: boolean) => any;
};

type IState = {
  checked: boolean;
};

class ToggleCheckbox extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      checked: this.props.checked || false
    };
  }

  toggle = () => {
    const checked: boolean = !this.state.checked;
    this.setState({ checked });
    this.props.onChange(checked);
  };

  render() {
    const { resource, field } = this.props;
    const { checked } = this.state;
    const id = inputId(resource, field);

    return (
      <div className="onoffswitch">
        <input type="checkbox" id={id + '_switch'} className="onoffswitch-checkbox" checked={checked} readOnly={true} />
        <label className="onoffswitch-label" onClick={this.toggle} htmlFor={id + '_switch'}>
          <span className="onoffswitch-inner"></span>
          <span className="onoffswitch-switch"></span>
        </label>
        {/* React sets value of unchecked checkbox to undefined, so when
          checkbox is unchecked rails simply ignores its value on server.
          That is why we use plain field below to store boolean value */}
        <input type="hidden" value={`${checked}`} name={inputName(resource, field)} id={id} readOnly={true} />
      </div>
    );
  }
}

export default ToggleCheckbox;
