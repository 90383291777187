// @flow
import mask from './mask';
import type { IMaskObject } from './mask';

type IFormatOptions = {
  precision?: number,
  decimalSeparator?: string,
  thousandSeparator?: string,
  allowNegative?: boolean,
  prefix?: string,
  suffix?: string,
};

export const CURRENCY_FORMAT_OPTIONS: IFormatOptions = {
  precision: 2,
  decimalSeparator: '.',
  thousandSeparator: ',',
  allowNegative: false,
  prefix: '$',
  suffix: ''
};

export const formatMoney = (value: number, options: IFormatOptions = {}): IMaskObject => {
  const {
    precision, decimalSeparator, thousandSeparator, allowNegative, prefix, suffix
  } = Object.assign({}, CURRENCY_FORMAT_OPTIONS, options);

  return mask(value, precision, decimalSeparator, thousandSeparator, allowNegative, prefix, suffix);
}
