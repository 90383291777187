import { IRentersInsuranceOption } from './interfaces';

export const IN_FULL = 'in_full';
export const MONTHLY = 'monthly';
export const INSTALMENTS = 'instalments';
export const BOTH = 'both';

export const RentersInsuranceOptions: IRentersInsuranceOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: "I'm not sure", value: 'also false' }
];

export const MONTHLY_PROCESSING_FEE = 100;

export const RentersInsuranceFAQs = [
  {
    text: 'What does “personal property” include?',
    answer: 'Clothing, electronics, and furniture',
    legalCopy: 'Coverage limits, sub-limits, or exclusions may apply.'
  },
  {
    text: 'What is "liability coverage?"',
    answer:
      "Liability coverage helps cover you if you cause injury to someone or damage their property, and you're deemed \
    liable. For specific details about your policy, please log into your XCover Account to see what's covered."
  },
  {
    text: 'Will this renters insurance policy meet my property’s requirements?',
    answer:
      "Our coverage amounts meet most properties' coverage requirements. For specific details about your policy, \
    please log into your XCover Account to see what's covered."
  }
];
