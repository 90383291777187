import React, {Component, ReactNode} from 'react';

interface IProps {
  index: number;
  item: string;
  subheader?: string | number;
  content?: string;
  alt_tag?: string;
}

class NumberedImageWithTextItem extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, alt_tag } = this.props;
    return (
      <div className="item carousel-card-centered" key={index}>
        <div style={styles.textContainer} className="card-container">
          <p style={styles.purpleCircle} className="circle-number">{this.props.index + 1}</p>
          <br />
          <p style={styles.subheader} className="text">{this.props.subheader}</p>
          <p style={styles.text} className="text">{this.props.content}</p>
        </div>
        <div>
          <img className="image" src={item} alt={alt_tag} />
        </div>
      </div>
    );
  }
}

const styles = {
  subheader: {
    fontSize: '24px',
    lineHeight: '36px',
    color: '#6318CE',
    marginTop: '27px',
    fontFamily: 'MaisonNeueExtendedMedium',
    textAlign: 'left'  as 'left'
  },
  textContainer: {
    padding: '31px 0'
  },
  text: {
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'left'  as 'left'
  },
  purpleCircle: {
    backgroundColor: '#6318CE',
    color: 'white',
    textAlign: 'center' as 'center',
    float: 'left' as 'left',
    display: 'block',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    marginRight: '10px',
    fontSize: '14px',
    fontWeight: 'bold' as 'bold'
  }
};

export default NumberedImageWithTextItem;
