// @flow
import React, { Component } from 'react';
import DatePickerInput from 'components/common/form/date-picker';
import FormGroup from 'components/common/form/form-group';
import { inputId, inputName } from 'utils/form';
import moment from 'moment';

import { START_INPUT_PROPS, END_INPUT_PROPS } from './constants';

type IProps = {
  bond_start_date: string;
  bond_end_date: string;
  data_cy?: string;
};

type IState = {
  bond_start_date: any;
  bond_end_date: any;
};

class BondPeriodDates extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { bond_start_date, bond_end_date } = props;

    this.state = {
      bond_start_date: moment(bond_start_date).format('YYYY-MM-DD'),
      bond_end_date: moment(bond_end_date).format('YYYY-MM-DD')
    };
  }

  onStartChange = (bond_start_date: any) => {
    this.setState({ bond_start_date });
  };

  onEndChange = (bond_end_date: any) => {
    this.setState({ bond_end_date });
  };

  dynamicStartProps = (): {} => {
    return {
      value: this.state.bond_start_date,
      onChange: this.onStartChange
    };
  };

  dynamicEndProps = (): {} => {
    const { bond_start_date, bond_end_date } = this.state;

    return {
      value: bond_end_date,
      onChange: this.onEndChange
    };
  };

  renderStartInput() {
    return (
      <FormGroup>
        <DatePickerInput
          {...START_INPUT_PROPS}
          {...this.dynamicStartProps()}
          dataCy={`${this.props.data_cy || 'datePicker'}StartDate`}
        />
      </FormGroup>
    );
  }

  renderEndInput() {
    return (
      <FormGroup>
        <DatePickerInput
          {...END_INPUT_PROPS}
          {...this.dynamicEndProps()}
          dataCy={`${this.props.data_cy || 'datePicker'}EndDate`}
        />
      </FormGroup>
    );
  }

  render() {
    const { bond_start_date, bond_end_date } = this.state;
    const start = moment(bond_start_date).format();
    const end = moment(bond_end_date).format();

    return (
      <div>
        <div className="field">
          <div className="field-label">
            <label htmlFor="admitted_pricing_form_bond_start_date">Bond Start Date</label>
          </div>
          <div className="field-input">{this.renderStartInput()}</div>
        </div>

        <div className="field-label">
          <label htmlFor="admitted_pricing_form_bond_end_date">Bond End Date</label>
        </div>
        <div className="field-input">{this.renderEndInput()}</div>
      </div>
    );
  }
}

export default BondPeriodDates;
