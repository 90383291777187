import React from 'react';
import styled from '@emotion/styled';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

// mobile
const OuterDiv = styled.div({
  position: 'fixed',
  height: '100dvh',
  width: '100%',
  bottom: 0,
  left: 0,
  background: PALETTE.white,
  zIndex: 999999
});

// desktop
const ModalWrapper = styled.div({
  position: 'fixed',
  zIndex: 1300,
  inset: 0
});

const ModalContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
});

const BackdropDiv = styled.div({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  zIndex: -1,
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 1,
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
});

const DesktopContainerDiv = styled.div({
  backgroundColor: PALETTE.white,
  padding: '30px 45px 60px 45px',
  width: '580px',
  borderRadius: '5px'
});

const ContainerDiv = styled.div({
  minHeight: 'calc(100dvh - 80px)',
  margin: '40px 24px',
  display: 'flex',
  flexDirection: 'column'
});

interface CancelationModalProps {
  onClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
}

const Modal = (props: CancelationModalProps) => {
  const isMobile = useCheckMobile(768);
  const { isOpen, children, onClose } = props;

  React.useEffect(() => {
    const bodyRef = document.getElementsByTagName('body')[0];
    if (isOpen) {
      bodyRef.style.overflow = 'hidden';
    } else {
      bodyRef.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  if (isMobile) {
    return (
      <OuterDiv>
        <ContainerDiv>{children}</ContainerDiv>
      </OuterDiv>
    );
  } else {
    return (
      <ModalWrapper onClick={onClose}>
        <BackdropDiv />
        <ModalContentWrapper>
          <DesktopContainerDiv onClick={(e) => e.stopPropagation()}>{children}</DesktopContainerDiv>
        </ModalContentWrapper>
      </ModalWrapper>
    );
  }
};

export default Modal;
