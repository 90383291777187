import React from 'react';
import styled from '@emotion/styled';
import { IResidentialHeroSectionFields } from '../../../../../../@types/generated/contentful';
import { ButtonHabitat, PALETTE, FONTS, TextInput, Select } from '@sayrhino/rhino-shared-js';
import { useResidentialForm } from '../hooks/useResidentialForm';

const SubmitButton = styled(ButtonHabitat)`
  background: ${PALETTE.brand100} url(${require('../../../../../assets/images/v2_landing_page/arrow.svg')}) no-repeat
    bottom 50% right 20px;
  width: auto;
`;
export const StyledSubtext = styled.div<{ error?: boolean }>(
  [FONTS.p3Light, { color: PALETTE.neutral65, margin: 0, marginTop: -45 }],
  (props) => props.error && { color: PALETTE.alert125 }
);

export const HeroSectionSignupForm: React.FC<Partial<IResidentialHeroSectionFields>> = (props) => {
  // leaving these because they come from contentful (in case someone wants to reimplement them)
  const { over500UnitsButtonText, under500UnitsButtonText } = props;
  const {
    email,
    handleEmailChange,
    unitCountOption,
    handleUnitCountChange,
    emailError,
    unitCountError,
    handleSubmit,
    isLoading,
    unitCountOptions
  } = useResidentialForm();

  const submitButtonText = 'Get in touch';

  return (
    <form
      onSubmit={(e) => {
        e?.preventDefault();
        handleSubmit();
      }}
      id="residential-hero-form"
    >
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            id="email_hero_section"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            subtext={emailError}
            error={!!emailError}
            placeholder="Email address"
          />
        </div>
        <div className="col-lg-6">
          <Select
            options={unitCountOptions}
            id="unit-count_hero_section"
            value={unitCountOption}
            onChange={handleUnitCountChange}
            error={!!unitCountError}
            placeholder="Units Under Management"
          />
          {unitCountError && <StyledSubtext error>{unitCountError}</StyledSubtext>}
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col">
          {/* This padding has to be applied in style= instead of styled-components because the styles written in
          styled components are overwritten by the styles from shared-js */}
          <SubmitButton
            variant="primary"
            type="submit"
            id="final-cta-submit-button"
            disabled={isLoading}
            style={{ paddingRight: '68px' }}
          >
            {submitButtonText}
          </SubmitButton>
        </div>
      </div>
    </form>
  );
};
