import React from 'react';
import SupportLink from './support_link';
import { IChildProps } from './step_wrapper';

const FailedPricingCalculation = (props: IChildProps) => {
  return (
    <div className="sign-up__form">
      <div className="form-holder">
        <div className="form" style={styles.formHolder}>
          <div className="input-holder" style={styles.inputContainer}>
            We're sorry, but we're having technical difficulties right now.<br />
            Please <a href="/" style={{color: '#2a2e76'}}><b>try again</b></a> a little later,
            or <SupportLink name="contact us" /> if the problem persists.
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    textAlign: 'center' as 'center'
  },
  inputContainer: {
    padding: '50px'
  }
};

export default FailedPricingCalculation;
