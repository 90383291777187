import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { IValuePropsModuleFields, IValuePropFields } from '../../../../../../@types/generated/contentful';
import { Spacer72pxDesktop52pxMobile } from '../../residential/Spacers';

interface valuePropFields extends Omit<IValuePropFields, 'valuePropImage'> {
  valuePropImage: { url: string; description: string };
}

export interface ValuePropsModuleProps extends Omit<IValuePropsModuleFields, 'valueProp'> {
  valueProp: valuePropFields[];
}

const SectionHeader = styled.div([FONTS.brandH2Bold, { fontSize: '44px', lineHeight: '56px', textAlign: 'center' }]);
const ValuePropHeader = styled.div([FONTS.h3, { marginBottom: '12px' }]);
const ValuePropDescription = styled.div([FONTS.p1Light]);
const ValuePropHyperlink = styled.a`
  text-decoration: underline;
`;
const ValuePropImage = styled.img`
  max-width: 80px;
  padding-bottom: 24px;
`;
const RowWrapper = styled.div`
  margin-bottom: 48px;
`;
const ColWrapper = styled.div`
  padding-right: 18px;
`;

const websiteUrl = 'https://www.sayrhino.com/';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <ValuePropHyperlink
        href={data.uri}
        target={`${data.uri.startsWith(websiteUrl) ? '_self' : '_blank'}`}
        rel={`${data.uri.startsWith(websiteUrl) ? '' : 'noopener noreferrer'}`}
      >
        {children}
      </ValuePropHyperlink>
    )
  }
};

export const ValuePropsModule: React.FC<ValuePropsModuleProps> = (props) => {
  const { sectionTitle, valueProp } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-9 d-flex flex-column align-items-center mx-auto">
          <SectionHeader>{sectionTitle}</SectionHeader>
        </div>
      </div>
      <Spacer72pxDesktop52pxMobile />
      <div className="row">
        {valueProp.map((item, index) => {
          return (
            <div className="col-md-6 d-flex align-items-start" key={index}>
              <RowWrapper className="row no-gutters">
                <ColWrapper className="col-lg-2">
                  <ValuePropImage src={item.valuePropImage.url} alt={item.valuePropImage.description} width="100%" />
                </ColWrapper>
                <div className="col-lg-10">
                  <ValuePropHeader>{item.valuePropTitle}</ValuePropHeader>
                  <ValuePropDescription>
                    {documentToReactComponents(item.valuePropDescriptionRichText, options)}
                  </ValuePropDescription>
                </div>
              </RowWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ValuePropsModule;
