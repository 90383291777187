import React from 'react';

interface IProps {
  active: boolean;
  label: string;
  dataCy?: string;
  iconColorOnly?: boolean;
}

const Validation: React.FC<IProps> = (props: IProps) => {
  const { active, label, dataCy, iconColorOnly = false } = props;
  return (
    <div data-cy={dataCy} style={styles.container}>
      <span style={active ? (iconColorOnly ? styles.active.match : styles.active.circle) : styles.inactive.circle} />
      <p style={active && !iconColorOnly ? styles.active.label : styles.inactive.label}> {label} </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex' as 'flex'
  },
  inactive: {
    circle: {
      width: '12px',
      height: '12px',
      borderRadius: '8px',
      background: '#F5F4FB',
      position: 'absolute' as 'absolute',
      marginTop: '5px'
    },
    label: {
      fontFamily: 'MaisonNeueLight',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#777777',
      marginLeft: '30px'
    }
  },
  active: {
    circle: {
      width: '12px',
      height: '12px',
      borderRadius: '8px',
      background: '#6318CE',
      position: 'absolute' as 'absolute',
      marginTop: '5px'
    },
    match: {
      width: '12px',
      height: '12px',
      borderRadius: '8px',
      background: '#17B64B',
      position: 'absolute' as 'absolute',
      marginTop: '5px'
    },
    label: {
      fontFamily: 'MaisonNeueLight',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#6318CE',
      marginLeft: '30px'
    }
  }
};

export default Validation;
