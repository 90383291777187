import React from 'react';

import * as actions from '../signup/redux/actions';
import { IInvitation, RawInvitation } from '../signup/redux/state';
import { useDispatch } from 'react-redux';
import { useAxiosDispatcher, fetchInvitation } from '../signup/api';
import styled from '@emotion/styled';
import getFeatureFlags from 'utils/getFeatureFlags';
import { PALETTE } from '@sayrhino/rhino-shared-js';

const startApplicationStyles = {
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '1rem',
  lineHeight: '28px',
  border: '1px solid white',
  borderRadius: '24px',
  color: 'white',
  display: 'inline-flex',
  padding: '4px 20px',
  backgroundColor: 'transparent',
  transitionProperty: 'background-color, color',
  transition: '200ms ease-in-out',
  '&:hover': {
    backgroundColor: 'white',
    color: PALETTE.brand100
  }
};

const StartApplicationButton = styled.button(startApplicationStyles);
const StartApplicationLink = styled.a(startApplicationStyles);

const { inviteByToken } = getFeatureFlags();

export default function Invitation(props: { invite: Partial<IInvitation & RawInvitation> }) {
  const dispatch = useDispatch();
  const dispatchAPI = useAxiosDispatcher();
  const { invite } = props;

  async function startApplication() {
    if (invite.invite_token && inviteByToken) {
      const invitation = await dispatchAPI(fetchInvitation)(invite.invite_token);
      if (invitation) {
        dispatch(actions.startInvitation(invitation));
      }
    } else if (!inviteByToken && invite.id) {
      const invitation = await dispatchAPI(fetchInvitation)(invite.id.toString());
      if (invitation) {
        dispatch(actions.startInvitation(invitation));
      }
    }
  }

  return (
    <div
      data-cy="pendingInvitation"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        borderRadius: '8px',
        marginBottom: '32px',
        padding: '28px',
        cursor: 'pointer',
        backgroundColor: PALETTE.brand100
      }}
      key={invite.id}
    >
      <h4 style={{ color: 'white', margin: 0 }}>{invite.owner_name} has invited you to enroll with Rhino!</h4>
      <hr style={{ borderTop: '1px solid white', margin: 0 }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          paddingInline: '16px'
        }}
      >
        <h4 style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold', margin: 0 }}>{invite.building_name}</h4>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <p style={{ color: 'white', margin: 0 }}>{invite.address_line_one}</p>
          <p style={{ color: 'white', margin: 0 }}>{invite.unit_name}</p>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {invite.enrollment_link ? (
          <StartApplicationLink href={invite.enrollment_link} title="Start Application" aria-label="Start Application">
            Start Application
          </StartApplicationLink>
        ) : (
          <StartApplicationButton
            id={`start-invitation-${invite.id}`}
            onClick={startApplication}
            title="Start Application"
            aria-label="Start Application"
          >
            Start Application
          </StartApplicationButton>
        )}
      </div>
    </div>
  );
}
