import axios, { AxiosResponse } from 'axios';
import { useMutation, MutationFunction } from 'react-query';
import { IUpdateInterestedPartyParams } from '../interfaces';

const updateInterestedParty: MutationFunction<AxiosResponse, IUpdateInterestedPartyParams> = async (
  params: IUpdateInterestedPartyParams
): Promise<AxiosResponse> => {
  const result = await axios.put(`/cover_genius_policy_details/${params.ins_number}/update_interested_party`, params);
  return result;
};

export default function useUpdateInterestedParty() {
  return useMutation(updateInterestedParty);
}
