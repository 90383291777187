import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';

import Input from 'components/v2/input';
import { allPresent, BackButton, renderRentAmoutError, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';

function RentAmountErrorLabel() {
  const styles = {
    position: 'absolute' as 'absolute',
    top: '73px',
    right: '0px',
    fontSize: '10px',
    lineHeight: '16px',
    color: '#DF2D42'
  };
  return <p style={styles}>Rent Amount</p>;
}
function Rent(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const [policyApplication, setPolicyApplication] = useSubsetState(props.policyApplication.result, [
    'monthly_rent',
    'property_id'
  ]);
  const updateRent = (monthly_rent?: number) => {
    setPolicyApplication(Object.assign({}, policyApplication, { monthly_rent }));
  };
  const history = useHistory();

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const policyApplicationId = props.policyApplication.result?.id;
    const userId = props.user.result?.id;
    if (policyApplication && policyApplicationId && userId) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const disabled = !allPresent([policyApplication.monthly_rent]);
  const cssInput = props.policyApplication.errors ? 'rent-amount-error' : undefined;

  return (
    <div>
      <h2 className="bold header" data-cy="monthlyRentHeader">
        What's your monthly rent?
      </h2>
      <p className="p2" data-cy="monthlyRentDescription">
        Enter your rent amount exactly as it appears on your lease, not including any discounts.
      </p>
      <form className="sign-up__form" id="rent-form" onSubmit={onSubmit}>
        <div className="form-holder">
          <div className="form">
            <div className="input-holder">
              <Input
                dataCy="rent"
                value={policyApplication?.monthly_rent}
                resource="user"
                field="monthly_rent"
                type="currency"
                placeholder="Rent amount"
                onChangeNumber={updateRent}
                className={cssInput}
              />
              {cssInput ? RentAmountErrorLabel() : null}
              {renderRentAmoutError(props.policyApplication)}
            </div>
            <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
              <BackButton step={props.step} />
              <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Rent;
