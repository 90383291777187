import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, ButtonHabitat, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { formatCents } from 'utils/money/formatter';

const Card = styled.div({
  backgroundColor: '#FFFFFF',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  marginTop: '8px',
  marginBottom: '16px',
  // desktop
  '@media (max-width: 992px)': {
    maxWidth: 'none',
    width: '100%'
  }
});

const Header = styled.h6([FONTS.h6Demi], { margin: '0px' });
const Text = styled.p([FONTS.p2Medium, { color: PALETTE.neutral55 }]);

const WithdrawDepositButton = styled(ButtonHabitat)({
  fontSize: '14px',
  lineHeight: '24px'
});

const WithdrawDepositSection = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignContent: 'center'
});

const StyledBadgeLabel = styled.label([
  FONTS.p3Medium,
  {
    padding: `2px 8px`,
    background: '#F9F7FE',
    borderRadius: '4px',
    color: PALETTE.brand100,
    height: '24px',
    marginBottom: '0px'
  }
]);
interface Props {
  renterFunds: number;
}

export const RenterAvailableWithdrawalFunds = (props: Props) => {
  const isMobile = useCheckMobile();
  const formattedWithdrawalAmount = formatCents(props.renterFunds);

  const goToWithdrawalForm = () => {
    window.location.href = `/users/renter_withdrawal_funds/new`;
  }

  return (
    <>
      <Card>
        <Header css={[FONTS.h6Medium, FONT_FAMILY.extendedBold]}>
          Nice! You have {formattedWithdrawalAmount} available to withdraw from your deposit
        </Header>
        <Text>Your property returned {formattedWithdrawalAmount} of your security deposit</Text>

        <WithdrawDepositSection>
          <WithdrawDepositButton
            id="withdraw_deposit"
            size="small"
            variant="primary"
            onClick={goToWithdrawalForm}
          >
            Withdraw Deposit
          </WithdrawDepositButton>

          <StyledBadgeLabel data-mobile={`isMobile: ${isMobile}`}>
            Recommended
          </StyledBadgeLabel>
        </WithdrawDepositSection>
      </Card>
    </>
  );
};
