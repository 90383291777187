import React from 'react';
import { Col, Info, Label, LabelContainer, Row } from './styles';
import styled from '@emotion/styled';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { RenderNeedToKnow } from './render_need_to_know';
import { formatDate } from './utils';

interface IRenderSecurityDepositInfoProps {
  coverage: {
    start_date: string;
    end_date: string;
    amount: string;
    policy_term?: number;
  };
  address: {
    street_address: string;
    city: string;
    state: string;
    zip: string;
    unit?: string;
  };
  inAccount: boolean;
}

const LabelMobile = styled(Label)({ '&&': { marginBottom: 4 } });
const InfoMobile = styled(Info)({ '&&': { marginBottom: 8 } });
const LabelDesktop = styled(Label)({ '&&': { marginBottom: 8 } });
const InfoDesktop = styled(Info)({ '&&': { marginBottom: 6 } });

export function RenderSecurityDepositInfo(props: IRenderSecurityDepositInfoProps) {
  const isMobile = useCheckMobile();
  const { coverage } = props;
  const leaseTerm = `${formatDate(coverage.start_date)} \u2013 ${formatDate(coverage.end_date)}`;
  if (isMobile) {
    return (
      <Col>
        <LabelMobile>Coverage amount</LabelMobile>
        <InfoMobile>{coverage.amount}</InfoMobile>
        <LabelMobile>Policy term</LabelMobile>
        <InfoMobile>{`${coverage.policy_term} months (${leaseTerm})`}</InfoMobile>
        <RenderNeedToKnow inAccount={props.inAccount} address={props.address} />
      </Col>
    );
  } else {
    return (
      <Row>
        <LabelContainer>
          <LabelDesktop>Coverage amount</LabelDesktop>
          <LabelDesktop>Policy term</LabelDesktop>
        </LabelContainer>
        <Col>
          <InfoDesktop>{coverage.amount}</InfoDesktop>
          <InfoDesktop>{leaseTerm}</InfoDesktop>
          <RenderNeedToKnow inAccount={props.inAccount} address={props.address} />
        </Col>
      </Row>
    );
  }
}
