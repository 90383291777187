import Button from 'components/v2/button';
import Input from 'components/v2/input';
import CreatePassword from 'components/v2/password/create';
import React, { Component } from 'react';
import { csrfToken } from 'utils/document';
import { putRaw } from 'utils/request';

interface IProps {
  user_registration_url: string;
  dataCy?: string;
  dataCy2?: string;
}

interface IState {
  passwordString: string;
  oldPassword: string;
  errors: IErrors;
  buttonText: string;
  success: boolean;
  activeButton: boolean;
}

interface IErrors {
  current_password?: [string];
}

class UpdatePassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      passwordString: '',
      oldPassword: '',
      errors: {},
      buttonText: 'Update',
      success: false,
      activeButton: false
    };
  }

  public handleUpdate = (newVal: string) => {
    this.setState({ passwordString: newVal, errors: {} });
  };

  public handleConfirmationUpdate = () => {
    this.setState({ errors: {} });
  };

  public setOldPassword = (newVal: string) => {
    this.setState({ oldPassword: newVal, errors: {} });
  };

  public setActiveButton = (newVal: boolean) => {
    this.setState({ activeButton: newVal });
  };

  public handleSubmit = () => {
    const authenticity_token = csrfToken();
    const { oldPassword, passwordString } = this.state;
    const user = {
      current_password: oldPassword,
      password: passwordString,
      password_confirmation: passwordString
    };
    putRaw(this.props.user_registration_url, {
      user,
      authenticity_token
    })
      .then((response) => {
        this.setState({ success: true, buttonText: 'Password Updated' });
        setTimeout(() => {
          this.setState({
            success: false,
            buttonText: 'Update',
            activeButton: false,
            errors: {}
          });
        }, 3000);
      })
      .catch((response: any) => {
        response
          .json()
          .then((resp) => {
            const errors: IErrors = {
              current_password: resp.errors || [resp.error]
            };
            this.setState({ errors });
          })
          .catch(() => {
            const errors: IErrors = {
              current_password: ['Something went wrong']
            };
            this.setState({ errors });
          });
      });
  };

  public render(): JSX.Element {
    const { activeButton, errors, buttonText } = this.state;
    return (
      <div className="edit-profile-section">
        <div>
          <p className="demi p1"> Change password </p>
          <hr style={styles.divider} />
        </div>
        <div>
          <Input
            placeholder="Old password"
            type="password"
            toggleVisibility={false}
            onChangeString={this.setOldPassword}
            dataCy="oldPassword"
          />
          {errors.current_password && <p style={styles.error}>{errors.current_password.join()}</p>}
        </div>
        <CreatePassword
          resource="user"
          onChange={this.handleUpdate}
          onConfirmationChange={this.handleConfirmationUpdate}
          setActiveButton={this.setActiveButton}
          field_name={'new_password'}
          dataCy="newPassword"
          dataCy2="confirmPassword"
        />
        <Button
          text={buttonText}
          styles={activeButton ? styles.activeButton : styles.button}
          onClick={
            activeButton
              ? this.handleSubmit
              : () => {
                  return;
                }
          }
          id="update-password-btn"
          dataCy="update"
        />
      </div>
    );
  }
}

const styles = {
  divider: {
    paddingBottom: '25px'
  },
  button: {
    border: '1px solid #E3E3E3',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#777777',
    padding: '0px 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'not-allowed'
  },
  activeButton: {
    border: 'none',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'white',
    padding: '0 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    backgroundColor: '#6318CE',
    cursor: 'pointer'
  },
  error: {
    color: 'red'
  }
};

export default UpdatePassword;
