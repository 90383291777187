import AddressInput, { AddressError, fetchUnits, NullHit } from 'components/common/form/address-input';
import Form from 'components/common/form/form';
import Input from 'components/common/form/input';
import Button from 'components/v2/button';
import React, { Component } from 'react';
import { IAlgoliaHit } from 'types';
import { IUnit, NullUnit } from 'types/algolia/unit';
import { inputId, inputName, signUpFormProps } from 'utils/form';
import { IProperty } from 'types/algolia/property';
import type { IAlgoliaResult } from 'utils/algolia';

export interface IValues {
  address_line_one?: string;
  address_line_two?: string;
  unit_id?: string | number;
  google_place_id?: string;
  owner_requires_invitation?: boolean;
  policy_app_property_info?: IProperty;
  policy_app_unit_info_name?: string;
}

interface IProps {
  cyAddress?: string;
  cyUnit?: string;
  user_id?: number;
  buttonText?: string;
  url?: string;
  csrf_token?: string;
  useForm: boolean;
  values: IValues;
  full_address?: string;
  mapEnable?: boolean;
  onPropertyChange?: (property: IAlgoliaHit, unit: IUnit) => void;
  hideLabels?: boolean;
  namePrefix?: string;
  policy_app_property_info?: IProperty;
  policy_app_unit_info_name?: string;
}

interface IState {
  hasError: boolean;
  submitted: boolean;
  property: Partial<IAlgoliaHit>;
  unit: Partial<IUnit>;
}

class AddressStep extends Component<IProps, IState> {
  public static defaultProps = {
    values: {},
    mapEnable: true
  };

  private form?: HTMLFormElement;

  constructor(props: IProps) {
    super(props);

    const {
      address_line_one,
      address_line_two,
      unit_id,
      google_place_id,
      owner_requires_invitation,
      policy_app_property_info,
      policy_app_unit_info_name
    } = props.values;
    const { full_address } = props;
    this.state = {
      hasError: false,
      submitted: false,
      property: {
        ...NullHit,
        address_line_one,
        full_address,
        id: google_place_id,
        owner_requires_invitation,
        selected: !!address_line_one
      },
      // @ts-ignore unit_id type mismatch here. out of scope to figure out when it is going wrong
      unit: { ...NullUnit, id: unit_id, name: address_line_two }
    };
  }

  public render(): JSX.Element {
    const { buttonText, useForm } = this.props;
    return useForm ? (
      <Form
        {...this.formProps()}
        setRef={(form) => {
          if (form) {
            this.form = form;
          }
        }}
      >
        {this.renderInputs()}
        <br />
        <div className="sign-up__actions">
          <Button
            text={buttonText || 'Next'}
            className={'transparent-button transparent-button--wide'}
            onClick={this.onClick}
          />
        </div>
      </Form>
    ) : (
      this.renderInputs()
    );
  }

  private addressInputProps = (): any => {
    const { property, unit } = this.state;
    const { mapEnable } = this.props;
    return {
      property,
      unit,
      mapEnable: mapEnable === undefined ? true : mapEnable,
      onChange: this.onChange,
      addressLabel: this.props.hideLabels ? '' : 'Address',
      unitLabel: this.props.hideLabels ? '' : 'Unit',
      autocompleteProps: { inputProps: { autoComplete: 'chrome-off' } },
      namePrefix: this.props.namePrefix,
      disabled: this.props.policy_app_property_info,
      policy_app_property_info: this.props.policy_app_property_info,
      policy_app_unit_info_name: this.props.policy_app_unit_info_name
    };
  };

  private onChange = (property: IAlgoliaHit, unit: IUnit) => {
    this.setState({
      submitted: false,
      property,
      unit
    });

    this.props.onPropertyChange && this.props.onPropertyChange(property, unit);
  };

  private formProps = (): any => {
    const { user_id, url, csrf_token } = this.props;

    // @ts-ignore
    return signUpFormProps(user_id || 0, url, csrf_token);
  };

  private onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const hasError = !this.state.property.selected;

    this.setState({ submitted: true, hasError });

    if (!hasError) {
      this.ensureUnitId().then(() => this.form && this.form.submit());
    }
  };

  private ensureUnitId = (): Promise<null | void> => {
    return new Promise((resolve, reject) => {
      if (this.state.unit.id || !this.state.unit.name) {
        resolve();
      } else {
        const { unit, property } = this.state;
        const propertyID = property.property_id;

        // @ts-ignore out of scope to track down logic of when unit.name can be undefined
        fetchUnits(unit.name, propertyID).then((result: IAlgoliaResult) => {
          const foundUnit: IUnit = result.units[0];
          this.setState({ unit: foundUnit });
          resolve();
        })
        .catch((): void => resolve());
      }
    });
  };

  private inputId = (fieldName: string) => inputId('user', fieldName);
  private inputName = (fieldName: string) => inputName('user', fieldName);

  private renderInput = (fieldName: string, value: any): JSX.Element => {
    const props = {
      type: 'hidden',
      id: this.inputId(fieldName),
      name: this.inputName(fieldName),
      value: value || '',
      readOnly: true
    };

    return <Input {...props} />;
  };

  private renderInputs = (): JSX.Element => {
    const { hasError, submitted, property, unit } = this.state;

    return (
      <div>
        {hasError && submitted && <AddressError />}

        <AddressInput cyAddress={this.props.cyAddress} cyUnit={this.props.cyUnit} {...this.addressInputProps()} />

        {this.renderInput('address_line_one', property.full_address)}
        {this.renderInput('google_place_id', property.id)}

        {this.renderInput('address_line_two', unit.name)}

        {this.renderInput('unit_id', unit.id)}
        {this.renderInput('property_id', property.property_id)}
      </div>
    );
  };
}

export default AddressStep;
