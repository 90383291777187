import { useState } from 'react';
import { sleep, isPresent } from '../../../../../utils';

const reloadPageAndShowPayNowModal = (myProfilePath): string =>
  (window.location.href = `${myProfilePath}?show_pay_now_modal=true`);

interface QueryParams {
  [key: string]: any;
}

const PAY_NOW_MODAL_OPEN_EVENT = 'pay_now_modal_opened';
const PAY_NOW_MODAL_EXIT_EVENT = 'pay_now_modal_exited';

const removeFromQueryParams = (paramToRemove): QueryParams => {
  const newParams = new URLSearchParams();
  const oldQueryParams = new URLSearchParams(window.location.search);
  const paramEntries = Object.fromEntries(oldQueryParams);
  if (isPresent(paramEntries)) {
    Object.keys(paramEntries).forEach((key) => {
      const paramValue: string | null = oldQueryParams.get(key);
      if (key !== paramToRemove && paramValue) {
        newParams.append(key, paramValue);
      }
    });
    oldQueryParams.delete(paramToRemove);
  }
  return newParams;
};

const createUrlWithoutQueryParam = (param) => {
  const newQueryParamsAsStr = removeFromQueryParams(param).toString();
  const newQueryParams = isPresent(newQueryParamsAsStr) ? `?${newQueryParamsAsStr}${location.hash}` : '';
  return `${window.location.pathname}${newQueryParams}`;
};

const handleRemovingShowModalFromQueryParams = async () => {
  await sleep(500);
  const showPayNowModalQueryParam = 'show_pay_now_modal';
  const currentUrlWithoutQueryParam = createUrlWithoutQueryParam(showPayNowModalQueryParam);
  window.location.replace(currentUrlWithoutQueryParam);
};

const usePayNowModals = ({ myProfilePath }) => {
  const [showPayNowModal, setShowPayNowModal] = useState(false);
  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] = useState(false);
  const [showPartialPaymentConfirmationModal, setShowPartialConfirmationModel] = useState(false);
  const [reloading, setReloading] = useState<any>(false);

  const handleReload = async () => {
    setReloading(true);
    await sleep(1000);
    handleRemovingShowModalFromQueryParams();
  };

  const handleClosePayNowModal = (): void => {
    setShowPayNowModal(false);
    setShowPartialConfirmationModel(false);
    setShowPaymentConfirmationModal(false);
  };

  const handleClosePayNowModalWithTracking = (userId: string | number) => (): void => {
    handleClosePayNowModal();
  };

  const handleShowPaymentConfirmationModal = (): void => {
    setShowPayNowModal(false);
    setShowPartialConfirmationModel(false);
    setShowPaymentConfirmationModal(true);
  };

  const handleShowPartialPaymentConfirmationModal = (): void => {
    setShowPayNowModal(false);
    setShowPaymentConfirmationModal(false);
    setShowPartialConfirmationModel(true);
  };

  const handleClosePaymentConfirmationModal = async () => {
    handleReload();
  };

  const handleClosePartialPaymentConfirmationModal = async () => {
    handleReload();
  };

  const handlePayRemainingBalanceClick = async () => {
    await sleep(500);
    setReloading(true);
    window.location.replace(reloadPageAndShowPayNowModal(myProfilePath));
  };

  const handlePayNowClick = (): void => {
    setShowPayNowModal(true);
  };

  const handleShowPayNowOnPageLoad = (): void => {
    setShowPayNowModal(true);
  };

  const handlePayNowClickWithTracking = (userId: string | number) => (): void => {
    handlePayNowClick();
  };

  const handleShowPayNowOnPageLoadWithTracking = (userId: string | number) => (): void => {
    handleShowPayNowOnPageLoad();
  };

  return {
    showPayNowModal,
    showPaymentConfirmationModal,
    showPartialPaymentConfirmationModal,
    handleClosePayNowModal,
    handlePayNowClick,
    handlePayNowClickWithTracking,
    handleShowPayNowOnPageLoadWithTracking,
    handleClosePayNowModalWithTracking,
    reloading,
    handleShowPayNowOnPageLoad,
    handleShowPaymentConfirmationModal,
    handleShowPartialPaymentConfirmationModal,
    handleClosePaymentConfirmationModal,
    handleClosePartialPaymentConfirmationModal,
    handlePayRemainingBalanceClick
  };
};

export default usePayNowModals;
