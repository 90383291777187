import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button as RhinoButton, TextInput as RhinoTextInput } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import Modal from './Modal';
import { CloseIcon as CloseIconBase } from '@sayrhino/rhino-shared-js';
import useCancelRentersInsurancePolicy from '../hooks/use-cancel-renters-insurance';
import FinalStepMessage from './FinalStepMessage';

// desktop
const ButtonWrapper = styled.div({
  marginTop: 47,
  display: 'flex',
  justifyContent: 'flex-end'
});

const CancelButton = styled.button([
  FONTS.h5,
  {
    background: PALETTE.white,
    borderRadius: '100px',
    color: PALETTE.raptor,
    borderColor: PALETTE.raptor,
    border: '1px solid',
    marginRight: '16px',
    padding: '10px 16px'
  }
]);

const CancelButtonMobile = styled.button([
  FONTS.h5,
  {
    background: PALETTE.white,
    borderRadius: '100px',
    color: PALETTE.raptor,
    borderColor: PALETTE.raptor,
    border: '1px solid',
    marginTop: '16px',
    padding: '9px 16px',
    width: '100%'
  }
]);

const CenterDiv = styled.div({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const Title = styled.h3([FONTS.h3, { marginBottom: 4 }]);

const SubTitle = styled.p([
  FONTS.p1Light,
  {
    color: PALETTE.neutral65
  }
]);

const ButtonWrapperMobile = styled.div({
  marginTop: 42
});

const Button = styled(RhinoButton)([
  FONTS.h5,
  {
    background: PALETTE.raptor,
    width: '327px',
    justifyContent: 'center',
    'button&:disabled': { background: PALETTE.neutral12, color: PALETTE.neutral55 }
  }
]);

const MobileButton = styled(RhinoButton)([
  FONTS.h5,
  {
    background: PALETTE.raptor,
    width: '100%',
    justifyContent: 'center',
    'button&:disabled': { background: PALETTE.neutral12, color: PALETTE.neutral55 }
  }
]);

const TextInput = styled(RhinoTextInput)([
  {
    '&>label': FONTS.p2Light
  }
]);

const CloseIconContainer = styled.div({
  paddingBottom: 16,
  justifyContent: 'right',
  textAlign: 'right'
});

const CloseIcon = styled(CloseIconBase)({
  cursor: 'pointer',
  textAlign: 'right'
});

const TopDiv = styled.div({
  margin: '0px'
});

interface CancelationModalProps {
  onClose: () => void;
  isOpen: boolean;
  onCancel: () => void;
  ins_number: string;
}

const CancellationModal = (props: CancelationModalProps) => {
  const isMobile = useCheckMobile(768);
  const { isOpen, onClose, onCancel } = props;
  const [inputValue, setValue] = React.useState('');
  const isOverLimit = (value: string, limit: number) => value.length > limit;
  const getLimitDiff = (value: string, limit: number) => limit - value.length;
  const maxChar = 150;
  const hasError = isOverLimit(inputValue, maxChar);

  const { mutate: cancelRentersInsurancePolicy, error, reset, isLoading } = useCancelRentersInsurancePolicy();
  const handleCancelationRequest = () => {
    cancelRentersInsurancePolicy({ reason: inputValue, ins_number: props.ins_number }, { onSuccess: onCancel });
  };
  const disableButton = isLoading || hasError;

  const handleCloseErrorModal = () => {
    onClose();
    reset();
  };

  if (error) {
    return (
      <Modal isOpen={isOpen} onClose={handleCloseErrorModal}>
        <div data-cy="cancelModal">
          <TopDiv>
            <CloseIconContainer>
              <CloseIcon onClick={handleCloseErrorModal} data-cy="modalCancel" />
            </CloseIconContainer>
          </TopDiv>
          <CenterDiv>
            <FinalStepMessage onCloseModal={handleCloseErrorModal} errors={error?.response?.data.errors} />
          </CenterDiv>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div data-cy="cancelModal">
        <TopDiv>
          <CloseIconContainer>
            <CloseIcon onClick={onClose} data-cy="modalCancel" />
          </CloseIconContainer>
        </TopDiv>
        <div>
          <Title> We're sorry to see you go</Title>
          <SubTitle>Please tell us why you're canceling your renters insurance policy.</SubTitle>
          <TextInput
            id="textInput"
            label="Reason"
            subtext={`${getLimitDiff(inputValue, maxChar)} of ${maxChar} characters remaining`}
            onChange={(e) => setValue(e.target.value)}
            value={inputValue}
            error={hasError}
            data-cy="cancelReason"
          />
        </div>
        {isMobile ? (
          <ButtonWrapperMobile>
            <MobileButton onClick={handleCancelationRequest} disabled={disableButton} data-cy="submitCancellation">
              Submit cancellation request
            </MobileButton>
            <CancelButtonMobile onClick={onClose} data-cy="cancel">
              Cancel
            </CancelButtonMobile>
          </ButtonWrapperMobile>
        ) : (
          <ButtonWrapper>
            <CancelButton onClick={onClose} data-cy="cancel">
              Cancel
            </CancelButton>
            <Button onClick={handleCancelationRequest} disabled={disableButton} data-cy="submitCancellation">
              Submit cancellation request
            </Button>
          </ButtonWrapper>
        )}
      </div>
    </Modal>
  );
};

export default CancellationModal;
