import React, { Component } from 'react';

interface IProps {
  index: number;
  item: string;
  subheader?: string | number;
  content?: string;
  alt_tag?: string;
}

class ImageWithTextItem extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, alt_tag } = this.props;
    return (
      <div className="item carousel-card-centered" key={index}>
        <img style={styles.image} src={item} alt={alt_tag} />
        <p style={styles.subheader} className="person-name">{this.props.subheader}</p>
        <p style={styles.text} className="content">{this.props.content}</p>
      </div>
    );
  }
}

const styles = {
  image: {
    height: '144px',
    width: '192px',
    margin: 'auto'
  },
  subheader: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '40px',
    lineHeight: '52px',
    color: 'black',
    marginTop: '20px'
  },
  text: {
    fontFamily: 'MaisonNeueExtendedLight',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black'
  }
};

export default ImageWithTextItem;
