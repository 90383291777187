import React from 'react';
import SupportLink from './support_link';
import { IChildProps } from './step_wrapper';

function ContactUs(props: IChildProps) {
  return (
    <div>
      <div className="sign-up__form" id="contact_us-form">
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              We need to gather some more information before we can proceed. Please <SupportLink name="contact us" />.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    padding: '50px'
  }
};

export default ContactUs;
