import React, { createContext, FC, ReactChild, useState } from 'react';

const initState = {
  currentUser: {} as IUser,
  updateUser: (userUpdate: IUser) => {/**/}
};
const UserContext = createContext(initState);
export const UserProvider = UserContext.Provider;

interface Props {
  children?: ReactChild | ReactChild[];
}

export const UserProviderContainer: FC<Props> = ({ children }) => {
  const getCurrentUser = (window as any).App?.user;
  const [currentUser, setCurrentUser] = useState<IUser>(getCurrentUser);

  const updateUser = (userUpdate: IUser) => {
    setCurrentUser(userUpdate);
  };

  return <UserProvider value={{ currentUser, updateUser }}>{children}</UserProvider>;
};
export default UserContext;
