import React, { SyntheticEvent } from 'react';
import { allPresent, BackButton, useSubsetState, doesExist } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import RadioForm from 'components/common/radio_form';
import { FONTS, Checkbox, Select, TextInput } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { IRadioOption, LivingAloneOptions, NumberOptions } from '../constants/household_options';

const QuestionContent = styled.span([FONTS.p1Light]);
const SelectAll = styled.span([FONTS.p2Light], {
  paddingLeft: '2px'
});
const QuestionsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column'
});
const NavButtonContainer = styled.div({
  marginTop: '40px'
});
const InputContainer = styled.div({
  paddingTop: '15px'
});
const QuestionGroup = styled.div({
  marginTop: '40px'
});
const ClarifyingQuestionGroup = styled.div({
  marginTop: '40px',
  '@media(max-width: 991px)': {
    marginBottom: '200px'
  }
});

function Household(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);

  const totalOption = (value) => {
    return NumberOptions.find((option) => option.value === value);
  };

  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'living_alone',
    'total_roommates',
    'total_kids',
    'partner',
    'other_roommate_relationship'
  ]);

  const [roommates, setRoommates] = React.useState<any>(doesExist(policyApplication.total_roommates));
  const [kids, setKids] = React.useState<any>(doesExist(policyApplication.total_kids));
  const [other, setOther] = React.useState<any>(doesExist(policyApplication.other_roommate_relationship));

  const policyApplicationId = props.policyApplication.result?.id;
  const userId = props.user.result?.id;

  const updateLivingAlone = (newValue) => {
    const value = newValue.living_alone === 'true';
    if (value) resetRemainingState();
    updatePolicyApplication({
      living_alone: value,
      total_roommates: 0,
      total_kids: 0,
      partner: false,
      other_roommate_relationship: ''
    });
  };

  const resetRemainingState = () => {
    setRoommates(false);
    setKids(false);
    setOther(false);
  };

  const updatePartner = (newValue) => {
    updatePolicyApplication({
      ...policyApplication,
      partner: newValue
    });
  };

  const updateRoommatesCheck = (newValue) => {
    if (!newValue) updateTotalRoommates({ value: 0 });
    setRoommates(newValue);
  };

  const updateTotalRoommates = (newValue) => {
    updatePolicyApplication({
      ...policyApplication,
      total_roommates: newValue?.value
    });
  };

  const updateKidsCheck = (newValue) => {
    if (!newValue) updateTotalKids({ value: 0 });
    setKids(newValue);
  };

  const updateTotalKids = (newValue) => {
    updatePolicyApplication({
      ...policyApplication,
      total_kids: newValue?.value
    });
  };

  const updateOtherCheck = (newValue) => {
    if (!newValue) updateOther({ value: '' });
    setOther(newValue);
  };

  const updateOther = (newValue) => {
    updatePolicyApplication({
      ...policyApplication,
      other_roommate_relationship: newValue?.value
    });
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (policyApplicationId && userId && policyApplication) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const requiredValues = [roommates, kids, other, policyApplication.partner];

  const anySelected = (list) => {
    let count = 0;
    list.forEach((item) => {
      if (item) return count++;
    });

    if (count > 0) {
      return true;
    } else {
      return false;
    }
  };

  const verifyInputs = () => {
    if (!policyApplication.living_alone && anySelected(requiredValues)) {
      if (
        (roommates && !doesExist(policyApplication.total_roommates)) ||
        (kids && !doesExist(policyApplication.total_kids)) ||
        (other && !doesExist(policyApplication.other_roommate_relationship)) ||
        (policyApplication.partner && !doesExist(policyApplication.partner))
      ) {
        return false;
      }
      return true;
    } else if (policyApplication.living_alone) {
      return true;
    } else {
      return false;
    }
  };

  const disabled = () => {
    return !(allPresent([policyApplication.living_alone]) && verifyInputs());
  };

  return (
    <div>
      <h2 className="bold header" data-cy="householdHeader">
        Let's get some information about who you'll be living with
      </h2>
      <form className="sign-up__form" id="household-form" onSubmit={onSubmit}>
        <QuestionsContainer>
          <div>
            <QuestionContent>Are you living by yourself?</QuestionContent>
            <InputContainer className="input-holder">
              <RadioForm
                field="living_alone"
                options={LivingAloneOptions}
                selected={`${policyApplication.living_alone}`}
                hideActions={true}
                onChange={(living_alone: IRadioOption) => updateLivingAlone({ living_alone })}
                holderClass=""
              />
            </InputContainer>
          </div>
          {policyApplication.living_alone === false && (
            <QuestionGroup>
              <QuestionContent>Who will you be living with?</QuestionContent>
              <SelectAll>(Select all that apply)</SelectAll>
              <InputContainer className="input-holder">
                <Checkbox
                  label="Roommate(s)"
                  id="roommate-checkbox"
                  checked={roommates}
                  onCheckedChange={(value) => updateRoommatesCheck(value)}
                />
                <Checkbox
                  label="Partner"
                  id="partner-checkbox"
                  checked={policyApplication.partner || false}
                  onCheckedChange={(value) => updatePartner(value)}
                />
                <Checkbox
                  label="Kid(s)"
                  id="kid-checkbox"
                  checked={kids}
                  onCheckedChange={(value) => updateKidsCheck(value)}
                />
                <Checkbox
                  label="Other"
                  id="other-checkbox"
                  checked={other}
                  onCheckedChange={(value) => updateOtherCheck(value)}
                />
              </InputContainer>
            </QuestionGroup>
          )}
          <ClarifyingQuestionGroup id="household-clarifying-questions">
            {roommates && (
              <div>
                <QuestionContent>How many roommates will be living with you?</QuestionContent>
                <div>
                  <Select
                    options={NumberOptions}
                    id="total-roommates-select"
                    value={totalOption(policyApplication.total_roommates)}
                    onChange={(newValue) => updateTotalRoommates(newValue)}
                    placeholder="Select number"
                  />
                </div>
              </div>
            )}
            {kids && (
              <div>
                <QuestionContent>How many kids will be living with you?</QuestionContent>
                <div>
                  <Select
                    options={NumberOptions}
                    id="total-kids-select"
                    value={totalOption(policyApplication.total_kids)}
                    onChange={(newValue) => updateTotalKids(newValue)}
                    placeholder="Select number"
                  />
                </div>
              </div>
            )}
            {other && (
              <div>
                <QuestionContent>What is the relationship of the other person?</QuestionContent>
                <div>
                  <TextInput
                    id="other-relationship-input"
                    value={policyApplication.other_roommate_relationship}
                    onChange={(event) => updateOther(event?.target)}
                  />
                </div>
              </div>
            )}
          </ClarifyingQuestionGroup>
          <NavButtonContainer className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <BackButton step={props.step} />
            <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled()} />
          </NavButtonContainer>
        </QuestionsContainer>
      </form>
    </div>
  );
}

export default Household;
