import styled from '@emotion/styled';

export const Spacer72pxDesktop52pxMobile = styled.div`
  margin-bottom: 72px;

  @media (max-width: 767.98px) {
    margin-bottom: 52px;
  }
`;

export const Spacer60pxDesktop12pxMobile = styled.div`
  margin-bottom: 60px;

  @media (max-width: 767.98px) {
    margin-bottom: 12px;
  }
`;

export const Spacer120px100px52px = styled.div`
  margin-bottom: 120px;

  @media (max-width: 1199.98px) {
    margin-bottom: 100px;
  }
  @media (max-width: 767.98px) {
    margin-bottom: 52px;
  }
`;

export const Spacer36 = styled.div({ marginTop: '36px' });
export const Spacer48 = styled.div({ marginTop: '48px' });
