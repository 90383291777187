/** @jsx jsx */

import { Stripe, StripeElements } from '@stripe/stripe-js';
import { PaymentOption } from './utils';
import { IPaymentProps, IResult } from 'components/v2/signup/redux/actions';
import Rollbar from 'rollbar';
import { PolicyApplication, User } from 'components/v2/signup/redux/state';
import { IProps as IOldProps } from './index';
import { jsx } from '@emotion/react';
import PaymentPanel, { IPaymentPanelProps } from './payment';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { useLocation } from 'react-router';
import { OrderSummary } from '../renters_insurance/components/OrderSummary';
import { OrderSummaryV2 } from '../renters_insurance/components/OrderSummaryV2';
export interface IPaths {
  subscriptions_path: string;
  terms_of_service_path: string;
  electronic_transactions_agreement_path: string;
  taa_path: string;
}

export interface IPaymentFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  paths: IPaths;
  paymentOption: PaymentOption;
  full_name: string;
  rollbar?: Rollbar;
  loaderImage?: string;
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  user: IResult<User>;
  submitEnabled: boolean;
  displayLoader?: boolean;
  total: string;
}

type Props = IPaymentPanelProps & Pick<IOldProps, 'price'>;

const PaymentVariantContainer = styled.div({
  borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: 734,
  marginLeft: 'auto',
  marginRight: -17,
  paddingRight: 110,
  height: '650px',
  '&&': {
    '@media (max-width: 768px)': {
      marginRight: 0
    }
  }
});

const PaymentVariantInnerContainer = styled.div({
  width: '100%',
  maxWidth: 624,
  marginLeft: 'auto'
});

const PaymentFormVariant = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '0px'
});

const Title = styled.p([FONTS.h2, { fontFamily: 'MaisonNeueExtendedMedium' }]);

export function PaymentVariant(props: Props) {
  const isMobile = useCheckMobile();
  const { search } = useLocation();
  const riPaymentFlexiblityEnabled = (window as any).App?.featureFlags?.riPaymentFlexiblity;

  const sdiAmount = props.paymentOption === PaymentOption.Monthly ? props.price.monthly : props.price.upfront;
  const sdiPriceInfo = props?.policyApplication?.result?.price_info;
  const addressState = props?.policyApplication?.result?.address_state;
  
  const OrderSummaryV1 = 
    <OrderSummary
      price={props.price}
      paymentOption={props.paymentOption}
      sdiPrice={sdiPriceInfo}
      qualifiesForMonthlyRentersInsurance={props.qualifiesForMonthlyRentersInsurance}
      eligibleInstalmentRIState={props.eligibleInstalmentRIState}
      coverGeniusRentersInsurance={props.coverGeniusRentersInsurance}
      taa_path={props.paths.taa_path}
      address_state={addressState}
    />

  const OrderSummaryRiPaymentFlexibility = 
    <OrderSummaryV2
      price={props.price}
      paymentOption={props.paymentOption}
      sdiPrice={sdiPriceInfo}
      qualifiesForMonthlyRentersInsurance={props.qualifiesForMonthlyRentersInsurance}
      eligibleInstalmentRIState={props.eligibleInstalmentRIState}
      coverGeniusRentersInsurance={props.coverGeniusRentersInsurance}
      taa_path={props.paths.taa_path}
      address_state={addressState}
    />

  const OrderSummaryComponent = riPaymentFlexiblityEnabled ? OrderSummaryRiPaymentFlexibility : OrderSummaryV1;

  if (isMobile) {
    return (
      <div id="payment-form-variant-mobile">
        {OrderSummaryComponent}
        <PaymentPanel dataCy="paymentPanel" total={sdiAmount} isMobile={isMobile} {...props} />
      </div>
    );
  } else
    return (
      <PaymentVariantContainer>
        <PaymentVariantInnerContainer>
          <PaymentFormVariant id="payment-form-variant">
            <Title>Add payment method</Title>
            <PaymentPanel dataCy="paymentPanel" total={sdiAmount} isMobile={isMobile} {...props} />
          </PaymentFormVariant>
        </PaymentVariantInnerContainer>
      </PaymentVariantContainer>
    );
}
