// @flow
import moment from 'moment';

declare var Modernizr: any;

export const DATE_FORMAT = 'MM/DD/YYYY';
export const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE = '01/01/1991';

export const formatDate = (value: string): string => {
  const momented = moment(value, [DATE_FORMAT, ISO_FORMAT]);

  if (!momented.isValid()) return '';

  if (Modernizr.inputtypes.date) return value;

  return momented.format(DATE_FORMAT);
};

export const formatCalendarDate = (value: string): string => {
  if (Modernizr.inputtypes.date) return value;

  return moment(value, DATE_FORMAT).format(CALENDAR_DATE_FORMAT);
};

export const formatToIso = (value: string): string => {
  const momented = moment(value, [DATE_FORMAT, ISO_FORMAT]);
  return momented.isValid() ? momented.format(ISO_FORMAT) : '';
};

export const formatFromMoment = (value: moment): string => {
  if (Modernizr.inputtypes.date) {
    return value.format(ISO_FORMAT);
  } else {
    return value.format(DATE_FORMAT);
  }
};

export const formatToLocaleDate = (date: string): string => {
  if (isNaN(new Date(date).getTime())) return date;
  return new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

export const formatLongDate = (date: string): string => {
  if (isNaN(new Date(date).getTime())) return date;
  return new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const placeholder = (): string => {
  return Modernizr.inputtypes.date ? DEFAULT_DATE : DATE_FORMAT;
};
