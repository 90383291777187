import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { WrappedPolicyApplication } from '../interfaces';
import { addCsrf } from '../redux/actions';
import { PolicyApplication } from '../redux/state';

async function fetchPolicyApplication(id: number, userId: number): Promise<PolicyApplication> {
  const params = addCsrf({});
  const url = `/users/${userId}/policy_applications/${id}.json`;
  const result = await axios.get<WrappedPolicyApplication>(url, { params });
  return result.data.policy_application;
}

export default function usePolicyApplication(id: number, userId: number): QueryObserverResult<PolicyApplication> {
  const policyApplication: PolicyApplication | undefined = (window as any).App?.policy_application;
  return useQuery(['policy_application', id], () => fetchPolicyApplication(id, userId), {
    initialData: policyApplication
  });
}
