import React, { Component } from 'react';

interface IProps {
  index: number;
  item: string;
  link: string | undefined;
  title: string | undefined;
}

class ImageWithLink extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, link, title } = this.props;

    return (
      <div className="item image-with-link" key={index}>
        <a href={link} title={title} rel="noopener" target="_blank">
          <img src={item} alt={title} style={styles.image} />
        </a>
      </div>
    );
  }
}

const styles = {
  image: {
    width: '0 auto'
  }
};

export default ImageWithLink;
