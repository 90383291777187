import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { Component } from 'react';

interface IProps {
  header?: string;
  text?: string;
  autoRenewPolicy?: string;
  hasNonRenewalReason?: boolean;
  subtext?: string;
  interactive?: boolean;
  buttonText?: string;
  showRenewButton?: boolean;
  onClose?: (event: React.MouseEvent) => void;
  onOpen?: () => void;
  loadingQuote?: boolean;
  onAccept?: () => Promise<any>;
  id?: string;
  dataCy?: string;
  policyEndDate?: string;
  cannotEditTerms?: boolean;
}

interface IState {
  loading: boolean;
  error: IError;
}

interface IError {
  errors: string[];
}

const AutoRenewPolicy = styled.div([
  FONTS.p2Light,
  {
    color: PALETTE.white,
    marginRight: '30px'
  }
]);

const ExpirePolicy = styled.div([
  FONTS.p2Light,
  {
    color: PALETTE.white,
    marginRight: '50px'
  }
]);

class Banner extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      error: { errors: [] }
    };
  }

  public onAccept = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    if (this.props.onAccept) {
      this.props.onAccept().catch((error) => {
        this.setState({ loading: false, error });
      });
    }
  };

  public displayErrors = () => {
    const { errors } = this.state.error;
    let cardCta: JSX.Element = <></>;

    if (errors.length === 0) {
      return <></>;
    }

    if (errors[0].includes('card failed')) {
      cardCta = (
        <div>
          Click <a href="/account/edit">here</a> to update your card details.
        </div>
      );
    }

    return (
      <>
        <div style={styles.error}>{errors[0]}</div>
        {cardCta}
      </>
    );
  };

  public render() {
    const { header, showRenewButton, buttonText, dataCy, interactive, hasNonRenewalReason, cannotEditTerms } =
      this.props;
    const contentClasses = header ? 'bottom' : 'center';
    return (
      <div className={`row no-gutters dashboard-banner`} style={styles.container} id={this.props.id}>
        <div className="col banner-inner" style={styles.containerInner}>
          {header && (
            <div className="row">
              <div style={styles.header} className="col banner-header">
                {header}
              </div>
            </div>
          )}
          <div className={`row no-gutters ${contentClasses}`} style={styles.content}>
            {this.props.autoRenewPolicy && !this.props.hasNonRenewalReason && (
              <AutoRenewPolicy>Your policy will auto-renew on {this.props.autoRenewPolicy}.</AutoRenewPolicy>
            )}
            {this.props.hasNonRenewalReason && (
              <ExpirePolicy>Your policy will expire on {this.props.policyEndDate}.</ExpirePolicy>
            )}
            {interactive && !hasNonRenewalReason && (
              <div className="main" style={styles.contentContainer}>
                {this.renderTextInteractive()}
                {this.displayErrors()}
              </div>
            )}
            {!interactive && (
              <div className="main" style={styles.contentContainer}>
                {this.renderText()}
                {this.displayErrors()}
              </div>
            )}
            {showRenewButton && (
              <div data-cy={dataCy} className="button" style={styles.buttonContainer}>
                <button
                  disabled={
                    cannotEditTerms !== undefined && cannotEditTerms ? this.props.loadingQuote : this.state.loading
                  }
                  className="btn banner-button-color banner-buttons"
                  onClick={cannotEditTerms !== undefined && cannotEditTerms ? this.props.onOpen : this.onAccept}
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private renderTextInteractive = (): JSX.Element => {
    const { onClose, text } = this.props;
    return (
      <button style={{ textDecoration: 'underline', ...styles.text }} className="btn banner-text" onClick={onClose}>
        {text}
      </button>
    );
  };

  private renderText = (): JSX.Element => {
    const { text, subtext } = this.props;

    return (
      <>
        <div style={styles.text} className="banner-text">
          {text}
        </div>
        <div style={styles.subtext} className="banner-subtext">
          {subtext}
        </div>
      </>
    );
  };
}

const styles = {
  container: {
    maxWidth: '580px',
    marginBottom: '20px'
  },
  containerInner: {
    borderRadius: '8px',
    padding: '24px'
  },
  header: {
    fontFamily: 'MaisonNeueExtendedDemi',
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: '8px'
  },
  text: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px'
  },
  subtext: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    lineHeight: '24px'
  },
  button: {
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '14px',
    lineHeight: '24px',
    borderRadius: '24px',
    padding: '4px 20px',
    whiteSpace: 'nowrap' as 'nowrap'
  },
  buttonContainer: {
    flex: '0 1 auto',
    paddingTop: '10px'
  },
  contentContainer: {
    flex: '1 1 auto'
  },
  error: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#DF2D42'
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    alignItems: 'center'
  }
};

export default Banner;
