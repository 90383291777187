import React, {Component} from 'react';

interface IProps {
  css: string;
  image: string;
  header: string;
  alt_tag: string;
  subheaders: string[];
  text: string[];
}

class SectionLock extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public renderDesktopVersion() {
    const { image, header, subheaders, text, css, alt_tag } = this.props;

    return (
      <div className="section-lock d-none d-md-flex">
        <div className="col-md-6 sticky">
          <img src={image} className={`img-fluid ${css}`} alt={alt_tag} />
        </div>
        <div className="col-md-6">
          <div style={styles.desktop.textContainer}>
            <p style={styles.desktop.header}>{header}</p>
            {subheaders.map(function(subheader: string, index: number) {
              return (
                <div key={index}>
                  <p style={styles.desktop.subheader}>{subheader}</p>
                  <p style={styles.desktop.text}>{text[index]}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  public renderMobileVersion() {
    const { image, header, subheaders, text, alt_tag } = this.props;

    return (
      <div className="d-block d-md-none">
        <div className="col-12" style={styles.mobile.imageContainer}>
          <img src={image} style={styles.mobile.image} className="br-8px" alt={alt_tag} />
        </div>
        <div className="col-12" style={styles.mobile.textContainer}>
          <p style={styles.mobile.header}>{header}</p>
          {subheaders.map(function(subheader: string, index: number) {
            return (
              <div key={index}>
                <p style={styles.mobile.subheader}>{subheader}</p>
                <p style={styles.mobile.text}>{text[index]}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  public render() {
    return(
      <div>
        {this.renderDesktopVersion()}
        {this.renderMobileVersion()}
      </div>
    );
  }
}

const styles = {
  desktop: {
    textContainer: {
      marginLeft: '15px',
      padding: '0 160px 0 40px',
      textAlign: 'left' as 'left'
    },
    header: {
      fontFamily: 'MaisonNeueExtendedMedium',
      fontSize: '16px',
      lineHeight: '28px',
      color: 'white',
      marginTop: '85px'
    },
    subheader: {
      color: 'white',
      fontFamily: 'GTSectraBold',
      fontStyle: 'normal',
      fontSize: '32px',
      lineHeight: '40px',
      margin: '96px 0 28px 0'
    },
    text: {
      color: 'white',
      fontFamily: 'MaisonNeueExtendedLight',
      fontSize: '18px',
      lineHeight: '32px'
    }
  },
  mobile: {
    imageContainer: {
      padding: '0'
    },
    image: {
      width: '100%'
    },
    textContainer: {
      textAlign: 'left' as 'left',
      padding: '0 30px 0 30px'
    },
    header: {
      fontFamily: 'MaisonNeueExtendedMedium',
      fontSize: '16px',
      lineHeight: '28px',
      color: 'white',
      marginTop: '85px'
    },
    subheader: {
      color: 'white',
      fontFamily: 'GTSectraBold',
      fontStyle: 'normal',
      fontSize: '32px',
      lineHeight: '40px',
      margin: '60px  0 20px 0'
    },
    text: {
      color: 'white',
      fontFamily: 'MaisonNeueExtendedLight',
      fontSize: '18px',
      lineHeight: '32px'
    }
  }
};

export default SectionLock;
