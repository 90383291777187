import React from 'react';

import CloseButton from 'components/v2/close';

interface ISliderProps {
  show: boolean;
  renderContent: () => JSX.Element;
  close: () => void;
  buttonBackground: string;
  onConfirmExitStage?: boolean;
}

const Slider: React.FC<ISliderProps> = (props: ISliderProps) => {
  let drawerClasses = 'side-drawer';

  if (props.show) {
    drawerClasses = 'side-drawer open';
  }

  return (
    <div className={drawerClasses}>
      {!props.onConfirmExitStage &&
        <CloseButton id="drawer-close"
          onClick={props.close}
          background={props.buttonBackground}
          dataCy="closeDrawer"
        />
      }
      {props.show && props.renderContent()}
    </div>
  );
};

export default Slider;
