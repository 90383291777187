import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import React, { Component, ReactNode, useEffect, useState, useMemo } from 'react';

import { IPaymentProps, IResult, payAndSubscribeSync } from 'components/v2/signup/redux/actions';
import { PolicyApplication, User } from 'components/v2/signup/redux/state';
import PaymentForm, { IPaths } from './payment_form';
import { PaymentOption } from './utils';
import getFeatureFlags from 'utils/getFeatureFlags';
import PaymentElementContainer from './PaymentElementContainer';

const InjectedPaymentForm = (props: IPaymentPanelProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => {
        if (stripe && elements) {
          return (
            <PaymentForm
              stripe={stripe}
              elements={elements}
              loading={loading}
              sdiPolicyPriceInfo={props?.policyApplication?.result?.price_info}
              {...props}
            />
          );
        } else {
          return <p>Error loading payment form</p>;
        }
      }}
    </ElementsConsumer>
  );
};

export interface IPaymentPanelProps {
  paths: IPaths;
  paymentOption: PaymentOption;
  full_name: string;
  loaderImage?: string;
  payAndSubscribe: (paymentProps: IPaymentProps) => void;
  policyApplication: IResult<PolicyApplication>;
  user: IResult<User>;
  submitEnabled: boolean;
  setSubmitEnabled: (bool) => void;
  dataCy?: string;
  total?: string;
  isMobile?: boolean;
  loading?: boolean;
  confirmRentersInsurance?: any;
  coverGeniusRentersInsurance?: any;
  qualifiesForMonthlyRentersInsurance?: boolean;
  eligibleInstalmentRIState?: boolean;
  optOut?: (ins_number: string) => void;
}

const PaymentPanel = (props: IPaymentPanelProps) => {
  const featureFlags = getFeatureFlags();
  const enrollmentStripePaymentElement = featureFlags?.enrollmentStripePaymentElement;

  const stripePromise = useMemo(
    () => loadStripe(props.policyApplication.result?.stripe_publishable_key || ''),
    [props.policyApplication.result?.stripe_publishable_key || '']
  );

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      spacingGridRow: '16px',
      spacingGridColumn: '16px'
    }
  };

  const stripePaymentOptions: StripeElementsOptions = {
    clientSecret: props.policyApplication.result?.stripe_payment_intent_client_secret,
    appearance,
    loader: 'always'
  };

  return (
    <>
      {!enrollmentStripePaymentElement && (
        <Elements stripe={stripePromise}>
          <InjectedPaymentForm {...props} />
        </Elements>
      )}
      {enrollmentStripePaymentElement && (
        <Elements options={stripePaymentOptions} stripe={stripePromise}>
          <PaymentElementContainer {...props} sdiPolicyPriceInfo={props?.policyApplication?.result?.price_info} />
        </Elements>
      )}
    </>
  );
};

export default PaymentPanel;
