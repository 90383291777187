import { titleize } from 'utils/string';

const DEFAULT_SERVICE_FEE_LABEL = 'service fee';

const SERVICE_FEES_LABELS = {
  AL: 'new application policy fee',
  FL: 'policy fee',
  GA: 'policy fee',
  LA: 'agency fee',
  MO: 'policy fee',
  MS: 'policy fee',
  TX: 'policy fee',
  VA: 'policy fee'
};

const DEFAULT_PAYMENT_PROCESSING_FEE_LABEL = 'processing fee';

const PAYMENT_PROCESSING_FEES_LABELS = {
  // TODO: Add state specific labels
};

interface IFeeLabelOptions {
  titleize: boolean;
}

export const buildFeeLabel = (
  state: any,
  options: Partial<IFeeLabelOptions> = {},
  labels: { [key: string]: string },
  defaultLabel: string
) => {
  let label = labels[state] || defaultLabel;

  if (options.titleize) {
    label = titleize(label);
  }

  return label;
};

export const serviceFeeLabel = (state: any, options: Partial<IFeeLabelOptions> = {}) => {
  return buildFeeLabel(state, options, SERVICE_FEES_LABELS, DEFAULT_SERVICE_FEE_LABEL);
};

export const processingFeeLabel = (state: any, options: Partial<IFeeLabelOptions> = {}) => {
  return buildFeeLabel(state, options, PAYMENT_PROCESSING_FEES_LABELS, DEFAULT_PAYMENT_PROCESSING_FEE_LABEL);
};

export const US_STATE_ABBREVIATION_TO_NAME = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Washington D.C.',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};
