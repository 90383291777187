import React, { Component } from 'react';
import moment from 'moment';
import DatePickerInput from 'components/common/form/date-picker';
import AccountField from 'components/common/account/AccountField';
import AccountLabel from 'components/common/account/AccountLabel';

type IProps = {
  birthday: string;
};
type IState = {
  opened: boolean;
  birthday: string;
};

class EditDetails extends Component<IProps, IState> {
  static defaultProps = {
    birthday: '1991-01-01'
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      opened: false,
      birthday: this.props.birthday
    };
  }

  handleClick = (e: Event) => {
    e.preventDefault();
    this.setState({ opened: true });
  };

  renderEditLink = () => {
    const props = {
      href: '#',
      id: 'edit-details-link',
      className: 'accounts-form--link',
      onClick: this.handleClick
    };

    return (
      <AccountField style={{ marginTop: '55px' }} colMd={6}>
        <div>
          {/* @ts-ignore */}
          <a {...props}>Edit Details</a>
        </div>
      </AccountField>
    );
  };

  renderBirthdayField = () => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    const props = {
      resource: 'user',
      field: 'birthdate',
      value: this.state.birthday,
      pickerProps: {
        autoFocus: true,
        placeholderText: 'dd/mm/yyyy',
        className: 'input_field',
        skipBaseStyle: true
      },
      maxDate: d.toISOString()
    };

    return (
      <AccountField style={{ marginTop: 10 }} colMd={12}>
        <AccountLabel>Birthdate</AccountLabel>
        <div className="accounts-form-field field col-md-6 col-sm-12" style={{ paddingLeft: 0 }}>
          <div className="input-holder">
            <DatePickerInput {...props} />
          </div>
        </div>
      </AccountField>
    );
  };

  render() {
    return this.state.opened ? this.renderBirthdayField() : this.renderEditLink();
  }
}

export default EditDetails;
