import React from 'react';

import './Settings.scss';

import Popover from 'react-popover-component';

const CustomPopover = ({ children, ...props}) => (
  <Popover {...props}>
    {children}
  </Popover>
);

export default CustomPopover;
