import styled from '@emotion/styled';
import { Card } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { CardParagraph, ONLINE_PAYMENT_OPTIONS } from '../cash_deposit';
import CardsPNG from './payment_card_options.png';

const TopTextSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BottomTextSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const PaymentOptionTitle = styled.h3`
  font-size: 16px;
  color: #000;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.32px;
  margin: 0;
`;

const PaymentOptionAmount = styled.h5`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.4px;
  color: #6a3bf5;
  margin: 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  gap: 16px;
  width: 352px !important;
  height: auto !important;
  padding: 16px;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;
`;

const styles = {
  activeCard: {
    border: '1px solid #7D53F7'
  },
  payTypeParagraph: {
    color: '#5A6468',
    fontSize: 14,
    marginBottom: 0
  },
  totalDue: {
    color: '#6A3BF5',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.4
  },
  paymentSelectCard: {
    textAlign: 'left'
  },
  processingTime: {
    color: '#12873D',
    fontWeight: 600
  },
  paymentCardOptionsImg: {
    height: '20px'
  }
};

export const PaymentOptionSelectCard = ({
  activeCard,
  onClick,
  serviceFee,
  processingTime,
  title,
  totalDue
}: {
  activeCard: boolean;
  onClick: () => void;
  processingTime: string;
  serviceFee: string;
  title: string;
  totalDue: string;
}) => {
  return (
    <div onClick={onClick}>
      <StyledCard style={activeCard ? styles.activeCard : {}}>
        <TopTextSection>
          <PaymentOptionTitle>{title}</PaymentOptionTitle>
          <PaymentOptionAmount>Total Due: {totalDue}</PaymentOptionAmount>
        </TopTextSection>
        {ONLINE_PAYMENT_OPTIONS[title] === 'card' ? (
          <section>
            <img
              style={styles.paymentCardOptionsImg}
              src={CardsPNG}
              alt="Visa, MasterCard, Discover, American Express, Apple Pay, Google Pay"
            />
          </section>
        ) : null}
        <BottomTextSection>
          <CardParagraph>Rhino Service Fee: {serviceFee}</CardParagraph>
          <CardParagraph>
            Processing Time: <span style={styles.processingTime}>{processingTime}</span>
          </CardParagraph>
        </BottomTextSection>
      </StyledCard>
    </div>
  );
};
