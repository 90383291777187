const dollarUS = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
});

const multiplyRent = (rent: number, multiplier: number) => {
  return rent * multiplier;
};

const formatIncome = (income: number) => {
  return dollarUS.format(income);
};

const rentMidpoint = (rent: number, firstMultiple: number, lastMultiple: number) => {
  const startRange = rent * firstMultiple;
  const endRange = rent * lastMultiple - 1;

  return Math.trunc((startRange + endRange) / 2);
};

const IncomeOptions = (monthlyRent: number) => {
  return [
    {
      label: `$0 to ${formatIncome(multiplyRent(monthlyRent, 5) - 1)}`,
      value: rentMidpoint(monthlyRent, 0, 5)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 5))} to ${formatIncome(multiplyRent(monthlyRent, 10) - 1)}`,
      value: rentMidpoint(monthlyRent, 5, 10)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 10))} to ${formatIncome(multiplyRent(monthlyRent, 15) - 1)}`,
      value: rentMidpoint(monthlyRent, 10, 15)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 15))} to ${formatIncome(multiplyRent(monthlyRent, 20) - 1)}`,
      value: rentMidpoint(monthlyRent, 15, 20)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 20))} to ${formatIncome(multiplyRent(monthlyRent, 25) - 1)}`,
      value: rentMidpoint(monthlyRent, 20, 25)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 25))} to ${formatIncome(multiplyRent(monthlyRent, 30) - 1)}`,
      value: rentMidpoint(monthlyRent, 25, 30)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 30))} to ${formatIncome(multiplyRent(monthlyRent, 35) - 1)}`,
      value: rentMidpoint(monthlyRent, 30, 35)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 35))} to ${formatIncome(multiplyRent(monthlyRent, 40) - 1)}`,
      value: rentMidpoint(monthlyRent, 35, 40)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 40))} to ${formatIncome(multiplyRent(monthlyRent, 45) - 1)}`,
      value: rentMidpoint(monthlyRent, 40, 45)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 45))} to ${formatIncome(multiplyRent(monthlyRent, 50) - 1)}`,
      value: rentMidpoint(monthlyRent, 45, 50)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 50))} to ${formatIncome(multiplyRent(monthlyRent, 55) - 1)}`,
      value: rentMidpoint(monthlyRent, 50, 55)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 55))} to ${formatIncome(multiplyRent(monthlyRent, 60) - 1)}`,
      value: rentMidpoint(monthlyRent, 55, 60)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 60))} to ${formatIncome(multiplyRent(monthlyRent, 65) - 1)}`,
      value: rentMidpoint(monthlyRent, 60, 65)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 65))} to ${formatIncome(multiplyRent(monthlyRent, 70) - 1)}`,
      value: rentMidpoint(monthlyRent, 65, 70)
    },
    {
      label: `${formatIncome(multiplyRent(monthlyRent, 70))} or more`,
      value: multiplyRent(monthlyRent, 70)
    }
  ];
};

export default IncomeOptions;
