import React, { useEffect } from 'react';
import Map, { IProps as IMapProps } from 'components/v2/signup/map_with_overlay/map';
import styled from '@emotion/styled';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { QuoteDisplayFooter } from './QuoteDisplayFooter';
import { Section, SubHeader, Title, MobileTitle } from './styles';
import { ArrowIcon, Button, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { useHistory } from 'react-router-dom';
import { BackButton } from '../renters_insurance/components/SharedStyles';
import { Steps } from '../renters_insurance/components/RentersInsuranceModal';
import { RentersInsurancePolicyProps } from '../renters_insurance/interfaces';
import { RenderPolicyDetails } from './render_policy_details';
import { RenderSecurityDepositInfo } from './render_security_deposit_info';
import { IRentersInsuranceAddress } from '../renters_insurance/interfaces';
import { PaymentOption } from 'components/v2/quote_display/utils';
import { useAxiosDispatcher, calculatePriceAndCreateOrUpdatePaymentIntent } from 'components/v2/signup/api';
import { useDispatch } from 'react-redux';
import { updatePolicyApplicationSync } from 'components/v2/signup/redux/actions';

export interface IProps {
  map: IMapProps;
  address: {
    street_address: string;
    city: string;
    state: string;
    zip: string;
    unit?: string;
  };
  addressForRI?: string;
  coverage: {
    start_date: string;
    end_date: string;
    amount: string;
    policy_term?: number;
  };
  lease: {
    start_date: string;
    end_date: string;
  };
  policyApplicationId?: number;
  userId?: number;
  inAccount: boolean;
  rentersInsurancePolicy?: RentersInsurancePolicyProps;
  goToStep: (modalStep: string) => void;
  selectedPolicyApp?: Partial<IRentersInsuranceAddress>;
  search?: any;
  rentersInsuranceSelected?: boolean;
  coverGeniusRentersInsurance?: any;
  paymentOption?: PaymentOption;
  qualifiesForMonthlyRentersInsurance: boolean;
}

import { RenderRentersInsuranceDetails } from './render_renters_insurance_details';
import { SECURITY_DEPOSIT_INSURANCE } from '../signup/redux/state';

// temporarily hidden behind feature flag until hooked up with CGPolicyDetails
const rentersInsuranceAnnualAtSignUp = (window as any).App?.featureFlags?.signUp?.rentersInsuranceAnnualAtSignUp;

const Header = styled.h2([FONTS.h2], {
  lineHeight: '150%',
  letterSpacing: '0.56px',
  marginBottom: '8px',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    fontSize: '24px'
  }
});
const AddPayment = styled(Button)([{ '&&': { background: PALETTE.brand100 } }]);

const Wrapper = styled.div([
  {
    margin: '0 auto',
    maxWidth: '480px',
    '@media (min-width: 768px)': {
      margin: '0 auto',
      width: '480px'
    }
  }
]);

const MapSection = styled.div([
  {
    height: '86px',
    border: '1px solid',
    width: '100%',
    borderColor: PALETTE.neutral12,
    borderRadius: '8px',
    backgroundColor: 'lightgrey',
    overflow: 'hidden'
  }
]);

const MapContainer = styled.div([
  {
    height: 'auto',
    width: 'auto',
    margin: '-325px 0 0 0px',
    '@media (max-width: 520px)': {
      margin: '-275px 0 0 0px'
    }
  }
]);

const ActionButtons = styled.div([
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: '35px'
  }
]);

export const Divider = styled.hr({ '&&': { marginBottom: 40 } });

function PolicyDetails(props: IProps) {
  const goNext = useHistory();
  const {
    map,
    coverage,
    rentersInsurancePolicy,
    goToStep,
    selectedPolicyApp,
    search,
    rentersInsuranceSelected,
    coverGeniusRentersInsurance,
    inAccount,
    qualifiesForMonthlyRentersInsurance
  } = props;

  const lease = inAccount
    ? {
        start_date: selectedPolicyApp?.lease_start_date,
        end_date: selectedPolicyApp?.lease_end_date
      }
    : props.lease;
  const isMobile = useCheckMobile();

  const mapAttrs = {
    className: 'card-img rounded d-lg-block card-img-map',
    alt: `Map of ${map.location}`
  };
  const mobileMap = { ...map, size: { width: 327, height: 100 } };

  const innerWrapperStyle = {
    maxWidth: 624,
    width: '100%',
    marginLeft: 'auto',
    ...(isMobile && { paddingBottom: 96 })
  };
  const wrapperStyle = isMobile
    ? {}
    : {
        width: '100%',
        maxWidth: 734,
        marginLeft: 'auto',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        marginRight: -17,
        paddingRight: 110,
        '&&': {
          '@media (max-width: 768px)': {
            marginRight: 0
          }
        }
      };

  const dispatchAxios = useAxiosDispatcher();
  const dispatch = useDispatch();

  async function next() {
    const { userId, policyApplicationId } = props;
    const upfront = new URLSearchParams(search).get('payment_cadence') !== '0';
    if (userId && policyApplicationId) {
      const policyApplication = await dispatchAxios(calculatePriceAndCreateOrUpdatePaymentIntent)({
        userId,
        policyApplicationId,
        upfront,
        productType: SECURITY_DEPOSIT_INSURANCE
      });
      dispatch(updatePolicyApplicationSync(policyApplication));
      goNext.push(`payment` + search);
    }
  }

  return props.inAccount ? (
    <Wrapper>
      <Header data-cy="reviewDetailsHeader">Review policy details</Header>
      <MapSection>
        <MapContainer role="img" aria-label={`map of ${selectedPolicyApp?.label}`}>
          <Map.Component {...map} attributes={mapAttrs} />
        </MapContainer>
      </MapSection>
      <br />
      <RenderPolicyDetails
        rentersInsurancePolicy={rentersInsurancePolicy}
        inAccount={props.inAccount}
        address={props.address}
        lease={lease}
        addressForRI={props.addressForRI}
      />
      <RenderRentersInsuranceDetails
        paymentOption={props.paymentOption}
        data-cy="riPolicyTerm"
        rentersInsurancePolicy={rentersInsurancePolicy}
        inAccount={props.inAccount}
        address={props.address}
        coverage={coverage}
        addressForRI={props.addressForRI}
        coverGeniusRentersInsurance={coverGeniusRentersInsurance}
        qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
      />
      <ActionButtons>
        <BackButton variant="white" onClick={() => goToStep(Steps.QUOTE)}>
          Back
        </BackButton>
        <AddPayment onClick={() => goToStep(Steps.CHECKOUT)} data-cy="addPayment">
          Add payment info
          <ArrowIcon style={{ paddingLeft: 10 }} />
        </AddPayment>
      </ActionButtons>
    </Wrapper>
  ) : (
    <div style={wrapperStyle}>
      <div style={innerWrapperStyle}>
        <Section className="policy-details">
          {isMobile ? <MobileTitle>Review policy details</MobileTitle> : <Title>Review policy details</Title>}
          <div className="map" style={{ display: 'flex', marginBottom: '24px' }}>
            <Map.Component {...mobileMap} attributes={mapAttrs} />
          </div>
          <RenderPolicyDetails
            rentersInsurancePolicy={rentersInsurancePolicy}
            inAccount={props.inAccount}
            address={props.address}
            lease={lease}
            addressForRI={props.addressForRI}
          />
        </Section>
        <Divider />
        <Section className="security-deposit" data-cy="sdiPolicyTerm">
          <SubHeader>Security Deposit Insurance</SubHeader>
          <RenderSecurityDepositInfo coverage={coverage} address={props.address} inAccount={props.inAccount} />
        </Section>
        <Divider />

        {rentersInsuranceSelected && rentersInsuranceAnnualAtSignUp && (
          <>
            <Section className="renters-insurance" data-cy="riPolicyTerm">
              <SubHeader>Renters Insurance</SubHeader>
              <RenderRentersInsuranceDetails
                rentersInsurancePolicy={rentersInsurancePolicy}
                inAccount={props.inAccount}
                address={props.address}
                coverage={coverage}
                addressForRI={props.addressForRI}
                hasRentersInsurance={rentersInsuranceSelected}
                coverGeniusRentersInsurance={coverGeniusRentersInsurance}
                qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
              />
            </Section>
            <hr />
          </>
        )}

        <QuoteDisplayFooter
          arrowOnButton={true}
          nextBtnLabel={'Add payment info'}
          onBack={() => history.back()}
          onNext={() => next()}
          dataCy="paymentInfo"
        />
      </div>
    </div>
  );
}

export default PolicyDetails;
