/** @jsx jsx */
import React, { Fragment, useContext, useState } from 'react';
import { FONTS } from '@sayrhino/rhino-shared-js';
import { jsx } from '@emotion/react';
import UserContext from '../layout/account/user-context';
import { UpdatePaymentModal } from './components/UpdatePaymentModal';

interface IProps {
  card_update_path: string;
  payment_method_update_path: string;
  stripePublishableKey: string;
}

export enum CardUpdateStep {
  Initial,
  Confirm,
  Complete
}

const PaymentInformation = (props: IProps) => {
  const { currentUser } = useContext(UserContext);
  const { card_update_path, payment_method_update_path, stripePublishableKey } = props;
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col" css={[FONTS.h5]}>
          Payment information
        </div>
        <div className="col-auto" css={[FONTS.p2Light]}>
          <span onClick={() => openModal()} className="edit-info">
            Update
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6 col-12" css={[FONTS.p2Medium]}>
              Payment method
            </div>
            {/* TODO: Update this to not be hardcoded */}
            <div className="col-md-6 col-12 mb-3" css={[FONTS.p1Light]}>
              Visa, **** **** **** 1234
            </div>
          </div>
        </div>
      </div>
      <UpdatePaymentModal
        closeModal={closeModal}
        isOpen={isOpen}
        user={currentUser}
        card_update_path={card_update_path}
        payment_method_update_path={payment_method_update_path}
        stripePublishableKey={stripePublishableKey}
      />
    </Fragment>
  );
};

export default PaymentInformation;

const styles = {
  button: {
    border: '1px solid #E3E3E3',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black',
    padding: '0px 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'not-allowed',
    width: '100%'
  },
  activeButton: {
    border: 'none',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'white',
    padding: '0 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    backgroundColor: '#6318CE',
    cursor: 'pointer',
    width: '100%'
  },
  outlineButton: {
    border: '1px solid #6318CE',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#6318CE',
    padding: '0 24px',
    height: '48px',
    background: 'transparent',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'pointer',
    width: '100%'
  },
  error: {
    color: 'red'
  }
};
