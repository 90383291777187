import React, { SyntheticEvent } from 'react';
import CreatePassword from 'components/v2/password/create';
import { useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useUpdateUser } from '../hooks/useUpdateUser';

function Password(props: IChildProps) {
  const [user, setUser] = useSubsetState(props.user.result, ['reset_password_token', 'password']);
  const { mutate: updateUser, isSuccess } = useUpdateUser(props.step);
  const userId = props.user.result?.id;

  const updatePassword = (newValue) => {
    const password = newValue.newPassword;
    const update = {
      password
    };

    setUser(update);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (userId) {
      updateUser({ user, userId, signupStep: props.step });
    }
  };

  return (
    <div>
      <form className="sign-up__form" id="password-form" onSubmit={onSubmit}>
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              <CreatePassword
                resource="user"
                token={user.reset_password_token}
                onChange={(newPassword) => updatePassword({ newPassword })}
                submitButton={true}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  }
};

export default Password;
