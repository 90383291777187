import React from 'react';
import { IChildProps } from './step_wrapper';

function ExpiredInvitation(props: IChildProps) {
  return (
    <div>
      <div className="sign-up__form" id="ExpiredInvitation-form" style={styles.container}>
        <div className="form-holder">
          <div className="form" style={styles.formHolder}>
            <div className="input-holder" style={styles.inputContainer}>
              <h1 className="black" style={styles.header}>Oops! Looks like this invitation has expired.</h1>
              <p style={styles.body}>If you think this is incorrect, please contact support.</p>
              <div className="landing-page--button error-page-btn">
                <a href="https://support.sayrhino.com/hc/en-us/categories/360003291332-Contact-us">
                  Contact Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    padding: '100px'
  },
  container: {
    textAlign: 'center' as 'center'
  },
  header: {
    fontSize: '60px'
  },
  body: {
    fontSize: '18px',
    margin: '28px 0 40px 0'
  }
};

export default ExpiredInvitation;
