import React from 'react';

import DeclinedStatusBanner from './declinedStatusBanner';
import PendingStatusBanner from './pendingStatusBanner';

import { ICancellationRequest } from '../../policyProps'

// TODO: get this from the ENV
const CONTACT_SUPPORT_LINK = 'https://bookmeeting.sayrhino.com/7e52f331-e563-465b-ac44-3db9dcd6dc9f';
interface IProps {
  cancellationRequest: ICancellationRequest;
}

const CancellationRequestStatusBanner = (props: IProps) => {
  const { cancellationRequest } = props;

  let statusBanner;
  if (cancellationRequest.status === 'declined') {
    statusBanner = <DeclinedStatusBanner
      denialLockingPeriod={cancellationRequest.denial_locking_period}
      denialReason={cancellationRequest.denial_reason}
      denialReasonExplanation={cancellationRequest.denial_reason_explanation}
      contactSupportUrl={CONTACT_SUPPORT_LINK}
      responderEmail={cancellationRequest.responder_email}
      responderName={cancellationRequest.responder_name} />
  } else if(cancellationRequest.status === 'pending') {
    statusBanner = <PendingStatusBanner
      estimatedRefundCents={cancellationRequest.estimated_refund_cents}
      responseDeadline={cancellationRequest.response_deadline}
      contactSupportUrl={CONTACT_SUPPORT_LINK} />
  } else {
    return null;
  }

  return (
    <div>{statusBanner}</div>
  );
};

export default CancellationRequestStatusBanner;
