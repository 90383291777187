import React from 'react';
import { IChangeRequest, ICancellationRequest, IRequestPriceInfo } from '../../policyProps';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { serviceFeeLabel, processingFeeLabel } from 'utils/us_states';
import getFeatureFlags from 'utils/getFeatureFlags';

interface IProps {
  coverageInfo: ICoverageInfo;
  changeRequest: IChangeRequest;
}

interface ICoverageInfo {
  property_owner_name: string;
  documents_url: string;
  document_effective_date: string;
  policy_id: string | number;
  payment_amount: string;
  unit_name?: string;
  coverage_start_date: string;
  coverage_end_date: string;
  monthly_rent: string;
  coverage_amount: string;
  status: string;
  upfront: boolean;
  pending_change_request: boolean;
  lease_start_date: string;
  lease_end_date: string;
  policy_number: string;
  cancellation_request: ICancellationRequest | null;
  address_line_one: string;
  state: string;
  zipcode: string;
  price_info: Partial<IPriceInfo>;
}

interface IPartnerRelatedFees {
  move_in_fees: { [key: string]: string };
}

interface IPriceInfo {
  coverage_amount:               string,
  monthly:                       string,
  monthly_cents:                 number,
  monthly_processing_fee:        string,
  monthly_processing_fee_cents?: number,
  service_fee:                   string,
  service_fee_cents:             number,
  upfront:                       string,
  upfront_cents:                 number,
  upfront_discount?:             string | number,
  upfront_processing_fee:        string,
  upfront_processing_fee_cents?: number,
  partner_fees: IPartnerRelatedFees;
}

const FeesListTitle = styled.div([
  FONTS.p1Medium,
  {
    marginBottom: '4px',
    color: PALETTE.neutral88
  }
])

const FeeItem = styled.div([
  FONTS.p2Medium,
  {
    display: 'flex',
    alignItems: 'center',
    color: PALETTE.neutral65,
    lineHeight: '1.2'
  }
])

const FeeAmount = styled.span([
  FONTS.p2Medium,
  {
    marginLeft: '4px',
    color: PALETTE.neutral65
  }
])

const PriceLabel = styled.div([
  FONTS.p1Medium,
  {
    marginBottom: 4,
    marginRight: 70,
    color: PALETTE.neutral65
  }
])

const FeesContainer = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  }
])

const FeesContent = styled.div([
  {
    display: 'flex',
    alignItems: 'flex-start'
  }
])

const FeesList = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
])

const PartnerFees = styled.h4([
  FONTS.h4,
  {
    fontWeight: 400,
    marginTop: 32,
    marginBottom: 7,
    color: PALETTE.neutralDark
  }
])

const PartnerFeesDisclaimer = styled.small([
  {
    fontSize: 10,
    fontWeight: 200,
    marginBottom: 7,
    color: PALETTE.neutral88,
    lineHeight: '1.2',
    fontFamily: 'MaisonNeueLight'
  }
])

const ListBreak = styled.div([
  {
    flexBasis: '100%',
    height: 0
  }
])

const ListItemLabel = styled.div([
  FONTS.p2Medium,
  {
    fontWeight: 400,
    color: PALETTE.neutral65,
    minWidth: 120
  }
]);

const ListItemValue = styled.div([
  FONTS.p1Medium,
  {
    fontWeight: 400,
    color: PALETTE.neutralDark
  }
]);

const ListItemValueStrikeThrough = styled.div([
  FONTS.p1Medium,
  {
    fontWeight: 400,
    color: PALETTE.neutral65,
    textDecorationLine: 'line-through'
  }
]);

const ListItemValueChangeRequest = styled.div([
  FONTS.p1Medium,
  {
    fontWeight: 400,
    color: PALETTE.alert100
  }
]);

const ListSubItem = styled.div([
  FONTS.p3Light,
  {
    fontWeight: 200,
    color: PALETTE.neutralDark
  }
])

const ListSubItemValueChangeRequest = styled.div([
  FONTS.p3Light,
  {
    fontWeight: 200,
    color: PALETTE.alert100
  }
])

const ListSubItemValueStrikeThrough = styled.div([
  FONTS.p3Light,
  {
    fontWeight: 200,
    color: PALETTE.alert100,
    textDecorationLine: 'line-through'
  }
]);

const ListItemWrapper = styled.div([
  {
    borderBottom: `1px solid #E3E3E3`,
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%'
  }
]);

const getStatus = (policy) => {
  const cancellationRequest = policy.cancellation_request;
  const pendingCancellation = cancellationRequest && policy.cancellation_request.status === 'pending';
  const pendingChangeRequest = policy.pending_change_request

  if (pendingCancellation) {
    return 'Pending Cancellation';
  }

  if (pendingChangeRequest) {
    return 'Action Needed';
  }

  return policy.status;
};

const formatCancellationDate = (date) => {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
}

const getEffectiveFrom = (cancellationRequest) => {
  if(cancellationRequest){
    const today = new Date();
    const effectiveFrom = new Date(cancellationRequest.effective_from);

    if(effectiveFrom > today && ['accepted', 'autocancelled'].includes(cancellationRequest.status)) {
      return `, Cancellation scheduled for ${formatCancellationDate(effectiveFrom)}`;
    }
  }

  return '';
}

const processingFeeHasCharges = (policyPrice: Partial<IPriceInfo>) => {
  return (policyPrice.upfront_processing_fee_cents !== undefined && policyPrice.upfront_processing_fee_cents > 0
    || policyPrice.monthly_processing_fee_cents !== undefined && policyPrice.monthly_processing_fee_cents > 0);
}

const processingFeeHasChanged = (
  isUpfront: boolean,
  changeRequestPrice: Partial<IRequestPriceInfo>,
  policyPrice: Partial<IPriceInfo>
) => {
  if (isUpfront) {
    return (changeRequestPrice?.upfront_processing_fee_cents !== undefined
      && changeRequestPrice?.upfront_processing_fee_cents !== policyPrice.upfront_processing_fee_cents);
  }

  return (changeRequestPrice?.monthly_processing_fee_cents !== undefined
      && changeRequestPrice?.monthly_processing_fee_cents !== policyPrice.monthly_processing_fee_cents);
}

const ChangeRequestItem = ({ value, changeValue, hasChanges }) => {
  if (hasChanges) {
    return (
      <div>
        <ListItemValueStrikeThrough>{value}</ListItemValueStrikeThrough>
        <ListItemValueChangeRequest>{changeValue}</ListItemValueChangeRequest>
      </div>
    );
  }
  return <ListItemValue>{value}</ListItemValue>;
};

const SubItemWrapper = ({ label, value }) => {
  return (
    <div style={ styles.wrappedSubItem }>
      <ListBreak />
      <ListItemLabel>{ label }</ListItemLabel>
      <ListSubItem>{ value }</ListSubItem>
    </div>
  )
}

const ChangeRequestSubItem = ({ value, changeValue, hasChanges, label }) => {
  if (hasChanges) {
    return (
      <SubItemWrapper label="" value={
        <div style={ styles.wrappedSubItemChangeRequest }>
          <ListSubItemValueChangeRequest>{`+ `}</ListSubItemValueChangeRequest>
          <ListSubItemValueStrikeThrough>{value}</ListSubItemValueStrikeThrough>
          <ListSubItemValueChangeRequest>{`-> ${changeValue} ${label}`}</ListSubItemValueChangeRequest>
        </div>
      }/>
    );
  }
  return (
    <SubItemWrapper label="" value={
      <ListSubItem>{`+ ${value} ${label}`}</ListSubItem>
    }/>
  );
};

const CancellationRequestStatus = ({ policy, showCancellationRequestStatus }) => {
  if (showCancellationRequestStatus) {
    return(
      <ListItemValue>
        {policy.status}
        {policy.pending_change_request && ', Change Requested' }
        {getEffectiveFrom(policy.cancellation_request)}
      </ListItemValue>
    );
  } else {
    return(<ListItemValue>{getStatus(policy)}</ListItemValue>);
  }
}

const PolicyCoverage: React.FC<IProps> = (props: IProps) => {
  const featureFlags = getFeatureFlags();
  const showCancellationRequestStatus = Boolean(featureFlags?.showCancellationRequestStatus);

  const policy = props.coverageInfo;
  const changeRequest = props.changeRequest;

  const addressHasChanged = changeRequest.address_line_one || changeRequest.state || changeRequest.zipcode;
  const priceHasChanged = changeRequest.payment_amount;
  const unitNameHasChanged = changeRequest.unit;
  const leaseDateHasChanged = changeRequest.lease_start_date || changeRequest.lease_end_date;
  const coverageDateHasChanged = changeRequest.coverage_start_date || changeRequest.coverage_end_date;

  const serviceFeesCharged = policy.price_info.service_fee_cents !== undefined
    && policy.price_info.service_fee_cents > 0;
  const serviceFeesSentence = `+ ${policy.price_info.service_fee} one-time ${serviceFeeLabel(policy.state)}`;

  const processingFeeCharged = processingFeeHasCharges(policy.price_info);
  const processingFeesSentence = (policy.upfront ?
    policy.price_info.upfront_processing_fee : `${policy.price_info.monthly_processing_fee}/Month`
  );
  const processingFeeChanged = processingFeeHasChanged(policy.upfront, changeRequest?.price_info, policy?.price_info);
  const processingFeesChangedSentence = (policy.upfront ?
    changeRequest?.price_info?.upfront_processing_fee : `${changeRequest?.price_info?.monthly_processing_fee}/Month`
  );

  const PartnerFeesList = ({ fees }) => {
    const feeItems = fees.move_in_fees.map((fee, index) => (
      <FeeItem key={index}>
        + <FeeAmount>{`$${(fee.charge_amount_cents / 100).toFixed(2)} ${fee.charge_code}`}</FeeAmount>
      </FeeItem>
    ));

    const totalAmount = fees.move_in_fees.reduce((total, fee) => {
      return total + fee.charge_amount_cents / 100;
    }, 0);

    return (
      <FeesContainer>
        <FeesContent>
          <PriceLabel>Price</PriceLabel>
          <FeesList>
            <FeesListTitle>
              {policy.property_owner_name} One-Time Move-in Fees: ${totalAmount.toFixed(2)}
            </FeesListTitle>
            {feeItems}
          </FeesList>
        </FeesContent>
      </FeesContainer>
    );
  };

  return (
    <div className="row no-gutters" style={styles.container}>
      <div className="row no-gutters">
        <ListItemWrapper>
          <ListItemLabel>Status</ListItemLabel>
          <CancellationRequestStatus policy={policy} showCancellationRequestStatus={showCancellationRequestStatus} />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Price</ListItemLabel>
          <ChangeRequestItem
            value={`${policy.payment_amount}/${policy.upfront ? 'Full' : 'Month'}`}
            hasChanges={priceHasChanged}
            changeValue={`${changeRequest?.payment_amount}/${policy.upfront ? 'Full' : 'Month'}`}
          />
          { serviceFeesCharged && <SubItemWrapper label="" value={ serviceFeesSentence } /> }
          { processingFeeCharged &&
            <ChangeRequestSubItem
              value={processingFeesSentence}
              hasChanges={processingFeeChanged}
              changeValue={processingFeesChangedSentence}
              label={processingFeeLabel(policy.state)}
            />
          }
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Street Address</ListItemLabel>
          <ChangeRequestItem
            value={`${policy.address_line_one}, ${policy.state} ${policy.zipcode}`}
            hasChanges={addressHasChanged}
            changeValue={`${changeRequest?.address_line_one}, ${changeRequest?.state} ${changeRequest?.zipcode}`}
          />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Unit / Floor</ListItemLabel>
          <ChangeRequestItem
            value={policy.unit_name || ''}
            hasChanges={unitNameHasChanged}
            changeValue={changeRequest.unit || ''}
          />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Lease dates</ListItemLabel>
          <ChangeRequestItem
            value={`${policy.lease_start_date} \u2014 ${policy.lease_end_date}`}
            hasChanges={leaseDateHasChanged}
            changeValue={`${changeRequest.lease_start_date || policy.lease_start_date} \u2014 ${
              changeRequest.lease_end_date || policy.lease_end_date
            }`}
          />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Coverage dates</ListItemLabel>
          <ChangeRequestItem
            value={`${policy.coverage_start_date} \u2014 ${policy.coverage_end_date}`}
            hasChanges={coverageDateHasChanged}
            changeValue={`${changeRequest.coverage_start_date || policy.coverage_start_date} \u2014 ${
              changeRequest.coverage_end_date || policy.coverage_end_date
            }`}
          />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Rent amount</ListItemLabel>
          <ChangeRequestItem
            value={policy.monthly_rent}
            hasChanges={!!changeRequest.monthly_rent}
            changeValue={changeRequest.monthly_rent}
          />
        </ListItemWrapper>
        <ListItemWrapper>
          <ListItemLabel>Coverage amount</ListItemLabel>
          <ChangeRequestItem
            value={policy.coverage_amount}
            hasChanges={!!changeRequest.coverage_amount}
            changeValue={changeRequest.coverage_amount}
          />
        </ListItemWrapper>
      </div>

      {props.coverageInfo.price_info.partner_fees &&
        <div className="row no-gutters">
          <PartnerFees>Partner-related Move-in Fees</PartnerFees>

          <PartnerFeesDisclaimer>Landlord Move-In Fees are controlled by the terms of your lease and your Landlord.
            As these fees have been set by your Landlord, please contact them directly with any questions.
            Rhino does not receive any portion of the Landlord Move-In Fees.
          </PartnerFeesDisclaimer>

          <PartnerFeesList fees={props.coverageInfo.price_info.partner_fees} />
        </div>
      }
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrappedSubItem: {
    display: 'contents'
  },
  wrappedSubItemChangeRequest: {
    display: 'flex'
  }
};

export default PolicyCoverage;
