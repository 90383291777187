import React from 'react';
import { IFinalCtaModuleFields } from '../../../../../@types/generated/contentful';
import { CaseStudyModule, CaseStudyModuleProps } from './CaseStudyModule';
import { FinalCtaModule } from './FinalCtaModule';
import { HeroSection, HeroSectionProps } from './HeroSection';
import { LogosModule, LogosModuleProps } from './LogosModule';
import { Spacer72pxDesktop52pxMobile } from './Spacers';
import { TestimonialsModule, TestimonialsModuleProps } from './TestimonialsModule';
import { ValuePropsModule, ValuePropsModuleProps } from '../shared/ValuePropsModule';
import { Helmet } from 'react-helmet';
import { UserProviderContainer } from '../layout/account/user-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FeaturesModule } from './FeaturesModule';
import { ProductModule } from './types/ProductModule';
import styled from '@emotion/styled';
import { ResultsModule } from './ResultsModule';

const queryClient = new QueryClient();

interface ResidentialPageProps {
  heroSection: HeroSectionProps;
  partnerLogosModule: LogosModuleProps;
  finalCtaModule: IFinalCtaModuleFields;
  valuePropsModule: ValuePropsModuleProps;
  testimonialsModule: TestimonialsModuleProps;
  caseStudyModule: CaseStudyModuleProps;
  integrationsModule: LogosModuleProps;
  featuresModule: ProductModule;
  resultsModule: ProductModule;
}

const SectionDivider = styled.hr({
  margin: '0px',
  border: 'none',
  borderBottom: '1px solid #F2EEFF'
});

const ResidentialPage: React.FC<ResidentialPageProps> = (props) => {
  const {
    heroSection,
    partnerLogosModule,
    finalCtaModule,
    valuePropsModule,
    testimonialsModule,
    caseStudyModule,
    integrationsModule,
    featuresModule,
    resultsModule
  } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <UserProviderContainer>
        <HeroSection {...heroSection} />
        <SectionDivider />
        <Spacer72pxDesktop52pxMobile />
        <LogosModule {...partnerLogosModule} />
        <Spacer72pxDesktop52pxMobile />
        <SectionDivider />
        <FeaturesModule {...featuresModule} />
        <SectionDivider />
        <Spacer72pxDesktop52pxMobile />
        <ValuePropsModule {...valuePropsModule} />
        <SectionDivider />
        <Spacer72pxDesktop52pxMobile />
        <ResultsModule {...resultsModule} />
        <Spacer72pxDesktop52pxMobile />
        <SectionDivider />
        <Spacer72pxDesktop52pxMobile />
        <LogosModule {...integrationsModule} />
        <Spacer72pxDesktop52pxMobile />
        <TestimonialsModule {...testimonialsModule} />
        <Spacer72pxDesktop52pxMobile />
        <CaseStudyModule {...caseStudyModule} />
        <FinalCtaModule {...finalCtaModule} />
      </UserProviderContainer>
    </QueryClientProvider>
  );
};

export default ResidentialPage;
