import React from 'react';
import { ProductModule } from './types/ProductModule';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

const ResultsModuleHeader = styled.h2(FONTS.brandH2Bold, {
  fontSize: '44px',
  lineHeight: '56px',
  textAlign: 'center'
});

const ResultsRow = styled.div({
  width: '100%',
  height: '383px',
  '@media (max-width: 768px)': {
    height: 'auto',
    paddingTop: '50px'
  }
});

const ResultsCardsContainer = styled.div({
  '@media (max-width: 991px)': {
    gap: '30px'
  },

  '@media (max-width: 768px)': {
    gap: '50px',

    '> *:not(:last-child)': {
      borderBottom: '1px solid #e3e3e3',
      width: '100%',
      paddingBottom: '50px'
    }
  }
});

const ResultsCardHeader = styled.h3([
  FONTS.extendedMedium,
  {
    fontSize: '69px',
    lineHeight: '92px',
    textAlign: 'center',
    color: PALETTE.raptor,
    fontWeight: 'bold'
  }
]);

const ResultsCardSubheader = styled.h3(FONTS.h3, {
  textAlign: 'center',
  width: '220px'
});

const ResultsCardDivider = styled.hr({
  margin: '0px',
  width: '60px',
  border: 'none',
  borderBottom: '1px solid black'
});

const ResultsCard = styled.div({
  gap: '25px'
});

const BetweenCardDivider = styled.div({
  width: '1px',
  height: '60%',
  backgroundColor: '#e3e3e3',

  '@media (max-width: 768px)': {
    width: '0px',
    height: '0px',
    display: 'none'
  }
});

export const ResultsModule: React.FC<ProductModule> = (props) => {
  const { groupedContent, header } = props;

  return (
    <div className="container d-flex flex-column align-items-center">
      <ResultsModuleHeader>{header}</ResultsModuleHeader>
      <ResultsRow className="row">
        <ResultsCardsContainer className="col d-flex justify-content-around align-items-center flex-column flex-md-row">
          {groupedContent &&
            groupedContent.map((item, index) => {
              return (
                <>
                  <ResultsCard className="d-flex flex-column align-items-center">
                    <ResultsCardHeader>{item.header}</ResultsCardHeader>
                    <ResultsCardDivider />
                    <ResultsCardSubheader>{item.subhead}</ResultsCardSubheader>
                  </ResultsCard>
                  {index !== groupedContent!.length - 1 && <BetweenCardDivider />}
                </>
              );
            })}
        </ResultsCardsContainer>
      </ResultsRow>
    </div>
  );
};
