/** @jsx jsx */
import { useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { IProps } from './index';
import { css, jsx } from '@emotion/react';
import { PaymentOption } from './utils';
import PolicyDetails from './policy_details';
import { PaymentVariant } from './payment_variant';
import { PolicySelection } from './PolicySelection';
import { OrderSummary } from '../renters_insurance/components/OrderSummary';
import { OrderSummaryV2 } from '../renters_insurance/components/OrderSummaryV2';
import { RentersInsurance } from './RentersInsurance';
import { FAQ } from './FAQ';
import styled from '@emotion/styled';
import { DidYouKnow } from './did_you_know';
import env from 'utils/env';
import useOptOutRentersInsurance from '../renters_insurance/hooks/useOptOutRentersInsurance';

const leftPanelDesktopStyles = css({
  flexGrow: 1,
  display: 'flex',
  width: '60%'
});

const leftPanelStyles = css({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  justifyContent: 'center'
});

const DesktopOrderDiv = styled.div({
  marginTop: '30px'
});

const quoteWrapper = css({
  display: 'flex'
});

export enum riPageType {
  single_page,
  combined_page
}

function QuoteDisplayVariant(props: IProps) {
  const isMobile = useCheckMobile();
  const { path } = useRouteMatch();
  const [paymentOption, setPaymentOption] = useState(PaymentOption.Upfront);
  const [activeStep, setActiveStep] = useState<null | string>(null);
  const [rentersInsuranceQuote, setRentersInsuranceQuote] = useState(null);
  const disqualifiedFromMonthlySdi = props.policyApplication.result?.disqualified_from_monthly;
  const rentersInsuranceInstalmentsFlag = (window as any)?.App?.featureFlags?.rentersInsuranceInstalments;
  const rentersInsuranceInstalmentsEnrollmentFlag = (window as any)?.App?.featureFlags
    ?.rentersInsuranceInstalmentsEnrollment;
  const qualifiesForMonthlyRentersInsurance =
    env('MONTHLY_RENTERS_INSURANCE_STATES').includes(props.address.state);
  const eligibleInstalmentRIState =
    env('INSTALMENT_RENTERS_INSURANCE_STATES').includes(props.address.state) &&
    rentersInsuranceInstalmentsFlag &&
    rentersInsuranceInstalmentsEnrollmentFlag;
  const eligibleServiceFeeState: boolean = env('SERVICE_FEE_STATES').includes(props.address.state);
  const { mutate: optOutRentersInsurance } = useOptOutRentersInsurance();
  const optOut = (insNumber: string) => {
    optOutRentersInsurance({ ins_number: insNumber });
  };

  const { search } = useLocation();
  const [rentersInsuranceSelected, setRentersInsuranceSelected] = useState(
    new URLSearchParams(search).get('ri_selected') === 'true'
  );
  const [rentersInsuranceCadence, setRentersInsuranceCadence] = useState(PaymentOption.Upfront);

  const paymentPanelProps = Object.assign({ paymentOption }, props.payment, {
    payAndSubscribe: props.payAndSubscribe,
    policyApplication: props.policyApplication,
    user: props.user,
    price: props.price,
    rentersInsuranceSelected,
    qualifiesForMonthlyRentersInsurance,
    eligibleInstalmentRIState,
    eligibleServiceFeeState,
    optOut
  });

  const policyDetailsProps = {
    map: props.map,
    address: props.address,
    coverage: props.coverage,
    lease: props.lease,
    policyApplicationId: props.policyApplication.result?.id,
    userId: props.user.result?.id,
    inAccount: false,
    goToStep: () => {
      null;
    },
    search,
    rentersInsuranceSelected,
    coverGeniusRentersInsurance:
      rentersInsuranceQuote || props?.policyApplication?.result?.cover_genius_renters_insurance,
    qualifiesForMonthlyRentersInsurance
  };

  const policySelectionProps = {
    price: props.price,
    paymentOption,
    setPaymentOption,
    backPath: props.edit_path,
    policyApplicationId: props.policyApplication.result?.id,
    userId: props.user.result?.id,
    qualifiesForMonthlyRentersInsurance,
    eligibleInstalmentRIState
  };

  const RightPanel = styled.div(
    isMobile
      ? {}
      : {
          width: '40%',
          flexGrow: 1,
          paddingLeft: 50
        }
  );

  const FaqWrapper = styled.div({
    maxWidth: '384px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px'
  });

  const DidYouKnowWrapper = styled.div({
    paddingTop: '30px',
    maxWidth: '384px',
    margin: 'auto'
  });

  const riPaymentFlexiblityEnabled = (window as any).App?.featureFlags?.riPaymentFlexiblity;

  const OrderSummaryV1 = 
    <OrderSummary
      price={props.price}
      paymentOption={paymentOption}
      sdiPrice={props.policyApplication.result?.price_info}
      qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
      eligibleInstalmentRIState={eligibleInstalmentRIState}
      coverGeniusRentersInsurance={
        rentersInsuranceQuote || props?.policyApplication?.result?.cover_genius_renters_insurance
      }
      taa_path={props.payment.paths.taa_path}
      address_state={props.address.state}
    />

  const OrderSummaryRiPaymentFlexibility = 
    <OrderSummaryV2
      price={props.price}
      paymentOption={paymentOption}
      sdiPrice={props.policyApplication.result?.price_info}
      qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
      eligibleInstalmentRIState={eligibleInstalmentRIState}
      coverGeniusRentersInsurance={
        rentersInsuranceQuote || props?.policyApplication?.result?.cover_genius_renters_insurance
      }
      taa_path={props.payment.paths.taa_path}
      address_state={props.address.state}
    />

  const OrderSummaryComponent = riPaymentFlexiblityEnabled ? OrderSummaryRiPaymentFlexibility : OrderSummaryV1;

  return (
    <div css={css({ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flexGrow: 1 }, quoteWrapper)}>
      <div css={isMobile ? leftPanelStyles : leftPanelDesktopStyles}>
        <Switch>
          <Route
            exact
            path={path}
            render={(innerProps) => (
              <PolicySelection
                {...policySelectionProps}
                {...innerProps}
                setActiveStep={(step: string | null) => setActiveStep(step)}
                copy={props.copy}
                policyApplication={props?.policyApplication?.result}
                setRentersInsuranceQuote={setRentersInsuranceQuote}
                partnerRentersInsuranceRequirements={props?.partnerRentersInsuranceRequirements.result}
                disqualifiedFromMonthlySdi={disqualifiedFromMonthlySdi}
                rentersInsuranceSelected={rentersInsuranceSelected}
                rentersInsuranceCadence={rentersInsuranceCadence}
              />
            )}
          />
          <Route exact path={`${path}/renters_insurance`}>
            <RentersInsurance
              paymentOption={paymentOption}
              rentersInsuranceSelected={rentersInsuranceSelected}
              setRentersInsuranceSelected={setRentersInsuranceSelected}
              setRentersInsuranceCadence={setRentersInsuranceCadence}
              setActiveStep={(step: string | null) => setActiveStep(step)}
              userId={policySelectionProps.userId!}
              policyAppId={policySelectionProps.policyApplicationId!}
              qualifiesForMonthlyRentersInsurance={qualifiesForMonthlyRentersInsurance}
              eligibleInstalmentRIState={eligibleInstalmentRIState}
              coverGeniusRentersInsurance={
                rentersInsuranceQuote || props?.policyApplication?.result?.cover_genius_renters_insurance
              }
              partnerRentersInsuranceRequirements={props?.partnerRentersInsuranceRequirements.result}
              coverGeniusRentersInsuranceNumber={
                props?.policyApplication?.result?.cover_genius_renters_insurance?.ins_number
              }
              rentersInsuranceCadence={rentersInsuranceCadence}
              policyApplicationAddressState={props.address.state}
            />
          </Route>
          <Route exact path={`${path}/policy_details`}>
            <PolicyDetails {...policyDetailsProps} />
          </Route>
          <Route
            exact
            path={`${path}/payment`}
            render={() => (
              <PaymentVariant
                {...paymentPanelProps}
                paymentOption={paymentOption}
                coverGeniusRentersInsurance={
                  rentersInsuranceQuote || props?.policyApplication?.result?.cover_genius_renters_insurance
                }
              />
            )}
          />
        </Switch>
      </div>
      <RightPanel>
        {!isMobile && (
          <DesktopOrderDiv>
            {OrderSummaryComponent}
            {activeStep === 'quote_display' && (
              <DidYouKnowWrapper>
                <DidYouKnow copyContent={props.copy} />
              </DidYouKnowWrapper>
            )}
          </DesktopOrderDiv>
        )}
        {activeStep === 'renters_insurance' && (
          <FaqWrapper>
            {' '}
            <FAQ />{' '}
          </FaqWrapper>
        )}
      </RightPanel>
    </div>
  );
}
export default QuoteDisplayVariant;
