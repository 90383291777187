import React from 'react';
import { Helmet } from 'react-helmet';

class ZendeskSupportLandingPageWidget extends React.PureComponent {
  render() {
    const isMobile = (window as any).innerWidth < 800;
    const isLandingPage: boolean = window.location.pathname === '/';
    if (!isMobile && isLandingPage) {
      return (
        <Helmet>
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=55c40d8d-01e4-4fc9-ad67-b9a807712b14"
          />
        </Helmet>
      );
    }
  }
}

export default ZendeskSupportLandingPageWidget;
