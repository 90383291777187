import { createContext, useContext } from 'react';

export interface IAssets {
  map: {
    tooltip: string;
    whiteTooltip: string;
  };
}

export const AssetContext = createContext<IAssets>((window as any).App.assets as IAssets);
export const AssetProvider = AssetContext.Provider;
export const useAssetContext = () => useContext(AssetContext);
