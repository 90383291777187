import React, { Component } from 'react';
import AutocompleteInput from 'components/common/form/autocomplete-input';
import Form from 'components/common/form/form';
import Submit from 'components/common/form/submit';
import ErrorText from 'components/common/form/error-text/error-text';
import { request as get } from 'utils/request';

type IProps = {
  url: string;
  csrf_token: string;
  href: string;
  search_url: string;
  reset_password_path: string;
};

type IUser = {
  id: number;
  email: string;
};

type IState = {
  value: string;
  wrong: boolean;
  dirty: boolean;
  users: IUser[];
  user?: IUser;
};

class SignInForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value: '',
      wrong: false,
      dirty: false,
      users: []
    };
  }

  onChange = (value: string) => {
    this.setState({ value });

    if (value) {
      const url = this.props.search_url + '?query=' + value;

      fetch(url, { credentials: 'same-origin' })
        .then((response): any => {
          return response.json();
        })
        .then((users) => {
          const userMatch = users.find((user) => user.email === this.state.value);
          this.setState({ users, dirty: false, wrong: userMatch === undefined });
        });
    } else {
      this.setState({ dirty: false, users: [], wrong: false });
    }
  };

  onSelect = (user: IUser) => {
    this.setState({ user, value: user.email, wrong: false });
  };

  autocompleteProps = () => ({
    fieldName: 'email',
    placeholder: 'me@email.com',
    onChange: this.onChange,
    onSelect: this.onSelect,
    value: this.state.value,
    matchBy: 'email',
    autoFocus: true,
    options: this.state.users,
    inputProps: {
      'data-autocompleted': !this.state.wrong
    }
  });

  formProps = () => ({
    action: this.props.url,
    className: '',
    'data-remote': 'false',
    csrf_token: this.props.csrf_token
  });

  onClick = (e: any) => {
    if (!this.state.value || this.state.wrong) e.preventDefault();

    this.setState({ dirty: true });
  };

  handleResetPasswordClick = (e: any) => {
    e.preventDefault();

    const { user } = this.state;

    if (!user) {
      return;
    }

    get(this.props.reset_password_path, { user_id: user.id })
      .then((response) => {
        alert(response.url);
      })
      .catch((r) => {
        alert('Sorry, something went wrong');
      });
  };

  render() {
    const { user, value, wrong, dirty } = this.state;

    return (
      <Form {...this.formProps()}>
        {!value && dirty && <ErrorText text="Email can't be blank." />}
        {wrong && dirty && <ErrorText text="You entered an incorrect email." />}
        <AutocompleteInput {...this.autocompleteProps()} />

        <div className="sign-up__actions">
          <Submit
            value="Sign in"
            className="btn-default sign-up__action"
            onClick={this.onClick}
            style={{ marginTop: '15px' }}
          />

          {user && (
            <span>
              <a href={`/admin/users/${user.id}/edit`} style={{ margin: '25px', display: 'inline-block' }}>
                Edit
              </a>
              <a
                id="reset-pass-btn"
                style={{ margin: '25px', display: 'inline-block' }}
                onClick={this.handleResetPasswordClick}
              >
                Generate Reset Password URL
              </a>
            </span>
          )}

          <a href={this.props.href} style={{ float: 'right', marginTop: '30px' }}>
            Create a New User
          </a>
        </div>
      </Form>
    );
  }
}

export default SignInForm;
