import React, { useState } from 'react';

import RoommateForm from './RoommateForm';
import { IRoommate } from '../policyProps';

interface IProps {
  index: number;
  numeration: number;
  roommate: IRoommate;
}

const RoommateCard: React.FC<IProps> = (props: IProps) => {
  const { index, roommate, numeration } = props;
  const [isEdit, setIsEdit] = useState(!roommate?.id);
  const onSubmit = () => setIsEdit(false);

  const phone = (): string => {
    const phoneParts = roommate?.phone?.match(/(\d{3})(\d{3})(\d{4})/);
    return phoneParts ? `(${phoneParts[1]}) ${phoneParts[2]} - ${phoneParts[3]}` : '&mdash;';
  };

  if (isEdit) {
    return <RoommateForm
      roommate={roommate}
      index={index}
      numeration={numeration}
      onSubmit={onSubmit} />
  }

  return (
    <div className="roommate-card">
      <div className="numeration">{numeration}</div>

      <div className="line">
        <div className="full-name">{roommate.full_name}</div>
        <a className="edit" onClick={() => setIsEdit(true)}>Edit</a>
      </div>

      <div className="line">
        <div className="email_or_phone">{roommate.email}</div>
        <div
          className="email_or_phone"
          dangerouslySetInnerHTML={{__html: phone()}} />
      </div>
    </div>
  )
}

export default RoommateCard;
