import React from 'react';

import RadioOption from 'components/common/radio_form/RadioOption';

interface IRadioGroupOption {
  label: string;
  value: any;
}
interface IRadioGroupProps {
  name: string;
  selected: any;
  options: IRadioGroupOption[];
  onChange(value: any): void;
}

function RadioGroup(props: IRadioGroupProps): JSX.Element {
  return (
    <div className="radio-form">
      {
        props.options.map((option, index) => (
          <RadioOption
            id={`radio-${props.name}-option-${index}`}
            key={index}
            value={option.value}
            label={option.label}
            onChange={props.onChange}
            selected={props.selected}
            name={props.name}
          />
        ))
      }
    </div>
  );
}

export default RadioGroup;
