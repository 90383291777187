import styled from '@emotion/styled';
import {
  FONTS,
  PALETTE,
  FONT_FAMILY,
  Radio,
  TextInput,
  Button
} from '@sayrhino/rhino-shared-js';

export const ButtonGroup = styled.div({
  position: 'sticky',
  bottom: 32,
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-end',
  margin: '62px 0px',
  '@media (min-width: 426px)': {
    gap: '30px'
  }
});

export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
});

export const StyledContent = styled.div({
  padding: '2rem',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '6px',
  margin: '20px 0px',
  '@media (max-width: 992px) and (min-width: 360px)': {
    padding: '1rem'
  },
  '@media (max-width: 359px)': {
    padding: '.1rem'
  }
});

export const DepositSection = styled(StyledContent)({
  display: 'flex',
  height: '100px',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const HeaderWrapper = styled.div({ marginTop: '30px', marginBottom: '30px' });

export const SelectorWrapper = styled.div({
  margin: '24px 0',
  '&.disabled': {
    display: 'none'
  }
});

export const HeaderText = styled.h4([
  FONTS.h4,
  {
    color: PALETTE.neutralDark,
    fontWeight: '600',
    marginBottom: 0
  }
]);

export const StyledTextInput = styled(TextInput)({ marginTop: '16px' });

export const DepositAmount = styled.div([
  FONTS.h3,
  {
    color: PALETTE.brand125
  }
]);

export const Heading = styled.p([FONTS.h2]);

export const BankSectionText = styled.p([FONTS.p2, {
  marginLeft: '24px',
  color: '#1F1F1F'
}]);

export const WithdrawalWrapper = styled.div({
  width: '50%',
  margin: 'auto',
  '@media (max-width: 992px)': {
    width: '90%'
  }
})

export const StyledBackButton = styled(Button)({
  fontWeight: 500,
  justifyContent: 'center'
})

export const FormLabel = styled.p([FONT_FAMILY.medium, {
  margin: '16px 0px 2px 0px',
  fontSize: '12px',
  fontWeight: 500,
  color: PALETTE.neutral65
}]);

export const StyledRadioRoot = styled(Radio.Root)({
  marginTop: '8px',
  paddingBottom: '12px',
  'label': {
    marginBottom: 0
  }
});

export const PhoneInputWrapper = styled.div({
  marginBottom: '16px',
  'input.phone-input, button.phone-input': {
    border: 'none',
    boxShadow: 'inset 0 0 0 1px #bfbfbf',
    height: '100%'
  },
  'input.phone-number': {
    boxSizing: 'border-box',
    flexGrow: 1,
    fontSize: '16px',
    padding: '8px'
  },
  'div.phone-input-container': {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    height: '44px',
    width: '100%',
    borderRadius: '4px',
    '&:hover .phone-input': {
      boxShadow: 'inset 0 0 0 1px #1f1f1f'
    },
    '&:focus-within .phone-input': {
      boxShadow: 'inset 0 0 0 1px #315bf1, 0 0 0 1px #315bf1'
    }
  }
});
