import React, { EffectCallback, ReactNode, useEffect, FormEvent, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateModalState, signOut } from '../redux/actions';
import { IState } from '../redux/state';
import { useRedirect, useEnrollmentStep } from '../step_helpers';

export interface IProps {
  children: ReactNode;

  // rootPage represents which page the modal was launched from, so we can
  // return there when we close the modal.
  rootPage: string;
}

export interface IModalCloseConfirmProps {
  visible: boolean;
  onConfirm: (e: SyntheticEvent) => void;
  onResume: (e: SyntheticEvent) => void;
}

const PURPLE_PAGES = new Set(['check_your_inbox']);

const BODY_CLASS = 'fullscreen-modal-visible';

// Reflects on `visible` to determine whether or not to add `BODY_CLASS` to
// the body tag.
const bodyClassEffect: (visible: boolean) => EffectCallback = (visible: boolean) => () => {
  const body = document.getElementsByTagName('body')[0];
  let bodyClass: string[] = Array.prototype.slice.call(body.classList);
  if (visible) {
    if (bodyClass.find((x) => x === BODY_CLASS) === undefined) {
      bodyClass.push(BODY_CLASS);
    }
  } else {
    bodyClass = bodyClass.filter((x) => x !== BODY_CLASS);
  }
  body.className = bodyClass.join(' ');
};

function ModalCloseConfirm(props: IModalCloseConfirmProps) {
  const classes = ['fullscreen-modal-confirm-close'];
  if (props.visible) {
    classes.push('visible');
  }

  return (
    <div className={classes.join(' ')}>
      <div className="fullscreen-modal-confirm-close-content">
        <h2 className="bold purple">Are you sure you want to leave the application?</h2>
        <p className="p2">You can always return and pick up where you left off</p>
        <p>
          <button className="transparent-button no-arrow" id="confirm-signup-close" onClick={props.onConfirm}>
            Leave application
          </button>
        </p>
        <p>
          <a href="#" className="purple-link" id="resume-signup" onClick={props.onResume}>
            Resume application
          </a>
        </p>
      </div>
    </div>
  );
}

function SignupFlowLayout(props: IProps) {
  useRedirect();
  const visible = useSelector((state: IState) => state.modal.visible);
  useEffect(bodyClassEffect(visible));
  const dispatch = useDispatch();
  const signOutOnClose = props.rootPage.match(/^\/users\/edit(\/.*)?/) === null;
  const [confirmPromptVisible, setConfirmPromptVisible] = useState(false);
  const step = useEnrollmentStep();

  const classes = ['fullscreen-modal d-flex flex-column'];
  if (visible) {
    classes.push('visible');
  }
  if (step && PURPLE_PAGES.has(step)) {
    classes.push('purple-background');
  }

  const closeModal = () => {
    if (signOutOnClose) {
      dispatch(signOut(props.rootPage));
    } else {
      dispatch(
        updateModalState({
          visible: false,
          redirect: props.rootPage
        })
      );
      setConfirmPromptVisible(false);
    }
  };

  const onResume = (e: SyntheticEvent) => {
    e.preventDefault();
    setConfirmPromptVisible(false);
  };

  useEffect(() => {
    const modalLogo = document.getElementById('modal-logo');
    if (modalLogo) {
      modalLogo.scrollIntoView();
    }
  }, [step]);

  return (
    <div>
      <div style={{ inset: 0, padding: 0 }}>
        <div className={classes.join(' ')}>
          <ModalCloseConfirm visible={visible && confirmPromptVisible} onConfirm={closeModal} onResume={onResume} />
          <div className="fullscreen-modal-navbar">
            <div className="fullscreen-modal-logo" id="modal-logo" style={{ scrollMargin: 50 }}></div>
            <div
              className="fullscreen-modal-close"
              data-cy="modalCancel"
              onClick={() => setConfirmPromptVisible(true)}
            ></div>
          </div>
          <div className="fullscreen-modal-content flow-step--page flex-grow-1 d-flex flex-column">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupFlowLayout;
