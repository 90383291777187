import React, { useState } from 'react';

interface IProps {
  baseUrl: string;
}

const CopyClipBoardButton = (props: IProps) => {
  const { baseUrl } = props;
  const [copySuccess, setCopySuccess] = useState(false);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(baseUrl);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <div className="icon-button clipboard">
      <p className="icon-clipboard" id="clipboard" onClick={copyCodeToClipboard} />
      <span></span>
      <div className="copy-message">{copySuccess ? <div id="success-message">Copied!</div> : null}</div>
    </div>
  );
};

export default CopyClipBoardButton;
