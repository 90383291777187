import { useMutation } from 'react-query';
import axios from 'axios';
import { csrfToken } from 'utils/document';

export interface IDeposit {
  userId?: number | undefined;
  policyApplicationId?: number;
  signup_step?: boolean
}

const authenticity_token = csrfToken();
const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': authenticity_token
};

const FetchDeposifyIframeURL = async ({ userId, policyApplicationId }: IDeposit) => {
  const { data } = await axios.get(`/users/${userId}/policy_applications/${policyApplicationId}/cash_deposit.json`, {
    headers
  });

  return data;
};

export const useDeposifyIframeURL = () => {
  const { mutate: getDeposifyIframeURL, ...rest } = useMutation(FetchDeposifyIframeURL);
  return {
    getDeposifyIframeURL,
    ...rest
  };
};
