import React from 'react';
import { IResult } from '../redux/actions';
import { PolicyApplication, User } from '../redux/state';

export enum Panel {
  Guilding,
  Map
}

export interface IProps {
  containerId?: string;
  panel: Panel;
}

export interface ISignupStepProps {
  user: IResult<User>;
  policyApplication: IResult<PolicyApplication>;
}

const PanelWrapper = (props: IProps) => {
  const { containerId } = props;
  return (
    <div className="container" id={containerId}>
      <div className="row">
        <div className="col-lg-5 col-sm-12 col-xs-12 d-lg-block order-last order-lg-first">
        </div>
        <div className="col-lg-7 col-sm-12 col-xs-12 d-lg-block order-first order-lg-last">
        </div>
      </div>
    </div>
  );
};

export default PanelWrapper;
