import { UPDATE_CANCELLATION_REQUEST } from '../actions';

export default function cancellationRequest(state = {}, action) {
  switch(action.type) {
    case UPDATE_CANCELLATION_REQUEST:
      return { ...state, ...action.cancellationRequest };
    default:
      return state;
  }
}
