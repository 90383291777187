import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Card as CardBase,
  FONTS,
  Button,
  PALETTE,
  ArrowIcon,
  ROUNDNESS,
  CheckIcon as CheckIconBase
} from '@sayrhino/rhino-shared-js';
import env from 'utils/env';
import { Steps } from './RentersInsuranceModal';
import { BackButton } from './SharedStyles';
import { FAQ } from 'components/v2/quote_display/FAQ';
import { formatCents } from 'utils/money/formatter';
import { PaymentOption as PAYMENT_OPTIONS } from 'components/v2/quote_display/utils';
import { PolicyApplication } from 'components/v2/signup/redux/state';
import { IResult } from 'components/v2/signup/redux/actions';
import { MONTHLY_PROCESSING_FEE } from '../constants';
import rentersInsuranceImageSVG from '../../quote_display/rentersInsuranceImage.svg';

const Header = styled.h2([FONTS.h2], {
  lineHeight: '150%',
  letterSpacing: '0.56px',
  marginBottom: '8px',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    fontSize: '24px'
  }
});
const CardLabel = styled.p([FONTS.p1Light, { marginBottom: 0 }, { color: PALETTE.neutral65 }]);
const ReviewDetails = styled(Button)([{ '&&': { background: PALETTE.brand100 } }]);
const Price = styled.div([
  FONTS.h2,
  {
    color: PALETTE.brand100,
    '@media (max-width: 480px)': {
      fontSize: '20px',
      lineHeight: 'normal'
    }
  }
]);
const Banner = styled.div([{ justifyContent: 'center', marginBottom: '32px' }]);
const Row = styled.div([{ display: 'flex', justifyContent: 'space-between' }]);

const Wrapper = styled.div([
  {
    margin: '0 auto',
    maxWidth: '480px',
    '@media (min-width: 769px)': {
      margin: '0 auto',
      width: '480px'
    }
  }
]);

const Label = styled.p([
  FONTS.p1Medium,
  {
    color: 'black',
    letterSpacing: '0.32px',
    marginBottom: 24,
    textAlign: 'center',
    '@media (max-width: 480px)': {
      fontSize: '16px'
    }
  }
]);

const LabelBold = styled.span([FONTS.p1Medium]);

const SubLabel = styled.p([
  FONTS.p1Light,
  {
    color: PALETTE.neutral88,
    fontSize: '12px',
    lineHeight: 'normal'
  }
]);

const Card = styled(CardBase)({
  '&&': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    height: 'auto',
    margin: '24px auto',
    backgroundColor: 'white',
    width: 'auto',
    padding: '16px',
    overflow: 'hidden',
    border: `1px solid ${PALETTE.neutral12}`
  }
});

const ActionButtons = styled.div([
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: '24px',
    marginBottom: '24px'
  }
]);

const QuoteSection = styled.div([
  {
    display: 'flex',
    color: PALETTE.brand100,
    alignItems: 'baseline',
    marginTop: '8px',
    marginBottom: '8px',
    '@media (max-width: 480px)': {
      marginTop: '4px',
      marginBottom: '4px'
    }
  }
]);

const ToggleButtonStyle = styled(Button)([
  {
    '&&': {
      border: 'none',
      background: 'transparent',
      fontSize: 16,
      padding: '6px 22px',
      flexGrow: 1,
      outline: 'none',
      width: 133,
      color: PALETTE.neutralDark,
      font: FONTS.p1Medium,
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        color: 'white',
        background: '#502cb9'
      }
    }
  }
]);

const activeButtonStyles = {
  background: '#6A3BF5',
  color: PALETTE.neutralLight
};

const ToggleButtonWrapper = styled.div([
  ROUNDNESS.round,
  {
    '&&': {
      background: PALETTE.neutral4,
      border: `1px solid ${PALETTE.neutral25}`,
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'space-between',
      padding: 3,
      marginTop: 24,
      marginLeft: 0,
      '&&': {
        '@media (max-width: 768px)': {
          background: PALETTE.neutral4,
          border: `1px solid ${PALETTE.neutral25}`,
          alignItem: 'left',
          justifyContent: 'left',
          marginTop: 40,
          marginLeft: 'auto'
        }
      }
    }
  }
]);

const CadenceStyle = styled.span([FONTS.h5, { color: PALETTE.brand100 }]);
const RentersInsuranceHeader = styled.h4(FONTS.h4, {
  '&&': { color: PALETTE.neutralDark, marginBottom: 8 }
});
const RentersInsuranceDetail = styled.p(FONTS.p2Medium, {
  '&&': {
    color: PALETTE.neutralDark,
    textAlign: 'left',
    marginBottom: 0,
    '@media (max-width: 480px)': {
      fontSize: '10px'
    }
  }
});
const CheckIcon = styled(CheckIconBase)({
  width: '16px',
  height: '16px',
  color: PALETTE.success,
  fontWeight: 'bold',
  '@media (max-width: 480px)': {
    width: '12px',
    height: '12px'
  }
});
interface IRentersInsuranceQuote {
  in_full_quote_cents: number;
  monthly_quote_cents: number;
  instalments_quote_cents: number;
  address_state: string;
}
interface IGetQuoteProps {
  goToStep: (modalStep: string) => void;
  rentersInsurancePolicy: IRentersInsuranceQuote;
  renter_insurance_faq_variation?: boolean;
  setPaymentOption: (option: number) => void;
  paymentOption: PAYMENT_OPTIONS;
  policyApplication?: IResult<PolicyApplication>;
  rentersInsuranceRequired: boolean;
  partnerRequiresRentersInsurance: boolean | null;
  eligibleInstalmentRIState: boolean;
}

const GetQuote = (props: IGetQuoteProps) => {
  const {
    rentersInsurancePolicy,
    goToStep,
    setPaymentOption,
    paymentOption,
    partnerRequiresRentersInsurance,
    eligibleInstalmentRIState
  } = props;

  const eligibleMonthlyRIState = env('MONTHLY_RENTERS_INSURANCE_STATES').includes(rentersInsurancePolicy.address_state);

  const qualifiesForMonthlyBasisRentersInsurance = eligibleMonthlyRIState || eligibleInstalmentRIState;

  const rentersInsuranceRequired =
    partnerRequiresRentersInsurance !== true && props.rentersInsuranceRequired !== true ? false : true;

  const {
    instalments_quote_cents: instalmentsCents,
    monthly_quote_cents: monthlyCents,
    in_full_quote_cents: upfrontCents
  } = rentersInsurancePolicy;

  const monthlyPaymentSelected = paymentOption === PAYMENT_OPTIONS.Monthly;

  const formattedPrice = () => {
    if (monthlyPaymentSelected && eligibleInstalmentRIState) {
      return formatCents(instalmentsCents + MONTHLY_PROCESSING_FEE);
    } else if (monthlyPaymentSelected) {
      return formatCents(monthlyCents + MONTHLY_PROCESSING_FEE);
    } else {
      return formatCents(upfrontCents);
    }
  };

  const PAYMENT_OPTIONS_WITH_LABEL: any = [
    { label: 'Pay monthly', value: PAYMENT_OPTIONS.Monthly, dataCy: 'priceMonthly' },
    { label: 'Pay in full', value: PAYMENT_OPTIONS.Upfront, dataCy: 'priceFull' }
  ];

  return (
    <Wrapper>
      <Banner>
        <Header data-cy="quoteHeader">Your personalized quote</Header>
        {rentersInsuranceRequired && (
          <Label>
            Protect your personal property and <LabelBold>Save 35%</LabelBold>* when you purchase Rhino renters
            insurance
            <SubLabel>*Compared to the base price if your property did not require renters insurance.</SubLabel>
          </Label>
        )}
      </Banner>
      {qualifiesForMonthlyBasisRentersInsurance && (
        <ToggleButton
          options={PAYMENT_OPTIONS_WITH_LABEL}
          selectedOptionValue={paymentOption}
          onClick={(option) => setPaymentOption(option)}
        />
      )}
      <Card data-cy="rentersInsuranceCard">
        <div>
          <RentersInsuranceHeader> Renters Insurance</RentersInsuranceHeader>
          <QuoteSection>
            <Price data-cy="riQuotePrice">{formattedPrice()}</Price>
            <CadenceStyle>{monthlyPaymentSelected ? '/month' : <CadenceStyle>&nbsp;/year </CadenceStyle>}</CadenceStyle>
          </QuoteSection>
          <RentersInsuranceDetail>
            <CheckIcon />
            &nbsp;$15,000 Personal property coverage
          </RentersInsuranceDetail>
          <RentersInsuranceDetail>
            <CheckIcon />
            &nbsp;$100,000 Liability coverage
          </RentersInsuranceDetail>
          <RentersInsuranceDetail>
            <CheckIcon />
            &nbsp;Satisfies most lease requirements
          </RentersInsuranceDetail>
        </div>
        <img src={rentersInsuranceImageSVG} style={{ width: '105px', height: 'auto' }} />
      </Card>
      <ActionButtons>
        <BackButton variant="white" onClick={() => goToStep(Steps.START)} data-cy="back">
          Back
        </BackButton>
        <ReviewDetails onClick={() => goToStep(Steps.REVIEW_POLICY)} data-cy="reviewDetails">
          Review details <ArrowIcon style={{ paddingLeft: 10 }} />
        </ReviewDetails>
      </ActionButtons>
      <FAQ />
    </Wrapper>
  );
};
export default GetQuote;

interface ToggleButtonProps {
  onClick: (value: number) => void;
  options: { label: string; value: number; dataCy: string }[];
  selectedOptionValue: number;
}

const ToggleButton = ({ onClick, options, selectedOptionValue }: ToggleButtonProps) => {
  return (
    <ToggleButtonWrapper>
      {options.map((option) => {
        return (
          <ToggleButtonStyle
            style={option.value === selectedOptionValue ? activeButtonStyles : {}}
            key={option.label}
            onClick={() => onClick(option.value)}
            data-cy={option.dataCy}
          >
            {option.label}
          </ToggleButtonStyle>
        );
      })}
    </ToggleButtonWrapper>
  );
};
