import styled from '@emotion/styled';
import FormGroup from 'components/common/form/form-group';
import React, { Component } from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import Error from './Error';
import './types';

interface IProps {
  autoFocus: boolean;
  header?: boolean;
  cardError?: (stripeEvent: IStripeElementEvent) => void;
  postalCode: string;
  postalCodeError: string;
  onPostalCodeFieldChange: (e: any) => void;
}
interface IState {
  errors: {
    cardNumber?: string;
    cardExpiry?: string;
    cardCvc?: string;
  };
}

const postalCodeFlag = (window as any)?.App?.featureFlags?.postalCode;

const PostalCodeInput = styled.input({
  display: 'block',
  width: '100%',
  maxHeight: '48.8px',
  fontFamily: 'sans-serif',
  fontSize: '1em',
  '&&:focus-visible': {
    outline: 'none'
  }
});

class CardSection extends Component<IProps, IState> {
  public static defaultProps = {
    autoFocus: false,
    header: true
  };

  constructor(props: IProps) {
    super(props);
    this.state = { errors: {} };
  }

  public onFieldChange = (e: IStripeElementEvent, field: string) => {
    const { errors } = this.state;
    errors[field] = e.error ? e.error.message : '';
    this.setState({ errors });
    this.props.cardError!(e);

    this.onCloseNotifications();
  };

  public onCardNumberMount = (cardNumRef: any) => {
    if (this.props.autoFocus) {
      cardNumRef.focus();
    }
  };

  public onCloseNotifications = () => {
    const elements = document.getElementsByClassName('error-message');
    Array.prototype.map.call(elements, function (element) {
      element.style.display = 'none';
    });
  };

  public cardSection_v2 = () => {
    const { postalCode, onPostalCodeFieldChange, postalCodeError } = this.props;

    return (
      <div className="row">
        <div className="col-12 payment-input-wrapper" id="card-number" data-cy="creditCardNumber">
          <FormGroup>
            <div className="form-group">
              <label style={styles.fieldLabel}>Credit card number</label>
              <CardNumberElement
                placeholder=" "
                onChange={(e): void => this.onFieldChange(e, 'cardNumber')}
                elementRef={this.onCardNumberMount}
                className="credit-card-input"
              />
              <Error text={this.state.errors.cardNumber} />
            </div>
          </FormGroup>
        </div>

        <div className="col-12 payment-input-wrapper" id="card-expiry" data-cy="creditCardExpiry">
          <div className="form-group">
            <label style={styles.fieldLabel}>Expiration date</label>
            <CardExpiryElement
              placeholder=" "
              onChange={(e): void => this.onFieldChange(e, 'cardExpiry')}
              className="credit-card-input"
            />
            <Error text={this.state.errors.cardExpiry} className="exp" />
          </div>
        </div>

        <div className="col-12 payment-input-wrapper" id="card-cvc" data-cy="creditCardCvc">
          <div className="form-group">
            <label style={styles.fieldLabel}>CVV / Security Code</label>
            <CardCVCElement
              placeholder=" "
              onChange={(e): void => this.onFieldChange(e, 'cardCvc')}
              className="credit-card-input"
            />
            <Error text={this.state.errors.cardCvc} className="cvc" />
          </div>
        </div>
        {postalCodeFlag && (
          <div className="col-12 payment-input-wrapper" id="zip" data-cy="zip">
            <div className="form-group">
              <label style={styles.fieldLabel}>Billing Zip Code</label>
              <PostalCodeInput
                placeholder=" "
                onChange={(e): void => onPostalCodeFieldChange(e)}
                className="credit-card-input"
                value={postalCode}
              />
              <Error text={postalCodeError} className="postalCode" />
            </div>
          </div>
        )}
      </div>
    );
  };

  public cardSection(): JSX.Element {
    return this.cardSection_v2();
  }

  public render(): JSX.Element {
    return <div>{this.cardSection()}</div>;
  }
}

const styles = {
  header: {
    margin: '0 15px'
  },
  divider: {
    paddingBottom: '25px'
  },
  disclaimer: {
    fontFamily: 'MaisonNeueExtendedLight',
    top: '32px'
  },
  fieldLabel: {
    marginBottom: '0px'
  }
};

export default CardSection;
