import React from 'react';
import Button from '../button';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';

interface IFilterButtonsProps {
  filters: string[];
  filter: string;
  setFilter: (filter: string) => void;
}

const FilterButtons: React.FC<IFilterButtonsProps> = (props: IFilterButtonsProps) => {
  const { filters, filter, setFilter } = props;

  const isMobile = useCheckMobile();

  return (
    <div className="col-md-6 filters">
      {filters.map((text) => {
        return (
          <Button
            key={text}
            text={text}
            styles={isMobile ? styles.mobile.filter : styles.desktop.filter}
            className={text === filter ? 'filter selected' : 'filter'}
            onClick={(event) => setFilter(event.currentTarget.textContent || '')}
          />
        );
      })}
    </div>
  );
};

const styles = {
  desktop: {
    filter: {
      padding: '0 12px 0 0px',
      fontSize: '14px',
      lineHeight: '24px',
      border: 'none',
      outline: 'none',
      background: 'white'
    }
  },
  mobile: {
    filter: {
      padding: '0 24px 0 0px',
      fontSize: '14px',
      lineHeight: '24px',
      border: 'none',
      outline: 'none',
      background: 'white'
    }
  }
};

export default FilterButtons;
