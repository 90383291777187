export interface IRadioOption {
  label: string;
  value: string;
}

export interface INumberOption {
  label: string;
  value: number;
}

export const LivingAloneOptions: IRadioOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
];

export const PetOptions: IRadioOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
];

export const NumberOptions: INumberOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5+', value: 5 }
];
