import styled from '@emotion/styled';
import PayNowCard from './PayNowCard';
import React from 'react';
import StyledModal from './StyledModal';
import { PayNowModalProps } from './interfaces';

const Container = styled.div({
  width: '576px',
  margin: 'auto',
  '@media (max-width: 600px)': {
    width: '100%'
  }
});

const PayNowModal = ({
  showModal,
  submitting,
  errors,
  handleSelectOption,
  handleZipCodeUpdate,
  selectedOption,
  unpaidInvoicesBreakdown,
  handleShowPartialPaymentConfirmationModal,
  handleShowPaymentConfirmationModal,
  handleClosePayNowModal,
  handleSubmit,
  id,
  fullName,
  defaultCard,
  setSubmitting
}: PayNowModalProps) => {
  return (
    <StyledModal id={'pay-now-modal'} isOpen={showModal} onRequestClose={handleClosePayNowModal}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="mt-md-4 mt-5"></div>
          <Container>
            <PayNowCard
              errors={errors}
              handleZipCodeUpdate={handleZipCodeUpdate}
              selectedOption={selectedOption}
              handleSelectOption={handleSelectOption}
              submitting={submitting}
              handleSubmit={handleSubmit}
              fullName={fullName}
              handleShowPartialPaymentConfirmationModal={handleShowPartialPaymentConfirmationModal}
              handleShowPaymentConfirmationModal={handleShowPaymentConfirmationModal}
              unpaidInvoicesBreakdown={unpaidInvoicesBreakdown}
              defaultCard={defaultCard}
              handleClosePayNowModal={handleClosePayNowModal}
              setSubmitting={setSubmitting}
            />
          </Container>
        </div>
      </div>
    </StyledModal>
  );
};

export default PayNowModal;
