import React from 'react';
import { PaymentOptionSelectCard } from './payment_options_select_card';
import { formatCents } from 'utils/money/formatter';
import { ONLINE_PAYMENT_OPTIONS } from '../cash_deposit';

interface IPaymentOptionsSelectorProps {
  paymentOptionsConfig: {
    title: string;
    totalDue: string;
    processingTime: string;
    serviceFee: number;
  }[];
  setSelectedOnlineType: (title: 'ach' | 'card') => void;
}

export const PaymentOptionsSelector: React.FC<IPaymentOptionsSelectorProps> = ({
  paymentOptionsConfig,
  setSelectedOnlineType
}) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<string>('Pay Online - Credit/Debit Card');

  const handleSelectedOption = (title: string) => {
    setSelectedPaymentOption(title);
    setSelectedOnlineType(ONLINE_PAYMENT_OPTIONS[title]);
  };
  return (
    <>
      {paymentOptionsConfig.map((config) => (
        <PaymentOptionSelectCard
          key={config.title}
          activeCard={config.title === selectedPaymentOption}
          onClick={() => handleSelectedOption(config.title)}
          processingTime={config.processingTime}
          serviceFee={formatCents(config.serviceFee)}
          title={config.title}
          totalDue={config.totalDue}
        />
      ))}
    </>
  );
};
