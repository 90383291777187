import { TextInput } from '@sayrhino/rhino-shared-js';
import Error from 'components/common/form/input/Error';
import Input from 'components/v2/input';
import Validation from 'components/v2/validation';
import { debounce } from 'lodash';
import React, { Component, useState, useCallback, useEffect, useRef, ChangeEvent } from 'react';
import { csrfToken } from 'utils/document';
import { post } from 'utils/request';

interface IErrors {
  password: string[];
  password_confirmation: string[];
}

interface IProps {
  resource: string;
  token?: string;
  onChange?: (value: string) => void;
  onConfirmationChange?: (value: string) => void;
  onCheckPassword: (value: boolean) => void;
  errors?: IErrors;
  field_name?: string;
  dataCy?: string;
  dataCy2?: string;
}

interface IState {
  validStrength: boolean;
  isButtonDisabled: boolean;
  errors: string;
  passwordString: string;
  passwordConfirmation: string;
}

const ResetPassword = (props: IProps) => {
  const initialState = {
    validStrength: false,
    isButtonDisabled: true,
    errors: '',
    passwordString: '',
    passwordConfirmation: ''
  };
  const [createPassword, setCreatePassword] = useState(initialState);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const { resource, token, errors } = props;
  const { passwordString, passwordConfirmation, validStrength } = createPassword;
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (passwordString !== '' && !validStrength) {
      validateStrength();
    }
    setPasswordMatch(validatePasswords(passwordString, passwordConfirmation));
    props.onCheckPassword(validatePasswords(passwordString, passwordConfirmation));
    return validateStrength.cancel;
  }, [createPassword]);

  const validateStrength = useCallback(
    debounce(() => {
      _validateStrength(passwordString);
    }, 500),
    [createPassword]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
    setCreatePassword({ ...createPassword, [key]: event.target.value, validStrength: false });
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const handleConfirmationChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
    setCreatePassword({ ...createPassword, [key]: event.target.value });
    if (props.onConfirmationChange) {
      props.onConfirmationChange(event.target.value);
    }
  };

  const _validateStrength = (value: string) => {
    const authenticityToken = csrfToken();
    post('/password/validate', { password: value, authenticity_token: authenticityToken })
      .then((response: { valid: boolean }) => {
        setCreatePassword({ ...createPassword, validStrength: response.valid });
      })
      .catch((response: { errors: string }) => {
        const err = response.errors || '';
        setCreatePassword({ ...createPassword, errors: err });
      });
  };

  const validatePasswords = (password: string, pwdConfirmation: string) => {
    return pwdConfirmation === password;
  };

  const renderError = (field: string, err: string[]) => {
    if (!err) {
      return;
    }
    return err.map((error, i) => {
      return <Error key={i} text={`${field} ${error}`} css="custom-error-text" />;
    });
  };

  return (
    <div>
      <TextInput
        id="newPassword"
        label="New password"
        type="password"
        error={errors?.password.length! > 0}
        onChange={(event) => handleChange(event, 'passwordString')}
        subtext={errors && errors.password.join()}
      />

      {token && (
        <input
          resource={resource}
          name={resource + '[reset_password_token]'}
          id={resource + '_reset_password_token'}
          style={styles.hidden}
          value={token}
        />
      )}
      {!validStrength && (
        <div data-cy="validationSection" id="validation-section">
          <Validation label={'At least 8 characters'} active={validStrength} dataCy={'passwordValidationLength'} />
          <Validation label={'At least 1 number'} active={validStrength} dataCy={'passwordValidationNumber'} />
          <Validation label={'At least 1 symbol'} active={validStrength} dataCy={'passwordValidationSymbol'} />
        </div>
      )}

      <TextInput
        id="confirmNewPassword"
        label="Confirm new password"
        type="password"
        error={errors?.password_confirmation.length! > 0}
        onChange={(event) => handleConfirmationChange(event, 'passwordConfirmation')}
        subtext={errors && errors.password_confirmation.join()}
      />
        {validStrength && (
          <div data-cy="PasswordMatch" id="validation-section">
            <Validation
              label={'Password match'}
              active={passwordMatch}
              dataCy={'passwordValidationLength'}
              iconColorOnly={true}
            />
          </div>
        )}
    </div>
  );
};

const styles = {
  hidden: {
    display: 'none'
  }
};

export default ResetPassword;
