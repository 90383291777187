import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { BackButton } from '../../step_helpers';
import styled from '@emotion/styled';
import { SectionHeading } from '../cash_deposit';
import { useHistory } from 'react-router-dom';
import env from 'utils/env';
import useToast, { TOAST_STATUS } from 'components/v2/action_center/components/RenterWithdrawalFunds/toast/use-toast';

const StripeElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CustomPaymentTerms = styled.p`
  color: #5a6468;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'MaisonNeueLight';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 12px */
  margin-top: 16px;
  margin-bottom: 0px !important;
`;

const ActionsSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
`;

const CustomBackButton = styled(BackButton)`
  flex: 1;
  position: relative !important;
  .back-btn {
    position: relative !important;
  }
`;

const StyledCheckoutForm = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 540px;
  width: 100%;
  @media (max-width: 768px)': {
    margin-bottom: 25px,
  }
`;

const PayButton = styled.button({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: 0.24,
  borderRadius: 42,
  background: '#6a3bf5',
  border: 'none',
  padding: 15,
  color: 'white !important',
  marginLeft: 'auto'
});

interface ICheckoutProps {
  paymentIntentSecret: string | undefined;
  step: string;
  totalAmount: string;
  defaultTab: 'us_bank_account' | 'card';
  submitCashDeposit: () => void;
}

export const CheckoutForm = ({ paymentIntentSecret, step, totalAmount, defaultTab,
    submitCashDeposit }: ICheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useHistory();

  const [message, setMessage] = useState<string | undefined>('');
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentElementReady, setPaymentElementReady] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!paymentIntentSecret) {
      return;
    }
  }, [stripe, paymentIntentSecret, defaultTab]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: env('RHINO_URL') + `/enroll/password?step=password`
      },
      redirect: 'if_required'
    })

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        addToast(String(error.message), 'error', TOAST_STATUS.ERROR);
      } else {
        addToast('An unexpected error occurred.', 'error', TOAST_STATUS.ERROR);
      }
    } else {
      submitCashDeposit()
      navigate.push('/enroll/password');
    }

    setIsLoading(false);
  };

  // The default selected payment method on PaymentElement initial render will be the first in this list.
  const paymentMethodOrder =
    defaultTab === 'us_bank_account'
      ? ['us_bank_account', 'card', 'apple_pay', 'google_pay']
      : ['card', 'apple_pay', 'google_pay', 'us_bank_account'];

  const paymentElementOptions: StripePaymentElementOptions = {
    paymentMethodOrder,
    fields: {
      billingDetails: {
        name: 'never'
      }
    },
    wallets: {
      /*
        The Stripe Payments Element will automatically display Apple Pay and Google Pay
        if device allows because they are enabled in Stripe Dashboard.
        These options will not display in local development because they require HTTPS:
      */
      applePay: 'auto',
      googlePay: 'auto'
    },
    terms: {
      card: 'never',
      usBankAccount: 'never'
    }
  };

  return (
    <StyledCheckoutForm>
      <SectionHeading>Add your payment details</SectionHeading>
      <form id="payment-form" onSubmit={handleSubmit}>
        <StripeElementsContainer>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
            onReady={() => {
              setPaymentElementReady(true);
            }}
          />
          <AddressElement options={{ mode: 'billing', allowedCountries: ['US'] }} />
        </StripeElementsContainer>
        {paymentElementReady ? (
          <CustomPaymentTerms>
            I understand that by submitting my payment, I am authorizing Rhino's third-party payment partner to
            electronically debit/credit my credit card for all payments related to my security deposit. This authority
            will remain in full force and effect until Rhino is notified by me in writing that I wish to revoke this
            authorization. I understand that Rhino requires at least 14 days' prior notice to revoke this authorization.
            <br />
            <br />I also understand that by submitting my payment (1) I have read and agreed to the Rhino Terms of
            Service and Privacy Policy, and (2) I am consenting to the electronic delivery of any information or other
            communications required by law to be provided in writing, unless and until I withdraw my consent by
            providing written notification to Rhino. I understand that I must have a personal computer or electronic
            device with internet connectivity.
          </CustomPaymentTerms>
        ) : null}
        <ActionsSection>
          <CustomBackButton step={step} />
          <PayButton disabled={isLoading || !stripe || !elements} id="submit">
            <span
              id="button-text"
              style={{
                color: 'white'
              }}
            >
              {isLoading ? <div className="spinner" id="spinner"></div> : `Pay ${totalAmount}`}
            </span>
          </PayButton>
        </ActionsSection>

        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </StyledCheckoutForm>
  );
};
