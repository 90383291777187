import moment from 'moment';
import React, { useState, useRef } from 'react';

import Input from 'components/v2/input';
import { User } from '../redux/state';
import { allPresent, renderError, pluck, useSubsetState, useLoader, mergeInvitationChange } from '../step_helpers';
import { MARKETING_ENUM_VALUES, NOT_SET, OPTED_OUT, OPTED_IN } from '../constants/marketing';
import { IChildProps } from './step_wrapper';
import { smsMarketingBool, sendEventToIterable } from 'components/v2/shared/tracking';
import { usePersistUserAndPolicyApplication } from '../hooks/usePersistUserAndPolicyApplication';

const maxDate = moment().subtract(18, 'years').toISOString();

function PersonalInformation(props: IChildProps) {
  const userAndPolicyApplicationPersist = usePersistUserAndPolicyApplication();
  const invitation = pluck(props.invitation.result || {}, ['first_name', 'last_name', 'email', 'phone']);
  const marketingInitialValue = () => {
    const marketingValue = props.user.result?.sms_marketing_opt_in;
    if (marketingValue === undefined || marketingValue === NOT_SET) {
      return MARKETING_ENUM_VALUES[OPTED_IN];
    } else {
      return MARKETING_ENUM_VALUES[marketingValue];
    }
  };
  const smsOptIn = marketingInitialValue();
  const [user, setUser] = useSubsetState(
    props.user.result,
    ['id', 'first_name', 'last_name', 'email', 'birthdate', 'phone', 'corporation', 'sms_marketing_opt_in'],
    Object.assign(
      {
        corporation: props.user.result?.corporation || false
      },
      props.user.result?.id ? {} : invitation
    ),
    { sms_marketing_opt_in: smsOptIn }
  );
  const [submitClicked, setSubmitClicked] = useState(false);
  const [smsAccepted, setSmsAccepted] = useState(smsMarketingBool(smsOptIn));
  // Need to also apply the phone and corporation to policy application
  // since we halfway migrated from user to policy app
  const mergedPolicyApp = mergeInvitationChange(props.policyApplication.result, props.invitation.result);
  const [policyApplication, setPolicyApplication] = useSubsetState(
    mergedPolicyApp,
    ['id', 'phone', 'partial_quote_id', 'property_id', 'corporation', 'google_place_id', 'unit_id'],
    { corporation: false, phone: props.invitation.result?.phone || props.user.result?.phone }
  );

  const updateUser = (update: User) => {
    setSubmitClicked(false);
    setUser({ ...user, ...update });
  };

  const updateUserAndPolicyApplication = (update: { phone?: string; corporation?: boolean }) => {
    setSubmitClicked(false);
    setUser({ ...user, ...update });
    setPolicyApplication({ ...policyApplication, ...update });
  };

  const updateSmsMarketing = (e) => {
    const isChecked = e.target.checked;
    setSmsAccepted(isChecked);

    const value = isChecked ? MARKETING_ENUM_VALUES[OPTED_IN] : MARKETING_ENUM_VALUES[OPTED_OUT];
    setUser({ ...user, sms_marketing_opt_in: value });
  };

  const displayLoading = useLoader();
  const isFirstRun = useRef(true);

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitClicked(true);
    // if there is a user and the user does not have an existing policyApplication
    // currently the case for users that enter the sign-up flow from invitation or home page
    if (props.user.result?.id && props.policyApplication.result?.id === undefined) {
      if (props.invitation.result?.id) {
        sendEventToIterable(
          'basicsInformationCompleted',
          { ...props.user.result, ...user },
          { ...policyApplication, source: 'invitation' },
          '/iterable_events/send_basics_information_completed'
        );

        const updatedUser = { invitation_signup: true, ...user };
        const newPolicyApplication = { source: 'invitation', ...policyApplication };
        userAndPolicyApplicationPersist(
          updatedUser,
          newPolicyApplication,
          props.user.result?.id,
          undefined,
          props.step
        );
      }
      //
      else {
        userAndPolicyApplicationPersist(user, policyApplication);
      }
    }
    // If there is a user and the user has an exisitng policyApplication
    // currently the case for user that enter the sign-up flow from the homepage
    else if (user?.id && policyApplication?.id) {
      sendEventToIterable(
        'basicsInformationCompleted',
        { ...props.user.result, ...user },
        { ...props.policyApplication.result, ...policyApplication },
        '/iterable_events/send_basics_information_completed'
      );

      userAndPolicyApplicationPersist(
        user,
        policyApplication,
        props.user.result?.id,
        props.policyApplication.result?.id,
        props.step
      );
    }
    // if there is no user and no exisitng policyApplication
    // case is invitation
    else {
      const updatedUser = { invitation_signup: true, ...user };
      const updatedPolicyApplication = { source: 'invitation', ...policyApplication };
      userAndPolicyApplicationPersist(updatedUser, updatedPolicyApplication, undefined, undefined, props.step);
    }

    sendEventToIterable(
      'basicsInformationCompleted',
      { ...props.user.result, ...user },
      { ...policyApplication, source: 'invitation' },
      '/iterable_events/send_basics_information_completed'
    );
  };

  const disabled = submitClicked || !allPresent([user.first_name, user.last_name, user.birthdate, user.phone]);

  return (
    <div>
      {displayLoading && (
        <div style={styles.loaderSection}>
          <h1 className="black purple" style={styles.loaderTitle}>
            Say hello to Rhino!
          </h1>
        </div>
      )}
      <div>
        <h2 data-cy="basicHeader" className="bold header">
          Let's start with the basics
        </h2>
        <form className="sign-up__form" id="personal-information-form" onSubmit={onSubmit}>
          <div className="form-holder">
            <div className="form" style={styles.formHolder}>
              <div className="input-holder" style={styles.inputContainer}>
                <Input
                  dataCy="firstName"
                  field="first_name"
                  value={user?.first_name}
                  placeholder="First name"
                  onChangeString={(first_name) => updateUser({ first_name })}
                  resource="user"
                />
                {renderError('first_name', props.user)}
              </div>
              <div className="input-holder" style={styles.inputContainer}>
                <Input
                  dataCy="lastName"
                  field="last_name"
                  resource="user"
                  value={user?.last_name}
                  placeholder="Last name"
                  onChangeString={(last_name) => updateUser({ last_name })}
                />
                {renderError('last_name', props.user)}
              </div>
              {user?.id === undefined && (
                <div className="input-holder" style={styles.inputContainer}>
                  <Input
                    dataCy="email"
                    field="email"
                    resource="user"
                    value={user?.email}
                    placeholder="Email"
                    onChangeString={(email) => updateUser({ email })}
                  />
                  {renderError('email', props.user)}
                </div>
              )}
              <div className="input-holder" style={styles.inputContainer}>
                <Input
                  dataCy="birthdate"
                  field="birthdate"
                  resource="user"
                  value={user?.birthdate || ''}
                  selected={maxDate}
                  maxDate={maxDate}
                  datePicker={{ placeholderText: 'Date of birth' }}
                  type="date"
                  onChangeDate={(birthdate) => updateUser({ birthdate })}
                />
                {renderError('birthdate', props.user)}
              </div>
              <div style={styles.inputContainer} className="input-holder">
                <Input
                  dataCy="phone"
                  value={user?.phone || ''}
                  type="phone"
                  field="phone"
                  resource="user"
                  onChangeMasked={(phone) => updateUserAndPolicyApplication({ phone })}
                />
                {renderError('phone', props.user)}
              </div>
              <div className="v2-checkbox position-relative sms-legal" style={styles.checkboxContainer}>
                <input
                  data-cy="termsCheckbox"
                  type="checkbox"
                  id="sms_legal_language"
                  onChange={(e) => updateSmsMarketing(e)}
                  checked={smsAccepted}
                  readOnly={true}
                />
                <label htmlFor="sms_legal_language" className="v2-checkbox-label">
                  You agree to receive automated promotional text and email messages from Rhino. This agreement is not a
                  condition of purchase. MSG & Data Rates may apply.
                </label>
              </div>
              <p style={styles.attestationText}>
                By clicking on Continue, you agree to our&nbsp;
                <a href="https://www.sayrhino.com/privacy_policy" style={styles.href}>
                  privacy policy
                </a>
                &nbsp; and our&nbsp;
                <a href="https://www.sayrhino.com/terms_of_service" style={styles.href}>
                  terms of service
                </a>
              </p>
            </div>
          </div>
          <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <input type="submit" className="btn-default" data-cy="continue" value="Continue" />
          </div>
        </form>
      </div>
    </div>
  );
}

const styles = {
  checkbox: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '260px'
  },
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  },
  loaderSection: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  loaderTitle: {
    textAlign: 'center' as 'center',
    width: '50%',
    margin: 'auto',
    marginTop: '20%'
  },
  checkboxContainer: {
    marginTop: '25px'
  },
  href: {
    borderBottom: '1px solid black'
  },
  attestationText: {
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px'
  }
};

export default PersonalInformation;
