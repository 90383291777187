import React from 'react';

const PaymentStatus: React.FC<{status: string}> = (props) => {
  const color = colors[props.status.toLowerCase()];
  return (
    <div className="float-right" style={ {display: 'flex'} }>
      <div style={{...statusStyles.text, color}} >
        {props.status}
      </div>
      <div style={ {...statusStyles.icon, ...{background: color}} }>
      </div>
    </div>
  );
};

const colors: { [status: string]: string } = {
  paid: '#189D7A',
  failed: '#FF615E',
  pending: '#777777',
  refunded: '#189D7A'
};

const statusStyles = {
  icon: {
    width: '12px',
    height: '12px',
    borderRadius: '14px',
    margin: '5px 0px 5px 8px',
    flexBasis: 'auto'
  },
  text: {
    flexBasis: 'auto',
    fontFamily: 'MaisonNeueMedium'
  }
};

export default PaymentStatus;
