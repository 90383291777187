import React from 'react';

import Loader from 'components/v2/loader';

interface IState {
  isLoading: boolean;
  inviteId: string | null;
  errors: string[];
}

interface IProps {
  unprocessedId: string;
}

class CompleteQuote extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      inviteId: null,
      errors: []
    };
  }

  public componentDidMount = () => {
    this.processInvite();
  }

  public processInvite = (): void => {
    const { unprocessedId } = this.props;
    const requestUrl = `/complete_quote/udr/${encodeURIComponent(unprocessedId)}`;

    if (unprocessedId) {
      fetch(requestUrl, { credentials: 'same-origin', headers: { Accept: 'application/json'} })
        .then((response): any => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error('Unable to process invite.');
          }
        }).then((parsed_response) => {
          this.setState({
            isLoading: false,
            inviteId: parsed_response.invite_id
          });
        }).catch((error) => {
          this.setState({
            isLoading: false,
            errors: [error.message]
          });
        });
    }
  }

  public render() {
    return (
      <div className="container">
        <div className="row no-gutters" style={styles.container}>
          <div style={styles.content} className="text-center">
            { this.renderContent() }
          </div>
        </div>
      </div>
    );
  }

  private renderContent(): JSX.Element {
    const inviteId = this.state;

    if (this.state.isLoading) {
      return this.renderLoading();
    } else if ( inviteId != null && this.state.errors.length !== 0) {
      return this.renderError();
    } else {
      // Redirect to our invite page
      window.location.pathname = `/complete_quote/${this.state.inviteId}`;
      return this.renderLoading();
    }
  }

  private renderLoading(): JSX.Element {
    return (
      <>
        <h1>
          Hang tight,<br /> we're loading your invitation
        </h1>
        <Loader />
      </>
    );
  }

  private renderError(): JSX.Element {
    return (
      <>
        <h1 style={styles.header}>
          Something went wrong...
        </h1>
        <p className="p1">
          Try refreshing your page or clicking the invitation link
          again. If the problem continues, please contact your property manager
        </p>
      </>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    width: '575px',
    marginTop: '300px',
    marginBottom: '200px'
  },
  header: {
    paddingBottom: '32px'
  }
};

export default CompleteQuote;
