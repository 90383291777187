/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import CancellationRequestModal from '../cancellationRequestModal';
import Payments from './payments/index';
import PolicyCoverage from './policy_coverage';

import { IDisplayQuote } from '../types';
import Banner from '../../shared/banner';
import { IChangeRequest, IPolicy, ICallbacks } from '../policyProps';

import Flash from 'components/v2/flash';
import RenewalWarningModal from '../nonRenewalWarningModal';
import CancellationRequestStatusBanner from './cancellation_requests/statusBanner';
import CancellationRequestButton from './cancellation_requests/button';

import {
  updatePolicy,
  acceptChangeRequest,
  updateChangeRequest,
  updateCanChangePaymentMethod,
  updateRoommates,
  cachePolicy
} from '../actions';

import { FONTS, RhinoModal, InlineNotification, PALETTE } from '@sayrhino/rhino-shared-js';
import Modal from 'react-modal';
import CustomModal from '../modal';
import RenewalModal from '../renewalModal';

import CannotRenewBanner from '../CannotRenewBanner';
import styled from '@emotion/styled';

// @ts-ignore
import LeftChevron from '../LeftChevron.svg';
import PolicyDocuments from './policy_coverage/policyDocuments';

import Roommates from '../roommates/AddRoomate';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import onToggleFullscreenModal from 'components/v2/utils/onToggleFullscreenModal';

interface IUser {
  id: number;
  tenant_id: number;
  firstName: string;
  email: string;
}

interface IProps {
  insurancePolicy: IPolicy;
  changeRequest: IChangeRequest;
  user: IUser;
  mapEnabled: boolean;
  csrf_token: string;
  acceptChangeRequest: () => void;
  updatePolicy: (_policyParams) => void;
  cachePolicy: (_policyParams) => void;
  updateCanChangePaymentMethod: (_value) => void;
  updateChangeRequest: (_changeRequestParams) => void;
  updateRoommates: (_changeRequestParams) => void;
  dataCy?: string;
}

const Spacer = styled.div({
  marginBottom: '44px'
});

const defaultCallbacks = { onSuccess: () => null, onFailure: () => null };

Modal.setAppElement('#cancellationModal');

const CancellationWrapper = styled.div([
  FONTS.p2Extended,
  {
    marginTop: 40,
    '@media (min-width: 991.98px)': {
      marginTop: 40
    }
  }
]);

const Button = styled.button([
  FONTS.p2Extended,
  {
    '&&': {
      border: `1px solid ${PALETTE.neutralDark}`,
      borderRadius: 24,
      fontWeight: 500,
      backgroundColor: PALETTE.white,
      marginRight: 12,
      padding: '8px 20px'
    }
  }
]);

const CoverageTitle = styled.h4([
  FONTS.h4,
  {
    fontWeight: 400,
    marginBottom: 7,
    color: PALETTE.neutralDark
  }
]);

const PageTitle = styled.h3([
  FONTS.h3,
  {
    fontWeight: 600,
    marginBottom: 4,
    color: PALETTE.neutralDark
  }
]);

const Address = styled.p([
  FONTS.p2Extended,
  {
    fontWeight: 400,
    marginBottom: 8,
    color: PALETTE.neutral88
  }
]);

const PolicyNumber = styled.p([
  FONTS.p2Medium,
  {
    fontWeight: 400,
    marginBottom: 32,
    color: PALETTE.neutral65
  }
]);

const PropertyDetails = styled.div({
  '&&': {
    '@media (min-width: 767px)': {
      marginTop: '48px'
    },
    '@media (max-width: 767px)': {
      marginTop: '-5px'
    }
  }
});

const MobileNavigationWrapper = styled.div({
  '&&': {
    border: `1px solid ${PALETTE.neutral12}`,
    backgroundColor: PALETTE.neutral4,
    paddingTop: '19px',
    paddingBottom: '10px',
    '@media (min-width: 767px)': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      margin: '0px -15px 27px'
    }
  }
});

const MobileInnerWrapper = styled.div({
  paddingLeft: 26
});
const MobileHeader = styled.h4([FONTS.h4, { marginBottom: 0, color: PALETTE.neutralDark }]);
const MobileDetail = styled.p([FONTS.p2, { marginBottom: 0, color: PALETTE.neutral65, lineHeight: 2 }]);
const AllPoliciesLink = styled.p([
  FONTS.h5,
  {
    color: PALETTE.neutral65,
    marginBottom: 16,
    '@media (max-width: 767px)': {
      display: 'none'
    }
  }
]);
const ChevronIcon = styled.img({
  height: '14px'
});

export const InsurancePolicyDashboard = (props: IProps) => {
  const { insurancePolicy, changeRequest, user, mapEnabled, csrf_token } = props;
  const {
    policy_id,
    policy_app_id,
    change_request_id,
    id,
    lease_id,
    pending_change_request,
    cancellation_request,
    eligible_for_renewal,
    status,
    lease_end_date,
    allow_delinquent_manual_renewal,
    delinquent_renter_info,
    unreimbursed_claims_info,
    coverage_end_date
  } = insurancePolicy;

  const [errors, setErrors] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [hasNonRenewalReason, setHasNonRenewalReason] = useState<boolean>(false);
  const [isNonRenewalModalOpen, setNonRenewalModalOpen] = useState<boolean>(false);
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState<boolean>(false);
  const [displayQuote, setDisplayQuote] = useState<IDisplayQuote>();
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const isMobile = useCheckMobile();

  const autoRenewalDate = moment(lease_end_date).subtract(15, 'days').format('MMMM D, YYYY');
  const policyEndDate = moment(props.insurancePolicy.coverage_end_date).format('MMMM D, YYYY');

  const confirmedRentersPolicy = (window as any).App?.policy_application?.renters_insurance_confirmed;
  const useNewCancellationsExperience = (window as any).App?.featureFlags.useNewCancellationsExperience;

  const isRenterDelinquent =
    delinquent_renter_info && Object.values(delinquent_renter_info).some((value) => value === true);

  const cannotRenewStatuses =
    delinquent_renter_info &&
    Object.keys(delinquent_renter_info).filter((delinquentStatus) => delinquent_renter_info[delinquentStatus]);

  const canRequestCancellation =
    cancellation_request?.status !== 'declined' &&
    cancellation_request?.status !== 'accepted' &&
    status !== 'Canceled' &&
    status !== 'Expired';

  const getChangeRequest = async (newInsurancePolicy) => {
    const requestUrl = `/insurance_policies/${newInsurancePolicy.policy_id}/change_requests/${newInsurancePolicy.change_request_id}`;

    if (newInsurancePolicy.policy_id && newInsurancePolicy.change_request_id) {
      try {
        const { data } = await axios.get(requestUrl, {
          withCredentials: true,
          headers: { Accept: 'application/json' }
        });
        const { change_request } = data;
        props.updateChangeRequest(change_request);
      } catch (error) {
        if (error instanceof Error) {
          setErrors([error.message]);
        }
      }
    }
  };

  const getPolicyData = async () => {
    const userId = user.id;
    const policyId = id;
    const requestUrl = `/users/${userId}/insurance_policies/${policyId}.json`;

    if (userId && policyId) {
      try {
        const { data } = await axios.get(requestUrl, {
          withCredentials: true,
          headers: { Accept: 'application/json' }
        });
        const { insurance_policy } = data;
        setHasNonRenewalReason(insurance_policy.non_renewal_reason !== null);
        if (insurance_policy.pending_change_request) {
          getChangeRequest(insurance_policy);
        }
        props.updatePolicy(insurance_policy);
        props.cachePolicy(insurance_policy);
        props.updateCanChangePaymentMethod(insurance_policy.update_payment_method);
        return true;
      } catch (error) {
        if (error instanceof Error) {
          setErrors([error.message]);
        }
        return false;
      }
    }
    return false;
  };

  const getRoommatesData = async () => {
    const policyId = id;
    const requestUrl = `/insurance_policies/${policyId}/roommates.json`;

    if (!policyId) return false;
    try {
      const { data } = await axios.get(requestUrl);
      const { roommates } = data;
      props.updateRoommates(roommates);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setErrors([error.message]);
      }
      return false;
    }
  };

  const openNonRenewalModalAfterRedirectedFromCancellationRequest = () => {
    setNonRenewalModalOpen(true)
  }

  const redirectedFromCancellationRequest = () => {
    return getParameterByName('open_non_renewal_modal') === 'true'
  }

  const getParameterByName = (name) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    return urlParams.get(name)
  }

  const onOpenModal = () => {
    setModalOpen(true);
    onToggleFullscreenModal(true);
  };

  const closeModal = async () => {
    document.body.style.overflow = 'auto';
    await getPolicyData();
    setModalOpen(false);
  };

  const onOpenNonRenewalModal = async () => {
    setNonRenewalModalOpen(true);
  };

  const closeNonRenewalModal = async () => {
    setNonRenewalModalOpen(false);
  };

  const onNonRenewalClose = async (reason: string) => {
    if (lease_id) {
      const payload = {
        lease: { moving_out_after_end: reason === '0' },
        authenticity_token: csrf_token
      };

      const url = `/leases/${lease_id}`;
      try {
        const { data } = await axios.put(url, payload);
        await axios.put(
          `/insurance_policies/${policy_id}/update_non_renewal_reasons.json`,
          {
            renter_policy: { non_renewal_reason: Number(reason) }
          },
          {
            headers: { Accept: 'application/json', authenticity_token: csrf_token }
          }
        );
        setHasNonRenewalReason(true);
      } catch (error) {
        if (error instanceof Error) {
          setErrors([error.message]);
        }
      }
    }
    setNonRenewalModalOpen(false);
  };

  const onAcceptChangeRequest = (hideDrawer = true, callbacks: ICallbacks = defaultCallbacks): Promise<any> => {
    const options = {
      authenticity_token: csrf_token,
      credentials: 'same-origin',
      headers: { Accept: 'application/json' }
    };

    const url = `/insurance_policies/${policy_id}/change_requests/${change_request_id}`;

    hideDrawer && props.updateCanChangePaymentMethod(false);

    return axios
      .put(url, options)
      .then(() => {
        getPolicyData();
        props.acceptChangeRequest();
        Flash.events.emit('flash:new', { text: 'The changes have been successfully confirmed' });
        callbacks.onSuccess();
      })
      .catch((error) => {
        Flash.events.emit('flash:new', { text: error.response.data.errors.join(' '), type: 'danger' });
        callbacks.onFailure();
      });
  };

  const goToRenewal = (): Promise<any> => {
    closeNonRenewalModal();
    openRenewalModal();

    return Promise.resolve('yay');
  };

  const openRenewalModal = async () => {
    try {
      setLoadingQuote(true);
      const { data } = await axios.post(`/insurance_policies/${policy_id}/display_quote.json`);
      setLoadingQuote(false);
      setDisplayQuote(data);
    } catch (error) {
      setLoadingQuote(false);
      Flash.events.emit('flash:new', { text: 'Internal server error.', type: 'danger' });
    }

    setIsRenewalModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeRenewalModal = () => {
    setIsRenewalModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleCancellationButtonClicked = () => {
    if (useNewCancellationsExperience) {
      window.location.href = `/v2/insurance_policies/${policy_id}/cancellation_requests/new`;
    } else {
      onOpenModal();
    }
  }

  const bannerText = `Review and confirm the changes to your policy.  If anything looks incorrect, visit support.sayrhino.com as soon as possible and let us know.`;
  const bannerSubText =
    confirmedRentersPolicy &&
    'If you confirm these changes, you must purchase a new renters insurance policy for the new address.';

  const renderBanner = (): JSX.Element => {
    if (pending_change_request) {
      return (
        <Banner
          header="Confirm policy changes"
          showRenewButton={true}
          text={bannerText}
          subtext={bannerSubText}
          buttonText="Confirm changes"
          onAccept={onAcceptChangeRequest}
          dataCy="confirm"
        />
      );
    }
    if (!isNonRenewalModalOpen && !isModalOpen && eligible_for_renewal) {
      return (
        <Banner
          text="I don't want to renew"
          showRenewButton={true}
          buttonText="Renew coverage"
          hasNonRenewalReason={hasNonRenewalReason}
          autoRenewPolicy={autoRenewalDate}
          onAccept={goToRenewal}
          loadingQuote={loadingQuote}
          onOpen={openRenewalModal}
          interactive={true}
          onClose={onOpenNonRenewalModal}
          policyEndDate={policyEndDate}
          cannotEditTerms={true}
        />
      );
    }
    return <React.Fragment></React.Fragment>;
  };

  useEffect(() => {
    const promise1 = getPolicyData();
    const promise2 = getRoommatesData();
    const promises = [promise1, promise2];

    Promise.allSettled(promises).then((results: any) => {
      if (results.some(r => !r.value)) return;
      if (redirectedFromCancellationRequest()) {
        openNonRenewalModalAfterRedirectedFromCancellationRequest();
      }
    });
  }, []);

  return (
    <div className="insurance-policy-container row no-gutters">
      <PropertyDetails className="col-12">
        <React.Fragment>
          <div className="row">
            <a href="/policies">
              <AllPoliciesLink>
                <ChevronIcon src={LeftChevron} style={{ paddingRight: 12 }} />
                All Policies
              </AllPoliciesLink>
            </a>
            {useNewCancellationsExperience && cancellation_request && (
              <CancellationRequestStatusBanner cancellationRequest={cancellation_request} />)}
          </div>
        </React.Fragment>
      </PropertyDetails>
      <PropertyDetails className="col-lg-6" style={{marginTop: '30px'}}>
        <React.Fragment>
          <a href="/policies">
            <MobileNavigationWrapper>
              <MobileInnerWrapper>
                <ChevronIcon src={LeftChevron} style={{ paddingRight: 26, margin: '0 auto', marginBottom: 35 }} />
                <div className="another-wrapper" style={{ display: 'inline-block' }}>
                  <MobileHeader>Security Deposit Insurance</MobileHeader>
                  <MobileDetail>
                    {insurancePolicy.address_line_one} {insurancePolicy.city}, {insurancePolicy.state}{' '}
                    {insurancePolicy.zipcode}
                  </MobileDetail>
                  <MobileDetail>{insurancePolicy.policy_number}</MobileDetail>
                </div>
              </MobileInnerWrapper>
            </MobileNavigationWrapper>
          </a>
        </React.Fragment>
        <div className="policy">
          {isRenterDelinquent &&
            cannotRenewStatuses.map((cannotRenewStatus, index) => {
              return (
                <div key={status}>
                  <CannotRenewBanner
                    allow_delinquent_manual_renewal={allow_delinquent_manual_renewal}
                    status={cannotRenewStatus}
                    policy_number={insurancePolicy.policy_number}
                    first_name={user.firstName}
                    email={user.email}
                    unreimbursed_claims_info={unreimbursed_claims_info}
                  />
                  {index === cannotRenewStatuses.length - 1 && <Spacer />}
                </div>
              );
            })}
        </div>
        {!isMobile && (
          <React.Fragment>
            <PageTitle>Security Deposit Insurance</PageTitle>
            <Address>{insurancePolicy.address_line_one}</Address>
            <PolicyNumber>#{insurancePolicy.policy_number}</PolicyNumber>
          </React.Fragment>
        )}
        <CoverageTitle>Coverage Details</CoverageTitle>
        <PolicyCoverage coverageInfo={insurancePolicy} changeRequest={changeRequest} />
        {/* This is wrapping the new cancellations flow. */}
        {useNewCancellationsExperience && !['Expired', 'Canceled'].includes(status) && (
          <CancellationWrapper className="row no-gutters">
            <Roommates />
            <CancellationRequestButton currentRequest={cancellation_request}
              insurancePolicyId={policy_id}
              coverageEndDate={coverage_end_date} />
          </CancellationWrapper>
        )}
        {/*
          This is wrapping the OLD cancellations flow.
          The container below is DEPRECATED and will be removed soon.
        */}
        <div className="row">
          {!useNewCancellationsExperience && canRequestCancellation && (
            <CancellationWrapper>
              <div>
                <Roommates />
                {cancellation_request !== null && cancellation_request.status === 'pending' ? (
                  <InlineNotification variant={'highPriority'} style={{ width: '90%', margin: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: 'unset' }}>
                      <span style={{ fontSize: '10px', color: 'unset' }}>
                        Cancellation requested on {format(parseISO(cancellation_request.requested_at), 'MMM d, yyyy')}
                      </span>
                    </div>
                  </InlineNotification>
                ) : (
                  <Button
                    id="cancellationButton"
                    onClick={handleCancellationButtonClicked}
                  >
                    Request Policy Cancellation
                  </Button>
                )}
              </div>
            </CancellationWrapper>
          )}
        </div>
        <div className="banners">{renderBanner()}</div>
      </PropertyDetails>
      <div className="col-lg-6">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="policy-details col-lg-9" style={{ marginTop: isMobile ? '0px' : '175px' }}>
            <div className="payments">
              <Payments policyId={id} policy={insurancePolicy} acceptChangeRequest={onAcceptChangeRequest} />
            </div>
            <div>
              {insurancePolicy.unit_name ? (
                <PolicyDocuments
                  url={insurancePolicy.documents_url}
                  documentEffectiveDate={insurancePolicy.document_effective_date}
                  id={insurancePolicy.policy_id}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <RhinoModal
          isOpen={isModalOpen}
          onRequestClose={() => closeModal()}
          style={{ background: PALETTE.brand4, inset: 0, padding: 0, position: 'fixed' }}
          contentLabel="Cancellation Modal"
        >
          <CancellationRequestModal
            onCloseModal={() => closeModal()}
            userId={user.tenant_id}
            policyId={policy_id}
            policyAppId={policy_app_id}
            insuranceApplicationId={insurancePolicy.id}
            leaseStartDate={insurancePolicy.lease_start_date}
            leaseEndDate={insurancePolicy.lease_end_date}
            cancellationLookbackDays={insurancePolicy.cancellation_lookback_days}
            coverageStartDate={insurancePolicy.coverage_start_date}
          />
        </RhinoModal>
        <CustomModal isOpen={isNonRenewalModalOpen} variant="white" closeModal={() => closeNonRenewalModal()}>
          <RenewalWarningModal
            onNonRenewalCloseModal={() => closeNonRenewalModal()}
            onNonRenewalClose={onNonRenewalClose}
            onRenewalModal={goToRenewal}
            userId={user.tenant_id}
            policyAppId={policy_app_id}
            insuranceApplicationId={insurancePolicy.id}
          />
        </CustomModal>

        <RenewalModal
          csrfToken={csrf_token}
          isOpen={isRenewalModalOpen}
          closeModal={() => closeRenewalModal()}
          insurancePolicy={insurancePolicy}
          displayQuote={displayQuote}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ insurancePolicy, changeRequest, user, mapEnabled, csrf_token }) => ({
  insurancePolicy,
  changeRequest,
  user,
  mapEnabled,
  csrf_token
});

const mapDispatchToProps = (dispatch) => {
  return {
    acceptChangeRequest: () => dispatch(acceptChangeRequest()),
    updatePolicy: (policyParams) => dispatch(updatePolicy(policyParams)),
    cachePolicy: (policyParams) => dispatch(cachePolicy(policyParams)),
    updateCanChangePaymentMethod: (value) => dispatch(updateCanChangePaymentMethod(value)),
    updateChangeRequest: (changeRequestParams) => dispatch(updateChangeRequest(changeRequestParams)),
    updateRoommates: (roommates) => dispatch(updateRoommates(roommates))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePolicyDashboard);
