import React from 'react';
import {
  Wrapper,
  StyledActionButton,
  StyledActionSection,
  StyledCardParagraph,
  StyledHeader,
  StyledStatus
} from './styles';

export const PendingCheckCard = () => {
  const handleDownloadContinueClick = () => {
    const pdfURL =
      'https://deposify-public-cdn.s3.us-east-2.amazonaws.com/site/forms/Cash+Deposit+-+Check+Mail-in+Form+%26+Instructions.pdf';
    window.open(pdfURL, '_blank');
  };
  return (
    <Wrapper>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledHeader>Pay by Mail</StyledHeader>
        <StyledStatus status="pending_check" >Attention Needed</StyledStatus>
      </div>
      <StyledCardParagraph>
        You elected to pay your deposit by mailing a check. Your deposit is not active until we have received your
        check. Once you have downloaded your form, please return and complete the rest of your application.
      </StyledCardParagraph>

      <StyledActionSection>
        <StyledActionButton
          id={'policy-application-finalize-button'}
          disabled={false}
          variant={'noIcon'}
          onClick={handleDownloadContinueClick}
        >
          Download Form
        </StyledActionButton>
      </StyledActionSection>
    </Wrapper>
  );
};
