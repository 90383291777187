import React from 'react';

interface IButtonProps {
  text: string;
  styles?: {};
  className?: string;
  onClick: (event: React.MouseEvent) => void;
  id?: string;
  disabled?: boolean;
  dataCy?: string;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {text, styles, className, onClick, id, disabled, dataCy} = props;

  return (
    <button
      type="button"
      onClick={onClick}
      style={styles}
      className={className}
      id={id}
      disabled={disabled}
      data-cy={dataCy}
    >
      {text}
    </button>
  );
};

export default Button;
