import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, ROUNDNESS } from '@sayrhino/rhino-shared-js';
// @ts-ignore - allows import for Caret
import Caret from '../assets/Caret.svg';

interface Props {
  text: string;
  link: string;
  svg: string;
  eventName: string;
  hideIfCashOnly: boolean;
}

const FAQLink = styled.div([
  FONTS.p2Medium,
  ROUNDNESS.m,
  {
    color: PALETTE.neutralDark,
    backgroundColor: PALETTE.neutralLight,
    border: `1px solid ${PALETTE.neutral12}`,
    padding: '16px',
    marginBottom: '8px',
    display: 'flex',
    height: '56px'
  }
]);

const StyledIcon = styled.img({
  height: '16px',
  marginRight: '7px'
});

const CaretWrapper = styled.div({
  width: '1%'
});

const TextWrapper = styled.div({
  width: '99%'
});

export const FAQCard = (props: Props) => {
  const { link, text, svg, eventName, hideIfCashOnly } = props;

  return (
    <div>
      {!hideIfCashOnly &&
        <a href={link} target="_blank">
        <FAQLink>
          <TextWrapper>
            <StyledIcon src={svg}></StyledIcon>
            {text}
          </TextWrapper>
          <CaretWrapper>
            <StyledIcon style={{ height: '14px' }} src={Caret}></StyledIcon>
          </CaretWrapper>
        </FAQLink>
      </a>
      }
    </div>
  );
};
