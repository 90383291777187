// @flow
import React from 'react';

type IProps = {
  label: string,
  id: string,
  value: any,
  selected: any,
  name?: string,
  onChange: (value: any) => any
};

const RadioOption = (props: IProps): JSX.Element => {
  const { value, selected, id, label, name } = props;

  return (
    <div
      className="custom-radio flow-form--radio-container">
      <input type="radio"
        checked={value === selected}
        name={name}
        id={id}
        value={value}
        data-cy={label.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')}
        readOnly={true}
        onChange={() => props.onChange(value)} />
      <label htmlFor={id} className="flow-form--radio-input"> {label} </label>
    </div>
  )
}

export default RadioOption;
