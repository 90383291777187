import React, { PureComponent } from 'react';
import { csrfToken } from 'utils/document';

type IProps = {
  children: any;
  onSubmit?: (e?: any) => any;
  id: string;
  name?: string;
  action?: string;
  className?: string;
  method?: string;
  acceptCharset?: string;
  'data-remote'?: string;
  csrf_token?: string;
  holderClass?: string;
  setRef: (a: any) => any;
};

class Form extends PureComponent<IProps & any> {
  static defaultProps = {
    method: 'post',
    acceptCharset: 'UTF-8',
    className: 'form-validation form',
    holderClass: 'form-holder',
    setRef: () => null
  };

  formProps(): any {
    const { setRef, children, csrf_token, holderClass, ...props } = this.props;

    return props;
  }

  isRemote(): boolean {
    return !(this.props['data-remote'] === 'false');
  }

  methodInput() {
    if (this.isRemote() || !this.props.id || !this.props.id.match(/^edit/) || this.props.id.match(/invitation$/))
      return;

    // for consistency with Rails if form is not ajax
    return <input type="hidden" name="_method" value="patch" />;
  }

  csrfTokenInput() {
    if (this.isRemote()) return; // is not needed in ajax requests
    const token = this.props.csrf_token || csrfToken();
    return <input type="hidden" name="authenticity_token" value={token} readOnly={true} />;
  }

  render() {
    return (
      <form {...this.formProps()} ref={this.props.setRef}>
        <input type="hidden" name="utf8" value="✓" />
        {this.methodInput()}
        {this.csrfTokenInput()}

        <div className={this.props.holderClass}>{this.props.children}</div>
      </form>
    );
  }
}

export default Form;
