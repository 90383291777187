import { TextInput } from '@sayrhino/rhino-shared-js';
import React, { ChangeEvent, useState } from 'react';
import PasswordInput from './passwordInput';
type InputValue = number | string | undefined;

interface IProps {
  placeholder?: string;
  style?: object;
  id?: string;
  label?: string;
  type?: string;
  value?: InputValue;
  onChangeString?: (val: string) => void;
  onChangeNumber?: (val: number | undefined) => void;
  maxLength?: number;
  resource?: string;
  field?: string;
  datePicker?: object;
  maxDate?: string | Date;
  selected?: string | Date;
  toggleVisibility?: boolean;
  onPasswordChange?: (val: string) => void;
  borderColor?: string;
  defaultValue?: string;
  dataCy?: string;
}

const inputValueMaybeString = (x: InputValue | undefined): x is string | undefined => {
  return typeof x === 'string' || typeof x === 'undefined';
};

const Input = (props: IProps) => {
  const {
    type,
    style,
    maxLength,
    resource,
    field,
    value,
    toggleVisibility,
    onPasswordChange,
    selected,
    label,
    defaultValue,
    dataCy
  } = props;

  const [inputValue, setInputValue] = useState('');

  const onChangeString = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (props.onChangeString) {
      props.onChangeString(event.target.value);
    }
  };

  if (type === 'password' && inputValueMaybeString(value)) {
    return (
      <PasswordInput
        resource={resource ? resource : 'user'}
        toggleVisibility={toggleVisibility}
        onPasswordChange={onPasswordChange}
        onChangeString={props.onChangeString}
        field={field ? field : 'password'}
        value={value}
        label={label}
        data-cy={dataCy}
      />
    );
  } else {
    return (
      <TextInput
        style={style}
        maxLength={maxLength}
        value={value}
        type={type ? type : 'text'}
        onChange={onChangeString}
        resource={resource}
        name={resource + '[' + field + ']'}
        id={resource + '_' + field}
        defaultValue={defaultValue}
        label={label}
        data-cy={dataCy}
      />
    );
  }
};

export default Input;
