import React from 'react';
import { Link } from 'react-router-dom';

const SupportLink = (props: { name: string }) => {
  return (
    <Link target="_blank" style={{color: '#2a2e76'}}
      to={{ pathname: 'https://support.sayrhino.com/hc/en-us/sections/16325311557780-Contact-us' }}>
      <b>{props.name}</b>
    </Link>
  );
}

export default SupportLink;
