const POPOVER_STYLES = {
  width: '195px',
  position: 'absolute',
  top: '0px',
  right: '50%'
};

export const START_INPUT_PROPS = {
  resource: 'admitted_pricing_form',
  field: 'bond_start_date',
  pickerProps: {
    autoFocus: false,
    placeholderText: 'Start Date',
    skipBaseStyle: true
  },
  popoverStyles: POPOVER_STYLES
};

export const END_INPUT_PROPS = {
  resource: 'admitted_pricing_form',
  field: 'bond_end_date',
  pickerProps: {
    autoFocus: false,
    placeholderText: 'End Date',
    skipBaseStyle: true
  },
  popoverStyles: POPOVER_STYLES
};
