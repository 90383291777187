import React from 'react';
import styled from '@emotion/styled';
import ConfirmationCard from './ConfirmationCard';
import StyledModal from './StyledModal';
import { PartialPaymentConfirmationModalProps } from './interfaces';

const Container = styled.div({
  width: '576px',
  margin: 'auto',
  '@media (max-width: 600px)': {
    width: '100%'
  }
});

const confirmationCardHeader = `We were able to process part of your balance`;

const confirmationCardSubHeader = (amount) =>
  `We were only able to process part your payment Due to insufficient funds on your card, you have a remaining balance of ${amount}`;

const confirmationCardCTAText = `Pay remaining balance`;

const PartialPaymentConfirmationModal = ({
  showModal,
  id,
  reloading,
  remainingPartialPaymentAmount,
  handlePayRemainingBalanceClick,
  handleClosePartialPaymentConfirmationModal
}: PartialPaymentConfirmationModalProps) => {
  return (
    <StyledModal id={id} isOpen={showModal} onRequestClose={handleClosePartialPaymentConfirmationModal}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="mt-md-4 mt-5"></div>
          <Container>
            <ConfirmationCard
              reloading={reloading}
              headerText={confirmationCardHeader}
              subheaderText={confirmationCardSubHeader(remainingPartialPaymentAmount)}
              ctaText={confirmationCardCTAText}
              onCloseClick={handleClosePartialPaymentConfirmationModal}
              onCTAClick={handlePayRemainingBalanceClick}
            />
          </Container>
        </div>
      </div>
    </StyledModal>
  );
};

export default PartialPaymentConfirmationModal;
