import React from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button } from '@sayrhino/rhino-shared-js';
import warningIconSrc from './assets/warning_icon.svg';
import { redirectToPartnerEnrollment, useSubsetState } from '../step_helpers';
import { IChildProps } from './step_wrapper';

const Header = styled.h3([
  FONTS.h3,
  {
    color: PALETTE.neutralDark,
    fontWeight: 600,
    marginTop: '8px'
  }
]);

const Message = styled.p([
  FONTS.p2,
  {
    color: PALETTE.neutralDark,
    fontWeight: 500,
    marginTop: '8px'
  }
]);

const Container = styled.div([
  {
    width: '100%',
    position: 'relative',
    top: '28vh',
    left: 0,
    background: 'white'
  }
]);

const MessageContainer = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center'
  }
]);

const StyledColumn = styled.div({
  position: 'relative',
  textAlign: 'center' as 'center',
  width: '100%'
});

interface IAlertProps {
  enrollmentUrl: string;
}

export const ExtraStepsNeededAlert = (props: IAlertProps) => (
  <Container>
    <MessageContainer>
      <img src={warningIconSrc} height="48px" width="48px" />
      <Header>Selecting Standard Security Deposit requires a few extra steps</Header>
      <Message>
        Standard Security Deposit will need to be paid directly to your Property Manager.<br />
        If you would like to continue with the Standard Security Deposit, please select ‘Continue’.
      </Message>
      <Button
        data-cy="continue"
        variant="primary"
        value="Continue"
        type="submit"
        // @ts-ignore
        onClick={() => redirectToPartnerEnrollment(props.enrollmentUrl)}
      >
        Continue
      </Button>
    </MessageContainer>
  </Container>
)

const GetStandardSecurityDeposit = (props: IChildProps) => {
  const [policyApplication] = useSubsetState(props.policyApplication.result, [
    'partner_enrollment_url'
  ]);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center align-items-sm-start">
        <StyledColumn>
          {/* @ts-ignore */}
          <ExtraStepsNeededAlert enrollmentUrl={policyApplication.partner_enrollment_url} />
        </StyledColumn>
      </div>
    </div>
  );
};

export default GetStandardSecurityDeposit;
