import React from 'react';

interface IProps {
  text?: string;
  className?: string;
}

const Error: React.FC<IProps> = (props: IProps) => {
  return <span className={'checkout-form__error ' + props.className}>{props.text}</span>;
};

export default Error;
