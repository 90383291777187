import React, { SyntheticEvent } from 'react';
import FormGroup from '../form-group';
import Label from '../label';
import Error from './Error';

// TODO: remove any. Some made up props are being passed
const Input = (
  props: {
    onChange?: (e: any) => any;
    onBlur?: () => any;
    id: string;
    name: string;
    className?: string;
    type?: string;
    placeholder?: string;
    label?: string;
    value?: any;
    labelClass?: string;
    error?: string;
    onKeyDown?: (e: any) => any;
    dataCy?: string;
    dataCy2?: string;
  } & any
) => {
  const { label, labelClass, error, dataCy, ...inputProps } = props;

  if (label) {
    return (
      <FormGroup>
        <Label target={inputProps.id} title={label} className={labelClass || ''} />
        {error && <Error text={error} />}

        <input data-cy={dataCy} {...inputProps} />
      </FormGroup>
    );
  }

  return <input data-cy={dataCy} {...props} />;
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
