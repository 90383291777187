import { useQuery } from 'react-query';
import axios from 'axios';

const getRentersInsurancePolicy = async (userId: number, policyAppId: number) => {
  const url = `/cover_genius_policy_details?user_id=${userId}&policy_application_id=${policyAppId}`;
  const result = await axios.get(url, { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });
  return result.data;
};

export const useRentersInsurancePolicy = (userId: number, policyAppId: number) => {
  return useQuery('rentersInsurancePolicy', () => getRentersInsurancePolicy(userId, policyAppId), {
    enabled: !!policyAppId
  });
};
