import React, { SyntheticEvent, useEffect } from 'react';
import RentersInsuranceOptions, { IRentersInsuranceOption } from '../constants/renters_insurance_options';
import { IChildProps } from './step_wrapper';
import { useUpdatePolicyApplication } from '../hooks/useUpdatePolicyApplication';
import { allPresent, BackButton, useSubsetState } from '../step_helpers';
import RadioForm from 'components/common/radio_form';

function RentersInsuranceRequired(props: IChildProps) {
  const policyApplicationUpdate = useUpdatePolicyApplication(props.step);
  const [policyApplication, updatePolicyApplication] = useSubsetState(props.policyApplication.result, [
    'renters_insurance_required'
  ]);
  const policyApplicationId = props.policyApplication.result?.id;
  const userId = props.user.result?.id;

  const updateRentersInsuranceRequired = (newValue) => {
    const update = {
      renters_insurance_required: newValue.renters_insurance_required === 'true'
    };
    updatePolicyApplication(update);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (policyApplicationId && userId && policyApplication) {
      policyApplicationUpdate.mutate({
        policyApplicationId,
        userId,
        policyApplication
      });
    }
  };

  const disabled = !allPresent([policyApplication.renters_insurance_required]);

  return (
    <div>
      <h2 className="bold header" data-cy="riRequiredHeader">
        Does your new home require renter’s insurance?
      </h2>
      <div className="form-container">
        <form className="sign-up__form" id="is-ri-required-form" onSubmit={onSubmit}>
          <RadioForm
            field="renters_insurance_required"
            options={RentersInsuranceOptions}
            selected={policyApplication.renters_insurance_required}
            hideActions={true}
            onChange={(renters_insurance_required: IRentersInsuranceOption) =>
              updateRentersInsuranceRequired({ renters_insurance_required })
            }
            holderClass=""
          />
          <div className="sign-up__actions d-flex justify-content-between align-items-center action_buttons">
            <BackButton step={props.step} data-cy="back" />
            <input type="submit" className="btn-default" data-cy="continue" value="Continue" disabled={disabled} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default RentersInsuranceRequired;
