import React, {Component, ReactNode} from 'react';

interface IProps {
  index: number;
  item: string;
  alt_tag?: string;
  subheader?: string | number;
  content?: string;
}

class ImageWithSubheader extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { index, item, alt_tag } = this.props;
    return (
      <div className="item carousel-card-centered" key={index}>
        <img style={styles.image} src={item} alt={alt_tag} />
        <p style={styles.subheader}>{this.props.subheader}</p>
      </div>
    );
  }
}

const styles = {
  image: {
    height: '144px',
    width: '144px',
    margin: 'auto'
  },
  subheader: {
    marginTop: '20px',
    fontFamily: 'MaisonNeueExtendedLight',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'black'
  }
};

export default ImageWithSubheader;
