import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button, Radio } from '@sayrhino/rhino-shared-js';
import { buildCreditCardLabel } from './utils';
import NewPaymentMethodForm from './NewPaymentMethodForm';
import { isPresent } from '../../../../../utils';
import { prettyPrintErrors } from './utils';
import DisplayWhen from '../../../DisplayWhen';
import SpinnerIcon from 'components/v2/common/SpinnerIcon';
import { PaymentMethodsProps } from './interfaces';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { csrfToken } from 'utils/document';
import env from 'utils/env';
import { createSetupIntent, makeSetupIntentDefaultPaymet } from './api';

const StyledPaymentMethodCard = styled.div({
  border: `2px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  width: '100%',
  borderRadius: '16px'
});

const StyledSubHeader = styled.p([
  FONTS.p1Light,
  {
    color: `${PALETTE.neutral88}`,
    margin: '0px',
    padding: '40px 0 4px 0'
  }
]);

const StyledPaymentMethodTable = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: `100`,
  justifyContent: 'space-between',
  '& b': {
    textAlign: 'end'
  }
});

const StyledTableHeader = styled.div([
  FONTS.p3Light,
  {
    width: '100%',
    alignItems: 'baseline',
    color: `${PALETTE.neutral88}`
  }
]);

const ButtonWrap = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '20px',
  paddingTop: '30px',
  paddingBottom: '30px',
  flexDirection: 'column-reverse',
  '@media(min-width: 768px)': {
    gap: '26px',
    flexDirection: 'row'
  }
});

const PaymentMethodError = styled.div([
  FONTS.p1Medium,
  {
    color: PALETTE.alert125,
    backgroundColor: PALETTE.alert4,
    borderRadius: 4,
    padding: `16px 0`,
    fontSize: `14px`,
    width: '100%',
    lineHeight: `24px`,
    '& button': {
      display: 'inline-block'
    },
    textAlign: `center`
  }
]);

const StyledRadioOption = styled(Radio.Option)`
  align-items: baseline !important;
  border-bottom: 2px solid ${PALETTE.neutral4};
  paddingbottom: 0px;
  marginbottom: 0px;
`;

const DefaultCardContainer = styled.div`
  border-bottom: solid 2px ${PALETTE.neutral4};
  padding: 10px 10px 5px 10px;
  display: flex;
  align-items: baseline;
`;

const NewPaymentMethodContainer = styled.div`
  padding: 10px 10px 5px 10px;
`;

const StyledPayNowButton = styled(Button)`
  font-weigt: 500;
  justify-content: center;
  margin-bottom: '16px';
  text-align: 'center';
  user-select: 'none';
  white-space: 'nowrap';
  :disabled {
    font-weight: 500;
    background-color: ${PALETTE.brand4} !important;
    border: solid 1px ${PALETTE.brand100} !important;
    color: ${PALETTE.brand100} !important;
  }
`;

const StyledCancelButton = styled(Button)`
  font-weight: 500;
  justify-content: center;
`;

const defaultCardOption = { value: 'default_credit_card', label: 'Credit or Debit Card Number' };

const updatePaymentMethodOption = { value: 'update_payment_method', label: 'Update Payment Method' };

const googlePayPayNowEnabled = (window as any).App?.featureFlags?.googlePayPayNow;

const PayNowLoader = () => (
  <>
    <SpinnerIcon width={30} />
    <StyledProcessingText> Processing... </StyledProcessingText>
  </>
);

const StyledProcessingText = styled.span([
  FONTS.h4,
  {
    color: PALETTE.brand125,
    paddingLeft: '10px'
  }
]);

const PaymentMethods = ({
  submitting,
  defaultCard,
  selectedOption,
  errors,
  handleSelectOption,
  handleClosePayNowModal,
  handleZipCodeUpdate,
  handleSubmit,
  setSubmitting
}: PaymentMethodsProps) => {
  const updatePaymentMethodSelected = selectedOption === updatePaymentMethodOption.value;
  const defaultCardSelected = selectedOption === defaultCardOption.value;

  const showDefaultCardError = isPresent(defaultCard) && defaultCardSelected && isPresent(errors);
  const showNewPaymentMethodError = updatePaymentMethodSelected && isPresent(errors);

  const { brand, last4 } = defaultCard || {};

  const stripe = useStripe();
  const elements = useElements();

  const [paymentElementErrorMessage, setPaymentElementErrorMessage] = useState();

  const handleError = (error) => {
    setSubmitting(false);
    setPaymentElementErrorMessage(error.message);
  };

  const DEFAULT_HEADERS = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const DEFAULT_OPTIONS = {
    authenticity_token: csrfToken(),
    credentials: 'same-origin',
    headers: DEFAULT_HEADERS
  };

  const handlePaymentElementSubmit = async (event) => {
    // don't let default form submission happen here which would reresh the page
    event.preventDefault();

    if (!stripe) {
      // disable form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    // Trigger form validation and wallet collection
    // @ts-ignore:next-line
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const { client_secret: clientSecret, id: setup_id } = await createSetupIntent();

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmSetup({
      // @ts-ignore:next-line
      elements,
      clientSecret,
      confirmParams: {
        return_url: env('RHINO_URL') + '/users/edit'
      },
      redirect: 'if_required'
    });

    if (error) {
      setSubmitting(false);
      handleError(error);
    } else {
      await makeSetupIntentDefaultPaymet(setup_id);
      // pays open invoices
      handleSubmit();
    }
  };

  const handlePayNowButtonClicked =
    googlePayPayNowEnabled && updatePaymentMethodSelected ? handlePaymentElementSubmit : handleSubmit;

  return (
    <>
      <StyledSubHeader>Payment method</StyledSubHeader>
      <StyledPaymentMethodCard>
        <StyledPaymentMethodTable>
          <StyledTableHeader>
            <Radio.Root
              name="payment-method-radio-options"
              value={selectedOption}
              onValueChange={(value) => {
                handleSelectOption(value);
              }}
            >
              <DisplayWhen predicate={isPresent(defaultCard)}>
                <DefaultCardContainer>
                  <StyledRadioOption value={defaultCardOption.value} label={buildCreditCardLabel(brand, last4)} />
                </DefaultCardContainer>
                <DisplayWhen predicate={showDefaultCardError}>
                  <PaymentMethodError>{prettyPrintErrors(errors)}</PaymentMethodError>
                </DisplayWhen>
              </DisplayWhen>
              <NewPaymentMethodContainer>
                <StyledRadioOption value={updatePaymentMethodOption.value} label={updatePaymentMethodOption.label} />
                <DisplayWhen predicate={showNewPaymentMethodError}>
                  <PaymentMethodError>{prettyPrintErrors(errors)}</PaymentMethodError>
                </DisplayWhen>
              </NewPaymentMethodContainer>
            </Radio.Root>
          </StyledTableHeader>
        </StyledPaymentMethodTable>
        <DisplayWhen predicate={updatePaymentMethodSelected}>
          {paymentElementErrorMessage && <PaymentMethodError>{paymentElementErrorMessage}</PaymentMethodError>}
          {!googlePayPayNowEnabled && <NewPaymentMethodForm handleZipCodeUpdate={handleZipCodeUpdate} />}
          {googlePayPayNowEnabled && <PaymentElement />}
        </DisplayWhen>
      </StyledPaymentMethodCard>
      <ButtonWrap>
        <StyledCancelButton variant="secondary" onClick={handleClosePayNowModal}>
          Cancel
        </StyledCancelButton>
        <StyledPayNowButton disabled={submitting} id="pay-now-overview" onClick={handlePayNowButtonClicked}>
          {submitting ? <PayNowLoader /> : 'Pay Now'}
        </StyledPayNowButton>
      </ButtonWrap>
    </>
  );
};

export default PaymentMethods;
