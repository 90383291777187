import React from 'react';

import PaymentStatus from './status';

import { IPayment } from './paymentProps';

const Payment: React.FC<IPayment> = (props: IPayment & { children?: React.ReactNode }) => {
  return (
    <div style={styles.box} className="payment-box row">
      <div className="col">
        <div className="row">
          <div className="col" style={styles.paymentTitleWrapper}>
            <h6 className="demi-18" style={styles.paymentTitle}>
              Payment Info
            </h6>
            <div>{props.children}</div>
          </div>
        </div>
        <div className="row">
          <h6 className="col demi">{props.amount}</h6>
        </div>
        <div style={styles.payment}>
          <div style={styles.dueDate}>{`Due ${props.due_date}`}</div>
          <div style={styles.details}>
            <div style={styles.creditCard.lastFour}>{`****${props.last_four}`}</div>
            <div style={styles.creditCard.expirationDate}>{props.expiration_date}</div>
            <div style={styles.status}>
              <PaymentStatus {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  payment: {
    fontFamily: 'MaisonNeueLight',
    lineHeight: '24px',
    fontSize: '14px'
  },
  box: {
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    padding: '16px 15px'
  },
  dueDate: {
    fontFamily: 'MaisonNeueMedium',
    flex: '0 0 145px',
    marginRight: '60px'
  },
  details: {
    flex: '1 0 auto',
    display: 'flex'
  },
  creditCard: {
    lastFour: {
      flex: '0 0 auto',
      marginRight: '32px'
    },
    expirationDate: {
      flex: '0 0 auto'
    }
  },
  status: {
    flex: '1 0 auto'
  },
  paymentTitle: {
    flex: '1 0 auto',
    marginBottom: '0px'
  },
  paymentTitleWrapper: {
    display: 'flex',
    alignitems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
};

export default Payment;
