import React from 'react';
import { pendingChangeRequest } from '../constants';
import ActionCard from './ActionCard';

interface Props {
  SDIs:
    | {
        eligible_for_renewal: boolean;
        coverage_end_date: string;
        upfront: boolean;
        pending_change_request: boolean;
        policy_id: number;
      }[]
    | undefined;
}

export const ChangeRequests = (props: Props) => {
  const SDIs = props?.SDIs;
  if (!SDIs) {
    return null;
  }

  return (
    <>
      {SDIs.map((policy) => {
        return (
          <ActionCard
            key={policy.policy_id}
            header={pendingChangeRequest.header}
            text={pendingChangeRequest.text}
            label={pendingChangeRequest.label}
            ctaText={pendingChangeRequest.ctaText}
            handleClick={() => {
              window.location.href = `/insurance_policies/${policy.policy_id}`;
            }}
            destructive={true}
            callToActionId={'change-requests'}
          />
        );
      })}
    </>
  );
};
