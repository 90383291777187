import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { PaymentInformationLink } from './PaymentInformationLink';
import { ProfileLink } from './ProfileLink';
import { UpdatePasswordLink } from './UpdatePasswordLink';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { RightCaret } from './RightCaret';

const Wrapper = styled.div({
  background: PALETTE.brand4,
  padding: '100px 0 100px 50px'
});

const WrapperMobile = styled.div({
  background: PALETTE.brand4,
  padding: '30px',
  marginLeft: -15,
  marginRight: -15,
  minHeight: '100dvh'
});

const MobileLinkWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 28
});

const LinkWrapper = styled.div({
  marginBottom: 28
});

const Header = styled.div([
  FONTS.brandH1Bold,
  {
    paddingBottom: 15,
    fontWeight: 'bold'
  }
]);

const HeaderMobile = styled.div([
  FONTS.brandH2Black,
  {
    paddingBottom: 15,
    fontWeight: 'bold'
  }
]);

interface IProps {
  stripe_publishable_key?: string | null;
}

export const EditAccountLeftNav = (props: IProps) => {
  return (
    <Wrapper>
      <Header data-cy="accountHeader">Your Account</Header>
      <div>
        <LinkWrapper>
          <ProfileLink />
        </LinkWrapper>
        <LinkWrapper>
          <UpdatePasswordLink />
        </LinkWrapper>
        {props.stripe_publishable_key && (
          <LinkWrapper>
            <PaymentInformationLink />
          </LinkWrapper>
        )}
      </div>
    </Wrapper>
  );
};

export const EditAccountLeftNavMobile = (props: IProps) => {
  return (
    <WrapperMobile>
      <HeaderMobile data-cy="accountHeader">Your Account</HeaderMobile>
      <div>
        <MobileLinkWrapper>
          <ProfileLink />
          <RightCaret />
        </MobileLinkWrapper>
        <MobileLinkWrapper>
          <UpdatePasswordLink />
          <RightCaret />
        </MobileLinkWrapper>
        {props.stripe_publishable_key && (
          <MobileLinkWrapper>
            <PaymentInformationLink />
            <RightCaret />
          </MobileLinkWrapper>
        )}
      </div>
    </WrapperMobile>
  );
};
