import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import AutocompleteInput from 'components/common/form/autocomplete-input';

type IState = {
  hits: any[];
};

class Autocomplete extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hits: this.props.hits || []
    };
  }

  componentDidMount() {
    if (this.shouldRunFallback(this.props)) this.fetchAdditionalHits(this.props.hits);
  }

  shouldRunFallback = (props: any): boolean => {
    const { hits, hitsPerPage, fallback, value } = props;
    return hits.length < hitsPerPage && !!fallback && !!value.length;
  };

  componentWillReceiveProps(nextProps: any) {
    const { filter, hitsPerPage, searchBy } = this.props;
    const hits = filter ? filter(nextProps.hits, hitsPerPage) : nextProps.hits;

    if (this.shouldRunFallback(nextProps)) {
      this.fetchAdditionalHits(hits);
    } else {
      this.setState({ hits });
    }
  }

  onChange = (newVal: string) => {
    this.props.onChange(newVal);

    if (!newVal.length) return;

    this.props.refine(newVal);
  };

  fetchAdditionalHits = (currentHints: any[]) => {
    const { hitsPerPage, fallback, value } = this.props;
    const additional_count = hitsPerPage - currentHints.length;

    fallback(value).then((fallbackHits) => {
      this.setState({
        hits: currentHints.concat(fallbackHits.slice(0, additional_count))
      });
    });
  };

  options = (): any[] => this.state.hits;

  inputProps = (): any => {
    const { value, onChange } = this.props;

    return Object.assign({}, this.props, {
      options: value.length ? this.options() : [],
      onChange,
      value
    });
  };

  render() {
    return <AutocompleteInput {...this.inputProps()} />;
  }
}

export default Autocomplete;
