import React from 'react';

const ErrorRentAmount = ({ text, key=undefined, css="inline-error-text" }) => (
  <span className={css} style={styles} key={key}>{text}</span>
);

const styles = {
  color: '#DF2D42',
  fontWeight: '300',
  letterSpacing: '0.5px',
  lineHeight: '24px',
  fontSize: '14px',
  display: 'block',
  background: '#FEF8F9',
  padding: '12px 20px',
  borderRadius: '8px',
  margin: '0 auto'
}

export default ErrorRentAmount;
