/** @jsx jsx */
import React from 'react';
import { ArrowIcon, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { useCheckMobile } from 'utils/hooks/useCheckMobile';
import { css, jsx } from '@emotion/react';

const backButton = css({
  width: `auto`,
  background: `transparent`,
  color: PALETTE.brand100,
  border: `1px solid transparent`
});

const footerWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 24,
  background: PALETTE.white,
  position: `fixed`,
  bottom: 0,
  left: 0,
  width: `100%`
});

const footerWrapperDesktop = css({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 70,
  marginTop: 42
});

const nextButton = css({
  border: `1px solid ${PALETTE.brand100}`,
  color: PALETTE.white,
  borderRadius: 24,
  background: PALETTE.brand100,
  width: `auto`,
  padding: '9px 23px',
  minWidth: 209,
  transition: 'background 300ms ease'
});

const disabledButtonStyle = css({
  border: `1px solid ${PALETTE.neutral25}`,
  background: PALETTE.neutral25
});

const arrowIconStyles = css({
  marginLeft: 10
});

interface QuoteDisplayVariantProps {
  arrowOnButton: boolean;
  nextBtnLabel: string;
  nextBtnDisabled?: boolean;
  onNext: () => void;
  onBack?: () => void;
  dataCy: string;
}

export function QuoteDisplayFooter(props: QuoteDisplayVariantProps) {
  const isMobile = useCheckMobile();
  const rootStyles = isMobile ? footerWrapper : footerWrapperDesktop;

  const buttonStateStyles = props.nextBtnDisabled ? [disabledButtonStyle] : [];

  return (
    <React.Fragment>
      <div css={rootStyles}>
        {props.onBack ? (
          <button
            id="back-button"
            css={[backButton, FONTS.h5]}
            onClick={() => props.onBack && props.onBack()}
            data-cy="back"
            type="button"
          >
            Back
          </button>
        ) : (
          <div />
        )}
        <button
          id="next-button"
          css={[nextButton, FONTS.h5, ...buttonStateStyles]}
          onClick={props.onNext}
          data-cy={props.dataCy}
          disabled={props.nextBtnDisabled}
        >
          {props.nextBtnLabel}
          {props.arrowOnButton && <ArrowIcon css={arrowIconStyles} />}
        </button>
      </div>
    </React.Fragment>
  );
}
