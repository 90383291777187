import React from 'react';

// @ts-ignore
import timeIcon from './time.svg';

const ChangeRequest: React.FC = () => {
  return (
    <div style={styles.actionNeeded}>
      <div className="col-1" style={styles.actionNeededIcon}>
        <img src={timeIcon} alt="Time" />
      </div>
      <div data-cy="propertyUpdatedPolicy" className="col-11" style={styles.actionNeededText}>
        Your property has updated your policy. Please review and confirm the changes as soon as possible.
      </div>
    </div>
  );
};

const styles = {
  actionNeeded: {
    background: '#DF2D42',
    borderRadius: '8px',
    margin: '0 20px 20px 20px',
    padding: '24px 0',
    display: 'inline-flex'
  },
  actionNeededText: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    paddingLeft: '25px'
  },
  actionNeededIcon: {
    alignSelf: 'center' as 'center'
  }
};

export default ChangeRequest;
