import React, { useState } from 'react';

import Button from 'components/v2/button';
import Loader from 'components/v2/loader';

import DataHolder from '../data_holder';
import { IPolicy, IChangeRequest, ICallbacks } from '../policyProps';

interface IProps {
  policy: IPolicy;
  changeRequest: IChangeRequest;
  onConfirm: (_callbacks: ICallbacks) => void;
};

const PendingChangeRequest: React.FC<IProps> = (props: IProps) => {
  const { policy, changeRequest } = props;
  const [isLoading, setIsLoading] = useState(false);
  const callback = () => setIsLoading(false);

  const configureDateChange = () => {
    const {lease_start_date, lease_end_date} = changeRequest;

    if (lease_end_date == null && lease_start_date == null) {
      return undefined;
    }
    return `${lease_start_date || policy.lease_start_date} \u2014 ${lease_end_date || policy.lease_end_date}`;
  };

  const onConfirm = () => {
    setIsLoading(true);
    props.onConfirm({ onSuccess: callback, onFailure: callback });
  }

  const renderLoading = () => {
    return (
      <div className="payment-container">
        <Loader />
      </div>
    );
  }

  const renderData = () => {
    return <div>
      <div className="row no-gutters payment-container">
        <DataHolder
          value={policy.payment_amount}
          label={policy.upfront ? 'In full' : 'Per month'}
          newValue={changeRequest.payment_amount}
        />
        <DataHolder
          value={policy.unit_name || ''}
          label="Unit/Floor"
          newValue={changeRequest.unit}
        />
        <DataHolder
          value={`${policy.lease_start_date} \u2014 ${policy.lease_end_date}`}
          label="Lease dates"
          newValue={configureDateChange()}
        />
        <DataHolder
          value={`${policy.coverage_start_date} \u2014 ${policy.coverage_end_date}`}
          label="Coverage dates"
        />
        <DataHolder
          value={policy.monthly_rent}
          label="Rent amount"
          newValue={changeRequest.monthly_rent}
        />
        <DataHolder
          value={policy.coverage_amount}
          label="Coverage amount"
          newValue={changeRequest.coverage_amount}
        />
      </div>

      <Button text="Confirm" onClick={onConfirm} />
    </div>
  }

  return (
    <div className="update-payment-method">
      <div className="header border-bottom" >
        <h6 className="demi-18">
          You have updates to your policy that affects payment pricing.
          Please review and confirm updates first.
        </h6>
      </div>

      {isLoading ? renderLoading() : renderData() }
    </div>
  );
};

export default PendingChangeRequest;