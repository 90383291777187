import React from 'react';
import moment from 'moment';

import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  denialReason: string;
  denialReasonExplanation: string;
  denialLockingPeriod: string;
  contactSupportUrl: string;
  responderEmail: string;
  responderName: string;
}

const DeclinedStatusBanner = (props: IProps) => {
  const {
    denialReason,
    denialReasonExplanation,
    denialLockingPeriod,
    contactSupportUrl,
    responderEmail,
    responderName
  } = props;

  if (denialLockingPeriod && moment.utc().isSameOrAfter(denialLockingPeriod)) {
    return null;
  }

  const SupportButton = styled.a([
    FONTS.p2Extended,
    {
      '&&': {
        borderRadius: 24,
        fontWeight: 500,
        backgroundColor: '#6A3BF5',
        color: 'white',
        marginRight: 12,
        padding: '8px 20px'
      }
    }
  ]);

  const PropertyManagerButton = styled(SupportButton)(
    {
      '&&': {
        cursor: 'pointer'
      }
    }
  )

  const DeniedBannerWrapper = styled.div({
    border: '1px solid #9ca3af',
    borderRadius: '8px',
    padding: '24px 15px',
    margin: '24px 15px 0px',
    backgroundColor: '#f5f5f5',
    fontSize: '1rem'
  });

  const BannerTitle = styled.h3([
    FONTS.h3,
    { fontSize: '1.1rem' }
  ]);

  const newRequestsBlockedUntilFmt = moment(denialLockingPeriod).format('MMMM D, YYYY');
  const propertyManagerIdentification = responderName || 'Your Property Manager';

  let denialExplanation;
  if (['lease_end_date_known', 'lease_end_date_unknown'].includes(denialReason)) {
    denialExplanation = <p>{propertyManagerIdentification} has indicated that the date of your cancellation request
      was not aligned with the lease. You can submit another request on <b>{newRequestsBlockedUntilFmt}</b> -
      don't worry, we'll send you a reminder!</p>
  } else if (denialReason === 'other') {
    denialExplanation = <p>{propertyManagerIdentification} has denied your cancellation request
      and provided the following reason: {denialReasonExplanation}</p>
  } else if (denialReason === 'no_indicated_plans') {
    denialExplanation = <p>{propertyManagerIdentification} has denied your cancellation request
      and provided the following reason: You have not indicated plans to end the lease
      and have not provided a cash deposit.</p>
  } else { // legacy declined requests
    denialExplanation = <p>{propertyManagerIdentification} has denied your cancellation request.</p>
  }

  return (
    <DeniedBannerWrapper>
      <BannerTitle>Your cancellation request has been denied</BannerTitle>
      <div>
        {denialExplanation}

        <p>If you have questions, or believe this denial was in error, please get in touch with
          your Property Manager or our support team. We're happy to help!</p>

        <SupportButton
          href={contactSupportUrl}
          target="_blank"
          rel="noopener noreferrer"
          id="contactSupportBtn"
        >
          Contact Support
        </SupportButton>

        {
          responderEmail && 
            (
              <PropertyManagerButton href={`mailto:${responderEmail}`} id="contactPropertyManagerBtn">
                Contact Property Manager
              </PropertyManagerButton>
            )
        }
      </div>
    </DeniedBannerWrapper>
  );
};

export default DeclinedStatusBanner;
