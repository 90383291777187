import React from 'react';

// @ts-ignore
import closeIcon from './icon.svg';

interface IProps {
  onClick: () => void;
  background: string;
  id: string;
  dataCy?: string;
}

const CloseButton: React.FC<IProps> = (props: IProps) => {
  return (
    <div id={props.id}
      onClick={props.onClick}
      style={{...styles.closeButton, background: props.background}}
      data-cy={props.dataCy}
    >
      <img src={closeIcon} style={styles.image} alt="Close" />
    </div>
  );
};

const styles = {
  closeButton: {
    width: '32px',
    height: '32px',
    border: '1px solid #777777',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '20px',
    position: 'absolute' as 'absolute',
    top: '24px',
    right: '38px',
    cursor: 'pointer'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    paddingTop: '7px'
  }
};

export default CloseButton;
