import React from 'react';

interface IProps {
  status: string;
  className?: string;
  pendingChangeRequest?: boolean;
  pendingCancellation?: boolean;
  dataCy?: string;
}

const PolicyStatusIndicator: React.FC<IProps> = (props: IProps) => {
  const {className, dataCy} = props;

  const getStatus = () => {
    const {status, pendingChangeRequest, pendingCancellation} = props;
    if (pendingCancellation) {
      return 'Pending Cancellation';
    }
    if (pendingChangeRequest) {
      return 'Action Needed';
    }
    return status;
  };

  const getStyles = () => {
    const {pendingChangeRequest, pendingCancellation} = props;
    if (pendingCancellation) {
      return styles.pendingCancellation;
    }
    if (pendingChangeRequest) {
      return styles.actionNeeded;
    }
    return styles.status;
  };

  return (
    <div style={getStyles()} data-cy={dataCy} className={className}>{getStatus()}</div>
  );
};

const styles = {
  status: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    color: '#6318CE',
    background: '#F5F4FB',
    borderRadius: '14px',
    textAlign: 'center' as 'center',
    display: 'inline-block',
    padding: '2px 12px'
  },
  pendingCancellation: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    color: '#777777',
    borderRadius: '14px',
    border: '1px solid #777777',
    textAlign: 'center' as 'center',
    display: 'inline-block',
    padding: '2px 12px',
    overflow: 'hidden'
  },
  actionNeeded: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '14px',
    background: '#FDF2F2',
    borderRadius: '14px',
    textAlign: 'center' as 'center',
    display: 'inline-block',
    padding: '2px 12px',
    color: '#ED3F3D'
  }
};

export default PolicyStatusIndicator;
